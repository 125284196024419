import React from 'react';
import { useEditCommissions } from '../../../hooks';
import { commissionBaseLabels } from '../utils';
import * as Styled from '../styles';
import { EDIT_COMMISSIONS } from '../../../enums';

export const CPAStructure = ({ hook }: {hook: ReturnType<typeof useEditCommissions>}) => {
  if (!hook.hookCommissionData?.commissionStructure) return <p />;

  const { cpaFlat, cpaTiered } = hook.hookCommissionData.commissionStructure;
  const isTiered = cpaTiered.commissionBase;
  const commissionBase = isTiered ? cpaTiered.commissionBase : cpaFlat.commissionBase;
  const minimumSaleAmount = isTiered ? Number(cpaTiered.minimumSaleAmount) : Number(cpaFlat.minimumSaleAmount);
  const commissionFee = isTiered ? '' : cpaFlat.commissionFee;
  const {
    // dynamic label based on different commission base
    commissionBaseFormat,
    labelOfSales,
    labelOfPerSales,
  } = commissionBaseLabels(commissionBase || '');

  const tierRows = cpaTiered.tiers?.map((x, i) => (
    <Styled.TierRowStyled key={`tierRow${i}`}>
      <Styled.TierItemStyled>
        <Styled.InputTextStyled
          type="text"
          value={x.upTo || ''}
          label={`Level ${i + 1} ${labelOfSales}`}
          theme="readonly"
          readonly
        />
      </Styled.TierItemStyled>
      <Styled.TierItemStyled>
        <Styled.InputTextStyled
          type="text"
          value={x.commissionAmount || ''}
          label={`Level ${i + 1} Commission ($) Per ${labelOfPerSales}`}
          theme="readonly"
          readonly
        />
      </Styled.TierItemStyled>
    </Styled.TierRowStyled>
  ));
  return (
    <>
      <Styled.CommissionStructureRow>
        <Styled.InputTextStyled
          label={EDIT_COMMISSIONS.DETAILS.INPUT.COMMISSION_BASE}
          type="text"
          value={commissionBaseFormat === 'Units' ? '' : commissionBaseFormat}
          theme="readonly"
          readonly
        />
        <Styled.InputTextStyled
          label="Minimum Amount"
          type="text"
          value={minimumSaleAmount || ''}
          theme="readonly"
          readonly
        />
        <Styled.InputTextStyled
          label="Commission Amount ($)"
          type="text"
          value={commissionFee || ''}
          theme="readonly"
          readonly
        />
      </Styled.CommissionStructureRow>
      {tierRows}
      {isTiered && (
        <div>
          <Styled.InputTextStyled
            label="All Subsequent Transactions earn commission ($) per Transaction"
            type="text"
            value={cpaTiered.cutoffAmount || ''}
            theme="readonly"
            readonly
          />
        </div>
      )}
    </>
  );
};
