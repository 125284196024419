import React from 'react';
import rehypeRaw from 'rehype-raw';
import { LoadingDots, Modal } from '../../../../components';
import Progress from '../../../../components/Progress';
import { imageList, path } from '../../../../utils';
import * as Styled from './styles';
import { useMerchantProgram } from './hooks';
import { columns } from './contracts';
import { SIGN_UP } from '../enums';
import { MERCHANT_PROGRAM } from './enums';

const MerchantProgram = () => {
  const hook = useMerchantProgram();

  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img
            src={imageList.logoFull.src}
            alt="logo"
          />
        </figure>

        <Progress step={5} />
      </Styled.LeftStyled>

      <Styled.RightStyled>
        <Styled.RightContentStyled>
          <h1>{SIGN_UP.TITLE}</h1>
          <h2><pre>{`Step 5   ${path.createAccountStep5.name}`}</pre></h2>
          <span>{MERCHANT_PROGRAM.DESCRIPTION}</span>

          {hook.hookLoading ? (
            <LoadingDots />
          ) : (
            <Styled.TableStyled
              columns={columns}
              data={hook.hookTableData}
              setData={hook.hookSetTableData}
              setOpenModal={hook.hookSetModalTC}
              setModalInfo={hook.hookSetModalInfo}
              setContext={hook.hookContextDispatcher}
            />
          )}

          <span>{MERCHANT_PROGRAM.TERMS}</span>
          <Styled.FormStyled>
            <Styled.ButtonStyled
              onClick={() => hook.hookNavigate(path.createAccountStep4.href)}
              theme="secondary"
            >
              {MERCHANT_PROGRAM.BACK}
            </Styled.ButtonStyled>
            <Styled.ButtonStyled
              onClick={() => hook.hookNavigate(path.createAccountStep6.href)}
            >
              {MERCHANT_PROGRAM.NEXT}
            </Styled.ButtonStyled>
          </Styled.FormStyled>
        </Styled.RightContentStyled>
      </Styled.RightStyled>
      <Modal isOpen={hook.hookModalTC}>
        <Styled.ModalTitleStyled>
          {hook.hookModalInfo.title}
        </Styled.ModalTitleStyled>
        <Styled.ReactMarkdownStyled rehypePlugins={[rehypeRaw]}>
          {hook.hookModalInfo.terms}
        </Styled.ReactMarkdownStyled>
        <Styled.ModalButtonStyled
          onClick={hook.hookSetModalTC}
        >
          {MERCHANT_PROGRAM.CLOSE_MODAL}
        </Styled.ModalButtonStyled>
      </Modal>
    </Styled.WrapperStyled>
  );
};

export default MerchantProgram;
