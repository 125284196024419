import React from 'react';
import {
  faSearch, faFileArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { DefaultPropsType } from '../../../../types';
import {
  AppWrapper, Button, Calendar, ErrorBanner, InputCalendar, PageTitle, Pagination, RecordsDropdown, Table,
} from '../../../../components';
import * as Styled from './styles';
import { RECORDS_PER_PAGE_OPTIONS, path } from '../../../../utils';
import { useChangeMonitoring } from './hooks/useChangeMonitoring';
import PlaceHolderNoResults from '../../../../components/NoResultsPlaceHolder';
import { columns } from './contracts/columns';
import { useSideMenu } from '../../../../components/SideMenu/hooks';
import {
  CLEAR_FORM, DOWNLOAD_CSV, SET_STATUS_TITLE, UPDATE_REVIEW_STATUS,
} from './enum';

type ChangeMonitoringProps = DefaultPropsType

const ChangeMonitoring = ({ permissionsCodeList = [] }: ChangeMonitoringProps) => {
  const hook = useChangeMonitoring();
  const hookSideMenu = useSideMenu();
  const navigate = useNavigate();

  if (!hookSideMenu.shouldMerchantChangeMonitoringBeAvailable()) {
    navigate('/merchant');
    return null;
  }

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        message={hook.errorMessage}
        isOpen={hook.errorMessage.length > 0}
      />
      <Styled.HeaderStyled>
        <PageTitle>{path.fintelCheckChangeMonitoring.name}</PageTitle>
      </Styled.HeaderStyled>

      <Styled.FiltersStyled>
        <Styled.FiltersSelectStyled>
          <InputCalendar
            label="Run Date"
            placeholder="Select Date"
            value={hook.selectedRunDate ? hook.selectedRunDate.toDateString() : ''}
            onClick={() => hook.setIsDateCalendarOpen(true)}
            isDisabled={hook.isLoading}
          />

          <Styled.InputTextStyled
            faIcon={faSearch}
            type="text"
            placeholder="Search for URL..."
            label="URL Search"
            value={hook.urlSearch}
            onChange={(e) => hook.onUrlSearchChange(e.target.value)}
            disabled={hook.isLoading}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !hook.isLoading) hook.searchButtonHandler();
            }}
          />

          <Styled.SelectStyled
            options={[{ label: 'All Statuses', value: 'All Statuses' }, ...hook.REVIEW_STATUS]}
            onChange={(e) => hook.setFilterReviewStatus(e)}
            selected={hook.filterReviewStatus}
            placeholder="Review Status"
            label="Review Status"
          />

          <Styled.ClearFormButtonStyled
            onClick={hook.handleOnClearFormData}
            type="reset"
            theme="text-only"
            disabled={hook.isLoading}
          >
            {CLEAR_FORM}
          </Styled.ClearFormButtonStyled>
        </Styled.FiltersSelectStyled>

        <Styled.RowStyled>
          <RecordsDropdown
            selected={hook.records}
            onChange={hook.setRecords}
            options={RECORDS_PER_PAGE_OPTIONS}
            totalRecords={hook.totalRecords}
            currentPage={hook.currentPage}
          />
          <Button
            onClick={hook.generateCSVHandler}
            theme="tertiary"
          >
            <Styled.IconStyled icon={faFileArrowDown} />
            {DOWNLOAD_CSV}
          </Button>
        </Styled.RowStyled>
      </Styled.FiltersStyled>

      {hook.changeMonitoringData.length > 0 || hook.isLoading ? (
        <>
          <Table
            columns={columns(hook.handleOnViewChangesClick, hook.handleOnSelectCheckbox)}
            data={hook.changeMonitoringData}
            isLoading={hook.isLoading}
            loadWithData={hook.changeMonitoringData.length > 0}
            onSort={(column, direction) => hook.onSortHandler(column, direction)}
            sortColumn={hook.sortColumn}
            headerCheckBox={hook.headerCheckbox}
            handleHeaderCheckBox={(checked) => hook.handleOnSelectCheckbox(checked, undefined, true)}
          />
          {hook.changeMonitoringData.length > 0 && (
          <Pagination
            currentPage={hook.currentPage}
            total={hook.totalPages}
            onPageChange={hook.handleOnChangeCurrentPage}
          />
          )}
        </>
      ) : (
        <PlaceHolderNoResults />
      )}

      <Calendar
        date={hook.selectedRunDate}
        onApply={(date) => hook.handleOnSelectDate(date)}
        onCancel={() => hook.setIsDateCalendarOpen(false)}
        isOpen={hook.isDateCalendarOpen}
        hasRange={false}
        isSingle
      />

      {hook.changeMonitoringData.some((item) => item.checked) && (
        <Styled.SetStatusWrapper>
          <Styled.SubtitleStyled>
            {SET_STATUS_TITLE}
          </Styled.SubtitleStyled>
          <Styled.SetStatusContent>
            <Styled.SelectStyled
              options={hook.REVIEW_STATUS}
              onChange={(e) => hook.setSelectedReviewStatus(e)}
              selected={hook.selectedReviewStatus}
              placeholder="Select Review Status"
              menuPlacement="top"
            />
            <Button
              onClick={() => hook.selectedReviewStatus && hook.handleOnSetReviewStatus(hook.selectedReviewStatus?.value)}
              theme="primary"
              width="20rem"
              disabled={!hook.selectedReviewStatus}
              loading={hook.setReviewStatusLoading}
            >
              {UPDATE_REVIEW_STATUS}
            </Button>
          </Styled.SetStatusContent>
        </Styled.SetStatusWrapper>
      )}
    </AppWrapper>
  );
};

export default ChangeMonitoring;
