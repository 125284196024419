import * as utils from '../../../../utils';

export const validationfields = {
  minUsageFee: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  minBalanceRequired: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  managementFee: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,

  banking: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  creditCards: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  investments: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  mortgage: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  businesses: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  creditReporting: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  crypto: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  insurance: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  loans: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  realEstate: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  taxServices: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
  planning: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT,
};
