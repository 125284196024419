import React from 'react';
import { useEditCommissions } from '../../../hooks';
import * as Styled from '../styles';

export const ReferralStructure = ({ hook }: {hook: ReturnType<typeof useEditCommissions>}) => {
  if (!hook.hookCommissionData?.commissionStructure) return <p />;
  const { referral } = hook.hookCommissionData.commissionStructure;

  const tierRows = referral.levels.map((x, i) => (
    <Styled.TierItemStyled key={`tierRow${i}`}>
      <Styled.InputTextStyled
        type="text"
        value={x}
        label={`Level ${i + 1} Referral %`}
        theme="readonly"
        readonly
      />
    </Styled.TierItemStyled>
  ));
  return (
    <Styled.ReferralTierSectionStyled>
      { tierRows }
    </Styled.ReferralTierSectionStyled>
  );
};
