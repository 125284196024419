import { gql } from '@apollo/client';

export const LIST_COMPANIES = gql`
  query listCompanies($input: CompanyInput) {
    companies(input: $input) {
      companies {
        id
        companyName
      }
    }
  }
`;
