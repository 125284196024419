import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';

import * as Styled from './styles';

type CheckboxProps = {
  checked: boolean;
  partial?: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  theme?: Theme;
  className?: string;
  disabled?: boolean;
  tooltip?: string;
};

export const Checkbox = ({
  checked,
  partial = false,
  onChange,
  label,
  className,
  disabled = false,
  theme,
  tooltip,
}: CheckboxProps) => (
  <Styled.WrapperStyled className={className}>
    <Styled.IconStyled
      checked={checked || partial}
      disabled={disabled}
      theme={theme}
    >
      {checked && <FontAwesomeIcon icon={faCheck} />}
      {partial && !checked && <FontAwesomeIcon icon={faMinus} />}
    </Styled.IconStyled>

    {label && (
    <Styled.LabelStyled theme={theme}>
      {label}
      {' '}
      {tooltip && <Styled.TooltipStyled text={tooltip} />}
    </Styled.LabelStyled>
    )}

    <Styled.InputStyled
      type="checkbox"
      checked={checked}
      onChange={() => onChange(!checked)}
      disabled={disabled}
    />
  </Styled.WrapperStyled>
);
