import { gql } from '@apollo/client';

export const LIST_MERCHANTS = gql`
  query List($input: ListSearchCompanyInput) {
    listSearchMerchants(input: $input) {
      companies {
        id
        companyName
        program {
          id
        }
      }
    }
  }
`;
