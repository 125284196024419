import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as Styled from './styles';

type ErrorBannerProps = {
  message: string;
  isOpen: boolean;
  margin?: string;
}

export const ErrorBanner = ({ message = '', isOpen = false, margin = '0 0 2.5rem' }: ErrorBannerProps) => (
  <Styled.BannerWrapper>
    {isOpen && (
      <Styled.RequiredFieldsError margin={margin}>
        <FontAwesomeIcon
          icon={faTriangleExclamation}
        />
        <Styled.RequiredTextStyled>
          {message}
        </Styled.RequiredTextStyled>
      </Styled.RequiredFieldsError>
    )}
  </Styled.BannerWrapper>
);
