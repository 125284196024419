import { gql } from '@apollo/client';

export const GET_PUBLISHER_GROUPS = gql`
  query groups($programId: String) {
    groups(programId: $programId) {
      groups {
        id
        name
        memberships {
          id
          publisher {
            id
            companyName
          }
        }
      }
    }
  }
`;

export type GetPublisherGroupsInput = {
  programId: string
};

export type GPGGroupType = {
  id: string
  name: string
  memberships: {
    id: string
    publisher: {
      id: string
      companyName: string
    }
  }[]
}

export type GetPublisherGroupsOutput = {
  groups: {
    groups: GPGGroupType[]
  }
}
