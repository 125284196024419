import { gql } from '@apollo/client';

export const LIST_MERCHANTS = gql`
  query getMerchantsForReports($input: CompanyInput) {
    companyOptions(input: $input) {
      companies {
        id
        companyName
      }
    }
  }
`;

export type ListMerchantInputType = {
  input: {
    companyType: string
    accountStatus?: string
  }
};

export type ListMerchantOutputType = {
  companyOptions: {
    companies: {
      id: string
      companyName: string
    }[]
  }
}
