import styled from 'styled-components';
import { Button, InputCalendar, Select } from '../../../../../components';
import { colors, fonts } from '../../../../../styles/theme';
import { toRem } from '../../../../../utils';

export const FiltersStyled = styled.div`
  display: flex;
  gap: ${toRem(32)};
  align-items: flex-end;
  margin-top: ${toRem(40)};
  width: 60%;
`;

export const ButtonLineWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: ${toRem(32)} 0;
`;
export const RecordRowContainer = styled.div`
  display: flex;
  margin-bottom: ${toRem(36)};
  justify-content: space-between;
`;

export const FilterCalendarStyled = styled(InputCalendar)`
  width: 40%;
`;

export const FilterStatusStyled = styled(Select)`
  width: 40%;
`;

export const FilterButtonStyled = styled(Button)`
   margin-left: ${toRem(20)};
  min-width: 150px;

  svg {
    margin-right: 10px;
  }
`;

export const NoRecordsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${toRem(150)};
`;

export const NoRecordsTitleStyled = styled.div`
  ${fonts.lato.H20}
  color: ${colors.color1};
`;

export const NoRecordsDescriptionStyled = styled.div`
  ${fonts.lato.H16}
  text-align: center;
  color: ${colors.color1};
  line-height: 1.5;
  width: 25%;
  margin: ${toRem(20)} 0;
`;
