import React from 'react';
import * as Styled from './styles';
import {
  PERCENTAGE_INFRONT, DOLLAR_INFRONT, MARKETING_ITEM, LEGAL_REFERENCE, OFFER, ARRAY_VALUES,
} from './enums';
import { dateFormatter } from '../../../../../utils';

const rowFormatter = (key: any, value: any) => {
  if (key.includes('marketing') || key.includes('exclusionItems')) {
    return (
      value?.filter((str: string) => str !== '').map((item: any, index : number) => (
        <Styled.TableRow>
          <Styled.KeyStyled>
            {`${MARKETING_ITEM} ${index + 1}`}
          </Styled.KeyStyled>
          <Styled.ValueStyled>
            {item}
          </Styled.ValueStyled>
        </Styled.TableRow>
      ))
    );
  }

  if (key.includes('legalReferenceItems')) {
    return (
      value.map((item: any, index: number) => (
        <Styled.TableRow>
          <Styled.KeyStyled>
            { `${LEGAL_REFERENCE} ${index + 1}` }
          </Styled.KeyStyled>
          <Styled.ValueStyled>
            <Styled.BoldValueStyled>{`${item.name}`}</Styled.BoldValueStyled>
            <p>{`${item.description}`}</p>
          </Styled.ValueStyled>
        </Styled.TableRow>
      ))
    );
  }

  if (key.includes('legalReference')) {
    return;
  }

  if (ARRAY_VALUES.includes(key)) {
    return (
      <Styled.TableRow>
        <Styled.KeyStyled>
          {key.replace(/([a-z])([A-Z])/g, '$1 $2')}
        </Styled.KeyStyled>
        <Styled.ValueStyled>
          {value.join(', ')}
        </Styled.ValueStyled>
      </Styled.TableRow>
    );
  }

  if (key === 'validUntil') {
    return (
      <Styled.TableRow>
        <Styled.KeyStyled>
          {OFFER}
        </Styled.KeyStyled>
        <Styled.ValueStyled>
          {dateFormatter(new Date(value))}
        </Styled.ValueStyled>
      </Styled.TableRow>
    );
  }

  let val = value;

  if (typeof value === 'boolean') {
    val = value ? 'Yes' : ' No';
  }

  let sign = '';
  switch (true) {
    case PERCENTAGE_INFRONT.includes(key):
      sign = '%';
      break;
    case DOLLAR_INFRONT.includes(key):
      sign = '$';
      break;
    default:
      break;
  }
  return (
    <Styled.TableRow>
      <Styled.KeyStyled>
        {key.replace(/([a-z])([A-Z])/g, '$1 $2')}
      </Styled.KeyStyled>
      <Styled.ValueStyled>
        {sign === '%' ? `${val}${sign}` : `${sign}${val}`}
      </Styled.ValueStyled>
    </Styled.TableRow>
  );
};

export const VerticalTable = (values: any) => (
  <Styled.TableWrapper>
    {values?.filter(([_, value]: [any, any]) => value !== null && value.length !== 0).map(([key, value]: [any, any]) => (
      rowFormatter(key, value)
    ))}
  </Styled.TableWrapper>
);
