import { ChangeEvent, useState } from 'react';
import { useModal } from '../../../../../../hooks';
import {
  SAVE_REPORT_PROFILE_PERMISSIONS_OPTIONS, SAVE_REPORT_PROFILE_SCHEDULE_FREQUENCY_OPTIONS, SAVE_REPORT_PROFILE_SCHEDULE_MONTH_OPTIONS, SAVE_REPORT_PROFILE_SCHEDULE_WEEK_OPTIONS,
} from '../enums';

type SaveReportProfileProps = {
  onSave: (profile: any) => void;
  isUpdate: boolean
};

export const useSaveReportProfile = ({ onSave, isUpdate }: SaveReportProfileProps) => {
  const [stepOneState, setStepOneState] = useModal(false);
  const [stepTwoState, setStepTwoState] = useModal(false);
  const [updateReport, setUpdateReport] = useModal(false);
  const [reportName, setReportName] = useState('');
  const [reportDescription, setReportDescription] = useState('');
  const [reportPermissionOptions] = useState(SAVE_REPORT_PROFILE_PERMISSIONS_OPTIONS);
  const [reportPermission, setReportPermission] = useState(SAVE_REPORT_PROFILE_PERMISSIONS_OPTIONS[0]);
  const [reportScheduleFrequencyOptions] = useState(SAVE_REPORT_PROFILE_SCHEDULE_FREQUENCY_OPTIONS);
  const [reportScheduleFrequency, setReportScheduleFrequency] = useState(SAVE_REPORT_PROFILE_SCHEDULE_FREQUENCY_OPTIONS[0]);
  const [reportScheduleDetailedFrequency, setReportScheduleDetailedFrequency] = useState<SelectOption | undefined>(undefined);

  const setCloseAndCleanHandler = (stepOne: boolean) => {
    if (stepOne) {
      if (isUpdate) setUpdateReport();
      else setStepOneState();
    } else {
      setStepTwoState();
    }

    setReportName('');
    setReportDescription('');
    setReportPermission(SAVE_REPORT_PROFILE_PERMISSIONS_OPTIONS[0]);
  };

  const setStepOneStateHandler = () => {
    if (isUpdate) setUpdateReport();
    else setStepOneState();
  };

  const setStepTwoStateHandler = () => {
    setStepTwoState();
  };

  const setChangeStepHandler = () => {
    setStepOneState();
    setStepTwoState();
  };

  const setReportNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setReportName(event.target.value);
  };

  const setReportDescriptionHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReportDescription(event.target.value);
  };

  const setReportPermissionHandler = (value: any) => {
    setReportPermission(value);
  };

  const setReportScheduleDetailedFrequencyHandler = (value: any) => {
    setReportScheduleDetailedFrequency(value);
  };

  const setReportScheduleFrequencyHandler = (value: any) => {
    setReportScheduleFrequency(value);

    if (value.value === 'Weekly') {
      setReportScheduleDetailedFrequency(SAVE_REPORT_PROFILE_SCHEDULE_WEEK_OPTIONS[0]);
    }

    if (value.value === 'Monthly') {
      setReportScheduleDetailedFrequency(SAVE_REPORT_PROFILE_SCHEDULE_MONTH_OPTIONS[0]);
    }
  };

  const setSaveReportHandler = () => {
    onSave({
      name: reportName,
      description: reportDescription,
      permission: reportPermission,
      schedule: {
        frequency: reportScheduleFrequency.value,
        detailedFrequency: reportScheduleDetailedFrequency?.value,
      },
    });

    setStepTwoState();
    setReportName('');
    setReportDescription('');
    setReportPermission(SAVE_REPORT_PROFILE_PERMISSIONS_OPTIONS[0]);
    setReportScheduleFrequency(SAVE_REPORT_PROFILE_SCHEDULE_FREQUENCY_OPTIONS[0]);
    setReportScheduleDetailedFrequency(undefined);
  };

  return {
    hookStepOne: stepOneState,
    hookStepTwo: stepTwoState,
    hookUpdateReport: updateReport,
    hookSetStepOne: setStepOneStateHandler,
    hookSetStepTwo: setStepTwoStateHandler,
    hookSetChangeStep: setChangeStepHandler,
    hookReportName: reportName,
    hookReportDescription: reportDescription,
    hookReportPermission: reportPermission,
    hookSetReportName: setReportNameHandler,
    hookSetReportDescription: setReportDescriptionHandler,
    hookSetReportPermission: setReportPermissionHandler,
    hookSetCloseAndClean: setCloseAndCleanHandler,
    hookReportPermissionOptions: reportPermissionOptions,
    hookReportScheduleFrequencyOptions: reportScheduleFrequencyOptions,
    hookReportScheduleFrequency: reportScheduleFrequency,
    hookSetReportScheduleFrequency: setReportScheduleFrequencyHandler,
    hookReportScheduleDetailedFrequency: reportScheduleDetailedFrequency,
    hookSetReportScheduleDetailedFrequency: setReportScheduleDetailedFrequencyHandler,
    hookSetSaveReport: setSaveReportHandler,
  };
};
