import { gql } from '@apollo/client';

export const GET_COMPANY_USER = gql`
  query users($userType: String, $companyId: String) {
    users (userType:$userType, companyId:$companyId){
      users{
        id
        firstName
        lastName
        lastLogin
        status
        position
        auth0Id
        auth0Ids
        email
        roles {
          name
          id
        }
        newRoles {
          name
          id
          oldId
        }
      }
    }
  }
  `;
