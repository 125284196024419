import { gql } from '@apollo/client';

export const UPDATE_POSTBACK = gql`
  mutation updatePostback($input: PostbackInput) {
    updatePostback(input: $input) {
      postback {
        id
        companyId
        targetId
        target {
          companyName
        }
        targetType
        eventType
        url
        queryString
        disabled
        createdAt
      }
    }
  }
`;
