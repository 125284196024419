import styled from 'styled-components';
import { fonts, colors } from '../../styles/theme';

type WrapperProps = {
  readonly display?: string;
}

export const WrapperStyled = styled.div<WrapperProps>`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0;
  ${fonts.lato.H18}
  color: ${colors.color1};

  img {
    width: 180px;
  }

  span {
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }
`;

export const TextStyled = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

export const SubtextStyled = styled.p`
  ${fonts.lato.H14}
  color: ${colors.color1};
  margin-bottom: 3rem;
`;
