import { gql } from '@apollo/client';

export const LIST_NEW_PROGRAMS = gql`
  query listPublisherNewPrograms($input: ListSearchProgramInput!) {
    listPublisherNewPrograms(input: $input) {
      newPrograms {
        id
        company {
          companyName
          companyUrl
          companyImgUrl
        }
        productCategories
        targetMarkets
      }
    }
  }
`;
