import { gql } from '@apollo/client';

export const LIST_USER_ROLE_TYPES = gql`
  query listUserTypeRoles($type: RoleType) {
    newRolesByType(type: $type) {
      id
      name
    }
  }
`;
