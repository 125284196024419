import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query GetProducts($programId: String) {
    products(programId: $programId) {
      products {
        id
        name
        status
        productCategory
      }
    }
  }
`;
