import React from 'react';
import { faCheckCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { LMRRow } from '../graphql/queries';
import * as Styled from './styles';
import { moneyFormater } from '../../../Reports/PerfomanceReport/utils';
import { imageList } from '../../../../utils';

const formatItemWithIconRightLink = (val: string, isNew: boolean, link: string) => (
  <Styled.ItemContainer>
    <Styled.CellStyled>
      <Styled.CellLinkStyled
        target="_blank"
        href={link}
      >
        {val}
      </Styled.CellLinkStyled>
    </Styled.CellStyled>
    {isNew && (
    <Styled.NewImage
      src={imageList.newIcon.src}
      alt={imageList.newIcon.alt}
    />
    )}
  </Styled.ItemContainer>
);

const formatItemWithIconLeftLink = (val: string, parentUrl: string) => (
  <Styled.ItemContainer>
    {parentUrl
      && parentUrl.includes('https://') && val
      && (val && val.includes(parentUrl) ? (
        <Styled.ColoredIcon
          icon={faCheckCircle}
          $iconColor="green"
        />
      ) : (
        <Styled.ColoredIcon
          icon={faMinusCircle}
          $iconColor="red"
        />
      ))}

    <Styled.CellStyled>
      <Styled.CellLinkStyled
        target="_blank"
        href={val}
      >
        {val}
      </Styled.CellLinkStyled>
    </Styled.CellStyled>
  </Styled.ItemContainer>
);

export const linkManagerColumns = (userType: UserStringType): TableColumn[] => {
  const merchantColumn: TableColumn[] = [
    {
      dataField: 'merchantId',
      text: 'Merchant',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (_: any, row: LMRRow) => (
        <Styled.CellStyled>
          <Styled.CellSpan>{`${row.merchantId} - ${row.merchantName}`}</Styled.CellSpan>
        </Styled.CellStyled>
      ),
      width: '250px',
    },
  ];
  const publisherColumn: TableColumn[] = [
    {
      dataField: 'publisherId',
      text: 'Publisher',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (_:any, row: LMRRow) => (
        <Styled.CellStyled>
          <Styled.CellSpan>
            {`${row.publisherId} - ${row.publisherName}`}
          </Styled.CellSpan>
        </Styled.CellStyled>
      ),
      width: '250px',
    },
  ];
  const globalColumns: TableColumn[] = [
    {
      dataField: 'trackingProfileId',
      text: 'Tracking Profile',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (_:any, row: LMRRow) => formatItemWithIconRightLink(`${row.trackingProfileId} - ${row.trackingProfileUrl}`, row.newToSystem || false, row.trackingProfileUrl ?? ''),
      width: '230px',
    },
    {
      dataField: 'referralUrl',
      text: 'Referral Page',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any, row: LMRRow) => formatItemWithIconLeftLink(value, row.trackingProfileUrl ?? ''),
      width: '245px',
    },
    {
      dataField: 'pageType',
      text: 'Page Type',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (_:any, row: LMRRow) => (
        <Styled.CellStyled>
          <Styled.CellSpan>{row.pageType}</Styled.CellSpan>
        </Styled.CellStyled>
      ),
      width: '150px',
    },
    {
      dataField: 'adId',
      text: 'Ad Id',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (_:any, row: LMRRow) => (
        <Styled.CellStyled>
          <Styled.CellSpan>{row.adId}</Styled.CellSpan>
        </Styled.CellStyled>
      ),
      width: '120px',
    },
    {
      dataField: 'productName',
      text: 'Product',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (_:any, row: LMRRow) => (
        <Styled.CellStyled>
          <Styled.CellSpan>{row.customizedProductId ? `${row.customizedProductId} - ${row.productName}` : ''}</Styled.CellSpan>
        </Styled.CellStyled>
      ),
      width: '230px',
    },
    {
      dataField: 'impressions',
      text: 'Impression Count',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => (
        <Styled.CellStyled>
          {value}
        </Styled.CellStyled>
      ),
      width: '180px',
    },
    {
      dataField: 'uniqueImpressions',
      text: 'Impression Count Unique',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => (
        <Styled.CellStyled>
          {value}
        </Styled.CellStyled>
      ),
      width: '120px',
    },
    {
      dataField: 'clicks',
      text: 'Click Count',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => (
        <Styled.CellStyled>
          {value}
        </Styled.CellStyled>
      ),
      width: '130px',
    },
    {
      dataField: 'uniqueClicks',
      text: 'Click Count Unique',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => (
        <Styled.CellStyled>
          {value}
        </Styled.CellStyled>
      ),
      width: '130px',
    },
    {
      dataField: 'RPI',
      text: 'RPI',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => (
        <Styled.CellStyled>
          {moneyFormater.format(value)}
        </Styled.CellStyled>
      ),
      width: '90px',
    },
    {
      dataField: 'CTR',
      text: 'CTR',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => (
        <Styled.CellStyled>
          {`${value.toFixed(2)}%`}
        </Styled.CellStyled>
      ),
      width: '90px',
    },
    {
      dataField: 'totalTransactions',
      text: 'Total Transactions',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => (
        <Styled.CellStyled>
          {value}
        </Styled.CellStyled>
      ),
      width: '130px',
    },
    {
      dataField: 'approvedTransactions',
      text: 'Approved Transactions',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => (
        <Styled.CellStyled>
          {value}
        </Styled.CellStyled>
      ),
      width: '130px',
    },
    {
      dataField: 'totalCommissions',
      text: 'Total Commissions',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => (
        <Styled.CellStyled>
          {moneyFormater.format(value)}
        </Styled.CellStyled>
      ),
      width: '120px',
    },
  ];

  switch (userType) {
    case 'Admin':
      return [...merchantColumn, ...publisherColumn, ...globalColumns];
    case 'Merchant':
      return [...publisherColumn, ...globalColumns];
    case 'Publisher':
      return [...merchantColumn, ...globalColumns];
    default:
      return [...merchantColumn, ...publisherColumn, ...globalColumns];
  }
};
