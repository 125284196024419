import styled from 'styled-components';
import { DragTable, Select } from '../../../components';

type GridProps = {
  columns: number
}

export const HeaderStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderInnerStyled = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  gap: 1rem;
`;

export const GridStyled = styled.div<GridProps>`
  margin: 3rem 0;
  width: 100%;
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  gap: 2rem;
`;

export const CheckBoxGridStyled = styled.div`
  margin: 3rem 0;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(13, 1fr);
  grid-auto-flow: column;
  gap: 0.5rem;
`;

export const PermissionStyled = styled(Select)`
  grid-column: 1;
`;

export const TableStyled = styled(DragTable)`
  grid-column: span 2;
`;
