import styled from 'styled-components';
import { colors } from '../../../../../styles/theme';
import { Button } from '../../../../../components';

export const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const OverflowStyled = styled.span`
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NameCellStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  `;

export const URLStyled = styled.span`
  color: ${colors.color7};
  padding-top: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ButtonStyled = styled(Button)`
  color: ${colors.color2};
  padding: 0;
  text-decoration: none;
`;
