import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { GET_PROCESSED_PAYMENTS, LIST_PUBLISHERS } from '../Tabs/PendingPayments/graphql/queries';
import { monthNames, path, csvGenerator } from '../../../../utils';
import { MerchantPublisherOptionsMaker } from '../../../../utils/MerchantPublisherOptionsMaker';
import {
  AMOUNTS, CAN_CHECKED, NO_MONEY, PENDING_APPROVAL, PENDING_PAYMENT,
} from '../enums';
import { APPROVE_PAYMENTS } from '../Tabs/PendingPayments/graphql/mutation';
import { useUserInfo } from '../../../../hooks';
import { UPDATE_PAYMENTS } from '../Tabs/PendingPayments/graphql/mutation/UpdatePayments';
import { PAYOUT } from '../Tabs/PendingPayments/graphql/mutation/payoutPublishers';
import { GET_PAYMENT_CSV } from '../../PaymentReport/graphql/queries/getPaymentCsv';
import { Permission } from '../../../../entities';

export const usePendingPayments = (permissionsCodeList: string[] = []) => {
  const defaultPublisherAccountOption = {
    label: 'All Publishers',
    value: '',
  };

  const defaultCurrencyOption = {
    label: 'All Currency',
    value: 'any',
  };

  const defaultPaymentMethod = {
    label: 'All Methods',
    value: 'any',
  };

  const defaultRecordSelected = {
    label: '10',
    value: '0',
  };

  const defaultStatusOption = { label: 'All Statuses', value: 'any' };

  const user = useUserInfo();

  const currentDate = new Date();
  const previousM = ((moment(currentDate).subtract(1, 'months').toDate())
    .getMonth());
  const previousY = (moment(currentDate).subtract(1, 'months').toDate())
    .getFullYear();
  const currentM = ((moment(currentDate).toDate()).getMonth());
  const currentY = (moment(currentDate).toDate()).getFullYear();
  const [previousMonth, setPreviousMonth] = useState(monthNames[previousM]);
  const [previousMonthString, setPreviousMonthString] = useState(previousM.toString());
  const [currentYear, setCurrentYear] = useState(currentY);
  const [previousYear, setPreviousYear] = useState(previousY);
  const [errorMessage, setErrorMessage] = useState('');
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const [firstLoad, setFirstLoad] = useState(true);

  const [headerCheck, setHeaderCheck] = useState<boolean>(false);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [checkedObjects, setCheckedObjects] = useState<any>([]);

  const FormatPayments = (arr: any[]): any[] => arr.map((obj) => ({
    ...obj,
    checked: headerCheck,
    ...Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        AMOUNTS.includes(key) && value !== undefined ? key : key,
        AMOUNTS.includes(key) && value !== undefined ? Number(value) : value,
      ]),
    ),
  }));

  const getUniqueIds = (objs: any[]) => {
    const idSet = new Set();
    objs.forEach((obj) => {
      idSet.add(Number(obj.id));
    });
    return Array.from(idSet);
  };

  // Generate Report
  const [selectedPublisher, setSelectedPublisher] = useState<SelectOption>(defaultPublisherAccountOption);
  const [selectedCurrency, setSelectedCurrency] = useState<SelectOption>(defaultCurrencyOption);
  const [selectedPayment, setSelectedPayment] = useState<SelectOption>(defaultPaymentMethod);
  const [paymentStatus, setPaymentStatus] = useState<SelectOption>(defaultStatusOption);
  const [paymentId, setPaymentId] = useState<string>('');
  const [hasGeneratedReports, setHasGeneratedReports] = useState(false);
  const [previousStates, setPreviousStates] = useState<any>({ // This state is  only applied once generate reports is clicked
    paymentMethod: selectedPayment,
    currency: selectedCurrency,
    publisherSelected: selectedPublisher,
    statusPayment: paymentStatus,
    idPayment: paymentId,
  });

  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [recordSelected, setRecordSelected] = useState<SelectOption>(defaultRecordSelected);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const [footerData, setFooterData] = useState<any>({});

  const [getPaymentReports, { loading: paymentDataLoading }] = useLazyQuery(GET_PROCESSED_PAYMENTS);

  const checkNonEstimatedPayment = async () => {
    // when page first load, we need to check whether we have generated payment for the month before last month
    const currentMonth = currentDate.getMonth() + 1;
    const theMonthBeforeLastMonth = currentMonth <= 2 ? currentMonth + 12 - 2 : currentMonth - 2;
    const theYear = currentMonth <= 2 ? currentYear - 1 : currentYear;
    const { data } = await getPaymentReports({
      variables: {
        input: {
          month: previousM.toString(),
          year: previousY.toString(),
          paymentStatus: 'Generated',
          limit: 1,
          currentPage: 1,
          filter: '',
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.paymentsV2?.paymentReports === undefined) {
      setErrorMessage('Failed to fetch payments');
    } else if (data.paymentsV2.count > 0) {
      setPreviousMonth(monthNames[theMonthBeforeLastMonth - 1]);
      setPreviousMonthString(theMonthBeforeLastMonth.toString());
      setCurrentYear(theYear);
    }
  };
  const handleGetPaymentReports = async () => {
    const { data } = await getPaymentReports({
      variables: {
        input: {
          month: (previousM + 1).toString(),
          year: previousY.toString(),
          paymentStatus: 'Generated',
          limit: Number(recordSelected.label),
          currentPage: page,
          currency: selectedCurrency.value === 'any' ? undefined : selectedCurrency.value,
          publisherId: (selectedPublisher.value === '' || selectedPublisher.label === 'All Publishers') ? undefined : selectedPublisher.value,
          filter: paymentId,
          sortBy: sortColumn?.column,
          sortOrder: sortColumn?.direction === 'asc' ? 1 : -1,
          paymentMethod: selectedPayment.label === 'All Methods' ? undefined : selectedPayment.label,
          paymentStatusApproved: paymentStatus.value === 'any' ? undefined : paymentStatus.value,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.paymentsV2?.paymentReports === undefined) {
      setErrorMessage('Failed to fetch payments');
    } else {
      setPaymentData(FormatPayments(data.paymentsV2.paymentReports));
      setTotalPages(Math.ceil(data.paymentsV2.count / Number(recordSelected.label)));
      setFooterData(data.paymentsV2.paymentReportSum);
      setCount(data.paymentsV2.count);
    }
  };

  // Generate Reports end

  // Get CSV

  const [getPaymentsCsv, { loading: getCsvLoading }] = useLazyQuery(GET_PAYMENT_CSV);

  const handleGenerateCSV = async () => {
    const { data } = await getPaymentsCsv({
      variables: {
        input: {
          filter: paymentId,
          sortOrder: sortColumn?.direction === 'asc' ? -1 : 1,
          sortBy: sortColumn?.column || 'id',
          paymentMethod: selectedPayment.label === 'All Methods' ? undefined : selectedPayment.label,
          publisherId: selectedPublisher.value === '1' ? undefined : selectedPublisher.value,
          month: previousMonthString,
          year: currentYear.toString(),
          isPublisher: false,
          paymentStatus: 'Generated',
          currency: selectedCurrency.value === 'any' ? undefined : selectedCurrency.value,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getPaymentCsv?.csv) {
      csvGenerator(data.getPaymentCsv.csv, 'Payment Report List');
    }
  };

  // Pay payments
  const [isPayDisabled, setIsPayDisabaled] = useState(true);
  const [payOpen, setPayOpen] = useState(false);
  const [modalAmounts, setModalAmounts] = useState({ CAD: NO_MONEY, USD: NO_MONEY });
  const [pay, { loading: payLoading }] = useMutation(PAYOUT);
  const [payInputs, setPayInputs] = useState<any[]>([{}]);
  const [isSuccessOpen, setIsSucessOpen] = useState(false);

  const hookHandleCloseModal = () => {
    setIsSucessOpen(false);
    handleGetPaymentReports();
  };

  const CancelModal = () => {
    setPayOpen(false);
  };

  const PayModal = async () => {
    const { data } = await pay({
      variables: {
        inputs: payInputs,
      },
    });
    if (!data?.payoutToPublishers) {
      setErrorMessage('Failed to pay publishers!');
      setPayOpen(false);
    } else {
      setPayOpen(false);
      setIsSucessOpen(true);
      setErrorMessage('');
    }
  };

  const handleOpenPayModal = () => {
    setPayOpen(true);
  };

  const handleAmount = (checked: any[]) => {
    const toPay: any[] = [];
    const newAmounts: any = { CAD: NO_MONEY, USD: NO_MONEY };
    checked.forEach((obj: any) => {
      newAmounts[obj.currency] += obj.totalPayable;
      toPay.push({
        publisherId: obj.publisher.id,
        amount: obj.totalPayable.toString(),
        paymentId: obj.id,
        currency: obj.currency,
        receiverEmail: obj.paymentInfo?.accountEmail,
        month: obj.month,
        year: obj.year,
      });
    });
    setPayInputs(toPay);
    setModalAmounts(newAmounts);
  };

  // Get publishers
  const [publisherOptions, setPublisherOptions] = useState<SelectOption[]>([]);
  const [getPublishers, { loading: getPublishersLoading }] = useLazyQuery(LIST_PUBLISHERS);

  const queryPublishers = async () => {
    const { data } = await getPublishers();
    if (data?.getPublishers !== undefined) {
      setPublisherOptions(MerchantPublisherOptionsMaker(data.getPublishers, 'Publisher'));
    } else {
      setErrorMessage('Failed to fetch publisher options');
    }
  };

  // Publishers end

  // Approve payments
  const [isApproveDisabled, setISApproveDisabled] = useState<boolean>(true);
  const [approvePayments, { loading: approvePaymentsLoading }] = useMutation(APPROVE_PAYMENTS);

  const handleApprove = async () => {
    const { data } = await approvePayments({
      variables: {
        input: {
          paymentIds: getUniqueIds(checkedObjects),
          userId: user.hookWhoAmI.id,
          approveAll: headerCheck,
        },
      },
    });
    if (!data?.bulkApprovePaymentsV2) {
      setErrorMessage('Failed to approve payments');
    }
  };

  const handleApproveButton = () => {
    handleApprove();
    handleGetPaymentReports();
  };

  const handleChangeCheck = (id: any, value: any) => {
    const newObj = [...paymentData];
    const targetIndex = newObj.findIndex((obj) => obj.id === id);
    if (CAN_CHECKED.includes(newObj[targetIndex].paymentStatusApproved)) { newObj[targetIndex].checked = value; }
    setPaymentData(newObj);
  };

  const handleHeaderCheck = () => {
    const newData = paymentData.map((obj: any) => ({ ...obj, checked: !headerCheck }));
    setPaymentData(newData);
    setHeaderCheck(!headerCheck);
  };

  // Update Payments / Note modal
  const [noteModal, setNoteModal] = useState<string>('');
  const [refNumber, setRefNumber] = useState<string>('');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [addRefID, setAddRefID] = useState<string>('');
  const [updatePayment, { loading: updateLoading }] = useMutation(UPDATE_PAYMENTS);

  const handleSaveModal = async () => {
    const { data } = await updatePayment({
      variables: {
        input: {
          note: noteModal,
          id: addRefID,
          ...(refNumber !== '' && {
            referenceNumber: refNumber,
            paidByUserId: user.hookWhoAmI.id,
            paymentPaidDate: new Date().toISOString(),
            paymentStatus: 'Paid',
          }),
        },
      },
    });
    if (data?.updatePayment === null) {
      setErrorMessage('Failed to update payments');
    } else {
      setErrorMessage('');
      handleGetPaymentReports();
    }
    setIsOpenModal(false);
  };

  const handleNoteModal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteModal(e.target.value);
  };

  const handleRefNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRefNumber(e.target.value);
  };

  const handleCancelModal = () => {
    setIsOpenModal(false);
  };

  const handleOpenModal = (value: Record<string, any>) => {
    setAddRefID(value.id);
    handleChangeCheck(value.id, true);
    setNoteModal(value.note || '');
    setRefNumber(value.referenceNumber || '');
    setIsOpenModal(true);
  };

  const handleSort = (dataField: string, direction: any) => {
    if (sortColumn?.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn?.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const handleClearForm = () => {
    setSelectedCurrency(defaultCurrencyOption);
    setSelectedPayment(defaultPaymentMethod);
    setPaymentStatus(defaultStatusOption);
    setPaymentId('');
    setSelectedPublisher(defaultPublisherAccountOption);
  };

  const handleRecords = (value: SelectOption) => {
    setRecordSelected(value);
  };

  const navgiateToReport = (values: Record<string, any>) => {
    const { row } = values;
    navigate(path.paymentReportDetails.href, {
      state: {
        id: row.id,
        publisherID: row.publisher.id,
        paymentStatus: row.paymentStatus,
        paymentMethod: row.paymentMethod,
        month: row.month,
        year: row.year,
        previousPath: path.processPayment.href,
      },
    });
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  const handlePaymentStatus = (value: any) => {
    setPaymentStatus(value);
  };

  const handlePaymentId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentId(e.target.value);
  };

  useEffect(() => {
    const checkedNew = paymentData.filter((obj: any) => obj.checked);
    if (checkedNew.length === count && !firstLoad) setHeaderCheck(count !== 0);
    if (checkedNew.length !== paymentData.length) setHeaderCheck(false);
    let samePaymentStatus = false;
    if (checkedNew.length === 0) {
      samePaymentStatus = false;
    } else {
      samePaymentStatus = checkedNew.every((obj: any) => obj.paymentStatusApproved === checkedNew[0]?.paymentStatusApproved);
    }
    if (samePaymentStatus) {
      if (checkedNew[0]?.paymentStatusApproved === PENDING_APPROVAL) {
        setISApproveDisabled(false);
      } else if (checkedNew[0]?.paymentStatusApproved === PENDING_PAYMENT) {
        const allPaypal = checkedNew.every((obj: any) => obj.paymentMethod === 'Paypal');
        if (allPaypal) {
          setIsPayDisabaled(false);
          handleAmount(checkedNew);
        } else {
          setIsPayDisabaled(true);
        }
      }
    } else {
      setISApproveDisabled(true);
      setIsPayDisabaled(true);
    }
    setCheckedObjects(checkedNew);
  }, [paymentData]);

  const setSelectedPublisherHandler = (selectPublisher: SelectOption) => {
    setSelectedPublisher(selectPublisher);
  };

  const setSelectedCurrencyHandler = (selectCurrency: SelectOption) => {
    setSelectedCurrency(selectCurrency);
  };

  const setSelectedPaymentHandler = (selectPayment: SelectOption) => {
    setSelectedPayment(selectPayment);
  };

  useEffect(() => {
    queryPublishers();
    checkNonEstimatedPayment();
  }, []);

  const handleGenerated = () => {
    setPage(1);
    setPreviousStates({
      paymentMethod: selectedPayment,
      currency: selectedCurrency,
      publisherSelected: selectedPublisher,
      statusPayment: paymentStatus,
      idPayment: paymentId,
    });
    setHasGeneratedReports(true);
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      setPage(1);
      handleGetPaymentReports();
    }
  }, [recordSelected, sortColumn]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      handleGetPaymentReports();
    }
  }, [page, previousStates]);

  useEffect(() => {
    if (hasGeneratedReports) {
      setPage(1);
      handleGetPaymentReports();
    }
  }, [selectedPublisher, selectedCurrency, selectedPayment, paymentStatus, paymentId]);

  return {
    hookErrorMessage: errorMessage,

    hookRecordsSelected: recordSelected,

    hookPage: page,

    hookTotalPages: totalPages,

    hookPaymentData: paymentData,

    hookHeaderCheckBox: headerCheck,

    hookSelectedPublisher: selectedPublisher,

    hookPublisherOptions: publisherOptions,

    hookPublishersLoading: getPublishersLoading,

    hookPaymentLoading: paymentDataLoading,

    hookSelectedPayment: selectedPayment,

    hookSelectedCurrency: selectedCurrency,

    hookPaymentStatus: paymentStatus,

    hookPaymentId: paymentId,

    hookModalRefNumber: refNumber,

    hookNoteModal: noteModal,

    hookIsModalOpen: isOpenModal,

    hookCurrentYear: currentYear,

    hookPreviousYear: previousYear,

    hookPreviousMonth: previousMonth,

    hookFooterData: footerData,

    hookSortColumn: sortColumn,

    hookCannotApprove: isApproveDisabled,

    hookCannotPay: isPayDisabled,

    hookApproveLoading: approvePaymentsLoading,

    handleSaveModalLoading: updateLoading,

    hookCount: count,

    hookPayOpen: payOpen,

    hookAmounts: modalAmounts,

    hookPayLoading: payLoading,

    hookSuccessOpen: isSuccessOpen,

    hookCsvLoading: getCsvLoading,

    hookCloseSucess: hookHandleCloseModal,

    hookHandleGenerateCsv: handleGenerateCSV,

    hookHandleCloseModal: handleCancelModal,

    hookHandlePayModal: PayModal,

    hookHandleGenerate: handleGenerated,

    hookChangePage: handleChangePage,

    hookHandleArrow: navgiateToReport,

    hookHandleSetRecords: handleRecords,

    hookHandleSaveModal: handleSaveModal,

    hookClosePayModal: CancelModal,

    hookHandleNoteModal: handleNoteModal,

    hookHandleRefNumber: handleRefNumber,

    hookHandleCheckBox: handleChangeCheck,

    hookSetSelectedPublisher: setSelectedPublisherHandler,

    hookSetSelectedCurrency: setSelectedCurrencyHandler,

    hookSetSelectedPayment: setSelectedPaymentHandler,

    hookHandleApproveButton: handleApproveButton,

    hookHandleHeaderCheck: handleHeaderCheck,

    hookHandleSetPaymentStatus: handlePaymentStatus,

    hookHandlePaymentId: handlePaymentId,

    hookHandleOpenModal: handleOpenModal,

    hookHandleClearForm: handleClearForm,

    hookOnSort: handleSort,

    hookOpenPayModal: handleOpenPayModal,

    hookGenerateReportClicked: hasGeneratedReports,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
