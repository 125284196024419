import { gql } from '@apollo/client';

export const CREATE_PUBLISHER = gql`
  mutation createNewPublisher($input: PublisherUserInput) {
    createNewPublisher(input: $input) {
      publisher {
        companyId
        userId
        userEmail
      }
    }
  }
`;
