import { gql } from '@apollo/client';

export const SHOW_MERCHANT = gql`
  query ShowMerchant($input: ShowMerchantInput) {
    showMerchant(input: $input) {
      programs {
        id
        campaigns {
          id
          name
        }
        ads {
          name
          id
        }
        productCategories {
          id
          name
        }
        products {
          id
          name
          customId
        }
        publisherGroups {
          id
          name
        }
        publishers {
          id
          name
          trackingProfiles {
            id
            url
            publishersId
          }
        }
      }
    }
  }
`;
