export const PAGE_TITLE = 'Create Campaign';
export const BANNER_MESSAGE = 'Please check the required fields in all tabs';

export const BUTTON_LABEL = {
  CANCEL: 'Cancel',
  SAVE: 'Save',
};

export const TAB_NAMES = {
  DETAILS: 'Details',
  TAG_GROUP: 'Tag/Group',
};

export const LABELS = {
  CAMPAIGN_NAME: 'Campaign Name',
  STATUS: 'Status',
  DESCRIPTION: 'Description',
  START_DATE: 'Campaign Start Date',
  END_DATE: 'Campaign End date',
  TAGS: 'Tags',
  PRODUCT: 'Product',
  DEFAULT_URL: 'Default Landing Page Url',
  LANGUAGE: 'Language',
  THIRD_PARTY_URL: '3rd Party Tracker Url',
  PUBLISHER_GROUPS: 'PUBLISHER GROUPS',
};

export const PLACEHOLDERS = {
  DATE: 'Select date',
  TAGS: 'Select tags',
  PRODUCT: 'Select product',
};
