import { gql } from '@apollo/client';

export const GET_PUBLISHER_PRODUCTS = gql`
  query getPublisherProducts($input: PublisherProductsInput!) {
    getPublisherProducts(input: $input) {
      products {
        id
        name
        productCategory
      }
    }
  }
`;

export type PublisherProductsInput = {
  input: {
    publisherId: string
    status: string
  }
};

export type PublisherProductsType = {
  id: string
  name: string
  productCategory: string
}

export type PublisherProductsOutput = {
  products: {
    products: PublisherProductsType[]
  }
};
