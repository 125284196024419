export const VERIFY_MESSAGE = {
  TITLE: 'Verify your email address',
  PART1: 'We have sent email to ',
  PART2: ' to confirm the validity of your email address. After receiving the email, please verify your email address by clicking the link',
};
export const EMAIL_RESENT_MESSAGE = {
  TITLE: 'Verification Email Sent',
  PART1: 'We have sent verification email to',
  PART2: '.Check your email and click the link to activate your account',
};

export const PLACEHOLDER_BACK = 'Back to Website';
export const PLACEHOLDER_RESEND = 'Resend Email';
