import styled from 'styled-components';
import { colors, fonts } from '../../../../../styles/theme';

export const WrapperStyled = styled.div`
  color: ${colors.color1};
`;

export const TitleStyled = styled.p`
  ${fonts.gotham.H22B}
  margin-bottom: 2rem;
`;

export const SubTitleStyled = styled.p`
  ${fonts.gotham.H12M}
  color: ${colors.color13};
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const FlexStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const DescriptionStyled = styled.p`
  ${fonts.lato.H16}
  line-height: 1.75;
  margin-top: 5.5px;
  margin-bottom: 1rem;

  strong {
    font-weight: bold;
    color: ${colors.color5};
  }

  p {
    padding-bottom: 1rem;
  }
`;
