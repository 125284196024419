import styled from 'styled-components';
import { Button, Select } from '../../../../../../components';
import { toRem } from '../../../../../../utils';

export const WrapperStyled = styled.div`
  margin-top: 2rem;
`;

export const ButtonStyled = styled(Button)`
  margin-top: 20px;

  svg {
    margin-right: 0.5rem;
  }
`;

export const SelectStyled = styled(Select)`
  margin-right: ${toRem(20)};
  width: 43%;
`;

export const FilterRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;
