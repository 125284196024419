import styled from 'styled-components';
import { toRem } from '../../../../utils';
import { colors, fonts } from '../../../../styles/theme';

type TextStyledProps = {
  readonly theme?: string
}

type CellWrapperStyledProps = {
  readonly theme?: string
}

export const CellWrapperStyled = styled.div<CellWrapperStyledProps>`
  height: 100%;
  min-height: ${({ theme }) => (theme === 'checkBox' ? 'auto' : toRem(38))};
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: ${({ theme }) => (theme === 'checkBox' ? 'row' : 'column')};
  text-overflow: ellipsis;
  line-height: 1.5;
`;

export const TextStyled = styled.p<TextStyledProps>`
  ${({ theme }) => (theme === 'main' ? fonts.lato.H14B : fonts.lato.H14)};
  color: ${({ theme }) => (theme === 'main' ? colors.color1 : colors.color13)};
  max-width: 100%;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CellStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
`;
