import React, { useEffect } from 'react';
import { Pagination, Table } from '../../../../../../components';
import { DomainEmptyState } from '../../components';
import { domainColumns, TAB_DESCRIPTION, TAB_TYPES } from '../../contracts';
import * as Styled from './styles';

type TrackingDomainsTabProps = {
  data: PublisherDetailsTrackingDomains[]
  getTrackingDomains: () => void
  pageChangeHandler: (page: number, type: string, loading?: boolean) => void
  totalPage: number
  currentPage: number
  sortHandler: (column: string, direction: 'asc' | 'desc' | undefined) => void
  sortColumn: TableSortColumn
  isLoading: boolean
}

const TrackingDomainsTab = ({
  data, getTrackingDomains, pageChangeHandler, currentPage, isLoading, totalPage, sortHandler,
  sortColumn,
}: TrackingDomainsTabProps) => {
  useEffect(() => {
    getTrackingDomains();
    pageChangeHandler(1, TAB_TYPES.DOMAIN, true);
  }, []);
  return (
    <Styled.WrapperStyled>
      <Styled.TitleStyled>
        {TAB_DESCRIPTION.DOMAIN}
      </Styled.TitleStyled>
      {data.length < 1 && !isLoading && (
        <DomainEmptyState />
      )}
      {data.length > 0 && (
        <>
          <Table
            data={data}
            columns={domainColumns}
            isLoading={isLoading}
            sortColumn={sortColumn}
            onSort={sortHandler}
          />
          <Pagination
            total={totalPage}
            currentPage={currentPage}
            onPageChange={(value) => pageChangeHandler(value, TAB_TYPES.DOMAIN)}

          />
        </>
      )}
    </Styled.WrapperStyled>
  );
};

export default TrackingDomainsTab;
