import { gql } from '@apollo/client';

export const LIST_PAYMENTS_METHODS = gql`
  query ListPaymentMethods {
    listPaymentMethods {
      id
      type
    }
  }
`;
