import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCT } from '../graphql/getProduct';
import { GET_ADS } from '../graphql/getAds';
import { paginator } from '../../../../../utils';
import { commissionsFormatter, commissionFilter } from '../../../../../utils/formatCommissions';
import { useUserInfo } from '../../../../../hooks';
import { GET_MEMBERSHIPS } from '../graphql/getMemeberships';

export const useProductDetails = () => {
  const [ads, setAds] = useState<any>();
  const navigate = useNavigate();
  const [product, setProduct] = useState<any>();
  const location = useLocation();
  const [getProducts, { loading: getProductsLoading }] = useLazyQuery(GET_PRODUCT);
  const [getAds, { loading: getAdsLoading }] = useLazyQuery(GET_ADS);
  const productId = location.state;
  const [errorMessage, setErrorMessage] = useState('');
  const [productFeed, setProductFeed] = useState<any>();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAdId, setModalAdId] = useState('');
  const [totalPagesAd, setTotalPagesAD] = useState(1);
  const [currentPageAD, setCurrentPageAD] = useState(1);
  const [commissions, setCommissions] = useState<any>();
  const [paginatedCom, setPaginatedCom] = useState<any>();
  const [totalPagesCom, setTotalCom] = useState(1);
  const [currentPageCom, setCurrentPageCom] = useState(1);
  const [allAds, setAllAds] = useState<any[]>();
  const userInfo = useUserInfo();

  const [getMemberships, { loading: getMembershipsLoading }] = useLazyQuery(GET_MEMBERSHIPS);

  const changePageCommissions = (value: any) => {
    setPaginatedCom(paginator(commissions, 10, value));
    setCurrentPageCom(value);
  };

  const changePageAds = (value:any) => {
    setCurrentPageAD(value);
  };

  const handleOpenModal = (ad: any) => {
    setModalAdId(ad.id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const formatAds = (input: any, productT:any) => input.map((element: any) => ({
    ...element,
    productName: productT?.name,
    productCategory: productT?.productCategory,
  }));

  const getProductFeedFields = (productFeedSection: any) => {
    const keys = Object.keys(productFeedSection);
    const keyPairs = keys.map((key) => [key, productFeedSection[key]]);
    return keyPairs;
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const getAdsHandler = async (productT: any) => {
    const { data } = await getAds({
      variables: {
        input: {
          filter: {
            publisherId: userInfo.hookWhoAmI.companyId?.toString(),
            productId,
            adStatusType: 'Active',
          },
          options: {
            page: currentPageAD,
            order: 'DESC',
            items: 4,
          },
          sortBy: 'id',
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data.listSearchAds?.ads !== undefined) {
      setAds(formatAds(data.listSearchAds.ads, productT));
      setAllAds(data.listSearchAds.ads);
      setTotalPagesAD(data.listSearchAds.totalPages);
    } else {
      setErrorMessage('Oops! Failed to fetch ads');
    }
  };

  const getMembershipsHandler = async () => {
    const { data } = await getMemberships({
      variables: {
        input: {
          publisherId: userInfo.hookWhoAmI.companyId?.toString(),
          status: 'Approved',
        },
      },
    });

    if (data?.memberships?.memberships !== undefined) {
      return data.memberships.memberships;
    }
    setErrorMessage('Failed to Fetch Memberships');
  };

  const getProductHandler = async () => {
    const { data } = await getProducts({
      variables: {
        productId,
      },
      fetchPolicy: 'no-cache',
    });

    if (data.product !== undefined) {
      setProduct(data.product);

      const memberships = await getMembershipsHandler();

      const user = {
        company: {
          memberships,
          id: userInfo.hookWhoAmI.companyId?.toString(),
        },
      };

      const commissionsNew = commissionFilter(commissionsFormatter(data.product.commissions, true), user);

      setCommissions(commissionsNew);
      setPaginatedCom(paginator(commissionsNew, 10, 1));
      setTotalCom(Math.ceil(commissionsNew.length / 10));
      setCurrentPageCom(1);
      const prodFeed = data.product.productFeedSection;
      const geoStates = data.product.targetDemographics?.geographicalStates;
      if (geoStates !== undefined && geoStates.length !== 0) {
        prodFeed.geographicalAvailability = geoStates.join(', ');
      }
      setProductFeed(getProductFeedFields(prodFeed));
      return data.product;
    }
    setErrorMessage('Oops! Failed to fetch product!');
  };

  // product feed render
  useEffect(() => {
    const fetchData = async () => {
      const productT = await getProductHandler();
      await getAdsHandler(productT);
    };
    fetchData();
  }, [currentPageAD]);
  return {

    hookHandleBackButton: handleBackButton,

    hookProduct: product,

    hookProductFeed: productFeed,

    hookErrorMessage: errorMessage,

    loading: getProductsLoading || getAdsLoading,

    hookAds: ads,

    hookModalOpen: modalOpen,

    hookModalAdId: modalAdId,

    hookTotalPages: totalPagesAd,

    hookCurrentPage: currentPageAD,

    hookPaginatedCom: paginatedCom,

    hookCurrentPageCom: currentPageCom,

    hookTotalPageCom: totalPagesCom,

    hookMembershipsLoading: getMembershipsLoading,

    hookChangePageCommissions: changePageCommissions,

    hookHandleOpenModal: handleOpenModal,

    hookHandleCloseModal: handleCloseModal,

    hookChangePageAds: changePageAds,

  };
};
