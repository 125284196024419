import styled from 'styled-components';
import { toRem } from '../../../utils';
import { fonts } from '../../../styles/theme';

export const WrapperStyled = styled.div``;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const FlexStyled = styled.span`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const FiltersStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1rem;
`;

export const FiltersRowStyled = styled.div`
  display: flex;
  width: 100%;
  gap: ${toRem(30)};
`;

export const PlaceholderStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0;
  ${fonts.lato.H18}

  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    width: 180px;
  }

  span {
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }
`;
