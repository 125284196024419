import {
  PostbackType, PostbackRuleErrors, FilterType, SelectedFilterType,
} from './types';
import { RECORDS_PER_PAGE_OPTIONS } from '../../utils/enums';

export const POSTBACK_DEFAULTS: PostbackType = {
  eventType: 'Created',
  queryString: '',
  target: null,
  targetId: null,
  url: '',
};

export const inputDefaults = {
  companyOptions: {
    value: '*',
    label: 'All Transactions',
  },
  eventType: [
    {
      value: '*',
      label: 'All Events',
    },
    {
      value: 'Created',
      label: 'Created',
    },
    {
      value: 'Approved',
      label: 'Approved',
    },
    {
      value: 'Declined',
      label: 'Declined',
    },
  ],
};

export const defaultErrors: PostbackRuleErrors = {
  eventType: '',
  targetId: '',
  url: '',
  queryString: '',
};

export const defaultFilters: FilterType = {
  createdAt: {
    start: new Date(2020, 0, 1), // Jan 1, 2020: Fintel's birthday
    end: new Date(),
  },
  companyOptions: [{ label: '-- Loading -- ', value: '' }],
  eventType: inputDefaults.eventType,
  limit: Number.parseInt(RECORDS_PER_PAGE_OPTIONS[0].value, 10),
};

export const defaultSelectedFilters: SelectedFilterType = {
  createdAt: {
    start: defaultFilters.createdAt.start,
    end: defaultFilters.createdAt.end,
  },
  dateRange: null,
  companyOptions: defaultFilters.companyOptions[0],
  eventType: defaultFilters.eventType[0],
  limit: defaultFilters.limit,
  sortBy: 'createdAt',
  sortDirection: 'desc',
  currentPage: 1,
  search: {
    label: 'search',
    value: '',
  },
};
