import { gql } from '@apollo/client';

export const LIST_USER_SUB_TYPES = gql`
  query ListUserSubType($input: ListUserSubTypeInput) {
    listUserSubType(input: $input) {
      list {
        id
        name
      }
    }
  }
`;
