import { dateFormatter } from '../../../../../../utils';

export type SummaryReportActionsProps = {
  showPlaceholder: boolean
}

type SummaryReportDropdownProps = {
  lastCheck: {
    label: any,
    value: string,
    publisherCount: number,
  }
  reportDate: SelectOption
  status: SelectOption
  publisher: SelectOption
  productCategory: SelectOption
  product: SelectOption
  records: SelectOption
}

export const SUMMARY_REPORT_ACTIONS = {
  SHOW_PLACEHOLDER: Symbol('show placeholder'),
  HIDE_PLACEHOLDER: Symbol('hide placeholder'),
};

export const SUMMARY_REPORT_DROPDOWN_ACTIONS = {
  DEFINE_REPORT_DATE: Symbol('define report date'),
  CHANGE_LAST_CHECK: Symbol('change last check'),
  CHANGE_STATUS: Symbol('change status'),
  CHANGE_PUBLISHER: Symbol('change publisher'),
  CHANGE_PRODUCT_CATEGORY: Symbol('change product category'),
  CHANGE_PRODUCT: Symbol('change product'),
  CHANGE_RECORDS: Symbol('change records'),
  CLEAR_FORM: Symbol('clear form'),
};

export const summaryReportReducer = (state: any, { type }: Pick<BaseReducerType, 'type'>) => {
  switch (type) {
    case SUMMARY_REPORT_ACTIONS.HIDE_PLACEHOLDER:
      return {
        ...state,
        showPlaceholder: false,
      };
    case SUMMARY_REPORT_ACTIONS.SHOW_PLACEHOLDER:
      return {
        ...state,
        showPlaceholder: true,
      };
    default:
      return { ...state };
  }
};

export const initialState = {
  lastCheck: { label: '', value: '', publisherCount: 0 },
  reportDate: { label: '', value: '' },
  status: { label: 'All Statuses', value: 'All Statuses' },
  publisher: { label: 'All Publishers', value: '' },
  productCategory: { label: 'All Categories', value: '' },
  product: { label: 'All Products', value: 'All Products' },
  records: { label: '10', value: '10' },
};

export const summaryReportDropdownReducer = (state: any, { type, data }: BaseReducerType): SummaryReportDropdownProps => {
  switch (type) {
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_LAST_CHECK:
      return {
        ...state,
        lastCheck: {
          label: dateFormatter(new Date(data.value)),
          value: data.value,
          publisherCount: 1,
        },
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.DEFINE_REPORT_DATE:
      return {
        ...state,
        reportDate: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_STATUS:
      return {
        ...state,
        status: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PUBLISHER:
      return {
        ...state,
        publisher: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategory: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PRODUCT:
      return {
        ...state,
        product: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_RECORDS:
      return {
        ...state,
        records: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CLEAR_FORM:
      return {
        ...state,
        ...data,
      };
    default:
      return { ...state };
  }
};
