import { gql } from '@apollo/client';

export const GET_FCHECK_SETTINGS = gql`
  query getFintelCheckSettings($input: FintelCheckSettingsInput!) {
    getFintelCheckSettings(input: $input) {
      settings {
        id
        merchantId
        blacklistDomains
      }
    }
  }
`;
