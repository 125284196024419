import { gql } from '@apollo/client';

export const LIST_SEARCH_PUBLISHER = gql`
  query SearchPublishersAsMerchant($input: ListSearchPublishersInput) {
    searchPublishersAsMerchant(input: $input) {
      count
      companies {
        membershipId
        id
        companyName
        accountStatus
        activityStatus
        audienceAges
        audienceMarkets
        avgIncomes
        avgMonthlyAudience
        creditScoreRating
        createdAt
        gender
        lastLogin
        primaryPlatformLink
        productCategories
        promotionMethods
        membershipStatus
        approvedDate
        pendingTransaction {
          id
        }
        publisherGroupIds
      }
    }
  }
`;

export type ListSearchPublshersInput = {
  input: {
    programId: string,
    sortBy: string,
    options: {
      items: number,
      order: 'ASC' | 'DESC',
      page: number
    },
    filter: {
      membershipStatus?: string,
      publisherGroupIds?: string,
      audienceMarkets?: string,
      creditScoreRating?: string,
      avgMonthlyAudience?: string,
      gender?: string,
      promotionMethods?: string,
      productCategories?: string,
      avgIncomes?: string,
      activityStatus?: string,
      audienceAges?: string,
      q?: string
    }
  }
}

export type LSPPublisherType = {
  membershipId: string
  id: string
  companyName: string | null
  accountStatus: string | null
  activityStatus: string | null
  audienceAges: string[] | null
  audienceMarkets: string[] | null
  avgIncomes: string[] | null
  avgMonthlyAudience: string[] | null
  creditScoreRating: string | null
  createdAt: string | null
  gender: string | null
  lastLogin: string | null
  primaryPlatformLink: string | null
  productCategories: string[] | null
  promotionMethods: string[] | null
  membershipStatus: string | null
  pendingTransaction: {id: string}
  approvedDate: string | null
  publisherGroupIds: string[] | null
  checked?: boolean // added after
}

export type ListSearchPublshersOutput = {
  searchPublishersAsMerchant: {
    count: number
    companies: LSPPublisherType[]
  }
}
