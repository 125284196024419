export const INVALID_USER_MESSAGES = {
  TITLE: 'Thanks for signing up to Fintel Connect, your application has been submitted for approval.',
  PART1: 'Please expect a response within 48 hours. If you have questions in the interim, contact us ',
  PART2: '.',
};

export const INVALID_COMPANY_MESSAGES = {
  TITLE: 'We are unable to grant account access with the credentials entered.',
  PART1: 'Please contact our support team ',
  PART2: ' if you believe this is an error.',
};

export const INCOMPLETE_SSO_ACCOUNT_LINK = {
  TITLE: 'It looks like you started but did not complete the process to link your Fintel Connect accounts.',
  PART1: 'Please return to the Log In page, select your preferred sign in method and select the “Link Account” option when prompted.',
  PART2: '',
};

export const INVALID_SSO_ACCOUNT_CREATION = {
  TITLE: 'It looks like you\'re attempting to log in to the Fintel Connect App for the first time - welcome! ',
  PART1: 'We require you to first complete the sign up process by clicking “Sign Up” on the Welcome Page.',
  PART2: '',
};

export const UNEXPECTED_ERROR = {
  TITLE: 'Whoops, it looks like there was an unexpected error',
  PART1: 'If you are attempting to log in, please try again. If you are still having difficulties, contact our support team ',
  PART2: '.',
};

export const EMAIL_MESSAGE = ' here';

export const PLACEHOLDER_BACK = 'Return to Welcome Page';
export const PLACEHOLDER_LOGIN = 'Return to Login Page';
export const PLACEHOLDER_SIGN_UP = 'Sign Up';

export type ErrorType = {
  TITLE: string;
  PART1: string;
  PART2: string;
}
