import React from 'react';

import {
  AppWrapper, Button, PageTitle, Tabs,
} from '../../../../components';
import { path } from '../../../../utils';
import { useManualUrls } from './hooks';
import { MANUAL_URLS } from './enums';
import FintelCheckUrlsList from './Tabs/UrlsList';
import FintelCheckUrlBulkUpload from './Tabs/BulkUpload';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type FintelCheckManualUrlsProps = DefaultPropsType;

const FintelCheckManualUrls = ({ permissionsCodeList = [] }: FintelCheckManualUrlsProps) => {
  const { hookIsReadOnlyList, ...hook } = useManualUrls(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle>{path.fintelCheckManualUrlsList.name}</PageTitle>
        <Button
          theme="secondary"
          onClick={() => hook.hookNavigate(-1)}
        >
          {MANUAL_URLS.BACK}
        </Button>
      </Styled.HeaderStyled>

      <Tabs
        tabNames={['URLs List', 'Bulk Upload']}
        elements={[
          <FintelCheckUrlsList
            isReadOnly={isReadOnly}
          />,
          <FintelCheckUrlBulkUpload
            isReadOnly={isReadOnly}
          />]}
      />
    </AppWrapper>
  );
};

export default FintelCheckManualUrls;
