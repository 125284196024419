import { gql } from '@apollo/client';

export const SHOW_COUNTRY = gql`
  query ShowGeoCountry($input: ShowGeoCountryInput) {
    showGeoCountry(input: $input) {
      id
      geoRegions {
        id
        region
      }
    }
  }
`;
