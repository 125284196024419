import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from './styles';
import { Button, Tooltip } from '../../../../../components';
import { CardProps } from './types';

export const Card = ({
  title, tooltip, description, button, link,
}: CardProps) => {
  const navigate = useNavigate();
  return (
    <Styled.WrapperStyled>
      <Styled.HeaderStyled>
        <Styled.TitleStyled>{title}</Styled.TitleStyled>
        <Tooltip text={tooltip} />
      </Styled.HeaderStyled>
      <Styled.DescriptionStyled>{description}</Styled.DescriptionStyled>
      <Button
        onClick={() => navigate(link)}
      >
        {button}
      </Button>
    </Styled.WrapperStyled>
  );
};
