import styled from 'styled-components';
import { colors, fonts } from '../../../../../../../styles/theme';

export const WrapperStyled = styled.div`
  width: 600px;
  padding: 1rem;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const ContentStyled = styled.div`
  ${fonts.lato.H16}
  color: ${colors.color1};
  line-height: 1.5;
  padding-bottom: 24px;
`;

export const BoldText = styled.span`
  ${fonts.lato.H16M}
`;

export const FooterAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  ${fonts.lato.H16}
  color: ${colors.color1};
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;
