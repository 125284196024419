import styled from 'styled-components';
import { toRem } from '../../../../utils';
import { fonts, colors } from '../../../../styles/theme';

type WrapperStyledProps = {
    theme?: string
    isLong?: boolean
    singleButton?: boolean
}

type TextStyledProps = {
    theme?: string
}

type OuterStyledProps = {
  theme?: string
}

export const ErrorDiv = styled.div`
    padding: 0 ${toRem(24)};
`;

export const OuterWrapperStyled = styled.div<OuterStyledProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    min-height: ${({ theme }) => (theme === 'BonusDetails' ? 'fit-content' : toRem(400))};
    margin: ${toRem(32)};
    min-width: ${({ theme }) => (theme === 'BonusDetails' ? toRem(600) : toRem(700))};
`;

export const WrapperStyled = styled.div<WrapperStyledProps>`
    display: flex;
    word-break: break-all;
    gap: ${toRem(16)};
    flex-direction: ${({ theme }) => (theme === 'half' ? 'column' : 'row')};
    justify-content: space-between;
    align-items: ${({ theme }) => (theme === 'half' ? 'flex-start' : 'center')};
    margin: ${({ theme }) => (theme === 'half' ? `${toRem(24)} 0` : 0)};
    color: ${colors.color1};
    min-width: ${({ theme }) => {
    switch (theme) {
      case 'half':
        return '48%';
      default:
        return 'fit-content';
    }
  }};
`;

export const TitleStyled = styled.p`
    ${fonts.gotham.H24M};
`;

export const TextStyled = styled.p<TextStyledProps>`
    ${({ theme }) => (theme === 'header' ? fonts.gotham.H12B : fonts.lato.H16)};
    color: ${({ theme }) => (theme === 'header' ? colors.color13 : colors.color1)};
    text-transform: ${({ theme }) => (theme === 'header' ? 'uppercase' : 'normal')};;
    max-width: 100%;
    margin-bottom: ${({ theme }) => (theme === 'header' ? toRem(12) : 0)};
`;
