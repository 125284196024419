export const MERCHANT_ACCESS_COMPONENT = {
  COMPONENT_TITLE: 'Merchant Access',
  COMPONENT_DESCRIPTION: 'Select the merchant(s) that this user has access to.',
  ALL_MERCHANTS_RADIO_TEXT: 'All Merchants',
  SPECIFIC_MERCHANTS_RADIO_TEXT: 'Specific Merchants',
  MERCHANT_SEARCH_PLACEHOLDER: 'Search name',
  DECHECK_ALL_BUTTON_TEXT: 'Deselect All',
  ADD_CHECKED_BUTTON_TEXT: 'Add',
  MERCHANT_ACCESS_LIST_TITLE: 'Merchant Access List',
  MERCHANT_ACCESS_LIST_NON_SELECTED_TITLE: 'No merchant added yet',
  MERCHANT_ACCESS_LIST_NON_SELECTED_TEXT: 'Once you set up your list, you will be able to see the merchants this user has access to.',
  MERCHANT_ACCESS_LIST_REMOVE_BUTTON_TEXT: 'Remove',
  MERCHANT_ACCESS_LIST_REMOVE_ALL_BUTTON_TEXT: 'Remove All',
  MODAL_TITLE: 'Add to List',
  MODAL_DESC: 'This user will now have access to the following additional selected merchants:',
  MODAL_CANCEL_BUTTON_TEXT: 'Cancel',
  MODAL_SAVE_BUTTON_TEXT: 'Save',
  MODAL_FOOTER_TEXT: 'Would you like to save this change?',
};
