import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import * as am5theme from '@amcharts/amcharts5/themes/Animated';
import * as Styled from './styles';
import { ProductsApprovalStructure } from './types';
import { colors } from '../../../../../styles/theme';
import { Spinner } from '../../../../../components';

type ProductsApprovalProps = {
  productsData: ProductsApprovalStructure[]
  loading: boolean
}

export const ProductByApproval = ({ productsData, loading }: ProductsApprovalProps) => {
  useEffect(() => {
    if (loading) return;
    if (productsData.length === 0) return;
    am5.ready(() => {
      am5.array.each(am5.registry.rootElements, (root) => {
        if (root?.dom.id === 'productAppChart') {
          root.dispose();
        }
      });
      const root = am5.Root.new('productAppChart');

      root.setThemes([am5theme.default.new(root)]);

      const chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.verticalLayout,
          innerRadius: am5.percent(40),
        }),
      );

      const series = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: 'Categories',
          valueField: 'transactions',
          categoryField: 'productCategory',
          legendLabelText: '{productCategory}',
          legendValueText: '',
        }),
      );
      if (productsData.length < 4) {
        series.get('colors')?.set('colors', [
          am5.color(colors.color21),
          am5.color(colors.color2),
          am5.color(colors.color7),
        ]);
      }
      series.data.setAll(productsData);
      series.labels.template.set('forceHidden', true);
      series.ticks.template.set('forceHidden', true);
      series.slices.template.setAll({
        tooltipText: "{category}: {valuePercentTotal.formatNumber('0.00')}%[/] ({value})",
      });

      const caption = chart.children.push(am5.Legend.new(root, {
        centerX: am5.percent(50),
        fillField: 'color',
        strokeField: 'color',
        x: am5.percent(50),
        layout: am5.GridLayout.new(root, {
          maxColumns: 3,
          fixedWidthGrid: false,
        }),
      }));
      caption.markers.template.setAll({
        width: 16,
        height: 16,
      });
      caption.labels.template.setAll({
        fontSize: 11,
        width: 40,
      });
      caption.data.setAll(series.dataItems);
    });
  }, [productsData]);

  return (
    <Styled.ChartWrapperStyled>
      {loading && (
      <Spinner
        size="1.5rem"
        theme="secondary"
      />
      )}
      {!loading && (
        productsData.length !== 0 ? (
          <Styled.ChartStyled id="productAppChart" />
        ) : (
          <>
            <Styled.ZeroStateStyled>No Data Available</Styled.ZeroStateStyled>
            <Styled.DescriptionStyled>Your top products by approved transactions will be displayed here.</Styled.DescriptionStyled>
          </>
        )
      )}
    </Styled.ChartWrapperStyled>
  );
};
