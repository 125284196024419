import React from 'react';
import { Button, ErrorBanner } from '../../../../components';
import { imageList, path } from '../../../../utils';
import Progress from '../../../../components/Progress';
import * as Styled from './styles';
import { useUserInfo } from './hooks';
import { SIGN_UP_ACTIONS } from '../../reducers';
import { USER_INFO } from './enums';
import { SIGN_UP } from '../enums';
import { useLogOut } from '../../../../hooks';

const UserInfo = () => {
  const hook = useUserInfo();
  const logout = useLogOut();

  /**
   * To avoid being stuck with the Auth0 login info, we logout the user
   */
  const handleBackToLoginPage = async () => {
    await logout.hookLogout();
  };

  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img
            src={imageList.logoFull.src}
            alt="logo"
          />
        </figure>

        <Progress step={1} />
      </Styled.LeftStyled>

      <Styled.RightStyled>
        <Styled.RightContentStyled>
          <ErrorBanner
            message={hook.hookErrorMessage}
            isOpen={!!hook.hookErrorMessage}
          />
          <h1>{SIGN_UP.TITLE}</h1>

          <span>{USER_INFO.DESCRIPTION}</span>

          <h2><pre>{`Step 1   ${path.createAccountStep1.name}`}</pre></h2>

          <Styled.FormStyled>
            <Styled.InputStyled
              label="first name"
              required
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => hook.hookHandleInputChange({ type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER, data: { userFirstName: e.target.value } })}
              value={hook.hookContextState.userFirstName}
              error={hook.hookInputErrors?.firstName}
            />
            <Styled.InputStyled
              label="last name"
              required
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => hook.hookHandleInputChange({ type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER, data: { userLastName: e.target.value } })}
              value={hook.hookContextState.userLastName}
              error={hook.hookInputErrors?.lastName}
            />
            <Styled.InputTwoColumnStyled
              label="email address"
              required
              type="email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => hook.hookHandleInputChange({ type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER, data: { userEmail: e.target.value } })}
              value={hook.hookContextState.userEmail}
              error={hook.hookEmailError}
            />
            <Styled.SelectTwoColumnStyled
              label="language"
              onChange={(e: SelectOption) => hook.hookHandleInputChange({ type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER, data: { preferredLanguage: e } })}
              selected={hook.hookContextState.preferredLanguage}
              options={hook.hookLanguageList}
            />
            <Button
              onClick={handleBackToLoginPage}
              theme="secondary"
            >
              {USER_INFO.BACK}
            </Button>
            <Button
              onClick={hook.hookNavigateNextPage}
              disabled={hook.hookCheckEmailLoading}
              loading={hook.hookCheckEmailLoading}
            >
              {USER_INFO.NEXT}
            </Button>
          </Styled.FormStyled>
        </Styled.RightContentStyled>
      </Styled.RightStyled>
    </Styled.WrapperStyled>
  );
};

export default UserInfo;
