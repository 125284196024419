import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faFile } from '@fortawesome/free-solid-svg-icons';
import { PUBLISHER_PREFIX, path } from '../../../../../utils';
import * as Styled from '../Tabs/Applications/styles';
import { Button } from '../../../../../components';
import { ManagementThumbnails } from '../../../../../components/Thumbnail';

type NameCellProps = {
  val: any
  row: any
};

type MessageProps = {
  setOpenModal: any
  value: any
}

const ImgCell = (value: any) => (
  <Styled.NameCellStyled>
    <ManagementThumbnails url={value.row} />
  </Styled.NameCellStyled>
);

const MessageCell = ({ setOpenModal, value }: MessageProps) => {
  if (value.publisherInvitation !== null) {
    return (
      <Styled.CellWrapper>
        <Button
          theme="quaternary"
          onClick={() => setOpenModal(value)}
        >
          <FontAwesomeIcon icon={faFile} />
        </Button>
      </Styled.CellWrapper>
    );
  }
  return <div />;
};

const ActionCell = ({ value }: {value: any}) => {
  const navigate = useNavigate();

  const goTo = () => {
    navigate(`${PUBLISHER_PREFIX}${path.programDetails.href}?id=${value}`);
  };
  return (
    <Styled.CellWrapper>
      <Button
        theme="quaternary"
        onClick={goTo}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </Styled.CellWrapper>
  );
};

const NameCell = ({ val, row }: NameCellProps) => (
  <Styled.NameCellStyled>
    <p>{val}</p>
    <Styled.URLStyled
      href={row?.companyUrl}
      target="_blank"
    >
      {row?.companyUrl}
    </Styled.URLStyled>
  </Styled.NameCellStyled>
);

const CellWrapper = (value:any) => (
  <Styled.WrapperStyled>
    {value}
  </Styled.WrapperStyled>
);

const StatusCellWrapper = (value:any, row:any) => {
  let formattedStatus = value;
  if (value === 'Closed') {
    formattedStatus = `Closed By ${row.statusLastUpdatedBy}`;
  }
  return (
    <Styled.WrapperStyled>
      {formattedStatus}
    </Styled.WrapperStyled>
  );
};

export const columns: TableColumn[] = [
  {
    dataField: 'companyImageUrl',
    text: '',
    width: '5%',
    formatter: (value: any) => (
      <ImgCell row={value} />
    ),
  },
  {
    dataField: 'id',
    text: 'ID',
    width: '8%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: CellWrapper,
  },
  {
    dataField: 'companyName',
    text: 'Company Name',
    width: '18%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any, row: any) => (
      <NameCell
        val={val}
        row={row}
      />
    ),
    notClickable: true,
  },
  {
    dataField: 'categories',
    text: 'Product Categories',
    width: '18%',
    formatter: (value: any) => CellWrapper(value.join(', ')),
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
  },
  {
    dataField: 'markets',
    text: 'Target Market',
    width: '12%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (value: any) => CellWrapper(value.join(', ')),
  },
  {
    dataField: 'publisherInvitation',
    text: 'Message',
    width: '10%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (_, row: any, ___, ____, _____, _______, setModalInfo) => (
      <MessageCell
        setOpenModal={setModalInfo}
        value={row}
      />
    ),
    notClickable: true,
  },
  {
    dataField: 'invited',
    text: 'Invited',
    width: '10%',
    formatter: (value: any) => CellWrapper(new Date(value).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })),
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
  },
  {
    dataField: 'status',
    text: 'Status',
    width: '14%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: StatusCellWrapper,
  },
  {
    dataField: 'programId',
    text: '',
    width: '5%',
    formatter: (value: any) => (
      <ActionCell value={value} />
    ),
  },
];
