export const BUTTON_LABEL = {
  BACK: 'Back',
};

export const SUBTITLE = {
  MERCHANT: 'Monthly Account Statement',
  PUBISHER: 'Monthly Payment Statement',
};

export const ADDRESS = {
  name: 'FINTELCONNECT TECHNOLOGIES INC.',
  address1: '1075 W Georgia St Unit #1660',
  country: 'Canada',
  city: 'Vancouver',
  state: 'British Columbia',
  zip: 'V6E 3C9',
  stateShort: 'BC',
};

export const HEADER_TAX = [
  {
    label: 'GST/HST',
    value: '77137 4881 RT0001',
  },
  {
    label: 'BC PST',
    value: 'PST-1157-3111',
  },
];

export const PLACEHOLDER_PAYEE = {
  address: ADDRESS,
  invoiceNo: '',
  invoiceDate: '',
  dueDate: '',
  invoiceTo: '',
  totalDue: '',
};

export const PLACEHOLDER_RECEIVER = {
  address: ADDRESS,
  tax: [{
    label: '',
    value: '',
  }],
};

export const BANK_ACCOUNT_INFO = {
  BANK: 'CIBC - Canadian Imperial Bank of Commerce',
  BANK_ADDRESS: '400 Burrard Street, Vancouver, BC, Canada, V6C 3A6',
  TRANSIT: '00010',
  INSTITUTION: '010',
  ACCOUNT: 8948917,
  SWIFT: 'CIBC CATT',
};

export const TAX_TYPES = ['hst', 'gst', 'pst', 'tax'];
