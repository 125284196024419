import { gql } from '@apollo/client';

export const GET_PAYMENT_REPORTS = gql`
  query PaymentsV2($input: PaymentInputV2) {
  paymentsV2(input: $input) {
    count
    paymentReportSum {
      bonusAmountCad
      bonusAmountUsd
      bonusTaxCad
      bonusTaxUsd
      commissionTaxCad
      commissionTaxUsd
      periodBalanceCad
      periodBalanceUsd
      rolloverCad
      rolloverUsd
      totalCommissionCad
      totalCommissionUsd
      totalPayableCad
      totalPayableUsd
    }
    paymentReports {
      id
      publisher {
        companyName
        id
      }
      month
      year
      paymentGeneratedDate
      paymentPaidDate
      totalCommissions
      totalTaxes
      currentRollover
      previousRollover
      bonus
      bonusTax
      paymentMethod
      currency
      totalPayable
      referenceNumber
      paymentStatus
      feasible
      note
      cpaCommission
      revShareCommission
      periodBalance
      isApproved
      approvedDate
      approvedByUserId
      paidByUserId
      createdAt
      updatedAt
      paymentInfo {
        currency
        payment
        payableTo
        accountHolder
        accountEmail
        accountPhoneNumber
        bankName
        bankAccountNumber
        bankCode
        transit
        wireTransferName
        email
        phone
        phone2
        address1
        address2
        city
        zip
        country
        state
        gst
        pst
        hst
        tax
        taxStatus
        taxVerifiedDate
        gstVerifiedDate
        pstVerifiedDate
        hstVerifiedDate
        gstStatus
        pstStatus
        hstStatus
      }
    }
  }
}
`;
