import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Styled from './styles';
import {
  AppWrapper, ErrorBanner, LoadingDots, PageTitle,
} from '../../../../../components';
import { DefaultPropsType } from '../../../../../types';
import {
  MERCHANT_PREFIX, dateFormatter, imageList, path,
} from '../../../../../utils';
import { ChangeMonitoringType } from '../graphql/queries';
import { MONITORING_CHANGE_DETAILS } from './enum';
import { useMonitoringDetails } from './hooks/useMonitoringDetails';

type ChangeMonitoringDetailsProps = DefaultPropsType

const ChangeMonitoringDetails = ({ permissionsCodeList = [] }: ChangeMonitoringDetailsProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const hook = useMonitoringDetails();
  const localStateData: ChangeMonitoringType | undefined = location.state.data;

  useEffect(() => {
    if (!localStateData) {
      navigate(`${MERCHANT_PREFIX}${path.fintelCheckChangeMonitoring.href}`);
      return;
    }
    hook.fetchPageData(localStateData.id);
  }, [localStateData]);

  if (!localStateData) {
    return null;
  }
  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        message={hook.errorMessage}
        isOpen={hook.errorMessage.length > 0}
      />

      <Styled.RowStyled>
        <Styled.HeaderStyled>

          <Styled.RowStyled>
            <PageTitle>{path.fintelCheckChangeMonitoringDetails.name}</PageTitle>
            <Styled.RunDateStyled>
              {MONITORING_CHANGE_DETAILS.RUN_DATE}
              {' '}
              {dateFormatter(new Date(localStateData.runDate))}
            </Styled.RunDateStyled>
          </Styled.RowStyled>

          <Styled.UrlLinkStyled
            href={localStateData.url}
            target="_blank"
            rel="noreferrer"
          >
            <Styled.UrlStyled>
              {localStateData.url}
            </Styled.UrlStyled>
          </Styled.UrlLinkStyled>

          <Styled.SubTitleStyled>
            {localStateData.changeSummary}
          </Styled.SubTitleStyled>

        </Styled.HeaderStyled>

        <Styled.BackButtonStyled
          type="button"
          theme="secondary"
          onClick={() => navigate(`${MERCHANT_PREFIX}${path.fintelCheckChangeMonitoring.href}`)}
        >
          {MONITORING_CHANGE_DETAILS.BACK}
        </Styled.BackButtonStyled>
      </Styled.RowStyled>

      {hook.isLoading ? (
        <LoadingDots />
      ) : (
        <Styled.DataChangesWrapper>
          <Styled.TextDiffChangesWrapperStyled>
            {hook.changedData?.snippedChanges.map((item, index) => (
              <React.Fragment key={`${item}+${index}`}>
                <Styled.TextDiffChangesContentStyled>
                  {item.deleted && item.deleted.map((deletedItem) => (
                    <pre key={deletedItem.id}>
                      <Styled.DeletedSnippedStyled>
                        {deletedItem.text.replace(/<br\s*\/?>/mg, '\n')}
                      </Styled.DeletedSnippedStyled>
                    </pre>
                  ))}
                  {item.added && item.added.map((addedItem) => (
                    <pre key={addedItem.id}>
                      <Styled.AddedSnippedStyled>
                        {addedItem.text.replace(/<br\s*\/?>/mg, '\n')}
                      </Styled.AddedSnippedStyled>
                    </pre>
                  ))}
                </Styled.TextDiffChangesContentStyled>
                {hook.changedData?.snippedChanges.length && hook.changedData?.snippedChanges.length > 0
                && index + 2 < hook.changedData?.snippedChanges.length && <Styled.SeparatorStyled />}
              </React.Fragment>
            ))}
          </Styled.TextDiffChangesWrapperStyled>
          <Styled.ImageWrapperStyled>
            {hook.changedData?.diffImage && (
              <img
                src={`data:image/jpeg;base64,${hook.changedData?.diffImage}`}
                alt="diffImage"
                style={{ height: 'inherit' }}
              />
            )}
          </Styled.ImageWrapperStyled>
        </Styled.DataChangesWrapper>
      )}

    </AppWrapper>
  );
};

export default ChangeMonitoringDetails;
