import styled from 'styled-components';
import { colors, fonts } from '../../../../../../styles/theme';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
`;

export const TitleStyled = styled.p`
  margin: 2rem 0;
  color: ${colors.color1};
  ${fonts.lato.H16M}
`;
