/* eslint-disable no-unused-expressions */
import React from 'react';
import { faCircleXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Radio } from '../../../../../components';
import * as Styled from './styles';
import { CPA_COMMISSION_BASES } from '../enums';
import { CommissionStructureProps } from '../types';
import {
  BUTTON_TEXT, COMMISSION_DEFAULT_CUSTOM, COMMISSION_LABELS, COMMISSION_STRUCTURE, RADIO_OPTIONS,
} from '../utils';

const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
  e.preventDefault();
};
export const CPACommissionStructure = ({ hook, isReadOnly }: CommissionStructureProps) => (
  <Styled.CommissionStructureWrapper isCustom={hook.hookCommissionSubType === COMMISSION_DEFAULT_CUSTOM.CUSTOM}>
    <Styled.TitleStyled>{COMMISSION_STRUCTURE}</Styled.TitleStyled>

    <Styled.RadioSectionStyled>
      {RADIO_OPTIONS.map((x, index) => (
        <Styled.RadioStyled key={index}>
          <Radio
            name="commission"
            value={index}
            label={x}
            checked={hook.hookRadioSelected === index}
            onChange={hook.hookSetRadioSelected}
            disabled={isReadOnly}
          />
        </Styled.RadioStyled>
      ))}
    </Styled.RadioSectionStyled>

    <Styled.FlexWrapper>
      <Styled.StaticFieldsStyled>
        <Styled.SelectStyled
          label={COMMISSION_LABELS.COMMISSION_BASE}
          options={CPA_COMMISSION_BASES}
          selected={hook.hookCommissionBase}
          onChange={hook.hookSetCommissionBase}
          isDisabled={isReadOnly}
        />
        <Styled.InputTextThreeCStyled
          type="number"
          value={hook.hookMinAmount}
          // eslint-disable-next-line no-unused-expressions
          // removes alphabet on first keystroke if present
          onKeyUp={(e: any) => {
            const val = e.target.value;
            if (e.target.validity.badInput && e.key !== '.') {
              e.target.value = '';
            }
          }}
          // handles the special case where value is '.1' the backspace doesn't work but this condition helps overiding it
          onKeyDown={(e: any) => {
            const val = e.target.value;
            if (e.key === 'Backspace' && val.length === 2 && val[0] === '.') {
              e.target.value = '';
            }
          }}
          label={COMMISSION_LABELS.MIN_TO_GET()}
          onChange={hook.hookSetMinAmount}
          onWheel={(e: any) => e.target.blur()}
          onBlur={hook.hookValidateStaticFields}
          error={hook.hookCommissionStructureErrors.minAmount}
          disabled={hook.hookCommissionBase.label === '' || isReadOnly}
        />
        <Styled.InputTextThreeCStyled
          type="text"
          value={hook.hookCommissionAmount}
          label={COMMISSION_LABELS.COMMISSION_AMOUNT}
          error={hook.hookCommissionStructureErrors.commissionAmount}
          onBlur={hook.hookValidateStaticFields}
          onChange={hook.hookSetCommissionAmount}
          required={hook.hookRadioSelected === 0}
          disabled={hook.hookRadioSelected !== 0 || isReadOnly}
        />
      </Styled.StaticFieldsStyled>
      {hook.hookTierList.map((item: any, index: number) => (
        <Styled.GridStyled key={index}>
          <Styled.InputTextTwoCStyled
            type="text"
            value={item.leftInput}
            error={hook.hookTierList[index].leftError}
            label={COMMISSION_LABELS.NUM_TRANSACTIONS(index + 1)}
            onChange={(e) => hook.hookSetLeftInput(e, index)}
            required={hook.hookRadioSelected !== 0}
            disabled={hook.hookRadioSelected === 0 || isReadOnly}
          />
          <Styled.InputTextTwoCStyled
            type="text"
            value={item.rightInput}
            error={hook.hookTierList[index].rightError}
            label={COMMISSION_LABELS.PER_TRANSACTION(index + 1)}
            onChange={(e) => hook.hookSetRightInput(e, index)}
            required={hook.hookRadioSelected !== 0}
            disabled={hook.hookRadioSelected === 0 || isReadOnly}
          />
          {index !== 0 && index === hook.hookTierList.length - 1 && (
          <Styled.DeleteButtonStyled
            theme="tertiary"
            onClick={() => hook.hookRemoveItem(index)}
            disabled={isReadOnly}
          >
            <FontAwesomeIcon
              icon={faCircleXmark}
              size="sm"
            />
          </Styled.DeleteButtonStyled>
          )}
          {hook.hookRadioSelected === 1 && index === hook.hookTierList.length - 1 && (
          <Styled.InputTextAfterStyled
            type="text"
            label={COMMISSION_LABELS.AFTER(hook.hookTierList.length)}
            onChange={hook.hookSetAfterLevels}
            disabled={isReadOnly}
          />
          )}
        </Styled.GridStyled>
      ))}
      <Styled.ButtonFieldStyled>
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={hook.hookAddItem}
          disabled={hook.hookRadioSelected === 0 || isReadOnly}
        >
          <FontAwesomeIcon icon={faPlus} />
          {BUTTON_TEXT.ADD_TIER}
        </Styled.ButtonStyled>
      </Styled.ButtonFieldStyled>
    </Styled.FlexWrapper>
  </Styled.CommissionStructureWrapper>
);
