import styled from 'styled-components';
import { colors, fonts } from '../../styles/theme';

type LoadReportListProps = {
  readonly isOpen: boolean;
}

export const LoadReportAreaStyled = styled.div`
  position: relative;
`;

export const LoadReportListWrapperStyled = styled.div<LoadReportListProps>`
  position: absolute;
  width: 400px;
  left: -250px;
  top: 50px;
  max-height: 400px;
  min-height: 400px;
  background: ${colors.color4};
  box-shadow: 0px 8px 22px -6px rgba(0,0,0,0.48);
  z-index: 80;
  padding: 1rem;
  border-radius: 5px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

export const LoadReportListHeaderStyled = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

export const LoadReportListHeaderTitleStyled = styled.div`
  ${fonts.lato.H16}
  color: ${colors.color1};
  margin-bottom: 1rem;
`;

export const LoadReportContentStyled = styled.div`
  max-height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const LoadReportListItemStyled = styled.button`
  width: 100%;
  padding: 0.5rem;
  border: none;
  background: transparent;
  text-align: left;
  border-bottom: 1px solid ${colors.color15};
  cursor: pointer;

  &:hover {
    background: ${colors.color6};
  }
`;

export const LoadReportListDescriptionStyled = styled.div`
  ${fonts.lato.H14}
  line-height: 1.5;
  color: ${colors.color13};
  min-height: 21px;
`;

export const LoadReportListTitleStyled = styled.div`
  ${fonts.lato.H16}
  color: ${colors.color1};
`;

export const NoRecordsFoundBlockStyled = styled.div`
  width: 100%;
  padding: 5rem 0.5rem;
  border: none;
  color: ${colors.color1};
  text-align: center;
`;

export const NoRecordsFoundTitleStyled = styled.p`
  ${fonts.lato.H14M}
  padding-bottom: 8px;
`;

export const NoRecordsFoundDescriptionStyled = styled.p`
  ${fonts.lato.H14}
`;
