import React from 'react';
import { BUTTON_LABEL, MODAL } from '../../contracts';
import { Button } from '../../../../../../components';
import * as Styled from './styles';

type ActionModalProps = {
  modalType: string
  companyName: string
  cancelHandler: () => void
  actionHandler: (type: string) => void
}

export const ActionModal = ({
  modalType, companyName, cancelHandler, actionHandler,
}: ActionModalProps) => (
  <Styled.ModalBodyStyled>
    <Styled.TitleStyled>{MODAL[modalType]?.TITLE}</Styled.TitleStyled>
    <div>
      {MODAL[modalType]?.DESCRIPTION.map((desc) => (
        <p key={`${modalType}-${desc[0].slice(0, 10)}`}>
          {desc.map((para) => (
            <React.Fragment
              key={`para-${desc.slice(0, 4)}`}
            >
              {para === '####' ? (
                <Styled.SpanStyled isBold>
                  {companyName}
                </Styled.SpanStyled>
              ) : (
                <Styled.SpanStyled>
                  {para}
                </Styled.SpanStyled>
              )}
            </React.Fragment>
          ))}
        </p>
      ))}
    </div>
    <Styled.ButtonWrapperStyled>
      <Button
        theme="octonary"
        onClick={cancelHandler}
      >
        {BUTTON_LABEL.CANCEL}
      </Button>

      <Button onClick={() => actionHandler(modalType)}>
        {MODAL[modalType]?.BUTTON}
      </Button>
    </Styled.ButtonWrapperStyled>
  </Styled.ModalBodyStyled>
);
