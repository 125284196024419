import { gql } from '@apollo/client';

export const GET_ACCOUNT_BALANCES = gql`
  query AccountBalances($input: AccountBalanceInput!) {
  accountBalances(input: $input) {
    count
    accountBalances {
      merchant {
        id
        companyName
      }
      minBalanceRequired
      productCategory
      balance
    }
  }
}
`;
