import { gql } from '@apollo/client';

export const LIST_LANGUAGES = gql`
  query languages {
    listLanguages {
      id
      language
    }
  }
`;
