import { gql } from '@apollo/client';

export const SHOW_CAMPAIGN = gql`
  query ShowCampaign($input: ShowCampaignInput) {
    showCampaign(input: $input) {
      id
      name
      description
      startDate
      endDate
      defaultLandingPage
      programId
      publisherGroupIds
      thirdPartyUrl
      tagIds
      status
      language
      productId
    }
  }
`;
