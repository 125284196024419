import { gql } from '@apollo/client';

export const GET_PUBLISHER_OVERVIEW = gql`
  query PublisherOverview($companyId: ID!) {
    company(id: $companyId) {
      id
      overview {
        businessDesc
        hasWebsite
        primaryPlatformLink
        audienceMarkets
        avgMonthlyAudience
        productCategories
        promotionMethods
      }
    }
  }
`;
