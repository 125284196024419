export const TRACKING_SETTINGS = {
  LABELS: {
    LANDING_URL: 'Ad Default Landing Page Url',
    QUERY_STRING: 'QueryString Value/Stats Relay',
    TRACKING_URL: '3rd Party Tracking URL',
    TRACKER: '3rd party tracker can be easily enabled/disabled',
    URL_FORMAT: '3rd Party URL Format',
  },
  PLACEHOLDERS: {
    LANDING_URL: 'https://www.merchantco.com/products',
    QUERY_STRING: 'example=addinfo_url&action=edit',
    TRACKING_URL: 'https://www.marketingtrack.io/merchantco',
    URL_FORMAT: 'E.g.[3rd party tracker][landing page value][stats relay value]',
  },
};
