export const useRedo = (editorRef: any) => {
  const handleRedo = () => {
    const editor = editorRef.current.getEditor();
    editor.history.redo();
  };

  return {
    hookUseRedo: handleRedo,
  };
};
