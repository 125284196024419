import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toRem } from '../../../../../../utils';
import { fonts, colors } from '../../../../../../styles/theme';

export const WrapperStyled = styled.div`
  padding: ${toRem(28)};
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: ${toRem(590)};
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonWrapperStyled = styled.div`
  margin-right: ${toRem(20)};
`;

export const DescriptionStyled = styled.p`
  text-align: center;
  margin-bottom: ${toRem(32)};
  line-height: ${toRem(22.5)};
`;

export const TitleStyled = styled.h1`
  ${fonts.gotham.H26M}
  color: ${colors.color1};
  text-align: center;
  min-height: 30px;
  margin-bottom: ${toRem(16)};
`;

export const IconStyled = styled(FontAwesomeIcon)`
  font-size: ${toRem(60)};
  margin-bottom: ${toRem(40)};
  color: ${colors.color29};
`;
