export const PENDING_TRANSACTIONS = {
  RECORD_DROPDOWN: {
    TEXT: 'Records',
    LABEL: '',
    NAME: 'status',
  },
  FILTER_ID: {
    LABEL: '',
    NAME: 'merchantTypes',
    TEXT: 'Filter',
  },
  ORDER: {
    LABEL: '',
    NAME: 'order',
  },
  MERCHANT: {
    LABEL: 'Merchant',
    NAME: 'merchantTypes',
  },
  APRROVE_MODAL: {
    TITLE: 'Approve Transaction(s)',
    DESCRIPTION: 'Please confirm approval of the following transactions: ',
    CLOSE_TEXT: 'Cancel',
    CONFIRM_TEXT: 'Approve',
  },
  DECLINE_MODAL: {
    TITLE: 'Decline Transaction(s)',
    DESCRIPTION_ONE: 'Please detail your reason for declining ',
    DESCRIPTION_TWO: ' transactions:',
    CLOSE_TEXT: 'Cancel',
    CONFIRM_TEXT: 'Decline',
  },
  FOOTER: {
    THEME: 'secondary',
  },
};
