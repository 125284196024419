import { gql } from '@apollo/client';

export const GET_FINTEL_CHECK_DETAILS = gql`
  query getFintelCheckDetails($input: FintelCheckSearchInput!) {
    fintelCheckDetails(input: $input) {
      count
      fintelChecks {
        id
        publisherId
        publisherName
        trackingProfileId
        trackingProfileUrl
        referralUrl
        productCategory
        productId
        customizedProductId
        productName
        adId
        ruleName
        productFeed
        exist
        expectedMatch
        ruleRequired
        resultFound
        status
        screenShotUrl
        screenshotName
        statusSince
        createdAt
        trackingEventDate
        feedback {
          correct
          currStatus
          inputType
          inputVal
          issue
          shouldBeStatus
        }
        eligibilityFeedback {
          correct
          currStatus
          inputType
          inputVal
          issue
          shouldBeStatus
        }
      }
    }
  }
`;
