import { useLazyQuery, useMutation } from '@apollo/client';
import { size } from 'lodash';
import {
  useEffect, useState, useMemo,
} from 'react';
import { CheckRulesOutputType, CheckMonitoringRulesOutputType } from '../../../types';
import { CHECK_UNIQUE_RULE_NAME, LIST_AVAILABLE_RULE_GROUPS } from '../graphql/queries';
import { CREATE_FINTEL_MONITORING_CHECK_RULE } from '../graphql/mutations';
import { useToast, useUserInfo } from '../../../../../../../hooks';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '../enums';
import { TOAST_ERR_MESSAGES_NO_PAGE } from '../../../../../../../utils';

export type IndexedObject = {
  [key: number]: any;
}

export type CustomTextListType = {
  id: number
  value: string | undefined
};

type CreateCheckRuleInputType = {
  merchantId: string,
  customTextList: string[],
  ruleName: string,
  ruleGroup: string,
}

type CheckRuleGroup = {
  id: number,
  groupName: string,
}
export const useAddMonitoringRuleModal = (setModalState:(state: boolean) => void, newRuleCreated:() => void) => {
  // Global Constants
  const { hookWhoAmI } = useUserInfo();
  const { hookShowToast } = useToast();
  // Overall Values
  const [currentModal, setCurrentModal] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Step One's State
  const [ruleName, setRuleName] = useState<string>('');
  const [ruleNameError, setRuleNameError] = useState<string>('');

  // Check Rule
  const [checkRuleGroupsList, setCheckRuleGroupsList] = useState<SelectOption[]>([]);
  const [selectedCheckRuleGroup, setSelectedCheckRuleGroup] = useState<SelectOption>();
  const [customTextList, setCustomTextList] = useState<CustomTextListType[]>([]);

  // Step Three's State
  const [stepTwoErrors, setStepTwoErrors] = useState<IndexedObject>({});
  const [onBlurOn, setOnBlurOn] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);
  const [canAddMoreCustomText, setCanAddMoreCustomText] = useState<boolean>(true);

  // Step Four's State (New Rule Summary)
  const [summary, setSummary] = useState<CheckRulesOutputType | undefined>();
  const [newSummary, setNewSummary] = useState<CheckMonitoringRulesOutputType | undefined>();
  const [createRuleError, setCreateRuleError] = useState<string>('');
  const [uniqueErrors, setUniqueErrors] = useState<string>('');
  // Cancel Modal State
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);

  // Queries & Mutations
  const [checkUniqueName, { loading: checkUniqueNameLoading }] = useLazyQuery(CHECK_UNIQUE_RULE_NAME, {
    fetchPolicy: 'no-cache',
  });

  const [listAvailableGroupRules] = useLazyQuery(LIST_AVAILABLE_RULE_GROUPS);

  const [createMonitoringCheckRule, { loading: createNewCheckRuleLoading }] = useMutation(CREATE_FINTEL_MONITORING_CHECK_RULE);

  /*
   * Overall Handlers
   */
  const setCurrentModalHandler = (modalNum: number) => {
    setCurrentModal(modalNum);
  };

  const getRuleGroupsValues = async () => {
    setErrorMessage('');
    const { data, error } = await listAvailableGroupRules({
      variables: {
        input: {
          merchantId: hookWhoAmI?.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (error) {
      setErrorMessage(error.message);
    }
    if (data && data.listAvailableRuleGroups.checkRuleGroups) {
      setCheckRuleGroupsList(data.listAvailableRuleGroups.checkRuleGroups.map((checkRuleGroup: CheckRuleGroup) => ({ label: checkRuleGroup.groupName, value: checkRuleGroup.id })));
    }
  };

  const cancelButtonHandler = (state: boolean) => {
    setCancelOpen(state);
  };

  const backButtonHandler = () => {
    setCurrentModalHandler(currentModal - 1);
  };

  const resetModalValues = (startPoint: number) => {
    if (startPoint < 1) {
      setRuleName('');
      setCustomTextList([]);
      setRuleNameError('');
      setErrorMessage('');
      setSelectedCheckRuleGroup({ label: '', value: '' });
    }
    if (startPoint < 2) {
      setCustomTextList([]);
    }
    if (startPoint < 4) {
      setCreateRuleError('');
    }
    if (startPoint < 5) {
      setStepTwoErrors([]);
      setOnBlurOn(false);
    }
  };

  /*
   * Cancel Modal Handlers and Logic
   */
  const exitAddRuleModalHandler = () => {
    resetModalValues(0);
    cancelButtonHandler(false);
    setModalState(false);
    setCurrentModal(1);
    setCanAddMoreCustomText(true);
  };

  /*
   * Step One Handlers & Logic
   */
  const setRuleNameHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
    setRuleNameError('');
    setRuleName(e.target.value);
  };

  const setSelectedCheckRuleGroupHandler = (newSelectedCheckRuleGroup: SelectOption) => {
    setSelectedCheckRuleGroup(newSelectedCheckRuleGroup);
    resetModalValues(2);
  };

  // continue next
  const canContinueOne = !!(ruleName && selectedCheckRuleGroup !== undefined && selectedCheckRuleGroup.value !== '');

  const navigateModalTwo = async () => {
    const { data, error } = await checkUniqueName({
      variables: {
        input: {
          merchantId: hookWhoAmI?.companyId?.toString(),
          ruleName,
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (data && data.checkUniqueRuleName.unique === true) {
      setRuleNameError('');
      setCurrentModal(2);
    } else {
      setRuleNameError(ERROR_MESSAGES.RULE_NAME_TAKEN);
      setCurrentModal(1);
    }
  };

  const canContinueTwo = Object.keys(stepTwoErrors).length < 1 && customTextList.length >= 1;

  const validateInputFields = (newCustomTextList: CustomTextListType[], sameValuesIds: number[][]) => {
    const errors: IndexedObject = {};
    const targetCustomTextList = (size(newCustomTextList) !== 0) ? newCustomTextList : customTextList;
    targetCustomTextList.forEach((r) => {
      if (!r.value) {
        errors[r.id] = ERROR_MESSAGES.MISSING_VALUE;
      }
    });
    setStepTwoErrors(errors);

    if (size(sameValuesIds) > 0) {
      sameValuesIds[0].forEach((r) => {
        errors[r] = ERROR_MESSAGES.UNIQUE_VALUE;
      });
      setStepTwoErrors(errors);
    }

    setValidate(false);
    if (Object.keys(errors).length > 0) return false;
    return true;
  };

  const addNewCustomText = () => {
    if (customTextList.length >= 4) {
      setCanAddMoreCustomText(false);
    }
    const newCustomTextList: CustomTextListType = {
      id: customTextList ? (customTextList.reduce((highest, curr) => (highest > curr.id ? highest : curr.id), 0) + 1) : 1,
      value: undefined,
    };

    setCustomTextList([...customTextList, newCustomTextList]);
    if (!validateInputFields([], [])) {
      setOnBlurOn(true);
    }
    setValidate(true);
  };

  const removeCustomTextById = (id: number) => {
    const newCustomTextListLocal = customTextList.filter((customText) => customText.id !== id);
    setCustomTextList(newCustomTextListLocal);
    setValidate(true);
    setCanAddMoreCustomText(true);

    if (newCustomTextListLocal.length >= 5) {
      setCanAddMoreCustomText(false);
    } else {
      setCanAddMoreCustomText(true);
    }
    validateInputFields(newCustomTextListLocal, []);
  };

  const updateCustomTextValue = (id: number, value: any) => {
    const errors: IndexedObject = {};
    const { inputType } = value.nativeEvent;
    const regex = /^[a-zA-Z0-9$%()+@" ]+$/;
    const regval = regex.test(value.target.value);
    if (!regval && inputType !== 'deleteContentBackward') {
      errors[id] = ERROR_MESSAGES.INVLAID_CHAR;
      setStepTwoErrors(errors);
      return;
    }

    // const isValueExist = customTextList.some((customText) => customText.value === value.target.value && customText.id !== id);

    setStepTwoErrors({});

    const newCustomTextList = customTextList.map((customText): CustomTextListType => {
      if (customText.id === id) {
        return {
          ...customText,
          value: value.target.value,
        };
      }
      return customText;
    });
    setCustomTextList(newCustomTextList);
  };

  const onBlurCheck = () => {
    if (onBlurOn) validateInputFields([], []);
  };

  function findIdsWithSameValue(data: CustomTextListType[]) {
    const valueGroups: { [key: string]: number[] } = {};
    data.forEach((item: CustomTextListType) => {
      if (item.value !== undefined) {
        if (!valueGroups[item.value || 0]) {
          valueGroups[item.value || 0] = [];
        }
        valueGroups[item.value || 0].push(item.id);
      }
    });
    const result: number[][] = Object.values(valueGroups).filter((group) => group.length > 1);
    return result;
  }
  const navigateModalThree = () => {
    const sameValuesIds: number[][] = findIdsWithSameValue(customTextList);
    if (!validateInputFields([], sameValuesIds)) {
      setOnBlurOn(true);
      return;
    }
    setUniqueErrors('');
    let ruleGroup = '';
    if (selectedCheckRuleGroup !== undefined) {
      ruleGroup = selectedCheckRuleGroup.label;
    }

    const localSummary: CheckMonitoringRulesOutputType = {
      id: 0,
      merchantId: hookWhoAmI.companyId?.toString() || '',
      ruleName,
      ruleGroup,
      customTextList: (customTextList.map((customText) => customText.value)),
    };
    setNewSummary(localSummary);
    setCurrentModalHandler(4);
  };

  /*
   * Step 4 (New Rule Summary) Handlers & Logic
   */
  const createRuleHandler = async () => {
    setCreateRuleError('');
    const newDate = new Date();
    newDate.setUTCHours(0, 0, 0, 0);
    let ruleGroup = '';
    if (selectedCheckRuleGroup !== undefined) {
      ruleGroup = selectedCheckRuleGroup.value;
    }
    const newRuleInput:CreateCheckRuleInputType = {
      merchantId: hookWhoAmI?.companyId?.toString() || '',
      customTextList: (customTextList.map((customText) => customText.value || '')),
      ruleName,
      ruleGroup,
    };

    const { errors } = await createMonitoringCheckRule({
      variables: {
        input: newRuleInput,
      },
      onError(err) {
        setCreateRuleError(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (errors) {
      setCreateRuleError(TOAST_ERR_MESSAGES_NO_PAGE(errors[0].message));
    } else {
      hookShowToast(SUCCESS_MESSAGES.CREATED_RULE_TOAST);
      exitAddRuleModalHandler();

      // Clear Cache after update as it is no longer valid.
      newRuleCreated();
    }
  };

  // Get the values for Step One dropdowns
  useEffect(() => {
    getRuleGroupsValues();
  }, []);

  return {
    // Overall Values
    hookCurrentModal: currentModal,
    hookCancelOpen: cancelOpen,
    hookSetCurrentModal: setCurrentModal,
    hookCancelButtonHandler: cancelButtonHandler,
    hookBackButtonHandler: backButtonHandler,
    hookErrorMessage: errorMessage,

    hookRuleName: ruleName,
    hookSetRuleName: setRuleNameHandler,
    hookRuleNameError: ruleNameError,

    // Check Rule Group
    checkRuleGroupsList,
    setSelectedCheckRuleGroup,
    setSelectedCheckRuleGroupHandler,
    selectedCheckRuleGroup,
    customTextList,
    setCustomTextList,
    addNewCustomText,
    newSummary,
    updateCustomTextValue,

    // Navigate
    hookCanContinueOne: canContinueOne,
    hookNavigateModalTwo: navigateModalTwo,
    hookLoadingNavigateModalTwo: checkUniqueNameLoading,

    // Navigate
    hookCanContinueTwo: canContinueTwo,
    canAddMoreCustomText,

    removeCustomTextById,
    hookStepTwoErrors: stepTwoErrors,
    hookValidateInputFields: validateInputFields,
    hookOnBlurCheck: onBlurCheck,
    // Navigate
    hookNavigateModalThree: navigateModalThree,

    // Step Four/Summary
    hookSummary: summary,
    hookCreateRuleError: createRuleError,
    hookCreateRule: createRuleHandler,
    hookCreateRuleLoading: createNewCheckRuleLoading,
    uniqueErrors,
    // Leave Rule
    hookExitAddRuleModalHandler: exitAddRuleModalHandler,
  };
};
