import React, { useEffect } from 'react';
import * as Styled from '../../styles';
import { Button, Checkbox } from '../../../../../../components';
import { SEARCH_PUBLISHER_CUSTOMIZE_COLUMNS } from '../../../../../../utils';
import { BUTTON_LABEL, MODAL_TITLE } from '../../contracts';
import { useHook } from './useHook';

type CustomizeColumnModelProps = {
  selectedColumns: string[]
  cancelHandler: () => void
  columnCustomizeHandler: (columns: string[]) => void
}

export const CustomizeColumnModel = ({
  selectedColumns, cancelHandler, columnCustomizeHandler,
}: CustomizeColumnModelProps) => {
  const hook = useHook();

  useEffect(() => {
    hook.hookCustomizeColumnsDefault(selectedColumns);
  }, [selectedColumns]);
  return (
    <Styled.ModalWrapperStyled>
      <Styled.PageTitleStyled>{MODAL_TITLE.CUSTOMIZE}</Styled.PageTitleStyled>
      {SEARCH_PUBLISHER_CUSTOMIZE_COLUMNS.map((columnGroup) => (
        <div key={columnGroup.label}>
          <Styled.ModalSubtitleStyled>{columnGroup.label}</Styled.ModalSubtitleStyled>
          <Styled.ModalCheckboxGroupStyled>
            {columnGroup.columns.map((column) => (
              <Styled.ModalCheckboxStyled key={column}>
                <Checkbox
                  checked={hook.hookCustomizeColumns.includes(column)}
                  onChange={(value: boolean) => hook.hookSetCustomizeColumns(value, column)}
                />
                {column}
              </Styled.ModalCheckboxStyled>
            ))}
          </Styled.ModalCheckboxGroupStyled>
        </div>
      ))}

      <Styled.ModalButtonAreaStyled>
        <Button
          theme="secondary"
          onClick={cancelHandler}
        >
          {BUTTON_LABEL.CANCEL}
        </Button>
        <Styled.ModalButtonStyled
          onClick={() => columnCustomizeHandler(hook.hookCustomizeColumns)}
        >
          {BUTTON_LABEL.CUSTOMIZE}
        </Styled.ModalButtonStyled>
      </Styled.ModalButtonAreaStyled>
    </Styled.ModalWrapperStyled>
  );
};
