import { gql } from '@apollo/client';

export const RULE_MANAGER_DROPDOWNS_INFO = gql`
  query ruleManagerDropdowns($input: RuleManagerDropdownsInput!) {
    ruleManagerDropdowns(input: $input) {
      categories
      products
      rules
    }
  }
`;
