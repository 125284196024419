import React from 'react';

import { AppWrapper, PageTitle, Tabs } from '../../../components';
import { path } from '../../../utils';
import { PerformanceReports, TransactionReports } from './Tabs';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type ManageSavedReportsProps = DefaultPropsType

const ManageSavedReports = ({ permissionsCodeList = [] }: ManageSavedReportsProps) => (
  <AppWrapper permissionsCodeList={permissionsCodeList}>
    <Styled.HeaderStyled>
      <PageTitle>{path.manageSavedReports.name}</PageTitle>
    </Styled.HeaderStyled>

    <Tabs
      tabNames={['Performance Report', 'Transaction Master']}
      elements={[<PerformanceReports />, <TransactionReports />]}
    />
  </AppWrapper>
);
export default ManageSavedReports;
