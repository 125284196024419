export const PAGE_DESCRIPTION = "Here you can manage all of the publisher's applications.";

export const BUTTON_LABEL: {[key: string]: string} = {
  CLEAR: 'Clear Form',
  DOWNLOAD: 'Download CSV',
  CANCEL: 'Cancel',
  DECLINE: 'Decline',
  APPROVE: 'Approve',
  CLOSE: 'Close',
};

export const LABEL = {
  PER_PAGE: 'Records per page',
};

export const PLACEHOLDER = {
  SEARCH: 'Search by Company Name, ID, URL...',
  FROM_DATE: 'From Date',
  TO_DATE: 'To Date',
  GROUP: 'Select Publisher Group',
  LOCATION: 'Select Location',
};

export const MARKETS = [
  { label: 'All', value: 'All' },
  { label: 'Any English speaking country', value: 'Any English speaking country' },
  { label: 'US only', value: 'US only' },
  { label: 'US and Canada', value: 'US and Canada' },
  { label: 'Canada only', value: 'Canada only' },
  { label: 'Canada only, excluding Quebec', value: 'Canada only, excluding Quebec' },
  { label: 'International', value: 'International' },
];

export const ALL_ENGLISH_SPEAKING_COUNTRIES = ['United States', 'Canada', 'Australia'];

export const TAB_NAMES = [
  { label: 'Pending Applications', value: 'pendingApplications' },
  { label: 'Pending Invitations', value: 'pendingInvitations' },
  { label: 'Active Memberships', value: 'activeMemberships' },
  { label: 'Inactive Memberships', value: 'inactiveMemberships' },
];

export const TABS = {
  PENDING_APPLICATIONS: TAB_NAMES[0],
  PENDING_INVITATIONS: TAB_NAMES[1],
  ACTIVE_MEMBERSHIPS: TAB_NAMES[2],
  INACTIVE_MEMBERSHIPS: TAB_NAMES[3],
};

export const ERROR_MESSAGE = 'Oops! Seems like you have not setup the program, and remember to add at least one product. ERROR: ';

export const MODAL_TYPES = {
  APPROVE: 'APPROVE',
  DECLINE: 'DECLINE',
  CLOSE: 'CLOSE',
};

export const MODAL_TITLE: {[key: string]: string} = {
  APPROVE: 'Approve Application',
  DECLINE: 'Decline Application',
  CLOSE: 'Close Application',
};

export const MODAL_DESCRIPTION_START: {[key: string]: string} = {
  APPROVE: 'You are about to approve the following publisher(s):',
  DECLINE: 'You are about to decline the following publisher(s):',
  CLOSE: 'You are about to close the following publisher(s):',
};
export const MODAL_DESCRIPTION_END: {[key: string]: string} = {
  APPROVE: 'They will be notified by email, tracking links enabled and their status will be changed to Approved.',
  DECLINE: 'They will be notified by email, tracking links disabled and their status will be changed to Declined.',
  CLOSE: 'They will be notified by email, tracking links disabled and their status will be changed to Closed.',
};

export const csvColumns = [
  { text: 'ID', dataField: 'publisherId' },
  { text: 'COMPANY NAME', dataField: 'companyName' },
  { text: 'TRACKING URL', dataField: 'primaryWebsite' },
  { text: 'CATEGORY', dataField: 'primaryCategory' },
  { text: 'PRODUCT/SERVICE CATEGORIES', dataField: 'promotingProduct' },
  { text: 'WEBSITE TRAFFIC', dataField: 'websiteTraffic' },
  { text: 'PROMOTION METHOD', dataField: 'promotionMethod' },
  { text: 'APPLICATION DATE', dataField: 'applicationDate' },
  { text: 'STATUS', dataField: 'status' },
];

export const DEFAULT_MEMBERSHIPS_DATA = {
  pendingApplications: [],
  pendingInvitations: [],
  activeMemberships: [],
  inactiveMemberships: [],
};

export const DEFAULT_MENUS = [
  `${TABS.PENDING_APPLICATIONS.label} (0)`,
  `${TABS.PENDING_INVITATIONS.label} (0)`,
  `${TABS.ACTIVE_MEMBERSHIPS.label} (0)`,
  `${TABS.INACTIVE_MEMBERSHIPS.label} (0)`,
];

export const DEFAULT_GROUP = { label: 'All', value: '' };
