import { useNavigate } from 'react-router-dom';
import { path } from '../../../utils';

export const useEmailVerified = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);

  const loginNavigateHandler = () => {
    navigate(path.login.href);
  };

  return {
    hookMessage: params.get('message'),
    hookSuccess: params.get('success'),
    hookLoginRedirect: loginNavigateHandler,
  };
};
