import styled from 'styled-components';
import { InputText, Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 23% calc(100% - 23%);
  height: 100vh;
`;

export const LeftStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.color1};
`;

export const RightStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const RightContentStyled = styled.div`
  width: 45%;
  display: flex;
  flex-flow: column nowrap;
  color: ${colors.color1};

  label {
    margin-bottom: 2rem;
  }

  h1 {
    margin-bottom: 2rem;
    ${fonts.gotham.H36M}
  }

  h2 {
    margin-top: 3rem;
    ${fonts.gotham.H26M}
  }

  > span {
    ${fonts.lato.H16}
    line-height: 1.5;
  }
`;

export const FormStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5%;
  margin-top: 1rem;

`;

export const InputStyled = styled(InputText)`
  margin-bottom: 0;
  font-size: ${toRem(14)};
`;

export const InputTwoColumnStyled = styled(InputText)`
  grid-column: span 2;
`;

export const SelectTwoColumnStyled = styled(Select)`
  grid-column: span 2;
`;
