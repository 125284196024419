import styled from 'styled-components';
import { fonts } from '../../../../styles/theme';

export const HeaderStyled = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderToggleStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H16}
`;

export const HeaderLabelStyled = styled.div`
  margin-right: 1rem;
`;

export const LoadingDotsWrapper = styled.div`
  margin-top: 200px;
`;
