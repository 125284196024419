import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, InputText, PageTitle, Select,
} from '../../../../../../../components';
import { colors, fonts } from '../../../../../../../styles/theme';

export const WrapperStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding: 1rem;
  max-width: 700px;
`;

export const PageTitleStyled = styled(PageTitle)`
  width: 100%;
  margin-bottom: 2rem;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const SelectStyled = styled(Select)`
  margin-bottom: 3rem;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: .5rem;
`;

export const ErrorStyled = styled.div`
  width: 100%;
  color: ${colors.color14};
  border: 2px solid ${colors.color14};
  border-radius: 3px;
  padding: .8rem;
  margin-bottom: 1rem;
  ${fonts.lato.H16}
`;

export const InputStyled = styled(InputText)`
  margin-bottom: 3rem;
`;
