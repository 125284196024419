import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_CAMPAIGNS } from '../graphql/queries';
import { useQueries, useUserInfo } from '../../../../../../../hooks';

export const useCampaigns = () => {
  const [campaignList, setCampaignList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { hookWhoAmI } = useUserInfo();
  const [getCampaigns, { loading }] = useLazyQuery(GET_CAMPAIGNS);
  const { id } = useQueries();

  const setCampaignListHandler = async () => {
    const { data } = await getCampaigns({
      variables: {
        input: {
          filter: {
            programId: hookWhoAmI.programId,
            publisherGroupId: id,
          },
          options: {
            page: currentPage,
            order: 'ASC',
            items: 5,
          },
          sortBy: 'campaignId',
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.listSearchCampaigns?.campaigns !== undefined) {
      const list = data.listSearchCampaigns.campaigns.map((item: any) => ({
        id: item.campaignId,
        campaign: {
          name: item.campaignName,
          description: item.description,
        },
        banner: item.bannerCount || 0,
        text: item.linkCount || 0,
        html: item.html5Count || 0,
        tracking: item.trackingCount || 0,
        detail: item.campaignId,
      }));
      setTotalPages(Math.ceil(data.listSearchCampaigns.count / 10));
      setCampaignList(list);
    }
  };

  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCampaignListHandler();
  }, [hookWhoAmI.programId, currentPage]);

  return {
    hookCampaigns: campaignList,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,
    hookTotalPages: totalPages,
    hookLoading: loading,
  };
};
