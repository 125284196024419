import React from 'react';
import * as Styled from './styles';
import { Button, ErrorBanner, Radio } from '../../../../../components';
import { FEEDBACK_ACTIONS, FeedbackReducerModalProps } from './reducers';
import { FEEDBACK_MODAL } from './enums';

type FeedbackModalProps = {
  hook: {
    state: FeedbackReducerModalProps;
    dispatch: (type: symbol, data: Record<string, any>) => void;
    modalOpenDispatcher: () => void;
    updateFeedback: () => void;
    errorMessage: string
    isLoading: boolean
  }
}

export const FeedbackModal = ({ hook }: FeedbackModalProps) => (
  <Styled.Wrapper>
    <ErrorBanner
      message={hook.errorMessage}
      isOpen={!!hook.errorMessage}
      margin="0px"
    />
    <Styled.TitleStyled>{FEEDBACK_MODAL.TITLE}</Styled.TitleStyled>

    <p>{FEEDBACK_MODAL.CONFIMRATION}</p>
    <Styled.RadioWrapper>
      <Radio
        name="correct feedback"
        label="Yes"
        value="check"
        checked={hook.state.correctStatus === true}
        onChange={() => hook.dispatch(FEEDBACK_ACTIONS.SET_STATUS_CONFIRMATION, { correctStatus: true })}
      />
      <Radio
        name="incorrect feedback"
        label="No"
        value="check"
        checked={hook.state.correctStatus === false}
        onChange={() => hook.dispatch(FEEDBACK_ACTIONS.SET_STATUS_CONFIRMATION, { correctStatus: false })}
      />
    </Styled.RadioWrapper>

    {hook.state.correctStatus === false && (
      <>
        <p>{FEEDBACK_MODAL.CORRECTION}</p>
        <Styled.RadioWrapper>
          {hook.state.currStatus !== 'Pass' && (
          <Radio
            name="pass"
            label="Pass"
            onChange={() => hook.dispatch(FEEDBACK_ACTIONS.SET_CORRECTED_STATUS, { shouldBeStatus: 'Pass' })}
            checked={hook.state.shouldBeStatus === 'Pass'}
            value="check"
          />
          )}
          {hook.state.currStatus !== 'Review' && (
          <Radio
            name="review"
            label="Review"
            onChange={() => hook.dispatch(FEEDBACK_ACTIONS.SET_CORRECTED_STATUS, { shouldBeStatus: 'Review' })}
            checked={hook.state.shouldBeStatus === 'Review'}
            value="check"
          />
          )}
          {hook.state.currStatus !== 'Fail' && (
          <Radio
            name="fail"
            label="Fail"
            onChange={() => hook.dispatch(FEEDBACK_ACTIONS.SET_CORRECTED_STATUS, { shouldBeStatus: 'Fail' })}
            checked={hook.state.shouldBeStatus === 'Fail'}
            value="check"
          />
          )}
          {hook.state.currStatus !== 'Not Applicable' && (
          <Radio
            name="notApplicable"
            label="Not Applicable"
            onChange={() => hook.dispatch(FEEDBACK_ACTIONS.SET_CORRECTED_STATUS, { shouldBeStatus: 'Not Applicable' })}
            checked={hook.state.shouldBeStatus === 'Not Applicable'}
            value="check"
          />
          )}
        </Styled.RadioWrapper>
      </>
    )}

    <Styled.ButtonWrapperStyled>
      <Button
        theme="secondary"
        onClick={hook.modalOpenDispatcher}
      >
        {FEEDBACK_MODAL.CANCEL}
      </Button>
      <Button
        onClick={() => {
          hook.updateFeedback();
        }}
        disabled={hook.isLoading}
        loading={hook.isLoading}
      >
        {FEEDBACK_MODAL.SUBMIT}
      </Button>
    </Styled.ButtonWrapperStyled>
  </Styled.Wrapper>
);
