import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Modal, PageTitle } from '../../../../components';
import * as Styled from '../styles';
import { PROGRAM_DETAILS } from '../enum';

type ModalTypes = {
    Description1: string
    Description2: string
    ButtonName: string
    isDecline: boolean
    merchantName: string
    onCancel: () => void
    onConfirm: () => void
    isOpen: boolean,
    title: string
    loading: boolean,
}

export const ModalComponent = ({
  Description1, Description2, ButtonName, isDecline, merchantName, onCancel, onConfirm, isOpen, title, loading,
}: ModalTypes) => (
  <Modal isOpen={isOpen}>
    <Styled.ModalStyled>
      <PageTitle>{title}</PageTitle>
      <Styled.ModalDesc>
        {Description1}
        {' '}
        <Styled.BoldText>{merchantName}</Styled.BoldText>
        {Description2}
      </Styled.ModalDesc>
      {isDecline && (
      <Styled.InfoBox>
        <FontAwesomeIcon icon={faCircleInfo} />
        <div>
          {PROGRAM_DETAILS.DECLINE_MODAL.NOTE}
        </div>
      </Styled.InfoBox>
      )}
      <Styled.ButtonWrapperStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={onCancel}
        >
          {PROGRAM_DETAILS.BUTTON.CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          theme="primary"
          onClick={onConfirm}
          loading={loading}
        >
          {ButtonName}
        </Styled.ButtonStyled>
      </Styled.ButtonWrapperStyled>
    </Styled.ModalStyled>
  </Modal>
);
