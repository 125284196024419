import React from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import * as Styled from './styles';

type LinkCardProps = {
  title: string
  link: string
  color: string
  icon: IconDefinition
  outsideLink?: boolean
}

export const LinkCard = ({
  link, color, title, icon, outsideLink,
}: LinkCardProps) => {
  const navigate = useNavigate();
  return (
    <Styled.CardWrapperStyled color={color}>
      <Styled.ButtonStyled
        color={color}
        onClick={() => {
          if (outsideLink) window.open(link, '_blank');
          else navigate(`${link}`);
        }}
        theme="quaternary"
      >
        {title}
        <Styled.IconStyled
          icon={icon}
        />
      </Styled.ButtonStyled>
    </Styled.CardWrapperStyled>
  );
};
