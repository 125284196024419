import React, { useState, useEffect } from 'react';
import { faTimes, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';
import { PageTitle, Modal } from '../../../../components';
import * as Styled from './styles';
import { colors, fonts } from '../../../../styles/theme';

// TODO: type the hook
const CreateCommissionModal = ({
  hook,
}: {
  hook: any
}) => (
  <Modal isOpen={hook.isCreateCommissionModalOpen}>
    <Styled.WrapperStyled>
      <Styled.TopStyled>
        { hook.hookIsCustom && (
        <PageTitle>
          Custom Commission Summary
        </PageTitle>
        )}

        { !hook.hookIsCustom && (
        <PageTitle>
          Default Commission Summary
        </PageTitle>
        )}

        <Styled.CloseButtonStyled
          theme="secondary"
          onClick={() => { hook.setIsCreateCommissionModalOpen(false); }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Styled.CloseButtonStyled>
      </Styled.TopStyled>

      <Styled.ContentStyled>

        <Styled.CSRow1>
          <Styled.Heading1> Commission Name</Styled.Heading1>
          <Styled.CSRow4>
            <Styled.CommissionText1>
              {hook.commissionName}
            </Styled.CommissionText1>

            { hook.commissionNameExists && (
            <Styled.WarningMessageBox>
              <FontAwesomeIcon
                icon={faWarning}
                size="2xs"
                color={colors.color2}
              />
              <Styled.WarningMessageText1> This Commission name already exists </Styled.WarningMessageText1>
            </Styled.WarningMessageBox>
            )}

          </Styled.CSRow4>
        </Styled.CSRow1>

        <Styled.CSRow1>
          <Styled.Heading1> Commission Description </Styled.Heading1>
          <Styled.CommissionText1>
            {hook.hookDescription}
          </Styled.CommissionText1>
        </Styled.CSRow1>

        <Styled.BorderElement />

        <Styled.CSRow1>
          <Styled.CSRow2>
            <Styled.CSRow3>
              <Styled.Heading1> Start Date</Styled.Heading1>
              <div>
                {' '}
                {hook.detailsStartDateStringFormat}
                {' '}
              </div>
            </Styled.CSRow3>
            <Styled.CSRow3>
              <Styled.Heading1> End Date </Styled.Heading1>
              <div>
                {' '}
                {hook.detailsEndDateStringFormat}
                {' '}
              </div>
            </Styled.CSRow3>
          </Styled.CSRow2>
        </Styled.CSRow1>

        <Styled.BorderElement />

        <Styled.CSRow1>
          <Styled.Heading2> Commission Structure </Styled.Heading2>
          <Styled.CommissionText1>
            {' '}
            {hook.commissionStructureSentence}
            {' '}
          </Styled.CommissionText1>
        </Styled.CSRow1>

        { hook.selectedPublisherGroupsDialog.length > 0 && (
        <>
          <Styled.BorderElement />
          <Styled.CSRow1>
            <Styled.CSRow5>
              <Styled.Heading2>
                Commission Members
              </Styled.Heading2>
            </Styled.CSRow5>
            <Styled.PublisherGroupSection1>
              {hook.selectedPublisherGroupsDialog.map((publisherGroup: any) => (
                <Styled.PublisherGroupBox1>
                  {' '}
                  {publisherGroup}
                  {' '}
                </Styled.PublisherGroupBox1>
              ))}
            </Styled.PublisherGroupSection1>
          </Styled.CSRow1>
        </>
        )}
      </Styled.ContentStyled>
      <Styled.FooterStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => { hook.setIsCreateCommissionModalOpen(false); }}
        >
          Cancel
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          theme="primary"
          onClick={() => { hook.handleCommissionConfirmation(); }}
        >
          Create Commission
        </Styled.ButtonStyled>
      </Styled.FooterStyled>
    </Styled.WrapperStyled>
  </Modal>
);

export default CreateCommissionModal;
