import { useState } from 'react';
import { DEFAULT_COLUMNS } from '../../contracts';

export const useHook = () => {
  const [customizeColumns, setCustomizeColumns] = useState(DEFAULT_COLUMNS);

  const customizeColumnsDefault = (colunms: string[]) => {
    setCustomizeColumns(colunms);
  };

  const setCustomizeColumnsHandler = (value: boolean, field: string) => {
    if (value) {
      setCustomizeColumns([...customizeColumns, field]);
    } else {
      const filteredColumns = customizeColumns.filter((column: string) => column !== field);
      setCustomizeColumns(filteredColumns);
    }
  };

  return {
    hookCustomizeColumns: customizeColumns,
    hookSetCustomizeColumns: setCustomizeColumnsHandler,

    hookCustomizeColumnsDefault: customizeColumnsDefault,
  };
};
