import styled from 'styled-components';
import { Button, FileUpload, InputTextarea } from '../../../components';
import { colors, fonts } from '../../../styles/theme';

type FieldStyledProps = {
  columns: string;
  flex?: string;
}

type ButtonStyledProps = {
  theme?: string;
  buttonType?: string;
}

export const BottomButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  button {
    ${fonts.lato.H16};
  }
`;

export const ButtonStyled = styled(Button)<ButtonStyledProps>`
  background-color: ${colors.color1};
  border: none;
  height: 40px;

  &:hover {
    background-color: ${colors.color10};
    border: none;
  }
  svg {
    margin-right: 5px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const FieldStyled = styled.div<FieldStyledProps>`
  display: grid;
  align-items: ${({ flex }) => (flex || 'flex-end')};
  grid-template-columns: ${({ columns }) => (columns)};
  grid-gap: 2%;
  row-gap: 2rem;
  margin:2.5rem 0;
`;

export const FileUploadStyled = styled(FileUpload)`
  span: nth-child(3) {
    ${fonts.lato.H14}
  }
`;

export const TextareaStyled = styled(InputTextarea)`
  grid-row: 2 / 4;
`;

export const SectionTitleStyled = styled.p`
  ${fonts.gotham.H22}
  color: ${colors.color1};
`;

export const PublisherGroupStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2%;
  row-gap: 1rem;
  margin: 1.5rem 0;
`;

export const FooterStyled = styled.div`
  display: flex;
  justify-content: end;

  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;

export const TextStyled = styled.div`
  color: ${colors.color1};
  ${fonts.lato.H16}
  padding-right: 18.75rem;
`;

export const CategoryStyled = styled.div`
  color: ${colors.color13};
  ${fonts.gotham.H12B}
  padding-right: 300px;
  padding-bottom: 15px;
  margin-bottom: 5px;
`;

export const InfoAreaStyled = styled.div`
  display: flex;
  flex-grow: 1;
  margin-bottom: 70px;
`;

export const SectionStyled = styled.div`
  margin-bottom: 70px;
`;

export const FileUploadRowStyled = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ViewAdButtonStyled = styled(Button)`
  background-color: ${colors.color1};
  border: none;
  height: 36px;

  &:hover {
    background-color: ${colors.color10};
    border: none;
  }
`;

export const CheckBoxStyled = styled.div`
  margin: 16px 0px 4px;
  span {
    color: ${colors.color1};
    ${fonts.lato.H16};
  }
  svg {
    color: ${colors.color4};
  }
`;

export const CPStyled = styled.div`
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  font-family: gotham_promedium;
  margin-top: 3rem;
  margin-bottom: -1.5rem !important;
`;

export const CPHeader = styled.div`
  display: flex;
  text-transform: uppercase;
  margin-right: 0.4rem;
  color: ${colors.color1}
`;

export const CPValue = styled.div`
  display: flex;
  color: ${colors.color1}
`;
