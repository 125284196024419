import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import * as am5theme from '@amcharts/amcharts5/themes/Animated';
import am5geodataCanadaLow from '@amcharts/amcharts5-geodata/canadaLow';

import * as Styled from './styles';
import { colors } from '../../../../../../styles/theme';
import { CanadaAndUsClickObjectType } from '../../../types';

type CanadaMapGraphProps = {
  data: CanadaAndUsClickObjectType[]
}

export const CanadaMapGraph = ({ data }: CanadaMapGraphProps) => {
  useEffect(() => {
    am5.ready(() => {
      am5.array.each(am5.registry.rootElements, (root) => {
        if (root?.dom.id === 'canadaMap') {
          root.dispose();
        }
      });
      const root = am5.Root.new('canadaMap');
      root.setThemes([am5theme.default.new(root)]);

      const chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: 'none',
          panY: 'none',
        }),
      );

      const polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodataCanadaLow,
        }),
      );
      polygonSeries.mapPolygons.template.setAll({
        tooltipText: '{name}',
        interactive: true,
      });
      polygonSeries.mapPolygons.template.states.create('hover', {
        fill: am5.color(colors.color22),
      });
      polygonSeries.set('fill', am5.color(colors.color22));

      const information = chart.series.push(
        am5map.MapPointSeries.new(root, {
          polygonIdField: 'id',
          valueField: 'clicks',
          calculateAggregates: true,
        }),
      );

      const template: am5.Template<am5.Circle> = am5.Template.new({});
      information.bullets.push((seriesRoot) => {
        const container = am5.Container.new(seriesRoot, {});

        container.children.push(
          am5.Circle.new(seriesRoot, {
            fill: am5.color(colors.color5),
            fillOpacity: 0.7,
            tooltipText: '{name}:\n[bold]{clicks} clicks[/]',
          }, template),
        );

        return am5.Bullet.new(seriesRoot, {
          sprite: container,
          dynamic: true,
        });
      });
      information.bullets.push(() => am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          centerX: am5.p50,
          centerY: am5.p50,
          text: '{clicks}',
          fill: am5.color(colors.color4),
          populateText: true,
          fontSize: 12,
        }),
      }));
      information.set('heatRules', [{
        target: template,
        min: 15,
        max: 30,
        dataField: 'value',
        key: 'radius',
      }]);

      information.data.setAll(data);
    });
  }, [data]);

  return (
    <Styled.MapWrapperStyled>
      <Styled.MapStyled id="canadaMap" />
    </Styled.MapWrapperStyled>
  );
};
