import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AdGenerateCodeModal, AppWrapper, Button, Calendar, Checkbox, CodeEditor, DropZone, ErrorBanner, InputCalendar, InputText, PageTitle, Select,
} from '../../../components';
import { CreateCampaignModal } from '../../../components/CreateCampaignModal';
import {
  BUTTON_LABELS, INFO, LABELS, PLACEHOLDERS, SECTION_HEADERS,
} from '../utils';
import {
  AD_TYPES, FILE_EXTENSIONS, NOT_AVAILABLE, path, TOAST_ERR_MESSAGES_NO_PAGE,
} from '../../../utils';
import { InfoHeader } from './components';
import { useEditAd } from './hooks';
import { SectionTitleStyled } from './styles';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type EditAdProps = DefaultPropsType;

const EditAd = ({ permissionsCodeList = [] }: EditAdProps) => {
  const { hookIsReadOnlyList, ...hook } = useEditAd(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        isOpen={hook.hookErrorBanner}
        message={TOAST_ERR_MESSAGES_NO_PAGE(hook.hookAdErrorMessage)}
      />
      <Styled.HeaderStyled>
        <PageTitle>{path.editAd.name}</PageTitle>
        <Styled.ButtonWrapper>
          <Button
            theme="secondary"
            onClick={() => hook.hookOnCancel()}
            width="104px"
          >
            {BUTTON_LABELS.CANCEL}
          </Button>
          <Button
            onClick={hook.hookOnSave}
            width="104px"
            disabled={isReadOnly}
          >
            {BUTTON_LABELS.SAVE}
          </Button>
        </Styled.ButtonWrapper>
      </Styled.HeaderStyled>

      {/* ========== basic info - common between all types of ads ========== */}
      <Styled.SectionStyled>
        <Styled.InfoAreaStyled>
          <InfoHeader
            type={LABELS.ID}
            value={hook.hookAdId}
          />
          <InfoHeader
            type={LABELS.COMPANY_NAME}
            value={hook.hookCompanyName}
          />
          <InfoHeader
            type={LABELS.CREATED_DATE}
            value={hook.hookCreatedDate ? new Date(hook.hookCreatedDate).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }) : NOT_AVAILABLE}
          />
          <InfoHeader
            type={LABELS.LAST_UPDATED}
            value={hook.hookLastUpdated ? new Date(hook.hookLastUpdated).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }) : NOT_AVAILABLE}
          />
        </Styled.InfoAreaStyled>

        <InputText
          type="text"
          label={LABELS.AD_NAME}
          required
          name="adName"
          value={hook.hookAdName}
          onChange={hook.hookSetAdName}
          onBlur={hook.hookValidate}
          disabled={hook.hookLoading || isReadOnly}
          error={hook.hookEditAdErrors.adName}
        />

        <Styled.FieldStyled columns="3.5fr 3.5fr 1fr">
          <Select
            name="status"
            label={LABELS.STATUS}
            options={hook.hookStatusOptions}
            selected={hook.hookAdStatus}
            isLoading={hook.hookLoading}
            isDisabled={hook.hookLoading || isReadOnly}
            required
            onChange={hook.hookSetStatus}
            error={hook.hookEditAdErrors.status}
          />
          <Select
            name="campaign"
            label={LABELS.CAMPAIGN}
            placeholder={PLACEHOLDERS.CAMPAIGN}
            selected={hook.hookCampaign}
            options={hook.hookCampaignOptions}
            isDisabled={isReadOnly}
            onChange={hook.hookSetCampaign}
            onBlur={hook.hookValidate}
          />
          <Styled.ButtonStyled
            onClick={() => hook.hookSetCampaignModal(true)}
            disabled={hook.hookLoading || isReadOnly}
          >
            <FontAwesomeIcon icon={faPlus} />
            {BUTTON_LABELS.CREATE_CAMPAIGN}
          </Styled.ButtonStyled>
        </Styled.FieldStyled>

        <Styled.TextareaStyled
          height="150px"
          name="description"
          label={LABELS.DESCRIPTION}
          value={hook.hookDescription}
          disabled={hook.hookLoading || isReadOnly}
          onChange={hook.hookSetDescription}
          onBlur={hook.hookValidate}
          error={hook.hookEditAdErrors.description}
          required
        />
      </Styled.SectionStyled>

      {/* ========== type specific info - type 1: link, type 2: banner, type 3: HTML ========== */}
      {hook.hookAdType === AD_TYPES.BANNER && (
        <Styled.SectionStyled>
          <SectionTitleStyled>{SECTION_HEADERS.BANNER}</SectionTitleStyled>
          <Styled.FieldStyled
            columns="1fr 1fr"
            flex="flex-start"
          >
            <DropZone
              type="Banner"
              name="profilePhoto"
              onChange={hook.hookUploadImage}
              label={LABELS.PROFILE_PHOTO}
              info={INFO.DROP_ZONE}
              accept={`${FILE_EXTENSIONS.PNG}, ${FILE_EXTENSIONS.JPG}, ${FILE_EXTENSIONS.JPEG}, ${FILE_EXTENSIONS.GIF}`}
              maxFileSize={200000}
              previewUrl={hook.hookAdContent}
              disabled={hook.hookLoading || isReadOnly}
            />
            <InputText
              required
              type="text"
              name="altText"
              label={LABELS.IMAGE_ALT}
              value={hook.hookAltText}
              onChange={hook.hookSetAltText}
              onBlur={hook.hookValidate}
              disabled={hook.hookLoading || isReadOnly}
              error={hook.hookEditAdErrors.altText || hook.hookEditAdErrors.specialCharacters}
            />
          </Styled.FieldStyled>
        </Styled.SectionStyled>
      )}

      {hook.hookAdType === AD_TYPES.HTML && (
        <Styled.SectionStyled>
          <Styled.FileUploadRowStyled>
            <SectionTitleStyled>{SECTION_HEADERS.HTML}</SectionTitleStyled>
            <Styled.ViewAdButtonStyled
              onClick={() => hook.hookSetOpenAdModal()}
              disabled={hook.hookLoading || isReadOnly}
            >
              {BUTTON_LABELS.VIEW_AD}
            </Styled.ViewAdButtonStyled>
          </Styled.FileUploadRowStyled>
          <CodeEditor
            label={LABELS.HTML_CODE}
            value={hook.hookAdContent}
            required
            onChange={hook.hookSetAdContent}
            onBlur={hook.hookValidate}
            error={hook.hookEditAdErrors.htmlCode}
            disabled={hook.hookLoading || isReadOnly}
          />
          <Styled.CheckBoxStyled>
            <Checkbox
              checked={hook.hookJsOnly}
              onChange={hook.hookSetJsOnly}
              disabled={hook.hookLoading || isReadOnly}
              label={INFO.JS_ONLY}
            />
          </Styled.CheckBoxStyled>
          <Styled.FieldStyled
            columns="1fr 1fr 1.5fr"
            flex="flex-start"
          >
            <InputText
              name="adWidth"
              label={LABELS.AD_WIDTH}
              value={hook.hookWidth}
              required
              type="number"
              onChange={hook.hookSetWidth}
              onBlur={hook.hookValidate}
              error={hook.hookEditAdErrors.htmlWidth}
              disabled={hook.hookLoading || isReadOnly}
            />
            <InputText
              name="adHeight"
              label={LABELS.AD_HEIGHT}
              value={hook.hookHeight}
              required
              type="number"
              onChange={hook.hookSetHeight}
              onBlur={hook.hookValidate}
              error={hook.hookEditAdErrors.htmlHeight}
              disabled={hook.hookLoading || isReadOnly}
            />
            <Styled.FileUploadStyled
              type="zip"
              label={LABELS.ASSET_FILE}
              info={INFO.ASSET}
              onChange={hook.hookOnAssetFileUpload}
              disabled={hook.hookLoading || isReadOnly}
              maxFileSize={4096000}
              accept={FILE_EXTENSIONS.ZIP}
            />
          </Styled.FieldStyled>
        </Styled.SectionStyled>
      )}

      {hook.hookAdType === AD_TYPES.LINK && (
        <Styled.SectionStyled>
          <SectionTitleStyled>{SECTION_HEADERS.LINK}</SectionTitleStyled>
          <Styled.FieldStyled
            columns="1fr 1fr"
            flex="flex-start"
          >
            <InputText
              type="text"
              label={LABELS.LINK_CONTENT}
              value={hook.hookAdContent}
              onChange={hook.hookSetAdContent}
              onBlur={hook.hookValidate}
              name="textLinkContent"
              disabled={hook.hookLoading || isReadOnly}
            />
            <InputText
              type="url"
              label={LABELS.LANDING_URL}
              placeholder={hook.hookUrlPlaceholder}
              value={hook.hookLandingPage}
              onChange={hook.hookSetDefaultLandingPageUrl}
              onBlur={hook.hookValidateWebsite}
              error={hook.hookDefaultUrlError}
              name="defaultLandingPageURL"
              disabled={hook.hookLoading || isReadOnly}
            />
          </Styled.FieldStyled>
        </Styled.SectionStyled>
      )}

      {/* ========== advanced options - Banner & HTML has the same advanced options ========== */}
      {!hook.hookHideOptions && hook.hookAdType === AD_TYPES.LINK && (
        <Styled.SectionStyled>
          <SectionTitleStyled>{SECTION_HEADERS.ADVANCED}</SectionTitleStyled>
          {(hook.hookCampaign) && (
          <Styled.CPStyled>
            <Styled.CPHeader> Campaign Product: </Styled.CPHeader>
            <Styled.CPValue>
              { (hook.hookCampaignProduct.productId === 'null' || !hook.hookCampaignProduct.productId)
                ? (<> None </>) : hook.hookCampaignProduct.productName }
            </Styled.CPValue>

          </Styled.CPStyled>
          )}
          <Styled.FieldStyled
            columns="1fr 1fr 1fr"
            flex="flex-start"
          >
            <Select
              name="product"
              label={LABELS.PRODUCT}
              placeholder={PLACEHOLDERS.PRODUCT}
              selected={hook.hookProduct}
              onChange={hook.hookSetProducts}
              options={hook.hookProductOptions}
              isDisabled={hook.hookLoading || isReadOnly}
              isLoading={hook.hookLoading}
              onBlur={hook.hookValidate}
              error={hook.hookEditAdErrors.productId}
            />
            <InputCalendar
              label={LABELS.START_DATE}
              value={hook.hookStartDate?.toDateString() || ''}
              placeholder={PLACEHOLDERS.DATE}
              onClick={hook.hookOnOpenStartCalendar}
              isDisabled={hook.hookLoading || isReadOnly}
              error={isReadOnly ? '' : hook.hookEditAdErrors.startDate}
            />
            <InputCalendar
              label={LABELS.END_DATE}
              value={hook.hookEndDate?.toDateString() || ''}
              placeholder={PLACEHOLDERS.DATE}
              onClick={hook.hookOnOpenEndCalendar}
              isDisabled={hook.hookLoading || isReadOnly}
              error={isReadOnly ? '' : hook.hookEditAdErrors.endDate || hook.hookEndDateError}
            />
          </Styled.FieldStyled>

          <Styled.FieldStyled
            columns="2.06fr 1fr"
            flex="flex-start"
          >
            <Select
              name="tags"
              label={LABELS.TAGS}
              placeholder={PLACEHOLDERS.TAGS}
              selectedMulti={hook.hookTags}
              options={hook.hookTagOptions}
              onChange={hook.hookSetTags}
              isDisabled={hook.hookLoading || isReadOnly}
              isLoading={hook.hookLoading}
              isMulti
              creatable
            />
            <Select
              name="language"
              label={LABELS.LANGUAGE}
              selected={hook.hookLanguage}
              options={hook.hookLanguageOptions}
              onChange={hook.hookSetLanguage}
              error={hook.hookEditAdErrors.language}
              isDisabled={hook.hookLoading || isReadOnly}
              isLoading={hook.hookLoading}
              required
            />
          </Styled.FieldStyled>
        </Styled.SectionStyled>
      )}
      {!hook.hookHideOptions && hook.hookAdType !== AD_TYPES.LINK && (
      <Styled.SectionStyled>
        <SectionTitleStyled> Advanced Options</SectionTitleStyled>
        {(hook.hookCampaign) && (
          <Styled.CPStyled>
            <Styled.CPHeader> Campaign Product: </Styled.CPHeader>
            <Styled.CPValue>
              { (hook.hookCampaignProduct.productId === 'null' || !hook.hookCampaignProduct.productId)
                ? (<> None </>) : hook.hookCampaignProduct.productName }
            </Styled.CPValue>

          </Styled.CPStyled>
        )}
        <Styled.FieldStyled
          columns="1fr 1fr 1fr"
          flex="flex-start"
        >
          <Select
            name="products"
            label={LABELS.PRODUCT}
            selected={hook.hookProduct}
            onChange={hook.hookSetProducts}
            options={hook.hookProductOptions}
            isDisabled={hook.hookLoading || isReadOnly}
            isLoading={hook.hookLoading}
            onBlur={hook.hookValidate}
            error={hook.hookEditAdErrors.productId}
          />
          <InputCalendar
            label={LABELS.START_DATE}
            value={hook.hookStartDate?.toDateString() || ''}
            placeholder={PLACEHOLDERS.DATE}
            onClick={hook.hookOnOpenStartCalendar}
            isDisabled={hook.hookLoading || isReadOnly}
            error={hook.hookEditAdErrors.startDate}
          />
          <InputCalendar
            label={LABELS.END_DATE}
            value={hook.hookEndDate?.toDateString() || ''}
            placeholder={PLACEHOLDERS.DATE}
            onClick={hook.hookOnOpenEndCalendar}
            isDisabled={hook.hookLoading || isReadOnly}
            error={hook.hookEditAdErrors.endDate || hook.hookEndDateError}
          />
        </Styled.FieldStyled>

        <Styled.FieldStyled
          columns="2.06fr 1fr"
          flex="flex-start"
        >
          <InputText
            type="url"
            label={LABELS.LANDING_URL}
            value={hook.hookLandingPage}
            onChange={hook.hookSetDefaultLandingPageUrl}
            onBlur={hook.hookValidateWebsite}
            error={hook.hookDefaultUrlError}
            name="defaultLandingPageURL"
            placeholder={hook.hookUrlPlaceholder}
            disabled={hook.hookLoading || isReadOnly}
          />
          <Select
            name="language"
            label={LABELS.LANGUAGE}
            required
            selected={hook.hookLanguage}
            options={hook.hookLanguageOptions}
            onChange={hook.hookSetLanguage}
            isDisabled={hook.hookLoading || isReadOnly}
            isLoading={hook.hookLoading}
            error={hook.hookEditAdErrors.language}
          />
        </Styled.FieldStyled>
        <Select
          name="tags"
          label={LABELS.TAGS}
          selectedMulti={hook.hookTags}
          options={hook.hookTagOptions}
          onChange={hook.hookSetTags}
          isDisabled={hook.hookLoading || isReadOnly}
          isLoading={hook.hookLoading}
          isMulti
          creatable
        />
      </Styled.SectionStyled>
      )}
      <Styled.BottomButtonWrapper>
        <Button
          onClick={() => hook.hookSetHideOptions(!hook.hookHideOptions)}
          theme="text-only"
        >
          {hook.hookHideOptions ? <>Show Advanced Options</> : <>Hide Advanced Options</>}
        </Button>
      </Styled.BottomButtonWrapper>

      {/* ========== modals ========== */}
      <Calendar
        date={hook.hookStartDate}
        onApply={hook.hookOnApplyStartCalendar}
        onCancel={hook.hookOnCancelStartCalendar}
        isOpen={hook.hookStartCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
      />
      <Calendar
        date={hook.hookEndDate}
        onApply={hook.hookOnApplyEndCalendar}
        onCancel={hook.hookOnCancelEndCalendar}
        isOpen={hook.hookEndCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
      />
      <CreateCampaignModal
        isOpen={hook.hookCampaignModal}
        onClose={() => hook.hookSetCampaignModal(!hook.hookCampaignModal)}
        setCampaign={hook.hookSetCampaignAfterCreation}
      />

      <AdGenerateCodeModal
        isOpen={hook.hookAdModal}
        adId={hook.hookAdId}
        onClose={hook.hookSetCloseAdModal}
      />
    </AppWrapper>
  );
};

export default EditAd;
