import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input/input';
import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';
import { Tooltip } from '../../Tooltip';

type InputContainerProps = {
  readonly error: boolean;
  readonly theme: 'default' | 'readonly';
  readonly disabled: boolean;
}

type WrapperProps = {
  readonly width: string;
}

type ErrorProps = {
  readonly error?: string;
}

const borderReturn = (theme: 'default' | 'readonly', hover: boolean) => {
  if (theme === 'default') {
    return !hover ? colors.color3 : colors.color1;
  }

  return 'transparent';
};

export const WrapperStyled = styled.label<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width)};
`;

export const LabelStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12M}
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
  margin-left: ${toRem(4)};
`;

export const InputStyled = styled(PhoneInput)`
  width: 100%;
  padding: ${toRem(10)};
  background: ${colors.color4};
  border: none;
  ${fonts.lato.H14}

  &::placeholder {
    color: ${colors.color7};
  }

  .PhoneInputInput {
    border: none;
  }
`;

export const InputContainerStyled = styled.div<InputContainerProps>`
  display: flex;
  border: 1px solid ${({ error, theme }) => (error ? colors.color14 : borderReturn(theme, false))};
  align-items: center;
  width: 100%;
  border-radius: 3px;
  padding-right: ${({ theme }) => (theme === 'readonly' ? '0' : toRem(10))};
  height: ${toRem(40)};

  &:hover {
    border: 1px solid ${({ error, theme }) => (error ? colors.color14 : borderReturn(theme, true))};

    svg {
      color: ${colors.color1};
    }
  }

  svg {
    color: ${colors.color13};
    margin-left: 0.5rem;
  }

  ${({ disabled }) => disabled && `
    background-color: ${colors.color11};
    .PhoneInput {
      background-color: ${colors.color11};
    }
    &:hover {
      border: 1px solid ${colors.color3};

      svg {
        color: ${colors.color1};
      }
    }

    ${InputStyled} {
      background-color: ${colors.color11};
    }
  `}

  ${InputStyled} {
    padding: ${({ theme }) => (theme === 'readonly' ? '0' : toRem(10))};

    ${({ theme }) => {
    if (theme === 'readonly') {
      return `
        font-size: ${toRem(18)};
        color: ${colors.color1};
      `;
    }
  }}
  }
`;

export const ErrorStyled = styled.span<ErrorProps>`
  margin-top: 0.3rem;
  ${fonts.lato.H14}
  color: ${colors.color14}
`;

export const TooltipStyled = styled(Tooltip)`
  height: auto;

  div {
    margin-left: 2px;
  }
`;
