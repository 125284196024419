import { gql } from '@apollo/client';

export const LIST_AD_FILTER_OPTIONS = gql`
  query getSearchAdsFilterOptions($input: AdsFilterOptionsInput) {
    getSearchAdsFilterOptions(input: $input) {
      campaigns {
        label
        value
      }
      products {
        label
        value
      }
      publisherGroups {
        label
        value
      }
    }
  }
`;
