import React from 'react';
import { Modal } from '../../../../../components';
import * as Styled from './styles';
import { BLACKLIST_DOMAINS } from '../enums';

type BlacklistDomainModalProps = {
  hook: any
}

const BlacklistDomainModal = ({ hook }: BlacklistDomainModalProps) => (
  <Modal isOpen={hook.hookIsOpen}>
    <Styled.Wrapper>
      <Styled.TitleStyled>
        {hook.hookModalType === BLACKLIST_DOMAINS.MODAL.TYPES.ADD && BLACKLIST_DOMAINS.MODAL.TITLE.ADD}
        {hook.hookModalType === BLACKLIST_DOMAINS.MODAL.TYPES.EDIT && BLACKLIST_DOMAINS.MODAL.TITLE.EDIT}
        {(hook.hookModalType === BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE || hook.hookModalType === BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE_MANY) && BLACKLIST_DOMAINS.MODAL.TITLE.REMOVE}
      </Styled.TitleStyled>
      {hook.hookModalType !== BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE && hook.hookModalType !== BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE_MANY ? (
        <Styled.InputStyled
          value={hook.hookDomain}
          onChange={hook.hookSetDomain}
          type="url"
          label="domain name"
          required
          error={hook.hookDomainError}
          placeholder="https://www.fintelconnect.com"
        />
      ) : (
        <Styled.RemovePromptStyled>
          You are about to remove the following URL(s) from
          {' '}
          <Styled.BoldText>Blacklist Domains</Styled.BoldText>
          {' '}
          <Styled.UrlWrapper>
            {
          hook.hookModalType === BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE
            ? <Styled.BoldTextDiv>{hook.hookDomain}</Styled.BoldTextDiv>
            : hook.hookSelectedDomains.map((item: any, index: number) => (<Styled.BoldTextDiv key={index}>{item}</Styled.BoldTextDiv>))
            }
          </Styled.UrlWrapper>
          Are you sure you want to continue?
        </Styled.RemovePromptStyled>
      )}
      <Styled.ButtonsWrapperStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => {
            hook.hookSetIsOpen();
            hook.hookResetModal();
          }}
        >
          {BLACKLIST_DOMAINS.MODAL.BUTTON.CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          onClick={hook.hookHandleModalRightButton}
        >
          {(hook.hookModalType === BLACKLIST_DOMAINS.MODAL.TYPES.ADD) && BLACKLIST_DOMAINS.MODAL.BUTTON.ADD}
          {(hook.hookModalType === BLACKLIST_DOMAINS.MODAL.TYPES.EDIT) && BLACKLIST_DOMAINS.MODAL.BUTTON.EDIT}
          {(hook.hookModalType === BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE) && BLACKLIST_DOMAINS.MODAL.BUTTON.REMOVE}
          {(hook.hookModalType === BLACKLIST_DOMAINS.MODAL.TYPES.REMOVE_MANY) && BLACKLIST_DOMAINS.MODAL.BUTTON.REMOVE}
        </Styled.ButtonStyled>
      </Styled.ButtonsWrapperStyled>
    </Styled.Wrapper>
  </Modal>
);

export default BlacklistDomainModal;
