import { gql } from '@apollo/client';

export const UPDATE_SUBSCRIPTION = gql`
mutation updateUserSubscriptionStatus($input: [UserSubscriptionInput]) {
  updateUserSubscriptionStatus(input: $input) {
    merchantId
    subscribe
    error
  }
}
`;
