import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../styles/theme';
import { Spinner } from '../Spinner';

type StatisticCardProps = {
  readonly color?: string;
}

export const CardWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-right: 1px solid ${colors.color15};
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  color: ${colors.color16};
  ${fonts.gotham.H14M}
  width: 100%;
`;

export const HeaderTitleStyled = styled.p`
  width: fit-content;
  overflow: hidden;
  white-space: break-spaces;
`;

export const BodyStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.color1};
  ${fonts.gotham.H32M}
  margin: 1.5rem 0;
`;

export const PercentageStyled = styled.div<StatisticCardProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${fonts.lato.H14M}
  border-radius: 15px;
  margin-left: 15px;
  width: 70px;
  height: 30px;
  color: ${({ color }) => color};
  background: ${({ color }) => {
    switch (color) {
      case colors.color14:
        return 'rgba(225, 93, 104, 0.05)';
      case colors.color2:
        return 'rgba(247, 141, 45, 0.05)';
      case colors.color21:
        return 'rgba(75, 199, 192, 0.05)';
      default:
        return 'none';
    }
  }};
`;

export const IconStyled = styled(FontAwesomeIcon)<StatisticCardProps>`
  width: 15px;
  color: ${({ color }) => color};
`;

export const FooterWrapperStyled = styled.div<StatisticCardProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colors.color15};
  padding-top: 1rem;
`;

export const FooterInnerStyled = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: ${colors.color16};
  ${fonts.gotham.H14M}
  white-space: break-spaces;

  span {
    ${fonts.lato.H14M}
    color: ${colors.color17};
  }
`;

export const SpinnerStyled = styled(Spinner)`
  margin: 4rem 0;
`;
