import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useUserInfo, useToast } from '../../../hooks';
import { codeGenerator, ERROR_MESSAGES, USER_TYPES_ID } from '../../../utils';
import { COPIED_MESSAGES } from '../utils';
import { CREATE_AD_TRACKER } from '../graphql/mutations';
import {
  SHOW_AD, LIST_PUBLISHERS, LIST_TRACKING_PROFILES_BY_PUBLISHER, SHOW_AD_TRACKER,
} from '../graphql/queries';

type TrackingCodes = {
  trackingCode: string,
  trackingUrl: string,
  impressionUrl?: string,
}

export const useAdGenerateCode = (isOpen: boolean) => {
  const [ad, setAd] = useState('');
  const [adId, setAdId] = useState();
  const [openAd, setOpenAd] = useState(false);
  const [publisherList, setPublisherList] = useState<SelectOption[]>([]);
  const [selectedPublisher, setSelectedPublisher] = useState<SelectOption>({ label: '', value: '' });
  const [trackingProfilesList, setTrackingProfilesList] = useState<SelectOption[]>([]);
  const [selectedTrackingProfile, setSelectedTrackingProfile] = useState<SelectOption>({ label: '', value: '' });
  const [code, setCode] = useState<TrackingCodes>({
    trackingCode: '',
    trackingUrl: '',
    impressionUrl: '',
  });
  const [error, setError] = useState('');
  const [radioSelected, setRadioSelected] = useState('1');
  const [acid, setAcid] = useState('');
  const [trackingProfileDisabled, setTrackingProfileDisabled] = useState(true);
  const [generated, setGenerated] = useState(false);

  const [showAd, { loading: adLoading }] = useLazyQuery(SHOW_AD);
  const [getPublishers, { loading: publisherLoading }] = useLazyQuery(LIST_PUBLISHERS);
  const [getTrackingProfiles, { loading: trackingLoading }] = useLazyQuery(LIST_TRACKING_PROFILES_BY_PUBLISHER);
  const [getAdTracker, { loading: adTrackerLoading }] = useLazyQuery(SHOW_AD_TRACKER);

  const [createAdTracker] = useMutation(CREATE_AD_TRACKER);

  const userHook = useUserInfo();
  const { hookShowToast } = useToast();

  const setTrackingProfileDisabledHandler = () => {
    setTrackingProfileDisabled(false);
  };

  const getAdHandler = async (adID: any) => {
    const { data } = await showAd({
      variables: {
        input: {
          id: adID,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.showAd) {
      setAd(data.showAd);
      setAdId(adID);
      setOpenAd(true);
    }
  };

  const getPublishersHandler = async () => {
    const { data } = await getPublishers({
      variables: {
        id: adId,
      },
    });

    if (data?.adPublisherOptions) {
      const formatted = data.adPublisherOptions.map((publisher: any) => ({
        value: publisher.id,
        label: publisher.companyName,
      }));

      setPublisherList(formatted);
    }
  };

  const getTrackingProfilesHandler = async () => {
    let publisherId: any;

    if (userHook.hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER || userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER) {
      publisherId = userHook.hookWhoAmI.companyId?.toString();
    } else {
      publisherId = selectedPublisher?.value;
    }
    if (!publisherId) return;
    const { data } = await getTrackingProfiles({
      variables: {
        companyId: publisherId,
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.trackings?.trackings) {
      const formatted = data.trackings.trackings.map((tracking: any) => {
        let label;
        if (tracking.profileName !== undefined && tracking.profileName !== null && tracking.profileName !== '') {
          label = `${tracking.id} - ${tracking.profileName}`;
        } else {
          label = tracking.primaryWebsite !== undefined && tracking.primaryWebsite !== null && tracking.primaryWebsite !== ''
            ? `${tracking.id} - ${tracking.primaryWebsite}` : `${tracking.id} - ${tracking.websiteDescription.substring(0, 40)}`;
        }
        return {
          value: tracking.id,
          label,
        };
      });
      setTrackingProfilesList(formatted);
    }
  };

  const setSelectedPublisherHandler = (publisher: any) => {
    setSelectedPublisher(publisher);
    setTrackingProfileDisabledHandler();
    setError('');
    setSelectedTrackingProfile({ label: '', value: '' });
  };

  const setSelectedTrackingProfileHandler = (trackingProfile: any) => {
    setSelectedTrackingProfile(trackingProfile);
    setError('');
  };

  const setAcidHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAcid(e.target.value);
  };

  const generateCodeHandler = async (adInfo: any) => {
    if (selectedPublisher?.value && selectedTrackingProfile?.value) {
      const { data } = await getAdTracker({
        variables: {
          acid: acid || undefined,
          adId,
          trackingProfileId: selectedTrackingProfile?.value,
        },
      });
      if (data?.adTracker?.id) {
        const codeFull = codeGenerator(adInfo, data?.adTracker?.id);
        if (codeFull) setCode(codeFull);
      } else {
        const { data: newAdTracker } = await createAdTracker({
          variables: {
            input: {
              acid: acid || undefined,
              adId,
              trackingProfileId: selectedTrackingProfile?.value,
            },
          },
        });
        if (newAdTracker?.createAdTracker?.adTracker) {
          const { id } = newAdTracker.createAdTracker.adTracker;
          const codeFull = codeGenerator(adInfo, id);
          if (codeFull) setCode(codeFull);
        }
      }
      setGenerated(true);
      setError('');
    } else {
      setError(ERROR_MESSAGES.GENERATE_CODE_PARAMS);
    }
  };

  const setRadioSelectedHandler = (value: any) => {
    setRadioSelected(value);
  };

  const copyTrackingCodeToClipboard = () => {
    navigator.clipboard.writeText(code?.trackingCode || '');
    hookShowToast(COPIED_MESSAGES);
  };

  const copyTrackingUrlToClipboard = () => {
    navigator.clipboard.writeText(code?.trackingUrl || '');
    hookShowToast(COPIED_MESSAGES);
  };

  const copyImpressionUrlToClipboard = () => {
    navigator.clipboard.writeText(code?.impressionUrl || '');
    hookShowToast(COPIED_MESSAGES);
  };

  const clearModalHandler = () => {
    setAd('');
    setPublisherList([]);
    setSelectedPublisher({ label: '', value: '' });
    setTrackingProfilesList([]);
    setSelectedTrackingProfile({ label: '', value: '' });
    setCode({
      trackingCode: '',
      trackingUrl: '',
      impressionUrl: '',
    });
    setError('');
    setRadioSelected('1');
    setAcid('');
    setGenerated(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setTimeout(clearModalHandler, 400);
      setOpenAd(false);
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedPublisherHandler(publisherList[0]);
  }, [publisherList]);

  useEffect(() => {
    setSelectedTrackingProfileHandler(trackingProfilesList[0]);
  }, [trackingProfilesList]);

  useEffect(() => {
    getTrackingProfilesHandler();
  }, [selectedPublisher]);

  useEffect(() => {
    setCode({
      trackingCode: '',
      trackingUrl: '',
      impressionUrl: '',
    });
  }, [selectedPublisher, selectedTrackingProfile, acid]);

  return {
    hookAd: ad,
    hookOpenAd: openAd,
    hookGetAd: getAdHandler,
    hookGetPublishers: getPublishersHandler,
    hookPublisherList: publisherList,
    hookGetTrackingProfiles: getTrackingProfilesHandler,
    hookSelectedPublisher: selectedPublisher,
    hookSetSelectedPublisher: setSelectedPublisherHandler,
    hookTrackingProfilesList: trackingProfilesList,
    hookAdLoading: adLoading,
    hookPublisherLoading: publisherLoading,
    hookTrackingLoading: trackingLoading,
    hookGenerateCode: generateCodeHandler,
    hookCode: code,
    hookError: error,
    hookSelectedTrackingProfile: selectedTrackingProfile,
    hookSetSelectedTrackingProfile: setSelectedTrackingProfileHandler,
    hookRadioSelected: radioSelected,
    hookSetRadioSelected: setRadioSelectedHandler,
    hookCopyTrackingCodeToClipboard: copyTrackingCodeToClipboard,
    hookCopyTrackingUrlToClipboard: copyTrackingUrlToClipboard,
    hookCopyImpressionUrlToClipboard: copyImpressionUrlToClipboard,
    hookSetAcid: setAcidHandler,
    hookAcid: acid,
    hookAdTrackerLoading: adTrackerLoading,
    hookClearModal: clearModalHandler,
    hookTrackingProfileDisabled: trackingProfileDisabled,
    hookGenerated: generated,
  };
};
