import styled from 'styled-components';
import { toRem } from '../../../../../../../utils';
import { fonts, colors } from '../../../../../../../styles/theme';

type InputWrappersProps = {
  isBottom?: boolean
};

type MidSectionWrapperProps = {
  isRow?: boolean
};

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InnerWrapperStyled = styled.div`
  width: 100%;
  margin-bottom: ${toRem(72)};
  margin-top: ${toRem(30)};
`;

export const InputWrappers = styled.div<InputWrappersProps>`
  display: grid;
  grid-template-columns: ${({ isBottom }) => (isBottom ? '1fr 2fr' : '2fr 1fr')};
  gap: ${toRem(32)};
  margin-top: ${({ isBottom }) => (isBottom ? toRem(40) : 'auto')};
`;

export const MidSectionWrapper = styled.div<MidSectionWrapperProps>`
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
  gap: ${toRem(36)};
`;

export const SingleLineWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${toRem(36)};
  margin-top: ${toRem(36)};
`;

export const LabeledCheckWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
`;

export const LabelTextStyled = styled.span`
  display: flex;
  justify-content: flex-start;
  text-transform: uppercase;
  color: ${colors.color13};
  ${fonts.gotham.H12M}
`;

export const CheckBoxesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-width: ${toRem(500)};
  row-gap: ${toRem(12)};
  
`;

export const WrapperTitleStyled = styled.span`
  ${fonts.gotham.H26};
  color: ${colors.color1};
  margin-bottom: ${toRem(40)};
`;
