import styled from 'styled-components';
import {
  Button, InputText, InputTextarea, Select,
} from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const WrapperStyled = styled.div`
  width: 720px;
  padding: 1rem;
`;

export const CloseButtonStyled = styled(Button)`
  border: transparent;
  font-size: 1.2rem;

  &:hover {
    border: transparent;
    background: transparent;
    color: ${colors.color2};
  }
`;

export const TopStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const ContentStyled = styled.div`
  margin-bottom: 5px;
  max-height: 600px
  overflow-y: scroll;
`;

export const LinksStyled = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -20px;
`;

export const FooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const InputTextStyled = styled(InputText)`
  margin-bottom: 2rem;
`;

export const TextareaStyled = styled(InputTextarea)`
  margin-bottom: 2rem;
`;

export const SelectStyled = styled(Select)`
  margin-bottom: 2rem;
`;

export const BoldText = styled.span`
font-style: italic;
`;

export const AnchorStyled = styled.a`
  ${fonts.lato.H14}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  
`;

export const CSRow1 = styled.div`
 display:flex;
 flex-direction: column;
 margin-bottom: 20px;
`;

export const CSRow2 = styled.div`
 display: flex;
 & > div {
  flex: 1 0 0;
  & > div {
  }
 }
`;

export const CSRow3 = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: 7px;
  }
`;

export const CSRow4 = styled.div`
 display: flex;
`;

export const CSRow5 = styled.div`
display:flex;
`;

export const WarningMessageBox = styled.div`
  display: flex;
  background-color: ${colors.color20};
  width: auto;
  height: auto;
  padding: 3px;
  border-radius: 3px;
  margin-left: 15px;
  justify-content: flex-start;
  align-items: center;
`;

export const Icon1 = styled.div`
  height: 10px;
`;

export const WarningMessageText1 = styled.div`
  font-size: 12px;
  margin-left: 5px;
  font-weight: bold;
  color: ${colors.color2};
`;

export const Heading1 = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: ${colors.color24};
  ${fonts.gotham.H12M}
  font-weight: bold !important;
`;

export const Heading2 = styled.div`
  margin-bottom: 20px;
  color: #022658 !important;
  font-weight: bold;
  ${fonts.gotham.H16M}
  display:flex;
`;

export const SubHeading1 = styled.div`
  font-size: 18px;
  margin-left: 10px;
  color: black;
`;

export const CommissionText1 = styled.div`
  font-size: 15px;
  color: ${colors.color1};
`;

export const CommissionText2 = styled.div`
  font-style: italic;
  font-size: 14px;
  margin-bottom: 4px;
  color: ${colors.color1};
`;

export const BorderElement = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  border-top:1px solid ${colors.color3} ;
`;

export const PublisherGroupSection1 = styled.div`
  border: 1px solid ${colors.color3};
  display: flex;
  padding: 10px;
  overflow-y: scroll;
  max-height: 150px;
  flex-wrap: wrap;
`;

export const PublisherGroupBox1 = styled.div`
  border: 1px solid ${colors.color3};
  border-radius: 20px;
  margin-right: 4px;
  margin-bottom: 5px;
  padding: 10px;
  background-color: ${colors.color3};
  width: auto;
  font-size: 12px;
`;
