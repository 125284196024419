import React, { useState } from 'react';
import { imageList } from '../../utils';
import * as Styled from './styles';

type ImagePreviewProps = {
  src: string;
  alt?: string;
  width?: string;
  useType?: string;
};

export const ImagePreview = ({
  src, alt, width = '40px', useType = '',
}: ImagePreviewProps) => {
  const [onHover, setOnHover] = useState(false);

  return (
    <Styled.ImagesWrapper
      useType={useType}
    >
      <Styled.ImageStyled
        src={src}
        alt={alt}
        width={width}
        useType={useType}
        onMouseOver={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      />
      {onHover && src !== imageList.noPic.src && (
      <Styled.EnlargedImageWrapper
        useType={useType}
      >
        <Styled.EnlargedImageStyled
          src={src}
          alt={alt}
          onMouseOver={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
        />
      </Styled.EnlargedImageWrapper>
      )}
    </Styled.ImagesWrapper>
  );
};
