import { gql } from '@apollo/client';

export const SEARCH_CAMPAIGN = gql`
  query listSearchCampaigns($input: ListSearchCampaignInput) {
    listSearchCampaigns(input: $input) {
      count
      campaigns {
        campaignId
        campaignName
        description
        bannerCount
        linkCount
        html5Count
        merchantName
        merchantId
        programId
      }
    }
  }
`;
