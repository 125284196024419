import { gql } from '@apollo/client';

export const ESTIMATE_PAYMENTS = gql`
 mutation EstimatePayments($month: String, $year: String) {
  estimatePayments(month: $month, year: $year) {
    payments {
      feasible
      publisher {
        id
        companyName
      }
      totalCommissions
      totalTaxes
      currentRollover
      bonus
      bonusTax
      paymentMethod
      currency
      totalPayable
      periodBalance
    }
    count
  }
}
`;
