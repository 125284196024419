export const SEARCH_PROGRAMS = {
  DESCRIPTION: 'Our New Publisher Programs',
  FILTERS: {
    SEARCH: 'Search for Name/ID/URL...',
    SORT_BY: 'Sort by',
    PRODUCT_CATEGORY: 'Select Product Category',
    TARGET_MARKET: 'Select Target Market',
  },
  CLEAR_FORM: 'Clear Form',
  OPTIONS: {
    TARGET_MARKET: [
      {
        label: 'All',
        value: '',
      },
      {
        label: 'US only',
        value: 'usOnly',
      },
      {
        label: 'US and Canada',
        value: 'usAndCanada',
      },
      {
        label: 'Canada only',
        value: 'canada',
      },
      {
        label: 'Canada only, excluding Quebec',
        value: 'canadaExcludingQuebec',
      },
      {
        label: 'International',
        value: 'international',
      },
    ],
    PRODUCT_CATEGORY: [
      { label: 'All Categories', value: '' },
      { label: 'Banking', value: 'Banking' },
      { label: 'Business Products', value: 'Businesses' },
      { label: 'Credit Cards', value: 'Credit Cards' },
      { label: 'Credit Reporting', value: 'Credit Reporting' },
      { label: 'Cryptocurrency', value: 'Cryptocurrency' },
      { label: 'Insurance', value: 'Insurance' },
      { label: 'Investments', value: 'Investments' },
      { label: 'Loans', value: 'Loans' },
      { label: 'Mortgage', value: 'Mortgage' },
      { label: 'Real Estate', value: 'Real Estate' },
      { label: 'Tax Services', value: 'Tax Services' },
      { label: 'Wills & Estate Planning', value: 'Wills & Estate Planning' },
    ],
  },
};
