import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import * as Styled from '../../styles';
import {
  Button, Modal, InputText, Select,
} from '../../../../../../../../components';
import { CriteriaOption } from '../../hooks';
import { STEP_TWO_MODAL } from '../../enums';

type AddRuleStepTwoProps = {
  criteriaList: CriteriaOption[]
  isOpen: boolean
  backButtonHandler: () => void
  selectedCriteria: SelectOption | undefined
  setSelectedCriteriaHandler: (value: SelectOption) => void
  selectedProductFeed: SelectOption | undefined
  productFeedList: SelectOption[]
  setSelectedProductFeed: (value: SelectOption) => void
  customTerm: string
  setCustomTerm: (e: React.ChangeEvent<HTMLInputElement>) => void
  cancelButtonHandler: () => void
  navigateModalThree: () => void
  canContinueTwo: boolean
}

export const AddRuleStepTwoModal = ({
  isOpen,
  backButtonHandler,
  selectedCriteria,
  setSelectedCriteriaHandler,
  selectedProductFeed,
  productFeedList,
  setSelectedProductFeed,
  customTerm,
  setCustomTerm,
  cancelButtonHandler,
  navigateModalThree,
  canContinueTwo,
  criteriaList,
}: AddRuleStepTwoProps) => {
  const criteriaFormatted = criteriaList.map((opt) => ({
    value: opt.value,
    label: (
      <div>
        <Styled.CriteriaTitleStyled className="optionLabel">{opt.label}</Styled.CriteriaTitleStyled>
        <div className="optionDescription">{opt.description}</div>
      </div>
    ),
  }));
  return (
    <Modal isOpen={isOpen}>
      <Styled.ModalWrapperStyled>
        <Styled.PageTitleStyled>
          <Styled.BackIconStyled
            onClick={backButtonHandler}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Styled.BackIconStyled>
          {STEP_TWO_MODAL.TITLE}
        </Styled.PageTitleStyled>
        <Styled.DropdownSectionStyled>
          <Styled.SubTitleTextStyled>
            {STEP_TWO_MODAL.DESCRIPTION}
          </Styled.SubTitleTextStyled>
          <Styled.TextStyled>
            {STEP_TWO_MODAL.GUIDE}
          </Styled.TextStyled>
          <Styled.SelectStyled
            required
            name="criteria"
            label={STEP_TWO_MODAL.CRITERIA.LABEL}
            tooltip={STEP_TWO_MODAL.CRITERIA.TOOLTIP}
            placeholder={STEP_TWO_MODAL.CRITERIA.PLACEHOLDER}
            selected={selectedCriteria}
            options={criteriaFormatted}
            onChange={setSelectedCriteriaHandler}
            optionBorder
          />
          {selectedCriteria?.value === 'productField' && (
          <Select
            required
            name="selectField"
            label={STEP_TWO_MODAL.SELECT_FIELD.LABEL}
            tooltip={STEP_TWO_MODAL.SELECT_FIELD.TOOLTIP}
            placeholder={STEP_TWO_MODAL.SELECT_FIELD.PLACEHOLDER}
            selected={selectedProductFeed}
            options={productFeedList}
            onChange={setSelectedProductFeed}
          />
          )}
          {selectedCriteria?.value === 'customTerm' && (
          <InputText
            required
            type="text"
            name="provideField"
            label={STEP_TWO_MODAL.PROVIDE_FIELD.LABEL}
            tooltip={STEP_TWO_MODAL.PROVIDE_FIELD.TOOLTIP}
            value={customTerm}
            onChange={setCustomTerm}
          />
          )}
        </Styled.DropdownSectionStyled>
        <Styled.ModalFooterStyled>
          <Styled.PageCounterStyled>
            {STEP_TWO_MODAL.STEP_COUNTER}
          </Styled.PageCounterStyled>
          <Styled.NavButtonsWrapperStyled>
            <Button
              theme="secondary"
              onClick={cancelButtonHandler}
              width="100px"
            >
              {STEP_TWO_MODAL.CANCEL_BUTTON}
            </Button>
            <Button
              theme="primary"
              onClick={navigateModalThree}
              disabled={!canContinueTwo}
              width="120px"
            >
              {STEP_TWO_MODAL.CONTINUE_BUTTON}
            </Button>
          </Styled.NavButtonsWrapperStyled>
        </Styled.ModalFooterStyled>
      </Styled.ModalWrapperStyled>
    </Modal>
  );
};
