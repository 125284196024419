import { gql } from '@apollo/client';

export const DELETE_MEMBERS = gql`
  mutation updateMembershipGroup($inputs: [MembershipInput]) {
    updateMembershipGroup(inputs: $inputs) {
      count
      memberships {
        id
      }
    }
  }
`;
