export const PUBLISHER_INFO = {
  DESCRIPTION: 'The below information is important for positioning your brand in Fintel Connect as it is what prospective partners view when determining who to work with. Best practice is to complete this at the sign up stage and then you can update it at any time in the system as your brand evolves.',
  NEXT: 'Next',
  BACK: 'Previous step',

  PROFILE_PHOTO: {
    LABEL: 'Profile Photo',
    TOOLTIP: 'Upload your branding logo to receive more recognition. File type: PNG or JPEG. File size must be less than 3MB',
    ERROR: 'Error Uploading Profile Photo',
  },

  COMPANY_URL: {
    LABEL: 'primary platform link',
    PLACEHOLDER: 'https://www.your.site',
    TOOLTIP: 'Choose the primary platform you will use to promote your affiliate links. This can be a website, Youtube channel, or social media profile.',
  },

  WEBSITE: {
    LABEL: 'I don\'t have a website',
  },

  BUSINESS_DESCRIPTION: {
    LABEL: 'business description',
    PLACEHOLDER: 'Please describe your platform\'s unique selling point, audience overview, and where you see financial products fitting on the site. Max length is 1500 characters.',
  },

  AUDIENCE_MARKET: {
    LABEL: 'primary audience market(s)',
    TOOLTIP: 'Please choose the primary markets your audience resides in to best match you with client products.',
  },

  AUDIENCE_SIZE: {
    LABEL: 'average montlhy audience size',
    TOOLTIP: 'Proving audience data for your platform(s) provides transparency with merchants, a foundational aspect of affiliate partnerships. For websites, select your average monthly visits - for other platforms, select your subscriber or follower total.',
  },

  PRODUCT_CATEGORIES: {
    LABEL: 'product categories',
    TOOLTIP: 'Which product types are you currently ready and able to promote?',
  },

  PROMOTION_METHODS: {
    LABEL: 'promotion method(s)',
    TOOLTIP: 'Sharing your trusted promotional methods will help us understand your site and find ideal merchants for you.',
  },

  DEMOGRAPHICS: {
    TITLE: 'Demographics',
    DESCRIPTION: 'Providing audience demographics helps match you with merchant products.',
  },

  PRIMARY_AUDIENCE_MARKETS: [
    {
      label: 'United States',
      value: 'United States',
    },
    {
      label: 'Europe',
      value: 'Europe',
    },
    {
      label: 'Canada',
      value: 'Canada',
    },
    {
      label: 'South East Asia',
      value: 'South East Asia',
    },
    {
      label: 'United Kingdom',
      value: 'United Kingdom',
    },
    {
      label: 'Australia & New Zealand',
      value: 'Australia & New Zealand',
    },
  ],
  AVERAGE_MONTHLY_AUDIENCE_SIZE: [
    {
      label: 'Under 5,000',
      value: 'Under 5,000',
    },
    {
      label: '5,000 - 25,000',
      value: '5,000 - 25,000',
    },
    {
      label: '25,000 - 100,000',
      value: '25,000 - 100,000',
    },
    {
      label: '100,000 - 250,000',
      value: '100,000 - 250,000',
    },
    {
      label: '250,000 - 500,000',
      value: '250,000 - 500,000',
    },
    {
      label: '500,000 - 1,000,000',
      value: '500,000 - 1,000,000',
    },
    {
      label: '1,000,000 +',
      value: '1,000,000 +',
    },
  ],
  AUDIENCE_AGES: [
    {
      label: 'All ages',
      value: 'All ages',
    },
    {
      label: 'Under 18',
      value: 'Under 18',
    },
    {
      label: '18 - 24',
      value: '18 - 24',
    },
    {
      label: '25 - 34',
      value: '25 - 34',
    },
    {
      label: '35 - 44',
      value: '35 - 44',
    },
    {
      label: '45 +',
      value: '45 +',
    },
  ],
  GENDERS: [
    {
      label: 'Mostly Male',
      value: 'Mostly Male',
    },
    {
      label: 'Mostly Female',
      value: 'Mostly Female',
    },
    {
      label: 'Any Gender',
      value: 'Any Gender',
    },
  ],
  AVERAGE_INCOMES: [
    {
      label: 'Less than $20,000',
      value: 'Less than $20,000',
    },
    {
      label: '$20,000 - $34,999',
      value: '$20,000 - $34,999',
    },
    {
      label: '$35,000 - $49,999',
      value: '$35,000 - $49,999',
    },
    {
      label: '$50,000 - $74,999',
      value: '$50,000 - $74,999',
    },
    {
      label: '$75,000 - $99,999',
      value: '$75,000 - $99,999',
    },
    {
      label: '$100,000 - $149,999',
      value: '$100,000 - $149,999',
    },
    {
      label: '$150,000 - $199,999',
      value: '$150,000 - $199,999',
    },
    {
      label: '$200,000 or more',
      value: '$200,000 or more',
    },
  ],
  CREDIT_SCORE_RATINGS: [
    {
      label: 'Unknown',
      value: 'Unknown',
    },
    {
      label: 'Poor',
      value: 'Poor',
    },
    {
      label: 'Fair',
      value: 'Fair',
    },
    {
      label: 'Good',
      value: 'Good',
    },
    {
      label: 'Very Good',
      value: 'Very Good',
    },
    {
      label: 'Excellent',
      value: 'Excellent',
    },
  ],
};
