export const BLACKLIST_DOMAINS = {
  BUTTONS: {
    BACK: 'Back',
    ADD: 'Add Domain',
    DOWNLOAD: 'Download CSV',
    REMOVE: 'Remove Domains',
    CLEAR: 'Clear Form',
  },
  PLACEHOLDERS: {
    INPUT_TEXT: 'https://www.fintelconnect.com',
  },
  MODAL: {
    TITLE: {
      ADD: 'Add Domain',
      EDIT: 'Edit Domain',
      REMOVE: 'Remove Domain',
    },
    BUTTON: {
      ADD: 'Add',
      EDIT: 'Save',
      REMOVE: 'Remove',
      CANCEL: 'Cancel',
    },
    TYPES: {
      ADD: 'ADD',
      EDIT: 'EDIT',
      REMOVE: 'REMOVE',
      REMOVE_MANY: 'REMOVE_MANY',
    },
  },
  ERROR: {
    DOMAIN_COLLISION: 'Domain already in blacklist',
  },
};
