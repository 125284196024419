import { gql } from '@apollo/client';

export const LIST_COUNTRY_PROGRAMS = gql`
  query countryPrograms($country: String) {
    countryPrograms(country: $country) {
      count
      programs {
        id
        termsConditionsTitle
        termsConditions
        merchant {
          id
          companyName
          companyUrl
        }
      }
    }
  }
`;
