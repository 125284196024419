import styled from 'styled-components';
import { colors, fonts } from '../../../styles/theme';
import { Button, InputText, Select } from '../../../components';

export const DescriptionStyled = styled.div`
  ${fonts.lato.H16}
  color: ${colors.color17};
  margin: 2rem 0;
`;

export const FilterWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const InputTextStyled = styled(InputText)`
  flex: 2;
  margin-right: 2rem;
`;

export const SelectStyled = styled(Select)`
`;

export const FiltersSelectStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-basis: 30%;
  width: 100%;
  ${SelectStyled}:nth-of-type(2) {
    margin: 0 1rem;
  }
`;

export const FiltersStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  ${FiltersSelectStyled} > span {
    margin-right: 1rem;
    ${fonts.lato.H14M}
    color: ${colors.color17};
  }
  ${FiltersSelectStyled}:nth-of-type(2) {
    margin-top: 2rem;
  }
`;

export const ClearFormButtonStyled = styled(Button)`
  display: flex;
  align-self: flex-end;
  width: 20%;
`;

export const RowStyled = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
