import { gql } from '@apollo/client';

export const LIST_USER_TYPES = gql`
  query List {
    listUserType {
      list {
        id
        name
      }
    }
  }
`;
