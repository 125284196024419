import styled from 'styled-components';
import { colors, fonts } from '../../styles/theme';
import { toRem } from '../../utils';
import { Button } from '../Button';
import { Select } from '../Input';

type FieldStyledProps = {
  columns: string;
  flex?: string;
  margin?: string;
  borderBottom?: boolean;
}

type ButtonWrapperProps = {
  borderBottom?: boolean;
}

type ButtonStyledProps = {
  selected?: boolean;
}

type ButtonContainerProps = {
  selected?: boolean;
}

export const WrapperStyled = styled.div`
  width: 1100px;
  padding: 1rem 2rem 1rem 2rem;
`;

export const SelectStyled = styled(Select)`
  margin: 2rem 0;
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)<ButtonStyledProps>`
  ${fonts.gotham.H16M};
  color: ${({ selected }) => (selected ? `${colors.color1}` : `${colors.color19}`)};
  text-decoration: none;

  :hover {
    text-decoration: none;
    color: ${colors.color1};
  }
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
padding-top: 30px;
  ${({ selected }) => { if (selected) return `border-bottom: 3px solid ${colors.color2}`; }};
`;

export const FieldStyled = styled.div<FieldStyledProps>`
  display: grid;
  align-items: ${({ flex }) => (flex || 'flex-end')};
  grid-template-columns: ${({ columns }) => (columns)};
  grid-gap: 2%;
  row-gap: 2rem;
  margin: ${({ margin }) => (margin || '2rem 0')};
`;

export const SectionTitleStyled = styled.p`
  ${fonts.gotham.H12M}
  color: ${colors.color13};
`;

export const PublisherGroupStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2%;
  row-gap: 1rem;
  margin: 1.5rem 0;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  gap: 12px;
  ${({ borderBottom }) => { if (borderBottom) return `border-bottom: 1px solid ${colors.color3}`; }};
`;

export const RequiredFieldsError = styled.div`
  ${fonts.lato.H16}
  color: ${colors.color14};
  width: 100%;
  border: 2px solid ${colors.color14};
  padding: ${toRem(15)};
  margin-top: ${toRem(30)};
`;

export const RequiredTextStyled = styled.span`
  ${fonts.lato.H16};
  color: ${colors.color14};
  padding-left: ${toRem(10)};
`;
