import { gql } from '@apollo/client';

export const UPDATE_COMMISSION = gql`
  mutation updateCommission($input: CommissionInput!) {
    updateCommission(input: $input) {
      commission {
        id
      }
    }
  }
`;

export type UpdateCommissionInput = {
  input: {
    id: string
    endDate: Date | string
    commissionName: string
    commissionTarget?: {
      productIds: string[] | null[]
      publisherTargets?: {
        publisherId: string
        startDate: Date
        endDate: Date | string | null
      }[]
      publisherGroupTargets?: {
        publisherGroupId: string
        startDate: Date
        endDate: Date | string | null
      }[]
    }
  }
}

export type UpdateCommissionOutput = {
  updateCommission: {
    commission: {
      id: string
    }
  }
}
