export const PAGE_TITLE = 'Publisher Account Settings';

export const FORM_SECTION_TITLE = {
  CONTACT: 'Contact Information',
  USER: 'User Management',
  PAYMENT: 'Payment Details',
  PAYMENT_CONTACT: 'Payment Contact Details',
  ACCESS_KEY: 'API Access Key',
};

export const BUTTON_LABEL = {
  EDIT: 'Edit',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  ADD_USER: 'Add User',
  INVITE_USER: 'Invite User',
  GENERATE_NEW_KEY: 'Generate New Key/URL',
  COPY_KEY: 'Copy Key',
  COPY_URL: 'Copy URL',
  DEACTIVATE: 'Deactivate',
  ACTIVATE: 'Activate',
};

export const INPUT_LABEL = {
  ID: 'ID',
  ACCOUNT_STATUS: 'Account Status',
  COMPANY_NAME: 'Company Name',
  ACTIVITY_STATUS: 'Activity Status',
  REGISTRATION_DATE: 'Registration Date',
  ADDRESS: 'Address 1',
  REGION: 'State/Province',
  PHONE_NUMBER: 'Primary Phone Number',
  ADDRESS_TWO: 'Address 2',
  COUNTRY: 'Country',
  PHONE_NUMBER_SECONDARY: 'Secondary Phone Number',
  CITY: 'City',
  POST_CODE: 'Zip/Postal Code',
  CONPANY_EMAIL: 'Email Address',
  TAXATION: 'Taxation #',
  GST: 'GST #',
  PST: 'PST #',
  HST: 'HST #',
  CURRENCY: 'Currency',
  PAYMENT_METHOD: 'Payment Method',
  ACCOUNT_HOLDER: 'Account Holder',
  ACCOUNT_EMAIL: 'Account Email Address',
  ACCOUNT_PHONE_NUMBER: 'Phone Number',
  PAYMENT_CONTACT_ADDRESS: 'Address',
  PAYMENT_CONTACT_EMAIL: 'Payment Contact Email Address',
  GENERATE_ACCESS_KEY: 'Generate Api Access Key',
  ACCESS_STATUS: 'Api Access Status',
  TRANSACTION_DETAIL: 'Transaction Details API URL',
  ACID: 'ACID Click API URL',
  TRACKING_SCRIPT: 'Tracking Script',
  BANK_NAME: 'BANK NAME',
  BANK_ACCOUNT_NUMBER: 'BANK ACCOUNT NUMBER',
  SWIFT_CODE: 'SWIFT CODE / IBAN / ROUTING',
  TRANSIT: 'TRANSIT',
  WIRE_TRANSFER_NAME: 'WIRE TRANSFER NAME',
  PAYABLE_TO: 'Payable To',
};

export const TAX_PLACEHOLDER = {
  TAX: '123 456 789',
  HST: '123456789RT0001',
  GST: '123456789RT0001',
  PST: 'PST12345678',
};

export const PLACEHOLDER = {
  BILLING_CONTACT: 'account@merchant.com',
};

export const BOX_INFO = {
  TAX: {
    title: 'Tax Number',
    description: 'You can choose to enter your tax information at any time in Fintel Connect by navigating to your Account Settings. Please note, tax will only calculate once your number has been added and verified.',
  },
};

export const TRANSACTION_CURRENCY: { [key: string]: string } = {
  CAD: 'Canadian Dollars (CAD $)',
  USD: 'US Dollars (USD $)',
};

export const CURRENCY_OPTIONS: SelectOption[] = [
  {
    label: 'Canadian Dollars (CAD $)',
    value: 'CAD',
  },
  {
    label: 'US Dollars (USD $)',
    value: 'USD',
  },
];

export const paymentMethodMinimums = (paymentMethod: any, currency: any) => {
  const Cheque: any = {
    AUD: 60,
    CAD: 75,
    USD: 60,
  };
  const chequeThousand: any = {
    USD: 1000,
    CAD: 1000,
    AUD: 1000,
  };
  const Paypal: any = {
    AUD: 20,
    CAD: 25,
    USD: 20,
  };
  const wireTransfer: any = {
    USD: 500,
    CAD: 500,
    AUD: 500,
  };

  if (currency !== undefined) {
    if (paymentMethod === 'Cheque') return Cheque[currency];
    if (paymentMethod === 'Cheque Payment for $1000') return chequeThousand[currency];
    if (paymentMethod === 'Paypal') return Paypal[currency];
    if (paymentMethod === 'Wire Transfer') return wireTransfer[currency];
  }
  return 'Currency not Selected';
};

const countries = {
  ca: 'Canada',
  us: 'United States',
  au: 'Australia',
  other: 'Other',
};

const hstStates = [
  'New Brunswick',
  'Newfoundland and Labrador',
  'Nova Scotia',
  'Ontario',
  'Prince Edward Island',
];

const gstStates = ['Alberta', 'Northwest Territories', 'Nunavut', 'Yukon Territory'];

const gstpstStates = ['British Columbia', 'Manitoba', 'Saskatchewan'];

const qstStates = ['Quebec'];

export const disableTaxField = (country: any, state: any, name: any) => {
  // Canada
  if (country === countries.ca) {
    if (hstStates.includes(state)) {
      return name !== 'hst';
    }
    if (gstStates.includes(state)) {
      return name !== 'gst';
    }
    if (gstpstStates.includes(state)) {
      return name !== 'gst' && name !== 'pst';
    }
    if (qstStates.includes(state)) {
      return name !== 'pst' && name !== 'gst';
    }
    return true;
  }

  // Australia, United States and other countries
  return name !== 'tax';
};

export const getTaxPlaceholder = (name: any) => {
  switch (name) {
    case 'tax':
      return '123 456 789';
    case 'hst':
      return '123456789RT0001';
    case 'gst':
      return '123456789RT0001';
    case 'pst':
      return 'PST12345678';
    default:
      return '';
  }
};

export const GENERATE_API_CONFIRMATION = 'Are you sure you want to generate a new API key?';
export const GENERATE_API_PART_1 = 'The current key was generated';
export const GENERATE_API_PART_2 = '. Please note that any applications that use the previous API key will have to be updated to support the new API key.';

export const maskValue = (value: any) => {
  if (!value || value.length <= 4) {
    return value;
  }
  const maskedChars = '*'.repeat(value.length - 4);
  const lastFourChars = value.slice(-4);
  return maskedChars + lastFourChars;
};
