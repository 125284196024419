import React from 'react';
import { faCircleInfo, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper, PageTitle, Pagination, Radio,
  Button,
  Table,
  ErrorBanner,
} from '../../../../components';
import { path, PRODUCT_CATEGORY } from '../../../../utils';
import { PRODUCT_FEED } from './enums';
import { useProductFeed } from './hooks/useProductFeed';
import { columns } from './contracts';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type ProductFeedProps = DefaultPropsType;

const ProductFeed = ({ permissionsCodeList = [] }: ProductFeedProps) => {
  const { hookIsReadOnlyList, ...hooks } = useProductFeed(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        isOpen={hooks.hookIsError}
        message={hooks.hookErrorMessage}
      />
      <PageTitle>{path.productFeed.name}</PageTitle>
      <Styled.InfoBoxStyled
        icon={faCircleInfo}
        title={PRODUCT_FEED.INFO_BOX.TITLE}
        description={PRODUCT_FEED.INFO_BOX.DESCRIPTION}
        description2={PRODUCT_FEED.INFO_BOX.DESCRIPTION2}
      />

      <Styled.GridStyled columns={6}>
        <Styled.Select2Styled
          isLoading={hooks.loading}
          required
          options={hooks.hookTrackingOptions}
          selected={hooks.hookTrackingProfileId}
          label={PRODUCT_FEED.TRACKING_PROFILE_ID}
          onChange={hooks.hookChangeTrackingProfileId}
          error={hooks.hookTrackingError.tracking}
          isDisabled={isReadOnly}
        />
        <Styled.Select2Styled
          isLoading={hooks.loading}
          label={PRODUCT_FEED.MARKET_COUNTRY}
          selected={hooks.hookMarketCountry}
          options={hooks.hookCountryOptions}
          placeholder={PRODUCT_FEED.PLACEHOLDERS.MARKET}
          onChange={hooks.hookChangeMarketCountry}
          isDisabled={isReadOnly}
        />
        <Styled.Select2Styled
          isLoading={hooks.loading}
          label={PRODUCT_FEED.STATE_PROVINCE}
          selectedMulti={hooks.hookStates}
          isMulti
          options={hooks.hookStatesOptions}
          placeholder={PRODUCT_FEED.PLACEHOLDERS.STATE}
          onChange={hooks.hookChangeStates}
          isDisabled={isReadOnly}
        />
        <Styled.Select3Styled
          isLoading={hooks.loading}
          label={PRODUCT_FEED.MERCHANT}
          onChange={hooks.hookChangeMerchants}
          selectedMulti={hooks.hookMerchants}
          options={hooks.hookMerchantOptions}
          placeholder={PRODUCT_FEED.PLACEHOLDERS.MERCHANT}
          isMulti
          isDisabled={isReadOnly}
        />
        <Styled.Select3Styled
          isLoading={hooks.loading}
          label={PRODUCT_FEED.PRODUCT_ID}
          onChange={hooks.hookChangeProductIds}
          isMulti
          selectedMulti={hooks.hookProductIds}
          options={hooks.hookProductIdOptions}
          placeholder={PRODUCT_FEED.PLACEHOLDERS.PRODUCT_ID}
          isDisabled={isReadOnly}
        />
        <Styled.Select3Styled
          isLoading={hooks.loading}
          label={PRODUCT_FEED.PRODUCT_CATEGORY}
          options={PRODUCT_CATEGORY}
          onChange={hooks.hookChangeProductCategory}
          selectedMulti={hooks.hookProductCategory}
          placeholder={PRODUCT_FEED.PLACEHOLDERS.PRODUCT_CATEGORY}
          isMulti
          isDisabled={isReadOnly}
        />
        <Styled.Select3Styled
          isLoading={hooks.loading}
          label={PRODUCT_FEED.PRODUCT_SUBCATEGORY}
          options={hooks.hookSubCategoryOptions}
          selectedMulti={hooks.hookSubCategories}
          placeholder={PRODUCT_FEED.PLACEHOLDERS.PRODUCT_SUBCATEGORY}
          isMulti
          onChange={hooks.hookChangeSub}
          isDisabled={isReadOnly}
        />
        <Styled.ClearFormWrapper>
          <Button
            theme="text-only"
            onClick={hooks.hookHandleClearForm}
          >
            {PRODUCT_FEED.CLEAR_FORM}
          </Button>
        </Styled.ClearFormWrapper>
        <Styled.TextareaStyled
          disabled
          value={hooks.hookProductFeedUrl}
          label={PRODUCT_FEED.PRODUCT_FEED_URL}
        />
        <Styled.EmptyDiv>
          <Styled.LabelStyled>{PRODUCT_FEED.URL_FILE_TYPE}</Styled.LabelStyled>
          <Styled.FileTypeStyled>
            <Styled.RadioWrapperStyled>
              <Radio
                checked={hooks.hookXmlChecked}
                name={PRODUCT_FEED.XML}
                value=""
                onChange={hooks.hookChangeChecked}
              />
              {PRODUCT_FEED.XML}
            </Styled.RadioWrapperStyled>
            <Styled.RadioWrapperStyled>
              <Radio
                checked={hooks.hookJsonChecked}
                name="json"
                value=""
                onChange={hooks.hookChangeChecked}
              />
              {PRODUCT_FEED.JSON}
            </Styled.RadioWrapperStyled>
          </Styled.FileTypeStyled>
          <Styled.FileTypeStyled>
            <Styled.ButtonStyled
              theme="secondary"
              onClick={hooks.hookHandleGenerateUrl}
              loading={hooks.hookCreateLoading}
              disabled={isReadOnly}
            >
              {PRODUCT_FEED.GENERATE_URL}
            </Styled.ButtonStyled>
            <Styled.ButtonStyled onClick={hooks.hookHandleCopyUrl}>
              <FontAwesomeIcon icon={faCopy} />
              {hooks.hookCopyUrl}
            </Styled.ButtonStyled>
          </Styled.FileTypeStyled>
        </Styled.EmptyDiv>
      </Styled.GridStyled>

      <Styled.TitleStyled>{PRODUCT_FEED.ACTIVE_PRODUCT}</Styled.TitleStyled>
      {(hooks.loading || hooks.hookProductFeeds.length !== 0) && (
      <Table
        columns={columns}
        data={hooks.hookProductFeeds}
        isLoading={hooks.loading}
        onSort={hooks.hookOnSortHandler}
        sortColumn={hooks.hookSortColumn}
        onRowClick={hooks.hookOnRowClick}
      />
      )}
      {(hooks.loading || hooks.hookProductFeeds.length !== 0)
      && (
      <Pagination
        currentPage={hooks.hookPage}
        total={hooks.hookTotalPage}
        onPageChange={hooks.hookChangePage}
      />
      )}
    </AppWrapper>
  );
};

export default ProductFeed;
