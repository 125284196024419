import { useState } from 'react';

export const useTableData = (data: any[]) => {
  const [dataState, setDataState] = useState(data);

  return {
    hookData: dataState,
    hookSetData: (newData: any[]) => {
      setDataState(newData);
    },
  };
};
