import { gql } from '@apollo/client';

export const LIST_BULK_IMPORT_LOG = gql`
  query listBulkUploads($input: BulkUploadInput) {
    minimizedBulkUploads(input: $input) {
      count
      currentPage
      totalPages
      limit
      bulkUploads {
        id
        originalFilename
        status
        totalTransactions
        uploadErrors
        processedTransactions
        createdAt
        updatedAt
        merchant {
          id
          companyName
        }
        userName
        bucketFilename
        uploadType
      }
    }
  }
`;
