export const PAGE_TITLE = 'Overview';

export const PAGE_DESCRIPTION = 'Consider this page your publisher resume - accurately completing all fields helps us match you to programs and increases the likelihood and speed of being approved by merchants.';

export const SUBTITLE = {
  DEMOGRAPHICS: 'Demographics',
};

export const SUBTITLE_DESCRIPTION = {
  DEMOGRAPHICS: 'Providing audience demographics helps match you with merchant products.',
};

export const BUTTON_LABEL = {
  SAVE: 'Save',
  CANCEL: 'Cancel',
};

export const INPUT_LABEL = {
  DESCRIPTION: 'business description',
  PROFILE_PHOTO: 'Profile Photo',
  PLATFORM_LINK: 'Primary Platform Link',
  AUDIENCE_MARKET: 'Primary audience market(s)',
  PRODUCT_CATEGORIES: 'product categories',
  PROMOTION: 'promotion method(s)',
  AUDIENCE_AGE: 'audience age(s)',
  GENDER: 'gender',
  CREDIT_SCORE_RATING: 'credit score rating',
  AVERAGE_INCOME: 'average income(s)',
  MONTHLY_AUDIENCE_SIZE: 'average monthly audience size',
  HAS_WEBSITE: "I don't currently have a website",
};

export const PLACEHOLDER = {
  PLATFORM_LINK: 'https://www.your.site',
  DESCRIPTION: "Please describe your platform's unique selling point, audience overview, and where you see financial products fitting on the site. Max length is 255 words.",
};

export const TOOLTIP = {
  PROFILE_PHOTO: 'Upload your branding logo to receive more recognition. File type: PNG or JPEG. File size must be less than 3MB.',
  PLATFORM_LINK: 'Choose the primary platform you will use to promote your affiliate links. This can be a website, YouTube channel, or social media profile.',
  AUDIENCE_MARKET: 'Please choose the primary markets your audience resides in to best match you with client products.',
  MONTHLY_AUDIENCE_SIZE: 'Providing audience data for your platform(s) provides transparency with merchants, a foundational aspect of affiliate partnerships. For websites, select your average monthly visits - for other platforms, select your subscriber or follower total.',
  PRODUCT_CATEGORIES: 'Which product types are you currently ready and able to promote?',
  PROMOTION: 'Sharing your trusted promotional methods will help us understand your site and find ideal merchants for you.',
};

// TODO Update to all the following to come from the A PIand then delete
export const AUDIENCE_MARKET_OPTIONS = [
  'United States',
  'Europe',
  'Canada',
  'South East Asia',
  'United Kingdom',
  'Australia & New Zealand',
];

export const AUDIENCE_AGE = ['All ages', 'Under 18', '18 - 24', '25 - 34', '35 - 44', '45 +'];

export const GENDER_OPTIONS = [
  { label: 'Mostly Male', value: 'Mostly Male' },
  { label: 'Mostly Female', value: 'Mostly Female' },
  { label: 'Any Gender', value: 'Any Gender' },
];

export const AVERAGE_INCOME = [
  { label: 'Less than $20,000', value: 'Less than $20,000' },
  { label: '$20,000 - $34,999', value: '$20,000 - $34,999' },
  { label: '$35,000 - $49,999', value: '$35,000 - $49,999' },
  { label: '$50,000 - $74,999', value: '$50,000 - $74,999' },
  { label: '$75,000 - $99,999', value: '$75,000 - $99,999' },
  { label: '$100,000 - $149,999', value: '$100,000 - $149,999' },
  { label: '$150,000 - $199,999', value: '$150,000 - $199,999' },
  { label: '$200,000 or more', value: '$200,000 or more' },
];

export const PRODUCT_CATEGORIES = [
  { label: 'Banking', value: 'Banking' },
  { label: 'Business Products', value: 'Businesses' },
  { label: 'Credit Cards', value: 'Credit Cards' },
  { label: 'Credit Reporting', value: 'Credit Reporting' },
  { label: 'Cryptocurrency', value: 'Cryptocurrency' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Investments', value: 'Investments' },
  { label: 'Loans', value: 'Loans' },
  { label: 'Mortgage', value: 'Mortgage' },
  { label: 'Real Estate', value: 'Real Estate' },
  { label: 'Tax Services', value: 'Tax Services' },
  { label: 'Wills & Estate Planning', value: 'Wills & Estate Planning' },
];

export const PROMOTION_METHOD = [
  { label: 'Long Form Content', value: 'Long Form Content' },
  { label: 'Deals/Coupons', value: 'Deals/Coupons' },
  { label: 'Tiktok', value: 'Tiktok' },
  { label: 'Forum', value: 'Forum' },
  { label: 'Cashback/Rewards', value: 'Cashback/Rewards' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Product Reviews', value: 'Product Reviews' },
  { label: 'Newsletter', value: 'Newsletter' },
  { label: 'YouTube', value: 'YouTube' },
  { label: 'Comparison', value: 'Comparison' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Social Media - Other', value: 'Social Media - Other' },
];

export const CREDIT_SCORE_RATING = [
  { label: 'Unknown', value: 'Unknown' },
  { label: 'Poor', value: 'Poor' },
  { label: 'Fair', value: 'Fair' },
  { label: 'Good', value: 'Good' },
  { label: 'Very Good', value: 'Very Good' },
  { label: 'Excellent', value: 'Excellent' },
];
