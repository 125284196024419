import React from 'react';
import {
  Button, DropZone, Checkbox, ErrorBanner,
} from '../../../../../../components';
import { PRODUCT_CATEGORY_SELECT_OPTIONS } from '../../../../../../utils';
import { VISIBILITY_OPTIONS, STATUS_OPTIONS, TARGET_GENDER_OPTIONS } from '../../../ProductCatalog/EditProduct/enums';
import { PRODUCT_INFORMATION } from './enums';
import { useNewProduct } from '../../hooks';

import * as Styled from './styles';

type ProductInformationProps = {
  isReadOnly: boolean
}

export const ProductInformation = ({ isReadOnly }: ProductInformationProps) => {
  const hook = useNewProduct();

  return (
    <Styled.WrapperStyled>
      <ErrorBanner
        message={hook.hookErrorMessage}
        isOpen={hook.hookIsError}
      />
      <Styled.GridWrapper>
        <Styled.InputTwoColumnStyled
          label={PRODUCT_INFORMATION.PRODUCT_NAME.LABEL}
          type="text"
          placeholder={PRODUCT_INFORMATION.PRODUCT_NAME.PLACEHOLDER}
          required
          onBlur={hook.hookValidate}
          onChange={hook.hookSetProductName}
          error={hook.hookNewProductErrors.productName}
          disabled={hook.hookLoading || isReadOnly}
        />
        <Styled.SelectStyled
          label={PRODUCT_INFORMATION.STATUS}
          name="productStatus"
          required
          selected={hook.hookStatus}
          options={STATUS_OPTIONS}
          isDisabled={hook.hookLoading || isReadOnly}
          onChange={hook.hookSetStatus}
          isLoading={hook.hookLoading}
          error={hook.hookNewProductErrors.status}
        />
        <Styled.InputTwoColumnStyled
          label={PRODUCT_INFORMATION.PRODUCT_URL}
          type="text"
          required
          onBlur={hook.hookValidateWebsite}
          onChange={hook.hookSetProductUrl}
          error={hook.hookProductUrlError}
          disabled={hook.hookLoading || isReadOnly}
        />
        <Styled.InputOneColumnStyled
          label={PRODUCT_INFORMATION.ID}
          type="text"
          required
          onBlur={hook.hookValidate}
          disabled={hook.hookLoading || isReadOnly || hook.hookGetProductListLoading}
          onChange={hook.hookSetId}
          error={hook.hookNewProductErrors.productId}
        />
        <Styled.DescriptionStyled
          required
          name="productDescription"
          label={PRODUCT_INFORMATION.PRODUCT_DESC}
          value={hook.hookDescription}
          disabled={hook.hookLoading || isReadOnly}
          onBlur={hook.hookValidate}
          onChange={hook.hookSetDescription}
          error={hook.hookNewProductErrors.description}
        />
        <Styled.DropZoneStyled>
          <DropZone
            label={PRODUCT_INFORMATION.IMAGE_LABEL}
            name="upload"
            accept=".png, .jpg, .jpeg, .gif"
            maxFileSize={200000}
            type="ProfileImage"
            onChange={hook.hookUploadImage}
            disabled={hook.hookLoading || isReadOnly}

          />
        </Styled.DropZoneStyled>
        <Styled.InputTwoColumnStyled
          label={PRODUCT_INFORMATION.LANDING}
          type="text"
          onBlur={hook.hookValidateWebsite}
          onChange={hook.hookSetLandingPageUrl}
          error={hook.hookLandingUrlError}
          disabled={hook.hookLoading || isReadOnly}
        />
        <Styled.InputTwoColumnStyled
          label={PRODUCT_INFORMATION.THIRD}
          type="text"
          onBlur={hook.hookValidateWebsite}
          onChange={hook.hookSetThirdPartyUrl}
          error={hook.hookThirdPartyUrlError}
          disabled={hook.hookLoading || isReadOnly}
        />
        <Styled.SelectStyled
          label={PRODUCT_INFORMATION.PRODUCT_CATEGORY}
          name="productCategory"
          placeholder="Select Product Category"
          required
          selected={hook.hookProductCategory}
          options={PRODUCT_CATEGORY_SELECT_OPTIONS}
          isDisabled={hook.hookLoading || isReadOnly}
          onChange={hook.hookSetProductCategory}
          error={hook.hookNewProductErrors.productCategory}
        />
      </Styled.GridWrapper>

      <Styled.SectionStyled>
        <Styled.SectionTitleStyled>
          {PRODUCT_INFORMATION.TARGET}
        </Styled.SectionTitleStyled>

        <Styled.GridWrapper>
          <Styled.SelectStyled
            label={PRODUCT_INFORMATION.GENDER_LABEL}
            name="gender"
            placeholder="Choose target gender(s)"
            required
            selected={hook.hookGender}
            options={TARGET_GENDER_OPTIONS}
            isDisabled={hook.hookLoading || isReadOnly}
            onChange={hook.hookSetGender}
            error={hook.hookNewProductErrors.targetGender}
          />
          <Styled.AgeRangeSection>
            <Styled.AgeRangeTitle>{PRODUCT_INFORMATION.AGE_RANGES}</Styled.AgeRangeTitle>
            <Styled.CheckBoxesWrapper>
              {hook.hookAges.map((age: any) => (
                <Checkbox
                  label={age.label}
                  checked={age.checked}
                  onChange={() => hook.hookHandleAges(age.label)}
                  disabled={isReadOnly}
                />
              ))}
            </Styled.CheckBoxesWrapper>
          </Styled.AgeRangeSection>

          <Styled.SelectStyled
            label={PRODUCT_INFORMATION.COMMISSIONS}
            name="commissionsVisibility"
            required
            options={VISIBILITY_OPTIONS}
            isDisabled={hook.hookLoading || isReadOnly}
            selected={hook.hookVisibility}
            onChange={hook.hookSetVisibility}
          />
          <Styled.SelectStyled
            label={PRODUCT_INFORMATION.COUNTRY}
            name="targetCountry"
            placeholder="Choose target country"
            required
            selected={hook.hookCountry}
            options={hook.hookCountryOptions}
            onChange={hook.hookSetCountry}
            isDisabled={hook.hookLoading || isReadOnly}
            error={hook.hookNewProductErrors.targetCountry}
          />
          <Styled.SelectTwoStyled
            label={PRODUCT_INFORMATION.STATE}
            name="targetRegion"
            placeholder=""
            isDisabled={hook.hookLoading || isReadOnly}
            isMulti
            selectedMulti={hook.hookRegion}
            options={hook.hookRegionOptions}
            onChange={hook.hookSetRegion}
          />
          <Styled.InputOneColumnStyled
            label={PRODUCT_INFORMATION.INCOME}
            type="text"
            placeholder={PRODUCT_INFORMATION.INCOME_PLACEHOLDER}
            disabled={hook.hookLoading || isReadOnly}
            onChange={hook.hookSetPersonalIncome}
          />
          <Styled.InputOneColumnStyled
            label={PRODUCT_INFORMATION.HOUSHOLD}
            type="text"
            placeholder={PRODUCT_INFORMATION.INCOME_PLACEHOLDER}
            onChange={hook.hookSetHouseholdIncome}
            disabled={hook.hookLoading || isReadOnly}
          />
          <Styled.InputOneColumnStyled
            label={PRODUCT_INFORMATION.CREDIT}
            type="text"
            placeholder={PRODUCT_INFORMATION.CREDIT_PLACEHOLDER}
            disabled={hook.hookLoading || isReadOnly}
            onChange={hook.hookSetCreditScore}
          />
        </Styled.GridWrapper>
      </Styled.SectionStyled>

      <Styled.FooterStyled>
        <Button
          theme="secondary"
          width="150px"
          onClick={hook.hookCancel}
        >
          {PRODUCT_INFORMATION.CANCEL}
        </Button>
        <Button
          onClick={hook.hookOnSubmit}
          width="150px"
          loading={hook.hookLoading}
          disabled={hook.hookLoading || isReadOnly}
        >
          {PRODUCT_INFORMATION.SAVE}
        </Button>
      </Styled.FooterStyled>
    </Styled.WrapperStyled>
  );
};
