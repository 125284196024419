export const BUTTON_LABELS = {
  ADD_COMMISSION: 'Add Commission',
};

export const CONFIRMATION_MODAL = {
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  TITLE: 'Delete Commission',
  DESCRIPTION: 'Are you sure you want to delete Commission ',
};

export const ERROR = {
  FAILED_TO_DELETE: 'Could not delete commission. Please try again.',
};
