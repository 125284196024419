import { gql } from '@apollo/client';

export const LIST_PUBLISHERS = gql`
  query listPublishersForMerchants($input: ListPublishersAndGroupsInput) {
    listPublishersForMerchants(input: $input) {
      publishers {
        id
        companyName
        location
        companyUrl
        productCategories
      }
    }
  }
`;
