import { gql } from '@apollo/client';

export const GET_INVITATION_TEMPLATE = gql`
query publisherInvitation($id: ID!) {
  publisherInvitation(id: $id) {
    id
    merchantId
    name
    description
    subject
    message
    template
  }
}
`;
