import styled from 'styled-components';
import { toRem } from '../../../../../utils';
import { colors, fonts } from '../../../../../styles/theme';

type CellStyledProps = {
  readonly theme?: string
}

export const CellStyled = styled.span<CellStyledProps>`
  display: flex;
  align-items: center;
  height: ${toRem(42)};

  justify-content: ${({ theme }) => (theme === 'secondary' ? 'center' : 'flex-start')};
  flex-direction: ${({ theme }) => (theme === 'secondary' ? 'column' : 'row')};
  align-items: ${({ theme }) => (theme === 'secondary' ? 'flex-start' : 'center')};
`;

export const CellDivStyled = styled.div<CellStyledProps>`
  display: flex;
  align-items: center;
  height: ${toRem(42)};

  justify-content: ${({ theme }) => (theme === 'secondary' ? 'center' : 'flex-start')};
  flex-direction: ${({ theme }) => (theme === 'secondary' ? 'column' : 'row')};
  align-items: ${({ theme }) => (theme === 'secondary' ? 'flex-start' : 'center')};
`;

export const SubTextStyled = styled.p`
  color: ${colors.color35};
  ${fonts.lato.H12};
`;

export const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 24px;

  p {
    margin-left: 10px;
  }
`;

export const DropdownChildStyled = styled.div`
  min-width: 140px;
  padding: 10px 0;
`;
