import React from 'react';
import {
  faChevronRight, faCopy, faPenToSquare, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { MERCHANT_PREFIX, path } from '../../../../../utils';
import { INVITE_PUBLISHERS } from '../Tabs/InvitePublishers/enum';
import * as Styled from '../styles';
import {
  MEMBERSHIP_STATUS, STATUS_LABALS, TABLE_HEADER, TEMPLATE_ACTIONS,
} from './enum';
import { Button } from '../../../../../components';

const SendInviteCell = ({ row, isReadOnly, currentState } : {row: any, isReadOnly?: boolean, currentState:any}) => {
  const navigate = useNavigate();

  const goTo = () => {
    navigate(`${MERCHANT_PREFIX}${path.sendPublisherInvitation.href}`, { state: { publisherId: row.id, publisherName: row.companyName, prevState: currentState } });
  };

  return (
    <Styled.CellStyled>
      <Styled.ButtonStyled
        onClick={goTo}
        theme="quinary"
        disabled={isReadOnly}
      >
        {INVITE_PUBLISHERS.SEND_INVITE}
      </Styled.ButtonStyled>
    </Styled.CellStyled>
  );
};

const ArrowCell = () => {
  const doNothing = () => null;

  return (
    <Styled.CellStyled>
      <Styled.ButtonStyled
        onClick={doNothing}
        theme="quaternary"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Styled.ButtonStyled>
    </Styled.CellStyled>
  );
};

export const convertMembershipStatus = (membership: any, hold = false) => {
  if (hold) return STATUS_LABALS.HOLD;
  if (!membership) return INVITE_PUBLISHERS.SEND_INVITE;

  const isPublisherInvitation = !!membership.publisherInvitation?.id;
  const lastUpdated = membership.statusLastUpdatedBy;
  switch (membership.status) {
    case MEMBERSHIP_STATUS.PENDING: return (lastUpdated === 'Merchant' || (!lastUpdated && isPublisherInvitation)) ? MEMBERSHIP_STATUS.INVITED : MEMBERSHIP_STATUS.PENDING;
    case MEMBERSHIP_STATUS.APPROVED: return MEMBERSHIP_STATUS.APPROVED;
    case MEMBERSHIP_STATUS.DECLINED: return (lastUpdated === 'Merchant' || (!lastUpdated && !isPublisherInvitation)) ? MEMBERSHIP_STATUS.DECLINED : MEMBERSHIP_STATUS.INVITATION_DECLINED;
    case MEMBERSHIP_STATUS.CLOSED: return (lastUpdated === 'Merchant' || (!lastUpdated && !isPublisherInvitation)) ? MEMBERSHIP_STATUS.CLOSED_BY_MERCHANT : MEMBERSHIP_STATUS.CLOSED_BY_PUBLISHER;
    default: return membership.status;
  }
};

export const columns = (isReadOnly: boolean, currentState:any): TableColumn[] => [
  {
    dataField: 'id',
    text: TABLE_HEADER.ID,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '10%',
  },
  {
    dataField: 'companyName',
    text: TABLE_HEADER.COMPANY_NAME,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '20%',
    notClickable: true,
    formatter: (cell: any, row: any) => (
      <Styled.CellStyled theme="third">
        <p>{cell}</p>
        {row.tracking?.primaryWebsite && (
        <Styled.LinkStyled
          href={row.tracking?.primaryWebsite?.includes('http') ? row.tracking.primaryWebsite : `http://${row.tracking?.primaryWebsite}`}
          target='_blank"'
        >
          {row.tracking?.primaryWebsite}
        </Styled.LinkStyled>
        )}
      </Styled.CellStyled>
    ),
  },
  {
    dataField: 'audienceMarkets',
    text: TABLE_HEADER.AUDIENCE_MARKETS,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '15%',
    formatter: (_: any, row: any) => (
      <Styled.CellStyled theme="secondary">
        <p>{row.overview?.audienceMarkets?.filter(((value: string) => value)).join(', ')}</p>
      </Styled.CellStyled>
    ),
  },
  {
    dataField: 'avgMonthlyAudience',
    text: TABLE_HEADER.MONTHLY,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '18%',
    formatter: (_: any, row: any) => (
      <Styled.CellStyled theme="secondary">
        <p>{row.overview?.avgMonthlyAudience}</p>
      </Styled.CellStyled>
    ),
  },
  {
    dataField: 'promotionMethods',
    text: TABLE_HEADER.PROMOTION_METHOD,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '18%',
    formatter: (_: any, row: any) => (
      <Styled.CellStyled theme="secondary">
        <p>{row.overview?.promotionMethods.join(', ')}</p>
      </Styled.CellStyled>
    ),
  },
  {
    dataField: 'membership',
    text: TABLE_HEADER.STATUS,
    width: '13%',
    notClickable: true,
    formatter: (cell: any, row: any) => (
      <Styled.CellStyled>
        {(convertMembershipStatus(cell, row.invitesOnHold) === INVITE_PUBLISHERS.SEND_INVITE)
          ? (
            <SendInviteCell
              row={row}
              isReadOnly={isReadOnly}
              currentState={currentState}
            />
          )
          : <p>{convertMembershipStatus(cell, row.invitesOnHold)}</p>}
      </Styled.CellStyled>
    ),
  },
  {
    dataField: '',
    text: '',
    formatter: (_: any) => (
      <ArrowCell />
    ),
    width: '5%',
  },
];

export const templateColumns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'name',
    text: 'Name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '30%',
    formatter: (value: string) => (
      <Styled.CellStyled>
        {value}
      </Styled.CellStyled>
    ),
  },
  {
    dataField: 'description',
    text: 'description',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '60%',
    formatter: (value: string) => (
      <Styled.CellStyled>
        {value}
      </Styled.CellStyled>
    ),
  },
  {
    dataField: '',
    text: 'actions',
    width: '10%',
    formatter: (_: any, row: any, ___: any, ____: any, action: any) => (
      <Styled.CellStyled theme="quaternary">
        <Button
          onClick={() => action(row, TEMPLATE_ACTIONS.COPY)}
          theme="quaternary"
          disabled={isReadOnly}
        >
          <FontAwesomeIcon icon={faCopy} />
        </Button>
        <Button
          onClick={() => action(row, TEMPLATE_ACTIONS.EDIT)}
          theme="quaternary"
          disabled={isReadOnly}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </Button>
        <Button
          onClick={() => action(row, TEMPLATE_ACTIONS.DELETE)}
          theme="quaternary"
          disabled={isReadOnly}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Styled.CellStyled>
    ),
  },
];
