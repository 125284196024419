import React, { useState } from 'react';
import { faDollar } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Calendar, Checkbox, InfoBox, InputCalendar, InputText, Select,
} from '../../../../../components';
import { ERROR_ICON } from '../../../../../utils';
import {
  BUTTON_LABEL, DEPOSIT_TYPES, FEE_TYPES, INPUT_LABEL, MODAL_TYPES, PLACEHOLDER, SUBTITLE,
} from '../../contracts';
import * as Styled from '../../styles';

type AddModalProps = {
  cancelHandler: (open: boolean) => void
  saveHandler: () => void
  addAmount: string
  setAddAmount: (event: React.ChangeEvent<HTMLInputElement>) => void
  addDate?: Date
  setDate: (addDate: Date) => void
  openAddModalCalender: boolean
  setOpenAddModalCalender: (openAddModalCalender: boolean) => void
  selectedType: SelectOption
  setSelectedType: (value: SelectOption) => void
  addFeeTypeOther: string
  setAddFeeTypeOther: (event: React.ChangeEvent<HTMLInputElement>) => void
  addModalReferenceNumber: string
  setAddModalReferenceNumber: (event: React.ChangeEvent<HTMLInputElement>) => void
  addNote: string
  setAddNote: (event: React.ChangeEvent<HTMLInputElement>) => void
  addSelectedModalProductCategory?: SelectOption
  setSelectedAddModalProductCategory: (value: SelectOption) => void
  addModalProductCategoryOptions: SelectOption[]
  errors: { [key: string]: string }
  type: string
  notifyMerchant: boolean
  handleCheck: (checked: boolean) => void;
  isEnableNewStats: boolean
}

export const AddModal = ({
  addAmount, setAddAmount, setDate, addDate, addFeeTypeOther, setAddFeeTypeOther,
  setOpenAddModalCalender, openAddModalCalender, selectedType, setSelectedType, saveHandler,
  addNote, setAddNote, addModalProductCategoryOptions, addModalReferenceNumber, setAddModalReferenceNumber, errors, handleCheck, notifyMerchant, isEnableNewStats,
  setSelectedAddModalProductCategory, addSelectedModalProductCategory, cancelHandler, type,
}: AddModalProps) => {
  const [reset, setReset] = useState(false);
  return (
    <Styled.ModalBodyStyled>
      {errors.message && (
      <InfoBox
        title=""
        description={errors.message}
        icon={ERROR_ICON}
        theme="ERROR"
      />
      )}
      <Styled.SubsectionStyled>
        <Styled.ModalTitleStyled>
          {type === MODAL_TYPES.FEE ? SUBTITLE.FEE_MODAL : SUBTITLE.DEPOSIT_MODAL}
        </Styled.ModalTitleStyled>
        <Styled.ButtonContainerStyled>
          <Button
            onClick={() => { setReset(!reset); cancelHandler(false); }}
            theme="secondary"
          >
            {BUTTON_LABEL.CANCEL}
          </Button>

          <Styled.ButtonWrapperStyled>
            <Button onClick={() => { setReset(!reset); saveHandler(); }}>
              {BUTTON_LABEL.SAVE}
            </Button>
          </Styled.ButtonWrapperStyled>
        </Styled.ButtonContainerStyled>
      </Styled.SubsectionStyled>

      <Styled.ModalGridStyled>
        <Styled.ModalInputContainerStyled>
          <InputText
            type="text"
            label={type === MODAL_TYPES.FEE ? INPUT_LABEL.FEE_AMOUNT : INPUT_LABEL.DEPOSIT_AMOUNT}
            value={addAmount}
            onChange={setAddAmount}
            faIcon={faDollar}
            error={errors.realNumberFee}
            required
          />
        </Styled.ModalInputContainerStyled>

        <Styled.ModalInputContainerStyled>
          <InputCalendar
            label={type === MODAL_TYPES.FEE ? INPUT_LABEL.FEE_DATE : INPUT_LABEL.DEPOSIT_DATE}
            value={addDate ? addDate.toDateString() : ''}
            placeholder={PLACEHOLDER.FEE_DATE}
            onClick={() => setOpenAddModalCalender(true)}
            error={errors.feeDate}
            required
          />
        </Styled.ModalInputContainerStyled>
      </Styled.ModalGridStyled>

      <Styled.ModalGridStyled>
        <Styled.ModalInputContainerStyled>
          <Select
            label={type === MODAL_TYPES.FEE ? INPUT_LABEL.FEE_TYPE : INPUT_LABEL.DEPOSIT_TYPE}
            selected={selectedType}
            onChange={setSelectedType}
            options={type === MODAL_TYPES.FEE ? FEE_TYPES : DEPOSIT_TYPES}
            error={errors.feeType}
            required
          />
        </Styled.ModalInputContainerStyled>

        <Styled.ModalInputContainerStyled>
          <InputText
            type="text"
            label={type === MODAL_TYPES.FEE ? INPUT_LABEL.FEE_OTHER : INPUT_LABEL.DEPOSIT_DETAILS}
            value={addFeeTypeOther}
            onChange={setAddFeeTypeOther}
            placeholder={type === 'deposit' ? PLACEHOLDER.DEPOSIT_DETAILS : ''}
            disabled={selectedType.value === FEE_TYPES[0].value || type === 'deposit'}
            error={type !== MODAL_TYPES.FEE ? '' : errors.feeTypeOther}
            required={selectedType.value !== FEE_TYPES[0].value && type !== 'deposit'}
          />
        </Styled.ModalInputContainerStyled>
      </Styled.ModalGridStyled>

      <Styled.ModalGridStyled>
        <Styled.ModalInputContainerStyled>
          <InputText
            type="text"
            label={INPUT_LABEL.REFERENCE_NUMBER}
            value={addModalReferenceNumber}
            onChange={setAddModalReferenceNumber}
            error={errors.referenceNumber}
          />
        </Styled.ModalInputContainerStyled>

        {isEnableNewStats ? (
          <Styled.ModalInputContainerStyled>
            <Select
              label={INPUT_LABEL.PRODUCT_CATEGORY}
              selected={addSelectedModalProductCategory}
              onChange={setSelectedAddModalProductCategory}
              options={addModalProductCategoryOptions}
              placeholder={PLACEHOLDER.SELECT}
              error={errors.productCategory}
              required
            />
          </Styled.ModalInputContainerStyled>
        ) : (
          <Styled.ModalInputContainerStyled>
            <InputText
              type="text"
              value={addNote}
              onChange={setAddNote}
              label={INPUT_LABEL.NOTES}
            />
          </Styled.ModalInputContainerStyled>
        )}
      </Styled.ModalGridStyled>

      {isEnableNewStats && (
      <Styled.ModalGridStyled>
        <InputText
          type="text"
          value={addNote}
          onChange={setAddNote}
          label={INPUT_LABEL.NOTES}
        />
      </Styled.ModalGridStyled>
      )}

      {type === 'deposit' && (
      <Styled.ModalGridStyled>
        <Checkbox
          checked={notifyMerchant}
          onChange={handleCheck}
          label={INPUT_LABEL.NOTIFY_MERCHANT}
        />
      </Styled.ModalGridStyled>
      )}

      <Calendar
        onApply={setDate}
        onCancel={() => setOpenAddModalCalender(false)}
        date={addDate}
        isOpen={openAddModalCalender}
        isSingle
        reset={reset}
      />
    </Styled.ModalBodyStyled>
  );
};
