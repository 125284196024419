import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingPage } from '../../components';

type RedirectToDashboardProps = {
  to: string;
};

const RedirectToDashboard = ({ to }: RedirectToDashboardProps) => {
  const history = useNavigate();

  useEffect(() => {
    history(to);
  }, []);

  return (
    <LoadingPage />
  );
};

export default RedirectToDashboard;
