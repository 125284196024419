import environment from '../config/environment';
import { AD_TYPES } from './enums';

export const codeGenerator = (ad: any, adTrackerId: string) => {
  const {
    adType, height, width,
  } = ad;

  const clickCode = `${environment.api.adsBasePath}/t/l/${adTrackerId}`;
  const iFrameCode = `${environment.api.adsBasePath}/t/banner/${adTrackerId}`;
  const impressionCode = `${environment.api.adsBasePath}/t/impression/${adTrackerId}`;
  const htmlCode = `${environment.api.adsBasePath}/t/h5/${adTrackerId}`;

  if (adType === AD_TYPES.LINK) {
    return {
      trackingCode: `<a rel="nofollow" href="${clickCode}" target="_blank" referrerpolicy="no-referrer-when-downgrade"><img src="${impressionCode}" referrerpolicy="no-referrer-when-downgrade" width=0 height=0 border=0/></a>`,
      trackingUrl: clickCode,
      impressionUrl: impressionCode,
    };
  }

  if (adType === AD_TYPES.BANNER) {
    return {
      trackingCode: `<iframe scrolling="no" referrerpolicy="no-referrer-when-downgrade" frameBorder="0" style="padding:0px;margin:0px; border:0px;border-style:none;border-style:none; "width="${width}" height="${height}" src="${iFrameCode}"></iframe>`,
      trackingUrl: iFrameCode,
    };
  }

  if (adType === AD_TYPES.HTML) {
    return {
      trackingCode: `<iframe scrolling="no" referrerpolicy="no-referrer-when-downgrade" frameBorder="0" style="padding:0px;margin:0px; border:0px;border-style:none;border-style:none; "width="${width}" height="${height}" src="${htmlCode}"></iframe>`,
      trackingUrl: htmlCode,
    };
  }

  return '';
};
