import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, useEffect, useState } from 'react';
import { useUserInfo } from '../../../../hooks';
import { MERCHANT_PREFIX, path, USER_TYPES_ID } from '../../../../utils';
import { SEARCH_CAMPAIGN } from '../graphql/queries';
import { columns } from '../contracts';
import { useRenderButton } from './useRenderButton';
import { useDebounce } from '../../../../utils/useDebounce';
import { Permission } from '../../../../entities';

export const useCampaignManagement = (permissionsCodeList: string[] = []) => {
  const navigate = useNavigate();
  const { hookWhoAmI, hookUserInfo } = useUserInfo();
  const { hookDisplayButton } = useRenderButton();

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 800);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [records, setRecords] = useState({ label: '10', value: '10' });
  const [recordsCount, setRecordsCount] = useState(0);
  const [sortBy, setSortBy] = useState<TableSortColumn>({ column: 'campaignId', direction: 'asc' });

  const [tableData, setTableData] = useState([]);
  const [searchCampaign, { loading }] = useLazyQuery(SEARCH_CAMPAIGN);

  const searchCampaignHandler = async () => {
    const { data } = await searchCampaign({
      variables: {
        input: {
          filter: {
            q: search,
            programId: hookWhoAmI.programId,
          },
          options: {
            page: currentPage,
            order: sortBy.direction?.toUpperCase(),
            items: parseInt(records.value, 10),
          },
          sortBy: sortBy.column,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.listSearchCampaigns?.campaigns) {
      setTableData(data?.listSearchCampaigns?.campaigns);
    }
    if (data?.listSearchCampaigns?.count) {
      setRecordsCount(data?.listSearchCampaigns?.count);
      setTotalPages(Math.ceil(data.listSearchCampaigns.count / parseInt(records.value, 10)));
    } else {
      setCurrentPage(1);
      setRecordsCount(0);
      setTotalPages(0);
    }
  };

  const setRecordsHandler = (value: any) => {
    setRecords({
      label: value.label,
      value: value.value,
    });
  };

  const setSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page);
  };

  const setSortByHandler = (dataField: string, direction: any) => {
    if (sortBy.direction === null) {
      setSortBy({ column: dataField, direction });
    } else {
      setSortBy({ column: dataField, direction: sortBy.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const getColumns = () => {
    const tableColumns = [...columns];
    if (hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT || hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT) {
      tableColumns.splice(2, 1);
      tableColumns[1].width = '67%';
    } else {
      tableColumns[1].width = '38%';
    }
    return tableColumns;
  };

  const clearFormHandler = () => {
    setSearch('');
  };

  const rowClickActAsHandler = (row: any) => {
    navigate(`${hookWhoAmI.programId ? MERCHANT_PREFIX : ''}${path.editCampaign.href}`, {
      state: {
        campaignId: row.campaignId,
        programId: row.programId,
        companyId: row.merchantId,
      },
    });
  };

  useEffect(() => {
    searchCampaignHandler();
  }, [hookWhoAmI.companyId, debouncedSearch, currentPage, sortBy, records]);

  useEffect(() => {
    setCurrentPage(1);
  }, [records, debouncedSearch]);

  return {
    hookWhoAmI,
    hookUserInfo,

    hookLoading: loading,
    hookTableData: tableData,
    hookTableColumns: getColumns,

    hookSearch: search,
    hookSetSearch: setSearchHandler,

    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,
    hookTotalPages: totalPages,
    hookRecords: records,
    hookSetRecords: setRecordsHandler,
    hookTotalRecords: recordsCount,
    hookSetSortBy: setSortByHandler,
    hookSortBy: sortBy,

    hookClearForm: clearFormHandler,

    hookDisplayButton,

    hookHandleRowClickActAs: rowClickActAsHandler,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
