import { useState } from 'react';

export const useMerchant = () => {
  const [companyName, setCompanyName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [website, setWebsite] = useState('');

  const setCompanyNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
  };
  const setCompanyEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyEmail(e.target.value);
  };
  const setWebsiteHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWebsite(e.target.value);
  };

  return {
    hookCompanyName: companyName,
    hookCompanyEmail: companyEmail,
    hookCompanyWebsite: website,

    hookSetCompanyName: setCompanyNameHandler,
    hookSetCompanyEmail: setCompanyEmailHandler,
    hookSetWebsite: setWebsiteHandler,
  };
};
