import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../../../styles/theme';

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const PageTitle = styled.h2`
  ${fonts.gotham.H22}
  color: ${colors.color1};
  margin: 1.5rem 0;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;
