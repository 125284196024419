import React from 'react';
import * as Styled from './styles';
import { DEACTIVATION_FORM_FIELDS } from '../../utils';
import { ConfirmationModal } from '../Modal/ConfirmationModal';

type DeactivationFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string
  desc?: string
  onCloseText: string
  onConfirmText: string
  userInformation?: {
    firstName?: string
    lastName?: string
    email?: string
  }
  isLoading?: boolean
  isConfirmDisable?: boolean
}

export const DeactivationForm = ({
  isOpen, onClose, onConfirm, title, desc, onCloseText, onConfirmText, userInformation, isLoading,
  isConfirmDisable = false,
}: DeactivationFormProps) => (
  <ConfirmationModal
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    title={title}
    desc={desc}
    isLoading={isLoading || false}
    onCloseText={onCloseText}
    onConfirmText={onConfirmText}
    onConfirmDisabled={isConfirmDisable}
  >
    <Styled.UserDescriptionWrapperStyled theme="outer">
      <Styled.UserDescriptionInnerWrapperStyled>
        <Styled.UserDescTitleStyled>
          {DEACTIVATION_FORM_FIELDS[0].value}
        </Styled.UserDescTitleStyled>
        <Styled.UserDescInformationStyled>
          {userInformation?.firstName}
          {' '}
          {userInformation?.lastName}
        </Styled.UserDescInformationStyled>
      </Styled.UserDescriptionInnerWrapperStyled>
      <Styled.UserDescriptionInnerWrapperStyled>
        <Styled.UserDescTitleStyled>
          {DEACTIVATION_FORM_FIELDS[1].value}
        </Styled.UserDescTitleStyled>
        <Styled.UserDescInformationStyled>
          {userInformation?.email}
        </Styled.UserDescInformationStyled>
      </Styled.UserDescriptionInnerWrapperStyled>
    </Styled.UserDescriptionWrapperStyled>
  </ConfirmationModal>
);
