import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { find } from 'lodash';
import {
  ERROR_TYPES, USER_LANGUAGE_OPTIONS, getCookie,
} from '../../../../utils';
import { useValidation } from '../../../../utils/validation';
import { useToast, useUserInfo } from '../../../../hooks';
import { useMerchantAccessExternal } from '../../AddNewUser/components/MerchantAccess/hooks/index';
import { GET_USER_INFO } from '../graphql/queries';
import { GET_ADMIN_ROLES } from '../../graphql/queries';
import { UPDATE_USER_INFO, ASSIGN_ADMIN_USER } from '../../graphql/mutations';
import environment from '../../../../config/environment';
import { Permission } from '../../../../entities';

type UserRoleType = {
  name: string
  id: string
  oldId?: number
}

type GetUserInfoOutputType = {
      id: string
      auth0Id: string
      email: string
      firstName: string
      lastName: string
      company: {
        id: string
        companyName: string
      }
      position: string
      phone: string
      preferredLanguage: string
      userType: string
      roles: {
        id: string
        name: string
      }[]
      newRoles: UserRoleType[]
      adminMerchantAccessList: {
        id: string
        companyName: string
      }[]
      adminHasAccessAll: boolean
}

type RoleOptionType = {
  label: string
  oldRoleId: number
  value: string
}

export const useUserProfileManagement = (permissionsCodeList: string[] = []) => {
  const [userFirstName, setUserFirstName] = useState<string>('');
  const [userLastName, setUserLastName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [userSecurityRole, setUserSecurityRole] = useState<RoleOptionType[]>([]);
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('');
  const [userLanguage, setUserLanguage] = useState<SelectOption>({ label: 'English', value: 'English' });
  const [userPosition, setUserPosition] = useState<string>('');
  const [userAcceptsOffers, setUserAcceptsOffers] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [initialized, setInitialized] = useState<boolean>(false);
  const [userSecurityRoleOptionsList, setUserSecurityRoleOptionsList] = useState<RoleOptionType[]>([]);

  const { hookWhoAmI, hookUserPermissions } = useUserInfo();
  const location = useLocation();

  const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER_INFO, {
    variables: {
      id: location.state?.userId || hookWhoAmI.id,
    },
    fetchPolicy: 'no-cache',
    onError(err) {
      setErrorMessage(err.message);
    },
  });

  const { data: roleData, loading: roleLoading, error: roleError } = useQuery(GET_ADMIN_ROLES, {
    onError(err) {
      setErrorMessage(err.message);
    },
    fetchPolicy: 'no-cache',
  });

  const [updateUserInfo, { loading: loadingUpdateUserInfo }] = useMutation(UPDATE_USER_INFO);
  const [assignAdminUser, { loading: assignUserLoading }] = useMutation(ASSIGN_ADMIN_USER);

  const setUserFirstNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserFirstName(e.target.value);
  };
  const setUserLastNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserLastName(e.target.value);
  };
  const setUserPositionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPosition(e.target.value);
  };
  const setUserPhoneNumberHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPhoneNumber(e === undefined || e.toString() === '' ? '' : e.toString());
  };
  const setUserLanguageHandler = (value: {label: string, value: string}) => {
    setUserLanguage({
      label: value.label,
      value: value.value,
    });
  };
  const setUserSecurityRoleHandler = useCallback((newRole: RoleOptionType[]) => {
    if (find(newRole, (role) => role.value && role.label.toLowerCase().indexOf('superuser') > -1)) {
      setWarningMessage('NOTE!  This user has been assigned a Super Admin role.  This role has access to all merchants and all features, and will allow them to create other Super Admins.');
    } else {
      setWarningMessage('');
    }
    setUserSecurityRole(newRole);
  }, [setUserSecurityRole]);

  const setUserAcceptsOffersHandler = useCallback((acceptsOffers: boolean) => {
    setUserAcceptsOffers(acceptsOffers);
  }, [setUserAcceptsOffers]);

  const vali = useValidation();
  const { hookShowToast } = useToast();

  const [updateUserErrors, setCreateUserErrors] = useState<{ [key: string]: string }>({});
  const [secondRender, setSecondRender] = useState<boolean>(false);

  const values: { [key: string]: string } = {
    firstName: userFirstName,
    lastName: userLastName,
    userEmail,
    phone: userPhoneNumber,
    securityRole: userSecurityRole[0] ? 'pass' : '',
  };

  const fields = {
    firstName: ERROR_TYPES.NOT_EMPTY,
    lastName: ERROR_TYPES.NOT_EMPTY,
    userEmail: ERROR_TYPES.EMAIL,
    phone: ERROR_TYPES.PHONE,
    securityRole: ERROR_TYPES.NOT_EMPTY,
  };

  const handleValidation = () => {
    const pass = vali.validateAll(values, fields, setCreateUserErrors, secondRender);
    return pass;
  };

  /** Send an email and displays toast on success */
  const forceResetPasswordHandler = async () => {
    const token = getCookie('id_token');
    const path = environment.api.adsBasePath;
    if (!userData) return;
    setErrorMessage('');
    fetch(`${path}/change_password/`, {
      method: 'POST',
      body: JSON.stringify({ email: userData.user.email }),
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(() => {
      hookShowToast('Email with the link to reset your password sent!');
    }).catch((error) => {
      setErrorMessage(error.message);
    });
  };

  /* Needed to allow errors to show after failed submits. */
  useEffect(() => {
    handleValidation();
  }, [secondRender]);

  /* Controls for the Merchant Access Component. */
  const {
    allMerchantsAccess,
    setAllMerchantAccessHandler,
    merchantList,
    checkedMerchants,
    selectedMerchants,
    setMerchantListHandler,
    setSelectedMerchantsHandler,
    setCheckedMerchantHandler,
  } = useMerchantAccessExternal();

  const canAssignRoles = () => {
    const item = hookUserPermissions.find((i: { code: string; }) => i.code === 'settings.userManagement');
    return item.permission === 'write';
  };

  const updateAdminUserHandler = async () => {
    const toastMessages = [];
    setSecondRender(true);
    const noErrors = handleValidation();
    if (!noErrors) return;
    setErrorMessage('');
    setIsLoading(true);
    setLoadingMessage('Updating User Info');
    const canAssign = canAssignRoles();

    const userInfo: GetUserInfoOutputType = userData.user;
    const simpleinput = {
      auth0Id: userInfo.auth0Id,
      firstName: userFirstName,
      lastName: userLastName,
      position: userPosition,
      phone: userPhoneNumber,
      preferredLanguage: userLanguage.value,
      adminMerchantAccessList: selectedMerchants.map((merchant) => ({ id: merchant.id, companyName: merchant.companyName })),
      adminHasAccessAll: allMerchantsAccess,
    };
    const input = {
      ...simpleinput,
      roleId: userSecurityRole.filter((role) => role.oldRoleId).map((role) => role.oldRoleId.toString()),
      newRoleIds: userSecurityRole.map((role) => role.value),
    };
    const updateResult = await updateUserInfo({
      variables: {
        input: simpleinput,
      },
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (updateResult?.errors) {
      setErrorMessage(updateResult?.errors[0]?.message);
      setIsLoading(false);
      setLoadingMessage('');
      return;
    }
    if (canAssign) {
      const roleResult = await assignAdminUser({
        variables: {
          input,
        },
      });
      if (roleResult?.errors) {
        setErrorMessage(`${roleResult?.errors[0]?.message}.  Only super admins can assign roles to users.`);
        setIsLoading(false);
        setLoadingMessage('');
        return;
      }
    }
    hookShowToast(`User ${userEmail} updated successfully`);
    setErrorMessage('');
    setIsLoading(false);
    setLoadingMessage('');
  };

  const setInitialData = () => {
    const userInfo: GetUserInfoOutputType = userData.user;
    setUserEmail(userInfo.email);
    setUserFirstName(userInfo.firstName);
    setUserLastName(userInfo.lastName);
    setUserPhoneNumber(userInfo.phone);
    setUserSecurityRoleHandler(userInfo.newRoles.map((role) => ({ value: role.id, label: role.name, oldRoleId: role.oldId || 0 })));
    setUserPosition(userInfo.position);
    setAllMerchantAccessHandler(userInfo.adminHasAccessAll === null || undefined ? true : userInfo.adminHasAccessAll);
    setSelectedMerchantsHandler(userInfo.adminMerchantAccessList);
    setUserLanguageHandler({ label: userInfo.preferredLanguage, value: userInfo.preferredLanguage });

    const roleInfo: UserRoleType[] = roleData.newRolesByType;
    setUserSecurityRoleOptionsList(roleInfo.map((role: UserRoleType) => ({ label: role.name, value: role.id, oldRoleId: role.oldId || 0 })));
  };

  useEffect(() => {
    setErrorMessage('');
    if (userError) {
      setErrorMessage(userError.message);
      return;
    }
    if (roleError) {
      setErrorMessage(roleError.message);
      return;
    }
    if (!userLoading && !roleLoading) {
      setInitialData();
    }
    setInitialized(true);
  }, [userData, roleData]);

  return {
    hookUserFirstName: userFirstName,
    hookUserLastName: userLastName,
    hookUserEmail: userEmail,
    hookUserPosition: userPosition,
    hookUserPhoneNumber: userPhoneNumber,
    hookUserLanguage: userLanguage,
    hookUserSecurityRole: userSecurityRole,
    hookUserAcceptsOffers: userAcceptsOffers,

    hookSetUserFirstNameHandler: setUserFirstNameHandler,
    hookSetUserLastNameHandler: setUserLastNameHandler,
    hookSetUserPositionHandler: setUserPositionHandler,
    hookSetUserPhoneNumberHandler: setUserPhoneNumberHandler,
    hookSetUserLanguageHandler: setUserLanguageHandler,
    hookSetUserSecurityRoleHandler: setUserSecurityRoleHandler,
    hookSetUserAcceptsOffersHandler: setUserAcceptsOffersHandler,
    hookForceResetPasswordHandler: forceResetPasswordHandler,

    hookUpdateUserErrors: updateUserErrors,
    hookUpdateAdminUserHandler: updateAdminUserHandler,
    hookSetSecondRender: setSecondRender,
    hookHandleValidation: handleValidation,

    hookUserLanguageOptionsList: USER_LANGUAGE_OPTIONS,
    hookUserSecurityRoleOptionsList: userSecurityRoleOptionsList,

    hookAllMerchantsAccess: allMerchantsAccess,
    hookMerchantList: merchantList,
    hookCheckedMerchants: checkedMerchants,
    hookSelectedMerchants: selectedMerchants,
    hookSetAllMerchantAccessHandler: setAllMerchantAccessHandler,
    hookSetMerchantListHandler: setMerchantListHandler,
    hookSetSelectedMerchantsHandler: setSelectedMerchantsHandler,
    hookSetCheckedMerchantHandler: setCheckedMerchantHandler,

    hookIsLoading: isLoading || roleLoading || userLoading,
    hookLoadingMessage: loadingMessage,
    hookErrorMessage: errorMessage,
    warningMessage,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
