import { gql } from '@apollo/client';

export const LIST_PROMOTION_METHODS = gql`
  query List {
    listPromotionMethod {
      list {
        id
        method
      }
    }
  }
`;
