import React from 'react';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { useDropdown } from '../../../../../../hooks';
import { TAX_STATUS } from '../../../../../../utils';
import { Button, Dropdown } from '../../../../../../components';
import * as Styled from './styles';

type TaxDropdownProps = {
  handleChange: (type: string) => void
}

export const TaxDropdown = ({ handleChange }: TaxDropdownProps) => {
  const dropdownHook = useDropdown();

  return (
    <Dropdown
      isOpen={dropdownHook.isOpen}
      setIsOpen={() => dropdownHook.setIsOpen(!dropdownHook.isOpen)}
      dropdownRef={dropdownHook.dropdownRef}
      text=""
      width="fit-content"
      iconClass={faEllipsis}
      leftIcon
    >
      <Styled.DropdownChildWrapper>
        {TAX_STATUS.map((status) => (
          <Button
            key={status.value}
            onClick={() => handleChange(status.value)}
            theme="senary"
            width="100%"
          >
            <Styled.ButtonStyled>
              {status.label}
            </Styled.ButtonStyled>
          </Button>

        ))}
      </Styled.DropdownChildWrapper>
    </Dropdown>
  );
};
