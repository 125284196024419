import styled from 'styled-components';
import { Button, Select } from '../../../../components';
import { fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

type ButtonStyledProps = {
  readonly isMargin?: boolean
}

export const HeaderStyled = styled.div`
  width: 100%;
  margin-top: ${toRem(26)};
`;

export const HeaderPeriodStyled = styled.div`
  ${fonts.gotham.H18}
`;

export const HeaderFiltersStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${toRem(40)};
  margin-bottom: ${toRem(40)};
`;

export const HeaderFilterStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 23%;
  ${fonts.lato.H14}
`;

export const RecordsSelectStyled = styled(Select)`
  width: 100px;
`;

export const ButtonsStyled = styled(Button)<ButtonStyledProps>`
  max-height: 41px;
  margin-left: ${({ isMargin }) => (isMargin ? '30px' : 'auto')};
  svg {
    margin-right: 5px;
  }
`;

export const ModalInnerStyled = styled.div`
  padding: ${toRem(40)};
`;

export const ModalTitleStyled = styled.div`
  ${fonts.gotham.H26M}
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${toRem(40)};
`;

export const ModalButtonsStyled = styled.div`
  width: 35%;
  display: flex;
  justify-content: space-between;
`;
