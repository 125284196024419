import { gql } from '@apollo/client';

export const GET_TRACKING_PROFILES = gql`
  query listTrackings($input: ListSearchTrackingInput!) {
    listTrackings(input: $input) {
      trackings {
        id
        profileName
        primaryWebsite
        trackingProfileType
        trackingStatus
        websiteDescription
        lastActive
        createdAt
      }
      trackingsCount
    }
  }
`;
