import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from '../../../../../../../components/Button';
import { InputText, InputTextarea, Select } from '../../../../../../../components/Input';
import { PageTitle } from '../../../../../../../components/PageTitle';
import { VisibilityHidden } from '../../../../../../../components/VisibilityHidden';
import * as Styled from './styles';

type SaveReportStepOneProps = {
  reportName: string;
  reportDescription: string;
  permissions: any;
  closeFn: () => void;
  reportNameFn: (event: React.ChangeEvent<HTMLInputElement>) => void;
  reportDescriptionFn: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  permissionsFn: (value: any) => void;
  nextFn: () => void;
  permissionsOptions: any;
};

const SaveReportStepOne = ({
  closeFn, reportNameFn, reportDescriptionFn, permissionsFn, reportName, reportDescription, permissions, nextFn, permissionsOptions,
}: SaveReportStepOneProps) => (
  <Styled.WrapperStyled>
    <Styled.HeaderStyled>
      <PageTitle>Save Report</PageTitle>

      <Styled.ButtonStyled onClick={closeFn}>
        <FontAwesomeIcon icon={faClose} />
        <VisibilityHidden>Close</VisibilityHidden>
      </Styled.ButtonStyled>
    </Styled.HeaderStyled>

    <Styled.ContentStyled>
      <InputText
        label="Report Name"
        required
        type="text"
        onChange={reportNameFn}
        value={reportName}
      />

      <InputTextarea
        label="Report Description"
        name="reportDescription"
        onChange={reportDescriptionFn}
        value={reportDescription}
      />

      <Select
        onChange={permissionsFn}
        label="Permissions"
        description="Set permissions of who can use this saved report."
        selected={permissions}
        options={permissionsOptions}
      />
    </Styled.ContentStyled>

    <Styled.FooterAreaStyled>
      <span>Step 1 of 2</span>

      <Styled.ButtonAreaStyled>
        <Button
          onClick={closeFn}
          theme="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={nextFn}
          disabled={!reportName}
        >
          Continue
        </Button>
      </Styled.ButtonAreaStyled>
    </Styled.FooterAreaStyled>
  </Styled.WrapperStyled>
);

export default SaveReportStepOne;
