import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export const BUTTON_LABEL = {
  SAVE: 'Save',
  CANCEL: 'Cancel',
  NEXT: 'Next',
  SELECT: 'Select Merchant and Period',
  CLEAR: 'Clear Form',
  DOWNLOAD: 'Download CSV',
};

export const MODAL_TITLE = 'Account Balance';

export const MODAL_DESCRIPTION = 'Note: To view and update usage statistics for each merchant, select the individual merchant below.';

export const MODAL_LABEL = {
  MERCHANTS: 'Merchant(s)',
  PERIOD: 'Period',
};

export const MODAL_MERCHANT_DEFAULT = {
  value: 'default',
  label: 'All Merchants',
};

export const MODAL_PERIOD_ALL = {
  value: 'all',
  label: 'All Periods',
};

export const DEFAULT_TOTALS: AccountBalanceManagementTableTotalData = {
  rollover: 0,
  deposits: 0,
  commissions: 0,
  manualAdjustments: 0,
  fees: 0,
  otherFees: 0,
  taxes: 0,
  balance: 0,
};

export const ERROR_ICON = faTriangleExclamation;
