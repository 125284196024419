import styled from 'styled-components';
import { colors } from '../../../../../styles/theme';

export const CellWrapper = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
`;

export const CellCenterWrapper = styled.div`
height: 35px;
display: flex;
align-items: center;
justify-content: center;
`;

export const CellStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.color1};
`;
