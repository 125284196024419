import React from 'react';
import { TRACKING_PROFILE } from '../../../../../utils';
import * as Styled from '../styles';

const formateWebsite = (val: string, rowInfo: PublisherDetailsTrackingProfile) => {
  if (rowInfo.profileName && rowInfo.primaryWebsite && rowInfo.trackingProfileType?.toLowerCase() === TRACKING_PROFILE.WEBSITE) {
    return (
      <Styled.TableDataStyled>
        <Styled.LinkStyled
          to={rowInfo.primaryWebsite}
          target="_blank"
        >
          <p>{rowInfo.profileName}</p>
          {rowInfo.primaryWebsite}
        </Styled.LinkStyled>
      </Styled.TableDataStyled>
    );
  }
  if (rowInfo.profileName) {
    return (
      <Styled.TableDataStyled>
        <p>{rowInfo.profileName}</p>
        <Styled.TableSubtextStyled>{rowInfo.websiteDescription}</Styled.TableSubtextStyled>
      </Styled.TableDataStyled>
    );
  }

  if (rowInfo.primaryWebsite && rowInfo.trackingProfileType?.toLowerCase() === TRACKING_PROFILE.WEBSITE) {
    return (
      <Styled.TableDataStyled>
        <Styled.LinkStyled
          to={rowInfo.primaryWebsite}
          target="_blank"
        >
          {rowInfo.primaryWebsite}
        </Styled.LinkStyled>
      </Styled.TableDataStyled>
    );
  }
  return (
    <Styled.TableDataStyled>
      <p>{rowInfo.websiteDescription || rowInfo.primaryWebsite}</p>
    </Styled.TableDataStyled>
  );
};

export const columns: TableColumn[] = [
  {
    dataField: 'id',
    text: 'id',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '10%',
    formatter: (val: string) => <Styled.TableDataStyled>{val}</Styled.TableDataStyled>,
  },
  {
    dataField: 'profileName',
    text: 'website url / description',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '50%',
    formatter: (val: string, row: PublisherDetailsTrackingProfile) => formateWebsite(val, row),
  },
  {
    dataField: 'createdAt',
    text: 'date created',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '20%',
    formatter: (val: string) => (
      <Styled.TableDataStyled>{new Date(val).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}</Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'lastActive',
    text: 'last active date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '20%',
    formatter: (val: string) => (
      <Styled.TableDataStyled>{val ? new Date(val).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }) : ''}</Styled.TableDataStyled>
    ),
  },
];

export const domainColumns: TableColumn[] = [
  {
    dataField: 'trackingProfileId',
    text: 'tracking id',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '20%',
  },
  {
    dataField: 'trackingDomainUrl',
    text: 'domain url',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '40%',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        <Styled.LinkStyled
          to={val}
          target="_blank"
          theme="secondary"
        >
          {val}
        </Styled.LinkStyled>
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'firstActiveDate',
    text: 'first active date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '20%',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {new Date(val).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'lastActiveDate',
    text: 'last active date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '20%',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {val ? new Date(val).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }) : ''}
      </Styled.TableDataStyled>
    ),
  },
];
