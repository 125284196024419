import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../../../../../../styles/theme';
import { Button, Tooltip } from '../../../../../../../components';
import { toRem } from '../../../../../../../utils';
import { StatusTypes } from './types';

type StatusProps = {
  readonly status: string
}

type IconProps = {
  readonly status: StatusTypes
}

type TooltipProps = {
  readonly feedback: boolean
}

export const CellStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  ${fonts.lato.H14};
  color: ${colors.color1};
  max-height: 60px;
  text-overflow: ellipsis;
  white-space: break-spaces;
  a {
    color: ${colors.color12};
    white-space: pre-wrap;
    line-height: 1.5;
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    :hover {
      color: ${colors.color2};
    }
  }
`;

export const ButtonStyled = styled(Button)`
  padding: 0.5rem;
  color: ${colors.color1};
  border-radius: 2px;
  div {
    margin: 0;
  }
  :hover {
    color: ${colors.color1};
    background-color: ${colors.color19};
  }
`;

export const TooltipStyled = styled(Tooltip)<TooltipProps>`
  div {
    color: ${colors.color4};
    
    svg {
      color: ${colors.color1};
    }
  }
  div:last-child {
    left: ${({ feedback }) => (feedback ? '380%' : '300%')};
    :after {
      left: 16px;
    }
  }
  margin: 0;
`;

export const StatusStyled = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${toRem(8)};
  gap: 10px;
  ${fonts.lato.H12B};
  letter-spacing: 0.3px;
  border-radius: 2px;
  white-space: nowrap;
  background-color: ${({ status }) => {
    switch (status) {
      case 'Review':
        return 'rgba(247, 141, 45, 0.05)';
      case 'Pass':
        return 'rgba(75, 199, 192, 0.05)';
      case 'Fail':
      case 'Check Fail':
        return 'rgba(255, 93, 104, 0.05)';
      case 'Not Applicable':
      case 'NF':
      case 'Incomplete':
        return 'rgba(146, 173, 203, 0.05)';
      default:
        return colors.color11;
    }
  }};
  color: ${({ status }) => {
    switch (status) {
      case 'Review':
        return colors.color2;
      case 'Pass':
        return colors.color25;
      case 'Fail':
      case 'Check Fail':
        return colors.color31;
      case 'Not Applicable':
      case 'NF':
      case 'Incomplete':
        return colors.color12;
      default:
        return colors.color12;
    }
  }};
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

export const LinkStyled = styled(Button)`
  padding: 10px 0;
`;

export const FontAwesomeIconStyled = styled(FontAwesomeIcon)<IconProps>`
  color: ${({ status }) => {
    switch (status) {
      case 'Review':
        return colors.color2;
      case 'Pass':
        return colors.color25;
      case 'Fail':
      case 'Check Fail':
        return colors.color31;
      case 'Not Applicable':
      case 'NF':
      case 'Incomplete':
        return colors.color12;
      default:
        return colors.color40;
    }
  }};
`;

export const CellStyledStyled = styled(CellStyled);
