import { gql } from '@apollo/client';

export const GET_COUNTRIES = gql`
  query getCountries {
    getCountries {
      countries {
        name
        subdivisions {
          name
          id
        }
      }
    }
  }
`;
