import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import { MerchantData } from './types';
import { useStatisticCard } from './hooks';
import { Tooltip } from '../Tooltip';
import { returnColorBaseOnValue, returnIconBaseOnValue } from '../../utils';
import { colors } from '../../styles/theme';

type StatisticCardProps = {
  title: string
  tooltip: string
  statistic: MerchantData
  currency?: '$' | '%'
  loading?: boolean
  tooltipWidth?: number
}

export const StatisticCard = ({
  title, tooltip, statistic, currency, loading = false, tooltipWidth,
}: StatisticCardProps) => {
  const hook = useStatisticCard();
  return (
    <Styled.CardWrapperStyled color={returnColorBaseOnValue({
      value: statistic.performanceIncrease, negativeColor: colors.color14, neutralColor: colors.color2, positiveColor: colors.color21,
    })}
    >
      <Styled.HeaderStyled>
        <Styled.HeaderTitleStyled>{title}</Styled.HeaderTitleStyled>
        <Tooltip
          text={tooltip}
          width={tooltipWidth}
        />
      </Styled.HeaderStyled>
      {loading ? <Styled.SpinnerStyled theme="secondary" /> : (
        <>
          <Styled.BodyStyled>
            {hook.hookCurrency(statistic.currentPerformance, currency)}
            <Styled.PercentageStyled color={returnColorBaseOnValue({
              value: statistic.performanceIncrease, negativeColor: colors.color14, neutralColor: colors.color2, positiveColor: colors.color21,
            })}
            >
              {statistic.performanceIncrease !== 0 && (
              <Styled.IconStyled
                icon={returnIconBaseOnValue<IconProp>({ value: statistic.performanceIncrease, negativeIcon: faLongArrowAltDown, positiveIcon: faLongArrowAltUp })}
                color={returnColorBaseOnValue({
                  value: statistic.performanceIncrease, negativeColor: colors.color14, neutralColor: colors.color2, positiveColor: colors.color21,
                })}
              />
              )}
              {Math.round(Math.abs(statistic.performanceIncrease))}
              %
            </Styled.PercentageStyled>
          </Styled.BodyStyled>

          <Styled.FooterWrapperStyled>
            <Styled.FooterInnerStyled>
              <p>Previous Period</p>
              <span>{hook.hookCurrency(statistic.prevPerformance, currency)}</span>
            </Styled.FooterInnerStyled>

            <Styled.FooterInnerStyled>
              <p>Today</p>
              <span>{hook.hookCurrency(statistic.todaysPerformance, currency, 0)}</span>

            </Styled.FooterInnerStyled>
          </Styled.FooterWrapperStyled>
        </>
      )}
    </Styled.CardWrapperStyled>
  );
};
