export const ADD_NEW_USER = {
  PAGE_TITLE: 'Add New User',
  CANCEL_BUTTON_TEXT: 'Cancel',
  SAVE_BUTTON_TEXT: 'Save',
  INPUTS: {
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    PREFERRED_LANGUAGE: 'Preferred Language',
    EMAIL_ADDRESS: 'Email Address',
    PHONE_NUMBER: 'Phone Number',
    SECURITY_ROLE: 'Security Role',
    POSITION: 'Position',
  },
};
