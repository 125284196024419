import React, {
  createContext, useState, useMemo,
} from 'react';

const initalState = {
  generatePaymentsData: {
    count: 0,
    payments: [],
  },
  paymentReportsData: {
    count: 0,
    payments: [] as any[],
    footerValues: [] as any[],
    selectPublisher: {} as SelectOption,
    selectPaymentMethod: {} as SelectOption,
    selectedPaymentPeriod: {} as SelectOption,
    filter: '' as string,
    selectedRecords: {} as SelectOption,
    currentPage: 1 as number,
    sortColoumn: {} as TableSortColumn,
    totalPages: 1 as number,
    totalRecords: 1 as number,
    publisherOptions: [] as SelectOption[],
    firstLoad: true,
  },
  merchantPaymentReportsData: {
    count: 0,
    merchantPaymentReports: [] as any[],
    payments: [] as any[],
    selectPublisher: {} as SelectOption,
    selectedPaymentPeriod: {} as SelectOption,
    selectedRecords: {} as SelectOption,
    currentPage: 1 as number,
    sortColoumn: {} as TableSortColumn,
    totalPages: 1 as number,
    totalRecords: 1 as number,
    publisherOptions: [] as SelectOption[],
    firstLoad: true,
  },
};

  type ReportDetailsContextType = {
    state: typeof initalState
    setHandler: (value: Record<string, any>) => void
  }

  type ReportDetailsContextProps = {
    children: React.ReactNode
  }

export const context = createContext<ReportDetailsContextType>({} as ReportDetailsContextType);

export const ReportDetailsContext = ({ children }: ReportDetailsContextProps) => {
  const [reportDetails, setReportDetails] = useState<any>(initalState);

  const handleSetReportDetails = (value: Record<string, any>) => {
    setReportDetails(value);
  };

  const state = useMemo(() => ({
    state: reportDetails,
    setHandler: handleSetReportDetails,
  }), [reportDetails]);

  return (
    <context.Provider value={state}>
      {children}
    </context.Provider>
  );
};
