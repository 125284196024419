import React from 'react';
import { dateFormatter } from '../../../utils';

export const columns: TableColumn[] = [
  {
    dataField: 'createdAt',
    text: 'Period',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {dateFormatter(new Date(value))}
      </div>
    ),
    width: '25%',
  },
  {
    dataField: 'currencyFrom',
    text: 'From',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '25%',
  },
  {
    dataField: 'currencyTo',
    text: 'To',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '25%',
  },
  {
    dataField: 'conversionRate',
    text: 'Exchange Rate',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{(+value).toFixed(2)}</div>,
    width: '25%',
  },
];
