import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query publisherDashboardProducts($input: PublisherDashboardInput) {
    publisherDashboardProducts(input: $input) {
      topProducts {
        name
        merchantName
        totalTransactions
        approvedTransactions
        totalCommissions
        commissionsPerformance
      }
      productByApproval {
        productCategory
        transactions
      }
    }
  }
`;
