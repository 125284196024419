import styled from 'styled-components';
import { colors, fonts } from '../../../../../../styles/theme';

export const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
`;

export const CardWrapper = styled.div`
  max-width: 270px;
  border: 1px solid ${colors.color1};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`;

export const LogoStyled = styled.figure`
  width: 40px;
  height: auto;
`;

export const MerchantStyled = styled.div`
  width: 100%;
`;

export const MerchantNameStyled = styled.p`
  ${fonts.lato.H16M}
  color: ${colors.color1};
`;

export const MerchantUrlStyled = styled.p`
  max-width: 200px;
  line-height: 1.5;
  color: ${colors.color7};
  padding-top: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LabelStyled = styled.p`
  margin-top: 2rem;
  text-transform: uppercase;
  ${fonts.gotham.H12}
  color: ${colors.color13};
`;

export const ValueStyled = styled.p`
  margin-top: 0.5rem;
  ${fonts.lato.H16R}
  color: ${colors.color1};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
