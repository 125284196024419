import { useState, useEffect } from 'react';

export const useResize = (width = '80px') => {
  const [resizeState, setResizeState] = useState(width);
  const [fixedHeader, setFixedHeader] = useState<boolean>(false);

  const onResize = (elementIndex: number) => {
    const element: HTMLElement | null = document.querySelector(`[data-column="${elementIndex}"]`);
    const headerElement: HTMLElement | null = document.getElementById(`fixed-header-span-${elementIndex}`);

    if (element) {
      const resize = (resizeEvent: any) => {
        const pixel = resizeEvent.pageX - (element.getBoundingClientRect().left - 30);
        element.style.width = `${pixel > 100 ? pixel : 100}px`;
        element.style.maxWidth = `${pixel > 100 ? pixel : 100}px`;
        setResizeState(`${pixel > 100 ? pixel : 100}px`);

        if (headerElement) {
          headerElement.style.width = `${pixel > 100 ? pixel : 100}px`;
          headerElement.style.maxWidth = `${pixel > 100 ? pixel : 100}px`;
        }
      };

      const stopResize = () => {
        window.removeEventListener('mousemove', resize, true);
        window.removeEventListener('mouseup', stopResize, true);
      };

      window.addEventListener('mousemove', resize, true);
      window.addEventListener('mouseup', stopResize, true);
    }
  };

  const resetColumnSize = (elementIndex: number) => {
    const targetColumn : HTMLElement | null = document.getElementById(`fixed-header-span-${elementIndex}`);
    const element: HTMLElement | null = document.querySelector(`[data-column="${elementIndex}"]`);
    if (targetColumn && element) {
      targetColumn.style.width = element.style.minWidth;
      targetColumn.style.maxWidth = element.style.minWidth;
    }
  };

  useEffect(() => {
    const headerElement: HTMLElement | null = document.getElementById('drag-table-fixed-header');
    const dragTable: HTMLElement | null = document.getElementById('drag-table-component');

    const watchTable: () => void = () => {
      if (dragTable && headerElement) {
        const leftValue = dragTable.getBoundingClientRect()?.left;
        const scrollPosition = dragTable.scrollLeft;
        headerElement.style.left = `${leftValue - scrollPosition}px`;
      }
    };

    const watchTopPosition = () => {
      const componentLocation = document.querySelector('#drag-table-component')?.getBoundingClientRect();

      if (componentLocation?.top && componentLocation?.top >= 0) {
        setFixedHeader(false);
      } else if (componentLocation?.top && componentLocation?.top < 0) {
        setFixedHeader(true);
      }
    };

    window.addEventListener('scroll', watchTopPosition);
    if (dragTable) {
      dragTable.addEventListener('scroll', watchTable);
    }
    return () => {
      window.removeEventListener('scroll', watchTopPosition);
      if (dragTable) {
        dragTable.removeEventListener('scroll', watchTable);
      }
    };
  }, []);

  return {
    hookResize: resizeState,
    hookSetResize: (newResize: string) => {
      setResizeState(newResize);
    },
    hookOnResize: onResize,
    hookFixedHeader: fixedHeader,
    hookResetColumnSize: resetColumnSize,
  };
};
