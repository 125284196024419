import React from 'react';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import { Checkbox } from '../../../../../../components';
import { formatAmount } from '../../../../../../utils/amountFormatter';

const CheckBoxCellFormatter = (checked: boolean, onChange: (state: any) => void) => (
  <Styled.CellWrapperStyled theme="checkBox">
    <Checkbox
      checked={checked}
      onChange={onChange}
    />
  </Styled.CellWrapperStyled>
);

const MinimumBalanceFormatter = (value: any, row: any) => {
  if (Number(value) > Number(row.balance)) {
    return (
      <Styled.CellWrapperStyled>
        <Styled.ToolTipStyled
          text={`Minimum required: $${value}`}
          icon={faTriangleExclamation}
        />
      </Styled.CellWrapperStyled>
    );
  }
  return <div />;
};

export const columns: TableColumn[] = [
  {
    dataField: 'checked',
    text: '',
    headerFormatter: (headerCheckBox: any, headerCheckBoxHandler: (state: any) => void) => (
      CheckBoxCellFormatter(headerCheckBox, headerCheckBoxHandler)
    ),
    formatter: (_: any, row: any, __: any, ___: any, handleCheckBox?: (id: any, state: any) => void) => (CheckBoxCellFormatter(row.checked, ((state: boolean) => { if (handleCheckBox) { handleCheckBox(row, state); } }))),
    width: '40px',
    isCheckBox: true,
  },
  {
    dataField: 'minBalanceRequired',
    text: '',
    width: '40px',
    formatter: (value:any, row: any) => (
      MinimumBalanceFormatter(value, row)
    ),
    cellOverflow: true,
  },
  {
    dataField: 'merchant',
    text: 'merchant',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (value.companyName),
    width: '25%',
    wrapText: true,
  },
  {
    dataField: 'productCategory',
    text: 'product category',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '20%',
  },
  {
    dataField: 'merchantId',
    text: 'merchant id',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => (row.merchant.id),
    width: '20%',
  },
  {
    dataField: 'balance',
    text: 'account balance',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '20%',
  },
];
