import React from 'react';
import * as Styled from './styles';
import { Button } from '../../../../../components';
import { BUTTONS, CONFIRMATION, LIST_DESCRIPTION } from './enums';
import { useFintelCheckReport } from '../../hooks';

type ModalProps = {
  hook: ReturnType<typeof useFintelCheckReport>
}

export const UpdateVisibility = ({ hook }: ModalProps) => (
  <Styled.ModalWrapper>
    <Styled.ModalTitle>{hook.hookModalInfo.title}</Styled.ModalTitle>
    <Styled.ConfirmationStyled>
      <p>{CONFIRMATION(hook.hookModalInfo?.visibilityName)}</p>
      <p>{LIST_DESCRIPTION}</p>
      <ul>
        {hook.hookModalInfo.list?.map((item: string) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </Styled.ConfirmationStyled>
    <Styled.ModalButtonWrapper>
      <Button
        theme="secondary"
        onClick={hook.hookModalSetIsOpen}
      >
        {BUTTONS.CANCEL}
      </Button>
      <Button
        onClick={() => {
          hook.hookModalSetIsOpen();
          if (hook.hookModalInfo.visibility) {
            hook.hookChangeModalInfo({
              data: {
                visibility: false,
              },
            });
            hook.hookUpdateFintelCheckVisibility(false);
          } else {
            hook.hookChangeModalInfo({
              data: {
                visibility: true,
              },
            });
            hook.hookUpdateFintelCheckVisibility(true);
          }
        }}
      >
        {hook.hookModalInfo.buttonName}
      </Button>
    </Styled.ModalButtonWrapper>
  </Styled.ModalWrapper>
);
