import logoFull from '../assets/images/fintel-logo.png';
import logoBlueFull from '../assets/images/fintel-logo.jpg';
import logoSmall from '../assets/images/fintel-logo-small.png';
import placeholder from '../assets/images/fintel-search.svg';
import noResult from '../assets/images/fintel-no-results.svg';
import piblisherPlaceholder from '../assets/images/publisher-profile-placeholder.svg';
import noPic from '../assets/images/no-pic.png';
import publisherCheckPlaceholder from '../assets/images/publisher-check-placeholder.svg';
import ruleCheckPlaceholder from '../assets/images/rule-check-placeholder.svg';
import newPublisher from '../assets/images/new-publisher.svg';
import payout from '../assets/images/payout.svg';
import lighthouse from '../assets/images/lighthouse.svg';
import newIcon from '../assets/images/new.svg';
import noImage from '../assets/images/noImageProgramDetails.svg';
import envelope from '../assets/images/envelope.svg';
import publisherCheckSample from '../assets/images/publisher-check-sample.svg';
import ruleCheckSample from '../assets/images/rule-check-sample.svg';

export const imageList = {

  logoFull: {
    src: logoFull,
    alt: 'Fintel Logo',
  },
  logoSmall: {
    src: logoSmall,
    alt: 'Fintel Logo',
  },
  logoBlueFull: {
    src: logoBlueFull,
    alt: 'Fintel Logo',
  },
  placeholder: {
    src: placeholder,
    alt: 'Placeholder',
  },
  noResults: {
    src: noResult,
    alt: 'No results',
  },
  publisherProfilePlaceholder: {
    src: piblisherPlaceholder,
    alt: 'Publisher Profile Placeholder',
  },
  noPic: {
    src: noPic,
    alt: 'No Pic',
  },
  publisherCheckPlaceholder: {
    src: publisherCheckPlaceholder,
    alt: 'Publisher Checks Placeholder',
  },
  ruleCheckPlaceholder: {
    src: ruleCheckPlaceholder,
    alt: 'Rule Checks Placeholder',
  },
  newPublisher: {
    src: newPublisher,
    alt: 'New Publisher',
  },
  paypalLogoImage: {
    src: payout,
    alt: 'Paypal Logo',
  },
  lightHousePlaceholder: {
    src: lighthouse,
    alt: 'placeholder',
  },
  newIcon: {
    src: newIcon,
    alt: 'new',
  },
  noImage: {
    src: noImage,
    alt: 'noImage',
  },
  envelope: {
    src: envelope,
    alt: 'placeholder',
  },
  publisherCheckSample: {
    src: publisherCheckSample,
    alt: 'publisher check sample',
  },
  ruleCheckSample: {
    src: ruleCheckSample,
    alt: 'rule check sample',
  },
};
