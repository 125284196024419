export const USER_PROFILE_MANAGEMENT_PAGE = {
  PAGE_TITLE: 'User Profile Management',
  SETTINGS_TITLE: 'User Account Management',
  CANCEL_BUTTON_TEXT: 'Cancel',
  SAVE_BUTTON_TEXT: 'Save',
  INPUT_LABELS: {
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    PREFERRED_LANGUAGE: 'Preferred Language',
    EMAIL_ADDRESS: 'Email Address',
    PHONE_NUMBER: 'Phone Number',
    PASSWORD: 'Password',
    SECURITY_ROLE: 'Security Role',
    POSITION: 'Position',
  },
};
