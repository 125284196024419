import { useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '../../../../../../../hooks';
import { LIST_PRODUCT } from '../../../graphql/ queries';
import { path } from '../../../../../../../utils/path';
import { MERCHANT_PREFIX, csvGenerator } from '../../../../../../../utils';
import { GET_CSV } from '../../../graphql/ queries/getCsv';
import { LIST_OPTIONS } from '../../../graphql/ queries/listoptions';

export const useProductCatalog = () => {
  const defaultProductIDOption = {
    label: 'All Products',
    value: '',
  };

  const defaultProductCategoryOption = {
    label: 'All Categories',
    value: '',
  };

  const [productList, setProductList] = useState<any[]>([]);
  const [productIDList, setProductIDList] = useState<SelectOption[]>();
  const [selectedProductID, setSelectedProductID] = useState<SelectOption>(defaultProductIDOption);
  const [selectedProductCategory, setSelectedProductCategory] = useState<SelectOption>(defaultProductCategoryOption);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const hookUser = useUserInfo();

  const [getProductList, { loading: getProductsLoading }] = useLazyQuery(LIST_PRODUCT);
  const [getProductOptionsQuery, { loading: getProductOptionsLoading }] = useLazyQuery(LIST_OPTIONS);
  const [getProductCsv, { loading: getProductCsvLoading }] = useLazyQuery(GET_CSV);

  const handleArrowClick = (value: Record<string, any>) => {
    navigate(`${MERCHANT_PREFIX}${path.editProduct.href}`, { state: value });
  };

  const getProductsIdListHandler = async () => {
    const selectedUser = hookUser.hookWhoAmI.programId;

    const { data } = await getProductList({
      variables: {
        input: {
          programId: selectedUser,
          productCategory: selectedProductCategory.label === 'All Categories' ? undefined : selectedProductCategory.value,
          currentPage: page,
          productId: selectedProductID.label === 'All Products' ? undefined : selectedProductID.label,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.productsV2) {
      setProductList(data.productsV2.products);
      setTotalPages(Math.ceil(data.productsV2.count / 10));
    } else {
      setErrorMessage('Failed to fetch products');
    }
  };

  const getProductOptions = async () => {
    const selectedUser = hookUser.hookWhoAmI.programId;

    const { data } = await getProductOptionsQuery({
      variables: {
        programId: selectedUser,
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.products) {
      const productIdOptions: any[] = [{ label: 'All Products', value: '' }];
      data.products.products.forEach((program: any) => {
        const productIDFormatted: SelectOption = {
          label: program.customizedProductId,
          value: program.customizedProductId,
        };
        productIdOptions.push(productIDFormatted);
      });
      setProductIDList(productIdOptions);
    } else {
      setErrorMessage('Failed to fetch products');
    }
  };

  const handleGenerateCSV = async () => {
    const selectedUser = hookUser.hookWhoAmI.programId;
    const { data } = await getProductCsv({
      variables: {
        input: {
          programId: selectedUser,
          productCategory: selectedProductCategory.label === 'All Categories' ? undefined : selectedProductCategory.label,
          productId: selectedProductID.label === 'All Products' ? undefined : selectedProductID.label,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getProductsCsv?.csv) {
      csvGenerator(data.getProductsCsv.csv, 'Product List');
    }
  };

  useEffect(() => {
    getProductsIdListHandler();
  }, [page, selectedProductCategory, selectedProductID]);

  useEffect(() => {
    getProductOptions();
  }, []);

  const setSelectedProductIdHandler = (productIDSelected: SelectOption) => {
    setSelectedProductID(productIDSelected);
    setPage(1);
  };

  const selectProductCategoryHandler = (productCategorySelected: SelectOption) => {
    setSelectedProductCategory(productCategorySelected);
    setPage(1);
  };

  const changePageHandler = (pageValue: number) => {
    setPage(pageValue);
  };

  return {
    hookProductList: productList,

    hookProductIDLIst: productIDList,

    hookSelectedProductID: selectedProductID,

    hookPage: page,

    hookTotalPages: totalPages,

    hookSelectedProductCategory: selectedProductCategory,

    hookTableLoading: getProductsLoading,

    hookErrorMessage: errorMessage,

    hookCsvLoading: getProductCsvLoading,

    hookGetProductOptionsLoading: getProductOptionsLoading,

    hookHandleArrowClick: handleArrowClick,

    hookSetSelectedProductID: setSelectedProductIdHandler,

    hookSetSelectedProductCategory: selectProductCategoryHandler,

    hookChangePage: changePageHandler,

    hookDownloadCSV: handleGenerateCSV,
  };
};
