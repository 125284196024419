import { Totals } from '../types';
import { moneyFormater } from './moneyFormater';

export const processDataToCSVString = (allTableData: any[], allTableTotals: Totals | undefined, allReportColumns: TableColumn[]) => {
  // Create the Row of Column Titles
  const headerRow = allReportColumns.map((column) => column.text).toString();

  // Create the Row of Footer Totals
  const footerRow = allReportColumns.map((column) => {
    if (column.dataType === 'key') return 'TOTAL CA / TOTAL US';
    if (!column?.dataField || !allTableTotals || allTableTotals[column.dataField as keyof Totals] == null) return '';
    if (column.dataType === 'money') {
      return `"${moneyFormater.format(allTableTotals[column.dataField as keyof Totals][0])} / ${moneyFormater.format(allTableTotals[column.dataField as keyof Totals][1])}"`;
    }
    return allTableTotals[column.dataField as keyof Totals].join(' / ');
  });

  // Create the Data Rows
  const newData = allTableData.map((row) => {
    const rowArray = allReportColumns.map((column) => {
      if (!column?.dataField || row[column?.dataField] == null) return 'N/A';
      if (!['"', '\r', '\n', ','].some((e) => row[column.dataField].toString().indexOf(e) !== -1)) {
        if (typeof row[column.dataField] === 'number') return +row[column.dataField].toFixed(2);
        return row[column.dataField];
      }
      return `"${row[column.dataField].toString().replace(/"/g, '""')}"`;
    });
    return rowArray.toString();
  });

  return [headerRow, ...newData, footerRow].join('\n');
};
