import { gql } from '@apollo/client';

export const GET_FILTERS = gql`
  query getFiltersForReports($input: CompanyInput) {
    companyOptions(input: $input) {
      companies {
        id
        companyName
        program {
          id
          campaigns {
            id
            name
          }
          products {
            id
            name
            status
            customizedProductId
            productCategory
          }
          publisherGroups {
            id
            name
          }
          ads {
            id
            adName
          }
        }
        memberships {
          id
          publisher {
            id
            companyName
            trackings {
              id
              primaryWebsite
              websiteDescription
              profileName
              trackingProfileType
            }
          }
        }
      }
    }
  }
`;
