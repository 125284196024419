import React from 'react';
import { PRODUCT_FEED } from '../../enums';
import {
  Banking, Insurance, Investments, Mortgage, CreditCards,
} from './Variations';
import { PRODUCT_CATEGORIES } from '../../../../../../../utils';
import { LegalReferenceItems } from './LegalReferenceComponent';

const editProductTabs = (productCategory: string, hook: any, isReadOnly: boolean) => {
  switch (productCategory) {
    case PRODUCT_CATEGORIES.INVESTMENTS:
      return (
        <>
          <Investments
            hook={hook}
            isReadOnly={isReadOnly}
          />
          <LegalReferenceItems
            disabled={hook.hookLoading || isReadOnly}
            setLegalReferenceItems={hook.hookSetLegalReferenceItems}
            addLegalReferenceItem={hook.hookAddLegalReferenceItem}
            legalReferenceItems={hook.hookLegalReferenceItems}
            handleLegalReferenceXButton={hook.hookHandleLegalReferenceX}
          />
        </>
      );
    case PRODUCT_CATEGORIES.INSURANCE:
      return (
        <>
          <Insurance
            hook={hook}
            isReadOnly={isReadOnly}
          />
          <LegalReferenceItems
            disabled={hook.hookLoading || isReadOnly}
            setLegalReferenceItems={hook.hookSetLegalReferenceItems}
            addLegalReferenceItem={hook.hookAddLegalReferenceItem}
            legalReferenceItems={hook.hookLegalReferenceItems}
            handleLegalReferenceXButton={hook.hookHandleLegalReferenceX}
          />
        </>
      );
    case PRODUCT_CATEGORIES.BANKING:
      return (
        <>
          <Banking
            hook={hook}
            isReadOnly={isReadOnly}
          />
          <LegalReferenceItems
            disabled={hook.hookLoading || isReadOnly}
            setLegalReferenceItems={hook.hookSetLegalReferenceItems}
            addLegalReferenceItem={hook.hookAddLegalReferenceItem}
            legalReferenceItems={hook.hookLegalReferenceItems}
            handleLegalReferenceXButton={hook.hookHandleLegalReferenceX}
          />
        </>
      );
    case PRODUCT_CATEGORIES.CREDIT_CARDS:
      return (
        <>
          <CreditCards
            hook={hook}
            isReadOnly={isReadOnly}
          />
          <LegalReferenceItems
            disabled={hook.hookLoading || isReadOnly}
            setLegalReferenceItems={hook.hookSetLegalReferenceItems}
            addLegalReferenceItem={hook.hookAddLegalReferenceItem}
            legalReferenceItems={hook.hookLegalReferenceItems}
            handleLegalReferenceXButton={hook.hookHandleLegalReferenceX}
          />
        </>

      );
    case PRODUCT_CATEGORIES.MORTGAGE:
      return (
        <>
          <Mortgage
            hook={hook}
            isReadOnly={isReadOnly}
          />
          <LegalReferenceItems
            disabled={hook.hookLoading || isReadOnly}
            setLegalReferenceItems={hook.hookSetLegalReferenceItems}
            addLegalReferenceItem={hook.hookAddLegalReferenceItem}
            legalReferenceItems={hook.hookLegalReferenceItems}
            handleLegalReferenceXButton={hook.hookHandleLegalReferenceX}
          />
        </>
      );
    default:
      return (
        <LegalReferenceItems
          disabled={hook.hookLoading || isReadOnly}
          setLegalReferenceItems={hook.hookSetLegalReferenceItems}
          addLegalReferenceItem={hook.hookAddLegalReferenceItem}
          legalReferenceItems={hook.hookLegalReferenceItems}
          handleLegalReferenceXButton={hook.hookHandleLegalReferenceX}
        />
      );
  }
};

export default editProductTabs;
