import { gql } from '@apollo/client';

export const LIST_COUNTRIES = gql`
  query List($input: ListGeoCountryInput) {
    listGeoCountry(input: $input) {
      list {
        country
        id
      }
    }
  }
`;
