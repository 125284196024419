import { useEffect, useState } from 'react';
import { IMAGE_FILE_TYPES, PROFILE_IMAGE_TYPES } from '../../../../utils';
import { ERROR_MESSAGES } from '../utils';

type CBFile = (file: File) => void;

export const useDropZone = (maxFileSize: number, cb?: CBFile, type?: string, accept = '') => {
  const [filesState, setFilesState] = useState<File>();
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>('');
  const [errorState, setErrorState] = useState<string>();

  const setFilesStateHandler = (Event: React.DragEvent<HTMLElement>) => {
    Event.preventDefault();
    const file = Event.dataTransfer.files[0];

    const fileTypes = accept.split(',').map((item: string) => item.trim());

    if (maxFileSize && file.size > maxFileSize) {
      setErrorState(ERROR_MESSAGES.FILE_SIZE);
      return;
    }

    if (accept === '' || fileTypes.includes(`.${file.name.split('.')[1]}`)) {
      setFilesState(file);
      setIsDraggingOver(false);
      setImagePreviewUrl(URL.createObjectURL(file));
      setErrorState('');
      if (cb) {
        cb(file);
      }
    } else {
      setErrorState(ERROR_MESSAGES.FILE_TYPE);
    }
  };

  const dragOverHandler = (Event: React.DragEvent<HTMLElement>) => {
    Event.preventDefault();
    setIsDraggingOver(true);
  };

  const dragLeaveHandler = (Event: React.DragEvent<HTMLElement>) => {
    Event.preventDefault();
    setIsDraggingOver(false);
  };

  const uploadHandler = (Event: React.ChangeEvent<HTMLInputElement>) => {
    if (Event.target.files) {
      const file = Event.target.files[0];
      if (file?.size <= maxFileSize) {
        setErrorState('');
        if (type === 'Banner' && imagePreviewUrl) {
          if (!IMAGE_FILE_TYPES.includes(file.type)) {
            setErrorState(ERROR_MESSAGES.FILE_TYPE);
          } else {
            const currImg = new Image();
            currImg.src = imagePreviewUrl;
            const newImg = new Image();
            newImg.src = URL.createObjectURL(file);
            newImg.onload = () => {
              if (currImg.height !== newImg.height || currImg.width !== newImg.width) {
                setErrorState(ERROR_MESSAGES.IMAGE_SIZE);
              } else {
                setErrorState('');
                setFilesState(file);
                setImagePreviewUrl(URL.createObjectURL(file));
                if (cb) {
                  cb(file);
                }
              }
            };
          }
        } else if (type === 'ProfileImage') {
          if (!PROFILE_IMAGE_TYPES.includes(file.type)) {
            setErrorState(ERROR_MESSAGES.FILE_TYPE);
          } else {
            setFilesState(file);
            setImagePreviewUrl(URL.createObjectURL(file));
            if (cb) {
              cb(file);
            }
          }
        } else {
          setFilesState(file);
          setImagePreviewUrl(URL.createObjectURL(file));
          if (cb) {
            cb(file);
          }
        }
      } else if (file?.size > maxFileSize) {
        setErrorState(ERROR_MESSAGES.FILE_SIZE);
      } else {
        setErrorState('');
      }
    }
  };

  useEffect(() => {
    if (accept.includes('/')) {
      console.error("DropZone component not built to use MIME types, eg. use '.png, *.png' not 'image/png'");
    }
  }, [accept]);

  return {
    hookFile: filesState,
    hookSetFiles: setFilesStateHandler,
    hookDragOver: dragOverHandler,
    hookDragLeave: dragLeaveHandler,
    hookIsDraggingOver: isDraggingOver,
    hookUpload: uploadHandler,
    hookImagePreviewUrl: imagePreviewUrl,
    hookSetImagePreviewUrl: setImagePreviewUrl,
    hookError: errorState,
  };
};
