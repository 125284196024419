import React, { useState } from 'react';

export const useUser = () => {
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPosition, setUserPosition] = useState('');
  const [userSuscription, setUserSuscription] = useState(false);

  const setUserFirstNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserFirstName(e.target.value);
  };
  const setUserLastNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserLastName(e.target.value);
  };
  const setUserEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(e.target.value);
  };

  const setUserPositionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPosition(e.target.value);
  };

  const setUserSuscriptionHandler = () => {
    setUserSuscription(!userSuscription);
  };

  return {
    hookUserFirstName: userFirstName,
    hookUserLastName: userLastName,
    hookUserEmail: userEmail,
    hookUserPosition: userPosition,
    hookUserSuscription: userSuscription,

    hookSetUserFirstName: setUserFirstNameHandler,
    hookSetUserLastName: setUserLastNameHandler,
    hookSetUserEmail: setUserEmailHandler,
    hookSetUserPosition: setUserPositionHandler,
    hookSetUserSuscription: setUserSuscriptionHandler,
  };
};
