export const TITLE_SUFFIX = 'Publisher';

export const SUBTITLE = {
  OVERVIEW: 'Publisher Overview',
  DEMOGRAPHICS: 'Audience Demographics',
};

export const PLACEHOLDER = {
  SEARCH: 'Search for Name/ID/URL',
  STATUS: 'Membership Status',
  GROUP: 'Publisher Group',
  MARKET: 'Primary Audience Market',
  CATEGORY: 'Product Category',
  PROMOTION: 'Promotion Method',
  AUDIENCE_SIZE: 'Average Monthly Audience Size',
  ACTIVITY_STATUS: 'Activity Status',
  AGE: 'Age',
  GENDER: 'Gender',
  RATING: 'Credit Score Rating',
  INCOME: 'Average Income',
};

export const INPUT_LABEL = {
  SEARCH: 'Show Advanced Search',
  RECORD: 'Records',
  ADD_REMOVE_MESSAGE: 'Please select Group before press Add/Remove button',
};

export const BUTTON_LABEL: {[key: string]: string } = {
  CLEAR: 'Clear Form',
  COLUMN: 'Customize Columns',
  REMOVE_GROUP: 'Remove From Group',
  ADD_GROUP: 'Add To Group',
  CUSTOMIZE: 'Customize',
  CANCEL: 'Cancel',
  REMOVE: 'Remove',
  ADD: 'Add',
};

export const ACTIVITY_STATUS_OPTIONS = [
  { label: 'Any Activity Status', value: '' },
  { label: 'Click active', value: 'Click active' },
  { label: 'Approval active', value: 'Approval active' },
  { label: 'Not live', value: 'Not Live' },
  { label: 'Not live (new to network)', value: 'Not live (new to network)' },
];

export const DEFAULT_COLUMNS = [
  'Member Since', 'Audience Markets', 'Product Categories', 'Promotion Method', 'Monthly Web Visits', 'Last Login', 'Publisher Status',
];

export const TABLE_COLUMN_ORDER = [
  'Member Since',
  'Audience Markets',
  'Product Categories',
  'Promotion Method',
  'Monthly Web Visits',
  'Age',
  'Gender',
  'Average Income',
  'Credit Score Rating',
  'Last Login',
  'Publisher Status',
];

export const MODAL_TYPE = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

export const MODAL_TITLE: {[key: string]: string } = {
  CUSTOMIZE: 'Customize Columns',
  ADD: 'Add to Group',
  REMOVE: 'Remove from Group',
};

export const MODAL_DISCRIPTION: {[key: string]: string } = {
  REMOVE: 'Are you sure you want to remove ## ** ## publisher(s) from the group?',
  REMOVE_METADATA: 'Once removed, publishers will no longer earn commissions associated with this group and all their links associated with campaigns for this group will be disabled.',
  ADD: 'Are you sure you want to add ## ** ## publisher(s) to the group?',
};

export const DEFAULT_PRODUCT_CATEGORY: SelectOption = { label: 'Any Product Categories', value: '' };
export const PUBLISHER_GROUP_DEFAULT: SelectOption = { label: 'Any Publisher Groups', value: '' };
