import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageTitle, Modal } from '../../../../../components';
import * as Styled from '../styles';
import { RULE_MANAGER } from '../enums';
import { UseFintelRuleManagerType } from '../types';

const DeleteRuleModal = ({
  hook,
}: {
  hook: UseFintelRuleManagerType
}) => (
  <Modal isOpen={hook.isDeleteModalOpen}>
    <Styled.ModalWrapperStyled>
      <Styled.TopStyled>
        <PageTitle>
          {RULE_MANAGER.DELETE_TITLE}
        </PageTitle>

        <Styled.CloseButtonStyled
          theme="secondary"
          onClick={() => { hook.setIsDeleteModalOpen(false); }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Styled.CloseButtonStyled>
      </Styled.TopStyled>

      <Styled.ContentStyled>
        {RULE_MANAGER.RULE_DELETE_WARNING}
      </Styled.ContentStyled>
      <Styled.FooterStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => { hook.setIsDeleteModalOpen(false); }}
        >
          {RULE_MANAGER.RULE_DELETE_CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          theme="primary"
          onClick={() => { hook.deletePostback(); }}
        >
          {RULE_MANAGER.RULE_DELETE}
        </Styled.ButtonStyled>
      </Styled.FooterStyled>
    </Styled.ModalWrapperStyled>
  </Modal>
);

export default DeleteRuleModal;
