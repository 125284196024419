import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { imageList, path } from '../../utils';
import * as Styled from './styles';
import { Alert } from '../../components';

const Login = () => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  /**
   * Calls the loginWithRedirect method from Auth0
   * If there's an error, shows an alert
   */
  const handleLoginWithRedirect = async () => {
    try {
      await loginWithRedirect({ authorizationParams: { prompt: 'login' } });
    } catch (error) {
      setAlertMessage('Could not login at this time. Please try again.');
      setIsAlertOpen(true);
    }
  };

  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img
            src={imageList.logoFull.src}
            alt="logo"
          />
        </figure>
      </Styled.LeftStyled>

      <Styled.RightStyled>
        <Styled.RightContentStyled>

          <h1>Welcome Back</h1>

          <Styled.RowStyled>
            <Styled.ButtonStyled
              theme="secondary"
              type="button"
              onClick={() => navigate(path.createAccountStep1.href)}
            >
              Sign Up
            </Styled.ButtonStyled>
            <Styled.ButtonStyled
              onClick={handleLoginWithRedirect}
              type="submit"
            >
              Login
            </Styled.ButtonStyled>
          </Styled.RowStyled>
        </Styled.RightContentStyled>

        <Alert
          isOpen={isAlertOpen}
          onClose={setIsAlertOpen}
          message={alertMessage}
          type="error"
        />
      </Styled.RightStyled>
    </Styled.WrapperStyled>
  );
};

export default Login;
