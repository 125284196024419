import React from 'react';
import { BUTTON_LABEL, ENABLE_BALANCE_REPORTING_MODAL } from '../../contracts';
import { Button, InfoBox } from '../../../../../components';
import * as Styled from '../../styles';
import { ERROR_ICON } from '../../../../../utils';

type ProductAccountBalanceReportingProps = {
  closeHandler: () => void
  updateHandler: () => void
  errors: { [key: string]: string }

}

export const ProductAccountBalanceReporting = ({
  closeHandler, updateHandler, errors,
}: ProductAccountBalanceReportingProps) => (
  <Styled.ModalBodyStyled>
    {errors.message && (
      <InfoBox
        title=""
        description={errors.message}
        icon={ERROR_ICON}
        theme="ERROR"
      />
    )}
    <Styled.ModalTitleStyled>{ENABLE_BALANCE_REPORTING_MODAL.TITLE}</Styled.ModalTitleStyled>

    <Styled.ModalDescriptionStyled>
      <p>{ENABLE_BALANCE_REPORTING_MODAL.SUB_TITLE}</p>
    </Styled.ModalDescriptionStyled>
    <p>{ENABLE_BALANCE_REPORTING_MODAL.LIST_TITLE}</p>
    <Styled.ModalListStyled>
      {ENABLE_BALANCE_REPORTING_MODAL.LIST.map((item) => (
        <li key={item.length + Math.random() * 123}>
          {item.map((para) => (
            <Styled.ModalSpanStyled
              key={para.text}
              bold={para.style === 'bold'}
            >
              {para.text}
            </Styled.ModalSpanStyled>
          ))}
        </li>
      ))}
    </Styled.ModalListStyled>
    <Styled.ModalFooterStyled>
      <Button
        onClick={closeHandler}
        theme="secondary"
      >
        {BUTTON_LABEL.CANCEL}
      </Button>
      <Styled.ButtonWrapperStyled>
        <Button
          onClick={updateHandler}
        >
          {BUTTON_LABEL.UPDATE}
        </Button>
      </Styled.ButtonWrapperStyled>
    </Styled.ModalFooterStyled>
  </Styled.ModalBodyStyled>
);
