/* eslint-disable no-param-reassign */
export const hoverManyElements = (rowIndex: number, isMouveOver: boolean, dataAttribute: string) => {
  const elements: any = document.querySelectorAll(`[${dataAttribute}="${rowIndex}"]`);

  elements.forEach((element: any) => {
    if (isMouveOver) {
      element.style.background = '#FDE8D5';
    } else {
      element.style.background = '#FFFFFF';
    }
  });
};
