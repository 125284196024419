const month = (num: string) => {
  let m = '';
  switch (num) {
    case '1':
      m = 'January';
      break;
    case '2':
      m = 'February';
      break;
    case '3':
      m = 'March';
      break;
    case '4':
      m = 'April';
      break;
    case '5':
      m = 'May';
      break;
    case '6':
      m = 'June';
      break;
    case '7':
      m = 'July';
      break;
    case '8':
      m = 'August';
      break;
    case '9':
      m = 'September';
      break;
    case '10':
      m = 'October';
      break;
    case '11':
      m = 'November';
      break;
    case '12':
      m = 'December';
      break;
    case 'January':
      m = '1';
      break;
    case 'February':
      m = '2';
      break;
    case 'March':
      m = '3';
      break;
    case 'April':
      m = '4';
      break;
    case 'May':
      m = '5';
      break;
    case 'June':
      m = '6';
      break;
    case 'July':
      m = '7';
      break;
    case 'August':
      m = '8';
      break;
    case 'September':
      m = '9';
      break;
    case 'October':
      m = '10';
      break;
    case 'November':
      m = '11';
      break;
    case 'December':
      m = '12';
      break;
    default:
      m = '';
      break;
  }
  return m;
};

export const getPeriodOptions = () => {
  const opt = [];
  const today = new Date();
  const startM = 1;
  const startY = 2020;
  const endM = today.getMonth() + 1;
  const endY = today.getFullYear();
  for (let i = startY; i <= endY; i += 1) {
    const s = i === startY ? startM : 1;
    const e = i === endY ? endM : 12;
    for (let j = s; j <= e; j += 1) {
      const m = month(`${j}`);
      opt.push(`${m} ${i}`);
    }
  }
  return opt.reverse();
};
