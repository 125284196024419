import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../../styles/theme';

import * as Styled from './styles';

type InputCalendarProps = {
  label?: string;
  required?: boolean;
  error?: string;
  value: string;
  width?: string;
  className?: string;
  onClick?: () => void;
  placeholder?: string;
  isDisabled?: boolean;
};

export const InputCalendar = ({
  label,
  required = false,
  error,
  value,
  width = '100%',
  className,
  onClick,
  placeholder,
  isDisabled = false,
}: InputCalendarProps) => (
  <Styled.WrapperStyled
    width={width}
    className={className}
  >
    {label && (
    <Styled.LabelStyled>
      {label}
      {' '}
      {required && <Styled.RequiredStyled>*</Styled.RequiredStyled>}
    </Styled.LabelStyled>
    )}
    <Styled.InputContainerStyled
      error={!!error}
      onClick={onClick}
      disabled={isDisabled}
    >
      <FontAwesomeIcon icon={faCalendarAlt} />
      <Styled.InputStyled>
        {value}
        {!value && placeholder && <Styled.PlaceholderStyled>{placeholder}</Styled.PlaceholderStyled>}
      </Styled.InputStyled>
      {error && (
      <FontAwesomeIcon
        icon={faTimes}
        style={{ color: colors.color14, paddingRight: '10px' }}
      />
      )}
    </Styled.InputContainerStyled>
    {error && (
    <Styled.ErrorStyled>{error}</Styled.ErrorStyled>
    )}
  </Styled.WrapperStyled>
);
