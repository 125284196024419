import { gql } from '@apollo/client';

export const LIST_CAMPAIGN_BY_PROGRAM = gql`
  query listCampaigns($input: ListCampaignInput) {
    listCampaigns(input: $input) {
      campaigns {
        id
        name
      }
    }
  }
`;
