import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import * as Styled from '../../styles';
import { CellProps } from '../../types';
import { PUBLISHER_PREFIX, path } from '../../../../../../../utils';
import { Button } from '../../../../../../../components';

export const ActionCell = ({ val }: CellProps) => {
  const navigate = useNavigate();

  const goTo = () => {
    navigate(`${PUBLISHER_PREFIX}${path.programDetails.href}?id=${val}`);
  };
  return (
    <Styled.CellWrapper>
      <Button
        theme="quaternary"
        onClick={goTo}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </Styled.CellWrapper>
  );
};
