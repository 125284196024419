import styled from 'styled-components';
import { colors, fonts } from '../../../../../../../../styles/theme';
import { Button } from '../../../../../../../../components';
import { toRem } from '../../../../../../../../utils';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${toRem(24)};
  width: 518px;
`;

export const TitleStyled = styled.h3`
  ${fonts.gotham.H24M}
  color: ${colors.color1};
`;

export const DescriptionStyled = styled.p`
  ${fonts.lato.H14}
  color: ${colors.color17};
  margin: 2rem 0;
`;

export const ButtonWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;
