export const STEPS = {
  ONE: 'Uploading multiple transactions is done using either a Fintel Connect .csv or .txt template. Please download either at right to get started.',
  TWO_ONE: 'Following the headings and order provided, carefully copy transaction data to the template file to the best of your ability, filling out as many columns as possible.',
  TWO_TWO: 'Note: Completing the first 5 columns is minimum required dataset for a bulk upload of transactions.',
  THREE_ONE: 'Once you’re satisfied with the data, save the file and upload it below.',
  THREE_TWO: 'After uploading, you will be asked to confirm the changes once more.',
};

export const SUCCESS_TEXT = {
  SUCCESSFUL_UPLOAD: 'Your file was successfully uploaded!',
  TOTAL_VALUE: 'Total value of uploaded transaction is:',
  GROSS_SALES: 'Gross Sales',
  NET_SALES: 'Net Sales',
  MATCH_RECORDS_ONE: 'If this value matches your record exactly, please click save below.',
  MATCH_RECORDS_TWO: 'If not, click back and resubmit a revised .csv or .txt file',
  NOTE_ONE: 'Note: Once you save these uploaded transactions,',
  NOTE_TWO: 'they cannot be changed in the future.',
};

export const ERROR_MESSAGE = {
  UPLOAD_ERROR: 'Something went wrong. Please make sure the formatting matches the template and try again.',
  MISSING_FILE: 'Please select file',
  MISSING_MERCHANT: 'Please select merchant',
  MISSING_TRANSACTIONS: 'Valid transaction data not found. Please check your csv/txt file and try it again',
  INVALID_DATE: 'Invalid Date',
  AWS_UPLOAD_ERROR: 'Something went wrong. Failed to upload file.',
};
