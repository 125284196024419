import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useActAsContext } from '../../../context';
import {
  useToast, useUserInfo,
} from '../../../hooks';
import {
  getCookie, USER_TYPES_ID, USER_TYPES_NAMES, PREFERED_LANGAUGE_OPTIONS, ERROR_TYPES, useValidation, TOAST_ERR_MESSAGES,
} from '../../../utils';
import { CREATE_USER, ASSIGN_USER } from '../graphql/mutations';
import { GET_SUBSCRIPTIONS } from '../graphql/queries/getSubscriptions';
import environment from '../../../config/environment';
import { UPDATE_USER } from '../graphql/mutations/updateUser';
import { UPDATE_SUBSCRIPTION } from '../graphql/mutations/updateUserSubscriptionStatus';
import {
  DEFAULT_VALIDATION_STATE, ERROR_MESSAGES, TOAST_MESSAGES,
} from '../contracts/enum';
import { GET_SECURITY_ROLES } from '../graphql/queries';
import type { GetSecurityRolesInput, GetSecurityRolesOutput, GSRRoleType } from '../graphql/queries';
import { CHECK_EMAIL } from '../../../pages/Publishers/SignUp/graphql';

type RoleOptionType = {
  label: string
  oldRoleId: number
  value: string
}

export const useManageUser = (userTypesId: number, closeFn: (update?: boolean) => void, merchantMemberships: any, hasWritePermission: boolean, userToEdit?: any) => {
  const vali = useValidation();
  const { hookShowToast } = useToast();
  const { actingAsMerchant, actingAsPublisher } = useActAsContext();
  const { hookWhoAmI } = useUserInfo();
  const editPermissions = hasWritePermission;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState<any>('');
  const [language, setLanguage] = useState<SelectOption>(PREFERED_LANGAUGE_OPTIONS[0]);
  const [position, setPosition] = useState('');
  const [userSubTypes, setUserSubTypes] = useState<RoleOptionType[]>();
  const [userSubTypeOptions, setUserSubTypesOptions] = useState<RoleOptionType[]>([]);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [memberships, setMemberships] = useState<any[]>([]);
  const [fintelChecked, setFintelChecked] = useState(false);
  const [successMessagePassword, setSucessMesage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState(DEFAULT_VALIDATION_STATE);

  const [getSubscriptions, { loading: getSubscriptionsLoading }] = useLazyQuery(GET_SUBSCRIPTIONS);
  const [getSecurityRoles, { loading: getSecurityRolesLoading }] = useLazyQuery<GetSecurityRolesOutput, GetSecurityRolesInput>(GET_SECURITY_ROLES);

  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER);
  const [assignUser, { loading: assignUserLoading }] = useMutation(ASSIGN_USER);
  const [updateSubscription, { loading: udpateSubscriptionLoading }] = useMutation(UPDATE_SUBSCRIPTION);
  const [createUser, { loading: createUserLoading }] = useMutation(CREATE_USER);
  const [checkEmail] = useLazyQuery(CHECK_EMAIL);

  const getSecurityRolesHandler = async (type: 'Admin' | 'Publisher' | 'Merchant') => {
    const { data } = await getSecurityRoles({
      variables: {
        type,
      },
      onError(error) {
        setErrorMessage(error.message);
      },
      fetchPolicy: 'no-cache',
    });

    if (data && data.newRolesByType) {
      setUserSubTypesOptions(data.newRolesByType.map((role: GSRRoleType) => ({ label: role.name, value: role.id, oldRoleId: role.oldId || 0 })));
    }
  };

  const getSubscriptionsHandler = async () => {
    const { data } = await getSubscriptions({
      variables: {
        id: userToEdit?.id,
      },
    });
    if (data?.user !== undefined) {
      setSubscriptions(data.user?.subscribeMerchantIds);
    }
  };

  const handleFintel = (can: boolean) => {
    if (can) setFintelChecked(!fintelChecked);
  };

  const forceResetPasswordHandler = async () => {
    const token = getCookie('id_token');
    const path = environment.api.adsBasePath;
    setErrorMessage('');
    fetch(`${path}/change_password/`, {
      method: 'POST',
      body: JSON.stringify({ email: userToEdit.email }),
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(() => {
      setSucessMesage('Reset Password Email Sent');
      setTimeout(() => {
        setSucessMesage('');
      }, 3000);
    }).catch((error: any) => {
      setErrorMessage(error.message);
    });
  };

  const inputValues: { [key: string]: string } = {
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    phone: phone || '',
    role: userSubTypes?.toString() || '',
  };

  const inputFields = {
    firstName: ERROR_TYPES.NAME,
    lastName: ERROR_TYPES.NAME,
    email: ERROR_TYPES.EMAIL,
    phone: ERROR_TYPES.PHONE_OPTIONAL,
    role: ERROR_TYPES.NOT_EMPTY,
  };

  const handleValidation = () => vali.validateAll(inputValues, inputFields, setValidationErrors, true);

  const setFirstNameHandler = (e: any) => {
    setFirstName(e.target.value);
  };

  const setLastNameHandler = (e: any) => {
    setLastName(e.target.value);
  };

  const setEmailHandler = (e: any) => {
    setEmail(e.target.value);
  };

  const setPhoneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e === undefined || e.toString() === '' ? '' : e.toString());
  };

  const setLanguageHandler = (select: SelectOption) => {
    setLanguage(select);
  };

  const setPositionHandler = (e: any) => {
    setPosition(e.target.value);
  };

  const setUserSubTypesHandler = (select: RoleOptionType[]) => setUserSubTypes(select);

  const updateUserHandler = async () => {
    const simpleinput = userToEdit.userType === USER_TYPES_NAMES.MERCHANT
      ? {
        id: userToEdit?.id,
        firstName,
        lastName,
        position,
        phone,
        auth0Id: userToEdit?.auth0Id,
        subscribeOffers: userToEdit.subscribeOffers,
      } : {
        id: userToEdit?.id,
        email,
        firstName,
        lastName,
        position,
        phone,
        preferredLanguage: language?.label,
        subscribeOffers: fintelChecked,
        auth0Id: userToEdit?.auth0Id,
      };
    const input = {
      ...simpleinput,
      roleId: (userSubTypes?.filter((role) => role.oldRoleId).map((role) => role.oldRoleId.toString())),
      newRoleIds: (userSubTypes?.map((userSecRole) => userSecRole.value)),
    };

    const data = await updateUser({
      variables: {
        input: simpleinput,
      },
    });
    if (data.data?.updateUser === undefined) {
      setErrorMessage('Failed to Edit User');
      return false;
    }

    const roleResult = await assignUser({
      variables: {
        input,
      },
    });

    // if (roleResult?.data?.assignUser === undefined) {
    //   hookShowToast(TOAST_MESSAGES.FAIL_USER_ASSIGN);
    // }
    hookShowToast(TOAST_MESSAGES.UPDATE_USER(email));

    return true;
  };

  const updateUserSubscriptionStatus = async () => {
    const { data } = await updateSubscription({
      variables: {
        input: memberships.map((membership: any) => ({ merchantId: membership.id, subscribe: subscriptions.includes(membership.id) })),
      },
    });
    if (data.updateUserSubscriptionStatus === undefined) {
      setErrorMessage('Failed to update subscriptions');
      return false;
    }
    return true;
  };

  const saveHandler = async (action: string) => {
    const passed = handleValidation();

    if (!passed) return;
    try {
      if (action === 'edit') {
        const updateSuccess = await updateUserHandler();
        const updateSubSucess = userToEdit.userType === USER_TYPES_NAMES.MERCHANT ? true : await updateUserSubscriptionStatus();
        if (updateSubSucess && updateSuccess) {
          closeFn(true);
        }
        return;
      }

      const userAlreadyExists = await checkEmail({
        variables: {
          email,
          checkInAuth0: true,
        },
      });

      if (userAlreadyExists.data?.checkEmailExists) {
        setValidationErrors({ ...validationErrors, email: 'Email already exists' });
        return;
      }

      const simpleinput: any = {
        firstName,
        lastName,
        email: email.toLowerCase(),
        details: {
          userSubTypesId: userSubTypes?.map((obj: SelectOption) => obj.value).includes('5') ? '5' : '6',
          userTypesId: userTypesId.toString(),
          firstUser: 'false',
        },
        position,
        preferredLanguage: language?.label || 'English',
        subscribeOffers: false,
        subscribeMerchantIds: [],
        phone,
        status: 'Active',
        companyId: hookWhoAmI.companyId?.toString(),
      };
      const input: any = {
        firstName,
        lastName,
        email: email.toLowerCase(),
        position,
        preferredLanguage: language?.label || 'English',
        subscribeOffers: false,
        subscribeMerchantIds: [],
        phone,
        status: 'Active',
        roleId: (userSubTypes?.filter((role) => role.oldRoleId).map((role) => role.oldRoleId.toString())),
        newRoleIds: (userSubTypes?.map((userSecRole) => userSecRole.value)),
        companyId: hookWhoAmI.companyId?.toString(),
      };

      switch (true) {
        case (userTypesId === USER_TYPES_ID.MERCHANT):
          simpleinput.userType = 'Merchant';
          input.userType = 'Merchant';
          break;
        case (userTypesId === USER_TYPES_ID.PUBLISHER):
          simpleinput.userType = 'Publisher';
          input.userType = 'Publisher';
          break;
        default:
          // Default case sets the user type to Error so that the user is not created
          simpleinput.userType = 'Error';
          input.userType = 'Error';
          break;
      }

      const { data, errors } = await createUser({
        variables: {
          input: simpleinput,
        },
      });
      if (errors) {
        hookShowToast(`Could not add new user. ${errors[0].message}`);
        return;
      }

      const toastMessages = [TOAST_MESSAGES.CREATE_USER(email)];

      if (data?.createUser !== undefined) {
        input.auth0Id = data.createUser.user.auth0Id;

        const roleResult = await assignUser({
          variables: {
            input,
          },
        });

        if (roleResult?.data?.assignUser === undefined) {
          toastMessages.push(TOAST_MESSAGES.FAIL_USER_ASSIGN);
          setErrorMessage(ERROR_MESSAGES.FAIL_USER_ASSIGN);
        }
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setPosition('');
        setLanguage({ label: 'English', value: 'English' });
        setUserSubTypes([]);
        closeFn(true);
        hookShowToast(toastMessages.join('; '));
      }
    } catch (e: any) {
      setErrorMessage(e);
    }
  };

  const handleChangeSubscriptions = (id: any, isSelf: any, admin: any) => {
    if (!isSelf || admin) return;
    if (subscriptions.includes(id)) {
      const newSubs = subscriptions.filter((obj: any) => obj !== id);
      setSubscriptions(newSubs);
    } else {
      const newSubs = [...subscriptions, id];
      setSubscriptions(newSubs);
    }
  };

  useEffect(() => {
    setMemberships(merchantMemberships || []);
  }, [merchantMemberships]);

  useEffect(() => {
    if (userToEdit && userToEdit !== undefined) {
      setFirstName(userToEdit?.firstName);
      setLastName(userToEdit?.lastName);
      setPhone(userToEdit?.phone);
      setEmail(userToEdit?.email);
      setLanguage({
        label: userToEdit?.lang || userToEdit?.preferredLanguage,
        value: userToEdit?.lang || userToEdit?.preferredLanguage,
      });
      const userRoles = (userToEdit?.newRoles || []).map((role: GSRRoleType) => ({ label: role.name, value: role.id, oldRoleId: role.oldId || 0 }));
      setUserSubTypes(userRoles);
      setFintelChecked(userToEdit?.subscriptionOffers);
      setPosition(userToEdit?.position);
      if (userToEdit?.subscribeMerchantIds) {
        setSubscriptions(userToEdit?.subscribeMerchantIds);
      } else {
        getSubscriptionsHandler();
      }
    }
  }, [userToEdit]);

  useEffect(() => {
    setValidationErrors(DEFAULT_VALIDATION_STATE);
    setErrorMessage('');
  }, [firstName, lastName, email, userSubTypes]);

  useEffect(() => {
    if (userTypesId === USER_TYPES_ID.MERCHANT) getSecurityRolesHandler('Merchant');
    else if (userTypesId === USER_TYPES_ID.PUBLISHER) getSecurityRolesHandler('Publisher');
  }, [userTypesId]);

  return {
    hookFirstName: firstName,
    hookLastName: lastName,
    hookEmail: email,
    hookPhone: phone,
    hookLanguage: language,
    hookSetFirstName: setFirstNameHandler,
    hookSetLastName: setLastNameHandler,
    hookSetEmail: setEmailHandler,
    hookSetPhone: setPhoneHandler,
    hookSetLanguage: setLanguageHandler,
    hookPosition: position,
    hookSetPosition: setPositionHandler,
    hookSave: saveHandler,
    hookUserSubTypes: userSubTypes,
    hookUserSubTypeOptions: userSubTypeOptions,
    hookSecurityRolesLoading: getSecurityRolesLoading,
    hookSetUserSubTypes: setUserSubTypesHandler,
    hookErrors: validationErrors,
    hookSubscriptions: subscriptions,
    hookMerchants: memberships,
    hookHandleChangeSubs: handleChangeSubscriptions,
    hookFintelChecked: fintelChecked,
    hookHandleChangeFintel: handleFintel,
    hookLoading: getSubscriptionsLoading,
    hookSuccessMessagePassword: successMessagePassword,
    hookResetPassword: forceResetPasswordHandler,
    hookEditLoading: udpateSubscriptionLoading || updateUserLoading,
    hookErrorMessage: errorMessage,
    hookAddUserLoading: createUserLoading,
    hookEditPermissions: editPermissions,
  };
};
