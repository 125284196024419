import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TabWrapperStyled, TabTitleStyled } from '../../pages/Publishers/ProgramDetails/ProductDetails/styles';
import { Table, Pagination } from '..';
import { columns } from './contracts/columns';
import { PRODUCT_DETAILS } from '../../pages/Publishers/ProgramDetails/ProductDetails/enums';
import { MERCHANT_PREFIX, path } from '../../utils';
import PlaceHolderNoResults from '../NoResultsPlaceHolder';

const CommissionsTab = (hook: any, editPermissions: boolean) => {
  const navigate = useNavigate();
  const onRowClick = (row: any) => {
    if (editPermissions) { navigate(`${MERCHANT_PREFIX}${path.editCommissions.href}`, { state: { val: row.id, goBack: -1 } }); }
  };
  return (
    <TabWrapperStyled>
      <TabTitleStyled>
        {PRODUCT_DETAILS.COMMISSIONS}
      </TabTitleStyled>

      {hook?.hookPaginatedCom?.length > 0 && (
      <>
        <Table
          columns={columns(editPermissions)}
          data={hook?.hookPaginatedCom}
          onRowClick={editPermissions ? onRowClick : () => (console.log(''))}
        />

        <Pagination
          currentPage={hook?.hookCurrentPageCom}
          total={hook?.hookTotalPageCom}
          onPageChange={hook?.hookChangePageCommissions}
        />
      </>
      )}
      {hook?.hookPaginatedCom?.length <= 0 && <PlaceHolderNoResults />}
    </TabWrapperStyled>
  );
};

export default CommissionsTab;
