import React from 'react';
import {
  Cell, CellCompany, CellDate, SelectPublisher,
} from '../components';

const columns: TableColumn[] = [
  {
    dataField: 'id',
    text: 'Id',
    width: '5%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <Cell value={value} />,
  },
  {
    dataField: 'companyName',
    text: 'Company Name',
    width: '18%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any, row: any) => (
      <CellCompany
        value={value}
        url={row?.primaryPlatformLink}
      />
    ),
  },
  {
    dataField: 'createdAt',
    text: 'Member Since',
    width: '10%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <CellDate value={value} />,
  },
  {
    dataField: 'audienceMarkets',
    text: 'Audience Markets',
    width: '16.5%',
    formatter: (value: any) => (
      <Cell
        compressed
        value={Array.isArray(value) ? value.join(', ') : ''}
      />
    ),
  },
  {
    dataField: 'productCategories',
    text: 'Product Categories',
    width: '16.5%',
    formatter: (value: any) => (
      <Cell
        compressed
        value={Array.isArray(value) ? value.join(', ') : ''}
      />
    ),
  },
  {
    dataField: 'promotionMethods',
    text: 'Promotion Method',
    width: '16.5%',
    formatter: (value: any) => (
      <Cell
        compressed
        value={Array.isArray(value) ? value.join(', ') : ''}
      />
    ),
  },
  {
    dataField: 'avgMonthlyAudience',
    text: 'Monthly Audience Size',
    width: '15%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <Cell value={value} />,
  },
  {
    dataField: 'audienceAges',
    text: 'Age',
    width: '12.5%',
    formatter: (value: any) => (
      <Cell
        compressed
        value={Array.isArray(value) ? value.join(' \n') : ''}
      />
    ),
  },
  {
    dataField: 'gender',
    text: 'Gender',
    width: '12.5%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <Cell value={value} />,
  },
  {
    dataField: 'avgIncomes',
    text: 'Average Income',
    width: '12.5%',
    formatter: (value: any) => (
      <Cell
        compressed
        value={Array.isArray(value) ? value.join('\n') : ''}
      />
    ),
  },
  {
    dataField: 'creditScoreRating',
    text: 'Credit Score Rating',
    width: '12.5%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <Cell value={value} />,
  },
  {
    dataField: 'address1',
    text: 'Address Info',
    width: '12.5%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any, row: any) => <Cell value={row?.address2 ? `${value} ${row.address2}` : value} />,
  },
  {
    dataField: 'country',
    text: 'Country',
    width: '12.5%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <Cell value={value} />,
  },
  {
    dataField: 'taxStatus',
    text: 'Tax Info',
    width: '12.5%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <Cell value={value} />,
  },
  {
    dataField: 'payment',
    text: 'Payment Method',
    width: '12.5%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <Cell value={value} />,
  },
  {
    dataField: 'currency',
    text: 'Payment Currency',
    width: '12.5%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <Cell value={value} />,
  },
  {
    dataField: 'lastLogin',
    text: 'Last Login',
    width: '10%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <CellDate value={value || 'Never'} />,
  },
  {
    dataField: 'accountStatus',
    text: 'Status',
    width: '10%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <Cell value={value === 'Integrating' || value === 'LivePending' ? 'Approved' : value} />,
  },
  {
    dataField: '_',
    text: 'Actions',
    width: '5%',
    formatter: (_: any, row: any) => <SelectPublisher value={row.id} />,
  },
];

export const reportColumns = (selectedColumns: any): TableColumn[] => {
  const columnsToReturn = columns.filter((column) => selectedColumns.includes(column.text));
  columnsToReturn.push(columns[columns.length - 1]);

  return columnsToReturn;
};

export const reportColumnsCSV = (selectedColumns: any): any[] => {
  const columnsToReturn = columns.map((column) => {
    if (selectedColumns.includes(column.text)) {
      return {
        dataField: column.dataField,
        text: column.text,
      };
    }

    return undefined;
  });

  const filteredColumns = columnsToReturn.filter((column) => column !== undefined);

  return filteredColumns;
};
