import styled from 'styled-components';
import { Button, Select } from '../../../components';
import { fonts, colors } from '../../../styles/theme';

type WrapperProps = {
  readonly isAdvance: boolean;
}

export const SelectStyled = styled(Select)`
  &:nth-of-type(2) {
    margin: 0 2%;
  }
`;

export const ButtonStyled = styled(Button)``;

export const AdvancedFiltersRowStyled = styled.div`
  display: flex;
`;

export const OptionsRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const WrapperStyled = styled.div<WrapperProps>`

  ${AdvancedFiltersRowStyled} {
    height: ${({ isAdvance }) => (isAdvance ? 'auto' : '0')};
    overflow: ${({ isAdvance }) => (isAdvance ? 'normal' : 'hidden')};
  }

  ${OptionsRowStyled} {
    margin-top: ${({ isAdvance }) => (isAdvance ? '2.5rem' : '0')};
  }
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const HeaderButtonStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const FiltersStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin-bottom: 1rem;
`;

export const AdvanceSelectionStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  grid-column: 3;
`;

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H14}
  gap: 16px;
  ${SelectStyled} {
    margin: 0 1rem;
  }
`;

export const RightStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TitleInfoBox = styled.span`
  background: ${colors.color15};
  ${fonts.gotham.H12M}
  padding: 0.5rem;
  margin-left: 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;

  span {
    margin-right: 0.5rem;
  }
`;
