import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { Button, Dropdown } from '../../../../../components';
import { useDropdown } from '../../../../../hooks';
import * as Styled from './styles';
import { BUTTON_LABEL } from './enum';

type CellProps = {
  children: React.ReactNode;
};

type DropdrowProps = {
  handleModel: (type: string) => void
  row: any
}

const RegularFormat = (value: any) => (
  <Styled.Regular>
    {value}
  </Styled.Regular>
);

const RoleArrayFormat = (value: any[]) => (
  <Styled.Regular>
    {value.map((role: {id: number, name: string, type: string}) => role.name).join(', ')}
  </Styled.Regular>
);

const FormatItems = (mainValue: any, secondaryValue: any) => (
  <Styled.CellWrapperStyled>
    <Styled.TextStyled theme="main">
      {mainValue}
    </Styled.TextStyled>
    <Styled.TextStyled>
      {secondaryValue}
    </Styled.TextStyled>
  </Styled.CellWrapperStyled>
);

const Cell = ({ children }: CellProps) => (
  <Styled.CellStyled>
    {children}
  </Styled.CellStyled>
);

const DropDown = ({ handleModel, row }: DropdrowProps) => {
  const dropdownHook = useDropdown();

  return (
    <Cell>
      <Dropdown
        isOpen={dropdownHook.isOpen}
        setIsOpen={() => dropdownHook.setIsOpen(!dropdownHook.isOpen)}
        dropdownRef={dropdownHook.dropdownRef}
        text=""
        iconClass={faEllipsis}
        leftIcon
      >
        <Styled.DropdownChildStyled>
          <Button
            onClick={() => {
              handleModel('edit');
              dropdownHook.setIsOpen(false);
            }}
            theme="quaternary"
            width="100%"
          >
            <Styled.ButtonStyled>
              <FontAwesomeIcon icon={faPen} />
              <p>
                {BUTTON_LABEL.EDIT}
              </p>
            </Styled.ButtonStyled>
          </Button>
          <Button
            onClick={() => {
              handleModel(row?.status);
              dropdownHook.setIsOpen(false);
            }}
            theme="quaternary"
            width="100%"
          >
            <Styled.ButtonStyled>
              <>
                <FontAwesomeIcon icon={faPowerOff} />
                <p>
                  {row?.status === 'Active' ? BUTTON_LABEL.DEACTIVATE : BUTTON_LABEL.ACTIVATE}
                </p>
              </>
            </Styled.ButtonStyled>
          </Button>
        </Styled.DropdownChildStyled>
      </Dropdown>
    </Cell>
  );
};

export const useColumns = (handleModel: (type: string, userId: string) => void, editPermissions: boolean, id: string) => {
  const columns: TableColumn[] = [
    {
      dataField: 'fullName',
      text: 'Full Name',
      width: '35%',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any, row: any) => (FormatItems(value, row.modalInfo.position)),
    },
    {
      dataField: 'securityRole',
      text: 'Security Role',
      width: '30%',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: RoleArrayFormat,
    },
    {
      dataField: 'status',
      text: 'Status',
      width: '15%',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: RegularFormat,
    },
    {
      dataField: 'lastLogin',
      text: 'Last Login',
      width: '15%',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => (
        RegularFormat(value || 'Never')
      ),
    },
    {
      dataField: editPermissions ? 'status' : '',
      text: '',
      width: '5%',
      cellOverflow: true,
      formatter: (_:any, row:any) => {
        if (editPermissions || (id === row.id)) {
          return (
            <DropDown
              handleModel={(action: string) => handleModel(action, row)}
              row={row}
            />
          );
        }
        return <div />;
      },
    },
  ];

  return {
    columns,
  };
};
