import React from 'react';
import * as Styled from '../styles';
import { CellProps } from '../types';

export const ScreenshotCell = ({ val }: CellProps) => (
  <Styled.CellStyled>
    <a
      href={val}
      target="_blank"
      rel="noreferrer"
    >
      Download All Screenshots
    </a>
  </Styled.CellStyled>
);
