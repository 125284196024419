import React from 'react';
import { TCProps } from '../types';
import * as Styled from '../styles';
import { Button } from '../../../../../../../components';

export const TCCell = ({ val, setOpenModal, setModalInfo }: TCProps) => (
  <Button
    theme="text-only"
    onClick={() => {
      setOpenModal();
      setModalInfo(val);
    }}
  >
    <Styled.TCCellStyled>
      Terms & Conditions
    </Styled.TCCellStyled>
  </Button>
);
