import { gql } from '@apollo/client';

export const CHECK_UNIQUE_RULE_NAME = gql`
  query CheckUniqueRuleName($input: CheckUniqueRuleNameInput!) {
    checkUniqueRuleName(input: $input) {
      ruleName
      unique
    }
  }
`;
