import { gql } from '@apollo/client';

export const CREATE_AD_TRACKER = gql`
  mutation CreateAdTracker($input: AdTrackerInput) {
    createAdTracker(input: $input) {
      adTracker {
        id
      }
    }
  }
`;
