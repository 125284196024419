import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import {
  AppWrapper, Button, InputPhone, InputText, PageTitle, Wysiwyg,
} from '../../../../components';
import { path, VISIBILITY_OPTIONS, STATS_UPDATE_FREQUENCY } from '../../../../utils';
import { useProgramSettings } from './hooks/useProgramSettings';
import { MERCHANT_PROGRAM_SETTINGS_PAGE } from './enums';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type ProgramSettingsProps = DefaultPropsType;

const ProgramSettings = ({ permissionsCodeList = [] }: ProgramSettingsProps) => {
  const { hookIsReadOnlyList, ...hook } = useProgramSettings(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  const navigate = useNavigate();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      {(!hook.hookEditTermsConditions && !hook.hookPreviewTermsConditions) && (
        <>
          <Styled.HeaderStyled>
            <PageTitle>
              {MERCHANT_PROGRAM_SETTINGS_PAGE.PAGE_TITLE}
            </PageTitle>
            <Styled.HeaderButtonsStyled>
              <Button
                onClick={() => navigate(path.merchantManagement.href)}
                theme="secondary"
              >
                {MERCHANT_PROGRAM_SETTINGS_PAGE.TITLE_CANCEL_BUTTON}
              </Button>

              <Button
                onClick={hook.hookUpdateProgram}
                disabled={isReadOnly}
              >
                {MERCHANT_PROGRAM_SETTINGS_PAGE.TITLE_SAVE_BUTTON}
              </Button>
            </Styled.HeaderButtonsStyled>
          </Styled.HeaderStyled>

          <Styled.NetworkSectionStyled>
            <Styled.SectionTitleStyled>{MERCHANT_PROGRAM_SETTINGS_PAGE.NETWORK.TITLE}</Styled.SectionTitleStyled>
            <Styled.SectionDescriptionStyled
              required
              name="descriptionHighLevel"
              label={MERCHANT_PROGRAM_SETTINGS_PAGE.NETWORK.DESCRIPTION_LABEL}
              value={hook.hookHighLevelDescription}
              onChange={hook.hookSetHighLevelDescription}
              error={hook.hookCreateUserErrors.descriptionHighLevel}
              onBlur={hook.hookHandleValidation}
              rows={5}
              disabled={isReadOnly}
            />

            <Styled.SectionTCStyled>
              <Styled.SectionTCTitleStyled>{MERCHANT_PROGRAM_SETTINGS_PAGE.NETWORK.TC_TITLE}</Styled.SectionTCTitleStyled>
              <Styled.SectionTCLastUpdateStyled>
                {MERCHANT_PROGRAM_SETTINGS_PAGE.NETWORK.TC_UPDATE}
                {' '}
                {hook.hookTermsAndConditionsUpdateTime}
              </Styled.SectionTCLastUpdateStyled>
              <Styled.SectionTCNoteStyled>
                {MERCHANT_PROGRAM_SETTINGS_PAGE.NETWORK.TC_NOTE}
                <Styled.SectionTCButtonStyled
                  theme="text-only"
                  onClick={() => hook.hookSetEditTermsConditionsHandler(!hook.hookEditTermsConditions)}
                >
                  {MERCHANT_PROGRAM_SETTINGS_PAGE.NETWORK.TC_LINK}
                </Styled.SectionTCButtonStyled>
              </Styled.SectionTCNoteStyled>
            </Styled.SectionTCStyled>

            <Styled.NetworkContentStyled>
              <Styled.SectionTextareaStyled
                name="signUpBonus"
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.NETWORK.SIGN_UP_LABEL}
                value={hook.hookSignUpBonus}
                onChange={hook.hookSetSignUpBonus}
                rows={5}
                disabled={isReadOnly}
              />
              <Styled.SectionSelectStyled
                required
                name="signupBonusVisibility"
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.NETWORK.SIGN_UP_VIS_LABEL}
                options={VISIBILITY_OPTIONS}
                onChange={hook.hookSetSignUpBonusVisibility}
                selected={hook.hookSignUpBonusVisibility}
                error={hook.hookCreateUserErrors.signupBonusVisibility}
                onBlur={hook.hookHandleValidation}
                isDisabled={isReadOnly}
              />
            </Styled.NetworkContentStyled>
          </Styled.NetworkSectionStyled>

          <Styled.MemberSectionStyled>
            <Styled.SectionTitleStyled>{MERCHANT_PROGRAM_SETTINGS_PAGE.USER_SETTINGS.TITLE}</Styled.SectionTitleStyled>
            <Styled.MemberContentStyled>
              <Styled.SectionDescriptionStyled
                required
                name="descriptionDetailed"
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.USER_SETTINGS.DESCRIPTION_LABEL}
                value={hook.hookDetailedDescription}
                onChange={hook.hookSetDetailedDescription}
                error={hook.hookCreateUserErrors.descriptionDetailed}
                onBlur={hook.hookHandleValidation}
                rows={5}
                disabled={isReadOnly}
              />
              <InputText
                required
                type="text"
                name="primaryContact"
                placeholder={MERCHANT_PROGRAM_SETTINGS_PAGE.PLACEHOLDERS.CONTACT_NAME}
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.USER_SETTINGS.PRIMARY_CONTACT_NAME}
                value={hook.hookProgramContactName}
                onChange={hook.hookSetProgramContactname}
                error={hook.hookCreateUserErrors.primaryContact}
                onBlur={hook.hookHandleValidation}
                disabled={isReadOnly}
              />
              <InputText
                required
                type="email"
                name="primaryContactEmail"
                placeholder={MERCHANT_PROGRAM_SETTINGS_PAGE.PLACEHOLDERS.CONTACT_EMAIL}
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.USER_SETTINGS.PRIMARY_CONTACT_EMAIL}
                value={hook.hookProgramContactEmail}
                onChange={hook.hookSetProgramContactEmail}
                error={hook.hookCreateUserErrors.primaryContactEmail}
                onBlur={hook.hookHandleValidation}
                disabled={isReadOnly}
              />
              <InputPhone
                required
                type="text"
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.USER_SETTINGS.PRIMARY_CONTACT_PHONE}
                name="primaryContactPhone"
                placeholder={MERCHANT_PROGRAM_SETTINGS_PAGE.PLACEHOLDERS.CONTACT_PHONE}
                value={hook.hookProgramContactPhone}
                onChange={hook.hookSetProgramContactPhone}
                error={hook.hookCreateUserErrors.primaryContactPhone}
                onBlur={hook.hookHandleValidation}
                disabled={isReadOnly}
              />
              <Styled.SectionTextareaStyled
                name="membersOffer"
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.USER_SETTINGS.SPECIAL_OFFER_LABEL}
                value={hook.hookMemberOffer}
                onChange={hook.hookSetMemberOffer}
                rows={5}
                disabled={isReadOnly}
              />
              <Styled.SectionSelectStyled
                required
                name="membersOfferVisibility"
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.USER_SETTINGS.SPECIAL_OFFER_VIS_LABEL}
                onChange={hook.hookSetMemberOfferVisibility}
                options={VISIBILITY_OPTIONS}
                selected={hook.hookMemberOfferVisibility}
                error={hook.hookCreateUserErrors.membersOfferVisibility}
                onBlur={hook.hookHandleValidation}
                isDisabled={isReadOnly}
              />
              <Styled.SectionInput2columnsStyled
                type="number"
                name="merchantCookieDuration"
                placeholder={MERCHANT_PROGRAM_SETTINGS_PAGE.PLACEHOLDERS.COOKIE_DURATION}
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.USER_SETTINGS.COOKIE_DUR_LABEL}
                value={hook.hookCookieDuration}
                onChange={hook.hookSetCookieDuration}
                disabled={isReadOnly}
              />
              <Styled.SectionSelectStyled
                required
                name="CookieDurationVisibility"
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.USER_SETTINGS.COOKIE_DUR_VIS_LABEL}
                onChange={hook.hookSetCookieDurationVisibility}
                options={VISIBILITY_OPTIONS}
                selected={hook.hookCookieDurationVisibility}
                error={hook.hookCreateUserErrors.CookieDurationVisibility}
                onBlur={hook.hookHandleValidation}
                isDisabled={isReadOnly}
              />
              <Styled.Section2ColumnSelectStyled
                name="merchatStatsUpdateFrequency"
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.USER_SETTINGS.STATS_UPDATE_LABEL}
                onChange={hook.hookSetUpdateFrequencyHander}
                options={STATS_UPDATE_FREQUENCY}
                selected={hook.hookUpdateFrequency}
                isDisabled={isReadOnly}
              />
              <Styled.SectionSelectStyled
                required
                name="statsFrequencyVisibility"
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.USER_SETTINGS.STATS_UPDATE_VIS_LABEL}
                onChange={hook.hookSetStatsFrequencyVisibility}
                options={VISIBILITY_OPTIONS}
                selected={hook.hookStatsFrequencyVisibility}
                error={hook.hookCreateUserErrors.statsFrequencyVisibility}
                onBlur={hook.hookHandleValidation}
                isDisabled={isReadOnly}
              />
              <Styled.SectionInput3columnsStyled
                required
                type="url"
                name="productMatrixUrl"
                placeholder={MERCHANT_PROGRAM_SETTINGS_PAGE.PLACEHOLDERS.MATRIX_URL}
                label={MERCHANT_PROGRAM_SETTINGS_PAGE.USER_SETTINGS.PRODUCT_URL_LABEL}
                value={hook.hookProductMatrixURL}
                onChange={hook.hookSetProductMatrixUrlHandler}
                error={!isReadOnly ? hook.hookUrlError || hook.hookCreateUserErrors.productMatrixUrl : ''}
                onBlur={() => {
                  hook.hookValidateWebsite();
                  hook.hookHandleValidation();
                }}
                disabled={isReadOnly}
              />
            </Styled.MemberContentStyled>
          </Styled.MemberSectionStyled>
        </>
      )}
      {hook.hookEditTermsConditions && (
      <>
        <Styled.HeaderStyled>
          <PageTitle>
            {MERCHANT_PROGRAM_SETTINGS_PAGE.UPDATE_TC.TITLE}
          </PageTitle>
          <Styled.HeaderButtonsStyled>
            <Button
              onClick={() => {
                hook.hookSetEditTermsConditionsHandler(false);
                hook.hookResetFields();
              }}
              theme="secondary"
            >
              {MERCHANT_PROGRAM_SETTINGS_PAGE.UPDATE_TC.CANCEL_BUTTON}
            </Button>

            <Button
              onClick={hook.hookPreviewPage}
            >
              {MERCHANT_PROGRAM_SETTINGS_PAGE.UPDATE_TC.PREVIEW_BUTTON}
            </Button>
          </Styled.HeaderButtonsStyled>
        </Styled.HeaderStyled>
        <Styled.TCInfoSectionStyled>
          <Styled.TCInfoBlockStyled>
            <Styled.TCInfoBlockTitleStyled>
              {MERCHANT_PROGRAM_SETTINGS_PAGE.UPDATE_TC.TC_UPDATE_TIME}
            </Styled.TCInfoBlockTitleStyled>
            <Styled.TCInfoBlockInfoStyled>
              {hook.hookTermsAndConditionsUpdateTime}
            </Styled.TCInfoBlockInfoStyled>
          </Styled.TCInfoBlockStyled>
          <Styled.TCInfoBlockStyled>
            <Styled.TCInfoBlockTitleStyled>
              {MERCHANT_PROGRAM_SETTINGS_PAGE.UPDATE_TC.TC_UPDATE_BY}
            </Styled.TCInfoBlockTitleStyled>
            <Styled.TCInfoBlockInfoStyled>
              {hook.hookTermsAndConditionsUpdateBy}
            </Styled.TCInfoBlockInfoStyled>
          </Styled.TCInfoBlockStyled>
        </Styled.TCInfoSectionStyled>

        <Styled.TCEditTitleStyled>
          <InputText
            required
            type="text"
            name="termsConditionsTitle"
            label={MERCHANT_PROGRAM_SETTINGS_PAGE.UPDATE_TC.TC_TITLE_LABEL}
            value={hook.hookTermsConditionsTitle}
            onChange={hook.hookTermsConditionsTitleHandler}
            error={hook.hookTitleError}
            disabled={isReadOnly}
          />
        </Styled.TCEditTitleStyled>

        <Wysiwyg
          label={MERCHANT_PROGRAM_SETTINGS_PAGE.UPDATE_TC.TC_DATA_LABEL}
          value={hook.hookTermsConditions}
          required
          onChange={hook.hookSetTermsConditionsHandler}
          placeholder="Enter your Terms and Conditions Details"
          readonly={isReadOnly}
        />
      </>
      )}

      {hook.hookPreviewTermsConditions && (
      <>
        <Styled.HeaderStyled>
          <PageTitle>
            {MERCHANT_PROGRAM_SETTINGS_PAGE.UPDATE_TC.TITLE}
          </PageTitle>
          <Styled.HeaderButtonsStyled>
            <Button
              onClick={() => {
                hook.hookSetEditTermsConditionsHandler(true);
                hook.hookSetPreviewTermsConditionsHandler(false);
              }}
              theme="secondary"
            >
              {MERCHANT_PROGRAM_SETTINGS_PAGE.UPDATE_TC.BACK_BUTTON}
            </Button>
            <Button
              onClick={() => hook.hookUpdateProgramTermsConditionsHandler()}
              disabled={isReadOnly}
            >
              {MERCHANT_PROGRAM_SETTINGS_PAGE.UPDATE_TC.SAVE_BUTTON}
            </Button>
          </Styled.HeaderButtonsStyled>
        </Styled.HeaderStyled>
        <Styled.TCInfoSectionStyled>
          <Styled.TCInfoBlockStyled>
            <Styled.TCInfoBlockTitleStyled>
              {MERCHANT_PROGRAM_SETTINGS_PAGE.UPDATE_TC.TC_UPDATE_TIME}
            </Styled.TCInfoBlockTitleStyled>
            <Styled.TCInfoBlockInfoStyled>
              {hook.hookTermsAndConditionsUpdateTime}
            </Styled.TCInfoBlockInfoStyled>
          </Styled.TCInfoBlockStyled>
          <Styled.TCInfoBlockStyled>
            <Styled.TCInfoBlockTitleStyled>
              {MERCHANT_PROGRAM_SETTINGS_PAGE.UPDATE_TC.TC_UPDATE_BY}
            </Styled.TCInfoBlockTitleStyled>
            <Styled.TCInfoBlockInfoStyled>
              {hook.hookTermsAndConditionsUpdateBy}
            </Styled.TCInfoBlockInfoStyled>
          </Styled.TCInfoBlockStyled>
        </Styled.TCInfoSectionStyled>

        <Styled.TCPreviewBoxStyled>
          <Styled.TCPreviewTitleStyled>
            {hook.hookTermsConditionsTitle}
          </Styled.TCPreviewTitleStyled>
          <Styled.TCPreviewContentStyled>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {hook.hookTermsConditions}
            </ReactMarkdown>
          </Styled.TCPreviewContentStyled>
        </Styled.TCPreviewBoxStyled>
      </>
      )}
    </AppWrapper>
  );
};
export default ProgramSettings;
