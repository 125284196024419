import { useState } from 'react';
import environment from '../config/environment';
import { getCookie } from '../utils';

export const useUpload = () => {
  const [destination, setDestination] = useState(environment.api.adsBasePath);

  const setDestinationHandler = (value: string) => setDestination(value);

  const uploadImageFile = async (file: File) => {
    const formData = new FormData();
    const token = getCookie('id_token');

    formData.append('file', file);

    // old APP & API are using the same route / AWS bucket to store company avatars and banner ads
    // we are not refactoring that flow for the sake of time right now
    const response = await fetch(`${destination}/uploads/avatars`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data;
  };

  const uploadImageFileNoAuth = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    // This is an endpoint that lets you upload without any authentication
    const response = await fetch(`${destination}/images/avatars`, {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();
    return data;
  };

  const uploadZipFile = async (file: File, adId: any) => {
    const formData = new FormData();
    const token = getCookie('id_token');

    formData.append('file', file);
    formData.append('adId', adId);

    const response = await fetch(`${destination}/uploads/adfiles`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        adId,
      },
    });

    const data = await response.json();
    return data;
  };

  const uploadDocument = async (file: File, merchantId: any) => {
    const formData = new FormData();
    const token = getCookie('id_token');

    formData.append('file', file);
    formData.append('merchantId', merchantId);

    const response = await fetch(`${destination}/uploads/documents`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        merchantId,
      },
    });

    const data = await response.json();
    return data;
  };

  return {
    hookDestination: destination,
    hookSetDestination: setDestinationHandler,
    hookUploadImageFile: uploadImageFile,
    hookUploadImageFileNoAuth: uploadImageFileNoAuth,
    hookUploadZipFile: uploadZipFile,
    hookUploadDocument: uploadDocument,
  };
};
