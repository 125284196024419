import { gql } from '@apollo/client';

export const LIST_SEARCH_PUBLISHER = gql`
  query companies($input: ListSearchCompanyInput) {
    listSearchPublishers(input: $input) {
      companies {
        id
        companyName
        createdAt
        accountStatus
        address1
        address2
        country
        activityStatus
        primaryPlatformLink
        audienceMarkets
        avgMonthlyAudience
        productCategories
        promotionMethods
        audienceAges
        gender
        avgIncomes
        creditScoreRating
        taxStatus
        payment
        currency
        lastLogin
      }
      count
    }
  }
`;
