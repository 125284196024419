export const REQUIRED_TEXT = (create?: boolean) => `Oops! Something went wrong for ${create ? 'create' : 'edit'} campaign! Please update inputs and try it again.`;
export const ASSOCIATED_ADS = 'Associated Ads';

export const NAMES = {
  CAMPAIGN_NAME: 'campaignName',
  STATUS: 'status',
  DESCRIPTION: 'description',
  TAGS: 'tags',
  PRODUCTS: 'products',
  DEFAULT_URL: 'landingUrl',
  LANGUAGE: 'language',
  THIRD_PARTY_URL: 'trackerUrl',
};

export const LABELS = {
  CAMPAIGN_NAME: 'Campaign Name',
  STATUS: 'Status',
  DESCRIPTION: 'Description',
  START_DATE: 'Campaign Start Date',
  END_DATE: 'Campaign End date',
  TAGS: 'Tags',
  PRODUCT: 'Product',
  DEFAULT_URL: 'Default Landing Page Url',
  LANGUAGE: 'Language',
  THIRD_PARTY_URL: '3rd Party Tracker Url',
  PUBLISHER_GROUPS: 'PUBLISHER GROUPS',
};

export const PLACEHOLDERS = {
  STATUS: 'Select Status',
  DATE: 'Select Date',
  TAGS: 'Select Tags',
  PRODUCT: 'Select Product',
  LANGUAGE: 'Select Language',
};

export const BUTTON_TEXT = {
  CANCEL: 'Cancel',
  CREATE: 'Create Ad Campaign',
  BACK: 'Back',
  SAVE: 'Save',
};
