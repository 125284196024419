import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../../../../../styles/theme';

type PlaceholderProps = {
  readonly show: boolean
}

export const PlaceholdersStyled = styled.div<PlaceholderProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  transition: all 0.5s ease;
`;

export const CheckBackSoonStyled = styled.div`
  width: 100%;
  color: ${colors.color1};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 3rem 0;
  gap: 16px;

  h3 {
    ${fonts.gotham.H20M}
  }

  p {
    ${fonts.lato.H16R}
  }
`;

export const BlockWrapperStyled = styled.div`
  background-color: ${colors.color4};
  padding: 1rem;
  margin: 2rem 0;
`;

export const GraphWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Placeholder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  color: ${colors.color19};
  margin: 2rem;

  img {
    padding: 0;
    width: 500px;
    max-width: 550px;
  }
`;

export const PlaceholderTitleWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
`;

export const PlaceholderTitleStyled = styled.h3`
  ${fonts.gotham.H20M}
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
`;

export const DividerStyled = styled.div`
  content: '';
  border-left: 1px solid ${colors.color19};
  align-self: stretch;
`;

export const PlaceholderDonwload = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const IconPlaceholder = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

export const ButtonContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
