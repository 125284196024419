import styled from 'styled-components';
import { Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

type FlexStyledProps = {
  readonly justifyContent?: string
}

export const DescriptionStyled = styled.div`
  ${fonts.gotham.H22}
  color: ${colors.color1};
  margin: 2rem 0;
`;

export const SelectStyled = styled(Select)`
`;

export const FlexStyled = styled.div<FlexStyledProps>`
  display: flex;
  ${fonts.lato.H14}
  align-items: center;
  justify-content: ${({ justifyContent }) => (justifyContent || 'space-between')};
  margin-top: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const SearchWrapperStyled = styled.div`
  margin: 3rem 0;
`;
