import { gql } from '@apollo/client';

export const LIST_AD_TYPES = gql`
  query getAdTypes {
    getAdTypes {
      adTypes {
        type
      }
    }
  }
`;
