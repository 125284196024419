import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faChevronRight, faFile } from '@fortawesome/free-solid-svg-icons';
import { INVITATION_HISTORY } from '../enum';

import * as Styled from '../styles';
import { MERCHANT_PREFIX, path } from '../../../../../utils';

type NavigateCellProps = {
  membershipId: number
  publisherId: string
}

const NavigateCell = ({ publisherId, membershipId }: NavigateCellProps) => {
  const navigate = useNavigate();

  const goTo = () => {
    const urlPath = `${MERCHANT_PREFIX}${path.membershipDetails.href}`;
    navigate(urlPath, {
      state: {
        publisherId,
        membershipId,
      },
    });
  };

  return (
    <Styled.CellStyled>
      <Styled.IconStyled
        icon={faChevronRight}
        onClick={goTo}
      />
    </Styled.CellStyled>
  );
};

export const columns: TableColumn[] = [
  {
    dataField: 'id',
    text: INVITATION_HISTORY.COLUMN.ID,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '15%',
    formatter: (val) => (
      <Styled.CellStyled>
        {val}
      </Styled.CellStyled>
    ),
  },
  {
    dataField: 'companyName',
    text: INVITATION_HISTORY.COLUMN.COMPANY,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '35%',
    formatter: (cell: any, row: any) => (
      <Styled.CellStyled>
        {cell}
        {row.url && (
        <Styled.LinkStyled href={row.url}>
          {row.url}
        </Styled.LinkStyled>
        )}
      </Styled.CellStyled>
    ),
  },
  {
    dataField: '',
    text: INVITATION_HISTORY.COLUMN.MESSAGE,
    width: '15%',
    formatter: (_, row, ___, ____, _____, setModal: () => void, setModalInfo) => (
      <Styled.CellStyled>
        <Styled.IconStyled
          icon={faFile}
          onClick={() => {
            setModal();
            setModalInfo(row);
          }}
        />
      </Styled.CellStyled>
    ),
  },
  {
    dataField: 'invitation',
    text: INVITATION_HISTORY.COLUMN.INVITATION_SENT,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '15%',
    formatter: (val) => (
      <Styled.CellStyled>
        {val}
      </Styled.CellStyled>
    ),
  },
  {
    dataField: 'status',
    text: INVITATION_HISTORY.COLUMN.STATUS,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '15%',
    formatter: (val) => (
      <Styled.CellStyled>
        {val}
      </Styled.CellStyled>
    ),
  },
  {
    dataField: '',
    text: '',
    formatter: (_, row) => (
      <NavigateCell
        membershipId={row.id}
        publisherId={row.publisherId}
      />
    ),
  },
];
