import styled from 'styled-components';
import { InputText } from '../../../../../../components';
import { toRem } from '../../../../../../utils';

export const WrapperStyled = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  width: 700px;
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: fit-content;
  justify-self: end;
`;

export const ButtonWrapper = styled.div`
  margin-right: ${toRem(16)};
`;

export const InputTextStyled = styled(InputText)`
  grid-column: 1 / -1;
`;

export const ErrorWrapperStyled = styled.div`
  grid-column: 1 / -1;
`;
