export const SEND_PUBLISHER_INVITATION = {
  PAGE_TITLE: 'Send Publisher Invitation',
  APPLY_INVITATION_TEMPLATE: 'Apply Invitation Template',
  SUBJECT: {
    LABEL: 'Subject',
    PLACEHOLDER: 'Type a subject',
  },
  PUBLISHER_NAME: {
    LABEL: 'Publisher Name',
    PLACEHOLDER: 'Publisher Name',
  },
  MERGE_FIELDS: {
    LABEL: 'Merge Fields',
    PLACEHOLDER: 'Merge Fields',
    OPTIONS: [
      {
        label: 'Merchant Company Name {{merchant}}',
        value: '{{merchant}}',
      },
      {
        label: 'Merchant Program Link {{merchantlink}}',
        value: '{{merchantlink}}',
      },
      {
        label: 'Publisher Company Name {{publisher}}',
        value: '{{publisher}}',
      },
      {
        label: 'Merchant Product Categories {{merchantproduct}}',
        value: '{{merchantproduct}}',
      },
    ],
    TOOLTIP: 'A merge field is a placeholder in a message or template. Paste the merge field in the Subject or Message body where you want the placeholder to be used, and it will be replaced with your merchant information or the publishers details on send of the message.',
    COPIED: 'Merge Field copied to clipboard!',
  },
  MESSAGE: {
    LABEL: 'Message',
    PLACEHOLDER: 'Enter your message',
  },
  BUTTON: {
    CANCEL: 'Cancel',
    SEND: 'Send',
  },
};

export const DEFAULT_MERGE_FIELD = {
  label: 'Merge Fields',
  value: 'Mege Fields',
};

export const DEFAULT_TEMPLATE_OPTIONS = {
  label: 'Apply invitation template',
  value: "<p>Hey there,</p><p><br></p><p>We'd like to extend a personal invite for you to join the {{merchant}} affiliate program. We offer products a variety of products and see a natural fit with the content produced on your platform. We are happy to provide examples of how we see the partnership working, just ask us!</p><p><br></p><p>Please visit our program page {{merchantlink}} to review more information about our program and if you're ready to join you can approve the request directly here {{merchantlink}}.</p><p><br></p><p>Thanks,</p><p>{{merchant}} Affiliate Team</p>",
};

export const DEFAULT_SUBJECT = 'Your personal invite to the {{merchant}} program!';

export const formatDomain = (suffix: any) => {
  let domain = '';
  switch (process.env.ENVIRONMENT) {
    case 'localDev':
      domain = `dev${suffix}`;
      break;
    case 'dev':
    case 'staging':
    case 'demo':
      domain = `${process.env.ENVIRONMENT}${suffix}`;
      break;
    // prod
    default:
      break;
  }
  return domain;
};

export const USER_TYPES_ID: { [key: string]: number } = {
  ADMIN: 1,
  MERCHANT: 2,
  PUBLISHER: 3,
};
