import React from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, PageTitle, VisibilityHidden } from '../../../../../components';
import * as Styled from './styles';
import { GROUP_BY_OPTIONS, PERFORMANCE_REPORT_CUSTOMIZE_COLUMNS, defaultCheckUncheckAllLabel } from '../../enums';
import { getColumnsByGroupBy } from '../../utils';
import { PerformanceGroupBy } from '../../types';

type CustomizeColumnsProps = {
  closeFn: () => void;
  checkFn: (column: SelectOption, checked: boolean) => void;
  checkAllColumnsFn: (checked: boolean) => void
  columns: SelectOption[];
  reportColumns: TableColumn[];
};

const CustomizeColumnsModal = ({
  closeFn,
  checkFn,
  checkAllColumnsFn,
  columns,
  reportColumns,
}: CustomizeColumnsProps) => (
  <Styled.WrapperStyled>
    <Styled.HeaderStyled>
      <PageTitle>Customize Columns</PageTitle>

      <Styled.ButtonStyled onClick={closeFn}>
        <FontAwesomeIcon icon={faClose} />
        <VisibilityHidden>Close</VisibilityHidden>
      </Styled.ButtonStyled>
    </Styled.HeaderStyled>

    <Styled.CheckboxContentStyled>
      <Styled.CheckboxRowStyled>
        <Checkbox
          checked={getColumnsByGroupBy(GROUP_BY_OPTIONS[0] as PerformanceGroupBy).length === reportColumns.length}
          onChange={(checked) => checkAllColumnsFn(checked)}
          label={defaultCheckUncheckAllLabel.label}
        />
      </Styled.CheckboxRowStyled>
    </Styled.CheckboxContentStyled>

    <Styled.ContentStyled>
      {PERFORMANCE_REPORT_CUSTOMIZE_COLUMNS.map((column) => (
        <Styled.ColumnStyled key={column.label}>
          <Styled.ColumnLabelStyled>{column.label}</Styled.ColumnLabelStyled>

          {column.columns.map((row) => (
            <Styled.RowStyled key={row.value}>
              <Checkbox
                checked={columns.some((col) => col.value === row.value)}
                onChange={() => checkFn(row, !columns.some((col) => col.value === row.value))}
                label={row.label}
              />
            </Styled.RowStyled>
          ))}
        </Styled.ColumnStyled>
      ))}
    </Styled.ContentStyled>
  </Styled.WrapperStyled>
);

export default CustomizeColumnsModal;
