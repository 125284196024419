export const SETTINGS_CHECK = {
  DISABLED: {
    TITLE: 'Enable Fintel Check',
    INSTRUCTION: 'Click the toggle switch on the right top corner to enable Fintel Check services.',
    DESCRIPTION: 'Checks Settings options will appear here.',
  },

  FREQUENCY: {
    TITLE: 'Set Frequency',
    DESCRIPTION: 'Choose how often Fintel Check performs scans of campaigns.',
    WEEKLY: 'Weekly',
    BIWEEKLY: 'Biweekly',
    MONTHLY: 'Monthly',
  },

  SCAN: {
    TITLE: 'Set Preferred Day for Scans',
    DESCRIPTION: 'Select the day of week Fintel Check performs its scans.',
    NEXT_REPORT: 'The next report will be on',
    OPTIONS: [
      { label: 'Sunday', value: '6' },
      { label: 'Monday', value: '0' },
      { label: 'Tuesday', value: '1' },
      { label: 'Wednesday', value: '2' },
      { label: 'Thursday', value: '3' },
      { label: 'Friday', value: '4' },
      { label: 'Saturday', value: '5' },
    ],
  },

  NON_APPLICABLE: {
    TITLE: 'Set Frequency for “Non-applicable” URLs',
    DESCRIPTION: 'Choose how long the system will wait before Fintel Check performs new scans of “non-applicable” URLs.',
    OPTIONS: [
      {
        label: 'Days',
        value: 'Days',
      },
      {
        label: 'Weeks',
        value: 'Weeks',
      },
    ],
  },

  PUBLISHER: {
    TITLE: 'Set Publisher Scan Settings',
    DESCRIPTION: 'Choose the Publishers that Fintel Check will scan',
    ALL: 'All',
    LIST: 'Set up a list of publishers',
    EDIT: 'Edit list',
    SELECTED: 'publishers selected',
  },

  URL_TYPES: {
    TITLE: 'Set Tracking URL Types',
    DESCRIPTION: 'Choose the type of URLs for Fintel Check to scan.',
    RELEVANT: 'URLs with Relevant Tracking',
    MANUAL: 'Manually Entered URLs',
    BOTH: 'Both',
    EDIT: 'Edit Manual URLs List',
  },

  URL_PREFERENCES: {
    TITLE: 'Set Tracking URL Preferences',
    DESCRIPTION: 'Choose which publisher tracking URLs for Fintel Check to scan',
    ALL: 'Check all URLs',
    SPECIFIED: 'Only check URLs with activity within a specified timeframe',
    TIMEFRAME: 'Specify here the timeframe:',
    LAST: 'Last',
    OPTIONS: {
      WEEKLY: [
        {
          label: 'Days',
          value: 'Days',
        },
      ],
      BIWEEKLY: [
        {
          label: 'Days',
          value: 'Days',
        },
        {
          label: 'Weeks',
          value: 'Weeks',
        },
      ],
      MONTHLY: [
        {
          label: 'Days',
          value: 'Days',
        },
        {
          label: 'Weeks',
          value: 'Weeks',
        },
      ],
    },
  },

  BLACKLIST: {
    TITLE: 'Set Blacklist Preferences',
    DESCRIPTION: 'Modify the list of domains to be ignored during report runs.',
    EDIT: 'Edit Blacklist',
  },

  FOOTER: {
    SAVE: 'Update Settings',
  },

  TOAST: {
    ENABLE: 'Fintel Check enabled',
    DISABLE: 'Fintel Check disabled',
    SUCCESS: 'Fintel Check Settings successfully updated',
    ERROR: 'Error updating Fintel Check Settings',
  },
};
