import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../../../../../../../styles/theme';

type CellProps = {
  readonly isId?: boolean
}

export const CellWrapper = styled.div<CellProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ isId }) => (isId ? 'flex-start' : 'center')};
  gap: 1rem;
  ${fonts.lato.H16}
  color: ${colors.color1};
`;

export const MainValStyled = styled.p`
  ${fonts.lato.H16R}
`;

export const SecondaryValStyled = styled.p`
  color: ${colors.color35};
`;

export const IconStyled = styled(FontAwesomeIcon)`
  color: ${colors.color1};
`;
