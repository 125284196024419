import { gql } from '@apollo/client';

export const GET_BULK_IMPORT_LOG_DETAILS = gql`
  query BulkUploadTransactionData($bulkUploadTransactionDataId: ID!) {
    bulkUploadTransactionData(id: $bulkUploadTransactionDataId) {
      processedTransactions {
        message
        status
      }
      uploadErrors {
        message
        status
      }
      count
    }
  }
`;
