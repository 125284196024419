import styled from 'styled-components';
import { colors } from '../../../styles/theme';
import { toRem } from '../../../utils';
import 'react-phone-number-input/style.css';

export const TopContainerStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TopContainerButtonsStyled = styled.div`
  display: flex;
  button {
    margin-left: ${toRem(16)};;
  }
`;

export const DetailsStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  column-gap: ${toRem(24)};
  row-gap: ${toRem(32)};
  padding: ${toRem(32)} 0;
  border-bottom: 1px solid ${colors.color15};
`;

export const AcceptOffersGridBoxStyled = styled.div`
  grid-row: 4;
  grid-column: 1 / 4;
`;

export const AcceptOffersBoxStyled = styled.div`
  width: fit-content;
`;
