import React from 'react';
import * as Styled from './styles';

type LinkProps = {
  children: React.ReactNode;
  to: string;
  className?: string;
  target?: string
}

export const Link = ({
  children, to, className, target,
}: LinkProps) => (
  <Styled.WrapperStyled
    to={to}
    className={className}
    target={target}
  >
    {children}
  </Styled.WrapperStyled>
);
