import { gql } from '@apollo/client';

export const GET_PAYPAL_ORDERS = gql`query Query($input: PaypalOrderSearchInputV2!) {
    paypalOrdersV2(input: $input) {
      orders {
        amount
        currency
        paymentId
        notes
        createdAt
        updatedAt
        status
      }
      count
    }
  }
`;
