import styled from 'styled-components';
import {
  Button, InfoBox, InputText, Select, Checkbox, InputPhone,
} from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

type GridProps = {
  readonly grids: number;
}

type InputWrapperStyledProps = {
  readonly grids: number;
}

type FlexProps = {
  readonly justify: 'space-between' | 'flex-start' | 'flex-end' | 'center' | 'space-around';
}

type ApiCaptionProps = {
  color: string
}

type SectionStyledProps = {
  readonly noMarginTop?: boolean
}

export const StyledCheckBox = styled(Checkbox)`
  grid-column: span 4;
`;

export const InfoBoxCurrency = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(8)};
  color: ${colors.color7};
  grid-column: span 2;
  padding-top: ${toRem(16)};
`;

export const ApiDesc = styled.p`
  ${fonts.lato.H16};
  line-height: 1.5;
  color: ${colors.color1};
  margin-bottom: ${toRem(24)};
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderButtonAreaStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${toRem(40)};
`;

export const HeaderButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const ContentStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: ${toRem(40)};
  margin-top: ${toRem(24)};
  grid-column: 1/7;
`;

export const InputTextStyled = styled(InputText)<GridProps>`
  grid-column: span ${({ grids }) => grids};
`;

export const InputPhoneStyled = styled(InputPhone)<GridProps>`
  grid-column: span ${({ grids }) => grids};
`;

export const SelectStyled = styled(Select)<GridProps>`
  grid-column: span ${({ grids }) => grids};
`;

export const SectionStyled = styled.div<SectionStyledProps>`
  width: 100%;
  border-top: ${({ noMarginTop }) => (noMarginTop ? '' : ` 1px solid ${colors.color3};`)};
  margin-top: ${({ noMarginTop }) => (noMarginTop ? '' : '3rem')};
  padding-top: ${({ noMarginTop }) => (noMarginTop ? '' : '3rem')};
`;

export const SectionTitleStyled = styled.div`
  grid-column: span 6;
  color: ${colors.color1};
  ${fonts.gotham.H22}
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InfoBoxStyled = styled(InfoBox)`
  grid-column: span 6;
`;

export const GridBoxStyled = styled.div<GridProps & FlexProps>`
  grid-column: span ${({ grids }) => grids};
  display: flex;
  justify-content: ${({ justify }) => justify};
`;

export const ApiCaption = styled.div<ApiCaptionProps>`
  color: ${({ color }) => color}; 
  grid-column: span 2;
  ${fonts.lato.H16};
  min-width: 200px;
  margin-top: -40px;
`;

export const FilterStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const InputWrapperStyled = styled.div<InputWrapperStyledProps>`
  display: flex;
  grid-column: span ${({ grids }) => grids};
`;

export const IconStyledStyled = styled.span`
  margin-right: ${toRem(8)};
`;

export const LinkStyled = styled.a`
  color: ${colors.color2}
`;
