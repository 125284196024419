import React, { useEffect } from 'react';
import { useTabs } from './hooks';
import * as Styled from './styles';

type TabsProps = {
  tabNames: string[];
  elements: React.ReactNode[];
  className?: string;
  activeTab?: number;
  isModalOpen?: boolean;
  hookOveride?: {
    activeTabOveride: any,
    setActiveTab: any,
  };
}

export const Tabs = ({
  tabNames, elements, className, activeTab, isModalOpen, hookOveride,
}: TabsProps) => {
  const { hookActiveTab, hookSetActiveTab } = useTabs(activeTab, hookOveride?.setActiveTab, hookOveride?.activeTabOveride);

  useEffect(() => {
    if (isModalOpen) hookSetActiveTab(0);
  }, [isModalOpen]);

  const tabRender = () => tabNames.map((tab, index) => (
    <Styled.ButtonStyled
      key={index}
      isActive={index === (hookOveride?.activeTabOveride ?? hookActiveTab)}
      onClick={() => hookSetActiveTab(index)}
    >
      {tab}
    </Styled.ButtonStyled>
  ));

  return (
    <Styled.WrapperStyled className={className}>
      <Styled.TopAreaStyled>
        {tabRender()}
      </Styled.TopAreaStyled>

      <Styled.ContentStyled>{elements[hookOveride?.activeTabOveride ?? hookActiveTab]}</Styled.ContentStyled>
    </Styled.WrapperStyled>
  );
};
