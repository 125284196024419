import { gql } from '@apollo/client';

export const FINALIZE_ACCOUNNT_BALANCE_BY_ID = gql`
  mutation updateAccountBalance($input: AccountBalanceInput!) {
    updateAccountBalance(input: $input) {
      accountBalance {
        id
      }
    }
  }
`;
