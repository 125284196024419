import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { EditFilterProps } from '../../reducers';
import * as Styled from '../../styles';
import {
  Calendar, InputCalendar, InputTextRef, Select,
} from '../../../../../components';
import { EDIT_REPORT } from '../../enums';
import { GROUP_BY_OPTIONS, USER_TYPES_ID } from '../../../../../utils';
import type { useEditReport } from '../../hooks';

type FilterSettingsProps = {
  state: EditFilterProps
  dispatcher: React.Dispatch<ComponentReducerType<Record<string, any>>>
  lists: Record<string, SelectOption[]>
  loading: boolean
  hook: ReturnType<typeof useEditReport>
  isTransactionMaster: boolean
}

const today = new Date();
const thirtyDaysAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate() + 1);

export const FilterSettings = ({
  state, dispatcher, lists, loading, hook, isTransactionMaster,
}: FilterSettingsProps) => (
  <Styled.GridStyled columns={3}>
    <InputCalendar
      label="Date Range"
      value={`${new Date(state.startDate?.value || thirtyDaysAgo).toDateString()} / ${new Date(state.endDate?.value || today).toDateString()}`}
      width="100%"
      onClick={hook.hookSetOpenCalendar}
      isDisabled={loading}
    />
    {isTransactionMaster && (
      <>
        <Select
          label="Date Type"
          name="dataTypeSelect"
          selected={state.dataType}
          onChange={(e: SelectOption) => dispatcher({ data: { dataType: e } })}
          options={hook.hookLists.hookDateTypeList}
          width="100%"
          isLoading={loading}
          isDisabled={loading}
        />
        <Select
          label="Transaction Status"
          name="transactionSelect"
          selected={state.transactionStatus}
          onChange={(e: SelectOption) => dispatcher({ data: { transactionStatus: e } })}
          options={hook.hookLists.hookTransactionStatusList}
          width="100%"
          isLoading={loading}
          isDisabled={loading}
        />
      </>
    )}
    {hook.hookUserInfo.userTypesId !== USER_TYPES_ID.MERCHANT && hook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT && (
      <Select
        label={EDIT_REPORT.LABELS.MERCHANT}
        selected={state.merchantId}
        options={lists.hookMerchantList}
        onChange={(e: SelectOption) => {
          dispatcher({
            data: {
              merchantId: e,
              publisherId: hook.hookDefaultPublisher,
              productId: EDIT_REPORT.DEFAULT_SELECT_VALUE.PRODUCT,
              productCategory: EDIT_REPORT.DEFAULT_SELECT_VALUE.CATEGORY,
              trackingProfileId: EDIT_REPORT.DEFAULT_SELECT_VALUE.TRACKING,
              campaignId: EDIT_REPORT.DEFAULT_SELECT_VALUE.CAMPAIGN,
              adCampaignId: EDIT_REPORT.DEFAULT_SELECT_VALUE.CAMPAIGN,
              publisherGroupId: EDIT_REPORT.DEFAULT_SELECT_VALUE.PUBLISHER_GROUP,
              adId: EDIT_REPORT.DEFAULT_SELECT_VALUE.AD,
            },
          });
        }}
      />
    )}
    {hook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER && hook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER && (
      <Select
        label={EDIT_REPORT.LABELS.PUBLISHER}
        selected={state.publisherId}
        options={lists.hookPublisherList}
        onChange={(e: SelectOption) => dispatcher({
          data: {
            publisherId: e,
            trackingProfileId: EDIT_REPORT.DEFAULT_SELECT_VALUE.TRACKING,
          },
        })}
        isLoading={loading}
        isDisabled={state.merchantId.value === '' || loading}
      />
    )}
    <Select
      label={EDIT_REPORT.LABELS.PRODUCT}
      selected={state.productId}
      options={lists.hookProductList}
      onChange={(e: SelectOption) => dispatcher({
        data: {
          productId: e,
        },
      })}
      isLoading={loading}
      isDisabled={state.merchantId.value === '' || loading}
    />
    <Select
      label={EDIT_REPORT.LABELS.CATEGORY}
      selected={state.productCategory}
      options={lists.hookCategoryList}
      onChange={(e: SelectOption) => dispatcher({
        data: {
          productCategory: e,
        },
      })}
      isLoading={loading}
      isDisabled={state.merchantId.value === '' || loading}
    />
    <Select
      label={EDIT_REPORT.LABELS.TRACKING}
      selected={state.trackingProfileId}
      options={lists.hookTrackingList}
      onChange={(e: SelectOption) => dispatcher({
        data: {
          trackingProfile: e,
          trackingProfileId: e,
        },
      })}
      isLoading={loading}
      isDisabled={state.merchantId.value === '' || loading}
    />
    {!isTransactionMaster && (
      <Select
        label={EDIT_REPORT.LABELS.GROUP_BY}
        selected={state.groupBy}
        options={GROUP_BY_OPTIONS}
        onChange={(e: SelectOption) => dispatcher({
          data: {
            groupBy: e,
          },
        })}
      />
    )}
    {!isTransactionMaster && (
    <Select
      label={EDIT_REPORT.LABELS.CAMPAIGN}
      selected={state.campaignId}
      options={lists.hookCampaignList}
      onChange={(e: SelectOption) => dispatcher({
        data: {
          campaignId: e,
        },
      })}
      isLoading={loading}
      isDisabled={state.merchantId.value === '' || loading}
    />
    )}
    {isTransactionMaster && (
    <Select
      label={EDIT_REPORT.LABELS.CAMPAIGN}
      selected={state.adCampaignId}
      options={lists.hookCampaignList}
      onChange={(e: SelectOption) => dispatcher({
        data: {
          adCampaignId: e,
        },
      })}
      isLoading={loading}
      isDisabled={state.merchantId.value === '' || loading}
    />
    )}
    <Select
      label={EDIT_REPORT.LABELS.PUBLISHER_GROUP}
      selected={state.publisherGroupId}
      options={lists.hookPublisherGroupList}
      onChange={(e: SelectOption) => dispatcher({
        data: {
          publisherGroupId: e,
        },
      })}
      isLoading={loading}
      isDisabled={state.merchantId.value === '' || loading}
    />
    <Select
      label={EDIT_REPORT.LABELS.AD}
      selected={state.adId}
      options={lists.hookAdlist}
      onChange={(e: SelectOption) => dispatcher({
        data: {
          adId: e,
        },
      })}
      isLoading={loading}
      isDisabled={state.merchantId.value === '' || loading}
    />
    {isTransactionMaster && (
      <>
        <div />
        <InputTextRef
          faIcon={faSearch}
          type="text"
          label="search"
          name="search"
          placeholder="Search Order ID / Publisher CID / Customer ID"
          width="100%"
          ref={hook.hookSearchInputRef}
          disabled={loading}
        />
      </>
    )}
    {!loading && hook.hookDateLoad && (
      <Calendar
        key={hook.hookReportState?.id}
        date={new Date(state.startDate?.value || thirtyDaysAgo)}
        endDate={new Date(state.endDate?.value || today)}
        onCancel={hook.hookOnCancelCalendar}
        onApply={hook.hookOnApplyCalendar}
        isOpen={hook.hookOpenCalendar}
        hasRange
        byRange={hook.hookFiltersState?.dateRange?.value}
      />
    )}
  </Styled.GridStyled>
);
