import styled from 'styled-components';
import { colors } from '../../styles/theme';
import { WrapperStyled as FixedFooterWrapperStyled } from '../FixedFooter/styles';

type WrapperProps = {
  readonly isCollapsed: boolean;
};

type RightContentProps = {
  readonly background: boolean
}

export const WrapperStyled = styled.div<WrapperProps>`
  display: grid;
  min-height: 100vh;
  grid-template-columns: ${({ isCollapsed }) => (isCollapsed ? '60px' : '220px')} calc(100% - ${({ isCollapsed }) => (isCollapsed ? '60px' : '220px')});
  overflow: hidden;
  min-width: 1250px;

  ${FixedFooterWrapperStyled} {
    left: ${({ isCollapsed }) => (isCollapsed ? '60px' : '220px')};
  }
`;

export const LeftStyled = styled.div`
  position: relative;
  background-color: ${colors.color1};
  z-index: 2;
`;

export const RightStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightTopStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1.8rem;
  height: 70px;
  min-height: 70px;
  border-bottom: 1px solid ${colors.color3};
`;

export const RightContentStyled = styled.div<RightContentProps>`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100% - 70px);
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  ${({ background }) => background && (`background-color: ${colors.color20}`)}
`;
