import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { usePDFRender } from './hooks';
import * as Styled from './styles';

type PfdRenderProps = {
  title: string
  headerInfo: PDFHeaderInfo
  payeeInfo: PDFPayeeInfo
  bankInfo: PDFBankInfo
  tableData: any
  hasProductCategory: boolean
  isMerchantPdf: boolean
  rate?: string
}

export const PfdRender = ({
  title, headerInfo, payeeInfo, bankInfo, tableData, hasProductCategory, isMerchantPdf, rate = '1',
}: PfdRenderProps) => {
  const hook = usePDFRender(isMerchantPdf);

  return (
    <Styled.PdfWrapperStyles>

      <PDFViewer style={{
        display: 'flex', flex: 1, width: '100%', height: '100%',
      }}
      >
        {hook.hookCreateDocment(
          title,
          headerInfo,
          payeeInfo,
          bankInfo,
          tableData,
          hasProductCategory,
          rate,
        )}
      </PDFViewer>
    </Styled.PdfWrapperStyles>
  );
};
