import { gql } from '@apollo/client';

export const GET_SUMMARY_REPORT_CSV = gql`
  query getFintelChecksCSV($input: FintelCheckSearchInput!) {
    fintelChecksCSVAdmin(input: $input) {
      fintelChecks {
        id
        publisherName
        publisherId
        merchantName
        merchantId
        trackingEventDate
        productCategory
        customizedProductId
        productName
        pageCount
        passCount
        failCount
        reviewCount
        incompleteCount
        nfCount
      }
    }
  }
`;
