import { toRem } from '../utils';

// fonts specificly for PDF library
import gothamboldPDF from '../assets/fonts/gothampro-medium-webfont.woff';
import latoBoldPDF from '../assets/fonts/lato-bold-webfont.woff';
import latoItalicPDF from '../assets/fonts/lato-italic-webfont.woff';
import latoRegularPDF from '../assets/fonts/lato-regular-webfont.woff';

// TODO - we should refactor the color system to use meaningful names instead of numbers
export const colors = {
  color1: '#022658',
  color2: '#F78D2D',
  color3: '#E0E0E0',
  color4: '#FFFFFF',
  color5: '#000000',
  color6: '#FDE8D5',
  color7: '#ACB4BD',
  color8: '#E36C00',
  color10: '#0C1117',
  color11: '#EDF2F7',
  color12: '#92adcb',
  color13: '#8a8a8a',
  color14: '#FF5D68',
  color15: '#E6E6E6',
  color16: '#696969',
  color17: '#243746',
  color19: '#D6DADE',
  color20: '#F5F5F5',
  color21: '#4CC7C0',
  color22: '#FFD4B2',
  color23: '#A1A1A1',
  color24: '#7C8790',
  color25: '#65c57d',
  color26: '#394f67',
  color27: '#feedde',
  color28: '#CDCDCD',
  color29: '#5DD755',
  color30: '#EE4B2B',
  color31: '#f52020',
  color32: '#32485b',
  color33: '#2b3f50',
  color34: '#ebecef',
  color35: '#cccccc',
  color36: '#fde7d3',
  color37: '#4AC7BF',
  color38: '#FF8D2D',
  color39: '#6C8DB2',
  color40: '#FF5F6A',
  color41: '#FF4651',
  color42: '#6EDB66',
  color43: '#f7949b', // change monitoring deleted snipped color
  color44: '#99e8ad', // change monitoring added snipped color
  transparent: 'transparent',
};

export const chartColors = {
  color1: 'rgba(220,220,220,1)',
  color2: 'rgba(79,190,128,1)',
  color3: 'rgba(79,190,128,0.4)',
  color4: 'rgba(103,195,223)',
  color5: 'rgba(103,195,223, 0.3)',
  color6: 'rgba(234,151,155)',
  color7: 'rgba(234,151,155, 0.3)',
};

export const fonts = {
  lato: {
    H12: `font-size: ${toRem(12)}; font-weight: 400; font-family: latoregular;`,
    H14: `font-size: ${toRem(14)}; font-weight: 400; font-family: latoregular;`,
    H16: `font-size: ${toRem(16)}; font-weight: 400; font-family: latoregular;`,
    H18: `font-size: ${toRem(18)}; font-weight: 400; font-family: latoregular;`,
    H20: `font-size: ${toRem(20)}; font-weight: 400; font-family: latoregular;`,
    H24: `font-size: ${toRem(24)}; font-weight: 400; font-family: latoregular;`,
    H12M: `font-size: ${toRem(12)}; font-weight: 400; font-family: latomedium;`,
    H14R: `font-size: ${toRem(14)}; font-weight: 400; font-family: latomedium;`,
    H16R: `font-size: ${toRem(16)}; font-weight: 400; font-family: latomedium;`,
    H26R: `font-size: ${toRem(26)}; font-weight: 400; font-family: latoregular;`,
    H14M: `font-size: ${toRem(14)}; font-weight: 400; font-family: latobold;`,
    H16M: `font-size: ${toRem(16)}; font-weight: 400; font-family: latobold;`,
    H12B: `font-size: ${toRem(12)}; font-weight: 400; font-family: latobold;`,
    H14B: `font-size: ${toRem(14)}; font-weight: 400; font-family: latobold;`,
    H15B: `font-size: ${toRem(15)}; font-weight: 400; font-family: latobold;`,
    H16B: `font-size: ${toRem(16)}; font-weight: 400; font-family: latobold;`,
    H18B: `font-size: ${toRem(18)}; font-weight: 400; font-family: latobold;`,
    H20B: `font-size: ${toRem(20)}; font-weight: 400; font-family: latobold;`,
    H22B: `font-size: ${toRem(22)}; font-weight: 400; font-family: latobold;`,
    H24B: `font-size: ${toRem(24)}; font-weight: bold; font-family: latobold;`,
  },
  gotham: {
    H10: `font-size: ${toRem(10)}; font-weight: 400; font-family: gotham_proregular;`,
    H11: `font-size: ${toRem(11)}; font-weight: bold; font-family: gotham_proregular;`,
    H12: `font-size: ${toRem(12)}; font-weight: 400; font-family: gotham_proregular;`,
    H14: `font-size: ${toRem(14)}; font-weight: 400; font-family: gotham_proregular;`,
    H16: `font-size: ${toRem(16)}; font-weight: 400; font-family: gotham_proregular;`,
    H18: `font-size: ${toRem(18)}; font-weight: 400; font-family: gotham_proregular;`,
    H20: `font-size: ${toRem(20)}; font-weight: 400; font-family: gotham_proregular;`,
    H22: `font-size: ${toRem(22)}; font-weight: 400; font-family: gotham_proregular;`,
    H24: `font-size: ${toRem(24)}; font-weight: 400; font-family: gotham_proregular;`,
    H26: `font-size: ${toRem(26)}; font-weight: 400; font-family: gotham_proregular;`,
    H10M: `font-size: ${toRem(10)}; font-weight: 400; font-family: gotham_promedium;`,
    H11M: `font-size: ${toRem(11)}; font-weight: 400; font-family: gotham_promedium;`,
    H12M: `font-size: ${toRem(12)}; font-weight: 400; font-family: gotham_promedium;`,
    H14M: `font-size: ${toRem(14)}; font-weight: 400; font-family: gotham_promedium;`,
    H16M: `font-size: ${toRem(16)}; font-weight: 400; font-family: gotham_promedium;`,
    H18M: `font-size: ${toRem(18)}; font-weight: 500; font-family: gotham_promedium;`,
    H22M: `font-size: ${toRem(22)}; font-weight: 400; font-family: gotham_promedium;`,
    H24M: `font-size: ${toRem(24)}; font-weight: 500; font-family: gotham_promedium;`,
    H26M: `font-size: ${toRem(26)}; font-weight: 500; font-family: gotham_promedium;`,
    H32M: `font-size: ${toRem(32)}; font-weight: 400; font-family: gotham_promedium;`,
    H36M: `font-size: ${toRem(36)}; font-weight: 400; font-family: gotham_promedium;`,
    H40M: `font-size: ${toRem(40)}; font-weight: 400; font-family: gotham_promedium;`,
    H12B: `font-size: ${toRem(12)}; font-weight: 400; font-family: gotham_promedium;`,
    H14B: `font-size: ${toRem(14)}; font-weight: 400; font-family: gotham_promedium;`,
    H16B: `font-size: ${toRem(16)}; font-weight: 400; font-family: gotham_promedium;`,
    H18B: `font-size: ${toRem(18)}; font-weight: 400; font-family: gotham_promedium;`,
    H20M: `font-size: ${toRem(20)}; font-weight: 500; font-family: gotham_promedium;`,
    H22B: `font-size: ${toRem(22)}; font-weight: 400; font-family: gotham_promedium;`,
  },
};

export const fontsPDF = {
  gotham: {
    bold: gothamboldPDF,
  },
  lato: {
    bold: latoBoldPDF,
    regular: latoRegularPDF,
    italic: latoItalicPDF,
  },
};
