import { gql } from '@apollo/client';

export const GET_FCHECK_SETTINGS = gql`
  query getFintelCheckSettings($input: FintelCheckSettingsInput!) {
    getFintelCheckSettings(input: $input) {
      settings {
        id
        merchantId
        manualUrls
      }
    }
  }
`;

export type GetFCheckSettingsInput = {
  input: {
    merchantId: number
  }
};

export type GetFCheckSettingsOutput = {
  getFintelCheckSettings: {
    settings: {
      id: string
      merchantId: number
      manualUrls: string[]
    }
  }
}
