export const CANCEL_ADD_RULE = {
  TITLE: 'Leave Rule?',
  WARNING: 'You have not finished adding your rule yet. Do you want to leave without finishing?',
  CANCEL_BUTTON: 'Cancel',
  LEAVE_BUTTON: 'Leave',
};

export const STEP_ONE_MODAL = {
  TITLE: 'Add Monitoring Rule Modal',
  RULE_NAME: {
    LABEL: 'Rule Name',
    TOOLTIP: 'Enter a rule name that describes the type of checks that the Fintel Check tool will perform. The clearer the description, the easier you will be able to search and find the rule.',
    PLACEHOLDER: 'Name this rule',
  },
  DESCRIPTION: 'Select the product(s) for which the rule will apply',
  GROUP_RULES: {
    LABEL: 'Group Rules',
    TOOLTIP: 'Select a rule group type',
    PLACEHOLDER: 'Select a Group Rule',
  },
  PRODUCT: {
    LABEL: 'Proudct(s)',
    TOOLTIP: 'Select a specific prodduct for which the rule will apply. Products can be added, maintained, and updated in the Product Catalog in your Account Settings.',
    PLACEHOLDER: 'Select a category',
  },
  STEP_COUNTER: 'Step 1 of 2',
  CANCEL_BUTTON: 'Cancel',
  CONTINUE_BUTTON: 'Continue',
};

export const STEP_TWO_MODAL = {
  TITLE: 'Add Custom Text',
  DESCRIPTION: 'Define the parameters for the rule to check',
  AND: 'And',
  CUSTOM_TEXT: {
    LABEL: 'Custom Text Value',
    TOOLTIP: 'Please make sure the values are unique',
    PLACEHOLDER: {
      NO_PROUDCTS: 'Please enter the value to be checked',
      DEFAULT: 'Data associated with product feed',
    },
    BUTTON: 'Click to view all product feed data',
  },
  ADD_CUSTOM_TEXT: 'Add custom text',
  STEP_COUNTER: 'Step 2 of 2',
  CANCEL_BUTTON: 'Cancel',
  CONTINUE_BUTTON: 'Continue',
};

export const SUCCESS_MESSAGES = {
  CREATED_RULE_TOAST: 'Rule successfully created.',
};

export const ERROR_MESSAGES = {
  MISSING_VALUE: 'Please enter a value',
  RULE_NAME_TAKEN: 'Please select a unique rule name.',
  MAX_LIMIT: 'Maximum limit reached: You can only add up to 5 custom text',
  INVLAID_CHAR: 'This is an invalid character',
  UNIQUE_VALUE: 'Values must be unique',
};

export const INFO = {
  LINE1: 'Enter 1 or More phrases or keywords to find on pages.',
  LINE2: 'If any one of them is found "Found" status will be reported',
  LINE3: 'If none are found "NOT FOUND" will be reported',
  WARNING: 'Please note: You are allowed to add a maximum of 5 text boxes',
};
