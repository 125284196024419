import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../../../../../../styles/theme';
import { Button, Tooltip } from '../../../../../../../components';
import { toRem } from '../../../../../../../utils';

type StatusProps = {
  readonly status: string
}

export const CellStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${fonts.lato.H14}
  color: ${colors.color1};
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-x: auto;
  overflow-y: hidden;
  line-height: 1.5;

  a {
    color: ${colors.color12};
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export const PageUrlCellStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${fonts.lato.H14}
  color: ${colors.color1};
  a {
    color: ${colors.color12};
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-x: auto;
    overflow-y: hidden;
    line-height: 1.5;
  }
`;

export const ButtonStyled = styled(Button)`
  padding: 0.5rem;
  color: ${colors.color1};
  border-radius: 2px;

  :hover {
    color: ${colors.color1};
    background-color: ${colors.color19};
  }
`;

export const TooltipStyled = styled(Tooltip)`
  div {
    color: ${colors.color1};
  }
  margin: 0;
`;

export const StatusStyled = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${toRem(8)};
  gap: 10px;
  ${fonts.lato.H12}
  border-radius: 2px;
  white-space: nowrap;
  background-color: ${({ status }) => {
    switch (status) {
      default:
        return colors.color11;
    }
  }};
  color: ${({ status }) => {
    switch (status) {
      default:
        return colors.color12;
    }
  }};
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

export const LinkStyled = styled(Button)`
  padding: 10px 0;
`;
