import React from 'react';
import * as Styled from './styles';
import {
  Button, ErrorBanner, Modal,
} from '../../../../../../components';
import { DEACTIVATE_MODAL } from './enums';

type DeactivateRuleModalProps = {
  closeModalFn: () => void
  isOpen: boolean
  deactivateRuleFn: () => void
  errorMessage: string
  isLoading: boolean
}

export const DeactivateRuleModal = ({
  closeModalFn, isOpen, deactivateRuleFn, errorMessage, isLoading,
}: DeactivateRuleModalProps) => (
  <Modal isOpen={isOpen}>
    <Styled.ModalWrapperStyled>
      <ErrorBanner
        message={errorMessage}
        isOpen={!!errorMessage}
      />
      <Styled.TitleStyled>
        {DEACTIVATE_MODAL.TITLE}
      </Styled.TitleStyled>
      <Styled.DetailsTextStyled>
        {DEACTIVATE_MODAL.DETAILS}
      </Styled.DetailsTextStyled>
      <Styled.WarningText>
        {DEACTIVATE_MODAL.WARNING}
      </Styled.WarningText>
      <Styled.ButtonsWrapper>
        <Button
          onClick={() => deactivateRuleFn()}
          loading={isLoading}
          width="120px"
        >
          {DEACTIVATE_MODAL.DEACTIVATE_BUTTON}
        </Button>
        <Button
          onClick={() => closeModalFn()}
          theme="secondary"
          width="100px"
        >
          {DEACTIVATE_MODAL.CANCEL_BUTTON}
        </Button>
      </Styled.ButtonsWrapper>
    </Styled.ModalWrapperStyled>
  </Modal>
);
