import { gql } from '@apollo/client';

export const GET_MERCHANT_COMPANIES = gql`
  query merchantCompaniesList {
    companies(input: {companyType: Merchant}) {
      companies {
        id
        companyName
      }
    }
  }
`;
