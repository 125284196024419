import styled from 'styled-components';
import { Checkbox, DropZone, InputTextarea } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 23% calc(100% - 23%);
  height: 100vh;
`;

export const LeftStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.color1};
  padding-top: 9rem;
`;

export const RightStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const RightContentStyled = styled.div`
  width: 60%;
  display: flex;
  flex-flow: column nowrap;
  color: ${colors.color1};
  padding-top: 7rem;
  padding-bottom: 5rem;

  h1 {
    ${fonts.gotham.H36M}
  }

  h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    ${fonts.gotham.H26M}
  }

  h3 {
    ${fonts.gotham.H16M}
  }

  > span {
    ${fonts.lato.H14}
    line-height: 1.5;
  }
`;

export const FormStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5%;
  row-gap: 1.5rem;
  margin-top: 2rem;
`;

export const DropzoneStyled = styled(DropZone)`
  grid-column: span 2;
  width: 50%;
`;

export const TextareaStyled = styled(InputTextarea)`
  grid-column: span 2;
`;

export const CheckboxWrapperStyled = styled.div`
  display: grid;
  justify-content: space-between;
  grid-column: span 2;
  margin: 1rem 0;
`;

export const CheckboxWrapperLabelStyled = styled.div`
  text-transform: uppercase;
  ${fonts.gotham.H12M}
  color: ${colors.color16};
  grid-column: 1 / span 3;
  margin-bottom: 1rem;
`;

export const CheckboxStyled = styled(Checkbox)`
  margin-top: 25px;
`;

export const GridCheckboxStyled = styled(Checkbox)`
  margin-bottom: 1rem;
`;

export const DemographicsStyled = styled.h3`
  border-top: 1px solid ${colors.color3};
  padding-top: 2rem;
  margin-bottom: 1rem;
  grid-column: span 2;
`;

export const DemographicsDescriptionStyled = styled.span`
  grid-column: span 2;
`;

export const ProductCategoryStyled = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  grid-column: span 3;
  grid-gap: 2%;
  row-gap: 1rem;
  margin: 1.5rem 0;
  width: 100%;
`;
