import { gql } from '@apollo/client';

export const MERCHANTS_LIST = gql`
  query companies($input: ListSearchCompanyInput) {
    listSearchMerchants(input: $input) {
      companies {
        id
        companyName
        program {
          id
        }
      }
    }
  }
`;
