import React from 'react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import { EMAIL_VERIFIED } from './enums';
import { useEmailVerified } from './hooks';

export const EmailVerified = () => {
  const hook = useEmailVerified();

  return (
    <Styled.Wrapper>
      <Styled.ModalStyled>
        <Styled.IconStyled
          className="fa-3x"
          icon={faCheckCircle}
          success={hook.hookSuccess === 'true'}
        />
        <Styled.TitleStyled>
          {EMAIL_VERIFIED.VERIFICATION}
        </Styled.TitleStyled>
        {hook.hookMessage && (
          <Styled.MessageStyled>
            {hook.hookMessage}
          </Styled.MessageStyled>
        )}
        {hook.hookSuccess && (
          <Styled.ButtonStyled
            onClick={hook.hookLoginRedirect}
          >
            {EMAIL_VERIFIED.GET_STARTED}
          </Styled.ButtonStyled>
        )}
      </Styled.ModalStyled>
    </Styled.Wrapper>
  );
};
