import React from 'react';
import { Modal } from '../Modal';
import { PageTitle } from '../PageTitle';
import * as Styled from './styles';

type LeavePageProps = {
  isOpen: boolean;
  onStay: () => void;
  onLeave: () => void;
};

export const LeavePage = ({ isOpen, onLeave, onStay }: LeavePageProps) => (
  <Modal isOpen={isOpen}>
    <Styled.WrapperStyled>
      <PageTitle>Leave Page?</PageTitle>
      <Styled.TextStyled>Are you sure you want to leave? Your changes will be lost if you leave this page.</Styled.TextStyled>

      <Styled.ButtonAreaStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={onStay}
        >
          Stay on page
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          onClick={onLeave}
        >
          Leave page

        </Styled.ButtonStyled>
      </Styled.ButtonAreaStyled>
    </Styled.WrapperStyled>
  </Modal>
);
