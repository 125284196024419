import { gql } from '@apollo/client';

export const GET_FINTEL_CHECK_SUMMARIES = gql`
  query FintelCheckSummariesPaginated($input: FintelCheckSummariesPaginatedInput) {
    fintelCheckSummariesPaginated(input: $input) {
      count
      currentPage
      limit
      totalPages
      fintelCheckSummary {
        id
        merchantId
        merchantName
        publisherId
        publisherName
        productCategory
        productId
        customizedProductId
        productName
        referralUrl
        ruleName
        ruleStatus
        screenshotName
        zipfileName
        ruleRequired
        resultFound
        trackingEventDate
        feedback {
          correct
          currStatus
          inputType
          inputVal
          issue
          shouldBeStatus
        }
        eligibilityFeedback {
          correct
          currStatus
          inputType
          inputVal
          issue
          shouldBeStatus
        }
      }
    }
  }
`;

export type GetFintelCheckSummariesInput = {
  input: {
    filter: {
      merchantId: string,
      publisherId: string,
      publisherName?: string,
      checkDate: string,
      productCategory?: string,
      productId?: string,
      ruleStatus?: string,
    },
    limit: number,
    page: number,
    sortBy: string,
    sortDirection: string,
  }
};

export type GFCSFeedbackType = {
  correct: string | null
  currStatus: string | null
  inputType: string | null
  inputVal: string | null
  issue: string | null
  shouldBeStatus: string | null
}

export type GFCSSummaryType = {
  id: string
  merchantId: string
  merchantName: string
  publisherId: string
  publisherName: string
  productCategory: string | null
  productId: string | null
  customizedProductId: string | null
  productName: string | null
  referralUrl: string | null
  ruleName: string | null
  ruleStatus: string | null
  screenshotName: string | null
  zipfileName: string | null
  ruleRequired: boolean
  resultFound: string | null
  trackingEventDate: string | null
  feedback: GFCSFeedbackType | null
  eligibilityFeedback: GFCSFeedbackType | null
}

export type GetFintelCheckSummariesOutput = {
  fintelCheckSummariesPaginated: {
    count: number
    currentPage: number
    limit: number
    totalPages: number
    fintelCheckSummary: GFCSSummaryType[]
  }
}
