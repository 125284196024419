export const MERCHANT_PROGRAM_SETTINGS_PAGE = {
  PAGE_TITLE: 'Manage Program Settings',
  TITLE_CANCEL_BUTTON: 'Cancel',
  TITLE_SAVE_BUTTON: 'Save',
  NETWORK: {
    TITLE: 'Network User Settings',
    DESCRIPTION_LABEL: 'Description - High Level',
    TC_TITLE: 'Terms and Conditions',
    TC_UPDATE: 'Last Updated',
    TC_NOTE: 'Please note the changes to the Terms and Conditions will have immediate effect on your Publisher relationship, and thus should always be considered by all major stakeholders.',
    TC_LINK: 'View and update current Terms and Conditions',
    SIGN_UP_LABEL: 'New Member Sign Up Bonus',
    SIGN_UP_VIS_LABEL: 'Visibility',
  },
  USER_SETTINGS: {
    TITLE: 'Member User Settings',
    DESCRIPTION_LABEL: 'Description - Detailed',
    PRIMARY_CONTACT_NAME: 'Primary Program Contact',
    PRIMARY_CONTACT_EMAIL: 'Primary Contact Email',
    PRIMARY_CONTACT_PHONE: 'Primary Contact Phone',
    SPECIAL_OFFER_LABEL: 'Members Only Special Offer',
    SPECIAL_OFFER_VIS_LABEL: 'Visibility',
    COOKIE_DUR_LABEL: 'Merchant Cookie Duration (Days)',
    COOKIE_DUR_VIS_LABEL: 'Visibility',
    STATS_UPDATE_LABEL: 'Merchant Stats Update Frequency',
    STATS_UPDATE_VIS_LABEL: 'Visibility',
    PRODUCT_URL_LABEL: 'Product Matrix URL',
  },
  UPDATE_TC: {
    TITLE: 'Update Terms and Conditions',
    CANCEL_BUTTON: 'Cancel',
    BACK_BUTTON: 'Back',
    PREVIEW_BUTTON: 'Preview & Save',
    SAVE_BUTTON: 'Save',
    TC_UPDATE_TIME: 'Last Updated',
    TC_UPDATE_BY: 'Last Updated By',
    TC_TITLE_LABEL: 'Title',
    TC_DATA_LABEL: 'TERMS AND CONDITIONS DETAILS',
  },
  PLACEHOLDERS: {
    CONTACT_NAME: 'Ashley Wilson',
    CONTACT_EMAIL: 'manager@email.com',
    CONTACT_PHONE: '604-123-4567',
    COOKIE_DURATION: '7',
    MATRIX_URL: 'https://company.com/files/productmatrix.xls',
  },
};
