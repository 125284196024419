import styled from 'styled-components';
import { Button, InputText } from '../../../../../../components';
import { colors, fonts } from '../../../../../../styles/theme';

export const FiltersStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 4rem;
`;

export const InputTextStyled = styled(InputText)``;

export const ButtonStyled = styled(Button)`
  white-space: nowrap;
`;

export const ExtendedFilterStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FixedFooterWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalWrapper = styled.div`
  padding: 2rem;
`;

export const ModalTitleStyled = styled.h3`
  ${fonts.gotham.H26M}
  color: ${colors.color1};
`;

export const ModalDescriptioStyled = styled.p`
  ${fonts.lato.H16}
  color: ${colors.color1};
  margin: 2rem 0;
`;

export const ButtonWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;
