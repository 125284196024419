export const FILTER_SETTINGS = {
  TITLE: 'Filter Settings',
  COLUMN_TITLE: 'Customize Columns',
  CLOSE: 'Close',

  FILTER_LABELS: [
    'Merchant ID - Name',
    'Publisher ID - Name',
    'Product ID - Name',
    'Product Category',
    'Tracking Profile',
    'Group By Options',
    'Ad Campaign',
    'Publisher Group',
    'Ad ID',
    'Records',
    'Date Range',
    'Start Date',
    'End Date',
  ],

  FILTER_TEXT_FORMATTED: {
    campaignId: 'AD CAMPAIGN',
    adId: 'AD ID',
    endDate: 'END DATE',
    groupBy: 'GROUP BY OPTIONS',
    merchantId: 'MERCHANT ID - NAME',
    productCategory: 'PRODUCT CATEGORY',
    productId: 'PRODUCT ID - NAME',
    publisherGroupId: 'PUBLISHER GROUP',
    publisherId: 'PUBLISHER ID - NAME',
    startDate: 'START DATE',
    trackingProfileId: 'TRACKING PROFILE',
    sizePerPage: 'RECORDS',
    dataType: 'DATA TYPE',
    adCampaignId: 'AD CAMPAIGN',
    transactionStatus: 'TRANSACTION STATUS',
    dateRange: 'DATE RANGE',
    limit: 'RECORDS',
    searchValue: 'SEARCH',
    search: 'SEARCH',
  },

  PERFORMANCE_FILTER_OPTIONS: [
    'startDate',
    'endDate',
    'merchantId',
    'publisherId',
    'productId',
    'productCategory',
    'trackingProfileId',
    'groupBy',
    'adCampaignId',
    'campaignId',
    'publisherGroupId',
    'adId',
    'sizePerPage',
    'dateRange',
  ],

  TRANSACTION_FILTER_OPTIONS: [
    'startDate',
    'endDate',
    'dataType',
    'transactionStatus',
    'merchantId',
    'publisherId',
    'productId',
    'productCategory',
    'trackingProfileId',
    'adCampaignId',
    'campaignId',
    'publisherGroupId',
    'adId',
    'searchValue',
    'search',
    'dateRange',
    'limit',
  ],

  DATE_RANGE_FORMATTED: {
    today: 'Today',
    yesterday: 'Yesterday',
    week: 'This Week',
    lastWeek: 'Last Week',
    last30Days: 'Last 30 Days',
    month: 'This Month',
    lastMonth: 'Last Month',
  },

  VALIDATION: {
    DATE_RANGE: 'dateRange',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
  },
};
