import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { find } from 'lodash';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import {
  ERROR_TYPES, path, USER_LANGUAGE_OPTIONS,
} from '../../../../utils';
import { useValidation } from '../../../../utils/validation';
import { useToast } from '../../../../hooks';
import { useMerchantAccessExternal } from '../components/MerchantAccess/hooks/index';
import { GET_ADMIN_ROLES } from '../../graphql/queries/getAdminRoles';
import { CREATE_USER } from '../graphql/mutations/addNewUserMutation';
import { CHECK_EMAIL } from '../../../Publishers/SignUp/graphql';

type UserRoleType = {
  name: string
  id: string
  oldId?: number
}

type addUserInputType = {
  firstName: string
  lastName: string
  email: string
  position: string
  userType: string
  companyId: null
  roleId: string[]
  newRoleIds: string[]
  status: string
  preferredLanguage: string
  phone: string
  subscribeOffers: boolean
  subscribeMerchantIds: []
  adminHasAccessAll: boolean
  adminMerchantAccessList: {id: string, companyName: string}[]
}

type RoleOptionType = {
  label: string
  oldRoleId: number
  value: string
}

export const useAddNewUser = () => {
  const [userFirstName, setUserFirstName] = useState<string>('');
  const [userLastName, setUserLastName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [userSecurityRole, setUserSecurityRole] = useState<RoleOptionType[]>([]);
  const [userSecurityRoleOptionsList, setUserSecurityRoleOptionsList] = useState<RoleOptionType[]>([]);
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('');
  const [userLanguage, setUserLanguage] = useState<SelectOption>({ label: 'English', value: 'English' });
  const [userPosition, setUserPosition] = useState<string>('');
  const [warningMessage, setWarningMessage] = useState<string>('');
  const { loading, data } = useQuery(GET_ADMIN_ROLES);
  const [createAdminUser, { loading: loadingCreateUser }] = useMutation(CREATE_USER);
  const [checkEmail] = useLazyQuery(CHECK_EMAIL);

  const setSecurityRoleOptionsHandler = () => {
    if (loading || !data || !data.newRolesByType) return;
    const newSelectOptions = data.newRolesByType.map((role: UserRoleType) => ({ label: role.name, value: role.id, oldRoleId: role.oldId || 0 }));
    setUserSecurityRoleOptionsList(newSelectOptions);
  };

  const setUserFirstNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserFirstName(e.target.value);
  };
  const setUserLastNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserLastName(e.target.value);
  };
  const setUserEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(e.target.value);
  };
  const setUserPositionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPosition(e.target.value);
  };
  const setUserPhoneNumberHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPhoneNumber(e === undefined || e.toString() === '' ? '' : e.toString());
  };
  const setUserLanguageHandler = (value: {label: string, value: string}) => {
    setUserLanguage({
      label: value.label,
      value: value.value,
    });
  };
  const setUserSecurityRoleHandler = useCallback((newRole: RoleOptionType[]) => {
    if (find(newRole, (role) => role.value && role.label.toLowerCase().indexOf('superuser') > -1)) {
      setWarningMessage('NOTE!  This user has been assigned a Super Admin role.  This role has access to all merchants and all features, and will allow them to create other Super Admins.');
    } else {
      setWarningMessage('');
    }
    setUserSecurityRole(newRole);
  }, [setUserSecurityRole]);

  const vali = useValidation();
  const { hookShowToast } = useToast();
  const navigate = useNavigate();
  const backButtonHandler = () => navigate(`${path.userManagement.href}`);
  const [createUserErrors, setCreateUserErrors] = useState<{ [key: string]: string }>({});
  const [secondRender, setSecondRender] = useState<boolean>(false);

  const values: { [key: string]: string } = {
    firstName: userFirstName,
    lastName: userLastName,
    userEmail,
    phone: userPhoneNumber,
    securityRole: userSecurityRole[0] ? 'pass' : '',
  };

  const fields = {
    firstName: ERROR_TYPES.NOT_EMPTY,
    lastName: ERROR_TYPES.NOT_EMPTY,
    userEmail: ERROR_TYPES.EMAIL,
    phone: ERROR_TYPES.PHONE,
    securityRole: ERROR_TYPES.NOT_EMPTY,
  };

  const handleValidation = () => {
    const pass = vali.validateAll(values, fields, setCreateUserErrors, secondRender);
    return pass;
  };

  /* Needed to allow errors to show after failed submits. */
  useEffect(() => {
    handleValidation();
  }, [secondRender]);

  useEffect(() => {
    setSecurityRoleOptionsHandler();
  }, [data]);

  /* Controls for the Merchant Access Component. */
  const {
    allMerchantsAccess,
    setAllMerchantAccessHandler,
    merchantList,
    checkedMerchants,
    selectedMerchants,
    setMerchantListHandler,
    setSelectedMerchantsHandler,
    setCheckedMerchantHandler,
  } = useMerchantAccessExternal(true);

  const createAdminUserHandler = async () => {
    setSecondRender(true);
    const noErrors = handleValidation();
    if (!noErrors) return;

    const userAlreadyExists = await checkEmail({
      variables: {
        email: userEmail,
        checkInAuth0: true,
      },
    });

    if (userAlreadyExists.data?.checkEmailExists) {
      setCreateUserErrors({ ...createUserErrors, userEmail: 'Email already exists.' });
      return;
    }

    const { errors } = await createAdminUser({
      variables: {
        input: <addUserInputType> {
          firstName: userFirstName,
          lastName: userLastName,
          preferredLanguage: userLanguage.value,
          email: userEmail.toLowerCase(),
          phone: userPhoneNumber,
          position: userPosition,
          userType: 'Admin',
          companyId: null,
          roleId: (userSecurityRole.filter((role) => role.oldRoleId).map((role) => role.oldRoleId.toString())),
          newRoleIds: (userSecurityRole.map((userSecRole) => userSecRole.value)),
          status: 'Active',
          subscribeOffers: false,
          subscribeMerchantIds: [],
          adminHasAccessAll: allMerchantsAccess,
          adminMerchantAccessList: selectedMerchants.map((merchant) => ({ id: merchant.id, companyName: merchant.companyName })),
        },
      },
    });

    if (errors) {
      hookShowToast(errors[0].message);
      return;
    }

    hookShowToast(`User ${userEmail} created successfully`);
    backButtonHandler();
  };

  return {
    hookUserFirstName: userFirstName,
    hookUserLastName: userLastName,
    hookUserEmail: userEmail,
    hookUserPosition: userPosition,
    hookUserPhoneNumber: userPhoneNumber,
    hookUserLanguage: userLanguage,
    hookUserSecurityRole: userSecurityRole,

    hookSetUserFirstNameHandler: setUserFirstNameHandler,
    hookSetUserLastNameHandler: setUserLastNameHandler,
    hookSetUserEmailHandler: setUserEmailHandler,
    hookSetUserPositionHandler: setUserPositionHandler,
    hookSetUserPhoneNumberHandler: setUserPhoneNumberHandler,
    hookSetUserLanguageHandler: setUserLanguageHandler,
    hookSetUserSecurityRoleHandler: setUserSecurityRoleHandler,

    hookCreateUserErrors: createUserErrors,
    hookCreateAdminUserHandler: createAdminUserHandler,
    hookLoadingCreateUser: loadingCreateUser,
    hookSetSecondRender: setSecondRender,
    hookHandleValidation: handleValidation,

    hookUserLanguageOptionsList: USER_LANGUAGE_OPTIONS,
    hookUserSecurityRoleOptionsList: userSecurityRoleOptionsList,

    hookAllMerchantsAccess: allMerchantsAccess,
    hookSetAllMerchantAccessHandler: setAllMerchantAccessHandler,
    hookMerchantList: merchantList,
    hookCheckedMerchants: checkedMerchants,
    hookSelectedMerchants: selectedMerchants,
    hookSetMerchantListHandler: setMerchantListHandler,
    hookSetSelectedMerchantsHandler: setSelectedMerchantsHandler,
    hookSetCheckedMerchantHandler: setCheckedMerchantHandler,
    warningMessage,
  };
};
