import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LinkCellProps } from '../types';
import * as Styled from '../styles';
import { DETAIL_COLUMNS } from '../../enums';
import { FINTEL_CHECK_ACTIONS } from '../../../../../reducers';

export const LinkCell = ({
  row, title, href, contextDispatch,
}: LinkCellProps) => {
  const navigate = useNavigate();
  const navigateHandler = () => {
    if (title === DETAIL_COLUMNS.VIEW_HISTORY || title === DETAIL_COLUMNS.VIEW_DETAIL) {
      contextDispatch({
        type: FINTEL_CHECK_ACTIONS.SET_DATA_FROM_DETAILS_REPORT,
        data: {
          ruleName: row.ruleName,
          referralUrl: row.referralUrl,
          productId: row.productId,
          productName: row.productName,
          fintelCheckSummaryId: row.id,
        },
      });
    }
    navigate(href);
  };

  return (
    <Styled.CellStyled>
      <Styled.LinkStyled
        theme="text-only"
        onClick={navigateHandler}
      >
        {title}
      </Styled.LinkStyled>
    </Styled.CellStyled>
  );
};
