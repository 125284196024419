import { useEffect, useState } from 'react';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useModal, useUserInfo } from '../../../../../../../hooks';
import { useValidation } from '../../../../../../../utils/validation';
import { GET_FCHECK_SETTINGS } from '../graphql/queries';
import {
  csvGenerator, paginator, URL_STATUSES, sortString,
} from '../../../../../../../utils';
import { UPDATE_FCHECK_SETTINGS } from '../graphql/mutations';
import { useDebounce } from '../../../../../../../utils/useDebounce';
import { GET_MANUAL_URL_CSV } from '../graphql/queries/getManualUrlCSV';
import { URLS_LIST } from '../enums';

export const useUrlsList = (isReadOnly: boolean) => {
  const validator = useValidation();
  const { hookWhoAmI } = useUserInfo();

  // Table States
  const [tableData, setTableData] = useState<any[]>([]);
  const [urlsList, setUrlsList] = useState<any[]>([]);
  const [urlsTemp, setUrlsTemp] = useState<any[]>([]);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: '', direction: 'asc' });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 800);

  // Modal States
  const [isOpen, setIsOpen] = useModal(false);
  const [modalType, setModalType] = useState(URLS_LIST.MODAL.TYPES.ADD);
  const [url, setUrl] = useState<string>('');
  const [selectedUrls, setSelectedUrls] = useState<any[]>([]);
  const [urlStatus, setUrlStatus] = useState<string>('');
  const [urlError, setUrlError] = useState<string>('');
  const [ID, setID] = useState<number>(0);

  // Page States
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [validateLoading, setValidateLoading] = useState<boolean>(false);

  const [getManualUrls, { loading: getManualUrlsLoading }] = useLazyQuery(GET_FCHECK_SETTINGS);
  const [getManualUrlCSV, { loading: getCSVLoading }] = useLazyQuery(GET_MANUAL_URL_CSV);
  const [updateFintelCheckSettings] = useMutation(UPDATE_FCHECK_SETTINGS);

  const setSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const clearSearch = () => {
    setSearch('');
  };

  const setUrlHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const validateUrl = async () => {
    const newStatus = await validator.validateUrlStatus(url, setUrlStatus, undefined, undefined, true);
    if (newStatus || urlStatus) validator.renderUrlCheck(newStatus, setUrlError);
    return newStatus;
  };

  const setModalInfo = ({
    id, manualUrl, type = URLS_LIST.MODAL.TYPES.ADD,
  }: Record<string, any>) => {
    setModalType(type);
    setID(id);
    setUrl(manualUrl);
  };

  const resetModal = () => {
    setID(0);
    setUrl('');
    setSelectedUrls([]);
  };

  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page);
  };

  const handleRowClick = (row: any) => {
    if (isReadOnly) return;

    const index = tableData.findIndex((item) => item.url === row.url);
    const tableDataCopy = [...tableData];
    tableDataCopy[index].checked = !tableDataCopy[index].checked;
    setTableData(tableDataCopy);
  };

  // ===== Querying & Mutating Data in DB =====

  const handleGetData = async () => {
    const { data } = await getManualUrls({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI?.companyId),
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getFintelCheckSettings?.settings?.manualUrls) {
      const formattedList = data.getFintelCheckSettings.settings.manualUrls.map((item: string, index: number) => ({
        id: index,
        url: item,
        checked: false,
        edit: faPenToSquare,
        delete: faTrash,
      }));
      setUrlsList(formattedList);
      setTableData(paginator(formattedList, recordsPerPage, 1));
      setTotalPages(Math.ceil(data.getFintelCheckSettings.settings.manualUrls.length / recordsPerPage));
      setCurrentPage(1);
    } else {
      setCurrentPage(1);
      setTotalPages(0);
    }
  };

  const handleGenerateCSV = async () => {
    const { data } = await getManualUrlCSV({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI?.companyId),
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getManualUrlCSV?.csv) {
      csvGenerator(data.getManualUrlCSV.csv, 'Manual Url List');
    }
  };

  const handleUpdateTableData = async (newTableData: any[]) => {
    const { data } = await updateFintelCheckSettings({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI.companyId),
          manualUrls: newTableData.map((item) => item.url),
        },
      },
    });
    if (data?.updateFintelSettingsFields?.settings?.manualUrls) {
      const formattedList = data.updateFintelSettingsFields.settings.manualUrls.map((item: string, index: number) => ({
        id: index,
        url: item,
        checked: false,
        edit: faPenToSquare,
        delete: faTrash,
      }));
      setUrlsList(formattedList);
      setTableData(paginator(formattedList, recordsPerPage, 1));
      setTotalPages(Math.ceil(formattedList.length / recordsPerPage));
      setCurrentPage(1);
    } else {
      setCurrentPage(1);
      setTotalPages(0);
    }
  };

  // ===== Table changes handlers =====

  const handleSearch = () => {
    const urlsListToSet = (debouncedSearch === '')
      ? urlsList
      : [...urlsList].filter((item) => item.url.toLowerCase().includes(debouncedSearch));
    setUrlsTemp(urlsListToSet);
    setTableData(paginator(urlsListToSet, recordsPerPage, 1));
    setTotalPages(Math.ceil(urlsListToSet.length / recordsPerPage));
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    const paginatedData = paginator(debouncedSearch ? urlsTemp : urlsList, recordsPerPage, page);
    setTableData(paginatedData);
    setCurrentPage(page);
  };

  const handleSort = (dataField: string, direction: any) => {
    const directionString: string = direction === 'asc' ? 'asc' : 'desc';
    const copyArrayList = [...urlsList];
    const copyArray = debouncedSearch ? [...urlsTemp] : copyArrayList;
    const sortedArray = sortString(copyArray, dataField, directionString);
    const sortedArrayList = sortString(copyArrayList, dataField, directionString);
    setUrlsTemp(sortedArray);
    setUrlsList(sortedArrayList);
    setTableData(paginator(sortedArray, recordsPerPage, 1));
    setSortColumn({ column: dataField, direction: sortColumn.direction === 'desc' ? 'asc' : 'desc' });
    setCurrentPage(1);
  };

  // ===== Local changes (add, edit, remove) handlers =====

  const handleAddManualUrl = () => {
    if (urlStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS || urlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS || urlStatus === URL_STATUSES.PENDING_WEBSITE.STATUS) return;
    if (!urlsList.map((item) => item.url).includes(url)) {
      const newList = [
        ...urlsList,
        {
          id: urlsList.length,
          url,
          checked: false,
          edit: faPenToSquare,
          delete: faTrash,
        },
      ];
      handleUpdateTableData(newList);
    }
    setIsOpen();
    resetModal();
  };

  const handleEditManualUrl = () => {
    if (urlStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS || urlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS || urlStatus === URL_STATUSES.PENDING_WEBSITE.STATUS) return;

    const domainToEditIndex = urlsList.findIndex((domain) => domain.id === ID);
    // Check if domain was edited (if not do nothing)
    if (domainToEditIndex !== -1 && urlsList[domainToEditIndex].url !== url) {
      // Check if overlaps with any current Manual URL (if yes block edit)
      if (!urlsList.some((domain) => domain.url === url && domain.id !== ID)) {
        const listCopy = [...urlsList];
        listCopy[domainToEditIndex].url = url;
        handleUpdateTableData(listCopy);
      } else {
        setUrlStatus(URL_STATUSES.INVALID_WEBSITE.STATUS);
        setUrlError(URLS_LIST.ERROR.DOMAIN_COLLISION);
        return;
      }
    }

    setIsOpen();
    resetModal();
  };

  const handleRemoveManualUrl = () => {
    const listCopy = [...urlsList];
    listCopy.splice(ID, 1);
    handleUpdateTableData(listCopy);
    setIsOpen();
    resetModal();
  };

  const handleRemoveManyManualUrls = () => {
    const listCopy = urlsList.filter((item) => !selectedUrls.includes(item.url));
    handleUpdateTableData(listCopy);
    setIsOpen();
    resetModal();
  };

  // ===== Button handlers =====

  const handleRemoveButton = () => {
    setIsOpen();
    setModalType(URLS_LIST.MODAL.TYPES.REMOVE_MANY);
  };

  const handleModalRightButton = async () => {
    let newURLStatus = '';
    if (modalType === URLS_LIST.MODAL.TYPES.ADD || modalType === URLS_LIST.MODAL.TYPES.EDIT) {
      newURLStatus = await validateUrl();
    }
    switch (modalType) {
      case URLS_LIST.MODAL.TYPES.ADD:
        setValidateLoading(true);
        if (newURLStatus === URL_STATUSES.ACTIVE_WEBSITE.STATUS || newURLStatus === URL_STATUSES.UNSAFE_WEBSITE.STATUS || newURLStatus === URL_STATUSES.INACTIVE_WEBSITE.STATUS) handleAddManualUrl();
        setValidateLoading(false);
        break;
      case URLS_LIST.MODAL.TYPES.EDIT:
        if (newURLStatus === URL_STATUSES.ACTIVE_WEBSITE.STATUS || newURLStatus === URL_STATUSES.UNSAFE_WEBSITE.STATUS || newURLStatus === URL_STATUSES.INACTIVE_WEBSITE.STATUS) handleEditManualUrl();
        break;
      case URLS_LIST.MODAL.TYPES.REMOVE:
        handleRemoveManualUrl();
        break;
      case URLS_LIST.MODAL.TYPES.REMOVE_MANY:
        handleRemoveManyManualUrls();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (url === '' || url === undefined) setUrlError('');
    else if (modalType !== URLS_LIST.MODAL.TYPES.REMOVE && modalType !== URLS_LIST.MODAL.TYPES.REMOVE_MANY) validateUrl();
  }, [url]);

  useEffect(() => {
    handleSearch();
  }, [debouncedSearch]);

  useEffect(() => {
    if (modalType === URLS_LIST.MODAL.TYPES.REMOVE_MANY) setSelectedUrls(tableData.filter((item) => item.checked === true).map((item) => item.url));
  }, [modalType, isOpen]);

  useEffect(() => {
    handleGetData();
    setCurrentPage(1);
  }, []);

  return {
    hookLoading: getManualUrlsLoading,
    hookCSVLoading: getCSVLoading,
    hookValidateLoading: validateLoading,

    hookSearch: search,
    hookSetSearch: setSearchHandler,
    hookClearSearch: clearSearch,

    hookList: tableData,
    hookSetList: setTableData,

    hookIsOpen: isOpen,
    hookSetIsOpen: setIsOpen,

    hookOnRowClick: handleRowClick,

    hookId: ID,
    hookUrl: url,
    hookSelectedUrls: selectedUrls,
    hookSetUrl: setUrlHandler,
    hookUrlError: urlError,

    hookSetModalInfo: setModalInfo,
    hookModalType: modalType,
    hookResetModal: resetModal,

    hookSetPage: setCurrentPageHandler,
    hookOnPageChange: handlePageChange,
    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,

    hookHandleSort: handleSort,
    hookSortColumn: sortColumn,

    hookDownload: handleGenerateCSV,

    hookRemoveButton: handleRemoveButton,
    hookHandleModalRightButton: handleModalRightButton,
  };
};
