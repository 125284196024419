import React from 'react';

import {
  AppWrapper, Button, PageTitle, FixedFooter, Modal,
} from '../../../../components';
import { path } from '../../../../utils';
import AccountInformationTab from './Tabs/AccountInformation';
import TrackingDomainsTab from './Tabs/TrackingDomains';
import TrackingProfileTab from './Tabs/TrackingProfile';
import { BUTTON_ACTIONS, BUTTON_LABEL, TAB_NAMES } from './contracts';
import { useMembershipDetails } from './hooks';
import { ActionModal } from './components';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type MembershipDetailsProps = DefaultPropsType;

const MembershipDetails = ({ permissionsCodeList = [] }: MembershipDetailsProps) => {
  const { hookIsReadOnlyList, ...hook } = useMembershipDetails(permissionsCodeList);
  const [isReadOnly, isPublisherInvitationsReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <PageTitle>{path.membershipDetails.name}</PageTitle>

      <Styled.TabsStyled
        tabNames={TAB_NAMES}
        elements={[
          <AccountInformationTab
            publisherDetails={hook.hookPublisherDetails}
            publisherDefaultUser={hook.hookPublisherDefaultUser}
            formatMembershipHistory={hook.hookFormatMembershipHistory}
            isMemberPage={hook.hookMemberPage}
            membershipDetails={hook.hookMembershipDetails}
            convertMembershipStatus={hook.hookConvertMembershipStatus}
          />,
          <TrackingProfileTab
            data={hook.hookTrackingProfiles}
            getTrackingProfiles={hook.hookGetTrackingProfiles}
            isLoading={hook.hookIsLoading}
            currentPage={hook.hookCurrentPage}
            totalPage={hook.hookTotalPages}
            pageChangeHandler={hook.hookSetCurrentPage}
            sortHandler={hook.hookSetProfileSortColumnHandler}
            sortColumn={hook.hookProfileSortColumn}
          />,
          <TrackingDomainsTab
            data={hook.hookTrackingDomains}
            pageChangeHandler={hook.hookSetCurrentPage}
            getTrackingDomains={hook.hookGetTrackingDomains}
            currentPage={hook.hookCurrentPage}
            totalPage={hook.hookTotalPages}
            isLoading={hook.hookIsLoading}
            sortHandler={hook.hookSetSortColumnHandler}
            sortColumn={hook.hookSortColumn}
          />,
        ]}
      />

      <FixedFooter>
        <Styled.FooterWrapperStyled>
          <Styled.RecordsCountStyled>
            {hook.hookMembershipDetails?.id && (
              <Button
                theme="tertiary"
                onClick={hook.hookNavigateMessageHandler}
                disabled={isReadOnly || isPublisherInvitationsReadOnly}
              >
                {BUTTON_LABEL.MESSAGE}
              </Button>
            )}
          </Styled.RecordsCountStyled>

          <Styled.FooterButtonAreaStyled>
            {hook.hookMenuButtons[BUTTON_ACTIONS.MENU_BACK] && (
              <Button
                theme="secondary"
                onClick={hook.hookNavigateBackHandler}
              >
                {BUTTON_LABEL.BACK}
              </Button>
            )}

            {hook.hookMenuButtons[BUTTON_ACTIONS.MENU_SEND_INVITATION] && (
              <Styled.ButtonWrapperStyled>
                <Button
                  onClick={() => hook.hookNavagtionInvite()}
                  disabled={isReadOnly || isPublisherInvitationsReadOnly}
                >
                  {BUTTON_LABEL.INVITE}
                </Button>
              </Styled.ButtonWrapperStyled>
            )}

            {hook.hookMenuButtons[BUTTON_ACTIONS.MENU_DECLINE] && (
              <Styled.ButtonWrapperStyled>
                <Button
                  theme="nonary"
                  onClick={() => hook.hookSetModalType(BUTTON_ACTIONS.MENU_DECLINE)}
                  disabled={isReadOnly || isPublisherInvitationsReadOnly}
                >
                  {BUTTON_LABEL.DECLINE}
                </Button>
              </Styled.ButtonWrapperStyled>
            )}

            {hook.hookMenuButtons[BUTTON_ACTIONS.MENU_CLOSE] && (
              <Styled.ButtonWrapperStyled>
                <Button
                  onClick={() => hook.hookSetModalType(BUTTON_ACTIONS.MENU_CLOSE)}
                  disabled={isReadOnly || isPublisherInvitationsReadOnly}
                >
                  {BUTTON_LABEL.DECLINE}
                </Button>
              </Styled.ButtonWrapperStyled>
            )}

            {hook.hookMenuButtons[BUTTON_ACTIONS.MENU_APPROVE] && (
              <Styled.ButtonWrapperStyled>
                <Button
                  onClick={() => hook.hookSetModalType(BUTTON_ACTIONS.MENU_APPROVE)}
                  disabled={isReadOnly || isPublisherInvitationsReadOnly}
                >
                  {BUTTON_LABEL.APPROVE}
                </Button>
              </Styled.ButtonWrapperStyled>
            )}
            {hook.hookMenuButtons[BUTTON_ACTIONS.MENU_INVITED] && (
              <Styled.ButtonWrapperStyled>
                <Button
                  onClick={() => ''}
                  disabled={isReadOnly || isPublisherInvitationsReadOnly}
                >
                  {BUTTON_LABEL.PENDING}
                </Button>
              </Styled.ButtonWrapperStyled>
            )}
          </Styled.FooterButtonAreaStyled>
        </Styled.FooterWrapperStyled>
      </FixedFooter>
      <Modal isOpen={!!hook.hookModalType && hook.hookIsModalOpen}>
        <ActionModal
          modalType={hook.hookModalType || ''}
          companyName={hook.hookPublisherDetails.companyName}
          actionHandler={hook.hookSetModalAction}
          cancelHandler={hook.hookSetModalCancel}
        />
      </Modal>
    </AppWrapper>
  );
};

export default MembershipDetails;
