import { gql } from '@apollo/client';

export const SHOW_PROGRAM = gql`
  query Query($id: ID!) {
    program(id: $id, currentPeriodAccountStats: false) {
      descriptionHighLevel
      termsConditionsTitle
      termsConditions
      updatedAt
      updatedBy {
        firstName
        lastName
      }
      membersOffer
      membersOfferVisibility
      descriptionDetailed
      signupBonus
      signupBonusVisibility
      primaryContact
      primaryContactEmail
      primaryContactPhone
      membersOffer
      membersOfferVisibility
      CookieDuration
      CookieDurationVisibility
      statsFrequency
      statsFrequencyVisibility
      productMatrixUrl
      id
    }
  }
`;
