import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { VERIFY_USER } from '../graphql/mutations';
import { TOAST_ERR_MESSAGES_NO_PAGE, path } from '../../../utils';

export const useSetPassword = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userEmail = urlParams.get('user_email');
  const redirectUrl = urlParams.get('url');
  const userType = urlParams.get('user_type');
  const firstUser = urlParams.get('first_user');
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [verifyUser, { loading: verifyUserLoading }] = useMutation(VERIFY_USER);

  const setCheckboxHandler = (newState: boolean) => {
    setCheckbox(newState);
  };

  const verifyUserHandler = async () => {
    setIsLoading(true);
    setErrorMessage('');
    if (!checkbox && !firstUser) {
      setIsLoading(false);
      return;
    }
    const { errors } = await verifyUser({
      variables: {
        email: userEmail,
      },
      onError(error) {
        setErrorMessage(error.message);
        setIsLoading(false);
      },
    });
    if (!errors) {
      if (redirectUrl) {
        window.open(redirectUrl, '_self');
      } else {
        setIsLoading(false);
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE('Missing url to set password'));
      }
    }
    if (errors) {
      setIsLoading(false);
      setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(errors[0].message));
    }
  };

  return {
    hookUserType: userType,
    isLoading,
    hookCheckbox: checkbox,
    hookSetCheckbox: setCheckboxHandler,

    hookErrorMessage: errorMessage,
    hookVerifyUser: verifyUserHandler,
    hookVerifyUserLoading: verifyUserLoading,
  };
};
