export const PERCENTAGE_INFRONT = [
  'balanceTransferRegular',
  'balanceTransferIntroRate',
  'interestRate',
  'annualInterestRate',
  'balanceTransferIntroFee',
  'balanceTransferFee',
  'cashAdvanceFee',
  'foreignCurrencyConversion',
  'promoInterestRate',
  'apyRate',
  'mortgageAprRate',
];

export const DOLLAR_INFRONT = [
  'annualFee',
  'monthlyFee',
  'dishonouredPaymentFee',
  'transactionFeeETR',
  'transactionFeeATM',
  'transactionFeeInternal',
  'closingBalanceToWaiveMonthlyFee',
  'bankingMinimumBalanceRate',
  'minimumOpenDeposit',
  'bankingMinimumBalanceRate',
];

export const MARKETING_ITEM = 'MARKETING ITEM';

export const LEGAL_REFERENCE = 'LEGAL REFERENCE';

export const ARRAY_VALUES = ['cardNetwork', 'userTypes', 'marketingItems', 'exclusionItems'];

export const OFFER = 'OFFER VALID UNTIL';
