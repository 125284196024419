import { gql } from '@apollo/client';

export const LIST_PUBLISHERS = gql`
  query GetPublishers {
  getPublishers {
    id
    companyName
  }
}
`;
