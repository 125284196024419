/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Checkbox, Toggle } from '../../../../../../../components';
import { TRANSACTION_SETTINGS } from '../enums';

type CellProps = {
  val: any
}

type TableProps = CellProps & {
  row: any
  tableData: any[]
  setTableData: (tableData: any[]) => void
  isReadOnly?: boolean
}

type DeleteProps = CellProps & {
  row: any
  setOpenModal: () => void
  setModalInfo: (values: Record<string, any>) => void
  isReadOnly?: boolean
}

let tableDataCopy: any[] = [];

const CellWrapper = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const CellCenterWrapper = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TypeFormatted = ({ val }: CellProps) => {
  let valFormatted;
  TRANSACTION_SETTINGS.TYPES.map((type) => {
    if (val === type.value) valFormatted = type.label;
  });
  return (
    <CellWrapper>
      {valFormatted}
    </CellWrapper>
  );
};

const CellFormatted = ({ val }: CellProps) => (
  <CellWrapper>
    {val}
  </CellWrapper>
);

const CheckFormatted = ({
  val, row, tableData, setTableData, isReadOnly,
}: TableProps) => {
  const handleChange = (checked: boolean) => {
    tableDataCopy = [...tableData];
    tableDataCopy[row.id].enabled = checked;
    setTableData(tableDataCopy);
  };

  return (
    <CellWrapper>
      <Checkbox
        checked={val}
        onChange={handleChange}
        disabled={isReadOnly}
      />
    </CellWrapper>
  );
};

const ApproveFormatted = ({
  val, row, tableData, setTableData, isReadOnly,
}: TableProps) => {
  const handleChange = (autoApprove: boolean) => {
    tableDataCopy = [...tableData];
    tableDataCopy[row.id].autoApproveEnabled = autoApprove;
    setTableData(tableDataCopy);
  };

  return (
    <CellCenterWrapper>
      <Toggle
        checked={val}
        onChange={handleChange}
        disabled={!row.enabled || isReadOnly}
      />
    </CellCenterWrapper>
  );
};

const DeleteFormatted = ({
  val, row, setOpenModal, setModalInfo, isReadOnly,
}: DeleteProps) => {
  const handleChange = () => {
    setOpenModal();
    setModalInfo(row);
  };

  return (
    <CellCenterWrapper>
      {row.id > 2 && (
      <Button
        onClick={handleChange}
        theme="quaternary"
        disabled={isReadOnly}
      >
        {row.id > 2 ? (<FontAwesomeIcon icon={val || faTrash} />) : (<i />)}
      </Button>
      )}
    </CellCenterWrapper>
  );
};

export const columns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'enabled',
    text: '',
    width: '5%',
    formatter: (val, row, tableData, setTableData) => (
      <CheckFormatted
        val={val}
        row={row}
        tableData={tableData}
        setTableData={setTableData}
        isReadOnly={isReadOnly}
      />
    ),
  },
  {
    dataField: 'type',
    text: 'Type',
    width: '25%',
    formatter: (val: any) => (
      <TypeFormatted
        val={val}
      />
    ),
  },
  {
    dataField: 'url',
    text: 'URL',
    width: '44%',
    formatter: (val: any) => <CellFormatted val={val} />,
  },
  {
    dataField: 'autoApproveEnabled',
    text: 'Auto Approve',
    width: '13%',
    position: 'center',
    formatter: (val, row, tableData, setTableData) => (
      <ApproveFormatted
        val={val}
        row={row}
        tableData={tableData}
        setTableData={setTableData}
        isReadOnly={isReadOnly}
      />
    ),
  },
  {
    dataField: 'delete',
    text: 'Delete',
    width: '13%',
    position: 'center',
    formatter: (val, row, _, __, ___, setOpenModal, setModalInfo) => (
      <DeleteFormatted
        val={val}
        row={row}
        setOpenModal={setOpenModal}
        setModalInfo={setModalInfo}
        isReadOnly={isReadOnly}
      />
    ),
  },
];
