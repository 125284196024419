import styled from 'styled-components';
import { colors, fonts } from '../../../../styles/theme';

export const WrapperStyled = styled.div`
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.color1};
`;

export const TitleStyled = styled.h2`
  ${fonts.lato.H22B}
`;

export const DescriptionStyled = styled.p`
  margin: 1.5rem 0;
  ${fonts.lato.H16R}
`;
