import { gql } from '@apollo/client';

export const GET_ACCOUNT_BALANCE_DETAILS = gql`
  query accountBalanceDetails($input: AccountBalanceInputV2!) {
    accountBalanceDetailsV2(input: $input) {
      count
      accountBalanceDetailItems {
        id
        publisher
        recordDate
        recordType
        recordName
        externalId
        publisher
        value
        taxes
        systemFee
        totalDeposit
        totalCost
        productCategory
      }
    }
  }
`;
