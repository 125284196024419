import React, { useMemo } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import {
  Button, InputText, Radio,
} from '../../../../../components';
import { MerchantAccessProps } from './types';
import { ConfirmationModal } from '../../../../../components/Modal/ConfirmationModal';
import { useMerchantAccessInternal } from './hooks/useMerchantAccessInternal';
import { MERCHANT_ACCESS_COMPONENT as ENUM } from './enums';

export const MerchantAccess = ({
  allMerchantsAccess,
  handleSetAllMerchantsAccess,
  merchantList,
  checkedMerchants,
  selectedMerchants,
  handleSetSelectedMerchants,
  handleSetCheckedMerchants,
  isReadOnly,
}: MerchantAccessProps) => {
  const {
    hookSearchValue,
    hookFirstLetterList,
    hookIsModalOpen,
    hookSetIsModalOpen,

    hookMerchantListState,
    hookOnSearchHandler,
    hookOnRemoveOneHandler,
    hookAddOrRemoveOneToCheckedHandler,
  } = useMerchantAccessInternal({
    allMerchantsAccess, handleSetAllMerchantsAccess, merchantList, checkedMerchants, selectedMerchants, handleSetSelectedMerchants, handleSetCheckedMerchants,
  });

  /**
   * The list of available merchants shown on the left side.
   * @details wrapped in useMemo to prevents this calculation intensive section from being recomputed when not needed to.
   */
  const availableMerchantList = useMemo(() => hookFirstLetterList.map((letter) => {
    // Sorts the displayed merchants under each letter.
    const merchantListSorted = hookMerchantListState
      .filter((merchant) => merchant.companyName.charAt(0).toUpperCase() === letter)
      .sort((a, b) => a.companyName.localeCompare(b.companyName));
    const isNaN = Number(letter);
    return (
      <div key={letter}>
        <Styled.LetterGroupTitleStyled>{!isNaN ? letter : '#'}</Styled.LetterGroupTitleStyled>
        {merchantListSorted.map((merchant) => (
          <Styled.AddMerchantCheckboxStyled
            key={merchant.id}
            label={merchant.companyName}
            partial={false}
            checked={checkedMerchants.some((item) => item.id === merchant.id)}
            onChange={() => hookAddOrRemoveOneToCheckedHandler(merchant)}
            disabled={isReadOnly}
          />
        ))}
      </div>
    );
  }), [hookFirstLetterList, hookMerchantListState, hookSearchValue, selectedMerchants, checkedMerchants, isReadOnly]);

  return (
    <>
      <Styled.MerchantAccessWrapper>
        <Styled.AccessTitleStyled>
          {ENUM.COMPONENT_TITLE}
        </Styled.AccessTitleStyled>
        <Styled.AccessDescriptionStyled>
          {ENUM.COMPONENT_DESCRIPTION}
        </Styled.AccessDescriptionStyled>
      </Styled.MerchantAccessWrapper>

      <Styled.AccessRadioButtonsStyled>
        <Styled.AccessRadioStyled>
          <Radio
            name="merchantAccess"
            value="allMerchantsAccess"
            onChange={() => handleSetAllMerchantsAccess(true)}
            checked={allMerchantsAccess}
            disabled={isReadOnly}
          />
          {ENUM.ALL_MERCHANTS_RADIO_TEXT}
        </Styled.AccessRadioStyled>
        <Styled.AccessRadioStyled>
          <Radio
            name="merchantAccess"
            value="specificMerchantsAccess"
            onChange={() => handleSetAllMerchantsAccess(false)}
            checked={allMerchantsAccess === false}
            disabled={isReadOnly}
          />
          {ENUM.SPECIFIC_MERCHANTS_RADIO_TEXT}
        </Styled.AccessRadioStyled>
      </Styled.AccessRadioButtonsStyled>

      {allMerchantsAccess === false && (
      <Styled.MerchantManagementStyled>
        <Styled.AllMerchantsListWrapper>
          <InputText
            faIcon={faSearch}
            label=""
            name="merchantSearch"
            type="text"
            value={hookSearchValue}
            onChange={(e) => hookOnSearchHandler(e)}
            placeholder={ENUM.MERCHANT_SEARCH_PLACEHOLDER}
          />
          <Styled.AllMerchantsListStyled>
            {availableMerchantList}
          </Styled.AllMerchantsListStyled>
          <Styled.AllMerchantsListFooterStyled>
            {checkedMerchants.length}
            {' '}
            {checkedMerchants.length === 1 ? 'merchant selected' : 'merchants selected'}
            <Styled.AllMerchantsListFooterButtonsStyled>
              <Button
                onClick={() => handleSetCheckedMerchants([])}
                theme="secondary"
                disabled={checkedMerchants.length < 1 || isReadOnly}
                type="button"
              >
                {ENUM.DECHECK_ALL_BUTTON_TEXT}
              </Button>
              <Button
                onClick={() => hookSetIsModalOpen(true)}
                disabled={checkedMerchants.length < 1 || isReadOnly}
                type="button"
              >
                {ENUM.ADD_CHECKED_BUTTON_TEXT}
              </Button>
            </Styled.AllMerchantsListFooterButtonsStyled>
          </Styled.AllMerchantsListFooterStyled>
        </Styled.AllMerchantsListWrapper>

        <Styled.SelectedMerchantsListWrapper>
          <Styled.SelectedMerchantsTitleStyled>{ENUM.MERCHANT_ACCESS_LIST_TITLE}</Styled.SelectedMerchantsTitleStyled>
          {selectedMerchants.length === 0 ? (
            <Styled.NonSelectedTextStyled>
              <p>{ENUM.MERCHANT_ACCESS_LIST_NON_SELECTED_TITLE}</p>
              <p>{ENUM.MERCHANT_ACCESS_LIST_NON_SELECTED_TEXT}</p>
            </Styled.NonSelectedTextStyled>
          ) : (
            <>
              <Styled.SelectedListStyled>
                {selectedMerchants.map((merchant) => (
                  <Styled.MerchantItemStyled key={merchant.id}>
                    {merchant.companyName}
                    <Button
                      onClick={() => hookOnRemoveOneHandler(merchant.id)}
                      theme="quinary"
                      type="button"
                      disabled={isReadOnly}
                    >
                      {ENUM.MERCHANT_ACCESS_LIST_REMOVE_BUTTON_TEXT}
                    </Button>
                  </Styled.MerchantItemStyled>
                ))}
              </Styled.SelectedListStyled>

              <Styled.SelectedListFooterStyled show={selectedMerchants.length > 0}>
                <Button
                  onClick={() => handleSetSelectedMerchants([])}
                  theme="quinary"
                  disabled={isReadOnly}
                >
                  {ENUM.MERCHANT_ACCESS_LIST_REMOVE_ALL_BUTTON_TEXT}
                </Button>
              </Styled.SelectedListFooterStyled>
            </>
          )}

        </Styled.SelectedMerchantsListWrapper>
      </Styled.MerchantManagementStyled>
      )}

      <ConfirmationModal
        title={ENUM.MODAL_TITLE}
        desc={ENUM.MODAL_DESC}
        isOpen={hookIsModalOpen}
        onCloseText={ENUM.MODAL_CANCEL_BUTTON_TEXT}
        onConfirmText={ENUM.MODAL_SAVE_BUTTON_TEXT}
        onClose={() => hookSetIsModalOpen(false)}
        onConfirm={() => {
          hookSetIsModalOpen(false);
          handleSetSelectedMerchants([...selectedMerchants, ...checkedMerchants]);
          handleSetCheckedMerchants([]);
        }}
      >
        <Styled.ModalListItemsBoxStyled>
          {checkedMerchants.map((merchant) => (
            <Styled.ModalListItemStyled key={merchant.id}>{merchant.companyName}</Styled.ModalListItemStyled>
          ))}
        </Styled.ModalListItemsBoxStyled>
        <Styled.ModalTextStyled>{ENUM.MODAL_FOOTER_TEXT}</Styled.ModalTextStyled>
      </ConfirmationModal>
    </>
  );
};
