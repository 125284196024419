export const PRODUCT_FEED_DETAILS = {
  BACK: 'Back',
  URL: 'product feed url',
  COPY: 'Copy URL',
  COPIED: 'Copied URL',
  TRACKING: 'tracking profile id',
  COUNTRY: 'country',
  STATES: 'states / provinces',
  MERCHANT: 'merchants',
  PRODUCT_CATEGORY: 'product category',
  PRODUCT_SUBCATEGORY: 'product subcategory',
  PRODUCTS: 'Associated Products',
};
