import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { copyToClipboard } from '../../../../../utils/copyToClipboard';
import { MERCHANT_PREFIX, path } from '../../../../../utils';
import { GET_COMPANY } from '../graphql/queries/getCompany';
import {
  DEFAULT_MERGE_FIELD, DEFAULT_SUBJECT, DEFAULT_TEMPLATE_OPTIONS, formatDomain, USER_TYPES_ID,
} from '../enum';
import { GET_PUBLISHER_INVITATIONS } from '../graphql/queries/getPublisherInvitations';
import { useToast, useUserInfo } from '../../../../../hooks';
import { MERCHANT_PROGRAM } from '../graphql/queries/getMerchantProgram';
import { INVITATIONS_AVAILABLE } from '../graphql/queries/invitationsAvailable';
import { CREATE_PUBLSIHER_INVITATION } from '../graphql/mutations/createPublisherInvitations';
import { CREATE_MEMBERSIP } from '../graphql/mutations/createMemberShip';
import { Permission } from '../../../../../entities';

export const useSendPublisherInvitation = (permissionsCodeList: string[] = []) => {
  const { publisherId, publisherName, prevState } = useLocation().state;

  const user = useUserInfo();

  const { hookShowToast } = useToast();

  const [invitations, { loading: invitationsLoading }] = useLazyQuery(INVITATIONS_AVAILABLE);

  const [inviationsLeft, setInvitationsLeft] = useState(true);

  const invitationsHandler = async () => {
    if (user.hookUserInfo.userTypesId !== USER_TYPES_ID.ADMIN) {
      const { data } = await invitations({ variables: { merchantId: user.hookWhoAmI.companyId?.toString() }, fetchPolicy: 'no-cache' });
      if (data?.publisherInvitationAvailability !== undefined) {
        setInvitationsLeft(data.publisherInvitationAvailability.available);
      }
    } else {
      setInvitationsLeft(true);
    }
  };

  const [productCategories, setProductCategories] = useState('');
  const [programUrl, setProgramUrl] = useState('');
  const [merchantName, setMerchantName] = useState('');
  const [programId, setProgramId] = useState('');
  const [merchantProgram, { loading: merchantProgramLoading }] = useLazyQuery(MERCHANT_PROGRAM);

  const handleMerchantProgram = async () => {
    const { data } = await merchantProgram({ variables: { merchantId: user.hookWhoAmI.companyId?.toString() } });
    if (data?.merchantProgram !== undefined) {
      const categories: any = [];
      data?.merchantProgram.program.products.forEach(({ productCategory } : {productCategory : any}) => {
        // product category
        const renamedProductCategory = productCategory === 'Businesses' ? 'Business Products' : productCategory;
        if (renamedProductCategory && !categories.includes(renamedProductCategory)) categories.push(renamedProductCategory);
      });
      setProductCategories(categories.join(', '));
      setProgramUrl(`https://${formatDomain('.')}app.fintelconnect.com/publisher/program-details?id=${data.merchantProgram.program.id}`);
      setMerchantName(data.merchantProgram.program.merchant.companyName);
      setProgramId(data.merchantProgram.program.id);
    }
  };

  const [templateOptions, setTemplateOptions] = useState<SelectOption[]>([]);
  const [getPublisherInvitations, { loading: getPublisherInvitationsLoading }] = useLazyQuery(GET_PUBLISHER_INVITATIONS);

  const getPublisherInvationsHandler = async () => {
    const { data } = await getPublisherInvitations({ variables: { input: { merchantId: user.hookWhoAmI.companyId?.toString(), template: true } } });

    if (data?.publisherInvitations !== undefined) {
      setTemplateOptions(data.publisherInvitations.filter((obj:any) => obj.message !== null).map((obj: any) => ({ label: obj.name, value: { message: obj.message, subject: obj.subject } })));
    }
  };

  const [invitesOnHold, setInvitesOnHold] = useState(false);
  const [getCompany, { loading: getCompanyLoading }] = useLazyQuery(GET_COMPANY);

  const handleGetCompany = async () => {
    const { data } = await getCompany({ variables: { companyId: publisherId } });
    if (data?.company !== undefined) {
      setInvitesOnHold(data?.company.invitesOnHold);
    }
  };

  const [templateSelected, setTemplateSelected] = useState(DEFAULT_TEMPLATE_OPTIONS);
  const [selectedMergeField, setSelectedMergeField] = useState<SelectOption>(DEFAULT_MERGE_FIELD);
  const [subject, setSubject] = useState(DEFAULT_SUBJECT);
  const [message, setMessage] = useState(DEFAULT_TEMPLATE_OPTIONS.value);
  const [indicator, setIndicator] = useState('');
  const navigate = useNavigate();

  const handleTemplateSelected = (value: any) => {
    setTemplateSelected(value);
    setMessage(value.value.message);
    setSubject(value.value.subject);
  };

  const filterOutTags = (value: any) => {
    const replacements: any = {
      '{{merchant}}': merchantName,
      '{{merchantlink}}': programUrl,
      '{{publisher}}': publisherName,
      '{{merchantproduct}}': productCategories,
    };
    const valueCopy = value.replace(/{{merchant}}|{{merchantlink}}|{{publisher}}|{{merchantproduct}}/g, (match: any) => replacements[match]);
    return valueCopy;
  };

  const goBack = () => {
    navigate(`${MERCHANT_PREFIX}${path.publisherInvitations.href}`, {
      state: {
        ...prevState,
        from: `${MERCHANT_PREFIX}${path.publisherInvitations.href}`,
      },
    });
  };

  const [createPublisherInvitation, { loading: createLoading }] = useMutation(CREATE_PUBLSIHER_INVITATION);
  const [createMembership, { loading: createMembershipLoading }] = useMutation(CREATE_MEMBERSIP);

  const createPublisherInvitationHandler = async () => {
    const { data } = await createPublisherInvitation(
      {
        variables: {
          input: {
            merchantId: user.hookWhoAmI.companyId?.toString(),
            name: publisherName,
            subject: filterOutTags(subject),
            message: filterOutTags(message),
          },
        },
      },
    );
    if (data?.createPublisherInvitation === undefined) {
      hookShowToast('Failed to create publisher invitation');
      return;
    }
    return data.createPublisherInvitation.id;
  };

  const createMemberShipHandler = async (invId: string) => {
    const { data } = await createMembership(
      {
        variables: {
          input: {
            programId,
            merchantId: user.hookWhoAmI.companyId?.toString(),
            publisherId,
            status: 'Pending',
            statusLastUpdatedBy: 'Merchant',
            subscription: false,
            publisherInvitationId: invId,
            invitationFromAdmin: user.hookUserInfo.userSubTypesId.includes(1),
          },
        },
      },
    );
    if (data.createMemberShip !== undefined) return true;
    return false;
  };

  const sendHandler = async () => {
    const invId = await createPublisherInvitationHandler();
    await createMemberShipHandler(invId);
    goBack();
  };

  const validateFields = () => {
    if (subject !== '' && message !== '<p><br></p>') {
      sendHandler();
    } else {
      hookShowToast('Your template must have a subject and message');
    }
  };

  const mergeFieldsHandler = (e: any) => {
    copyToClipboard(e.value);
    setIndicator('Merge Field copied to clipboard!');
    setSelectedMergeField(e);
  };

  const subjectHandler = (e: any) => {
    setSubject(e.target.value);
  };

  useEffect(() => {
    handleGetCompany();
    handleMerchantProgram();
    getPublisherInvationsHandler();
    invitationsHandler();
  }, []);

  return {
    hookMergeFieldsHandler: mergeFieldsHandler,
    hookSelectedMergeField: selectedMergeField,
    hookSubject: subject,
    hookSetSubject: subjectHandler,
    hookSetMessage: setMessage,
    hookMessage: message,
    hookGoBack: goBack,
    hookSendHandler: sendHandler,
    hookInvitesOnHold: invitesOnHold || !inviationsLeft,
    hookLoading: getCompanyLoading || getPublisherInvitationsLoading || merchantProgramLoading || invitationsLoading,
    hookPublisherName: publisherName,
    hookSendInvite: validateFields,

    hookTemplateSelected: templateSelected,
    hookHandleTemplateSelected: handleTemplateSelected,
    hookTemplateOptions: templateOptions,
    hookIndicator: indicator,

    hookSendLoading: createMembershipLoading || createLoading,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
