import styled from 'styled-components';
import { toRem } from '../../../../../../utils';
import { fonts } from '../../../../../../styles/theme';

type SpanStyledProps = {
  readonly isBold?: boolean
}

export const ModalBodyStyled = styled.div`
  padding: ${toRem(8)};
  ${fonts.lato.H14};
  line-height: ${toRem(21)};
  max-width: ${toRem(465)};
`;

export const TitleStyled = styled.p`
  ${fonts.gotham.H24M};
  margin-bottom: ${toRem(24)};
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${toRem(24)};
`;

export const SpanStyled = styled.span<SpanStyledProps>`
  ${({ isBold }) => (isBold ? fonts.lato.H14B : fonts.lato.H14)}
`;
