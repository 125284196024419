import React from 'react';
import { Button, Modal } from '../../../../../../../../components';
import * as Styled from './styles';
import { UPDATE_SETTINGS } from './enums';

type ModalProps = {
  hook: any
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const UpdateSettingsModal = ({ hook, isOpen, setIsOpen }: ModalProps) => (
  <Modal isOpen={isOpen}>
    <Styled.WrapperStyled>
      <Styled.TitleStyled>{UPDATE_SETTINGS.TITLE}</Styled.TitleStyled>
      <Styled.DescriptionStyled>{`${UPDATE_SETTINGS.DESCRIPTION} ${hook.hookChangeDate}.`}</Styled.DescriptionStyled>
      <Styled.ButtonWrapperStyled>
        <Button
          onClick={() => setIsOpen(false)}
          theme="secondary"
        >
          {UPDATE_SETTINGS.CANCEL}
        </Button>
        <Styled.ButtonStyled
          onClick={() => {
            setIsOpen(false);
            hook.hookSaveChanges();
          }}
        >
          {UPDATE_SETTINGS.SAVE}
        </Styled.ButtonStyled>
      </Styled.ButtonWrapperStyled>
    </Styled.WrapperStyled>
  </Modal>
);
