import { gql } from '@apollo/client';

export const LIST_DEMOGRAPH_GENDER = gql`
  query List {
    listDemographicGender {
      list {
        id
        gender
      }
    }
  }
`;
