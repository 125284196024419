import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query Products($status: String, $programId: String) {
  products(status: $status, programId: $programId) {
    products {
      id
      name
      updatedAt
      productCategory
      customizedProductId
      productImageUrl
      status
      targetDemographics {
        geographicalCountry
      }
    }
  }
}
`;
