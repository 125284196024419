import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useToast, useUpload, useUserInfo } from '../../../../../../hooks';
import { UPLOAD_BULK_IMPORT } from '../graphql/mutations';
import {
  csvGenerator, dateFormatter, MERCHANT_PREFIX, path, USER_TYPES_ID, TOAST_ERR_MESSAGES_NO_PAGE,
} from '../../../../../../utils';
import { txtGenerator } from '../../../../../../utils/txtGenerator';
import { GET_MERCHANTS_LIST } from '../../../../graphql/queries';
import { ERROR_MESSAGE } from '../utils';
import { Permission } from '../../../../../../entities';

type UploadedResult = {
  url: string,
  key: string,
  name: string,
}

type UploadTransactionsFileOutput = {
  count: number
  transactions: {
    grossSale: string
    netSale: string
    createdAt: string
  }[]
  totalGrossSale: number
  totalNetSale: number
  minDate: string
  maxDate: string
}

export const useBulkUpload = (permissionsCodeList: string[] = []) => {
  const userHook = useUserInfo();
  const upload = useUpload();
  const navigate = useNavigate();
  const { hookShowToast } = useToast();

  // inputs
  const [file, setFile] = useState<File | undefined>();
  const [merchantOptions, setMerchantOptions] = useState<SelectOption[]>([]);

  // options
  const [selectedMerchant, setSelectedMerchant] = useState<SelectOption | undefined>();

  // info
  const [uploadedResult, setUploadedResult] = useState<UploadedResult>();
  const [transactionCount, setTransactionCount] = useState<number>(0);
  const [netRevenue, setNetRevenue] = useState<string>('');
  const [grossRevenue, setGrossRevenue] = useState<string>('');
  const [latest, setLatest] = useState<string>('');
  const [earliest, setEarliest] = useState<string>('');

  // page states
  const [afterUpload, setAfterUpload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [resetFileUpload, setResetFileUpload] = useState<string>('UploadFileInput');
  const [fileSubmitError, setFileSubmitError] = useState<string>('');
  const [merchantError, setMerchantError] = useState<string>('');

  // queries & mutations
  const [uploadBulkImport, { loading: uploadBulkImportLoading }] = useMutation(UPLOAD_BULK_IMPORT);
  const [getMerchants, { loading: getMerchantsLoading }] = useLazyQuery(GET_MERCHANTS_LIST);

  const getMerchantsHandler = async () => {
    if (userHook.hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT) {
      const { data } = await getMerchants({
        variables: {
          input: {
            companyType: 'Merchant',
            accountStatus: 'Approved',
          },
        },
        onError(err) {
          setErrorMessage(err.message);
        },
      });
      if (data?.companies.companies) {
        const merchants = data.companies.companies.map((info: any): SelectOption => ({ value: info.id, label: info.companyName }));
        setMerchantOptions(merchants);
      }
    } else if (userHook.hookWhoAmI.companyId) {
      setSelectedMerchant({ label: '', value: userHook.hookWhoAmI.companyId.toString() });
    }
  };

  const selectMerchantHandler = (value: SelectOption) => {
    setMerchantError('');
    setSelectedMerchant(value);
  };

  const onUploadHandler = (value: any) => {
    if (value instanceof File) {
      setFileSubmitError('');
      setFile(value);
    }
  };

  const createBulkImportAndQueueHandler = async () => {
    setErrorMessage('');
    if (uploadedResult && selectedMerchant) {
      await uploadBulkImport({
        variables: {
          input: {
            merchantId: selectedMerchant.value,
            key: uploadedResult.key,
            process: true,
          },
        },
        onError(err) {
          setErrorMessage(err.message);
        },
      });

      navigate(userHook.hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT || userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT ? `${MERCHANT_PREFIX}${path.transactionMaster.href}` : `${path.transactionMaster.href}`);
    }
  };

  const createFileUploadHandler = async () => {
    setErrorMessage('');
    if (!file) {
      setFileSubmitError(ERROR_MESSAGE.MISSING_FILE);
    }
    if (!selectedMerchant?.value) {
      setMerchantError(ERROR_MESSAGE.MISSING_MERCHANT);
    }
    if (!file || !selectedMerchant?.value) return;

    try {
      setIsLoading(true);
      const response = await upload.hookUploadDocument(file, selectedMerchant.value);
      if (!response.key) {
        setErrorMessage(response.message ? `Something went wrong: ${response.message}` : ERROR_MESSAGE.AWS_UPLOAD_ERROR);
        setIsLoading(false);
        return;
      }
      setUploadedResult(response);
      const { data } = await uploadBulkImport({
        variables: {
          input: {
            merchantId: selectedMerchant.value,
            key: response.key,
            process: false,
          },
        },
        onError(err) {
          setErrorMessage(err.message);
        },
      });
      const returnedValue: UploadTransactionsFileOutput = data.newUploadTransactionsFile;

      if (returnedValue.transactions) {
        // get total gross and net sales amount
        const formater = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
        const totalGrossSaleString = formater.format(returnedValue.totalGrossSale);
        const totalNetSaleString = formater.format(returnedValue.totalNetSale);
        setGrossRevenue(totalGrossSaleString);
        setNetRevenue(totalNetSaleString);
        setTransactionCount(returnedValue.count);
        // get min and max date of the transaction
        if (returnedValue.minDate === null) {
          setEarliest(ERROR_MESSAGE.INVALID_DATE);
        } else {
          setEarliest(dateFormatter(new Date(returnedValue.minDate)));
          setLatest(dateFormatter(new Date(returnedValue.maxDate)));
        }
      }
      setIsLoading(false);
      if (returnedValue.transactions.length === 0) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(ERROR_MESSAGE.MISSING_TRANSACTIONS));
        return;
      }
      setAfterUpload(true);
    } catch (error: any) {
      setIsLoading(false);
      hookShowToast(ERROR_MESSAGE.UPLOAD_ERROR);
    }
  };

  const CSVHandler = async () => {
    csvGenerator('transaction_date,publisher_tracker,order_id,product_id,status_id,gross_revenue,net_revenue,transaction_type,ip_address\n,,,,,,,,,', 'template.csv');
  };

  const TXTHandler = async () => {
    txtGenerator('transaction_date\tpublisher_tracker\torder_id\tproduct_id\tstatus_id\tgross_revenue\tnet_revenue\ttransaction_type\tip_address', 'template.txt');
  };

  const setOpenModalHandler = () => {
    setConfirmModal(true);
  };

  const setCloseModalHandler = () => {
    setConfirmModal(false);
  };

  const onCancelHandler = () => {
    setSelectedMerchant({ value: '', label: '' });
    setFile(undefined);
    setAfterUpload(false);
    setResetFileUpload(`ResetFileUploadNewKey${Math.random()}`);
    setMerchantError('');
    setFileSubmitError('');
  };

  const onBackHandler = () => {
    setUploadedResult(undefined);
    setAfterUpload(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getMerchantsHandler();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (merchantOptions) selectMerchantHandler(merchantOptions[0]);
  }, [merchantOptions]);

  // Handles swaps between admin and acting as merchant view
  useEffect(() => {
    getMerchantsHandler();
  }, [window.location.href]);

  return {
    hookSelectedMerchant: selectedMerchant,
    hookMerchantOptions: merchantOptions,
    hookLoading: isLoading || getMerchantsLoading || uploadBulkImportLoading,
    hookErrorMessage: errorMessage,
    hookFileSubmitError: fileSubmitError,
    hookMerchantError: merchantError,
    hookFile: file,
    hookFileDetails: afterUpload,
    hookTransactionCount: transactionCount,
    hookNetRevenue: netRevenue,
    hookGrossRevenue: grossRevenue,
    hookEarliestDate: earliest,
    hookLatestDate: latest,
    hookModal: confirmModal,
    hookSetModal: setOpenModalHandler,
    hookCloseModal: setCloseModalHandler,
    hookOnUpload: onUploadHandler,
    hookMerchantList: getMerchantsHandler,
    hookSubmit: createFileUploadHandler,
    hookConfirmSubmit: createBulkImportAndQueueHandler,
    hookSetSelectedMerchant: selectMerchantHandler,
    hookCancel: onCancelHandler,
    hookBack: onBackHandler,
    hookCSVDownload: CSVHandler,
    hookTXTDownload: TXTHandler,
    hookResetFileUpload: resetFileUpload,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
