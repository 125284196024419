import styled from 'styled-components';
import { colors } from '../../../../../../styles/theme';
import { toRem } from '../../../../../../utils';

export const FooterDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
`;

export const ArrowDiv = styled.div`
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
`;

type TextStyledProps = {
  readonly theme?: string
}

type CellWrapperStyledProps = {
  readonly theme?: string
}

export const CellWrapperStyled = styled.div<CellWrapperStyledProps>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ theme }) => (theme === 'checkBox' ? 'flex-start' : 'space-between')};
  flex-direction: ${({ theme }) => (theme === 'checkBox' ? 'row' : 'column')};
`;

export const TextStyled = styled.p<TextStyledProps>`
  font-size: ${({ theme }) => (theme === 'main' ? toRem(16) : toRem(14))};
  font-weight: ${({ theme }) => (theme === 'main' ? 'bold' : 'normal')};
  color: ${({ theme }) => (theme === 'main' ? colors.color1 : colors.color13)};
`;

export const IconButtonStyled = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  color: ${colors.color1};
`;

export const Wrapper = styled.div`
  display: flex;
  gap: ${toRem(10)};
  color: ${colors.color2};
`;
