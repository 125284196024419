import React from 'react';
import * as Styled from './styles';
import { ConfirmationModal } from '../../../../components/Modal/ConfirmationModal';
import { ModalInformation } from '../types.ts';
import { APPROVE_DECLINE_MODAL } from './enums';

type ApproveModalTypes = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string
  desc: string
  onCloseText: string
  onConfirmText: string
  modalInformation?: ModalInformation[]
  value?: string | number
  handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isLoading?: boolean
  errorMessage?: string
}

export const ApproveDeclineModal = ({
  onClose, onConfirm, isOpen, title, desc, onCloseText, onConfirmText, modalInformation, value, handleChange, isLoading, errorMessage,
}: ApproveModalTypes) => (
  <ConfirmationModal
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    title={title}
    desc={desc}
    onCloseText={onCloseText}
    onConfirmText={onConfirmText}
    buttonWidth="150px"
    isLoading={isLoading}
    theme="secondary"
    errorMsg={errorMessage}
    width="468px"
  >
    {!value && modalInformation && (
      <Styled.BlockWrapper theme="outer">
        <Styled.BlockWrapper>
          <Styled.TextStyled theme="outer">
            {APPROVE_DECLINE_MODAL.TRANSACTIONS_TEXT}
          </Styled.TextStyled>
          <Styled.TextStyled>
            {modalInformation.map((currentGroup) => (
              <p key={currentGroup.currency}>
                {currentGroup.transactions}
              </p>
            ))}
          </Styled.TextStyled>
        </Styled.BlockWrapper>
        <Styled.BlockWrapper>
          <Styled.TextStyled theme="outer">
            {APPROVE_DECLINE_MODAL.GROSS_SALE_TEXT}
          </Styled.TextStyled>
          <Styled.TextStyled>
            {modalInformation.map((currentGroup) => (
              <p key={currentGroup.currency}>
                {currentGroup.currency}
                {' '}
                {currentGroup.grossSalesString}
              </p>
            ))}
          </Styled.TextStyled>
        </Styled.BlockWrapper>
        <Styled.BlockWrapper>
          <Styled.TextStyled theme="outer">
            {APPROVE_DECLINE_MODAL.NET_SALES_TEXT}
          </Styled.TextStyled>
          <Styled.TextStyled>
            {modalInformation.map((currentGroup) => (
              <p key={currentGroup.currency}>
                {currentGroup.currency}
                {' '}
                {currentGroup.netSalesString}
              </p>
            ))}
          </Styled.TextStyled>
        </Styled.BlockWrapper>
      </Styled.BlockWrapper>
    )}

    {value !== undefined
    && (
    <Styled.TextAreaStyled
      value={value}
      onChange={handleChange}
    />
    )}

  </ConfirmationModal>
);
