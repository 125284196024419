export const MANUAL_PUBLISHER_BONUS = {
  MANUAL_TITLE: 'Manual Bonus Details',
  BACK: 'Back',
  ADDED_BY: 'added by',
  PUBLISHER_ID_NAME: 'publisher id/name',
  PAYMENT_AMOUNT: 'payment amount',
  PAYMENT_PERIOD: 'payment period',
  PAYMENT_TYPE: 'payment type',
  MERCHANT: 'merchant',
  NOTE: 'note',
  PRODUCT_CATEGORY: 'product category',
  ADD_TITLE: 'Add Manual Bonus',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  FAILED_MERCHANTS: 'Failed to fetch Merchants and Publishers',
  LOADING_FILTERS: 'Loading Merchants and Publishers',
  ADD_BONUS_BUTTON: 'Add Bonus',
  PUBLISHER_SELECT: {
    LABEL: 'Publisher Account ID',
    NAME: 'publisherAccount',
  },
  MERCHANTS: {
    LABEL: 'Merchants',
    NAME: 'merchants',
  },
  CALENDAR: {
    LABEL: 'Date Range',
  },
  ADD_BONUS_MODAL: {
    PUBLISHER: {
      LABEL: 'publisher id/name',
      NAME: 'pubisherIdName',
      ERROR: 'Please select publisher',
      PLACEHOLDER: 'Select Publisher',
    },
    PAYMENT_AMOUNT_TEXTS: {
      ERROR: 'Please enter valid payment amount',
      LABEL: 'payment amount',
      NAME: 'paymentAmount',
    },
    PAYMENT_PERIOD_TEXTS: {
      LABEL: 'payment period',
      NAME: 'paymentPeriod',
      PLACEHOLDER: 'Select Period',
    },
    PAYMENT_TYPE_TEXTS: {
      LABEL: 'payment type',
      NAME: 'paymentTpye',
      PLACEHOLDER: 'Select Type',
    },
    MERCHANT_TEXTS: {
      ERROR: 'Please select a merchant',
      LABEL_NAME: 'merchant',
      PLACEHOLDER: 'Select Merchant',
    },
    PRODUCT_TEXTS: {
      ERROR: 'Please select a category',
      LABEL: 'product options',
      NAME: 'productOptions',
      PLACEHOLDER: 'Select Product',
    },
    PRODUCT_CATEGORY_TEXTS: {
      LABEL: 'product category',
      NAME: 'productCategory',
    },
    NOTE_TEXTS: {
      LABEL_NAME: 'note',
    },
  },
};

export const today = new Date();

export const MODAL_ERROR = 'Failed to fetch';

export const EMPTY_SELECT_MERCHANT = { label: 'All Merchants', value: '1' };
export const EMPTY_SELECT_PUBLISHER = { label: 'All Publishers', value: '1' };
