import { useState } from 'react';
import { toUTCHours, getPreviousMonth, rangeFormat } from '../utils';

export const useCalendar = () => {
  const defaultDateRange = rangeFormat('last30Days');

  const [startDate, setStartDate] = useState(defaultDateRange.start);
  const [endDate, setEndDate] = useState(defaultDateRange.end);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [range, setRange] = useState(`${startDate.toDateString()} - ${endDate.toDateString()}`);

  const setCalendarOpenHandler = () => {
    setCalendarOpen(!calendarOpen);
  };

  const closeCalendarHandler = () => {
    setCalendarOpen(false);
  };

  const setDateRangeHandler = (firstDate: Date, lastDate: Date | undefined, dateRange: string | undefined) => {
    setStartDate(firstDate);
    if (lastDate) {
      setEndDate(lastDate);
      setRange(`${firstDate.toDateString()} - ${lastDate.toDateString()}`);
    }
    if (dateRange) {
      setRange(dateRange);
    }
    closeCalendarHandler();
  };

  return {
    hookStartDate: startDate,
    hookEndDate: endDate,
    hookDateRange: range,
    hookStartDateToUTCHours: toUTCHours(startDate, 'beginning').toISOString(),
    hookEndDateToUTCHours: toUTCHours(endDate, 'end').toISOString(),
    hookPreviousMonthStartDate: toUTCHours(getPreviousMonth(startDate, 'start'), 'beginning').toISOString(),
    hookPreviousMonthEndDate: toUTCHours(getPreviousMonth(startDate, 'end'), 'end').toISOString(),
    hookIsCalendarOpen: calendarOpen,
    hookOpenCalendar: setCalendarOpenHandler,
    hookCloseCalendar: closeCalendarHandler,
    hookSetDateRange: setDateRangeHandler,
  };
};
