export const INVITATION_HISTORY = {
  COLUMN: {
    ID: 'ID',
    COMPANY: 'Company Name',
    MESSAGE: 'Message',
    INVITATION_SENT: 'Invitation Sent',
    STATUS: 'Status',
  },
  BUTTON: {
    CSV: 'Download CSV',
    BACK: 'Back',
    CLEAR_FORM: 'Clear Form',
    CLOSE: 'Close',
    CANCEL: 'Cancel',
    TERMS: 'View Terms & Conditions',
  },
  LABEL: {
    FILTER: 'Filters',
  },
  SUBTITLE: 'Here you can view your sent publisher invitations.',
  SEARCH_PLACEHOLDER: 'Search by publisher name, ID, URL',
  REPORT_PLACEHOLDER: 'No invitations found',
  FILTER_OPTIONS: [
    {
      label: 'Approved',
      value: 'Approved',
    },
    {
      label: 'Closed By Merchant',
      value: 'Closed By Merchant',
    },
    {
      label: 'Closed By Publisher',
      value: 'Closed By Publisher',
    },
    {
      label: 'Invitation Declined',
      value: 'Declined',
    },
    {
      label: 'Invited',
      value: 'Pending',
    },
  ],
  MODAL: {
    HEADER: 'Invitation from',
    SUBJECT: 'Subject',
    MESSAGE: 'Message',
  },
};

export const INVITATION_HISTORY_CSV_COLUMNS = [
  { dataField: 'id', text: 'ID' },
  { dataField: 'companyName', text: 'COMPANY NAME' },
  { dataField: 'message', text: 'MESSAGE' },
  { dataField: 'createdAt', text: 'INVITATION SENT' },
  { dataField: 'status', text: 'STATUS' },
];
