import styled from 'styled-components';
import { colors, fonts } from '../../../../../../../styles/theme';

export const CellWrapper = styled.div`
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const WebsiteStyled = styled.a`
  ${fonts.lato.H14}
  text-decoration: none;
  color: ${colors.color2};
`;
