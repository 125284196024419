import React from 'react';
import {
  SECTION_TITLES, INFO_LABEL, EMPTY_STATE, BUTTON_LABEL, DISCLAIMER,
} from '../../contracts';
import * as Styled from '../../styles';

type ActiveProductCategoryStatsProps = {
  activeCategories: string[]
  newActiveCategories: string[]
  setModalOpen: (value: boolean) => void
  isReadOnly: boolean
}

export const ActiveProductCategoryStats = ({
  activeCategories,
  newActiveCategories,
  setModalOpen,
  isReadOnly,
}: ActiveProductCategoryStatsProps) => (
  <Styled.SectionStyled>
    <Styled.SectionTitleStyled>
      {SECTION_TITLES.PRODUCT_CATEGORY}
    </Styled.SectionTitleStyled>

    <Styled.SectionLabelStyled>
      {INFO_LABEL.SETTING}
    </Styled.SectionLabelStyled>
    {activeCategories.length < 1 ? (
      <Styled.SectionContentStyled>
        {EMPTY_STATE.CATEGORY}
      </Styled.SectionContentStyled>
    ) : (
      <Styled.SectionContentStyled>
        {activeCategories.join(', ')}
      </Styled.SectionContentStyled>
    )}
    <Styled.SectionLabelWrapperStyled>
      <Styled.SectionLabelStyled>
        {INFO_LABEL.NEW_SETTING}
      </Styled.SectionLabelStyled>
      <Styled.DisclaimerStyled>{DISCLAIMER}</Styled.DisclaimerStyled>
    </Styled.SectionLabelWrapperStyled>
    <Styled.SectionContentStyled>
      {newActiveCategories.join(', ')}
    </Styled.SectionContentStyled>

    <Styled.ButtonStyled
      onClick={() => setModalOpen(true)}
      theme="tertiary"
      disabled={isReadOnly}
    >
      {BUTTON_LABEL.UPDATE_CATEGORY}
    </Styled.ButtonStyled>
  </Styled.SectionStyled>
);
