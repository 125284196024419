import styled from 'styled-components';
import { Button, Select } from '../../../../../../components';
import { fonts } from '../../../../../../styles/theme';

export const WrapperStyled = styled.div`
  width: 500px;
  padding: 2rem 2rem 1.5rem;
`;

export const SelectStyled = styled(Select)`
  margin: 2rem 0;
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const TextStyled = styled.div`
  ${fonts.lato.H14}
  margin: 10px 0;
`;

export const BoldStyled = styled.p`
  ${fonts.lato.H14B}
  display: inline;
`;

export const TextAreaStyled = styled.div`
  margin: 30px 0 40px 0;
`;

export const SubtitleStyled = styled.div`
  margin: 20px 0;
  ${fonts.gotham.H22}
`;
