import { gql } from '@apollo/client';

export const GET_MERCHANTS = gql`
  query List($input: ListSearchCompanyInput) {
    listSearchMerchants(input: $input) {
      companies {
        id
        companyName
      }
    }
  }
`;
