import { gql } from '@apollo/client';

export const GET_GROUP_MEMBERS = gql`
  query groupMembers($input: ID!) {
  groupMembers(id: $input) {
    membershipId
    merchantId
    publisherId
    groupId
    startDate
    endDate
    current
    status
    websiteStatus
    publisherName
    publisherGroups
  }
}
`;
