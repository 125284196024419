import React from 'react';

import * as Styled from './styles';

type RadioProps = {
  name: string;
  value: string | number;
  label?: string;
  onChange: (value: number | string) => void;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  partial?: 'full' | 'half' | 'none';
}

export const Radio = ({
  onChange, value, name, label, checked, className, disabled = false, partial,
}: RadioProps) => (
  <Styled.WrapperStyled className={className}>
    <input
      name={name}
      value={value}
      type="radio"
      onChange={(e) => onChange(e.target.value)}
      checked={checked}
      disabled={disabled}
    />
    <Styled.BodyStyled
      disabled={disabled}
      partial={partial}
      checked={checked}
    >
      <Styled.InsideStyled />
    </Styled.BodyStyled>
    <Styled.LabelStyled>{label}</Styled.LabelStyled>
  </Styled.WrapperStyled>
);
