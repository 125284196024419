export const TRACKING_DETAILS = {
  TABS: {
    TRACKING_PROFILES: 'Tracking Profiles',
    TRACKING_DOMAINS: 'Tracking Domains',
  },
  DOWNLOAD_CSV: 'Download CSV',
  ADD_PROFILE: 'Add Profile',
  NO_PROFILES: 'No tracking profiles found',
  NO_DOMAINS: 'No tracking domains found',
  NO_DOMAINS_DESCRIPTION: 'Your tracking domain will show up here once a tracking event happens.',
};
