import React from 'react';
import * as Styled from './styles';
import { Spinner } from '../../../../../components';

type LoadingMerchantProps = {
  merchant: SelectOption
  loading: boolean
}

export const LoadingMerchant = ({ merchant, loading }: LoadingMerchantProps) => (
  <Styled.Wrapper loading={loading}>
    {`Getting ${merchant.label} information`}
    <Spinner theme="secondary" />
  </Styled.Wrapper>
);
