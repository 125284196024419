import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import {
  FORM_SECTION_TITLE, INPUT_LABEL, CURRENCY_OPTIONS, maskValue,
} from '../../contracts';
import * as Styled from '../../styles';
import { useAccountDetails } from '../../hooks';
import { PAYMENT_OPTIONS_ACCOUNT_DETAILS } from '../../../../../../utils';

type PublisherPaymentDetailsProps = {
  hook: ReturnType<typeof useAccountDetails>
  isReadOnly: boolean
}

export const PublisherPaymentDetails = ({ hook, isReadOnly }: PublisherPaymentDetailsProps) => (
  <Styled.ContentStyled>
    <Styled.SectionTitleStyled>
      {FORM_SECTION_TITLE.PAYMENT}
    </Styled.SectionTitleStyled>

    {isReadOnly ? (
      <>
        <Styled.InputTextStyled
          grids={2}
          theme="readonlyTernary"
          type="text"
          label={INPUT_LABEL.CURRENCY}
          value={hook?.hookPaymentCurrency}
          readonly
        />
        <Styled.InputTextStyled
          grids={hook?.hookPaymentMethod === 'Paypal' ? 4 : 2}
          theme="readonlyTernary"
          type="text"
          label={INPUT_LABEL.PAYMENT_METHOD}
          value={hook?.hookPaymentMethod}
          readonly
        />
      </>
    ) : (
      <>
        <Styled.SelectStyled
          selected={hook?.hookCurrencySelected}
          onChange={hook?.hookHandleCurrencySelected}
          options={CURRENCY_OPTIONS}
          label={INPUT_LABEL.CURRENCY}
          grids={2}
          isDisabled={isReadOnly || hook?.hookLoading}
          required
        />
        <Styled.SelectStyled
          label={INPUT_LABEL.PAYMENT_METHOD}
          selected={hook?.hookPaymentSelected}
          onChange={hook?.hookHandlePaymentSelected}
          options={PAYMENT_OPTIONS_ACCOUNT_DETAILS}
          grids={2}
          isDisabled={isReadOnly || hook?.hookLoading}
          required
        />
        <Styled.InfoBoxCurrency>
          <FontAwesomeIcon icon={faCircleExclamation} />
          <div>
            Minimum payout:
            &nbsp;
            {hook.hookCurrencySelected?.value}
            &nbsp;
            {hook.hookMinimumAmount}
          </div>
        </Styled.InfoBoxCurrency>
      </>
    )}

    {hook?.hookPaymentSelected?.value === 'Wire Transfer' && isReadOnly && (
    <>
      <Styled.InputTextStyled
        grids={2}
        theme="readonlyTernary"
        type="text"
        label={INPUT_LABEL.WIRE_TRANSFER_NAME}
        value={maskValue(hook?.hookWireTransfer)}
        readonly
      />
      <Styled.InputTextStyled
        grids={2}
        theme="readonlyTernary"
        type="text"
        label={INPUT_LABEL.BANK_NAME}
        value={maskValue(hook?.hookBankName)}
        readonly
      />
      <Styled.InputTextStyled
        grids={2}
        theme="readonlyTernary"
        type="text"
        label={INPUT_LABEL.BANK_ACCOUNT_NUMBER}
        value={maskValue(hook?.hookBankAccountNumber)}
        readonly
      />
      <Styled.InputTextStyled
        grids={1}
        theme="readonlyTernary"
        type="text"
        label={INPUT_LABEL.SWIFT_CODE}
        value={maskValue(hook?.hookSwift)}
        readonly
      />
      <Styled.InputTextStyled
        grids={1}
        theme="readonlyTernary"
        type="text"
        label={INPUT_LABEL.TRANSIT}
        value={maskValue(hook.hookTransit)}
        readonly
      />
    </>
    )}

    {hook?.hookPaymentSelected?.value === 'Wire Transfer' && !isReadOnly && (
    <>
      <Styled.InputTextStyled
        label={INPUT_LABEL.BANK_NAME}
        value={hook.hookBankName}
        onChange={hook.hookHandleBankName}
        grids={6}
        type="text"
        disabled={isReadOnly || hook?.hookLoading}
        required
        error={hook.hookErrors?.bankName}
      />
      <Styled.InputTextStyled
        label={INPUT_LABEL.BANK_ACCOUNT_NUMBER}
        value={hook.hookBankAccountNumber}
        onChange={hook.hookHandleBankAccountNumber}
        grids={6}
        type="text"
        disabled={isReadOnly || hook?.hookLoading}
        required
        error={hook.hookErrors?.bankAccountNumber}
      />
      <Styled.InputTextStyled
        label={INPUT_LABEL.SWIFT_CODE}
        value={hook.hookSwift}
        onChange={hook.hookHandleSwift}
        grids={6}
        type="text"
        disabled={isReadOnly || hook?.hookLoading}
        required
        error={hook.hookErrors?.swiftCode}
      />
      <Styled.InputTextStyled
        label={INPUT_LABEL.TRANSIT}
        value={hook.hookTransit}
        onChange={hook.hookHandleTransit}
        grids={6}
        type="text"
        disabled={isReadOnly || hook?.hookLoading}
      />
      <Styled.InputTextStyled
        label={INPUT_LABEL.WIRE_TRANSFER_NAME}
        value={hook.hookWireTransfer}
        onChange={hook.hookHandleWireTransfer}
        grids={6}
        type="text"
        disabled={isReadOnly || hook?.hookLoading}
        required
        error={hook.hookErrors?.wireTransferName}
      />
    </>
    )}

    {hook?.hookPaymentSelected?.value === 'Paypal' && isReadOnly && (
      <>
        <Styled.InputTextStyled
          grids={2}
          theme="readonlyTernary"
          type="text"
          label={INPUT_LABEL.ACCOUNT_HOLDER}
          value={maskValue(hook?.hookAccountHolder)}
          readonly
        />
        <Styled.InputTextStyled
          grids={2}
          theme="readonlyTernary"
          type="text"
          label={INPUT_LABEL.ACCOUNT_EMAIL}
          value={maskValue(hook?.hookAccountEmail)}
          readonly
        />
        <Styled.InputTextStyled
          grids={2}
          theme="readonlyTernary"
          type="text"
          label={INPUT_LABEL.ACCOUNT_PHONE_NUMBER}
          value={maskValue(hook?.hookAccountPhoneNumber)}
          readonly
        />
      </>
    )}

    {hook?.hookPaymentSelected?.value === 'Paypal' && !isReadOnly && (
    <>
      <Styled.InputTextStyled
        label={INPUT_LABEL.ACCOUNT_HOLDER}
        value={hook?.hookAccountHolder}
        onChange={hook.hookHandleAccountHolder}
        grids={6}
        type="text"
        required
        disabled={isReadOnly || hook?.hookLoading}
        error={hook.hookErrors?.accountHolder}
      />
      <Styled.InputTextStyled
        label={INPUT_LABEL.ACCOUNT_EMAIL}
        value={hook.hookAccountEmail}
        onChange={hook.hookHandleAccountEmail}
        grids={6}
        type="text"
        required
        disabled={isReadOnly || hook?.hookLoading}
        error={hook.hookErrors?.accountEmail}
      />
      <Styled.InputPhoneStyled
        label={INPUT_LABEL.ACCOUNT_PHONE_NUMBER}
        value={hook.hookAccountPhoneNumber}
        onChange={hook.hookHandleAccountPhoneNumber}
        grids={6}
        type="text"
        required
        disabled={isReadOnly || hook?.hookLoading}
        error={hook.hookErrors?.accountPhoneNumber}
      />
    </>
    )}

    {(hook?.hookPaymentSelected?.value === 'Cheque' || hook?.hookPaymentSelected?.value === 'Cheque Payment for $1000') && isReadOnly && (
    <Styled.InputTextStyled
      grids={2}
      theme="readonlyTernary"
      type="text"
      label={INPUT_LABEL.PAYABLE_TO}
      value={maskValue(hook?.hookPayableTo)}
      readonly
    />
    )}

    {(hook?.hookPaymentSelected?.value === 'Cheque' || hook?.hookPaymentSelected?.value === 'Cheque Payment for $1000') && !isReadOnly && (
    <Styled.InputTextStyled
      label={INPUT_LABEL.PAYABLE_TO}
      value={hook?.hookPayableTo}
      onChange={hook.hookHandlePaybleTo}
      grids={6}
      type="text"
      required
      disabled={isReadOnly || hook?.hookLoading}
      error={hook.hookErrors?.payableTo}
    />
    )}
  </Styled.ContentStyled>
);
