import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableProps } from '../../types';
import { CellCenterWrapper } from '../../styles';
import { Button } from '../../../../../../../../../components';
import { URLS_LIST } from '../../../enums';

export const Edit = ({
  val, row, setOpenModal, setModalInfo, isReadOnly,
}: TableProps) => (
  <CellCenterWrapper>
    <Button
      onClick={() => {
        setOpenModal();
        setModalInfo({
          id: row.id,
          manualUrl: row.url,
          checked: row.checked,
          type: URLS_LIST.MODAL.TYPES.EDIT,
        });
      }}
      theme="quaternary"
      disabled={isReadOnly}
    >
      <FontAwesomeIcon icon={val} />
    </Button>
  </CellCenterWrapper>
);
