import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../Button';
import {
  MERCHANT_PREFIX, path, dateFormatter, toRem,
} from '../../../utils';
import { CommissionFormatted } from '../../../utils/formatCommissions';
import { fonts, colors } from '../../../styles/theme';

const CellWrapperStyled = styled.div`
  color: ${colors.color17};
  ${fonts.lato.H16};
  min-height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  white-space: normal;
  gap: ${toRem(5)};

  b {
    ${fonts.lato.H16R};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    ${fonts.lato.H14};
    color: ${colors.color13};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CellFormatted = (val: any) => (
  <CellWrapperStyled>
    {val}
  </CellWrapperStyled>
);

const ActionCell = ({ comId } : {comId: any}) => {
  const navigate = useNavigate();

  const goTo = () => {
    navigate(`${MERCHANT_PREFIX}${path.editCommissions.href}`, { state: comId });
  };
  return (
    <CellWrapperStyled>
      <Button
        theme="quaternary"
        onClick={goTo}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </CellWrapperStyled>
  );
};

const members = (val: any, row: CommissionFormatted) => (
  <CellWrapperStyled>
    <b>
      {`${!row?.publishers?.length && !row?.publisherGroups?.length ? 'Default' : 'Custom'} ${val}`}
    </b>
    <p>
      {row.memberType}
    </p>
  </CellWrapperStyled>
);

const DateFormatted = (_: any, row: any) => (
  <CellWrapperStyled>
    <span>
      {`${dateFormatter(new Date(row?.startDate))} - ${dateFormatter(new Date(row?.endDate))}`}
    </span>
  </CellWrapperStyled>
);

export const columns = (editPermmissions: boolean) => {
  const basic = [
    {
      dataField: 'id',
      text: 'ID',
      width: editPermmissions ? '18%' : '20%',
      formatter: CellFormatted,
    },
    {
      dataField: 'commissionType',
      text: 'Commission/Members',
      width: editPermmissions ? '20%' : '30%',
      formatter: (value: any, row: any) => members(value, row),
    },
    {
      dataField: 'payable',
      text: 'Commission Payable',
      width: '24%',
      formatter: CellFormatted,
    },
    {
      dataField: 'startEndDate',
      text: 'Start/End Date',
      width: '26%',
      formatter: DateFormatted,
    },
  ];
  const withPermission = [...basic,
    {
      dataField: 'id',
      text: '',
      formatter: (value: any) => (
        <ActionCell comId={value} />
      ),
      width: '10%',
    },
  ];
  return (editPermmissions ? withPermission : basic);
};
