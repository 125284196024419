import React from 'react';
import * as Styled from './styles';
import {
  Button, Modal, Table,
} from '../../../../../../components';
import { FeedCategoryType } from '../RuleDetailsModal/hooks';
import { buildColumns } from './contracts';
import { useProductFeedModal } from './hooks';
import { PRODUCT_FEED_MODAL } from './enums';

type RuleDetailsModalProps = {
  closeModalFn: () => void
  isOpen: boolean
  tableData: FeedCategoryType | undefined
}

export const ProductFeedModal = ({
  closeModalFn, isOpen, tableData,
}: RuleDetailsModalProps) => {
  const hook = useProductFeedModal(tableData);
  return (
    <Modal isOpen={isOpen}>
      <Styled.FeedModalWrapperStyled>
        <Styled.TitleRow>
          <Styled.TitleText>
            {PRODUCT_FEED_MODAL.TITLE}
          </Styled.TitleText>
          <Button
            onClick={() => closeModalFn()}
            theme="secondary"
          >
            {PRODUCT_FEED_MODAL.BACK_BUTTON}
          </Button>
        </Styled.TitleRow>
        <Styled.FeedTableSectionStyled>
          <Table
            columns={buildColumns(hook.hookDisplayedData?.category || 'value')}
            data={hook.hookDisplayedData?.values}
            sortColumn={hook.hookSortColumn}
            onSort={hook.hookSortByHandler}
          />
        </Styled.FeedTableSectionStyled>
      </Styled.FeedModalWrapperStyled>
    </Modal>
  );
};
