import React from 'react';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Modal, Checkbox } from '../../../../../../components';
import * as Styled from './styles';
import { GENERATE_PAYMENT } from '../../enums';

type GenerateModalProps = {
  hooks:any
};

export const GenerateModal = ({
  hooks,
}: GenerateModalProps) => (
  <Modal isOpen={hooks.hookIsGenerateOpen}>
    <Styled.WrapperStyled>
      <Styled.HeaderWrapperStyled>
        <Styled.PageTitleStyled>{GENERATE_PAYMENT.GENERATE_MODAL.TITLE}</Styled.PageTitleStyled>
        <Styled.CheckWrapperStyled>
          <Checkbox
            checked={hooks.hookNotifyChecked}
            onChange={hooks.hookHandleNotifyChecked}
          />
          {GENERATE_PAYMENT.GENERATE_MODAL.CHECKBOX}
        </Styled.CheckWrapperStyled>
      </Styled.HeaderWrapperStyled>
      <Styled.TextStyled>
        {GENERATE_PAYMENT.GENERATE_MODAL.QUESTION}
      </Styled.TextStyled>
      <Styled.BodyWrapperStyled>
        <Styled.IconStyled icon={faCircleInfo} />
        <Styled.WordWrapperStyled>
          <Styled.BodyTitleStyled>
            {GENERATE_PAYMENT.GENERATE_MODAL.WARNING_TITLE}
          </Styled.BodyTitleStyled>
          <Styled.BodyParagraphStyled>
            {GENERATE_PAYMENT.GENERATE_MODAL.WARNING_BODY}
          </Styled.BodyParagraphStyled>
        </Styled.WordWrapperStyled>
      </Styled.BodyWrapperStyled>
      <Styled.ButtonAreaStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={hooks.hookGenerateCancel}
        >
          {GENERATE_PAYMENT.GENERATE_MODAL.CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          onClick={hooks.hookGenerateConfirm}
          loading={hooks.hookGenerateLoading}
          disabled={hooks.hookGenerateLoading}
        >
          {GENERATE_PAYMENT.GENERATE_MODAL.PAYMENTS}
        </Styled.ButtonStyled>
      </Styled.ButtonAreaStyled>
    </Styled.WrapperStyled>
  </Modal>
);
