import styled from 'styled-components';
import { colors, fonts } from '../../../styles/theme';
import { Spinner } from '../../Spinner';
import { Tooltip } from '../../Tooltip';
import { toRem } from '../../../utils';

type InputContainerProps = {
  readonly width: string;
}

type FakeListProps = {
  readonly open: boolean;
}

export const WrapperStyled = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => (width)};
`;

export const LabelStyled = styled.label`
  display: flex;
  flex-direction: column;
`;

export const LabelTextStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: ${toRem(11)};
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12B}
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
  margin-left: ${toRem(4)};
`;

export const ErrorStyled = styled.span`
  margin-top: 0.3rem;
  color: ${colors.color14};
  ${fonts.gotham.H12M}
`;

export const TooltipStyled = styled(Tooltip)`
  height: auto;

  div {
    margin-left: 2px;
  }
`;

export const SpinnerStyled = styled(Spinner)`
  margin-left: 0.5rem;
`;

export const DescriptionStyled = styled.span`
  color: ${colors.color13};
  ${fonts.lato.H14}
  margin-bottom: 0.5rem;
`;

export const FakeInputStyled = styled.div`
  text-align: left;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const FakeInputTextStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: transparent;
  border: 1px solid ${colors.color3};
  ${fonts.lato.H16}
  color: ${colors.color1};
  height: 50px;
  border-radius: 3px;
  cursor: pointer;
`;

export const FakeInputIconStyled = styled.span`
  border-left: 1px solid #ccc;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 0.4rem 0 0.8rem;
  color: #ccc;
  cursor: pointer;
`;

export const FakeInputOptionListStyled = styled.div<FakeListProps>`
  position: absolute;
  top: 50px;
  background: ${colors.color4};
  border-radius: 3px;
  border: 1px solid ${colors.color3};
  width: 100%;
  display: ${({ open }) => (open ? 'grid' : 'none')};
  grid-template-columns: repeat(7, 1fr);
  gap: 0.8rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
`;

export const FakeInputOptionStyled = styled.button`
  border: none;
  border-radius: 15px;
  background: transparent;
  padding: 0.3rem;
  ${fonts.lato.H16}
  color: ${colors.color1};
  cursor: pointer;

  &:hover {
    background: ${colors.color6};
  }
`;

export const FakeInputOptionLastStyled = styled.button`
  grid-column: span 7;
  border: none;
  border-top: 1px solid ${colors.color15};
  background: transparent;
  cursor: pointer;
  ${fonts.lato.H16}
  text-align: left;
  padding: 0.8rem;
  color: ${colors.color1};

  &:hover {
    background: ${colors.color6};
  }
`;

export const FakeInputLabelStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;
