import styled from 'styled-components';
import { Link, Select, Tabs } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

type LinkStyledProps = {
  readonly theme?: 'secondary'
}

export const TabsStyled = styled(Tabs)`
  margin-top: 2rem;
`;

export const FooterWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RecordsCountStyled = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${colors.color1};
    ${fonts.lato.H14}
  }
`;

export const CounterSelectStyled = styled(Select)`
  margin-left: 1rem;
  width: 100px;
`;

export const FooterButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 240px;
`;

export const TableDataStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 30px;
  height: 30px;
  align-items: flex-start;
  white-space: normal;
  justify-content: center;
  flex-direction: column;
  ${fonts.lato.H14};

  &:hover {
    color: ${colors.color2};
  }
`;

export const LinkStyled = styled(Link) <LinkStyledProps>`
  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return fonts.lato.H14;
      default:
        return fonts.lato.H12;
    }
  }};

  p {
    ${fonts.lato.H14};
    color: ${colors.color1};
    padding-bottom: ${toRem(4)};
  }
`;

export const TableSubtextStyled = styled.p`
  color: ${colors.color24};
  ${fonts.lato.H12};
  padding-top: ${toRem(4)};
`;

export const ButtonWrapperStyled = styled.div`
  margin-left: ${toRem(16)};
`;
