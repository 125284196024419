import styled from 'styled-components';
import { Button, Chip } from '../../../../../components';
import { fonts, colors } from '../../../../../styles/theme';

export const WrapperStyled = styled.div`
  width: 650px;
  padding: 1rem;
`;

export const DescriptionStyled = styled.div`
  margin: 2rem 0;
  color: ${colors.color1};
  ${fonts.lato.H16}
`;

export const ScrollFrameStyled = styled.div`
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid ${colors.color3};
  min-height: 400px;
  max-height: 400px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
  margin-right: 1rem;
`;

export const ChipStyled = styled(Chip)`
  margin-bottom: 1rem;
`;
