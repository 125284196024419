/* eslint-disable import/no-cycle */
import { monthToNumber } from '.';

// formats given number into 1st, 2nd, 3rd format.
export const dayFormatter = (d: any) => {
  if (d === 1 || d === 21 || d === 31) {
    return `${d}st`;
  }
  if (d === 2 || d === 22) {
    return `${d}nd`;
  }
  if (d === 3 || d === 23) {
    return `${d}rd`;
  }
  return `${d}th`;
};

export const getMonthName = (num: any) => {
  let m = '';
  switch (num) {
    case 1:
      m = 'January';
      break;
    case 2:
      m = 'February';
      break;
    case 3:
      m = 'March';
      break;
    case 4:
      m = 'April';
      break;
    case 5:
      m = 'May';
      break;
    case 6:
      m = 'June';
      break;
    case 7:
      m = 'July';
      break;
    case 8:
      m = 'August';
      break;
    case 9:
      m = 'September';
      break;
    case 10:
      m = 'October';
      break;
    case 11:
      m = 'November';
      break;
    case 12:
      m = 'December';
      break;
    default:
      m = '';
      break;
  }
  return m;
};

// Converts the date (with -) in the following format Month Day / Year (June 1st / 2022)
export const formatCommissionDate1 = (d: any) => {
  try {
    const date1 = d.split('-');
    const day1 = dayFormatter(Number(date1[2].split('T')[0]));
    const month1 = getMonthName(Number(date1[1]));
    const year1 = date1[0];

    const finalDate = `${month1} ${day1}/${year1}`;
    return finalDate;
  } catch (err) {
    console.log(err);
    // Something to log at Sentry
    return '';
  }
};

// Converts the date (with spaces) in the following format Month Day / Year (June 1st / 2022)
export const formatCommissionDate2 = (d: any) => {
  try {
    const date1 = d.split(' ');
    const day1 = dayFormatter(Number(date1[2]));
    const month1 = getMonthName(monthToNumber(date1[1]));
    const year1 = date1[3];
    const finalDate = `${month1} ${day1}/${year1}`;
    return finalDate;
  } catch (err) {
    // Something to log at Sentry
    console.log(err);
    return '';
  }
};
