import { PublisherMember } from '../types';

export const compareValues = (keyName: any, order: 'asc' | 'desc' | undefined = 'asc') => {
  const innerSort = (firstValue: any, secondValue: any) => {
    if (!Object.hasOwn(firstValue, keyName)) return -1;
    if (!Object.hasOwn(secondValue, keyName)) return 1;

    let varA;
    let varB;
    // Assumes the given array is already sorted for performance.
    if (Array.isArray(firstValue[keyName])) {
      varA = order === 'asc' ? firstValue[keyName][0] || '' : firstValue[keyName][firstValue[keyName].length - 1] || '';
      varB = order === 'asc' ? secondValue[keyName][0] || '' : secondValue[keyName][secondValue[keyName].length - 1] || '';
    } else {
      varA = typeof firstValue[keyName] === 'string' ? firstValue[keyName].toUpperCase().trim() : firstValue[keyName];
      varB = typeof secondValue[keyName] === 'string' ? secondValue[keyName].toUpperCase().trim() : secondValue[keyName];
    }

    let comparison = 0;

    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    return order === 'desc' ? comparison * -1 : comparison;
  };

  return innerSort;
};

export const sortMemberships = (memberships: PublisherMember[], dataField: string, direction: 'asc' | 'desc') => {
  const membershipsList = [...memberships];
  const sortFunction = compareValues(dataField, direction);
  membershipsList.sort(sortFunction);
  return membershipsList;
};
