import { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { useModal } from '../../../../../../../../hooks';
import { useGraphs } from '../../hooks';
import { colors } from '../../../../../../../../styles/theme';
import { SUMMARY_REPORT_DROPDOWN_ACTIONS } from '../../../../../Components/SummaryReport/Reducers';
import { dateFormatter } from '../../../../../../../../utils';

export const usePublisherChart = (hook: any) => {
  const [isModalOpen, setIsModalOpen] = useModal();
  const [graphData, setGraphData] = useState<any[]>([]);
  const { hookLoadingOverview, hookPublisherData } = useGraphs();

  useEffect(() => {
    if (graphData.length > 0) {
      const root = am5.Root.new('publisherCheckGraph');
      root.setThemes([am5themesAnimated.new(root)]);

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          layout: root.verticalLayout,
        }),
      );
      chart.get('colors')?.set('colors', [
        am5.color(colors.color37),
        am5.color(colors.color38),
        am5.color(colors.color14),
        am5.color(colors.color39),
        am5.color(colors.color12),
      ]);

      const legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
          layout: root.gridLayout,
          maxWidth: 600,
        }),
      );
      legend.labels.template.setAll({
        fontSize: 10,
      });

      const tooltip = am5.Tooltip.new(root, {
        autoTextColor: false,
      });
      tooltip.label.setAll({
        fill: am5.color(0xffffff),
        fontSize: 12,
      });

      const y = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          max: 100,
          calculateTotals: true,
          numberFormat: '#',
          renderer: am5xy.AxisRendererY.new(root, {}),
          paddingLeft: -10,
        }),
      );

      y.children.unshift(am5.Label.new(root, {
        text: 'Percentage Of Publisher',
        textAlign: 'center',
        y: am5.p50,
        rotation: -90,
        fontSize: 10,
        paddingLeft: 0,
        paddingTop: 0,
      }));

      const x = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          categoryField: 'date',
        }),
      );
      x.data.setAll(graphData);

      const createSeries = (name: string, field: string) => {
        const series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name,
            xAxis: x,
            yAxis: y,
            valueYField: field,
            valueYShow: 'valueYTotalPercent',
            categoryXField: 'date',
            stacked: true,
            tooltip,
          }),
        );
        series.columns.template.setAll({
          tooltipText: '{name}: {valueYTotalPercent.formatNumber(\'#.#\')}% ({valueY})',
        });

        legend.data.push(series);
        series.data.setAll(graphData);

        series.bullets.push(() => {
          const label = am5.Label.new(root, {
            text: '{valueYTotalPercent.formatNumber(\'#.#\')}%',
            fill: am5.color(0xFFFFFF),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true,
            fontSize: 11,
          });

          // Dynamicaly turn labels on or off depending on size
          label.adapters.add('text', (text, target) => {
            if (target.dataItem) {
              const value = target.dataItem.get(('valueYTotalPercent' as any), 0);
              if (value > 7) {
                return text;
              }
            }
            return '';
          });

          const newBullet = (am5.Bullet.new(root, {
            locationY: 0.5,
            locationX: 0.5,
            sprite: label,
          }));

          return newBullet;
        });

        series.columns.template.events.on('click', (e: any) => {
          const { date } = e.target.dataItem.dataContext;
          const status = e.target.root.dom.outerText.split(':')[0].replace('Check ', '');
          hook.hookFiltersDispatch({
            label: dateFormatter(new Date(date), ','),
            value: date,
          }, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_LAST_CHECK);
          hook.hookFiltersDispatch({
            label: status,
            value: status,
          }, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_STATUS);
          hook.hookFiltersDispatch({
            label: 'All Publishers',
            value: '',
          }, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PUBLISHER);
          hook.hookHandleGenerate(status, date);
        });
      };

      createSeries('Check Pass', 'checkPassVal');
      createSeries('Check Review', 'checkReviewVal');
      createSeries('Check Fail', 'checkFailVal');
      createSeries('Check Incomplete', 'checkIncompleteVal');
      createSeries('Check Not Applicable', 'checkNotApplicableVal');
    }
  }, [graphData]);

  useEffect(() => {
    setGraphData(hookPublisherData);
  }, [hookPublisherData]);

  return {
    hookGraphLoading: hookLoadingOverview,
    hookIsModalOpen: isModalOpen,
    hookSetIsModalOpen: setIsModalOpen,
  };
};
