import styled from 'styled-components';
import { colors, fonts } from '../../../styles/theme';

export const CPStyled = styled.div`
  display: flex;
  margin-bottom: 1.1rem;
  font-size: 1rem;
  font-weight: bold;
  font-family: gotham_promedium;
`;

export const CPHeader = styled.div`
  display: flex;
  margin-right: 0.4rem;
  text-transform: uppercase;
  font-weight: bold;
  color: ${colors.color1}
`;

export const CPValue = styled.div`
  display: flex;
  font-weight: bold;
  color: ${colors.color1}
`;
