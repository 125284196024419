import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { LIST_NEW_PROGRAMS, LIST_PROGRAMS } from '../graphql/queries';
import { useUserInfo } from '../../../../../hooks';

export const useSearchProgram = () => {
  const { hookWhoAmI } = useUserInfo();
  const [search, setSearch] = useState('');
  const [targetMarket, setTargetMarket] = useState<SelectOption>();
  const [productCategory, setProductCategory] = useState<SelectOption>();
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'id', direction: 'asc' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [newPrograms, setNewPrograms] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [getPrograms, { loading }] = useLazyQuery(LIST_PROGRAMS);
  const [getNewPrograms] = useLazyQuery(LIST_NEW_PROGRAMS);

  const getProgramsHandler = async () => {
    const { data } = await getPrograms({
      variables: {
        input: {
          filter: {
            publisherId: hookWhoAmI.companyId?.toString(),
            q: search,
            country: targetMarket?.value,
            productCategory: productCategory?.value,
          },
          options: {
            page: currentPage,
            order: sortColumn.direction?.toUpperCase(),
          },
          sortBy: sortColumn.column,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.listPublisherPrograms !== undefined) {
      const programsData = data.listPublisherPrograms.programs.map((program: any) => ({
        img: program.company.companyImgUrl,
        id: program.merchantId,
        companyName: {
          name: program.company.companyName,
          url: program.company.companyUrl,
        },
        productCategories: program.productCategories || [],
        targetMarket: program.targetMarkets || [],
        membershipStatus: program.publisherMembershipStatus,
        programId: program.id,
      }));
      setTableData(programsData);
      setTotalPages(Math.ceil(data.listPublisherPrograms.count / 10));
    } else {
      setTableData([]);
      setTotalPages(1);
    }
  };

  const getNewProgramsHandler = async () => {
    const { data } = await getNewPrograms({
      variables: {
        input: {
          filter: {
            publisherId: hookWhoAmI.companyId?.toString(),
          },
        },
      },
    });

    if (data?.listPublisherNewPrograms?.newPrograms !== undefined) {
      setNewPrograms(data.listPublisherNewPrograms.newPrograms);
    }
  };

  const setSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const setTargetHandler = (e: SelectOption) => {
    setTargetMarket(e);
  };

  const setCategoryHandler = (e: SelectOption) => {
    setProductCategory(e);
  };

  const setPageHandler = (page: number) => {
    setCurrentPage(page);
  };

  const setSortColumnHandler = (dataField: string, direction: any) => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const clearFormHandler = () => {
    setSearch('');
    setTargetMarket(undefined);
    setProductCategory(undefined);
    setCurrentPage(1);
  };

  useEffect(() => {
    getNewProgramsHandler();
  }, []);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      getProgramsHandler();
    }
  }, [hookWhoAmI.companyId, search, productCategory, targetMarket]);

  useEffect(() => {
    getProgramsHandler();
  }, [hookWhoAmI.companyId, currentPage, sortColumn]);

  return {
    hookLoading: loading,
    hookData: tableData,
    hookNewPrograms: newPrograms,
    hookSearch: search,
    hookSetSearch: setSearchHandler,
    hookTargetMarket: targetMarket,
    hookSetTargetMarket: setTargetHandler,
    hookProductCategory: productCategory,
    hookSetProductCategory: setCategoryHandler,
    hookPage: currentPage,
    hookChangePage: setPageHandler,
    hookTotalPages: totalPages,
    hookSortTable: setSortColumnHandler,
    hookSortColumn: sortColumn,
    hookClearForm: clearFormHandler,
  };
};
