import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../../../../../styles/theme';
import { toRem, dateFormatter } from '../../../../../../utils';
import { formatAmount } from '../../../../../../utils/amountFormatter';

type IconStyledProps = {
  isCompleted: boolean
}

const IconStyled = styled(FontAwesomeIcon)<IconStyledProps>`
    color: ${({ isCompleted }) => (isCompleted ? colors.color29 : colors.color30)};
    font-size: ${toRem(16)};
    padding-right: ${toRem(12)};
`;

const StatusContainer = styled.div`
    width: fit-content;
    display: flex;
    justify-content: space-between;
    height: ${toRem(32)};
    align-items: center;
`;

const StatusFormatted = (value: any) => {
  const status = value.charAt(0) + value.slice(1).toLowerCase();
  const isCompleted = status === 'Completed';
  const isDenied = status === 'Denied';
  return (
    <StatusContainer>
      {isCompleted && (
      <IconStyled
        icon={faCircleCheck}
        isCompleted={isCompleted}
      />
      )}
      {isDenied && (
      <IconStyled
        icon={faCircleXmark}
        isCompleted={false}
      />
      )}
      {status}
    </StatusContainer>
  );
};

export const columns: TableColumn[] = [
  {
    dataField: 'paymentId',
    text: 'payment id',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '14%',
  },
  {
    dataField: 'amount',
    text: 'deposit amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{formatAmount(value)}</div>,
    width: '14%',
  },
  {
    dataField: 'currency',
    text: 'currency',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '14%',
  },
  {
    dataField: 'status',
    text: 'status',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      StatusFormatted(value)
    ),
    width: '14%',
  },
  {
    dataField: 'createdAt',
    text: 'created at',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (<h1>{dateFormatter(new Date(value))}</h1>),
    width: '14%',
  },
  {
    dataField: 'updatedAt',
    text: 'updated at',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (<h1>{dateFormatter(new Date(value))}</h1>),
    width: '14%',
  },
  {
    dataField: 'notes',
    text: 'note',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '14%',
  },
];
