import { colors } from '../styles/theme';

export const useToast = () => {
  const showToast = (message: string, link?: string, linkText = 'View Template') => {
    const existingToasts = document.querySelector('.toast-auto');

    if (existingToasts) {
      existingToasts.remove();
    }

    const element = document.createElement('div');
    element.className = 'toast-auto';
    element.innerText = message;
    element.style.position = 'fixed';
    element.style.bottom = '-100px';
    element.style.left = '50%';
    element.style.transform = 'translateX(-50%)';
    element.style.zIndex = '9999';
    element.style.background = colors.color6;
    element.style.color = colors.color1;
    element.style.transition = 'all 0.3s ease-in-out';
    element.style.width = '760px';
    element.style.padding = '15px 20px';
    element.style.borderRadius = '5px';
    element.style.fontFamily = 'latoregular';
    document.body.appendChild(element);

    if (link !== undefined) {
      const aTag = document.createElement('a');
      aTag.innerText = linkText;
      aTag.setAttribute('href', link);
      aTag.style.padding = '0 8px';
      aTag.style.fontWeight = '700';
      aTag.style.color = 'rgba(247, 141, 45)';
      aTag.style.textDecoration = 'none';
      element.appendChild(aTag);
    }

    setTimeout(() => {
      element.style.bottom = '3rem';
    }, 100);

    setTimeout(() => {
      element.style.transform = 'translate(-50%, 400%)';
    }, 3000);

    setTimeout(() => {
      if (document.body.contains(element)) document.body.removeChild(element);
    }, 3500);
  };

  return {
    hookShowToast: showToast,
  };
};
