import { gql } from '@apollo/client';

export const UPDATE_PROGRAM = gql`
  mutation updateProgram($input: ProgramInput) {
    updateProgram(input: $input) {
      program {
        id
        descriptionHighLevel
        signupBonus
        membersOffer
        CookieDurationVisibility
        membersOfferVisibility
        statsFrequencyVisibility
        CookieDuration
        statsFrequency
        primaryContact
        primaryContactEmail
        primaryContactPhone
        productMatrixUrl
        termsConditionsTitle
        termsConditions
        updatedAt
        updatedBy {
          firstName
          lastName
        }
      }
    }
  }
`;
