import { gql } from '@apollo/client';

export const UPDATE_PROGRAM = gql`
  mutation updateProgram($input: ProgramInput) {
    updateProgram(input: $input) {
      program {
        id
      }
    }
  }
`;
