import React from 'react';
import { FormatedCell } from '../../../components/FormatedCell';
import { ManagementThumbnails } from '../../../../../../components';
import { SelectProduct } from '../../../components/SelectProduct';
import { PRODUCTS } from '../enum';

export const columns: TableColumn[] = [
  {
    dataField: 'customizedProductId',
    text: PRODUCTS.COLUMNS.PRODUCT_ID,
    formatter: (value: any, row: any) => (
      <FormatedCell>
        <ManagementThumbnails
          value={value}
          url={row.productImageUrl}
        />
      </FormatedCell>
    ),
    width: '24%',
  },
  {
    dataField: 'name',
    text: PRODUCTS.COLUMNS.PRODUCT_NAME,
    formatter: (value: any, row: any) => (
      <FormatedCell
        value={value}
        secondValue={row.status}
      />
    ),
    width: '26%',
  },
  {
    dataField: 'productCategory',
    text: PRODUCTS.COLUMNS.PRODUCT_CATEGORIES,
    formatter: (value: any) => (
      <FormatedCell
        value={value}
        secondValue={value?.subcategories?.join(', ')}
      />
    ),
    width: '35%',
  },
  {
    dataField: 'updatedAt',
    text: PRODUCTS.COLUMNS.LAST_UPDATED,
    formatter: (value: any, row: any, _:any, __:any, ___:any, ____, setModalInfo: (product: Record<string, any>) => void) => (
      <FormatedCell>
        <SelectProduct
          handleArrowClick={setModalInfo}
          date={value}
          value={row.id}
        />
      </FormatedCell>
    ),
    width: '15%',
  },
];
