import styled from 'styled-components';
import { colors, fonts } from '../../styles/theme';

export const PlaceholderStyled = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0;
  ${fonts.lato.H26R}
  color: ${colors.color1};

  img {
    width: 180px;
  }
`;
