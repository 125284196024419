import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { csvGenerator, dateFormatter, paginator } from '../../../../../utils';
import { GET_DETAILED_RECORD } from '../graphql/getDetailedRecord';
import { COLUMNS, AMOUNTS, MERCHANT_COLUMNS } from '../enums';
import exportCSV from '../../../../../utils/exportCsv';

type detailedPayment = {
    merchant: string,
    publisher: string,
    transactionId: string,
    orderId: string,
    recordDate: string,
    productName: string,
    grossSales: string
    netSales: string,
    commissionId: string,
    recordType: string,
    amount: string,
    date: Date
}

export const useDetailedPayments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    month, year, publisher, publisherId, merchantId, previousPath,
  } = location.state;

  const [tableData, setTableData] = useState<any[]>([]);
  const [rawCommissions, setRawCommissions] = useState<any[]>([]);
  const [getDetailedPayments, { loading: detailedPaymentsLoading }] = useLazyQuery(GET_DETAILED_RECORD);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const [footerValues, setFooterValues] = useState<any>({ grossSales: 0, netSales: 0, amounts: 0 });
  const [dataLoad, setDataLoad] = useState(false);

  const FormatTableData = (data: any) => {
    const tData: detailedPayment[] = [];
    data.forEach((payment: any) => {
      const { rate = 1 } = payment;
      payment.transactions.forEach((trans:any) => {
        const findCommission = trans.commissionsDetailedAmount.find((commission: any) => (commission.commissionId === payment.commissionStructure.id));
        const { commissionAmount } = findCommission;
        const currOBJ: detailedPayment = {
          merchant: `${payment.merchant.id} - ${payment.merchant.companyName}`,
          publisher,
          commissionId: payment.commissionStructure.id,
          recordType: payment.commissionType.replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2'),
          amount: ((Number(commissionAmount) || 0) * rate).toFixed(3),
          orderId: trans.orderId,
          recordDate: dateFormatter(new Date(trans?.createdAt)),
          productName: trans.product?.name || 'All Products',
          transactionId: trans.id,
          netSales: trans.netSale,
          grossSales: trans.grossSale,
          date: new Date(trans.createdAt),
        };
        if (trans.commissionsDetailedAmount.length !== 0) tData.push(currOBJ);
      });
      if (payment.transactions.length === 0) {
        tData.push({
          merchant: `${payment.merchant.id} - ${payment.merchant.companyName}`,
          publisher,
          commissionId: payment.commissionStructure?.id || '',
          recordType: payment.commissionType.replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2'),
          amount: payment.totalCommissions,
          orderId: '',
          recordDate: dateFormatter(new Date(payment.createdAt)),
          productName: '',
          transactionId: '',
          netSales: '0',
          grossSales: '0',
          date: new Date(),
        });
      }
    });
    return tData;
  };

  const handleBack = () => {
    if (previousPath === undefined) {
      navigate(-1);
    } else {
      navigate(previousPath, {
        state: {
          context: true,
        },
      });
    }
  };

  const handleFooterValues = (value: any) => {
    const FooterData = { ...footerValues };
    value.forEach((row: any) => {
      FooterData.grossSales += Number(row.grossSales);
      FooterData.netSales += Number(row.netSales);
      FooterData.amounts += Number(row.amount);
    });
    setFooterValues(FooterData);
  };

  const getRawCommissionDetails = async () => {
    const { data } = await getDetailedPayments({
      variables: {
        input: {
          publisherId,
          merchantId,
          month,
          year,
        },
      },
      fetchPolicy: 'no-cache',
    });
    setDataLoad(true);
    if (data?.rawCommissions !== undefined) {
      setRawCommissions(FormatTableData(data.rawCommissions.rawCommissions));
      handleFooterValues(FormatTableData(data.rawCommissions.rawCommissions));
      setTableData(paginator(FormatTableData(data.rawCommissions.rawCommissions), 10, 1));
      setTotalPages(Math.ceil(FormatTableData(data.rawCommissions.rawCommissions).length / 10));
      setCurrentPage(1);
    } else {
      setErrorMessage('Failed to fetch detailed payment records');
    }
  };

  const onSort = (dataField: string, direction = 'asc') => {
    const nextDirection: number = direction === 'asc' ? 1 : -1;
    const compare = (a: any, b: any) => {
      let compareCondition = false;
      if (AMOUNTS.includes(dataField)) {
        compareCondition = Number(a[dataField]) < Number(b[dataField]);
      } else if (dataField === 'recordDate') {
        compareCondition = a.date < b.date;
      } else {
        compareCondition = a[dataField] < b[dataField];
      }
      return compareCondition ? nextDirection : nextDirection * -1;
    };
    const copyArray = [...rawCommissions];
    const sortedArray = copyArray.sort((a, b) => compare(a, b));
    setRawCommissions(sortedArray);
    setTableData(paginator(sortedArray, 10, 1));
    setSortColumn({ column: dataField, direction: sortColumn?.direction === 'desc' ? 'asc' : 'desc' });
    setCurrentPage(1);
  };

  const handleChangePage = (value: any) => {
    setTableData(paginator(rawCommissions, 10, value));
    setCurrentPage(value);
  };

  const exportPaymentCsv = (isMerchant = false) => {
    const unpaginated = [...rawCommissions];
    const columns = isMerchant ? MERCHANT_COLUMNS : COLUMNS;
    const formattedArray = unpaginated.map((item) => {
      const newItem = { ...item };
      columns.forEach((column: any) => {
        let data;
        if (AMOUNTS.includes(column.dataField)) {
          data = Number(item[column.dataField]).toFixed(2);
        } else {
          data = item[column.dataField];
        }
        newItem[column.dataField] = typeof data === 'string' ? data.replace(/,/g, '') : data;
      });
      return newItem;
    });
    const csv = exportCSV(columns, formattedArray);
    return csv;
  };

  const downloadCSV = () => {
    csvGenerator(exportPaymentCsv(), 'detailed-Payments.csv');
  };

  const downloadMerchantCSV = () => {
    csvGenerator(exportPaymentCsv(true), 'detailed-Payments.csv');
  };

  useEffect(() => {
    getRawCommissionDetails();
  }, []);

  return {
    hookTableData: tableData,

    hookCurrentPage: currentPage,

    hookTotalPages: totalPages,

    hookLoading: detailedPaymentsLoading,

    hookDataLoad: dataLoad,

    hookErrorMessage: errorMessage,

    hookDownloadCSV: downloadCSV,

    hookDownloadMerchantCSV: downloadMerchantCSV,

    hookHandleBack: handleBack,

    hookHandleChangePage: handleChangePage,

    hookOnsort: onSort,

    hookFooterValues: footerValues,

    hookSort: sortColumn,
  };
};
