import { gql } from '@apollo/client';

export const UPDATE_FINTEL_CHECK_VISIBILITY = gql`
  mutation updateMerchantVisibility($input: CompanyInput!) {
    updateMerchantVisibility(input: $input) {
      id
      companyName
      companyType
    }
  }
`;

export type UpdateFintelCheckVisibilityInput = {
  input: {
    id: string
    visibleToFintelCheck: boolean
  }
}

export type UpdateFintelCheckVisibilityOutput = {
  updateMerchantVisibility: {
    id: string
    companyName: string
    companyType: string
  }
}
