import styled from 'styled-components';
import { colors, fonts } from '../../../../styles/theme';
import { Button } from '../../../../components';

export const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const ValueStyled = styled.p`
  ${fonts.lato.H16R}
  color: ${colors.color1};
  white-space: normal;
  line-height: 1.5;
`;

export const DescriptionStyled = styled.p`
  margin-top: 10px;
  ${fonts.lato.H14R}
  color: ${colors.color7};
`;

export const ScheduleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

export const ButtonStyled = styled(Button)`
  padding: 0;
  border: none;
  margin-right: 10px;

  :hover {
    border: none;
    color: ${colors.color2};
    background-color: ${colors.transparent};
  }
`;
