import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../../../styles/theme';

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeftStyled = styled(HeaderStyled)`
  justify-content: flex-start;
  gap: 1rem;
`;

export const DateStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.color19};
  color: ${colors.color1};
  padding: 0.5rem;
  ${fonts.lato.H12}
  border-radius: 4px;
  margin-left: 1rem;
`;

export const ReportWrapper = styled.div`
  height: calc(100% - 70px);
  margin-top: 2rem;
  padding: 2rem 1rem 1rem 1rem;
  background-color: ${colors.color4};
`;

export const ReportTitleStyled = styled.h3`
  ${fonts.gotham.H20M}
  color: ${colors.color1};
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
  white-space: nowrap;
  margin-top: 2rem;
`;

export const TableWrapper = styled.div`
  margin-top: 2rem;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;
