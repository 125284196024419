import styled from 'styled-components';
import { colors, fonts } from '../../../../../../styles/theme';

export const CellWrapperStyled = styled.div`
  display: flex;
  min-height: 60px;
  align-items: center;
  flex-flow: wrap row;
`;

export const FirstLineStyled = styled.div`
  ${fonts.lato.H16R};
  overflow: hidden;
  white-space: none;
  text-overflow: ellipsis;
  margin-bottom: 8px;
`;

export const SecondLineStyled = styled.div`
  color: ${colors.color16};
  ${fonts.lato.H14};
  width: 100%;
  overflow: hidden;
  white-space: none;
  text-overflow: ellipsis;
  
  a {
    text-decoration: none;
    color: ${colors.color7};
    &:hover {
      color: ${colors.color2};
    }
  }
`;
