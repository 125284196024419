import { gql } from '@apollo/client';

export const TRACKINGS_PROFILE = gql`
  query listTrackings($input: ListSearchTrackingInput!) {
    listTrackings(input: $input) {
      trackings {
        id
        companyId
        primaryWebsite
        profileName
        trackingProfileType
        websiteDescription
        trackingStatus
        createdAt
        lastActive
      }
      trackingsCount
    }
  }
`;
