import React from 'react';

import {
  Button, AppWrapper, PageTitle, Tabs,
  Checkbox,
  ErrorBanner,
} from '../../../../../components';
import { path, USER_TYPES_ID } from '../../../../../utils';
import { useEditProduct } from './hooks/useEditProduct';
import ProductInformation from './Tabs/ProductInformation';
import { EDIT_PRODUCT } from './enums';
import editProductTabs from './Tabs/ProductFeed';
import CommissionsTab from '../../../../../components/CommissionsTab';
import { DefaultPropsType } from '../../../../../types';
import { useUserInfo } from '../../../../../hooks';
import * as Styled from './styles';

type EditProductProps = DefaultPropsType;

const EditProduct = ({ permissionsCodeList = [] }: EditProductProps) => {
  const { hookIsReadOnlyList, ...hook } = useEditProduct(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;
  const userHook = useUserInfo();
  const isMerchant = (userHook.hookWhoAmI?.isActingAsUserTypeId === undefined)
  && (userHook.hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT);

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        isOpen={hook.hookIsError}
        message={hook.hookErrorDisplay}
      />
      <Styled.HeaderStyled>
        <PageTitle>{path.editProduct.name}</PageTitle>
        <Styled.RightStyled>
          <Styled.ButtonsWrapperStyled>
            <Button
              theme="secondary"
              onClick={hook.hookHandleCancel}
            >
              {EDIT_PRODUCT.CANCEL}
            </Button>
            <Button
              onClick={hook.hookUpdateHandler}
              loading={hook.hookUpdateLoading}
              disabled={hook.hookIsUpdateDisabled || hook.hookUpdateLoading || isReadOnly}
            >
              {EDIT_PRODUCT.UPDATE_PRODUCT}
            </Button>
          </Styled.ButtonsWrapperStyled>
          {!isMerchant && (
          <Styled.CheckBoxWrappperStyled>
            <Checkbox
              checked={hook.hookIsChecked}
              onChange={hook.hookhandleSetIsChecked}
              disabled={isReadOnly}
            />
            {EDIT_PRODUCT.NOTIFY_PUBLISHERS}
          </Styled.CheckBoxWrappperStyled>
          )}
        </Styled.RightStyled>
      </Styled.HeaderStyled>

      <Tabs
        tabNames={EDIT_PRODUCT.FEED.includes(hook.hookProductCategory) ? EDIT_PRODUCT.TAB_NAMES : EDIT_PRODUCT.TAB_NAMES_NO_FEED}
        elements={EDIT_PRODUCT.FEED.includes(hook.hookProductCategory) ? [
          <ProductInformation
            hook={hook.hookProductInformation}
            isReadOnly={isReadOnly}
          />,
          editProductTabs(hook.hookProductCategory, hook.hookProductFeed, isReadOnly),
          CommissionsTab(hook.hookCommissionshook, true),
        ] : [
          <ProductInformation
            hook={hook.hookProductInformation}
            isReadOnly={isReadOnly}
          />,
          CommissionsTab(hook.hookCommissionshook, true),
        ]}
      />
    </AppWrapper>
  );
};

export default EditProduct;
