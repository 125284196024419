export const URL_UPLOAD = {
  DESCRIPTION: 'Upload a List of URLs to Be Added to the Manual URLs List.',
  INSTRUCTION: 'Each url should be on its own row in the file, and be the full path to the page that will be checked.',
  SAMPLE_TITLE: 'A sample file might look like:',
  SAMPLE_1: 'https://www.fintelconnect.com/contact/',
  SAMPLE_2: 'https://www.fintelconnect.com/brands/',
  LABEL: 'Upload CSV or TXT',
  TOAST: {
    SUCCESS: 'File uploaded successfully.',
    ERROR: 'Error uploading file.',
    INVALID: 'Error adding Manual URLs. Please check for duplicate or invalid URLs.',
  },
};
