import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { BUTTON_LABEL, FORM_SECTION_TITLE, INPUT_LABEL } from '../../contracts';
import { Button, InputTextarea } from '../../../../../../components';
import { PUBLISHER_API_ACCESS_STATUS } from '../../../../../../utils';
import * as Styled from '../../styles';
import { useAccountDetails } from '../../hooks';
import environment from '../../../../../../config/environment';

type PublisherApiAccessKeyProps = {
  hook: ReturnType<typeof useAccountDetails>
  isReadOnly: boolean
}

const tooltipDetails = (
  <span>
    Click
    {' '}
    <Styled.LinkStyled
      href={`${environment.app.kbUrl}/article?id=63d22f3e4525cf004bb7cfbb`}
      target="_blank"
      rel="noreferrer"
    >
      here
    </Styled.LinkStyled>
    {' '}
    to view instructions on how to use the script
  </span>
);

export const PublisherApiAccessKey = ({ hook, isReadOnly }: PublisherApiAccessKeyProps) => (
  <Styled.ContentStyled>
    <Styled.SectionTitleStyled>
      {FORM_SECTION_TITLE.ACCESS_KEY}
    </Styled.SectionTitleStyled>

    <Styled.InputTextStyled
      grids={4}
      type="text"
      label={INPUT_LABEL.GENERATE_ACCESS_KEY}
      value={hook?.hookApiKey}
      disabled
    />
    <Styled.SelectStyled
      isLoading={hook.hookUpdateApiLoading}
      grids={2}
      name="apiAccess"
      label={INPUT_LABEL.ACCESS_STATUS}
      options={PUBLISHER_API_ACCESS_STATUS}
      onChange={hook?.hookSetApiAccess}
      selected={hook?.hookApiAccess}
      isDisabled={isReadOnly || hook?.hookLoading || hook.hookUpdateApiLoading}
    />

    <Styled.GridBoxStyled
      grids={4}
      justify="space-between"
    >
      <Button
        theme="tertiary"
        onClick={hook?.hookHandleApiModal}
        disabled={isReadOnly}
      >
        {BUTTON_LABEL.GENERATE_NEW_KEY}
      </Button>
      <Button
        theme="tertiary"
        onClick={hook.hookHandleKey}
      >
        <Styled.IconStyledStyled>
          <FontAwesomeIcon icon={faCopy} />
        </Styled.IconStyledStyled>
        {hook.hookApiKeyText}
      </Button>
    </Styled.GridBoxStyled>

    <Styled.ApiCaption color={hook?.hookApiCaption.color}>
      {hook?.hookApiCaption.message !== '' && hook?.hookApiCaption.message}
    </Styled.ApiCaption>

    <Styled.GridBoxStyled
      grids={3}
      justify="flex-start"
    >
      <InputTextarea
        label={INPUT_LABEL.TRANSACTION_DETAIL}
        name="details"
        value={hook?.hookTransactionApiUrl}
        theme="readonlySecondary"
        readonly
        disabled
      />
    </Styled.GridBoxStyled>

    <Styled.GridBoxStyled
      grids={3}
      justify="flex-start"
    >
      <InputTextarea
        label={INPUT_LABEL.ACID}
        name="ACID"
        value={hook?.hookClickApiUrl}
        theme="readonlySecondary"
        readonly
        disabled
      />
    </Styled.GridBoxStyled>

    <Styled.GridBoxStyled
      grids={3}
      justify="flex-end"
    >
      <Button
        theme="tertiary"
        onClick={hook.hookHandleTransaction}
      >
        <Styled.IconStyledStyled>
          <FontAwesomeIcon icon={faCopy} />
        </Styled.IconStyledStyled>
        {hook.hookTransactionText}
      </Button>
    </Styled.GridBoxStyled>

    <Styled.GridBoxStyled
      grids={3}
      justify="flex-end"
    >
      <Button
        theme="tertiary"
        onClick={hook.hookHandleUrl}
      >
        <Styled.IconStyledStyled>
          <FontAwesomeIcon icon={faCopy} />
        </Styled.IconStyledStyled>
        {hook.hookApiUrlText}
      </Button>
    </Styled.GridBoxStyled>

    <Styled.GridBoxStyled
      grids={3}
      justify="space-between"
    >
      <InputTextarea
        label={INPUT_LABEL.TRACKING_SCRIPT}
        name="trackingScript"
        value={hook?.hookTrackingScript}
        theme="readonlySecondary"
        tooltip={tooltipDetails}
        tooltipWidth={230}
        readonly
        disabled
      />
    </Styled.GridBoxStyled>
    <Styled.GridBoxStyled
      grids={3}
      justify="space-between"
    >
      <Styled.ButtonWrapperStyled>
        <Button
          theme="tertiary"
          onClick={hook.hookHandleScriptCopy}
        >
          <Styled.IconStyledStyled>
            <FontAwesomeIcon icon={faCopy} />
          </Styled.IconStyledStyled>
          {hook.hookScriptCopied}
        </Button>
      </Styled.ButtonWrapperStyled>
    </Styled.GridBoxStyled>
  </Styled.ContentStyled>
);
