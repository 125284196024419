/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper, Calendar, Checkbox, ErrorBanner, FileUpload, InputCalendar, InputText, InputTextarea, PageTitle, Select,
} from '../../../../components';
import { CreateCampaignModal } from '../../../../components/CreateCampaignModal';
import { CodeEditor } from '../../../../components/Input/Code';
import { FILE_EXTENSIONS, path, TOAST_ERR_MESSAGES_NO_PAGE } from '../../../../utils';
import {
  BUTTON_LABELS, INFO, LABELS, PLACEHOLDERS, SECTION_HEADERS,
} from '../../utils';
import { useNewAd } from '../hooks';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';
import * as RootStyled from '../../Css/styles';

type AdHtmlProps = DefaultPropsType;

const AdHtml = ({ permissionsCodeList = [] }: AdHtmlProps) => {
  const { hookIsReadOnlyList, ...hook } = useNewAd(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        isOpen={hook.hookErrorBanner}
        message={TOAST_ERR_MESSAGES_NO_PAGE(hook.hookAdErrorMessage)}
      />

      <Styled.HeaderStyled>
        <PageTitle>{path.addNewAdHtml.name}</PageTitle>
      </Styled.HeaderStyled>

      <InputText
        required
        name=""
        type="text"
        label={LABELS.AD_NAME}
        value={hook.hookName}
        onChange={hook.hookSetName}
        error={hook.hookAdErrors.adName}
        onBlur={hook.hookHandleValidation}
        disabled={isReadOnly}
      />
      <Styled.FieldsStyled>
        <Select
          width="42%"
          required
          name="status"
          label={LABELS.STATUS}
          onChange={hook.hookSetSelectedStatus}
          selected={hook.hookSelectedStatus}
          error={hook.hookAdErrors.status}
          options={hook.hookAdStatusTypeList}
          isLoading={hook.hookLoading}
          isDisabled={isReadOnly}
        />
        <Select
          width="42%"
          name="campaign"
          label={LABELS.CAMPAIGN}
          options={hook.hookCampaignList}
          onChange={hook.hookSetSelectedCampaign}
          selected={hook.hookSelectedCampaign}
          placeholder={PLACEHOLDERS.CAMPAIGN}
          isDisabled={isReadOnly}
          onBlur={hook.hookHandleValidation}
        />
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={() => hook.hookSetCampaignModal(true)}
          disabled={isReadOnly}
        >
          <FontAwesomeIcon icon={faPlus} />
          {BUTTON_LABELS.CREATE_CAMPAIGN}
        </Styled.ButtonStyled>
      </Styled.FieldsStyled>
      <InputTextarea
        required
        name=""
        label={LABELS.DESCRIPTION}
        value={hook.hookDescription}
        onChange={hook.hookSetDescription}
        error={hook.hookAdErrors.description}
        onBlur={hook.hookHandleValidation}
        disabled={isReadOnly}
      />
      <Styled.CodeFieldStyled>
        <CodeEditor
          onChange={hook.hookSetHtml5}
          value={hook.hookHtml5}
          label={LABELS.HTML_CODE}
          required
          error={hook.hookAdErrors.htmlCode}
          onBlur={hook.hookHandleValidation}
          disabled={isReadOnly}
        />
      </Styled.CodeFieldStyled>
      <Styled.CheckboxWrapperStyled>
        <Checkbox
          checked={hook.hookJsOnly}
          onChange={hook.hookSetJsOnly}
          disabled={isReadOnly}
        />
        {INFO.JS_ONLY}
      </Styled.CheckboxWrapperStyled>
      <Styled.InputWrapperStyled>
        <InputText
          width="25%"
          required
          name="adWidth"
          type="text"
          label={LABELS.AD_WIDTH}
          placeholder={PLACEHOLDERS.PX}
          value={hook.hookHtmlWidth}
          onChange={hook.hookSetHtmlWidth}
          error={hook.hookAdErrors.htmlWidth}
          onBlur={hook.hookHandleValidation}
          disabled={isReadOnly}
        />
        <InputText
          width="25%"
          required
          name="adHeight"
          type="text"
          label={LABELS.AD_HEIGHT}
          placeholder={PLACEHOLDERS.PX}
          value={hook.hookHtmlHeight}
          onChange={hook.hooksetHtmlHeight}
          error={hook.hookAdErrors.htmlHeight}
          onBlur={hook.hookHandleValidation}
          disabled={isReadOnly}
        />
        <FileUpload
          label={LABELS.ASSET_FILE}
          name="assetFile"
          width="50%"
          info={INFO.ASSET}
          maxFileSize={4096000}
          type="zip"
          accept={FILE_EXTENSIONS.ZIP}
          onChange={hook.hookOnAssetFileUploadHandler}
          disabled={isReadOnly}
        />
      </Styled.InputWrapperStyled>
      <>
        <Styled.TitleStyled>{SECTION_HEADERS.ADVANCED}</Styled.TitleStyled>
        {(hook.hookSelectedCampaign) && (
          <RootStyled.CPStyled>
            <RootStyled.CPHeader> Campaign Product: </RootStyled.CPHeader>
            <RootStyled.CPValue>
              { (hook.hookCampaignProduct.productId === 'null' || !hook.hookCampaignProduct.productId)
                ? (<> None </>) : hook.hookCampaignProduct.productName }
            </RootStyled.CPValue>

          </RootStyled.CPStyled>
        )}
        <Styled.AdvancedStyled>
          <Select
            name="product"
            label={LABELS.PRODUCT}
            onChange={hook.hookSetSelectedProduct}
            selected={hook.hookSelectedProduct}
            placeholder={PLACEHOLDERS.PRODUCT}
            options={hook.hookProductList}
            isDisabled={isReadOnly}
            error={hook.hookAdErrors.productId}
            onBlur={hook.hookHandleValidation}
          />
          <InputCalendar
            label={LABELS.START_DATE}
            value={hook?.hookStartDate?.toDateString() || ''}
            placeholder={PLACEHOLDERS.DATE}
            onClick={hook.hookOnOpenStartCalendar}
            isDisabled={isReadOnly}
          />
          <InputCalendar
            label={LABELS.END_DATE}
            value={hook?.hookEndDate?.toDateString() || ''}
            placeholder={PLACEHOLDERS.DATE}
            onClick={hook.hookOnOpenEndCalendar}
            error={hook.hookAdErrors.endDate}
            isDisabled={isReadOnly}
          />
          <Styled.InputStyled
            name="landingUrl"
            type="url"
            label={LABELS.LANDING_PAGE}
            placeholder={hook.hookUrlPlaceholder}
            value={hook.hookLandingPageForHTML}
            onChange={hook.hookSetHTMLLandingPage}
            error={hook.hookSingleUrlError}
            onBlur={hook.hookValidateWebsite}
            disabled={isReadOnly}
          />
          <Select
            required
            label={LABELS.LANGUAGE}
            onChange={hook.hookSetLanguage}
            selected={hook.hookLanguage}
            isDisabled={hook.hookLoading || isReadOnly}
            isLoading={hook.hookLoading}
            options={hook.hookLanguageList}
            error={hook.hookAdErrors.language}
          />
          <Styled.SelectStyled
            label={LABELS.TAGS}
            onChange={hook.hookSetTags}
            selectedMulti={hook.hookTags}
            options={hook.hookTagList}
            placeholder={PLACEHOLDERS.TAGS}
            isDisabled={hook.hookLoading || isReadOnly}
            isLoading={hook.hookLoading}
            isMulti
            creatable
          />
        </Styled.AdvancedStyled>
      </>
      <Styled.FooterStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={hook.hookOnBack}
        >
          {BUTTON_LABELS.CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          onClick={hook.hookCreateHtmlAd}
          disabled={isReadOnly}
        >
          {BUTTON_LABELS.SAVE}
        </Styled.ButtonStyled>
      </Styled.FooterStyled>
      <Calendar
        date={hook.hookStartDate}
        onApply={hook.hookOnApplyStartCalendar}
        onCancel={hook.hookOnCancelStartCalendar}
        isOpen={hook.hookStartCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
      />

      <Calendar
        date={hook.hookEndDate}
        onApply={hook.hookOnApplyEndCalendar}
        onCancel={hook.hookOnCancelEndCalendar}
        isOpen={hook.hookEndCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
      />
      <CreateCampaignModal
        isOpen={hook.hookCampaignModal}
        onClose={() => hook.hookSetCampaignModal(!hook.hookCampaignModal)}
        setCampaign={hook.hookSetCampaignAfterCreation}
      />
    </AppWrapper>
  );
};

export default AdHtml;
