import React from 'react';
import * as Styled from '../../styles';
import {
  Button, Modal,
} from '../../../../../../../../components';
import { CANCEL_ADD_RULE } from '../../enums';

type CancelAddRuleModalProps = {
  isOpen: boolean
  cancelButtonHandler: (state: boolean) => void
  exitAddRuleModalHandler: () => void
}

export const CancelAddRuleModal = ({
  isOpen,
  cancelButtonHandler,
  exitAddRuleModalHandler,
}: CancelAddRuleModalProps) => (
  <Modal isOpen={isOpen}>
    <Styled.ModalWrapperStyled>
      <Styled.PageTitleStyled>
        {CANCEL_ADD_RULE.TITLE}
      </Styled.PageTitleStyled>
      <Styled.TextPaddedStyled>
        {CANCEL_ADD_RULE.WARNING}
      </Styled.TextPaddedStyled>
      <Styled.ModalFooterStyled>
        <div />
        <Styled.NavButtonsWrapperStyled>
          <Button
            theme="secondary"
            onClick={() => cancelButtonHandler(false)}
            width="120px"
          >
            {CANCEL_ADD_RULE.CANCEL_BUTTON}
          </Button>
          <Button
            theme="primary"
            onClick={() => exitAddRuleModalHandler()}
            width="120px"
          >
            {CANCEL_ADD_RULE.LEAVE_BUTTON}
          </Button>
        </Styled.NavButtonsWrapperStyled>
      </Styled.ModalFooterStyled>
    </Styled.ModalWrapperStyled>
  </Modal>
);
