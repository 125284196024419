import { gql } from '@apollo/client';

export const GET_SUMMARY_REPORT = gql`
  query getFintelChecks($input: FintelCheckSearchInput!) {
    fintelChecksAdmin(input: $input) {
      count
      totalPages
      currentPage
      totalCounts {
        pageCount
        passCount
        failCount
        reviewCount
        incompleteCount
        nfCount
      }
      fintelChecks {
        id
        merchantName
        merchantId
        publisherName
        publisherId
        productCategory
        customizedProductId
        trackingEventDate
        productName
        pageCount
        passCount
        failCount
        reviewCount
        incompleteCount
        nfCount
      }
    }
  }
`;
