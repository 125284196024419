// Copied/Modified from fintel-app utils/membershipStatus.js
/**
 * There are 2 ways the publisher become a member of the merchant;
 *
 * 1. Membership Application (Publisher apply Merchant program)
 * 2. Publisher Invitation (Merchant invite Publisher to the program)
 *
 * Following status is the membership status used for both case
 */
const PENDING = 'Pending';
const APPROVED = 'Approved';
const CLOSED = 'Closed';
const DECLINED = 'Declined';

export const membershipStates = [
  PENDING,
  APPROVED,
  CLOSED,
  DECLINED,
];

/**
 * The app needs to distinguish the status based on case 1 or 2
 * So the app is mapping each status to the 1. Membership application OR 2. Publisher Invitation
 *
 * 1. Membership Application
 *    - Pending
 *    - Approved
 *    - Closed by Merchant
 *    - Declined
 *
 * 2. Publisher Invitations
 *    - Invitation
 *    - Approved
 *    - Closed by Publisher
 *    - Invitation Declined
 */
const INVITED = 'Invited';
const CLOSED_BY_PUBLISHER = 'Closed By Publisher';
const CLOSED_BY_MERCHANT = 'Closed By Merchant';
const INVITATION_DECLINED = 'Invitation Declined';

// 1. Membership Application
export const membershipApplicationStatuses = [
  PENDING,
  APPROVED,
  CLOSED_BY_MERCHANT,
  DECLINED,
];

// 2. Publisher Invitations
export const publisherInvitationStatuses = [
  INVITED,
  APPROVED,
  CLOSED_BY_PUBLISHER,
  INVITATION_DECLINED,
];

/**
 * Convert to the one of following membership status
 *
 * 1. Membership Application (Publisher apply Merchant program)
 * 2. Publisher Invitation (Merchant invite Publisher to the program)
 */
export const convertMembershipStatus = (membership: any) => {
  if (!membership) return 'N/A';
  // check if the membership is 1. Membership Application OR 2. Publisher Invitation
  const isPublisherInvitation = !!membership.publisherInvitation?.id;
  const lastUpdated = membership.statusLastUpdatedBy;
  switch (membership.status) {
    case PENDING: return (lastUpdated === 'Merchant' || (!lastUpdated && isPublisherInvitation)) ? INVITED : PENDING;
    case APPROVED: return APPROVED;
    case DECLINED: return (lastUpdated === 'Merchant' || (!lastUpdated && !isPublisherInvitation)) ? DECLINED : INVITATION_DECLINED;
    case CLOSED: return (lastUpdated === 'Merchant' || (!lastUpdated && !isPublisherInvitation)) ? CLOSED_BY_MERCHANT : CLOSED_BY_PUBLISHER;
    default: return membership.status;
  }
};

/**
 * check if the status update available or not
 */
export const checkActionAvailability = (byUserType: string, membership: any) => {
  if (!membership) return false;
  const { publisherInvitation, status, statusLastUpdatedBy } = membership;
  const isPublisherInvitation = !!publisherInvitation?.id;
  const isMerchantUser = byUserType === 'Merchant';
  switch (status) {
    case PENDING:
      return ((statusLastUpdatedBy === 'Merchant' && !isMerchantUser) || (statusLastUpdatedBy !== 'Merchant' && isMerchantUser)
        || (!statusLastUpdatedBy && ((isPublisherInvitation && !isMerchantUser) || (!isPublisherInvitation && isMerchantUser))));
    case DECLINED: case CLOSED:
      return ((statusLastUpdatedBy === 'Merchant' && isMerchantUser) || (statusLastUpdatedBy !== 'Merchant' && !isMerchantUser)
                || (!statusLastUpdatedBy && ((isPublisherInvitation && !isMerchantUser) || (!isPublisherInvitation && isMerchantUser))));
    case APPROVED: return true;
    default: return false;
  }
};
