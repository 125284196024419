import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useQueries, useUserInfo } from '../../../../../../../hooks';
import { GET_COMMISSIONS } from '../graphql/queries';
import { payableAmount } from '../utils/getPayableAmount';
import { dateFormatter } from '../../../../../../../utils';

const RECORDS_PER_PAGE = 10;

export const useCommissions = () => {
  const { hookWhoAmI } = useUserInfo();
  const { id } = useQueries();

  const [commissionData, setCommissionData] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [getCommissions, { loading }] = useLazyQuery(GET_COMMISSIONS);

  const getCommissionsHandler = async () => {
    const { data } = await getCommissions({
      variables: {
        programId: hookWhoAmI.programId,
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.groups?.groups !== undefined) {
      const filterByCorrectGroup = data.groups.groups.filter((group: any) => group.id === id)[0];

      const dataFormated = filterByCorrectGroup.commissions.map((commission: any) => {
        const products = commission.products.length > 0 ? commission.products[0].name : 'All Products';
        const commissionType = commission.commissionType === 'CPA' ? 'Custom CPA' : commission.commissionType;
        const members = commission.publisherGroups.length === 1 ? commission.publisherGroups[0].name : 'Custom';
        const { payable, transaction } = payableAmount(commission);

        return ({
          id: commission.id,
          commissionMembers: {
            commission: commissionType,
            member: members,
          },
          transaction,
          products,
          payable,
          rangeDate: {
            start: dateFormatter(new Date(commission.startDate), ','),
            end: dateFormatter(new Date(commission.endDate), ','),
          },
          detail: commission.id,
        });
      });

      setCommissionData(dataFormated);
      setTableData(dataFormated.slice((currentPage - 1) * RECORDS_PER_PAGE, currentPage * RECORDS_PER_PAGE));
      setTotalPages(Math.ceil(dataFormated.length / RECORDS_PER_PAGE));
    }
  };

  const setCurrentPageHandler = (newPage: number) => {
    setCurrentPage(newPage);
    setTableData(commissionData.slice((newPage - 1) * RECORDS_PER_PAGE, newPage * RECORDS_PER_PAGE));
  };

  useEffect(() => {
    getCommissionsHandler();
  }, []);

  return {
    hookData: tableData,
    hookLoading: loading,
    hookTotalPages: totalPages,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,
  };
};
