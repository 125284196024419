import React from 'react';
import { BUTTON_LABEL, MODAL_TITLE, PRODUCT_CATEGORY_OPTIONS } from '../../contracts';
import { Checkbox } from '../../../../../components';
import * as Styled from '../../styles';
import { useProductCategoryModal } from '../../hook';

type ProductCategoryModalProps = {
  cancelHandler: () => void
  saveHandler: (updatedProductCategory: string[]) => void
  currentProductCategory: string[]
}

export const ProductCategoryModal = ({
  cancelHandler, saveHandler, currentProductCategory,
}: ProductCategoryModalProps) => {
  const hook = useProductCategoryModal(currentProductCategory);
  return (
    <Styled.ModalBodyStyled>
      <Styled.ModalTitleStyled>{MODAL_TITLE}</Styled.ModalTitleStyled>
      <Styled.ModalCheckboxContainerStyled>
        {!hook.hookIsloading && (
          <>
            {
              PRODUCT_CATEGORY_OPTIONS.map((product) => (
                <Checkbox
                  key={product.value}
                  label={product.label}
                  onChange={(checked) => hook.hookUpdateProductCategoryList(checked, product)}
                  checked={hook.hookUpdatedProductCategory.includes(product.label)}
                />
              ))
            }
          </>
        )}
      </Styled.ModalCheckboxContainerStyled>

      <Styled.ButtonContainerStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => {
            cancelHandler();
            hook.hookResetProductCategory(currentProductCategory);
          }}
        >
          {BUTTON_LABEL.CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          onClick={() => saveHandler(hook.hookUpdatedProductCategory)}
          margin="right"
        >
          {BUTTON_LABEL.SAVE}
        </Styled.ButtonStyled>
      </Styled.ButtonContainerStyled>
    </Styled.ModalBodyStyled>
  );
};
