import { gql } from '@apollo/client';

export const UPDATE_GROUP = gql`
  mutation updateGroup($input: GroupInput!) {
    updateGroup(input: $input) {
      group {
        id
        name
      }
    }
  }
`;
