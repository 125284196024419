import { gql } from '@apollo/client';

export const CREATE_MEMBERSIP = gql`
mutation createMembership($input: MembershipInput) {
  createMembership(input: $input) {
    membership {
      id
      status
      publisherInvitation {
        id
      }
    }
  }
}
`;
