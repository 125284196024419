/* eslint-disable no-param-reassign */
import { size } from 'lodash';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserInfo } from '../../../../../hooks';
import {
  MERCHANT_PREFIX, path, TOAST_ERR_MESSAGES_NO_PAGE, toUTCHours, ERROR_TYPES, formatPayable, formatCommissionDate1, formatCommissionDate2,
} from '../../../../../utils';
import { UpdateCommissionInput, UpdateCommissionOutput, UPDATE_COMMISSION } from '../graphql/mutations';
import {
  GET_COMMISSION_DETAILS, GetCommissionDetailsInput, GetCommissionDetailsOutput, GCDCommission, GET_PUBLISHER_GROUPS,
  GetPublisherGroupsOutput, GetPublisherGroupsInput, GetMembershipsOutput, GetMembershipsInput, GET_MEMBERSHIPS,
  GET_COMMISSION_NAME, GetCommissionNameInput,
} from '../graphql/queries';
import { compareValues } from '../../../../Reports/PerfomanceReport/utils';
import { DESC_ASC_OPTIONS, SORT_DATE } from '../enums';
import { Permission } from '../../../../../entities';
import { useValidation } from '../../../../../utils/validation';

export type PublisherRow = {
  id: string
  idNum: number // So can sort correctly on id
  name: string
  startDate: Date | null // Must be not set already to set it
  endDate: Date | null // Must be greater then startDate
  type: 'Publisher Group' | 'Publisher'
  selected: boolean // Checkbox status
  registered: boolean // is already included in the commission
  disabled: boolean // Determines if row greyed out
  publishersInGroup: string[] | null // Need to get publishers part of group
  searchRemovable?: boolean,
}

const TABLE_ROW_SIZE = 6;

const sortPublisherRows = (sortBy: string, sortOrder: 'asc' | 'desc', publisherDataToSort: PublisherRow[], searchValue?: string): PublisherRow[] => {
  const sortFunction = compareValues(sortBy, sortOrder);
  let resortedPublishers: PublisherRow[] = [];
  // Only Filter by the search value if it is not empty
  // When search empty display all active Publisher/PublisherGroups
  // Keep selected Publishers on list even if not matching
  if (searchValue) {
    resortedPublishers = [...publisherDataToSort].sort(sortFunction).filter((row) => row.id.includes(searchValue)
      || row.name.toLowerCase().includes(searchValue.toLowerCase())
      || row.selected);
  } else {
    resortedPublishers = [...publisherDataToSort].sort(sortFunction).filter((row) => row.registered
      || row.selected
      || row.startDate);
  }
  return resortedPublishers;
};

export const useEditCommissions = (permissionsCodeList: string[] = []) => {
  // Global Values
  const navigate = useNavigate();
  const hookLocation = useLocation();

  const comId = typeof hookLocation.state === 'object' ? hookLocation.state?.val : hookLocation.state;

  const { hookWhoAmI } = useUserInfo();

  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Commission Info
  const [commissionData, setCommissionData] = useState<GCDCommission>();
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [commissionType, setCommissionType] = useState<string>('');
  const [commissionStructureSentence, setCommissionStructureSentence] = useState<string>('');
  const [detailsStartDateStringFormat, setDetailsStartDateStringFormat] = useState<string>('');
  const [detailsEndDateStringFormat, setDetailsEndDateStringFormat] = useState<string>('');
  const [commissionName, setCommissionName] = useState<string>('Custom');
  const [commissionDescription, setCommissionDescription] = useState<string>('');
  const [commissionNameExists, setCommissionNameExists] = useState(false);
  const [commissionNameChange, setCommissionNameChange] = useState(false);
  const [selectedPublisherGroupsDialog, setSelectedPublisherGroupsDialog] = useState<any[]>([]);
  const [disableStartDate, setDisableStartDate] = useState<boolean>(false);
  const [disableDates, setDisableDates] = useState<boolean>(true);

  // Details Tab
  const [detailsEndDate, setDetailsEndDate] = useState<Date | undefined>();
  const [detailsStartDate, setDetailsStartDate] = useState<Date>(new Date());
  const [detailsCalendarOpen, setDetailsCalendarOpen] = useState(false);
  const [detailsMinDate, setDetailsMinDate] = useState<Date>(new Date());

  // Pub/Group duplicates Map
  const [duplicateAllowedMap, setDuplicateAllowedMap] = useState({});
  const [duplicatesSearchMap, setDuplicatesSearchMap] = useState<{[key: string]: any}>({});

  // Publisher/Groups Tab
  // Filters & Dropdowns
  const [sortByOrderSelected, setsortByOrderSelected] = useState<SelectOption>(DESC_ASC_OPTIONS[0]);
  const [sortByDateSelected, setsortByDateSelected] = useState<SelectOption>(SORT_DATE[0]);

  // Modal
  const [isEditCommissionModalOpen, setIsEditCommissionModalOpen] = useState(false);

  // Table Status
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [getPublisherInfo, setGetPublisherInfo] = useState<boolean>(false); // Triggers fetching the Merchant's Publisher Data when true
  const [publishersSelected, setPublishersSelected] = useState<string[]>([]); // Ids of Publisher/PublisherGroups Selected
  const [editCommissionErrors, setEditCommissionErrors] = useState<{ [key: string]: string }>({});

  // Publisher/PublisherGroup Data
  const [completePublisherList, setCompletePublisherList] = useState<PublisherRow[]>([]); // Complete list of all Publishers and Publisher Groups
  const [publisherList, setPublisherList] = useState<PublisherRow[]>([]); // List of Publisher Rows that match current filters
  const [tableData, setTableData] = useState<PublisherRow[]>([]); // Publishers to display on table currently

  // For Calculating the disabled status of Publisher/PublisherGroups
  // publishersSelected get added to registeredIds
  const [registeredIds, setRegisteredIds] = useState<string[]>([]); // The displayed list of Registered Ids (changes when making selections on table)

  const [startDate, setStartDate] = useState<Date>();
  const [startDateMin, setStartDateMin] = useState<Date>();
  const [startDateMax, setStartDateMax] = useState<Date>();
  const [startCalendarOpen, setStartCalendarOpen] = useState(false);

  const [endDate, setEndDate] = useState<Date>();
  const [endDateMin, setEndDateMin] = useState<Date>();
  const [endDateMax, setEndDateMax] = useState<Date>();
  const [endCalendarOpen, setEndCalendarOpen] = useState(false);

  // const [publisherData, setPublisherData] = useState<PublisherItem[]>([]);
  const [updateDateWarning, setDateWarning] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  // Queries
  const [getCommission, { loading: getCommissionLoading }] = useLazyQuery<GetCommissionDetailsOutput, GetCommissionDetailsInput>(GET_COMMISSION_DETAILS);
  const [updateCommission, { loading: updateCommissionLoading }] = useMutation<UpdateCommissionOutput, UpdateCommissionInput>(UPDATE_COMMISSION);
  const [getPublisherGroups, { loading: getPublisherGroupsLoading }] = useLazyQuery<GetPublisherGroupsOutput, GetPublisherGroupsInput>(GET_PUBLISHER_GROUPS);
  const [getMemberships, { loading: getMembershipsLoading }] = useLazyQuery<GetMembershipsOutput, GetMembershipsInput>(GET_MEMBERSHIPS);
  const [checkCommissionName] = useLazyQuery(GET_COMMISSION_NAME);
  const vali = useValidation();
  const [secondRender, setSecondRender] = useState(false);
  // ===== Validations =====

  const globalValues: { [key: string]: string } = {
    commissionName,
  };

  const globalFields = {
    commissionName: ERROR_TYPES.COMMISSION_NAME,
  };

  // formats date
  function formatDate(isoDateString: string) {
    const a = isoDateString.split('-');
    const b = a[2].split('T')[0];
    const d = `${a[1]}-${b}-${a[0]}`;
    return d;
  }

  const handleValidation = () => vali.validateAll(globalValues, globalFields, setEditCommissionErrors, secondRender);
  // Saves updates made to the commission
  const saveCommissionHandler = async () => {
    setSecondRender(true);
    setErrorMessage('');
    setLoadingMessage('Updating Commission');

    let newDate: Date | undefined;
    if (detailsEndDate) newDate = toUTCHours(detailsEndDate, 'end');
    const valiGlobal = handleValidation();
    if (!valiGlobal) return;

    // Send in all the startDate and endDate info for all Publisher/PublisherGroups with a start date if custom
    const updateInput: UpdateCommissionInput = isCustom ? {
      input: {
        id: comId,
        endDate: newDate || '',
        commissionName,
        commissionTarget: {
          productIds: commissionData?.products.map((product) => product.id) || [null],
          publisherTargets:
            completePublisherList.filter((row) => row.type === 'Publisher' && row.startDate).map((row) => ({
              publisherId: row.idNum.toString(),
              startDate: toUTCHours(row.startDate as Date, 'beginning'),
              endDate: row.endDate ? toUTCHours(row.endDate as Date, 'end') : null,
            })) || [],
          publisherGroupTargets:
            completePublisherList.filter((row) => row.type === 'Publisher Group' && row.startDate).map((row) => ({
              publisherGroupId: row.idNum.toString(),
              startDate: toUTCHours(row.startDate as Date, 'beginning'),
              endDate: row.endDate ? toUTCHours(row.endDate as Date, 'end') : null,
            })) || [],
        },
      },
    } : {
      input: {
        id: comId,
        endDate: newDate || '',
        commissionName,
      },
    };

    const { errors } = await updateCommission({
      variables: updateInput,
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (errors && errors[0]) {
      setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(errors[0]?.message));
      return;
    }
    // On success Navigate Back
    navigate(`${MERCHANT_PREFIX}${path.manageCommissions.href}`);
  };

  // modified publisher groups for the dialog box
  function publisherGroupDialogModifier2() {
    const result = [];
    for (let i = 0; i < completePublisherList.length; i += 1) {
      const row = completePublisherList[i];
      if (row.startDate) {
        let rowName = row.name;
        if (row.type === 'Publisher') {
          rowName = `${row.idNum} - ${rowName}`;
        }
        result.push(rowName);
      }
    }
    return result;
  }

  const saveCommissionClickHandler = async () => {
    setSecondRender(true);
    const valiGlobal = handleValidation();
    if (!valiGlobal) return;
    setSelectedPublisherGroupsDialog(publisherGroupDialogModifier2());

    // checks if duplicate name exists
    const merchantId = hookWhoAmI.companyId?.toString() || '';
    if (commissionNameChange) {
      const { data } = await checkCommissionName({
        variables: {
          input: {
            value: commissionName,
            merchantId,
          },
        },
      });

      const resCommissionName = data.uniqueCommissionName.value;
      setCommissionNameExists(resCommissionName);
    }
    setIsEditCommissionModalOpen(true);
  };

  const handleCommissionConfirmation = () => {
    setIsEditCommissionModalOpen(false);
    saveCommissionHandler();
  };

  const setCommissionNameHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommissionNameChange(true);
    setCommissionName(e.target.value);
  };

  // Gets the Commissions data for the commission Id received from useNavigate
  const getCommissionHandler = async () => {
    setErrorMessage('');
    setLoadingMessage('Loading Commission');

    const { data } = await getCommission({
      variables: {
        commissionId: comId,
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });

    const comissionStructure = data?.commission?.commissionStructure;
    if (data && comissionStructure) {
      const formattedCS = formatPayable(comissionStructure, data.commission.commissionType || '');
      setCommissionStructureSentence(formattedCS.payable);
    }
    if (data && data.commission) {
      const { commission } = data;
      setCommissionData(data.commission);
      if (data.commission.commissionName) {
        setCommissionName(data.commission.commissionName);
      } else {
        setCommissionName('Custom');
      }

      if (data.commission.description) setCommissionDescription(data.commission.description);
      // Should there be Publishers/Groups Tab, If there is fetch the info for it
      if (commission?.publishers?.length > 0 || commission?.publisherGroups?.length > 0) {
        setIsCustom(true);
        setGetPublisherInfo(true);
      } else {
        setIsCustom(false);
        setCommissionName('Default');
      }

      // Get the commission Type
      setCommissionType(commission.commissionType === 'Revenue Share' ? 'RevShare' : commission.commissionType || '');

      if (data.commission.startDate) {
        formatCommissionDate1(data.commission.startDate);
        setDetailsStartDate(new Date(formatDate(data.commission.startDate)));
        setDetailsStartDateStringFormat(formatCommissionDate1(data.commission.startDate));
      }

      if (data.commission.endDate) {
        const newEndDate = new Date(data.commission.endDate);
        setDetailsEndDate(newEndDate);
        setDetailsEndDateStringFormat(formatCommissionDate1(data.commission.endDate));
      }

      // Get the start date for calendar constraints
      // If Commission start date is after current Date use that as the min Date
      if (data.commission.startDate) {
        const newStartDate = toUTCHours(new Date(data.commission.startDate), 'beginning');
        const newMinDate = toUTCHours(new Date(), 'beginning');
        if (newStartDate > newMinDate) {
          setDetailsMinDate(newStartDate);
        } else {
          setDetailsMinDate(newMinDate);
        }
      }
    }
  };

  const setDetailsCalendarHandler = (state: boolean) => {
    setDetailsCalendarOpen(state);
  };

  const onApplyDetailsCalendarHandler = (newDate: Date) => {
    setDetailsEndDate(newDate);
    formatCommissionDate2(newDate.toString());
    setDetailsEndDateStringFormat(formatCommissionDate2(newDate.toString()));
    setDetailsCalendarOpen(false);
  };

  /**
   * The search duplicates restricts adding multiple groups on each keystrokes, as we just need one.
   */
  function createSearchDuplicatesMap(completePubGrpList: PublisherRow[]) {
    const localDuplicateSearchmap: any = {};
    for (let i = 0; i < completePubGrpList.length; i += 1) {
      const { idNum } = completePubGrpList[i];
      localDuplicateSearchmap[idNum] = 0;
    }
    setDuplicatesSearchMap(localDuplicateSearchmap);
  }

  /**
   * Processes the dates and make sure if the user allowed to add a duplicate pub/group
   * Updates the map with the max date or null, if there are lets say two same pub/group
   * Uses the maxdate or null to determine whether this pubgrp can be duplicated or not
   */
  function createAllowedDuplicateMap(items: any[]): Map<any, number> {
    const idMap = new Map<any, {allDatesValid: boolean, latestDate: Date | null}>();
    const currentDate = new Date();

    items.forEach((item) => {
      const localStartDate = item.startDate ? new Date(item.startDate) : null;
      const localEndDate = (item.endDate && item.endDate !== null) ? new Date(item.endDate) : null;

      const data = idMap.get(item.idNum) || { allDatesValid: true, latestDate: null };

      if (!localEndDate || localEndDate >= currentDate) {
        data.allDatesValid = false;
      }

      if (data.allDatesValid && localEndDate && localEndDate < currentDate && (!data.latestDate || localEndDate > data.latestDate)) {
        data.latestDate = localEndDate;
      }

      idMap.set(item.idNum, data);
    });

    const finalMap = new Map<any, number>();
    idMap.forEach((value, key) => {
      finalMap.set(key, value.allDatesValid && value.latestDate ? 1 : 0);
    });

    return finalMap;
  }

  // If the commission is 'Custom' get the Publisher info for that Merchant
  const getMerchantsPublisherInfo = async () => {
    setErrorMessage('');
    setLoadingMessage('Loading Commission');

    /** List of all the Merchant's available Publisher Groups and Publishers (Not yet checked if should be disabled) */
    const publisherRows: PublisherRow[] = [];
    /** List of all currently Registered Publisher Ids */
    const registeredIdsArray: string[] = [];

    const [{ data: publisherGroupsData }, { data: membershipData }] = await Promise.all([
      getPublisherGroups({
        variables: {
          programId: hookWhoAmI.programId || '0',
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
        },
      }),
      getMemberships({
        variables: {
          input: {
            merchantId: hookWhoAmI.companyId?.toString() || '0',
            status: 'Approved',
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
        },
      }),
    ]);

    // This Id is unique as we have mulitple pub/groups with same idNum which is their original Id
    let virtualPubGrpId = 0;

    if (publisherGroupsData && publisherGroupsData.groups) {
      /** List of all the Merchants Publisher Groups */
      const PublisherGroupsList: PublisherRow[] = [];
      const originalPublisherGroups = publisherGroupsData.groups.groups;
      originalPublisherGroups.forEach((pubGroup) => {
        virtualPubGrpId += 1;
        const targetGroups = commissionData?.commissionTarget.publisherGroupTargets.filter((pub) => pub.publisherGroupId === pubGroup.id);
        if (size(targetGroups) > 0) {
          registeredIdsArray.push(...(pubGroup.memberships.map((membership) => membership.publisher.id)));

          // Adding multiples if multiple exists
          targetGroups?.forEach((tg) => {
            PublisherGroupsList.push({
              id: virtualPubGrpId.toString(),
              idNum: Number(pubGroup.id),
              name: pubGroup.name,
              startDate: (tg && tg.startDate) ? new Date(formatDate(tg.startDate)) : null,
              endDate: (tg && tg.endDate) ? new Date(formatDate(tg.endDate)) : null,
              type: 'Publisher Group',
              selected: false,
              registered: !!tg,
              disabled: false,
              publishersInGroup: pubGroup.memberships.map((membership) => membership.publisher.id),
            });
            virtualPubGrpId += 1;
          });
        } else { // if not multiple add the regular one
          PublisherGroupsList.push({
            id: virtualPubGrpId.toString(),
            idNum: Number(pubGroup.id),
            name: pubGroup.name,
            startDate: null,
            endDate: null,
            type: 'Publisher Group',
            selected: false,
            registered: false,
            disabled: false,
            publishersInGroup: pubGroup.memberships.map((membership) => membership.publisher.id),
          });
        }
      });

      publisherRows.push(...PublisherGroupsList);
    }

    if (membershipData && membershipData.memberships) {
      const PublishersList: PublisherRow[] = [];
      const newMemberships = membershipData.memberships.memberships;
      newMemberships.forEach((pubMem) => {
        virtualPubGrpId += 1;
        const targetPubs = commissionData?.commissionTarget.publisherTargets.filter((pub) => pub.publisherId === pubMem.publisher.id);
        if (size(targetPubs) > 0 && targetPubs !== undefined) {
          registeredIdsArray.push(targetPubs[0].publisherId);

          // Adding multiples if multiple exists
          targetPubs?.forEach((tg) => {
            PublishersList.push({
              id: virtualPubGrpId.toString(),
              idNum: Number(pubMem.publisher.id),
              name: pubMem.publisher.companyName,
              startDate: (tg && tg.startDate) ? new Date(formatDate(tg.startDate)) : null,
              endDate: (tg && tg.endDate) ? new Date(formatDate(tg.endDate)) : null,
              type: 'Publisher',
              selected: false,
              registered: !!tg,
              disabled: false,
              publishersInGroup: null,
            });
            virtualPubGrpId += 1;
          });
        } else { // if not multiple add the regular one
          PublishersList.push({
            id: virtualPubGrpId.toString(),
            idNum: Number(pubMem.publisher.id),
            name: pubMem.publisher.companyName,
            startDate: null,
            endDate: null,
            type: 'Publisher',
            selected: false,
            registered: false,
            disabled: false,
            publishersInGroup: null,
          });
        }
      });
      publisherRows.push(...PublishersList);
    }

    // Disable the Publisher/PublisherGroup Rows that are not registered and have an overlapping Publisher Id
    // with a Publisher/Publisher Group that is already registered.
    const publisherRowsDisableChecked = publisherRows.map((pub) => {
      if (pub.type === 'Publisher') {
        if (!pub.registered && registeredIdsArray.some((regId) => regId === pub.idNum.toString())) {
          return { ...pub, disabled: true };
        }
      }
      if (pub.type === 'Publisher Group') {
        if (!pub.registered && registeredIdsArray.some((regId) => pub.publishersInGroup?.includes(regId))) {
          return { ...pub, disabled: true };
        }
      }
      return pub;
    });

    // Set the Min and Max Dates allowed on the Calendars
    const newMinDate = toUTCHours(new Date(), 'beginning');
    if (newMinDate > detailsMinDate) {
      setEndDateMin(newMinDate);
      setStartDateMin(newMinDate);
    } else {
      const newDate = new Date(detailsMinDate);
      setEndDateMin(detailsMinDate);
      newDate.setDate(detailsMinDate.getDate() + 1);
      setStartDateMin(newDate);
    }
    setStartDateMax(detailsEndDate);
    setEndDateMax(detailsEndDate);

    const sortFunction = compareValues('idNum', 'asc');
    publisherRowsDisableChecked.sort(sortFunction);
    const displayedPublishers = publisherRowsDisableChecked.filter((row) => row.registered);
    setRegisteredIds(registeredIdsArray);
    setCurrentPage(1);
    setTotalPages(Math.ceil(displayedPublishers.length / TABLE_ROW_SIZE));
    createSearchDuplicatesMap(publisherRowsDisableChecked);
    setCompletePublisherList(publisherRowsDisableChecked);
    setPublisherList(publisherRowsDisableChecked);
    setTableData(publisherRowsDisableChecked.filter((row) => row.registered).slice(0, TABLE_ROW_SIZE));
  };

  const handleSetSortByOrderSelected = (newSortOrder: SelectOption) => {
    setsortByOrderSelected(newSortOrder);
    setCurrentPage(1);
    const resortedPublishers = sortPublisherRows(sortByDateSelected.value, newSortOrder.value === 'asc' ? 'asc' : 'desc', completePublisherList, search);
    setPublisherList(resortedPublishers);
    setTotalPages(Math.ceil(resortedPublishers.length / TABLE_ROW_SIZE));
    setTableData(resortedPublishers.slice(0, TABLE_ROW_SIZE));
  };

  const handleSetSortByDateSelected = (newSortColumn: SelectOption) => {
    setsortByDateSelected(newSortColumn);
    setCurrentPage(1);
    const resortedPublishers = sortPublisherRows(newSortColumn.value, sortByOrderSelected.value === 'asc' ? 'asc' : 'desc', completePublisherList, search);
    setPublisherList(resortedPublishers);
    setTotalPages(Math.ceil(resortedPublishers.length / TABLE_ROW_SIZE));
    setTableData(resortedPublishers.slice(0, TABLE_ROW_SIZE));
  };

  /**
   * In order to add new pub/groups, we need to assign them unique virtual ids
   * This function checks the max number in the list and adds 1 to get new max value
   */
  function findMaxCompleteListId(completePubGrpList: PublisherRow[]) {
    let maxVal = 0;
    completePubGrpList.forEach((completePubGrp: PublisherRow) => {
      if (Number(completePubGrp.id) > maxVal) maxVal = Number(completePubGrp.id);
    });
    return maxVal + 1;
  }

  /**
   * Injects new pub/group to the completePubGrpList, after checking the eligibility
   */
  function injectDuplicatesForSearch(completePubGrpList: PublisherRow[]) {
    const localDuplicateAllowedMap:any = createAllowedDuplicateMap(completePubGrpList);
    const commissionEndDate: string | null | undefined = commissionData?.endDate;
    let newIterator = findMaxCompleteListId(completePubGrpList);
    let commissionEndDatePass = true;
    const newCompletePublisherList: PublisherRow[] = [];
    const todaysDate = new Date();

    // Commission Enddate is the max limit, restrict addition of new commission structure
    if (commissionEndDate !== undefined && commissionEndDate !== null) {
      const commissionEndDateObject = new Date(commissionEndDate);
      if (commissionEndDateObject < todaysDate) commissionEndDatePass = false;
    }

    completePubGrpList.forEach((completePubGrp) => {
      const { idNum } = completePubGrp;

      if (commissionEndDatePass && duplicatesSearchMap[idNum] === 0) {
        // Push the duplicate value max limit one for each unique publisher or publisher group
        if (localDuplicateAllowedMap.get(idNum) === 1) {
          const duplicatePubGrp = { ...completePubGrp };
          duplicatePubGrp.id = newIterator.toString();
          duplicatePubGrp.startDate = null;
          duplicatePubGrp.registered = false;
          duplicatePubGrp.endDate = null;
          duplicatePubGrp.searchRemovable = true;
          newCompletePublisherList.push(duplicatePubGrp);
          localDuplicateAllowedMap[idNum] = 0;
          duplicatesSearchMap[idNum] = 1;
        }

        // Push the original value
        newCompletePublisherList.push(completePubGrp);
        newIterator += 1;
      } else {
        // Push the original value
        newCompletePublisherList.push(completePubGrp);
      }
    });
    return newCompletePublisherList;
  }

  function resetDuplicateMapValues(obj: any): any {
    return Object.keys(obj).reduce((acc: any, key: any) => {
      acc[key] = 0;
      return acc;
    }, {});
  }

  /**
   * Remove the searchRemovable elements when the search is cleared
   */
  function removeDuplicatesFromSearch(completePubGrpList: PublisherRow[]) {
    const newList: PublisherRow[] = [];
    completePubGrpList.forEach((completePubGrp: PublisherRow) => {
      if (!completePubGrp?.searchRemovable) {
        newList.push(completePubGrp);
      }
    });
    setDuplicateAllowedMap(resetDuplicateMapValues(duplicateAllowedMap));
    setDuplicatesSearchMap(resetDuplicateMapValues(duplicatesSearchMap));
    return newList;
  }

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
    let newCompletePublisherList = completePublisherList;
    if (e.target.value) {
      newCompletePublisherList = injectDuplicatesForSearch(completePublisherList);
      setCompletePublisherList(newCompletePublisherList);
    } else {
      newCompletePublisherList = removeDuplicatesFromSearch(completePublisherList);
      setCompletePublisherList(newCompletePublisherList);
    }

    const resortedPublishers = sortPublisherRows(sortByDateSelected.value, sortByOrderSelected.value === 'asc' ? 'asc' : 'desc', newCompletePublisherList, e.target.value);
    setPublisherList(resortedPublishers);
    setTotalPages(Math.ceil(resortedPublishers.length / TABLE_ROW_SIZE));
    setTableData(resortedPublishers.slice(0, TABLE_ROW_SIZE));
  };

  const clearSearch = () => {
    setSearch('');
    setCurrentPage(1);
    const resortedPublishers = sortPublisherRows(sortByDateSelected.value, sortByOrderSelected.value === 'asc' ? 'asc' : 'desc', completePublisherList, '');
    setPublisherList(resortedPublishers);
    setTotalPages(Math.ceil(resortedPublishers.length / TABLE_ROW_SIZE));
    setTableData(resortedPublishers.slice(0, TABLE_ROW_SIZE));
  };

  const handleSetPage = (pageNum: number) => {
    setCurrentPage(pageNum);
    setTableData(publisherList.slice((pageNum - 1) * TABLE_ROW_SIZE, pageNum * TABLE_ROW_SIZE));
  };

  /**
   * Usually, if pub/grp is registered true only then their status is set to disabled true
   * But, when we add duplicates which are also called search removable they don't have registered set to true,
   * Even if their original pub/group has it set to true.
   * This function comapres duplicates with its original values and makes them equal
   */
  function correctDisabledStatus(completePubGrpList: PublisherRow[]): PublisherRow[] {
    completePubGrpList.forEach((completePubGrp: PublisherRow) => {
      if (completePubGrp.searchRemovable) {
        const sameIdNumObject = completePubGrpList.find((o) => o.idNum === completePubGrp.idNum && o.id !== completePubGrp.id);
        if (sameIdNumObject) {
          completePubGrp.disabled = sameIdNumObject.disabled;
        }
      }
    });

    return completePubGrpList;
  }

  const confirmAllUnselected = (row: any) => {
    if (size(publishersSelected) === 1 && publishersSelected[0] === row.id) {
      return true;
    }
    return false;
  };

  // Has both start date and end date: Block it.
  const blockStartDate = (row: any, actionType: string) => {
    let res = true;
    if (actionType === 'unselected') {
      completePublisherList.forEach((pubGrp: any) => {
        if (pubGrp.id !== row.id && pubGrp.selected === true) {
          if (pubGrp.startDate === null || pubGrp.endDate === null) res = false;
        }
      });
    }
    if (actionType === 'selected') {
      completePublisherList.forEach((pubGrp: any) => {
        if (pubGrp.selected === true || pubGrp.id === row.id) {
          if (pubGrp.endDate === null) {
            res = false;
          }
          if (pubGrp.endDate !== null && pubGrp.endDate !== null) {
            return true;
          }
        }
      });
    }
    return res;
  };

  // Selecting Publisher/PublisherGroup needs to update which rows on the table are disabled
  // Need to prevent selecting publishers who are part of Selected PublisherGroups and vice versa
  const handleChangeCheck = (row: PublisherRow, add: boolean) => {
    if (row.disabled) return; // Disabled rows can't be checked

    // That counts for selected.
    if (!row.selected) {
      setDisableDates(false);
      if (blockStartDate(row, 'selected')) setDisableStartDate(true);
      else {
        setDisableStartDate(false);
      }
    }

    // That counts for unselected.
    if (row.selected) {
      if (blockStartDate(row, 'unselected')) {
        setDisableStartDate(true);
      } else {
        setDisableStartDate(false);
      }
      if (confirmAllUnselected(row)) {
        setDisableDates(true);
      }
    }

    // Update list of selected Publishers (check boxes on table)
    if (add) {
      setPublishersSelected([...publishersSelected, row.id]);
    } else {
      setPublishersSelected(publishersSelected.filter((publisher) => publisher !== row.id));
    }

    confirmAllUnselected(row);

    // Update the list of registeredIds (used for disable status on table)
    let newRegisteredIds: string[] = [];
    if (row.registered || row.startDate) { // Already Registered/startDated Publishers do not change registeredIds status on select
      newRegisteredIds = [...registeredIds];
    } else if (add) {
      if (row.type === 'Publisher') {
        newRegisteredIds = [...registeredIds, row.id];
      }
      if (row.type === 'Publisher Group' && row.publishersInGroup) {
        newRegisteredIds = [...registeredIds, ...row.publishersInGroup];
      }
    } else {
      if (row.type === 'Publisher') {
        newRegisteredIds = registeredIds.filter((publisher) => publisher !== row.id);
      }
      if (row.type === 'Publisher Group' && row.publishersInGroup) {
        newRegisteredIds = registeredIds.filter((publisher) => !row.publishersInGroup?.includes(publisher));
      }
    }
    setRegisteredIds(newRegisteredIds);

    // Create the new PublisherRows with updated registeredIds
    const newPublisherRows = [...completePublisherList];
    const targetIndex = newPublisherRows.findIndex((obj) => obj.id === row.id);
    if (targetIndex === -1) return;
    newPublisherRows[targetIndex].selected = add;

    // Disable the Publisher/PublisherGroup Rows that are not registered or selected
    // and have an overlapping Id with the registeredIds.
    const publisherRowsDisableChecked = newPublisherRows.map((pub) => {
      if (pub.type === 'Publisher') {
        if (!(pub.registered || pub.selected || pub.startDate) && newRegisteredIds.some((regId) => regId === pub.idNum.toString())) {
          return { ...pub, disabled: true };
        }
      }
      if (pub.type === 'Publisher Group') {
        if (!(pub.registered || pub.selected || pub.startDate) && newRegisteredIds.some((regId) => pub.publishersInGroup?.includes(regId))) {
          return { ...pub, disabled: true };
        }
      }
      return { ...pub, disabled: false };
    });

    // exemption for not registered, only if they are search removable
    correctDisabledStatus(publisherRowsDisableChecked);
    setCompletePublisherList(publisherRowsDisableChecked);
    const resortedPublishers = sortPublisherRows(sortByDateSelected.value, sortByOrderSelected.value === 'asc' ? 'asc' : 'desc', publisherRowsDisableChecked, search);
    setPublisherList(resortedPublishers);
    setTableData(resortedPublishers.slice((currentPage - 1) * TABLE_ROW_SIZE, currentPage * TABLE_ROW_SIZE));
  };

  const setStartCalendarHandler = (state: boolean) => {
    setStartCalendarOpen(state);
  };

  const onApplyStartCalendarHandler = (newStartDate: Date) => {
    setStartCalendarOpen(false);
    setStartDate(newStartDate);
    // EndDate can't be earlier then startDate
    setEndDateMin(newStartDate);
  };

  const setEndCalendarHandler = (state: boolean) => {
    setEndCalendarOpen(state);
  };

  const onApplyEndCalendarHandler = (newEndDate: Date) => {
    setEndCalendarOpen(false);
    setEndDate(newEndDate);
    // StartDate can't be after EndDate
    setStartDateMax(newEndDate);
  };

  const updateDateHandler = () => {
    setDateWarning(true);
    // Update the Complete Publisher List Row Values
    const newPublisherRows = completePublisherList.map((row) => {
      // Update Rows that are selected
      if (publishersSelected.includes(row.id)) {
        const newRow = { ...row };
        // Already Registered Rows can't change startDate
        if (row.registered && row.startDate && endDate) {
          if (row.startDate <= endDate) {
            newRow.endDate = endDate;
          }
        }
        // If not Registered Row freely change startDate and endDate
        if (!row.registered) {
          if (startDate === undefined && detailsStartDate !== undefined) {
            if (detailsStartDate < new Date()) {
              newRow.startDate = new Date();
            } else {
              newRow.startDate = detailsStartDate || null;
            }
          } else if (startDate !== undefined) {
            newRow.startDate = startDate || null;
          }

          if (endDate !== undefined) {
            newRow.endDate = endDate || null;
          }
        }
        return newRow;
      }
      return row;
    });

    // Update Publisher Lists values
    setCompletePublisherList(newPublisherRows);
    const resortedPublishers = sortPublisherRows(sortByDateSelected.value, sortByOrderSelected.value === 'asc' ? 'asc' : 'desc', newPublisherRows, search);
    setPublisherList(resortedPublishers);
    setTableData(resortedPublishers.slice((currentPage - 1) * TABLE_ROW_SIZE, currentPage * TABLE_ROW_SIZE));
  };

  const navigateBack = () => {
    if (typeof hookLocation.state === 'object' && hookLocation.state?.goBack) {
      navigate(hookLocation.state?.goBack);
    } else {
      navigate(`${MERCHANT_PREFIX}${path.manageCommissions.href}`);
    }
  };

  // Should be triggered if Commission is 'Custom'
  if (getPublisherInfo) {
    setGetPublisherInfo(false);
    getMerchantsPublisherInfo();
  }

  useEffect(() => {
    if (secondRender) {
      handleValidation();
    }
  }, [secondRender]);

  useEffect(() => {
    if (comId === null) {
      setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE('Commission not found'));
      return;
    }
    getCommissionHandler();
  }, [window.location.href]);

  return {
    // Global
    hookPageLoading: getCommissionLoading || updateCommissionLoading || getPublisherGroupsLoading || getMembershipsLoading,
    hookLoadingMessage: loadingMessage,
    hookErrorMessage: errorMessage,
    hookNavigateBack: navigateBack,

    // Commission
    hookCommissionData: commissionData,
    hookIsCustom: isCustom,
    hookSaveCommissionLoading: updateCommissionLoading,
    hookCommissionType: commissionType,
    commissionName,
    commissionDescription,
    commissionStructureSentence,
    setCommissionNameHandler,
    detailsStartDateStringFormat,
    detailsEndDateStringFormat,
    commissionNameExists,
    selectedPublisherGroupsDialog,
    disableStartDate,
    disableDates,

    // Buttons
    hookSaveCommission: saveCommissionHandler,
    saveCommissionClickHandler,

    // Details Tab
    hookDetailsEndDate: detailsEndDate,
    hookDetailsStartDate: detailsStartDate,
    hookDetailsMinDate: detailsMinDate,
    hookDetailsCalendarOpen: detailsCalendarOpen,
    hookSetDetailsCalendarState: setDetailsCalendarHandler,
    hookOnApplyDetailsCalendar: onApplyDetailsCalendarHandler,

    // Publishers/Groups Tab
    // Table
    hookTableData: tableData,
    hookPageTotal: totalPages,
    hookCurrentPage: currentPage,
    hookSetPage: handleSetPage,
    hookHandleChangeCheck: handleChangeCheck,

    // Filters/Dropdowns
    hookSearch: search,
    hookSearchHandler: searchHandler,
    hookSortByOrderSelected: sortByOrderSelected,
    hookSetSortByOrderSelected: handleSetSortByOrderSelected,
    hookSortByDateSelected: sortByDateSelected,
    hookSetSortByDateSelected: handleSetSortByDateSelected,

    // Update Footer Bar
    // Start Calendar
    hookStartDate: startDate,
    hookStartDateMin: startDateMin,
    hookStartDateMax: startDateMax,
    hookStartCalendarOpen: startCalendarOpen,
    hookSetStartCalendarState: setStartCalendarHandler,
    hookOnApplyStartCalendar: onApplyStartCalendarHandler,
    // End Calendar
    hookEndDate: endDate,
    hookEndDateMin: endDateMin,
    hookEndDateMax: endDateMax,
    hookEndCalendarOpen: endCalendarOpen,
    hookSetEndCalendarState: setEndCalendarHandler,
    hookOnApplyEndCalendar: onApplyEndCalendarHandler,
    // Update Button
    hookUpdateDateWarning: updateDateWarning,
    hookUpdateDateHandler: updateDateHandler,
    hookEditCommissionErrors: editCommissionErrors,

    // Modal
    isEditCommissionModalOpen,
    setIsEditCommissionModalOpen,
    handleCommissionConfirmation,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),

    hookClearSearch: clearSearch,
  };
};
