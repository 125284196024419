import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../../../../components';
import { toRem } from '../../../../../../utils';

export const HeaderStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${toRem(40)} 0;
`;

export const FilterWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  white-space: nowrap;
`;

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  white-space: nowrap;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1.5rem;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

export const PaginationWrapper = styled.div`
  margin-bottom: 5rem;
`;
