import styled from 'styled-components';
import { Button } from '../../../../../components';
import { colors, fonts } from '../../../../../styles/theme';
import { toRem } from '../../../../../utils';

export const ModalContentWrapperStyled = styled.div`
  width: ${toRem(466)};
  padding: ${toRem(34)};
`;

export const ModalTitleStyled = styled.div`
  ${fonts.gotham.H26M}
  line-height: ${toRem(40)};
`;

export const ModalDescriptionStyled = styled.div`
  ${fonts.lato.H16}
  line-height: ${toRem(24)};
  color: ${colors.color7};
  margin: 0 0 ${toRem(40)};
`;

export const ModalSelectionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${toRem(40)};
`;

export const ModalButtonsStyled = styled.div`
  margin-top: ${toRem(40)};
  display: flex;
  justify-content: end;
`;

export const ModalButtonStyled = styled(Button)`
  margin-left: ${toRem(10)};
`;
