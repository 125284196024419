import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from '../Button';
import * as Styled from './styles';

type PlaceholderProps = {
  title: string;
  subtitle?: string;
  buttonText?: string;
  buttonIcon?: IconName | IconProp;
  placeholderImage?: { src: string, alt: string }
  action?: () => void;
  isReadOnly?: boolean;
};

export const Placeholder = ({
  title,
  subtitle,
  buttonText,
  buttonIcon,
  action,
  placeholderImage,
  isReadOnly = false,
}: PlaceholderProps) => (
  <Styled.WrapperStyled>
    <Styled.TitleStyled>
      {title}
    </Styled.TitleStyled>

    <Styled.SubtitleStyled>
      {subtitle}
    </Styled.SubtitleStyled>

    {placeholderImage && (
      <Styled.ImageStyled
        src={placeholderImage.src}
        alt={placeholderImage.alt}
      />
    )}

    {action && buttonIcon && (
      <Button
        onClick={action}
        disabled={isReadOnly}
      >
        <FontAwesomeIcon
          icon={buttonIcon}
          style={{ marginRight: 5 }}
        />
        {buttonText}
      </Button>
    )}
  </Styled.WrapperStyled>
);
