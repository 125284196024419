import styled from 'styled-components';
import {
  Button, InputText, Select, InputTextarea,
} from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderButtonsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${toRem(16)};
`;

export const NetworkSectionStyled = styled.div`
  ${fonts.lato.H16}
  margin: ${toRem(30)} 0;
`;

export const MemberSectionStyled = styled.div`
  margin-bottom: ${toRem(40)};
`;

export const SectionTitleStyled = styled.div`
  ${fonts.gotham.H22}
  color: ${colors.color1};
  margin-bottom: ${toRem(40)};
`;

export const NetworkContentStyled = styled.div`
  margin: ${toRem(30)} 0 ${toRem(50)};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2%;
`;

export const SectionTextareaStyled = styled(InputTextarea)`
  grid-column: 1 / 3;
  width: 100%;
`;

export const SectionSelectStyled = styled(Select)`
  grid-column: 3;
`;

export const SectionDescriptionStyled = styled(InputTextarea)`
  grid-column: 1 / 4;
`;

export const SectionTCStyled = styled.div`
  margin-top: ${toRem(40)};
`;

export const SectionTCTitleStyled = styled.div`
  ${fonts.gotham.H12B}
  color: ${colors.color13};
  margin-bottom: ${toRem(16)};
  text-transform: uppercase;
`;

export const SectionTCLastUpdateStyled = styled.i`
  ${fonts.lato.H16}
  font-style: italic;
`;

export const SectionTCNoteStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H16}
  margin-top: ${toRem(8)};
`;

export const SectionTCButtonStyled = styled(Button)`
  padding: 0;
  margin-top: 1px;
  margin-left: 5px;
`;

export const MemberContentStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2%;
  row-gap: 1.5rem;
`;

export const SectionInput2columnsStyled = styled(InputText)`
  grid-column: 1 / 3;
`;

export const Section2ColumnSelectStyled = styled(Select)`
  grid-column: 1 / 3;
`;

export const SectionInput3columnsStyled = styled(InputText)`
  grid-column: 1 / 4;
`;

export const TCInfoSectionStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${toRem(24)};
  margin: ${toRem(40)} 0;
`;

export const TCInfoBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TCInfoBlockTitleStyled = styled.div`
  text-transform: uppercase;
  ${fonts.gotham.H12B}
  color: ${colors.color13};
  margin-bottom: ${toRem(16)};
`;

export const TCInfoBlockInfoStyled = styled.div`
`;

export const TCEditTitleStyled = styled.div`
  margin-bottom: ${toRem(40)};
`;

export const TCPreviewBoxStyled = styled.div`
  max-width: 1200px;
`;

export const TCPreviewTitleStyled = styled.div`
  ${fonts.gotham.H32M}
  margin-bottom: ${toRem(32)};
`;

export const TCPreviewContentStyled = styled.div`
  ${fonts.lato.H16}
  line-height: ${toRem(24)};

  strong {
    ${fonts.lato.H16B}
  }
`;
