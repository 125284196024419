import styled from 'styled-components';
import { colors, fonts } from '../../../../../../styles/theme';
import { toRem } from '../../../../../../utils';

export const DescriptionStyled = styled.div`
  color: ${colors.color1};
  ${fonts.gotham.H14M}
  margin-bottom: 2rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${toRem(24)};
`;
export const CellStyled = styled.div`
  display: flex;
  min-height: 36px;
  align-items: center;
  text-overflow: ellipsis;
  flex-flow: wrap row;
  text-transform: capitalize;
`;

export const ActionStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80px;

  svg {
    color: ${colors.color1};

    &:hover {
      color: ${colors.color2};
      cursor: pointer;
    }
  }
`;
