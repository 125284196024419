import styled from 'styled-components';
import { Button, InputText, Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const SelectStyled = styled(Select)`
  width: 192px;
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

export const HeaderButtonAreaStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const FiltersStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ExtendedFiltersStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 550px;
`;

export const FilterByLabelStyled = styled.div`
  ${fonts.lato.H16}
  color: ${colors.color1};
`;

export const InputTextStyled = styled(InputText)`
  width: calc(100% - 570px);
`;

export const RecordsDropdownWrapper = styled.div`
  margin: 0 0 1.5rem 0;
`;
