import { gql } from '@apollo/client';

export const MERCHANT_DASHBOARD_ONE = gql`
  query MerchantDashboardOne($input: MerchantDashboardInput!) {
    merchantDashboardOne(input: $input) {
      announcementsNumber
      messageHubMessagesNumber
      pendingTransactions
      clicks {
        desktop
        mobile
        tablet
        desktopPercentage
        mobilePercentage
        tabletPercentage
        desktopPerformanceIncrease
        mobilePerformanceIncrease
        tabletPerformanceIncrease
        total
        canadaTotal
        usTotal
        countries {
          name
          clicks
          country
        }
        canada {
          id
          name
          clicks
        }
        us {
          id
          name
          clicks
        }
      }
      merchantPerformance {
        approvedTransactions
        totalTransactions
        commissions
      }
      isBellowMinimunBalance
    }
  }
`;

export const MERCHANT_DASHBOARD_TWO = gql`
  query MerchantDashboardTwo($input: MerchantDashboardInput!) {
    merchantDashboardTwo(input: $input) {
      topProducts {
        id
        totalCommissions
        totalTransactions
        approvedTransactions
        clicks
        commissionIncrease
        product {
          name
        }
      }
      topPublishers {
        id
        totalCommissions
        approvedTransactions
        commissionIncrease
        approvedTransactionsIncrease
        publisher {
          companyName
        }
      }
      activePublishersByClick {
        prevPerformance
        currentPerformance
        todaysPerformance
        performanceIncrease
      }
      productsByApprovals {
        count
        productCategory
      }
    }
  }
`;

export const MERCHANT_DASHBOARD_THREE = gql`
  query MerchantDashboardThree($input: MerchantDashboardInput!) {
    merchantDashboardThree(input: $input) {
      commissions {
        prevPerformance
        currentPerformance
        todaysPerformance
        performanceIncrease
      }
      approvedTransactions {
        prevPerformance
        currentPerformance
        todaysPerformance
        performanceIncrease
      }
    }
  }
`;

export const MERCHANT_DASHBOARD_FOUR = gql`
  query MerchantDashboardFour($input: MerchantDashboardInput!) {
    merchantDashboardFour(input: $input) {
      pendingApplications
      activePublishersByApprovals {
        prevPerformance
        currentPerformance
        todaysPerformance
        performanceIncrease
      }
      conversionRate {
        prevPerformance
        currentPerformance
        todaysPerformance
        performanceIncrease
      }
    }
  }
`;
