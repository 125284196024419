import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../../components';

export const HeaderWrapperSyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
  white-space: nowrap;
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${ButtonStyled}:first-of-type {
    margin: 0;
  }
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

export const SearchWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  gap: 3rem;
`;

export const PaginationWrapper = styled.div`
  margin-bottom: 5rem;
`;
