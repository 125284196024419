import { gql } from '@apollo/client';

export const SHOW_AD = gql`
  query ad($input: ShowAdInput) {
    showAd(input: $input) {
      id
      adName
      adContent
      adType
      adName
      height
      width
      createdAt
      updatedAt
      defaultLandingPage
      campaignName
      merchantName
      publisherGroups
      description
    }
  }
`;
