import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

export const HeaderStyled = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const TabWrapperStyled = styled.div`
  width: 100%;
`;

export const TabNoRecordsStyled = styled.div`
  width: 100%;
  margin-top: ${toRem(150)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TabNoRecordsTitleStyled = styled.div`
  ${fonts.lato.H24B}
  margin-bottom: ${toRem(20)};
`;

export const TabNoRecordsDescriptionStyled = styled.div`
  ${fonts.lato.H16}
  margin-bottom: ${toRem(20)};
`;

export const TabReportLinkStyled = styled(NavLink)`
  text-decoration: none;
`;

export const ModalStyled = styled.div`
  padding: ${toRem(12)};
`;

export const ModalTitleStyled = styled.div`
  ${fonts.gotham.H24M}
`;

export const ModalFiltersWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: ${toRem(32)} 0;
`;

export const ModalFilterStyled = styled.div`
  width: 60%;
  margin-bottom: ${toRem(20)};

  p:first-of-type {
    ${fonts.lato.H12}
    color: ${colors.color13};
    margin-bottom: ${toRem(8)};
  }

  p:last-of-type {
    ${fonts.lato.H16}
  }
`;

export const ModalCustomColumnsStyled = styled.div`
  ${fonts.lato.H12}
  color: ${colors.color13};
`;

export const ModalCustomColumnsListStyled = styled.ol`
  ${fonts.lato.H14}
  color: ${colors.color10};
  margin-top: ${toRem(12)};
  display: flex;
  flex-wrap: wrap;
`;

export const ModalCustomColumnsListItemStyled = styled.li`
  width: 33%;
  margin-bottom: ${toRem(12)};
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  justify-content: end;
`;
