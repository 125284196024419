import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import * as Styled from '../styles';
import { LinkProps } from '../types';
import { Button } from '../../../../../../../../components';
import { MERCHANT_PREFIX, path } from '../../../../../../../../utils';
import { FINTEL_CHECK_ACTIONS } from '../../../../../reducers';

export const SummaryCell = ({ row, setContext }: LinkProps) => {
  const navigate = useNavigate();
  return (
    <Button
      theme="text-only"
      onClick={() => {
        navigate(`${MERCHANT_PREFIX}${path.fintelCheckHistoryReport.href}`);
        setContext({
          type: FINTEL_CHECK_ACTIONS.SET_DATE,
          data: row.checkDate,
        });
        setContext({
          type: FINTEL_CHECK_ACTIONS.SET_ZIP_FILE,
          data: row.zipfiles,
        });
      }}
    >
      <Styled.IconCell>
        <Styled.IconStyled icon={faChevronRight} />
      </Styled.IconCell>
    </Button>
  );
};
