import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CellProps } from '../types';
import * as Styled from '../styles';
import { MERCHANT_PREFIX, path } from '../../../../../../../../utils';

export const DetailsCell = ({ val }: CellProps) => {
  const navigate = useNavigate();
  return (
    <Styled.CellStyled>
      {val}
      <Styled.LinkStyled
        theme="text-only"
        onClick={() => navigate(`${MERCHANT_PREFIX}${path.fintelCheckRuleDetails.href}`)}
      >
        View Details
      </Styled.LinkStyled>
    </Styled.CellStyled>
  );
};
