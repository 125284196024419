import { gql } from '@apollo/client';

export const CHECK_IF_NEW_USER = gql`
  query User($userId: ID!) {
    user(id: $userId) {
      newUser
      auth0Id
    }
  }
`;
