export const INVITATION_TEMPLATE = {
  DESC: {
    ONE: 'An invitation template is prepared content that you can use to invite publishers to join your program.',
    TWO: 'Here you can manage your invitation templates.',
  },
  BUTTON: {
    NEW_TEMPLATE: 'New Template',
  },
  PLACEHOLDER: {
    TITLE: 'No templates found',
    SUBTITLE: 'When you create an invitation template, they will show up here.',
  },
};
