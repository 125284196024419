import { gql } from '@apollo/client';

export const GET_TRANSACTIONS_LIST = gql`
  query getTransactions($input: TransactionSearchInput!) {
    transactions(input: $input) {
      count
      totalPages
      currentPage
      limit
      transactions {
        transactionStatus
        id
        createdAt
        merchant {
          id
          companyName
        }
        orderId
        customerId
        ad {
          id
        }
        trackingProfile {
          id
        }
        product {
          id
          name
        }
        publisher {
          id
          companyName
        }
        netSale
        grossSale
        currency
      }
    }
  }
`;
