export const MerchantPublisherOptionsMaker = (options: any[], name: string) => {
  const orginalOptions = options.map((obj) => ({
    label: `${obj.id} - ${obj.companyName}`,
    value: obj.id,
  }));
  if (name === 'Publisher') {
    orginalOptions.unshift({ label: 'All Publishers', value: '1' });
  } else {
    orginalOptions.unshift({ label: 'All Merchants', value: '1' });
  }
  return orginalOptions;
};
