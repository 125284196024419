export const PUBLISHER_INVITATION = {
  INVITATION_HISTORY: 'Invitation History',
};

export const TABS = ['Invite Publishers', 'Invitation Template'];

export const TABLE_HEADER = {
  ID: 'ID',
  COMPANY_NAME: 'Company Name',
  AUDIENCE_MARKETS: 'Audience Markets',
  MONTHLY: 'Monthly Audience Size',
  PROMOTION_METHOD: 'Promotion Method',
  STATUS: 'Status',
};

export const PAGE_TYPES = {
  INVITE: 'INVITE',
  MAIN: 'MAIN',
};

export const STATUS_LABALS = {
  HOLD: 'Invitations on Hold',
};

export const MEMBERSHIP_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  CLOSED: 'Closed',
  DECLINED: 'Declined',
  INVITED: 'Invited',
  CLOSED_BY_PUBLISHER: 'Closed By Publisher',
  CLOSED_BY_MERCHANT: 'Closed By Merchant',
  INVITATION_DECLINED: 'Invitation Declined',
};

export const TEMPLATE_ACTIONS = {
  COPY: 'COPY',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
};

export const TOAST_MESSAGES = {
  COPY: {
    MESSAGE: 'Your template was duplicated successfully.',
    ACTION_LABEL: 'View Template',
  },
  DELETE: { MESSAGE: 'Your template was deleted successfully.' },
};
