import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MEMBERSHIPS_STATUS, MERCHANT_PREFIX, RECORDS_PER_PAGE_OPTIONS, dateFormatter, path, toUTCHours, csvGenerator,
  useDebounce,
} from '../../../../../utils';
import {
  ALL_ENGLISH_SPEAKING_COUNTRIES, csvColumns, DEFAULT_GROUP, DEFAULT_MEMBERSHIPS_DATA, DEFAULT_MENUS, MODAL_TYPES, TABS,
} from '../contracts';
import { useModal, useUserInfo } from '../../../../../hooks';
import { GET_GROUPS, GET_MEMBERSHIPS } from '../graphql/queries';
import { UPDATE_MEMBERSHIPS } from '../graphql/mutations';
import {
  formatDataForCSV, processDataToCSVString, sortMemberships, formatMemberships, checkActionAvailability,
} from '../utils';
import type { AllMemberTypesType, PublisherMember, SelectedPublisherType } from '../types';
import type {
  GetMembershipsInput, GetMembershipsOutput, GMMembershipType, GetGroupsInput, GetGroupsOutput,
} from '../graphql/queries';
import type { UpdateMembershipsInput, UpdateMembershipsOutput } from '../graphql/mutations';
import { Permission } from '../../../../../entities';

export const useMembershipManagement = (permissionsCodeList: string[] = []) => {
  // Global Values
  const navigate = useNavigate();
  const { hookWhoAmI } = useUserInfo();

  // Page State
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [membershipsLoaded, setMembershipsLoaded] = useState<boolean>(false);

  // Filters
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 800);
  const [publisherSelectOptions, setPublisherSelectOptions] = useState<SelectOption[]>([]);
  const [selectedPublisherGroup, setSelectedPublisherGroup] = useState<SelectOption | undefined>();
  const [selectedLocation, setSelectedLocation] = useState<SelectOption | undefined>();
  const [perPage, setPerPage] = useState(RECORDS_PER_PAGE_OPTIONS[0]);

  // Calendars
  const [openFromCalendar, setOpenFromCalendar] = useModal(false);
  const [fromCalendarDate, setFromCalendarDate] = useState<Date>();
  const [inputFromCalendarDate, setInputFromCalendarDate] = useState<string>('');
  const [openToCalendar, setOpenToCalendar] = useModal(false);
  const [toCalendarDate, setToCalendarDate] = useState<Date>();
  const [inputToCalendarDate, setInputToCalendarDate] = useState<string>('');

  // Membership Data States
  const [allData, setAllData] = useState<AllMemberTypesType>(DEFAULT_MEMBERSHIPS_DATA); // All the Membership Data after filtering out Closed Publishers
  const [filteredData, setFilteredData] = useState<AllMemberTypesType>(DEFAULT_MEMBERSHIPS_DATA); // The Membership Data after filtering from local filters

  // Tab State
  const [statusType, setStatusType] = useState(TABS.PENDING_APPLICATIONS.value);
  const [tabMemberCount, setTabMemberCount] = useState<string[]>(DEFAULT_MENUS);

  // Table State
  const [tableData, setTableData] = useState<PublisherMember[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const [selectedPublishers, setSelectedPublishers] = useState<SelectedPublisherType[]>([]);
  const [headerCheck, setHeaderCheck] = useState<boolean>(false);

  // Footer & Update Modals
  const [declineButtonDisable, setDeclineButtonDisable] = useState(true);
  const [approveButtonDisable, setApproveButtonDisable] = useState(true);
  const [modalType, setModalType] = useState('');
  const [approveOpenModal, setApproveOpenModal] = useModal(false);

  // Mutations & Queries
  const [getMemberships, { loading: membershipsLoading }] = useLazyQuery<GetMembershipsOutput, GetMembershipsInput>(GET_MEMBERSHIPS);
  const [getGroups, { loading: groupsLoading }] = useLazyQuery<GetGroupsOutput, GetGroupsInput>(GET_GROUPS);
  const [updateMemberships, { loading: updateMembershipsLoading }] = useMutation<UpdateMembershipsOutput, UpdateMembershipsInput>(UPDATE_MEMBERSHIPS);

  const tableDataChangeHandler = (newData: PublisherMember[]) => {
    setTableData(newData);
    setHeaderCheck(false);
    setSelectedPublishers([]);
  };

  /**
   * Format the memberships then split them into the correct buckets.
   */
  const ProcessMemberships = (memberships: GMMembershipType[]) => {
    const formattedMemberships: PublisherMember[] = formatMemberships(memberships);
    const pendingApplications: PublisherMember[] = [];
    const pendingInvitations: PublisherMember[] = [];
    const activeMemberships: PublisherMember[] = [];
    const inactiveMemberships: PublisherMember[] = [];

    formattedMemberships.forEach((membership) => {
      const isInvitation = !!membership?.publisherInvitation?.id;
      const lastUpdated = membership?.statusLastUpdatedBy;

      // pending application
      if (membership?.status === MEMBERSHIPS_STATUS.PENDING && (lastUpdated !== 'Merchant' || (!lastUpdated && !isInvitation))) {
        pendingApplications.push(membership);
      // pending invitation
      } else if (membership?.status === MEMBERSHIPS_STATUS.PENDING && (lastUpdated === 'Merchant' || (!lastUpdated && isInvitation))) {
        pendingInvitations.push(membership);
      // active membership
      } else if (membership?.status === MEMBERSHIPS_STATUS.APPROVED) {
        activeMemberships.push(membership);
      // inactive membership
      } else {
        inactiveMemberships.push(membership);
      }
    });
    return {
      pendingApplications,
      pendingInvitations,
      activeMemberships,
      inactiveMemberships,
    };
  };

  /**
   * Creates the Values displayed on the tabs.
   */
  const createTabValues = (data?: AllMemberTypesType) => {
    const memberships = data || filteredData;
    return [
      `${TABS.PENDING_APPLICATIONS.label} (${memberships.pendingApplications.length})`,
      `${TABS.PENDING_INVITATIONS.label} (${memberships.pendingInvitations.length})`,
      `${TABS.ACTIVE_MEMBERSHIPS.label} (${memberships.activeMemberships.length})`,
      `${TABS.INACTIVE_MEMBERSHIPS.label} (${memberships.inactiveMemberships.length})`,
    ];
  };

  const setCurrentPageHandler = (newPage: number) => {
    setCurrentPage(newPage);
    if (Array.isArray(filteredData[statusType as keyof AllMemberTypesType])) {
      tableDataChangeHandler(filteredData[statusType as keyof AllMemberTypesType].slice((newPage - 1) * Number(perPage.value), newPage * Number(perPage.value)));
    } else {
      tableDataChangeHandler([]);
    }
  };

  const getMembershipsHandler = async () => {
    setIsLoading(true);
    setErrorMessage('');
    const { data } = await getMemberships({
      variables: {
        input: {
          programId: hookWhoAmI.programId || '0',
        },
      },
      fetchPolicy: 'no-cache',
      onError(error) {
        setErrorMessage(error.message);
      },
    });
    setMembershipsLoaded(true);
    if (data && data.memberships) {
      const noClosedAccounts = data.memberships.memberships.filter((mem) => mem.publisher.accountStatus !== MEMBERSHIPS_STATUS.CLOSED && mem.publisher.accountStatus !== MEMBERSHIPS_STATUS.DECLINED && mem.publisher.accountStatus !== MEMBERSHIPS_STATUS.PENDING);
      const processedMemberships = ProcessMemberships(noClosedAccounts);
      setAllData(processedMemberships);
    } else {
      setAllData(DEFAULT_MEMBERSHIPS_DATA);
    }
    setIsLoading(false);
  };

  const updateDisableButtons = (enableButton: boolean) => {
    if (!enableButton) {
      setDeclineButtonDisable(true);
      setApproveButtonDisable(true);
      return;
    }
    switch (statusType) {
      case TABS.PENDING_APPLICATIONS.value:
        setDeclineButtonDisable(false);
        setApproveButtonDisable(false);
        break;
      case TABS.PENDING_INVITATIONS.value:
        setDeclineButtonDisable(true);
        setApproveButtonDisable(true);
        break;
      case TABS.ACTIVE_MEMBERSHIPS.value:
        setDeclineButtonDisable(false);
        setApproveButtonDisable(true);
        break;
      case TABS.INACTIVE_MEMBERSHIPS.value:
        setDeclineButtonDisable(true);
        setApproveButtonDisable(false);
        break;
      default:
        break;
    }
  };

  const getSelectionFields = async () => {
    setErrorMessage('');
    const { data } = await getGroups({
      variables: {
        programId: hookWhoAmI.programId || '0',
      },
      fetchPolicy: 'no-cache',
      onError(error) {
        setErrorMessage(error.message);
      },
    });

    if (data && data.groups) {
      const groupOptions = [DEFAULT_GROUP, ...data.groups.groups.map((group) => ({ label: group.name, value: group.id }))];
      setPublisherSelectOptions(groupOptions);
    }
  };

  const clearFilterHandler = () => {
    setSearch('');
    setFromCalendarDate(undefined);
    setToCalendarDate(undefined);
    setInputFromCalendarDate('');
    setInputToCalendarDate('');
    setSelectedLocation(undefined);
    setSelectedPublisherGroup(undefined);
    setHeaderCheck(false);
    updateDisableButtons(false);
    setCurrentPage(1);
    setSortColumn(undefined);
    setSelectedPublishers([]);
    setHeaderCheck(false);
    setTableData(tableData.map((member) => ({
      ...member,
      checked: false,
    })));
  };

  const downloadCSVHandler = () => {
    const formattedData = formatDataForCSV(filteredData[statusType as keyof AllMemberTypesType]);
    const csvString = processDataToCSVString(formattedData, csvColumns);
    csvGenerator(csvString, statusType.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`));
  };

  const setSelectedPublishersHandler = (publisherInfo: SelectedPublisherType, value: boolean) => {
    if (!value) {
      setSelectedPublishers(selectedPublishers.filter((publisher) => publisher.membershipId !== publisherInfo.membershipId));
    } else {
      setSelectedPublishers([...selectedPublishers, publisherInfo]);
    }
    setTableData(tableData.map((member) => ({
      ...member,
      checked: publisherInfo.publisherId === member.publisherId ? value : member.checked,
    })));
    setHeaderCheck(value === false ? false : selectedPublishers.length + 1 === tableData.length);
    updateDisableButtons(value === true ? true : selectedPublishers.length - 1 > 0);
  };

  const setSelectedPublishersAllHandler = (value: boolean) => {
    setHeaderCheck(value);
    if (value) {
      const allMembers = tableData.filter((member) => checkActionAvailability('Merchant', member)).map((member) => ({ publisherId: member.publisherId, publisherName: member.companyName, membershipId: member.id.toString() }));
      setSelectedPublishers(allMembers);
      setTableData(tableData.map((member) => ({
        ...member,
        checked: checkActionAvailability('Merchant', member),
      })));
    } else {
      setSelectedPublishers([]);
      setTableData(tableData.map((member) => ({
        ...member,
        checked: false,
      })));
    }

    updateDisableButtons(value);
  };

  const navigationHandler = (row: PublisherMember) => {
    navigate(`${MERCHANT_PREFIX}${path.membershipDetails.href}`, {
      state: {
        publisherId: row.publisherId,
        membershipId: row.id,
      },
    });
  };

  const submitHandler = async (type: string) => {
    if (selectedPublishers.length === 0) return;
    let newStatus: 'Approved' | 'Closed' | 'Declined' = 'Declined';
    if (type === MODAL_TYPES.APPROVE) newStatus = 'Approved';
    if (type === MODAL_TYPES.CLOSE) newStatus = 'Closed';

    const inputDate = type === MODAL_TYPES.APPROVE ? new Date() : null;
    const updateInput: UpdateMembershipsInput = {
      inputs: selectedPublishers.map((pub) => ({
        id: pub.membershipId,
        approvedDate: inputDate,
        status: newStatus,
      })),
      status: newStatus,
    };

    setErrorMessage('');
    await updateMemberships({
      variables: updateInput,
      fetchPolicy: 'no-cache',
      onError(error) {
        setErrorMessage(error.message);
      },
    });
    setApproveOpenModal();
    setModalType('');
    getMembershipsHandler();
    getSelectionFields();
  };

  const setStatusTypeHandler = (status: string) => setStatusType(status);
  const setSearchHandler = (value: React.ChangeEvent<HTMLInputElement>) => setSearch(value.target.value);

  const setInputFromCalendarHandler = () => setOpenFromCalendar();
  const setFromCalendarDateHandler = (date: Date) => {
    setFromCalendarDate(date);
    setInputFromCalendarDate(dateFormatter(date, ','));
    setOpenFromCalendar();
  };

  const setInputToCalendarHandler = () => setOpenToCalendar();
  const setToCalendarDateHandler = (date: Date) => {
    setToCalendarDate(date);
    setInputToCalendarDate(dateFormatter(date, ','));
    setOpenToCalendar();
  };

  const setSelectedPublisherGroupHandler = (value: SelectOption) => setSelectedPublisherGroup(value);
  const setSelectedLocationHandler = (value: SelectOption) => setSelectedLocation(value);

  const setPerPageHandler = (value: SelectOption) => {
    setPerPage(value);
    setCurrentPage(1);
    if (Array.isArray(filteredData[statusType as keyof AllMemberTypesType])) {
      tableDataChangeHandler(filteredData[statusType as keyof AllMemberTypesType].slice(0, Number(value.value)));
      setTotalPages(Math.ceil((filteredData[statusType as keyof AllMemberTypesType].length) / Number(value.value)));
    } else {
      tableDataChangeHandler([]);
      setTotalPages(0);
    }
  };

  const setApproveOpenModalHandler = (type: string) => {
    setModalType(type);
    setApproveOpenModal();
  };

  const handleSort = (dataField: string, direction: any) => {
    if (dataField === 'checkBox') return;
    const newDirection = (sortColumn?.direction === 'asc' && direction !== undefined) ? 'desc' : 'asc';
    setSortColumn({ column: dataField, direction: newDirection });
    const newFilterData = {
      pendingApplications: sortMemberships(filteredData.pendingApplications, dataField, newDirection),
      pendingInvitations: sortMemberships(filteredData.pendingInvitations, dataField, newDirection),
      activeMemberships: sortMemberships(filteredData.activeMemberships, dataField, newDirection),
      inactiveMemberships: sortMemberships(filteredData.inactiveMemberships, dataField, newDirection),
    };
    if (Array.isArray(newFilterData[statusType as keyof AllMemberTypesType])) {
      tableDataChangeHandler(newFilterData[statusType as keyof AllMemberTypesType].slice(0, Number(perPage.value)));
    }
    setFilteredData(newFilterData);
  };

  /**  Applies all the filters to the Membership Array argument received */
  const filterMemberships = (memberships: PublisherMember[]) => {
    let newMemberships: PublisherMember[] = memberships;

    // Search Filter
    if (debouncedSearch) {
      const searchString = Number(debouncedSearch);
      if (Number.isNaN(searchString)) {
        newMemberships = newMemberships.filter(
          (membership) => membership.companyName.toLowerCase().includes(debouncedSearch.toLowerCase())
            || membership.emailString.toLowerCase().includes(debouncedSearch.toLowerCase())
            || membership.trackingUrlString.toLowerCase().includes(debouncedSearch.toLowerCase()),
        );
      } else {
        newMemberships = newMemberships.filter(
          (membership) => membership.publisher.id.includes(debouncedSearch)
            || membership.publisher.trackings.map((tracking) => tracking.id).includes(debouncedSearch),
        );
      }
    }

    // From Date Filter
    if (fromCalendarDate) {
      const startOfDay = toUTCHours(fromCalendarDate, 'beginning');
      newMemberships = newMemberships.filter((membership) => new Date(membership.applicationDate) >= startOfDay);
    }

    // To Date Filter
    if (toCalendarDate) {
      const endOfDay = toUTCHours(toCalendarDate, 'end');
      newMemberships = newMemberships.filter((membership) => endOfDay >= new Date(membership.applicationDate));
    }

    // Publisher Group Filter
    if (selectedPublisherGroup && selectedPublisherGroup.value !== '') {
      newMemberships = newMemberships.filter((membership) => membership.publisherGroups.some((group) => group.id === selectedPublisherGroup.value));
    }

    // Select Location Filter
    if (selectedLocation && selectedLocation.value !== '' && selectedLocation.value !== 'All') {
      if (selectedLocation.value === 'Any English speaking country') {
        newMemberships = newMemberships.filter((membership) => ALL_ENGLISH_SPEAKING_COUNTRIES.includes(membership.country));
      } else if (selectedLocation.value === 'US only') {
        newMemberships = newMemberships.filter(
          (membership) => membership.country === 'United States',
        );
      } else if (selectedLocation.value === 'Canada only') {
        newMemberships = newMemberships.filter((membership) => membership.country === 'Canada');
      } else if (selectedLocation.value === 'US and Canada') {
        newMemberships = newMemberships.filter(
          (membership) => membership.country === 'Canada' || membership.country === 'United States',
        );
      } else if (selectedLocation.value === 'Canada only, excluding Quebec') {
        newMemberships = newMemberships.filter(
          (membership) => membership.country === 'Canada' && membership.state !== 'Quebec',
        );
      } else if (selectedLocation.value === 'International') {
        newMemberships = newMemberships.filter(
          (membership) => !ALL_ENGLISH_SPEAKING_COUNTRIES.includes(membership.country),
        );
      }
      newMemberships = newMemberships.filter((membership) => membership.country);
    }
    return newMemberships;
  };

  const handleFilterChanges = () => {
    // Get the New FilteredData Data Set
    const newFilterData = {
      pendingApplications: filterMemberships(allData.pendingApplications),
      pendingInvitations: filterMemberships(allData.pendingInvitations),
      activeMemberships: filterMemberships(allData.activeMemberships),
      inactiveMemberships: filterMemberships(allData.inactiveMemberships),
    };
    // Sort Data if sortColumn set
    if (sortColumn && sortColumn.direction) {
      newFilterData.pendingApplications = sortMemberships(newFilterData.pendingApplications, sortColumn.column, sortColumn.direction);
      newFilterData.pendingInvitations = sortMemberships(newFilterData.pendingInvitations, sortColumn.column, sortColumn.direction);
      newFilterData.activeMemberships = sortMemberships(newFilterData.activeMemberships, sortColumn.column, sortColumn.direction);
      newFilterData.inactiveMemberships = sortMemberships(newFilterData.inactiveMemberships, sortColumn.column, sortColumn.direction);
    }
    setFilteredData(newFilterData);

    // Update Tab Values
    setTabMemberCount(createTabValues(newFilterData));

    // Update Table
    setCurrentPage(1);
    if (Array.isArray(newFilterData[statusType as keyof AllMemberTypesType])) {
      tableDataChangeHandler(newFilterData[statusType as keyof AllMemberTypesType].slice(0, Number(perPage.value)));
      setTotalPages(Math.ceil((newFilterData[statusType as keyof AllMemberTypesType].length) / Number(perPage.value)));
    } else {
      tableDataChangeHandler([]);
      setTotalPages(0);
    }
  };

  // Load Data & Dropdowns on page load
  useEffect(() => {
    getMembershipsHandler();
    getSelectionFields();
  }, []);

  // Update filteredData when filter changes
  useEffect(() => {
    if (!membershipsLoading && membershipsLoaded) {
      handleFilterChanges();
    }
  }, [selectedLocation, selectedPublisherGroup, toCalendarDate, fromCalendarDate, debouncedSearch]);

  // update the Table when changing Tabs
  useEffect(() => {
    updateDisableButtons(false);
    setHeaderCheck(false);
    if (!membershipsLoading && membershipsLoaded) {
      let newData: PublisherMember[] = [];
      if (Array.isArray(allData[statusType as keyof AllMemberTypesType])) {
        newData = filteredData[statusType as keyof AllMemberTypesType];
      }
      setCurrentPage(1);
      tableDataChangeHandler(newData.slice(0, Number(perPage.value)));
      setTotalPages(Math.ceil(newData.length / Number(perPage.value)));
    }
  }, [statusType]);

  // For Setting up inital state after Membership Data loaded
  useEffect(() => {
    if (!membershipsLoading && membershipsLoaded) {
      setFilteredData(allData);
      setTabMemberCount(createTabValues(allData));
      if (Array.isArray(allData[statusType as keyof AllMemberTypesType])) {
        tableDataChangeHandler(allData[statusType as keyof AllMemberTypesType].slice(0, Number(perPage.value)));
        setTotalPages(Math.ceil(allData[statusType as keyof AllMemberTypesType].length / Number(perPage.value)));
      }
      setCurrentPage(1);
    }
  }, [allData]);

  return {
    // Page State
    hookIsLoading: isLoading || groupsLoading || membershipsLoading,
    hookMerchantsLoading: membershipsLoading,
    hookErrorMessage: errorMessage,

    // Filters
    hookSearch: search,
    hookSetSearch: setSearchHandler,

    hookPublisherSelectOptions: publisherSelectOptions,
    hookSelectedPublisherGroup: selectedPublisherGroup,
    hookSetSelectedPublisherGroup: setSelectedPublisherGroupHandler,

    hookSelectedLocation: selectedLocation,
    hookSetSelectedLocation: setSelectedLocationHandler,

    hookPerPage: perPage,
    hookSetPerPageHandler: setPerPageHandler,

    hookClearFilter: clearFilterHandler,

    // Calendars
    hookOpenFromCalendar: openFromCalendar,
    hookSetInputFromCalendar: setInputFromCalendarHandler,
    hookFromCalendarDate: fromCalendarDate,
    hookInputFromCalendarDate: inputFromCalendarDate,
    hookSetFromCalendarDate: setFromCalendarDateHandler,

    hookOpenToCalendar: openToCalendar,
    hookSetInputToCalendar: setInputToCalendarHandler,
    hookToCalendarDate: toCalendarDate,
    hookInputToCalendarDate: inputToCalendarDate,
    hookSetToCalendarDate: setToCalendarDateHandler,

    // Tabs
    hookTabCount: tabMemberCount,
    hookSetStatusType: setStatusTypeHandler,
    hookStatusType: statusType,

    // Checkbox State + Handlers
    hookSelectedPublishers: selectedPublishers,
    hookSetSelectedPublishers: setSelectedPublishersHandler,
    hookSetSelectedPublishersAll: setSelectedPublishersAllHandler,
    hookHeaderCheck: headerCheck,

    // Table State
    hookMemberList: tableData,
    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookSortColumn: sortColumn,

    // Table Handlers
    hookNavigationHandler: navigationHandler,
    hookSetCurrentChange: setCurrentPageHandler,
    hookHandleSort: handleSort,

    // Footer + Modals
    hookDeclineButtonDisable: declineButtonDisable,
    hookApproveButtonDisable: approveButtonDisable,
    hookDownloadCSV: downloadCSVHandler,

    hookApproveOpenModal: approveOpenModal,
    hookSetApproveOpenModal: setApproveOpenModalHandler,
    hookSubmitHandler: submitHandler,
    hookModalType: modalType,
    hookUpdateMembershipLoading: updateMembershipsLoading,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
