import { faCircleExclamation, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export const BUTTON_LABEL = {
  SAVE: 'Save',
  CANCEL: 'Cancel',
  UPDATE_CATEGORY: 'Update Category',
  TIER: 'Add Tier',
  REMOVE: 'X',
};

export const INFO_BOX = {
  NEW_STATS: {
    TITLE: 'New Stats will be applied on the next period',
    DESCRIPTION: "The new stats will be applied to the next month payment calculation. The new stats won't reflect on the Account Balance until the next period starts.",
    ICON: faCircleExclamation,
  },
  CATEGORY_ERROR: {
    TITLE: '',
    DESCRIPTION: 'Please check new active categories, they must include all existing active categories',
    ICON: faTriangleExclamation,
  },
};

export const SECTION_TITLES = {
  PRODUCT_CATEGORY: 'Set Active Product Category',
  SYSTEM_RATE: 'System Usage Rate',
  MINIMUM_FEE: 'Minimum Usage Fee',
  MANAGEMENT_FEE: 'Management Fee',
  BALANCE_LEVELS: 'Balance Levels',
};

export const INFO_LABEL = {
  SETTING: 'Current Setting',
  NEW_SETTING: 'New Setting',
  RATE: 'Current Rate',
  NEW_RATE: 'New Rate',
  FEE: 'Current Fee',
  NEW_FEE: 'New Fee',
  NEW_FEE_DOLLAR: 'New Fee $',
  NEW_FEES: 'New Fees',
  BALANCE: 'Current Minimum Balance',
  NEW_BALANCE: 'New Minimum Balance',
  NEW_BALANCE_DOLLAR: 'New Minimum Balance $',
};

export const MODAL_TITLE = 'Active Product Category';

export const DISCLAIMER = 'Starting as of the 1st of next month';

export const INPUT_LABEL: {[key: string]: string} = {
  COMMISSION: 'level ### - total commission generated ($)',
  ADMIN_RATE: 'level ### - admin rate (%)',
  AFTER_RATE: 'after level ### - admin rate (%)',
  NEW_ADMIN_RATE: 'NEW ADMIN RATE %',
  MINUSAGEFEE: 'min usage fee',
  BANKING: 'banking',
  CREDITCARDS: 'credit cards',
  INVESTMENTS: 'investments',
  MORTGAGE: 'mortgage',
  BUSINESSES: 'businesses',
  CREDITREPORTING: 'credit Reporting',
  CRYPTO: 'Cryptocurrency',
  INSURANCE: 'insurance',
  LOANS: 'loans',
  REALESTATE: 'real estate',
  TAXSERVICES: 'tax Services',
  PLANNING: 'planning',
  MINIMUMBALANCE: 'minimum escrow required',
};

export const EMPTY_STATE = {
  CATEGORY: 'No category',
};

export const TIRED_RATE_OPTIONS = [
  { value: 'flat', label: 'Use flat rate' },
  { value: 'tier', label: 'Use tiered rate' },
];

export const TIRED_RATES = {
  FLAT: 'flat',
  TIER: 'tier',
};

export const PLACEHOLDER = {
  COMMISSION: 'Set a commission amount',
  ADMIN_RATE: 'Set a commission rate',
};

export const RATE_LEVEL_KEY: {[key: string]: string} = {
  COMMISSION: 'newAdminTierCommission',
  ADMIN_TIER: 'newAdminTierRate',
  AFTER_RATE: 'newAdminAfterTierRate',
  FLAT_RATE: 'adminRate',
};

export const TOOLTIP_MESSAGES = {
  COMMISSION: 'Set the maximum monetary amount for the range in this tier.',
};

export const RATE_EMPTY_VALUE = {
  id: 0,
  newAdminAfterTierRate: '',
  newAdminTierCommission: '',
  newAdminTierRate: '',
};

export const MINIMUM_USAGE_FEE_DEFAULT = { minUsageFee: '$0.00' };
export const MINIMUM_BALANCE_DEFAULT = { minimumBalance: '$0.00' };

export const PRODUCT_CATEGORY_OPTIONS = [
  { value: 'BANKING', label: 'Banking' },
  { value: 'BUSINESSES', label: 'Businesses Products' },
  { value: 'CREDITCARDS', label: 'Credit Cards' },
  { value: 'CREDITREPORTING', label: 'Credit Reporting' },
  { value: 'CRYPTO', label: 'Cryptocurrency' },
  { value: 'INSURANCE', label: 'Insurance' },
  { value: 'INVESTMENTS', label: 'Investments' },
  { value: 'LOANS', label: 'Loans' },
  { value: 'MORTGAGE', label: 'Mortgage' },
  { value: 'REALESTATE', label: 'Real Estate' },
  { value: 'TAXSERVICES', label: 'Tax Services' },
  { value: 'PLANNING', label: 'Wills & Estate Planning' },
];

export const PRODUCT_CATEGORY_ENUM: {[key: string]: string} = {
  BANKING: 'banking',
  CREDITCARDS: 'creditCards',
  INVESTMENTS: 'investments',
  MORTGAGE: 'mortgage',
  BUSINESSES: 'businesses',
  CREDITREPORTING: 'creditReporting',
  CRYPTOCURRENCY: 'crypto',
  INSURANCE: 'insurance',
  LOANS: 'loans',
  REALESTATE: 'realEstate',
  TAXSERVICES: 'taxServices',
  PLANNING: 'planning',
};
