import { gql } from '@apollo/client';

export const DEPOSIT_FEE = gql`
  mutation createDeposit($input: DepositInput) {
    createDeposit(input: $input) {
      deposit {
        id
      }
    }
  }
`;
