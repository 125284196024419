import React from 'react';
import { imageList } from '../../utils';
import * as Styled from './styles';

type ReportPlaceholderProps = {
  text: string;
  subtext?: string;
  display: 'wait' | 'not-found' | undefined;
};

export const ReportPlaceholder = ({ subtext, text, display }: ReportPlaceholderProps) => (
  <Styled.WrapperStyled display={display}>
    <Styled.TextStyled>{text}</Styled.TextStyled>

    {subtext && <Styled.SubtextStyled>{subtext}</Styled.SubtextStyled>}

    <figure>
      {display === 'wait' && (
      <img
        src={imageList.placeholder.src}
        alt={imageList.placeholder.alt}
      />
      )}
      {display === 'not-found' && (
      <img
        src={imageList.noResults.src}
        alt={imageList.noResults.alt}
      />
      )}
    </figure>
  </Styled.WrapperStyled>
);
