import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useUserInfo } from '../../../../hooks';
import { Button } from '../../../Button';
import { TOOLTIP_MESSAGES } from '../../utils';
import { AD_TYPES, USER_TYPES_ID } from '../../../../utils';
import * as Styled from './styles';

type GetCodeTabProps = {
  hook: any;
  onClose: () => void;
};

const GetCodeTab = ({ hook, onClose }: GetCodeTabProps) => {
  useEffect(() => {
    if (!hook.hookSelectedPublisher) hook.hookGetPublishers();
  }, [hook.hookAd.id]);

  const userHook = useUserInfo();

  return (
    <Styled.ContentStyled>
      <Styled.Input4Column
        label="Keyword"
        type="text"
        placeholder="Enter a keyword"
        onChange={hook.hookSetAcid}
        value={hook.hookAcid}
      />
      {(userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER) && (
      <Styled.Select2Column
        label="Publisher"
        options={hook.hookPublisherList}
        selected={hook.hookSelectedPublisher}
        onChange={hook.hookSetSelectedPublisher}
        isDisabled={hook.hookPublisherLoading}
        isLoading={hook.hookPublisherLoading}
        error={hook.hookError}
        required
      />
      )}
      <Styled.Select2Column
        label="Tracking Profile"
        options={hook.hookTrackingProfilesList}
        onChange={hook.hookSetSelectedTrackingProfile}
        isDisabled={(userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER) || userHook.hookWhoAmI.userTypeId === USER_TYPES_ID.ADMIN ? hook.hookTrackingProfileDisabled : false}
        isLoading={hook.hookTrackingLoading}
        selected={hook.hookSelectedTrackingProfile}
        error={hook.hookError}
        width={userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER ? '100%' : '208%'}
        required
      />
      {hook.hookGenerated && (
      <Styled.CodeWrapper>
        <Styled.GeneratedCodeInner>
          <Styled.InputTextArea2Columns
            label="Tracking Code"
            name="trackingCode"
            value={hook.hookCode.trackingCode}
            readonly
            disabled
            rows={5}
            tooltip={TOOLTIP_MESSAGES.TRACKING_CODE}
          />
          <Styled.CopyButtonStyled
            onClick={hook.hookCopyTrackingCodeToClipboard}
            disabled={hook.hookAdTrackerLoading}
            theme="secondary"
          >
            <FontAwesomeIcon
              icon={faCopy}
              style={{ marginRight: 5 }}
            />
          </Styled.CopyButtonStyled>
        </Styled.GeneratedCodeInner>
        <Styled.GeneratedCodeInner>
          <Styled.CodeInput
            label="Tracking Url"
            name="trackingUrl"
            theme="getCode"
            value={hook.hookCode.trackingUrl}
            readonly
            disabled
            tooltip={TOOLTIP_MESSAGES.TRACKING_URL}
          />
          <Styled.CopyButtonStyled
            onClick={hook.hookCopyTrackingUrlToClipboard}
            disabled={hook.hookAdTrackerLoading}
            theme="secondary"
          >
            <FontAwesomeIcon
              icon={faCopy}
              style={{ marginRight: 5 }}
            />
          </Styled.CopyButtonStyled>
        </Styled.GeneratedCodeInner>
        {hook.hookAd.adType === AD_TYPES.LINK && (
        <Styled.GeneratedCodeInner>
          <Styled.CodeInput
            label="Impression Url"
            name="impressionUrl"
            theme="getCode"
            value={hook.hookCode.impressionUrl}
            readonly
            disabled
            tooltip={TOOLTIP_MESSAGES.IMPRESSION_URL}
          />

          <Styled.CopyButtonStyled
            onClick={hook.hookCopyImpressionUrlToClipboard}
            disabled={hook.hookAdTrackerLoading}
            theme="secondary"
          >
            <FontAwesomeIcon
              icon={faCopy}
              style={{ marginRight: 5 }}
            />
          </Styled.CopyButtonStyled>
        </Styled.GeneratedCodeInner>
        )}
      </Styled.CodeWrapper>
      )}

      <Styled.FooterAreaStyled>
        <Button
          onClick={onClose}
          theme="secondary"
        >
          Cancel
        </Button>
        <Styled.CopyButtonStyled
          onClick={() => hook.hookGenerateCode(hook.hookAd)}
          disabled={hook.hookAdTrackerLoading}
          loading={hook.hookAdTrackerLoading}
        >
          Generate Code
        </Styled.CopyButtonStyled>
      </Styled.FooterAreaStyled>
    </Styled.ContentStyled>
  );
};

export default GetCodeTab;
