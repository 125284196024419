import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../../../../styles/theme';
import { Button } from '../../../../../components';

type StatusCardProps = {
  readonly color?: string;
}

export const CardWrapperStyled = styled.div<StatusCardProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 4px solid ${({ color }) => color};
  border-radius: 3px;
  background: ${colors.color4};
`;

export const ContentStyled = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  border-top: 1px solid ${colors.color20};
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.color16};
  ${fonts.gotham.H14M}
`;

export const BodyStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.color1};
  ${fonts.gotham.H32M}
  margin-top: 2rem;
`;

export const IconWrapperStyled = styled.div<StatusCardProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${({ color }) => {
    switch (color) {
      case colors.color14:
        return 'rgba(225, 93, 104, 0.05)';
      case colors.color2:
        return 'rgba(247, 141, 45, 0.05)';
      case colors.color21:
        return 'rgba(75, 199, 192, 0.05)';
      default:
        return 'none';
    }
  }};
`;

export const IconStyled = styled(FontAwesomeIcon)<StatusCardProps>`
  width: 15px;
  color: ${({ color }) => color};
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: ${colors.color2};
  }
`;
