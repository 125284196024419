import React from 'react';
import { Modal, PageTitle } from '../../../../../../components';
import * as Styled from './styles';

type BulkUploadConfirmModalProps = {
  isOpen: boolean,
  onClose: () => void,
  earliestDate: string,
  latestDate: string,
  grossRevenue: string,
  netRevenue: string,
  transactionCount: number,
  next: () => void,
  loading: boolean,
};

export const BulkUploadConfirmModal = ({
  isOpen, onClose, earliestDate, latestDate, grossRevenue, netRevenue, transactionCount, next, loading,
}: BulkUploadConfirmModalProps) => (
  <Modal isOpen={isOpen}>
    <Styled.WrapperStyled>
      <PageTitle>Add Transactions</PageTitle>
      <Styled.SubtitleStyled>Are you sure to proceed?</Styled.SubtitleStyled>

      <Styled.TextAreaStyled>
        <Styled.TextStyled>
          <Styled.BoldStyled>Transaction Date:</Styled.BoldStyled>
          {' '}
          {transactionCount > 1 && `from ${earliestDate} to ${latestDate}`}
          {transactionCount < 2 && earliestDate}
        </Styled.TextStyled>
        <Styled.TextStyled>
          <Styled.BoldStyled>Number of Transactions:</Styled.BoldStyled>
          {' '}
          {transactionCount}
        </Styled.TextStyled>
        <Styled.TextStyled>
          <Styled.BoldStyled>Gross Sales:</Styled.BoldStyled>
          {' '}
          {grossRevenue}
        </Styled.TextStyled>
        <Styled.TextStyled>
          <Styled.BoldStyled>Net Sales:</Styled.BoldStyled>
          {' '}
          {netRevenue}
        </Styled.TextStyled>
      </Styled.TextAreaStyled>

      <Styled.ButtonAreaStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={onClose}
          width="150px"
        >
          Cancel
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          onClick={next}
          width="150px"
          loading={loading}
        >
          Add Transaction
        </Styled.ButtonStyled>
      </Styled.ButtonAreaStyled>
    </Styled.WrapperStyled>
  </Modal>
);
