import styled from 'styled-components';
import {
  InputText, InputTextarea, Select, Checkbox,
} from '../../../../../../components';
import { toRem } from '../../../../../../utils';
import { colors, fonts } from '../../../../../../styles/theme';

export const WrapperStyled = styled.div`
  margin: 2rem 0 0.5rem;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2%;
  grid-row-gap: 2rem;
  align-items: flex-start;
  margin-top: 2rem;
`;

export const InputOneColumnStyled = styled(InputText)`
  grid-column: span 1;
`;

export const InputTwoColumnStyled = styled(InputText)`
  grid-column: span 2;
`;

export const SelectStyled = styled(Select)`
  grid-column: span 1;
`;

export const SelectTwoStyled = styled(Select)`
  grid-column: span 2;
`;

export const DescriptionStyled = styled(InputTextarea)`
  grid-column: span 2;
`;

export const DropZoneStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-row: span 3;
`;

export const SectionStyled = styled.div`
  margin-bottom: 3rem;
`;

export const SectionTitleStyled = styled.h2`
  ${fonts.gotham.H22}
  color: ${colors.color1};
  margin: ${toRem(65)} 0 ${toRem(40)};
`;

export const AgeRangeSection = styled.div`
  grid-column: span 2;
  grid-row: span 2;
  align-self: flex-start;
`;

export const CheckBoxesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-width: ${toRem(500)};
  row-gap: ${toRem(12)};
  
`;

export const AgeRangeTitle = styled.p`
  ${fonts.gotham.H12M}
  color: ${colors.color13};
  padding-bottom: ${toRem(16)};
`;

export const AgeRangeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2%;
  grid-row-gap: 1rem;
  align-items: center;
  margin-top: 1rem;
`;

export const CheckboxStyled = styled(Checkbox)`
  span {
    ${fonts.lato.H16};
  }
`;

export const FooterStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${toRem(24)};

  button {
    margin-left: 1rem;
  }
`;
