import { faArrowDown, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  ErrorBanner,
  ReportPlaceholder, Table,
} from '../../../../../components';
import {
  PAYMENT_CURRENCY,
  PAYMENT_METHOD,
  PAYMENT_STATUS, RECORDS_PER_PAGE_OPTIONS,
} from '../../../../../utils';
import * as Styled from './styles';
import { columns } from './contracts';
import { AddNoteModal } from './components/AddNoteModal';
import { PENDING_PAYMENTS } from '../../enums';
import { PaypalModal } from './components/PaypalModal';
import { SucessModal } from './components/SuccessModal';
import type { usePendingPayments } from '../../hooks/usePendingPayments';

type PendingPaymentsProps = {
  hook: ReturnType<typeof usePendingPayments>
}

const PendingPayments = ({ hook }:PendingPaymentsProps) => {
  const { hookIsReadOnlyList } = hook;
  const [_, isPaymentsPayReadOnly, isPaymentsApproveReadOnly] = hookIsReadOnlyList;

  return (
    <div>
      <ErrorBanner
        message={hook.hookErrorMessage}
        isOpen={!!hook.hookErrorMessage && hook.hookErrorMessage !== ''}
      />
      <Styled.FiltersStyled>
        <Styled.FilterSelectStyled
          label={PENDING_PAYMENTS.PUBLISHER_LABEL}
          name={PENDING_PAYMENTS.EMPTY_LABEL}
          onChange={hook.hookSetSelectedPublisher}
          options={hook.hookPublisherOptions}
          selected={hook.hookSelectedPublisher}
          isLoading={hook.hookPublishersLoading}
        />
        <Styled.FilterSelectStyled
          label={PENDING_PAYMENTS.PAYMENT_METHOD_LABEL}
          name={PENDING_PAYMENTS.EMPTY_LABEL}
          onChange={hook.hookSetSelectedPayment}
          options={PAYMENT_METHOD}
          selected={hook.hookSelectedPayment}
        />
        <Styled.FilterPeriodWrapperStyled>
          <Styled.FilterPeriodLabelStyled>{PENDING_PAYMENTS.PAYMENT_PERIOD}</Styled.FilterPeriodLabelStyled>
          <Styled.FilterPeriodStyled>{`${hook.hookPreviousMonth} ${hook.hookPreviousYear}`}</Styled.FilterPeriodStyled>
        </Styled.FilterPeriodWrapperStyled>
        <Styled.FilterSelectStyled
          label={PENDING_PAYMENTS.PAYMENT_STATUS_LABEL}
          name={PENDING_PAYMENTS.EMPTY_LABEL}
          selected={hook.hookPaymentStatus}
          onChange={hook.hookHandleSetPaymentStatus}
          options={PAYMENT_STATUS}
        />
        <Styled.FilterSelectStyled
          label={PENDING_PAYMENTS.PAYMENT_CURRENCY}
          name={PENDING_PAYMENTS.EMPTY_LABEL}
          onChange={hook.hookSetSelectedCurrency}
          options={PAYMENT_CURRENCY}
          selected={hook.hookSelectedCurrency}
        />
        <Styled.FilterInputTextStyled
          faIcon={faSearch}
          name={PENDING_PAYMENTS.EMPTY_LABEL}
          type="text"
          placeholder={PENDING_PAYMENTS.PAYMENT_ID_PLACEHOLDER}
          value={hook.hookPaymentId}
          onChange={hook.hookHandlePaymentId}
        />
      </Styled.FiltersStyled>

      <Styled.FilterButtonWrapperStyled>
        <Styled.FilterClearButtonStyled
          onClick={hook.hookHandleClearForm}
          theme="text-only"
        >
          {PENDING_PAYMENTS.CLEAR}
        </Styled.FilterClearButtonStyled>
        <Styled.FilterActionButtonsStyled>
          <Styled.FilterButtonStyled
            onClick={hook.hookHandleGenerateCsv}
            theme={PENDING_PAYMENTS.FILTER_THEME}
            loading={hook.hookCsvLoading}
          >
            <FontAwesomeIcon icon={faArrowDown} />
            {PENDING_PAYMENTS.DOWNLOAD}
          </Styled.FilterButtonStyled>
          <Styled.FilterButtonStyled
            onClick={hook.hookHandleGenerate}
            loading={hook.hookPaymentLoading}
          >
            <FontAwesomeIcon icon={faPlus} />
            {PENDING_PAYMENTS.GENERATE}
          </Styled.FilterButtonStyled>
        </Styled.FilterActionButtonsStyled>
      </Styled.FilterButtonWrapperStyled>

      <AddNoteModal
        isOpen={hook.hookIsModalOpen}
        onClose={hook.hookHandleCloseModal}
        onConfirm={hook.hookHandleSaveModal}
        referenceText={hook.hookModalRefNumber}
        referenceChange={hook.hookHandleRefNumber}
        noteText={hook.hookNoteModal}
        noteChange={hook.hookHandleNoteModal}
        isLoading={hook.handleSaveModalLoading}
        isReadOnly={isPaymentsPayReadOnly && isPaymentsApproveReadOnly}
        isPaymentPayReadOnly={isPaymentsPayReadOnly}
      />
      <PaypalModal
        onClose={hook.hookClosePayModal}
        onConfirm={hook.hookHandlePayModal}
        amountCad={hook.hookAmounts.CAD}
        amountUsd={hook.hookAmounts.USD}
        isOpen={hook.hookPayOpen}
        isLoading={hook.hookPayLoading}
      />
      <SucessModal
        isOpen={hook.hookSuccessOpen}
        onClose={hook.hookCloseSucess}
        amountCad={hook.hookAmounts.CAD}
        amountUsd={hook.hookAmounts.USD}
      />
      {(hook.hookPaymentLoading || hook.hookPaymentData.length !== 0)
        && (
        <Styled.StyledNotifyer isShow={hook.hookHeaderCheckBox}>
          <div>
            All of
            &nbsp;
            <b>{`${hook.hookCount} payments`}</b>
            &nbsp;
            have been selected
          </div>
        </Styled.StyledNotifyer>
        )}
      <Styled.Report show>
        {(hook.hookPaymentLoading || hook.hookPaymentData.length !== 0)
        && (
        <Table
          isLoading={hook.hookPaymentLoading}
          handleCheckBox={hook.hookHandleCheckBox}
          handleHeaderCheckBox={hook.hookHandleHeaderCheck}
          headerCheckBox={hook.hookHeaderCheckBox}
          columns={columns}
          data={hook.hookPaymentData}
          setModalInfo={hook.hookHandleOpenModal}
          setContext={hook.hookHandleArrow}
          onSort={hook.hookOnSort}
          sortColumn={hook.hookSortColumn}
          footerData={hook.hookFooterData}
        />
        )}
        {(hook.hookPaymentLoading || hook.hookPaymentData.length !== 0)
        && (
        <Styled.StyledPaginate
          currentPage={hook.hookPage}
          total={hook.hookTotalPages}
          onPageChange={hook.hookChangePage}
        />
        )}
        <Styled.ReportFooter>
          {PENDING_PAYMENTS.RECORD}
          <Styled.ReportFooterSelect
            selected={hook.hookRecordsSelected}
            onChange={hook.hookHandleSetRecords}
            options={RECORDS_PER_PAGE_OPTIONS}
          />
          <Styled.FilterButtonStyled
            disabled={hook.hookCannotApprove || isPaymentsApproveReadOnly}
            theme={PENDING_PAYMENTS.FILTER_THEME}
            loading={hook.hookApproveLoading}
            onClick={hook.hookHandleApproveButton}
          >
            {PENDING_PAYMENTS.APPROVE}
          </Styled.FilterButtonStyled>
          <Styled.FilterButtonStyled
            onClick={hook.hookOpenPayModal}
            disabled={hook.hookCannotPay || isPaymentsPayReadOnly}
          >
            {PENDING_PAYMENTS.PAY}
          </Styled.FilterButtonStyled>
        </Styled.ReportFooter>
      </Styled.Report>
      {hook.hookPaymentData.length === 0 && !hook.hookPaymentLoading
      && (
      <ReportPlaceholder
        text={hook.hookGenerateReportClicked ? PENDING_PAYMENTS.NOT_FOUND : PENDING_PAYMENTS.CLICK}
        display="wait"
      />
      )}

    </div>
  );
};

export default PendingPayments;
