import { useContext } from 'react';
import { publisherSignUpContext } from '../Context';

export const useSignUp = () => {
  const { state, dispatcherHandler } = useContext(publisherSignUpContext);

  return {
    contextState: state,
    contextDispatcher: dispatcherHandler,
  };
};
