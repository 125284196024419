import { faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../../../../../styles/theme';
import { AD_TYPES, imageList } from '../../../../../../../utils';

const ImgWrapperStyled = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const CellStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  overflow: hidden;

  a {
    color: ${colors.color1};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${colors.color2};
    }
  }

  p {
    ${fonts.lato.H16R}
    color: ${colors.color1};
    margin-bottom: 0.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    ${fonts.lato.H14}
    color: ${colors.color13};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    color: ${colors.color2};
  }
`;

const formattedCode = () => (
  <CellStyled>
    <FontAwesomeIcon icon={faCode} />
  </CellStyled>
);

const formattedProduct = (value: any, row: any) => (
  <CellStyled>
    <p>{value}</p>
    <span>{row?.productCategory}</span>
  </CellStyled>
);

const formattedId = (value: any, row: any) => (
  <CellStyled>
    <p>{value}</p>
    <span>{row?.campaignName}</span>
  </CellStyled>
);

const formattedType = (value: any, row: any) => (
  <CellStyled>
    <p>{value}</p>
    <span>
      {row?.width && row?.height && `${row?.width}w x ${row?.height}h`}
      {!row?.width && !row?.height && 'N/A'}
    </span>
  </CellStyled>
);

const formattedDateCell = (value: any) => (
  <CellStyled>
    {new Date(value).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}
  </CellStyled>
);

const formattedImage = (value: string, adType: string) => (
  <CellStyled>
    <ImgWrapperStyled>
      <img
        src={adType === AD_TYPES.BANNER && value !== undefined ? value : imageList.noPic.src}
        alt="cover"
      />
    </ImgWrapperStyled>
  </CellStyled>
);

export const columns: TableColumn[] = [
  {
    text: 'Thumbnail',
    dataField: 'adContent',
    width: '8%',
    formatter: (value, { adType }) => formattedImage(value, adType),
  },
  {
    text: 'Type/Size',
    dataField: 'adType',
    width: '10%',
    formatter: formattedType,
  },
  {
    text: 'ad id',
    dataField: 'id',
    width: '10%',
    formatter: formattedId,
  },
  {
    text: 'Product/Category',
    dataField: 'productName',
    width: '50%',
    formatter: formattedProduct,
  },
  {
    text: 'Date',
    dataField: 'createdAt',
    width: '17%',
    formatter: formattedDateCell,
  },
  {
    text: 'Code',
    dataField: '_',
    width: '5%',
    position: 'center',
    formatter: formattedCode,
  },
];
