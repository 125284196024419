import React, { useState, useEffect } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';
import { PageTitle, Modal } from '../../../../components';
import * as Styled from './styles';

const ChangeProductModal = ({
  hook,
}: {
  hook: any
}) => (
  <Modal isOpen={hook.isChangeProductModalOpen}>
    <Styled.WrapperStyled>
      <Styled.TopStyled>
        <PageTitle>
          Product Change Warning
        </PageTitle>

        <Styled.CloseButtonStyled
          theme="secondary"
          onClick={() => { hook.setIsChangeProductModalOpen(false); }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Styled.CloseButtonStyled>
      </Styled.TopStyled>

      <Styled.ContentStyled>
        Are you sure you want to change the product to
        <Styled.BoldText>
          {' '}
          { hook.holdProduct && (hook.holdProduct.label)}
          {' '}
        </Styled.BoldText>
        ? Changing the campaign product will update all the inheriting ads with the new product.
      </Styled.ContentStyled>
      <Styled.FooterStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => { hook.setIsChangeProductModalOpen(false); }}
        >
          Cancel
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          theme="primary"
          onClick={() => { hook.handleProductConfirmation(); }}
        >
          Continue
        </Styled.ButtonStyled>
      </Styled.FooterStyled>
    </Styled.WrapperStyled>
  </Modal>
);

export default ChangeProductModal;
