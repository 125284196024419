import { gql } from '@apollo/client';

export const CREATE_FEE = gql`
  mutation createFee($input: FeeInput) {
    createFee(input: $input) {
      fee {
        id
      }
    }
  }
`;
