export const PRODUCTS = {
  PRODUCT_ID: {
    LABEL: 'Product Id',
    NAME: 'productId',
  },
  PRODUCT_CATEGORY: {
    LABEL: 'Product Category',
    NAME: 'productCategory',
  },
  BUTTON_WIDTH: '160px',
  THEME: 'tertiary',
  DOWNLOAD: 'Download CSV',
};
