import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as Styled from './styles';

type SelectMerchantProps = {
  value: any;
  date: any;
  handleArrowClick: (value: Record<string, any>) => void
}

export const SelectProduct = ({ value, date, handleArrowClick }: SelectMerchantProps) => (
  <Styled.CellWrapperStyled>
    <Styled.DateStyled>
      {new Date(date).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}
    </Styled.DateStyled>
    <Styled.ButtonStyled
      onClick={() => handleArrowClick(value)}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </Styled.ButtonStyled>
  </Styled.CellWrapperStyled>
);
