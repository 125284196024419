import styled from 'styled-components';
import { InputTextarea, Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

export const RequiredFieldsError = styled.div`
  ${fonts.lato.H16}
  color: ${colors.color14};
  width: 100%;
  border: 2px solid ${colors.color14};
  padding: ${toRem(15)};
  margin-bottom: ${toRem(30)};
  icon: {
    color: ${colors.color14}
  }
`;

export const RequiredTextStyled = styled.span`
  ${fonts.lato.H16};
  color: ${colors.color14};
  padding-left: ${toRem(10)};
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const FieldsStyled = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2%;
  row-gap: 2rem;
  margin: 2rem 0;
`;

export const TextareaStyled = styled(InputTextarea)`
  grid-row: 2 / 4;
`;

export const DatesAreaStyled = styled.div`
  grid-row: 2 / 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 170px;
`;

export const SectionTitleStyled = styled.p`
  ${fonts.gotham.H12M}
  color: ${colors.color13};
`;

export const PublisherGroupStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2%;
  row-gap: 1rem;
  margin: 1.5rem 0;
  align-items: start;
  span {
    ${fonts.lato.H16}
  }
`;

export const AssociatedAdsWrapper = styled.div`
  margin-top: 5rem;
  margin-bottom: 1rem;
`;

export const AssociatedAdsTitleStyled = styled.p`
  ${fonts.gotham.H22}
  margin-bottom: 2rem;
`;

export const SortAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;

  p {
    margin-right: 1rem;
  }
`;

export const SelectStyled = styled(Select)`
  &:first-of-type {
    margin-right: 2rem;
  }
`;

export const AssociatedAdsStyled = styled.div`
  margin-bottom: 2rem;
`;
