import React from 'react';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faFileDownload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import * as Styled from './styles';
import { MERCHANT_PREFIX, imageList, path } from '../../../../../../utils';
import { Button, LoadingDots, TablePlaceholder } from '../../../../../../components';
import {
  BUTTONS, CHECK_BACK, GRAPHS, NO_CHECK_RULES,
} from './enums';

type PlaceholderProps = {
  show: boolean
  loading: boolean
  noCheckRules: boolean
}

export const Placeholder = ({ show, loading, noCheckRules }: PlaceholderProps) => {
  const navigate = useNavigate();
  return (
    <Styled.PlaceholdersStyled show={show}>
      {loading ? (
        <LoadingDots />
      ) : (
        <Styled.CheckBackSoonStyled>
          <h3>{noCheckRules ? NO_CHECK_RULES.TITLE : CHECK_BACK.TITLE}</h3>
          <p>{noCheckRules ? NO_CHECK_RULES.DESCRIPTION : CHECK_BACK.DESCRIPTION}</p>
          {noCheckRules && (
            <Button
              onClick={() => navigate(`${MERCHANT_PREFIX}${path.ruleManager.href}`)}
            >
              <Styled.ButtonContentStyled>
                <FontAwesomeIcon icon={faPlus} />
                {NO_CHECK_RULES.BUTTON}
              </Styled.ButtonContentStyled>
            </Button>
          )}
        </Styled.CheckBackSoonStyled>
      )}

      <Styled.BlockWrapperStyled>
        <Styled.GraphWrapperStyled>
          <Styled.Placeholder>
            <Styled.PlaceholderTitleWrapperStyled>
              <Styled.PlaceholderTitleStyled>{GRAPHS.PUBLISHER}</Styled.PlaceholderTitleStyled>
              <Styled.IconStyled icon={faCircleQuestion} />
            </Styled.PlaceholderTitleWrapperStyled>
            <img
              src={imageList.publisherCheckPlaceholder.src}
              alt={imageList.publisherCheckPlaceholder.alt}
            />
          </Styled.Placeholder>
          <Styled.DividerStyled />
          <Styled.Placeholder>
            <Styled.PlaceholderTitleWrapperStyled>
              <Styled.PlaceholderTitleStyled>{GRAPHS.RULE}</Styled.PlaceholderTitleStyled>
              <Styled.IconStyled icon={faCircleQuestion} />
            </Styled.PlaceholderTitleWrapperStyled>
            <img
              src={imageList.ruleCheckPlaceholder.src}
              alt={imageList.ruleCheckPlaceholder.alt}
            />
          </Styled.Placeholder>
        </Styled.GraphWrapperStyled>
      </Styled.BlockWrapperStyled>
      <Styled.BlockWrapperStyled>
        <Styled.PlaceholderDonwload>
          <Button
            theme="placeholder"
            onClick={() => undefined}
          >
            <Styled.IconPlaceholder icon={faFileDownload} />
            {BUTTONS.DOWNLOAD}
          </Button>
        </Styled.PlaceholderDonwload>
        <TablePlaceholder />
      </Styled.BlockWrapperStyled>
    </Styled.PlaceholdersStyled>
  );
};
