import { gql } from '@apollo/client';

export const SHOW_PUBLISHER = gql`
  query ShowPublisher($input: ShowPublisherInput) {
  showPublisher(input: $input) {
    publisherGroups {
      id
      name
    }
    memberships {
      program {
        id
      }
    }
  }
}
`;
