import { gql } from '@apollo/client';

export const PROGRAM_V2 = gql`
query ProgramV2($programV2Id: ID!) {
  programV2(id: $programV2Id) {
    transactionSettings {
      currency
    }
  }
}
`;
