export const sortDate = (list: any[], sortBy: string, direction = 'asc') => {
  const sortedList = [...list];

  if (direction === 'desc') {
    return sortedList.sort((a: any, b: any) => {
      if (Number.isNaN(new Date(a[sortBy]).getTime())) {
        return 1;
      }
      if (Number.isNaN(new Date(b[sortBy]).getTime())) {
        return -1;
      }
      if (new Date(a[sortBy]) > new Date(b[sortBy])) {
        return -1;
      }
      if (new Date(a[sortBy]) > new Date(b[sortBy])) {
        return 1;
      }
      return 0;
    });
  }
  return sortedList.sort((a: any, b: any) => {
    if (Number.isNaN(new Date(a[sortBy]).getTime())) {
      return 1;
    }
    if (Number.isNaN(new Date(b[sortBy]).getTime())) {
      return -1;
    }
    if (new Date(a[sortBy]) < new Date(b[sortBy])) {
      return -1;
    }
    if (new Date(a[sortBy]) < new Date(b[sortBy])) {
      return 1;
    }
    return 0;
  });
};

export const sortString = (list: any[], sortBy: string, direction = 'asc') => {
  const sortedList = [...list];
  if (direction === 'desc') {
    return sortedList.sort((a: any, b: any) => {
      if (!a[sortBy] || !b[sortBy]) return 0;
      if (a[sortBy] > b[sortBy]) {
        return -1;
      }
      if (a[sortBy] < b[sortBy]) {
        return 1;
      }
      return 0;
    });
  }
  return sortedList.sort((a: any, b: any) => {
    if (!a[sortBy] || !b[sortBy]) return 0;
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    return 0;
  });
};
