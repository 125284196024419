import { gql } from '@apollo/client';

export const GET_FINTEL_CHECK_SUMMARIES_CSV = gql`
  query fintelCheckSummaries($input: FintelCheckSummaryInput!) {
    fintelCheckSummaries(input: $input) {
      count
      fintelCheckSummary {
        id
        merchantId
        merchantName
        publisherId
        publisherName
        productCategory
        productId
        customizedProductId
        productName
        referralUrl
        ruleName
        ruleStatus
        screenshotName
        zipfileName
        ruleRequired
        resultFound
        trackingEventDate
      }
    }
  }
`;

export type GetFintelCheckSummariesCSVInput = {
  input: {
    merchantId: string,
    publisherId: string,
    publisherName?: string,
    checkDate: string,
    productCategory?: string,
    productId?: string,
    ruleStatus?: string,
  },
};

export type GFCSCSummaryType = {
  id: string
  merchantId: string
  merchantName: string
  publisherId: string
  publisherName: string
  productCategory: string | null
  productId: string | null
  customizedProductId: string | null
  productName: string | null
  referralUrl: string | null
  ruleName: string | null
  ruleStatus: string | null
  screenshotName: string | null
  zipfileName: string | null
  ruleRequired: boolean
  resultFound: string | null
  trackingEventDate: string | null
}

export type GetFintelCheckSummariesCSVOutput = {
  fintelCheckSummaries: {
    count: number
    fintelCheckSummary: GFCSCSummaryType[]
  }
}
