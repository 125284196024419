import React from 'react';
import * as Styled from './styled';

type CellProps = {
  val: string
  ellipsis?: boolean
}

export const CellWraper = ({ val, ellipsis = false }: CellProps) => (
  <Styled.CellWrapper ellipsis={ellipsis}>
    <p>{val}</p>
  </Styled.CellWrapper>
);
