import styled from 'styled-components';
import { colors, fonts } from '../../../../../../styles/theme';

export const DescriptionStyled = styled.p`
  margin: 2rem 0 0.5rem;
  ${fonts.gotham.H16M}
  color: ${colors.color1};
`;

export const InstructionStyled = styled.p`
  ${fonts.lato.H14}
  color: ${colors.color1};
  line-height: 1.5;
`;

export const SampleStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${fonts.lato.H14}
  color: ${colors.color2};
  line-height: 1.5;
  margin: 0.5rem 0 2rem;
`;
