import React from 'react';
import { faCircleXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Radio } from '../../../../../components';
import * as Styled from './styles';
import { REV_SHARE_COMMISSION_BASES } from '../enums';
import { CommissionStructureProps } from '../types';
import {
  BUTTON_TEXT, COMMISSION_DEFAULT_CUSTOM, COMMISSION_LABELS, COMMISSION_STRUCTURE, RADIO_OPTIONS,
} from '../utils';

export const RevShareCommissionStructure = ({ hook, isReadOnly }: CommissionStructureProps) => (
  <Styled.CommissionStructureWrapper isCustom={hook.hookCommissionSubType === COMMISSION_DEFAULT_CUSTOM.CUSTOM}>
    <Styled.TitleStyled>{COMMISSION_STRUCTURE}</Styled.TitleStyled>

    <Styled.FlexWrapper>
      <Styled.StaticFieldsStyled>
        <Styled.SelectThreeCStyled
          required
          label={COMMISSION_LABELS.COMMISSION_BASE}
          options={REV_SHARE_COMMISSION_BASES}
          selected={hook.hookCommissionBase}
          onChange={hook.hookSetCommissionBase}
          error={hook.hookCommissionStructureErrors.commissionBase}
          isDisabled={isReadOnly}
        />
        <Styled.InputTextTwoCStyled
          type="text"
          value={hook.hookFlatValue}
          label={COMMISSION_LABELS.FLAT_COMMISSION}
          error={hook.hookCommissionStructureErrors.flatCommission}
          onBlur={hook.hookValidateStaticFields}
          onChange={hook.hookSetFlatValue}
          required={hook.hookRadioSelected === 0}
          disabled={hook.hookRadioSelected !== 0 || isReadOnly}
        />
      </Styled.StaticFieldsStyled>
    </Styled.FlexWrapper>
  </Styled.CommissionStructureWrapper>
);
