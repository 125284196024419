import React from 'react';
import { BUTTON_LABEL, INPUT_LABEL, MODAL_TYPE } from '../../contracts';
import { Button, FixedFooter } from '../../../../../../components';
import * as Styled from '../../styles';

type SearchPublisherPageFooterProps = {
  groupOptions: SelectOption[]
  selectPublisherCount: number
  selectedPublisherGroup?: SelectOption
  setSelectedPublisherGroup: (selectedPublisherGroup: SelectOption) => void
  buttonHandler: (type: string) => void
  isReadOnly: boolean
}

export const SearchPublisherPageFooter = ({
  groupOptions,
  selectPublisherCount,
  selectedPublisherGroup,
  setSelectedPublisherGroup,
  buttonHandler,
  isReadOnly,
}: SearchPublisherPageFooterProps) => (
  <FixedFooter>
    <Styled.FooterWrapperStyled>
      <Styled.FooterSelectLabelStyled>{INPUT_LABEL.ADD_REMOVE_MESSAGE}</Styled.FooterSelectLabelStyled>
      <Styled.FooterSelectStyled
        name="group"
        onChange={setSelectedPublisherGroup}
        selected={selectedPublisherGroup}
        options={groupOptions}
        menuPlacement="top"
        isDisabled={isReadOnly}
      />

      <Styled.FooterButtonAreaStyled>
        <Button
          theme="secondary"
          onClick={() => buttonHandler(MODAL_TYPE.REMOVE)}
          disabled={selectPublisherCount < 1 || selectedPublisherGroup === undefined || isReadOnly}
        >
          {BUTTON_LABEL.REMOVE_GROUP}
        </Button>
        <Styled.FooterButtonWrapperStyled>
          <Button
            onClick={() => buttonHandler(MODAL_TYPE.ADD)}
            disabled={selectPublisherCount < 1 || selectedPublisherGroup === undefined || isReadOnly}
          >
            {BUTTON_LABEL.ADD_GROUP}
          </Button>
        </Styled.FooterButtonWrapperStyled>
      </Styled.FooterButtonAreaStyled>
    </Styled.FooterWrapperStyled>
  </FixedFooter>
);
