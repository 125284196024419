import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { Button, Tooltip } from '../../../../../../../../components';
import * as Styled from '../styles';
import { LEGAL_REFERENCE_ITEMS, PRODUCT_FEED, LEGAL_REFERENCE_TOOLTIP } from '../../../enums';

type LegalReferenceItemsProps = {
  legalReferenceItems: any
  setLegalReferenceItems: any
  addLegalReferenceItem: any
  handleLegalReferenceXButton: any
  disabled: boolean
};

export const LegalReferenceItems = ({
  legalReferenceItems, setLegalReferenceItems, addLegalReferenceItem, handleLegalReferenceXButton, disabled,
}: LegalReferenceItemsProps) => (
  <Styled.InnerWrapperStyled>
    <Styled.WrapperTitleStyled>
      {LEGAL_REFERENCE_ITEMS.TITLE}
    </Styled.WrapperTitleStyled>
    {legalReferenceItems && legalReferenceItems.length === 0 && (
      <Styled.LegalReferenceItemWrapper key={0}>
        <Styled.LegalReferenceNameWrapper>
          <Styled.NameLabel>
            {' '}
            {`LEGAL REFERENCE #${1} NAME`}
          </Styled.NameLabel>
          <Styled.LegalReferenceItemTextInput
            type="text"
            value=""
            onChange={(event) => setLegalReferenceItems(event.target.value, 0, 'name')}
          />
        </Styled.LegalReferenceNameWrapper>
        <Tooltip text={(
          <>
            {LEGAL_REFERENCE_TOOLTIP.msg}
            <br />
            {LEGAL_REFERENCE_TOOLTIP.characters}
          </>
)}
        />
        <Styled.LegalReferenceDescriptionWrapper>
          <Styled.LegalReferenceItemTextArea
            placeholder="Add description for this legal reference item"
            value=""
            onChange={(event) => setLegalReferenceItems(event.target.value, 0, 'description')}
          />
          <Tooltip text={(
            <>
              {LEGAL_REFERENCE_TOOLTIP.msg}
              <br />
              {LEGAL_REFERENCE_TOOLTIP.characters}
            </>
)}
          />
        </Styled.LegalReferenceDescriptionWrapper>
      </Styled.LegalReferenceItemWrapper>
    )}
    {legalReferenceItems && legalReferenceItems.map((item: { name: string, description: string }, index: number) => (
      <Styled.LegalReferenceItemWrapper key={index}>
        <Styled.LegalReferenceNameWrapper>
          <Styled.NameLabel>
            {' '}
            {`LEGAL REFERENCE #${index + 1} NAME`}
          </Styled.NameLabel>
          <Styled.LegalReferenceItemTextInput
            type="text"
            value={item.name}
            onChange={(event) => setLegalReferenceItems(event.target.value, index, 'name')}
          />
          <Tooltip text={(
            <>
              {LEGAL_REFERENCE_TOOLTIP.msg}
              <br />
              {LEGAL_REFERENCE_TOOLTIP.characters}
            </>
)}
          />
        </Styled.LegalReferenceNameWrapper>
        <Styled.LegalReferenceDescriptionWrapper>
          <Styled.LegalReferenceItemTextArea
            placeholder="Add description for this legal reference item"
            value={item.description || ''}
            onChange={(event) => setLegalReferenceItems(event.target.value, index, 'description')}
          />
          <Styled.LegalReferenceToolTipWrapper>
            <Tooltip text={(
              <>
                {LEGAL_REFERENCE_TOOLTIP.msg}
                <br />
                {LEGAL_REFERENCE_TOOLTIP.characters}
              </>
)}
            />
            <div>
              {index > 0 && (
              <Styled.XButton
                theme="secondary"
                onClick={() => handleLegalReferenceXButton(index)}
              >
                <FontAwesomeIcon icon={faX} />
              </Styled.XButton>
              ) }
            </div>
          </Styled.LegalReferenceToolTipWrapper>
        </Styled.LegalReferenceDescriptionWrapper>
      </Styled.LegalReferenceItemWrapper>
    ))}
    <Styled.LegalReferenceButtonWrapper>
      <Button
        onClick={addLegalReferenceItem}
        theme="tertiary"
        disabled={disabled || legalReferenceItems.length >= 10}
      >
        <FontAwesomeIcon icon={faPlus} />
        <Styled.ButtonTextWrapperStyled>
          {LEGAL_REFERENCE_ITEMS.ADD_LABEL}
        </Styled.ButtonTextWrapperStyled>
      </Button>
      <Styled.LegalReferenceErrorMessage> Maximum 10 </Styled.LegalReferenceErrorMessage>

    </Styled.LegalReferenceButtonWrapper>
  </Styled.InnerWrapperStyled>
);
