import React from 'react';
import {
  DefaultCell, LinkCell, PageUrlCell, ScreenShotCell, StatusCell,
} from './Components';
import { MERCHANT_PREFIX, path } from '../../../../../../utils';
import { DETAIL_COLUMNS } from './enums';

export const columns: TableColumn[] = [
  {
    dataField: 'ruleStatus',
    text: 'Status',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '180px',
    cellOverflow: true,
    formatter: (val, row, _, __, ___, setOpenModal, setModalInfo) => (
      <StatusCell
        val={val}
        row={row}
        openModal={setOpenModal}
        setModalInfo={setModalInfo}
      />
    ),
  },
  {
    dataField: 'referralUrl',
    text: 'Page URL',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '300px',
    formatter: (val) => <PageUrlCell val={val} />,
  },
  {
    dataField: 'productCategory',
    text: 'Category',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '180px',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'productName',
    text: 'Product',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '300px',
    formatter: (val, row) => <DefaultCell val={val ? `${row.customizedProductId} - ${val}` : ''} />,
  },
  {
    dataField: 'ruleName',
    text: 'Rule Name',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '250px',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'expectedMatch',
    text: 'Expected Match',
    width: '180px',
    formatter: (_, row, __, ___, ____, _____, ______, setContext) => (
      <LinkCell
        row={row}
        title={DETAIL_COLUMNS.VIEW_DETAIL}
        href={`${MERCHANT_PREFIX}${path.fintelCheckRuleDetails.href}`}
        contextDispatch={setContext}
      />
    ),
  },
  {
    dataField: 'trackingEventDate',
    text: 'Status Since',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '150px',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'screenshotName',
    text: 'Screenshot',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '150px',
    formatter: (val) => <ScreenShotCell val={val} />,
  },
  {
    dataField: 'history',
    text: 'History',
    width: '150px',
    formatter: (_, row, __, ___, ____, _____, ______, setContext) => (
      <LinkCell
        row={row}
        title={DETAIL_COLUMNS.VIEW_HISTORY}
        href={`${MERCHANT_PREFIX}${path.fintelCheckChangeHistory.href}`}
        contextDispatch={setContext}
      />
    ),
  },
];
