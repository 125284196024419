import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import {
  AppWrapper, Calendar, ErrorBanner, InputCalendar, PageTitle, Pagination, Table,
} from '../../../../components';
import {
  BUTTON_TEXT, COMMISSION_DEFAULT_CUSTOM, COMMISSION_LABELS, PAGE_DESCRIPTION, PAGE_TITLE, PLACEHOLDERS, PUBLISHER_GROUPS, REQUIRED_TEXT, REV_SHARE,
} from './utils';
import {
  BonusCommissionStructure, CPACommissionStructure, RevShareCommissionStructure,
} from './components';
import { COMMISSION_TYPES } from '../../../../utils';
import { useNewCommission } from './hooks';
import { columns } from './contracts';
import ConflictModal from './components/Modal';
import { DefaultPropsType } from '../../../../types';
import CreateCommissionModal from '../Modal/CreateCommissionModal';
import * as Styled from './styles';

type NewCommissionProps = DefaultPropsType;

const NewCommission = ({ permissionsCodeList = [] }: NewCommissionProps) => {
  const { hookIsReadOnlyList, ...hook } = useNewCommission(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  const navigate = useNavigate();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ConflictModal hook={hook} />
      <ErrorBanner
        isOpen={!hook.hookPublisherGroupsSelected && hook.hookCommissionSubType === COMMISSION_DEFAULT_CUSTOM.CUSTOM && hook.hookSecondRender}
        message={REQUIRED_TEXT}
      />
      <PageTitle>
        {PAGE_TITLE(hook.hookCommissionSubType, COMMISSION_TYPES[hook.hookCommissionType.toUpperCase()])}
      </PageTitle>
      <Styled.PageDescriptionStyled>
        {PAGE_DESCRIPTION[hook.hookCommissionType.toUpperCase()]}
        <br />
        {hook.hookCommissionType === COMMISSION_TYPES.CPA ? PAGE_DESCRIPTION.CPA_TWO : ''}
      </Styled.PageDescriptionStyled>

      <Styled.DateSectionStyled>
        { hook.hookCommissionSubType === 'Custom' && (
        <Styled.InputTextStyled
          required
          label={COMMISSION_LABELS.COMMISSION_NAME}
          name="commissionName"
          type="text"
          value={hook.commissionName}
          onChange={hook.setCommissionNameHandler}
          error={hook.hookNewCommissionErrors.commissionName}
        />
        )}

        { hook.hookCommissionSubType === 'Default' && (
        <Styled.InputTextStyled
          label={COMMISSION_LABELS.COMMISSION_NAME}
          type="text"
          value="Default"
          theme="readonly"
          readonly
        />
        )}

        <Styled.SelectStyled
          required
          name="product"
          label={COMMISSION_LABELS.PRODUCT}
          options={hook.hookProductOptions}
          isLoading={hook.hookProductsLoading}
          selected={hook.hookProduct}
          onChange={hook.hookSetProduct}
          error={hook.hookNewCommissionErrors.commissionProduct}
          isDisabled={hook.hookCommissionType === COMMISSION_TYPES.REFERRAL || hook.hookProductsLoading || isReadOnly}
        />

        <InputCalendar
          required
          label={COMMISSION_LABELS.START_DATE}
          placeholder={PLACEHOLDERS.SELECT_DATE}
          value={hook?.hookStartDate?.toDateString() || ''}
          error={hook.hookNewCommissionErrors.startDate}
          onClick={hook.hookOnOpenStartCalendar}
          isDisabled={isReadOnly}
        />
        <InputCalendar
          label={COMMISSION_LABELS.END_DATE}
          placeholder={PLACEHOLDERS.SELECT_DATE}
          value={hook?.hookEndDate?.toDateString() || ''}
          error={hook.hookNewCommissionErrors.endDate}
          onClick={hook.hookOnOpenEndCalendar}
          isDisabled={isReadOnly}
        />

        <Styled.ClearDateStyled>
          <Styled.ClearDateButtonStyled
            theme="text-only"
            onClick={hook.hookClearDate}
            disabled={isReadOnly}
          >
            {BUTTON_TEXT.CLEAR_DATE}
          </Styled.ClearDateButtonStyled>
        </Styled.ClearDateStyled>
      </Styled.DateSectionStyled>

      {hook.hookCommissionSubType === COMMISSION_DEFAULT_CUSTOM.CUSTOM && (
        <Styled.DescriptionSmallStyled
          height="130px"
          maxLength={140}
          label={COMMISSION_LABELS.COMMISSION_DESCRIPTION}
          value={hook.hookDescription}
          onChange={hook.hookSetDescription}
          disabled={isReadOnly}
        />
      )}

      {hook.hookCommissionType === COMMISSION_TYPES.CPA && (
        <CPACommissionStructure
          hook={hook}
          isReadOnly={isReadOnly}
        />
      )}
      {hook.hookCommissionType === REV_SHARE && (
        <RevShareCommissionStructure
          hook={hook}
          isReadOnly={isReadOnly}
        />
      )}
      {hook.hookCommissionType === COMMISSION_TYPES.BONUS && (
        <BonusCommissionStructure
          hook={hook}
          isReadOnly={isReadOnly}
        />
      )}

      {hook.hookCommissionSubType === COMMISSION_DEFAULT_CUSTOM.CUSTOM && (
        <Styled.PublisherGroupsWrapper>
          <Styled.PublisherGroupsTitleStyled>{PUBLISHER_GROUPS.TITLE}</Styled.PublisherGroupsTitleStyled>

          <Styled.PublisherGroupsDescriptionStyled>
            <div>{PUBLISHER_GROUPS.DESCRIPTION_ONE}</div>
            <div>{PUBLISHER_GROUPS.DESCRIPTION_TWO}</div>
          </Styled.PublisherGroupsDescriptionStyled>

          <Styled.InputTextStyled
            name="search"
            type="text"
            faIcon={faSearch}
            value={hook.hookSearch}
            placeholder={PLACEHOLDERS.SEARCH}
            onChange={hook.hookSetSearch}
          />
          <Table
            columns={columns(isReadOnly)}
            data={hook.hookTableData}
            onSort={hook.hookHandleSort}
            onRowClick={hook.hookOnRowClick}
            isLoading={hook.hookTableLoading}
            sortColumn={hook.hookSortColumn}
          />
          <Pagination
            total={hook.hookTotalPages}
            onPageChange={hook.hookOnPageChange}
            currentPage={hook.hookCurrentPage}
          />
        </Styled.PublisherGroupsWrapper>
      )}

      <Styled.ButtonSectionStyled>
        <Styled.ButtonStyled
          theme="secondary"
          width="150px"
          onClick={() => navigate(-1)}
        >
          {BUTTON_TEXT.CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          width="150px"
          loading={hook.hookLoading}
          disabled={hook.hookLoading || isReadOnly}
          onClick={hook.saveCommissionClickHandler}
        >
          {BUTTON_TEXT.ADD_COMMISSION}
        </Styled.ButtonStyled>
      </Styled.ButtonSectionStyled>

      <Calendar
        key={`$startCalendar${hook.hookMinStartDate}-${hook.hookMaxStartDate}`}
        date={hook.hookStartDate}
        onApply={hook.hookOnApplyStartCalendar}
        onCancel={hook.hookOnCancelStartCalendar}
        isOpen={hook.hookStartCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
        minDate={hook.hookMinStartDate}
        maxDate={hook.hookMaxStartDate}
      />

      <Calendar
        key={`$endCalendar${hook.hookMinEndDate}`}
        date={hook.hookEndDate}
        onApply={hook.hookOnApplyEndCalendar}
        onCancel={hook.hookOnCancelEndCalendar}
        isOpen={hook.hookEndCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
        minDate={hook.hookMinEndDate}
      />
      <CreateCommissionModal hook={hook} />
    </AppWrapper>
  );
};

export default NewCommission;
