import styled from 'styled-components';
import { fonts } from '../../../../../../styles/theme';

export const ChartWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ChartStyled = styled.div`
  width: 100%;
  height: 350px;
  z-index: 999;
`;

export const ZeroStateStyled = styled.span`
  ${fonts.gotham.H14M}
`;

export const DescriptionStyled = styled.span`
  ${fonts.lato.H14}
  margin-top: 1rem;
`;
