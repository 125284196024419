import { Permission } from '../../../../../entities';
import { useAccountInformation } from './useAccountInformation';
import { useTrackingDomains } from './useTrackingDomains';
import { useTrackingProfiles } from './useTrackingProfiles';

export const useInternalPublisher = (permissionsCodeList: string[] = []) => {
  const accountInformation = useAccountInformation();
  const trackingProfiles = useTrackingProfiles();
  const trackingDomains = useTrackingDomains();

  return {
    hookTrackingProfile: trackingProfiles,
    hookTrackingDomains: trackingDomains,
    hookAccountInformation: accountInformation,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
