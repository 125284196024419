import React, { useMemo } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper, Button, Calendar, InfoBox, Modal, PageTitle, Table, Tabs,
} from '../../../../components';
import { ERROR_ICON, path } from '../../../../utils';
import AddDeposit from './Modals/AddDeposit';
import DepositNotification from './Modals/DepositNotification';
import AddPaypalDepositFail from './Modals/AddPaypalDepositFail';
import MonthlyAccountBalance from './Tabs/MonthlyAccountBalance';
import PaypalOrder from './Tabs/PaypalOrder';
import {
  accountBalanceStatsColumns, accountBalanceStatsOriginalColumns, BUTTON_LABELS, STATS_TABS, SUBTITLE, TITLE_SUBTEXT,
} from './contracts';
import { useMerchantAccountBalance } from './hooks';
import AddPaypalDepositSuccess from './Modals/AddPaypalDepositSuccess';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type MerchantAccountBalanceProps = DefaultPropsType;

const MerchantAccountBalance = ({ permissionsCodeList = [] }: MerchantAccountBalanceProps) => {
  const { hookIsReadOnlyList, ...hook } = useMerchantAccountBalance(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;
  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      {hook.hookErrors.message && (
        <InfoBox
          title=""
          description={hook.hookErrors.message}
          icon={ERROR_ICON}
          theme="ERROR"
        />
      )}
      <PageTitle>
        {`${path.accountBalance.name} ${TITLE_SUBTEXT}`}
      </PageTitle>

      <Styled.SectionStyled>
        <Styled.SubHeaderWrapperStyled>
          <Styled.TitleStyled>
            {SUBTITLE}
          </Styled.TitleStyled>
          <Styled.TitleButtonAreaStyled>
            <Button
              theme="tertiary"
              onClick={hook.hookSetNotifyModal}
              disabled={isReadOnly}
            >
              {BUTTON_LABELS.NOTIFY}
            </Button>
            <Button
              onClick={hook.hookSetAddModal}
              disabled={isReadOnly}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ marginRight: 5 }}
              />
              {BUTTON_LABELS.ADD}
            </Button>
          </Styled.TitleButtonAreaStyled>
        </Styled.SubHeaderWrapperStyled>

        {hook.hookAccountStats && (
          <Table
            columns={hook.hookGeneralMerchantInfo.enableNewStats ? accountBalanceStatsColumns : accountBalanceStatsOriginalColumns}
            data={hook.hookAccountStats}
          />
        )}
      </Styled.SectionStyled>
      {useMemo(() => (
        <Styled.SectionStyled>
          <Tabs
            tabNames={STATS_TABS}
            elements={
            [
              <MonthlyAccountBalance
                selectedProductCategory={hook.hookSelectedProductCategory}
                setSelectedProjectCategory={hook.hookSetSelectedProductCategory}
                setAddModal={hook.hookSetAddModal}
                setIsCalendarOpen={hook.hookSetIsCalendarOpen}
                monthyAccountBalance={hook.hookMonthyAccountBalance}
                dateInputValue={hook.hookDateInputValue}
                clearFilterHander={hook.hookClearFilter}
                monthyAccountTotals={hook.hookMonthyAccountTotals}
                navigationHandler={hook.hookNavigationHandler}
                statementNavigationHandler={hook.hookStatementNavigationHandler}
                onSort={hook.hookSetSortColumn}
                sortColumn={hook.hookSortColumn}
                numberOfPages={hook.hookNumberOfPages}
                currentPage={hook.hookCurrentPage}
                onPageChange={hook.hookSetCurrentPage}
                csvDownHandler={hook.hookCsvDownHandler}
                isLoading={hook.hookIsLoading}
                enableNewStats={hook.hookGeneralMerchantInfo.enableNewStats}
                isReadOnly={isReadOnly}
              />,
              <PaypalOrder
                numberOfPages={hook.hookTotalPagePaypal}
                sortColumn={hook.hookSortColumnPaypal}
                handleSort={hook.hookHandleSortColumnPaypal}
                currentPage={hook.hookCurrentPagePaypal}
                onChangePage={hook.hookOnChangePagePaypal}
                getOrders={hook.hookGetPayPalOrders}
                paypalOrders={hook.hookPaypalOrders}
                productCategoryOptions={hook.hookActiveProductCategories}
                selectedProjectCategory={hook.hookSelectedPaypalProductCategory}
                setSelectedProjectCategory={hook.hookSetSelectedPaypalProductCategory}
                dateInputValue={hook.hookPaypalDateInputValue}
                setIsCalendarOpen={hook.hookSetIsPaypalCalendarOpen}
                paypalStatus={hook.hookPaypalStatus}
                setPaypalStatus={hook.hookSetPaypalStatus}
                clearFilterHandler={hook.hookClearPaypalFilter}
                addDepositHandler={hook.hookSetAddModal}
                enableNewStats={hook.hookGeneralMerchantInfo.enableNewStats}
                isReadOnly={isReadOnly}
              />,
            ]
          }
          />
        </Styled.SectionStyled>
      ), [
        hook.hookSelectedProductCategory,
        hook.hookMonthyAccountBalance,
        hook.hookDateInputValue,
        hook.hookMonthyAccountTotals,
        hook.hookSortColumn,
        hook.hookNumberOfPages,
        hook.hookCurrentPage,
        hook.hookIsLoading,
        hook.hookGeneralMerchantInfo.enableNewStats,
        hook.hookTotalPagePaypal,
        hook.hookSortColumnPaypal,
        hook.hookCurrentPagePaypal,
        hook.hookPaypalOrders,
        hook.hookActiveProductCategories,
        hook.hookSelectedPaypalProductCategory,
        hook.hookPaypalDateInputValue,
        hook.hookPaypalStatus,
        hook.hookGeneralMerchantInfo.enableNewStats,
      ])}
      {useMemo(() => (
        <Modal isOpen={hook.hookAddModal}>
          <AddDeposit
            setIsOpen={hook.hookSetAddModal}
            productCategoryOptions={hook.hookActiveProductCategories}
            seletedProjectCategory={hook.hookSelectedDepositProductCategory}
            selectedCurrency={hook.hookGeneralMerchantInfo.currency}
            setSeletedProjectCategory={hook.hookSetSelectedDepositProductCategory}
            despositAmount={hook.hookDepositAmount}
            depositAmountHander={hook.hookSetDepositAmount}
            additionalInfo={hook.hookAdditionalInfo}
            additionalInfoHander={hook.hookSetAdditionalInfo}
            addDepositHandler={hook.hookAddDeposit}
            errors={hook.hookErrors}
            payPalSuccessHandler={hook.hookPayPalSuccessHandler}
            payPalFailedHandler={hook.hookPayPalFailedHandler}
          />
        </Modal>
      ), [
        hook.hookAddModal,
        hook.hookActiveProductCategories,
        hook.hookSelectedDepositProductCategory,
        hook.hookGeneralMerchantInfo.currency,
        hook.hookDepositAmount,
        hook.hookAdditionalInfo,
        hook.hookErrors,
      ])}

      {useMemo(() => (
        <Modal isOpen={hook.hookNotifyModal}>
          <DepositNotification
            setIsOpen={hook.hookSetNotifyModal}
            productCategoryOptions={hook.hookActiveProductCategories}
            setSeletedProjectCategory={hook.hooksetSelectedNotifyProductCategory}
            seletedProjectCategory={hook.hookSelectedNotifyProductCategory}
            additionalInfo={hook.hookNofityAdditionalInfo}
            setAdditionalInfo={hook.hookSetNofityAdditionalInfo}
            paymentAmount={hook.hookPaymentAmount}
            setPaymentAmount={hook.hooksetPaymentAmount}
            selectedPaymentMethod={hook.hookSelectedPaymentMethod}
            setSelectedPaymentMethod={hook.hookSetSelectedPaymentMethod}
            paymentEmail={hook.hookPaymentEmail}
            paymentName={hook.hookPaymentName}
            setPaymentEmail={hook.hookSetPaymentEmail}
            setPaymentName={hook.hookSetPaymentName}
            paymentDate={hook.hookPaymentInputDate}
            setIsCalendarOpen={hook.hookSetNotifyCalenderOpen}
            notifyDepositHandler={hook.hookNotifyDeposit}
            errors={hook.hookErrors}
            notifyAdminLoading={hook.hookNotifyAdminLoading}
          />
        </Modal>
      ), [
        hook.hookActiveProductCategories,
        hook.hookSelectedNotifyProductCategory,
        hook.hookNofityAdditionalInfo,
        hook.hookPaymentAmount,
        hook.hookSelectedPaymentMethod,
        hook.hookPaymentEmail,
        hook.hookPaymentName,
        hook.hookPaymentInputDate,
        hook.hookErrors,
        hook.hookNotifyAdminLoading,
        hook.hookNotifyModal,
      ])}

      {useMemo(() => (
        <Modal isOpen={hook.hookPaypalFailOpen}>
          <AddPaypalDepositFail
            cancelHandler={hook.hookSetPaypalFailOpen}
            retryHandler={hook.hookRetryPaypalDepositHandler}
          />
        </Modal>
      ), [
        hook.hookPaypalFailOpen,
      ])}

      {useMemo(() => (
        <Modal isOpen={hook.hookPaypalSuccessOpen}>
          <AddPaypalDepositSuccess
            closeHandler={hook.hookSetPaypalSuccessOpen}
          />
        </Modal>
      ), [
        hook.hookPaypalSuccessOpen,
      ])}

      {useMemo(() => (
        <Calendar
          date={hook.hookStartDate}
          endDate={hook.hookEndDate}
          onCancel={hook.hookSetIsCalendarOpen}
          onApply={hook.hookApplyCalendarHandler}
          isOpen={hook.hookIsCalendarOpen}
          errors={hook.hookErrors}
        />
      ), [
        hook.hookStartDate, hook.hookEndDate, hook.hookIsCalendarOpen, hook.hookErrors,
      ])}

      {useMemo(() => (
        <Calendar
          date={hook.hookPaymentDate}
          onCancel={hook.hookSetNotifyCalenderOpen}
          onApply={hook.hookApplyNoficationCalender}
          isOpen={hook.hookNotifyCalenderOpen}
          isSingle
        />
      ), [
        hook.hookPaymentDate, hook.hookNotifyCalenderOpen,
      ])}

      {useMemo(() => (
        <Calendar
          date={hook.hookPaypalStartDate}
          endDate={hook.hookPaypalEndDate}
          onCancel={hook.hookSetIsPaypalCalendarOpen}
          onApply={hook.hookApplyPaypalCalendar}
          isOpen={hook.hookIsPaypalCalendarOpen}
        />
      ), [
        hook.hookPaypalStartDate, hook.hookPaypalEndDate, hook.hookIsPaypalCalendarOpen,
      ])}
    </AppWrapper>
  );
};

export default MerchantAccountBalance;
