import React from 'react';
import * as Styled from '../../styles';
import { CellProps } from '../../types';

export const PermissionCell = ({ val }: CellProps) => (
  <Styled.CellWrapper>
    <p>
      {val ? (
        'All Users on the Account'
      ) : (
        'Only Me'
      )}
    </p>
  </Styled.CellWrapper>
);
