import styled from 'styled-components';
import {
  Select, Button, InputText, InputTextarea,
  Checkbox,
} from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const WrapperStyled = styled.div`
  padding-bottom: 80px;
`;

export const ModalWrapperStyled = styled.div`
width: 720px;
padding: 1rem;
`;
export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;
export const HeaderButtonsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const HeaderStyledRuleManager = styled.div`
  display: flex;
  margin-bottom: 4rem;
`;

export const HeaderStyledRuleMangerTitle = styled.div`
  display: flex;
  flex: 1 0 0;
  justify-content: flex-start;
`;

export const HeaderStyledRuleMangerButtons = styled.div`
  display: flex;
  flex: 4 0 0;
  justify-content: flex-end;
`;

export const FiltersStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  margin-bottom: 2rem;
`;

export const FooterWrapperStyled = styled.div`
  display: flex;
`;

export const RecordsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const ExtendedRecordsStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const RecordsLabelStyled = styled.div`
  ${fonts.lato.H14}
  color: ${colors.color1};
`;

export const SelectStyled = styled(Select)`
  width: 120px;
  margin: 0 1rem;
`;

export const TopStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const CloseButtonStyled = styled(Button)`
  border: transparent;
  font-size: 1.2rem;

  &:hover {
    border: transparent;
    background: transparent;
    color: ${colors.color2};
  }
`;

export const FooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1.2rem;
`;

export const ContentStyled = styled.div`
  margin-bottom: 5px;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const DescriptionStyled = styled.p`
  ${fonts.lato.H16}
  color: ${colors.color1};
  margin-bottom: 2.5rem;
`;

export const InputStyled = styled(InputText)``;

export const EditInputStyled = styled(InputText)`
  flex: 0.3;
`;

export const EditInputAreaStyled = styled(InputTextarea)`
  flex: 0.3;
`;

export const RuleDetailsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 2rem;
`;

export const EditSelectStyled = styled(Select)`
  flex: 0.3;
`;

export const EditRuleWrapper = styled.div`
  padding-top: 3rem;
`;

export const EditRuleLabelStyled = styled.p`
  ${fonts.lato.H20B}
  color: ${colors.color1};
  margin-bottom: 1rem;
`;

export const EditRuleContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 2rem;
  padding-top: 1rem;
`;

export const RuleCriteriaContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2rem;
  padding-top: 1rem;
`;

export const RuleCriteriaContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 2rem;
`;

export const CategoryWrapper = styled.div`
  flex: 0.3;
  padding-bottom: 2rem;
`;

export const CategoryHeader = styled.p`
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: ${colors.color13};
  ${fonts.gotham.H12M};
`;

export const CategoryValue = styled.p`
  width: 100%;
  color: ${colors.color1};
  ${fonts.lato.H16}
  padding-bottom: 1rem;
`;

export const CheckboxStyled = styled(Checkbox)`
  span {
    opacity: 1;
  }
`;
