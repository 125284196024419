import { gql } from '@apollo/client';

export const LIST_PUBLISHERS_AND_GROUPS = gql`
  query ListPublishersAndGroups($input: ListPublishersAndGroupsInput) {
    listPublishersAndGroups(input: $input) {
      pubsAndGroups {
        id
        name
        type
        acceptedDate
        publisherIds
      }
    }
  }
`;
