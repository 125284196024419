import React from 'react';

import {
  AppWrapper, Checkbox, PageTitle, Tabs,
} from '../../../../components';
import { path } from '../../../../utils';
import { ProductInformation } from './Tabs';
import { useNewProduct } from './hooks';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';
import { useUserInfo } from '../../../../hooks';

type NewProductProps = DefaultPropsType;

const NewProduct = ({ permissionsCodeList = [] }: NewProductProps) => {
  const { hookIsReadOnlyList, ...hook } = useNewProduct(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;
  const userInfo = useUserInfo();
  const isAdmin = userInfo?.hookUserInfo?.userTypesId === 1;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <PageTitle>{path.addNewProduct.name}</PageTitle>
      <Styled.HeaderSectionStyled>
        {isAdmin && (
        <>
          <Checkbox
            checked={hook.hookNotifyPublisher}
            label=""
            onChange={hook.hookSetNotifyPublisher}
            disabled={isReadOnly}
          />
          {' '}
          Notify Publishers
        </>
        )}
      </Styled.HeaderSectionStyled>

      <Tabs
        tabNames={['Product Information']}
        elements={[<ProductInformation isReadOnly={isReadOnly} />]}
      />
    </AppWrapper>
  );
};

export default NewProduct;
