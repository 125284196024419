export const AMOUNTS = ['grossSales', 'netSales', 'amount'];

export const COLUMNS: TableColumn[] = [
  {
    dataField: 'merchant',
    text: 'MERCHANT',
  },
  {
    dataField: 'transactionId',
    text: 'TRANSACTION ID',
  },
  {
    dataField: 'orderId',
    text: 'ORDER ID',
  },
  {
    dataField: 'recordDate',
    text: 'RECORD DATE',
  },
  {
    dataField: 'productName',
    text: 'PRODUCT NAME',
  },
  {
    dataField: 'grossSales',
    text: 'GROSS SALES',
  },
  {
    dataField: 'netSales',
    text: 'NET SALES',
  },
  {
    dataField: 'commissionId',
    text: 'COMMISSION ID',
  },
  {
    dataField: 'recordType',
    text: 'RECORD TYPE',
  },
  {
    dataField: 'amount',
    text: 'AMOUNT',
  },
];

export const MERCHANT_COLUMNS: TableColumn[] = [
  {
    dataField: 'publisher',
    text: 'PUBLISHER',
  },
  {
    dataField: 'transactionId',
    text: 'TRANSACTION ID',
  },
  {
    dataField: 'orderId',
    text: 'ORDER ID',
  },
  {
    dataField: 'recordDate',
    text: 'RECORD DATE',
  },
  {
    dataField: 'productName',
    text: 'PRODUCT NAME',
  },
  {
    dataField: 'grossSales',
    text: 'GROSS SALES',
  },
  {
    dataField: 'netSales',
    text: 'NET SALES',
  },
  {
    dataField: 'commissionId',
    text: 'COMMISSION ID',
  },
  {
    dataField: 'recordType',
    text: 'RECORD TYPE',
  },
  {
    dataField: 'amount',
    text: 'AMOUNT',
  },
];
