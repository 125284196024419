export const SECURITY_PERMISSIONS_PAGE = {
  PAGE_TITLE: 'Security Permissions',
  ROLES_TITLE: 'Security Roles',
  NEW_ROLE_BUTTON_TEXT: 'Create New Role',
  ROLE_TYPE_LABEL: 'Role Type',
  ROLE_SELECTION_LABEL_PARTIAL: 'Role Name',
  PERMISSIONS: {
    TITLE: 'Update Permissions',
    CREATE: 'Create',
    READ: 'Read',
    UPDATE: 'Update',
    DELETE: 'Delete',
  },
  CREATE_ROLE_MODAL: {
    TITLE: 'Create Role',
    ROLE_TYPE_LABEL: 'Role Type',
    ROLE_NAME_LABEL: 'Role Name',
    CANCEL_BUTTON: 'Cancel',
    CONFIRM_BUTTON: 'Confirm',
  },
};
