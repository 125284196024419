import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../../components';
import { useUserInfo } from '../../../../hooks';
import { fonts, colors } from '../../../../styles/theme';
import { MERCHANT_PREFIX, path } from '../../../../utils';

type CellProps = {
  val: any
}

type CampaignCellProps = {
  val: any
  row: any
}

type ActionProps = {
  row: any
}

const CellStyled = styled.div`
  display: flex;
  min-height: 72px;
  align-items: center;
  color: ${colors.color1};
  ${fonts.lato.H16};
`;

const AdCellStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.color1};
  ${fonts.lato.H16};
`;

const CampaignCellStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colors.color1};

  p {
    ${fonts.lato.H15B}
    margin-bottom: 0.3rem;
  }

  span {
    color: ${colors.color13};
    width: 100%;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
  }
`;

const ButtonStyled = styled(Button)`
  background: transparent;
  border: none;
  color: ${colors.color1};
  font-size: 1rem;

  &:hover {
    background: transparent;
    border: none;
    color: ${colors.color2};
  }
`;

const CellFormatted = ({ val }: CellProps) => (
  <CellStyled>
    {val}
  </CellStyled>
);

const CampaignCell = ({ val, row }: CampaignCellProps) => (
  <CampaignCellStyled>
    <p>{val}</p>
    <span>{row?.description}</span>
  </CampaignCellStyled>
);

const AdCell = ({ val }: CellProps) => (
  <AdCellStyled>
    {val === undefined || val === '' || val === null ? 0 : val}
  </AdCellStyled>
);

const ActionCell = ({ row }: ActionProps) => {
  const navigate = useNavigate();
  const { hookWhoAmI } = useUserInfo();

  const goTo = () => {
    navigate(`${hookWhoAmI.programId ? MERCHANT_PREFIX : ''}${path.editCampaign.href}`, {
      state: {
        campaignId: row.campaignId,
        programId: row.programId,
        companyId: row.merchantId,
      },
    });
  };

  return (
    <CellStyled>
      <ButtonStyled onClick={goTo}>
        <FontAwesomeIcon icon={faChevronRight} />
      </ButtonStyled>
    </CellStyled>
  );
};

export const columns: TableColumn[] = [
  {
    dataField: 'campaignId',
    text: 'ID',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (val: any) => <CellFormatted val={val} />,
    width: '7%',
  },
  {
    dataField: 'campaignName',
    text: 'Campaign/Definition',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (val: any, row: any) => (
      <CampaignCell
        val={val}
        row={row}
      />
    ),
    width: '38%',
  },
  {
    dataField: 'merchantName',
    text: 'Merchant',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (val: any) => <CellFormatted val={val} />,
    width: '29%',
  },
  {
    dataField: 'bannerCount',
    text: 'Banner',
    position: 'center',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (val: any) => <AdCell val={val} />,
    width: '7%',
  },
  {
    dataField: 'linkCount',
    text: 'Text',
    position: 'center',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (val: any) => <AdCell val={val} />,
    width: '7%',
  },
  {
    dataField: 'html5Count',
    text: 'HTML5',
    position: 'center',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (val: any) => <AdCell val={val} />,
    width: '7%',
  },
  {
    dataField: '_',
    text: '',
    formatter: (_: any, row: any) => (
      <ActionCell row={row} />
    ),
    width: '5%',
    notClickable: true,
  },
];
