export const useUndo = (editorRef: any) => {
  const handleUndo = () => {
    const editor = editorRef.current.getEditor();
    editor.history.undo();
  };

  return {
    hookUseUndo: handleUndo,
  };
};
