import { useLazyQuery } from '@apollo/client';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useUserInfo } from '../../../../hooks';

import {
  csvGenerator, dateFormatter, path, MERCHANT_PREFIX, rangeFormat, dateSetterFormatter,
} from '../../../../utils';
import dataToCSVFormatter from '../../../../utils/csvStringConverter';
import {
  ALL_PRODUCTS, ALL_STATUSES, CATEGORY_DEFAULT_OPTIONS, CSV_FILE_NAME, PRODUCT_DEFAULT_OPTIONS, MERCHANT_DEFAULT_OPTIONS, PUBLISHER_DEFAULT_OPTIONS, SORT_COLUMN_NAMES, SUMMARY_REPORT_ADMIN_CSV_COLUMNS,
} from '../enums';
import {
  initialState, SUMMARY_REPORT_DROPDOWN_ACTIONS, summaryReportDropdownReducer,
} from '../Reducers';
import { useFintelCheckContext } from '../../../Merchants/FintelCheck/hooks';
import { FINTEL_CHECK_ACTIONS } from '../../../Merchants/FintelCheck/reducers';
import { columns } from '../contracts';
import {
  GET_SUMMARY_REPORT, GET_SUMMARY_REPORT_CSV, GET_SUMMARY_REPORT_FILTER_OPTIONS,
} from '../graphql/queries';

type ProductOptionType = {
  label: string,
  value: string,
  productCategory?: string,
  id?: string,
  customizedProductId?: string
}

// Status values used for the FintelCheckDetails page are different from those used on the summary report
const formatStatusForDetailsPage = (value: SelectOption) => {
  switch (value.value) {
    case 'Not Applicable':
      return { label: 'Not Applicable', value: 'NF' };
    case 'Fail':
      return { label: 'Fail', value: 'Check Fail' };
    default:
      return value;
  }
};

// Status values used for the FintelCheckDetails page are different from those used on the summary report
const formatStatusFromDetailsPage = (value: SelectOption) => {
  switch (value.value) {
    case 'NF':
      return { label: 'Not Applicable', value: 'Not Applicable' };
    case 'Check Fail':
      return { label: 'Fail', value: 'Fail' };
    case '':
      return initialState.status;
    default:
      return value;
  }
};

// The Summary Reports Product Dropdown uses the customizedProductId, while the FintelCheckDetails Page uses the product's Id
const formatProductFromDetailsPage = (value: ProductOptionType): ProductOptionType => {
  if (value.value && value.value !== initialState.product.value) {
    return {
      ...value, label: value.label, value: value.customizedProductId || '', id: value.value,
    };
  }
  return value;
};

// this is the real one.
export const useSummaryReport = (runDate?: string) => {
  const { contextState, contextDispatchHandler } = useFintelCheckContext();
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();
  const location = useLocation();
  // Filters
  // When Navigating from the Fintel Check Details page fill in the Dropdowns with data saved in Context
  const [filtersState, filtersDispatch] = useReducer(
    summaryReportDropdownReducer,
    null,
    () => (location?.state?.from === `${MERCHANT_PREFIX}${path.fintelCheckDetails.href}` ? {
      ...initialState,
      ...(contextState?.status) && { status: formatStatusFromDetailsPage(contextState.status) },
      ...(contextState?.product) && { product: formatProductFromDetailsPage(contextState.product) },
      ...(contextState?.productCategory) && { productCategory: contextState.productCategory },
      ...(contextState?.publisher) && { publisher: contextState.publisher },
      ...(contextState?.merchantId) && {
        merchant: {
          label: contextState.isMerchantAll ? 'All Merchants' : `${contextState.merchantId} - ${contextState.merchantName}`,
          value: contextState.isMerchantAll ? '' : contextState.merchantId,
        },
      },
      ...(contextState?.startDate && contextState?.endDate) && { dateRange: { startDate: contextState.startDate, endDate: contextState.endDate } },
      ...(contextState?.startDate && contextState?.endDate) && { dateRangeCal: { startDate: new Date(contextState.startDate), endDate: new Date(contextState.endDate) } },
    } : initialState),
  );
  // Calendar
  const [openCalendar, setOpenCalendar] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [publisherOptions, setPublisherOptions] = useState<SelectOption[]>(PUBLISHER_DEFAULT_OPTIONS);
  const [merchantOptions, setMerchantOptions] = useState<SelectOption[]>(MERCHANT_DEFAULT_OPTIONS);
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>(CATEGORY_DEFAULT_OPTIONS);
  const [productOptions, setProductOptions] = useState<ProductOptionType[]>(PRODUCT_DEFAULT_OPTIONS);
  const [filteredProductOptions, setFilteredProductOptions] = useState<ProductOptionType[]>(PRODUCT_DEFAULT_OPTIONS);
  const [getFilterOptions, { loading: getFilterOptionsLoading }] = useLazyQuery(GET_SUMMARY_REPORT_FILTER_OPTIONS);

  // Data
  const [tableData, setTableData] = useState<any[]>();
  const [dataGenerated, setDataGenerated] = useState(false);
  const [generateClicked, setGenerateClicked] = useState(false);
  const [getFintelChecks, { loading: getFintelChecksLoading }] = useLazyQuery(GET_SUMMARY_REPORT);
  const [getFintelCheckCSV, { loading: getCSVLoading }] = useLazyQuery(GET_SUMMARY_REPORT_CSV);
  // Table & Pagination
  const [tableColumns, setTableColumns] = useState<TableColumn[]>(columns());
  const [tableKey, setTableKey] = useState<number>(123456); // Used to force table to rerender from start
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'publisher', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [refetchTable, setRefetchTable] = useState<boolean>(false);

  const fetchData = async (status?: string | null, date?: string, publisherId?: string) => {
    const input = {
      publisherId: publisherId || filtersState.publisher.value,
      merchantId: filtersState.merchant.value,
      startDate: filtersState.dateRange.startDate,
      endDate: filtersState.dateRange.endDate,
      limit: Number(filtersState.records.value),
      currentPage,
      status: (filtersState.status.value === ALL_STATUSES) ? null : filtersState.status.value,
      customizedProductId: (filtersState.product.value === ALL_PRODUCTS) ? null : filtersState.product.value,
      sortBy: SORT_COLUMN_NAMES[sortColumn.column],
      sortDirection: sortColumn.direction,
      productCategory: filtersState.productCategory.value,
    };
    const { data } = await getFintelChecks({ variables: { input }, fetchPolicy: 'no-cache' });
    return data;
  };

  function getPublisherName(merchantId: string, publisherId: string, publisherName:string) {
    if (merchantId === publisherId) {
      return 'Manual Urls';
    }
    return `${publisherId} - ${publisherName}`;
  }
  // fetch Data
  const getFintelChecksHandler = async (status: string | null, publisherId?: string) => {
    const data = await fetchData(status, publisherId);
    setTableKey((last) => last + 1);
    if (data?.fintelChecksAdmin?.fintelChecks) {
      const formattedData = data.fintelChecksAdmin.fintelChecks.map((item: any) => ({
        publisherId: item.publisherId,
        publisherName: item.publisherName,
        merchantId: item.merchantId,
        merchantName: item.merchantName,
        merchant: item.merchantName,
        publisher: getPublisherName(item.merchantId, item.publisherId, item.publisherName),
        page: item.pageCount,
        pass: item.passCount,
        review: item.reviewCount,
        fail: item.failCount,
        incomplete: item.incompleteCount,
        notApplicable: item.nfCount,
        trackingEventDate: (new Date(item.trackingEventDate)).toDateString(),
        checkDate: dateSetterFormatter(new Date(item.trackingEventDate)),
      }));
      setTableColumns(columns(data.fintelChecksAdmin.totalCounts));
      setTableData(formattedData);
      setTotalPages(data.fintelChecksAdmin.totalPages);
      setTotalRecords(data.fintelChecksAdmin.count);
      setDataGenerated(true);
    } else {
      setTableColumns(columns());
      setTableData([]);
      setCurrentPage(1);
      setTotalPages(0);
      setTotalRecords(0);
    }
  };

  const fetchCSV = async () => {
    const input: { [key: string]: any } = {
      publisherId: filtersState.publisher.value,
      merchantId: filtersState.merchant.value || '',
      startDate: filtersState.dateRange.startDate,
      endDate: filtersState.dateRange.endDate,
      limit: 1000,
      status: (filtersState.status.value === ALL_STATUSES) ? null : filtersState.status.value,
      customizedProductId: (filtersState.product.value === ALL_PRODUCTS) ? null : filtersState.product.value,
      productCategory: filtersState.productCategory.value,
    };

    const { data } = await getFintelCheckCSV({ variables: { input }, fetchPolicy: 'no-cache' });
    if (data?.fintelChecksCSVAdmin.fintelChecks) {
      const fintelChecks = data.fintelChecksCSVAdmin.fintelChecks.map((item: any) => ({
        ...item,
        publisher: `${item.publisherId} - ${item.publisherName}`.replaceAll(',', ' '),
        merchant: `${item.merchantId} - ${item.merchantName}`.replaceAll(',', ' '),
        checkdate: `${item.trackingEventDate}`,
      }));
      csvGenerator(dataToCSVFormatter(SUMMARY_REPORT_ADMIN_CSV_COLUMNS, fintelChecks), CSV_FILE_NAME(''));
    }
  };

  const fetchFilterOptions = async (merchantId: string) => {
    const checkDates: {label: string, value: string, publisherCount: number}[] = [];
    const publishers: SelectOption[] = [];
    const merchants: SelectOption[] = [];
    const categories: SelectOption[] = [];
    const products: ProductOptionType[] = [];
    const { data } = await getFilterOptions({
      variables: {
        input: {
          merchantId,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getNewCheckReportFilterOptions?.checkDates && !runDate) {
      data.getNewCheckReportFilterOptions.checkDates.forEach((item: any) => {
        checkDates.push({
          label: dateFormatter(new Date(item.checkDate)),
          value: item.checkDate,
          publisherCount: item.publisherCount,
        });
      });
    }

    if (data?.getNewCheckReportFilterOptions?.categories) {
      data.getNewCheckReportFilterOptions.categories.forEach((item: any) => {
        categories.push({
          label: item.label,
          value: item.value,
        });
      });
      setCategoryOptions([...CATEGORY_DEFAULT_OPTIONS, ...categories]);
    }
    if (data?.getNewCheckReportFilterOptions?.products) {
      data.getNewCheckReportFilterOptions.products.forEach((item: any) => {
        products.push({
          label: `${item.customizedProductId} - ${item.name}`,
          value: item.customizedProductId,
          productCategory: item.productCategory,
          id: item.id,
          customizedProductId: item.customizedProductId,
        });
      });
      setProductOptions([...PRODUCT_DEFAULT_OPTIONS, ...products]);
      setFilteredProductOptions([...PRODUCT_DEFAULT_OPTIONS, ...products]);
    }
    if (data?.getNewCheckReportFilterOptions?.publishers) {
      data.getNewCheckReportFilterOptions.publishers.forEach((item: any) => {
        publishers.push({
          label: `${item.value} - ${item.label}`,
          value: item.value,
        });
      });
      const finalPublishers: { label: string; value: string; }[] = [...PUBLISHER_DEFAULT_OPTIONS(), ...publishers];
      setPublisherOptions(finalPublishers);
    }
    if (data?.getNewCheckReportFilterOptions?.merchants) {
      data.getNewCheckReportFilterOptions.merchants.forEach((item: any) => {
        merchants.push({
          label: `${item.value} - ${item.label}`,
          value: item.value,
        });
      });
      const finalMerchants: { label: string; value: string; }[] = [...MERCHANT_DEFAULT_OPTIONS(), ...merchants];
      setMerchantOptions(finalMerchants);
    }
  };

  const filtersDispatchHandler = (value: SelectOption, type: symbol) => {
    filtersDispatch({ type, data: value });
    setGenerateClicked(false);
    setDataGenerated(false);
    setTableColumns(columns());
    setTableData([]);
    setCurrentPage(1);
    setTotalPages(0);
    setTotalRecords(0);
    if (type === SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PRODUCT_CATEGORY) {
      contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_PRODUCT_CATEGORY, data: value });
    }
    if (type === SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PRODUCT) {
      if (value.value && value.value !== initialState.product.value) {
        // Save the customizedProductId in context to recover original value, needed for navigating back to this page.
        contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_PRODUCT, data: { label: value.label, value: (value as ProductOptionType).id, customizedProductId: value.value } });
      } else {
        contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_PRODUCT, data: value });
      }
    }
    if (type === SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_STATUS) {
      if (value.value === initialState.status.value) {
        contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_STATUS, data: { label: 'All Statuses', value: '' } });
      } else {
        contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_STATUS, data: formatStatusForDetailsPage(value) });
      }
    }
    if (type === SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PUBLISHER) {
      contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_PUBLISHER, data: value });
    }
    if (type === SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_MERCHANT) {
      let localIsMerchantAll = true;
      if (value.value) localIsMerchantAll = false;
      contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_IS_MERCHANT_ALL, data: localIsMerchantAll });
      contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_MERCHANT, data: value });
      filtersDispatch({ type: SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PRODUCT, data: { label: 'All Products', value: 'All Products' } });
      filtersDispatch({ type: SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PUBLISHER, data: { label: 'All Publishers', value: '' } });
      fetchFilterOptions(value.value);
    }
    if (type === SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_RECORDS) {
      setCurrentPage(1);
      setRefetchTable(true);
    }
  };

  const setOnCancelCalendarHandler = () => {
    setOpenCalendar(false);
  };

  const onChangeDateHandler = (startDateValue: Date, endDateValue?: Date, range?: string) => {
    // Set filters dateRange and context start and end date from any change in the calender dates
    filtersDispatch({ type: SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_DATE_RANGE, data: { startDate: dateSetterFormatter(startDateValue), endDate: dateSetterFormatter(endDateValue) } });
    filtersDispatch({ type: SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_DATE_RANGE_CAL, data: { startDate: startDateValue, endDate: endDateValue } });
    contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_START_DATE, data: dateSetterFormatter(startDateValue) });
    contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_END_DATE, data: dateSetterFormatter(endDateValue) });

    setIsCalendarOpen(false);
    // Update the table
  };

  const setOpenCalendarHandler = () => {
    setOpenCalendar(!openCalendar);
  };

  const handleGenerate = async () => {
    setGenerateClicked(true);
    setRefetchTable(true);
    setCurrentPage(1);
  };

  const handleSort = (dataField: string) => {
    setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    setRefetchTable(true);
  };

  const handlePageChange = (value: any) => {
    setCurrentPage(value);
    setRefetchTable(true);
  };

  const clearFormHandler = (value: typeof initialState, type: symbol) => {
    filtersDispatch({ type, data: value });
    setGenerateClicked(false);
    setDataGenerated(false);
    setTableColumns(columns());
    setTableData([]);
    setCurrentPage(1);
    setTotalPages(0);
    setTotalRecords(0);
    setFilteredProductOptions(productOptions);
    contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_PRODUCT, data: initialState.product });
    contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_PRODUCT_CATEGORY, data: initialState.productCategory });
    contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_STATUS, data: { label: 'All Statuses', value: '' } });
    contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_PUBLISHER, data: initialState.publisher });
  };

  const updateProductOptionsHandler = (newCategory: SelectOption) => {
    if (newCategory.value) {
      const newOptions = productOptions.filter((option) => option.productCategory === newCategory.value);
      setFilteredProductOptions([...PRODUCT_DEFAULT_OPTIONS, ...newOptions]);
    } else {
      setFilteredProductOptions(productOptions);
    }
  };

  if (generateClicked && refetchTable) {
    setRefetchTable(false);
    getFintelChecksHandler(filtersState.status.value, filtersState.publisher.value);
  }
  useEffect(() => {
    if (location?.state?.from === `${MERCHANT_PREFIX}${path.fintelCheckDetails.href}` && !contextState.isMerchantAll) {
      fetchFilterOptions(contextState.merchantId || '');
    } else {
      fetchFilterOptions('');
    }
    // If you are not navigating from the Fintel Check Details page reset the context values otherwise generate report.
    if (location?.state?.from !== `${MERCHANT_PREFIX}${path.fintelCheckDetails.href}`) {
      contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_PRODUCT, data: initialState.product });
      contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_PRODUCT_CATEGORY, data: initialState.productCategory });
      contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_STATUS, data: { label: 'All Statuses', value: '' } });
      contextDispatchHandler({ type: FINTEL_CHECK_ACTIONS.SET_PUBLISHER, data: initialState.publisher });
    } else {
      handleGenerate();
    }
    if (runDate) {
      setGenerateClicked(true);
      getFintelChecksHandler(filtersState.status.value, runDate);
    }
  }, []);
  return {
    hookData: tableData,
    hookTableColumns: tableColumns,
    hookTableKey: tableKey,
    hookFiltersState: filtersState,
    hookFiltersDispatch: filtersDispatchHandler,
    hookClearForm: clearFormHandler,
    hookNavigate: navigate,

    hookContext: contextState,
    hookSetContext: contextDispatchHandler,

    hookPublisherOptions: publisherOptions,
    hookMerchantOptions: merchantOptions,
    hookCategoryOptions: categoryOptions,
    hookProductOptions: productOptions,
    hookFilteredProductOptions: filteredProductOptions,
    hookFilterOptionsLoading: getFilterOptionsLoading,

    hookUpdateProductOptions: updateProductOptionsHandler,

    hookSortColumn: sortColumn,
    hookHandleSort: handleSort,

    // Calendar
    hookOpenCalendar: openCalendar,
    hookSetOpenCalendar: setOpenCalendarHandler,
    hookOnCancelCalendar: setOnCancelCalendarHandler,
    isCalendarOpen,
    setIsCalendarOpen,
    onChangeDateHandler,
    hookSelectedDateRange: (): string => `${new Date(filtersState.dateRange.startDate).toDateString()} / ${new Date(filtersState.dateRange.endDate).toDateString()}`,
    hookDateRangeCal: { startDate: filtersState.dateRangeCal.startDate, endDate: filtersState.dateRangeCal.endDate },

    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookTotalRecords: totalRecords,
    hookOnPageChange: handlePageChange,
    hookGenerateLoading: getFintelChecksLoading,
    hookHandleGenerate: handleGenerate,
    hookDataGenerated: dataGenerated,
    hookHandleCSV: fetchCSV,
    hookCSVLoading: getCSVLoading,
  };
};
