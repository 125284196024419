import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
query company($companyId: ID!) {
  company(id: $companyId) {
    id
    invitesOnHold
    invitesOnHoldStartDate
  }
}
`;
