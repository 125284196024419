import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../styles/theme';
import { Link } from '../Link';

export const MenuNotificationStyled = styled.div`
  font-size: 16px;
  position: relative;
`;

export const UnreadsStyled = styled.span`
  ${fonts.lato.H12B}
  font-size: 9px;
  line-height: 1.8;
  border-radius: 10px;
  background: ${colors.color2};
  color: white;
  position: absolute;
  top: 2px;
  right: -2px;
  padding: 0px 5px;
  z-index: 5;
`;

export const DropdownWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  padding-top: 16px;
`;

export const NotificationStyled = styled.button`
  display: flex;
  flex-direction: column;
  padding: 0px 16px 12px 16px;
  background: transparent;
  outline: none;
  border: none;
  text-align: 'left';
  white-space: break-spaces;
  &:hover {
    cursor: pointer;
  }
`;

export const NotificationsHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
`;

export const CircleStyled = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${colors.color2};
  margin-right: 3px;
`;

export const TitleStyled = styled.aside`
  font-size: 8px;
  color: ${colors.color7};
  text-transform: uppercase;
  line-height: 1.5;
  text-align: left;
`;

export const DateStyled = styled.aside`
  font-size: 8px;
  color: ${colors.color7};
  line-height: 1.5;
`;

export const DescriptionStyled = styled.aside`
  ${fonts.gotham.H11}
  margin-left: 10px;
  line-height: 1.5;
  white-space: break-spaces;
  text-align: left;
  b {
    font-family: gotham_promedium;
  }
`;

export const FooterStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 0 10px;
`;

export const ClockStyled = styled(FontAwesomeIcon)`
  font-size: 10px;
  color: ${colors.color7};
  margin-right: 2px;
`;

export const SeeAllWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${colors.color3};
  height: 32px;
  text-align: center;
  width: 100%;
  cursor: default;
`;

export const LinkStyled = styled(Link)`
  ${fonts.gotham.H11M}
  color: ${colors.color12}
`;
