import { gql } from '@apollo/client';

export const GET_MERCHANT_INFO = gql`
  query getMerchantInfo($companyId: ID!) {
    company(id: $companyId) {
      id
      program {
        id
        products {
          id
          name
          customizedProductId
          productCategory
          status
        }
        ads {
          id
          adName
          adStatus
        }
      }
      memberships {
        id
        status
        publisher {
          id
          companyName
        }
      }
    }
  }
`;
