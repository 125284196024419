import React from 'react';
import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons';
import { faFlagUsa, faGlobe } from '@fortawesome/free-solid-svg-icons';

import { UsaMapGraph } from '../../components/Graph';
import * as Styled from './styles';

import { TrafficAnalyticsProps } from '../../types';

type UsaTabProps = TrafficAnalyticsProps & {
  loading: boolean
}

export const UsaTab = ({ trafficAnalytics, loading }: UsaTabProps) => (
  <Styled.TabWrapper>
    <Styled.CountryWrapperStyled>
      <Styled.CountryStyled>
        <Styled.IconStyled icon={faGlobe} />
        {trafficAnalytics.total}
        {' '}
        Total visitors
      </Styled.CountryStyled>
      <Styled.CountryStyled>
        <Styled.IconStyled icon={faFlagUsa} />
        {trafficAnalytics.usTotal}
        {' '}
        USA visitors
      </Styled.CountryStyled>
      <Styled.CountryStyled>
        <Styled.IconStyled icon={faCanadianMapleLeaf} />
        {trafficAnalytics.canadaTotal}
        {' '}
        Canadian visitors
      </Styled.CountryStyled>
    </Styled.CountryWrapperStyled>
    {
      loading
        ? <Styled.SpinnerStyled theme="secondary" />
        : (
          <>
            <UsaMapGraph data={trafficAnalytics.us} />
            <Styled.StatisticsStyled>The world map&apos;s traffic statistics display over 100 visitors</Styled.StatisticsStyled>
          </>
        )
    }
  </Styled.TabWrapper>
);
