export type ModalInfoType = {
  title: string
  visibility: boolean
  visibilityName: string
  list: string[],
  buttonName: string
}

export const modalReducer = (state: ModalInfoType, { data }: ComponentReducerType<Partial<ModalInfoType>>) => ({
  ...state,
  ...data,
});
