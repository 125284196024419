import { gql } from '@apollo/client';

export const GET_SUMMARY_REPORT_FILTER_OPTIONS = gql`
  query getSummaryReportFilterOptions($input: SummaryReportInput) {
    getSummaryReportFilterOptions(input: $input) {
      categories {
        label
        value
      }
      products {
        id
        customizedProductId
        name
        productCategory
      }
      publishers {
        label
        value
      }
      checkDates {
        checkDate
        publisherCount
      }
    }
  }
`;
