import React from 'react';
import { Checkbox } from '../../../../../../../components';
import { Delete, Url, Edit } from './components';
import { CellStyled } from './styles';

export const columns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'checked',
    text: '',
    width: '50px',
    formatter: (val) => (
      <CellStyled>
        <Checkbox
          key={val}
          checked={val}
          onChange={() => ''}
          disabled={isReadOnly}
        />
      </CellStyled>
    ),
  },
  {
    dataField: 'url',
    text: 'Url',
    width: 'clamp(830px, calc(100vw - 440px), 1460px)',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val) => (
      <Url
        val={val}
      />
    ),
  },
  {
    dataField: 'edit',
    text: '',
    width: '50px',
    notClickable: true,
    formatter: (val, row, _, __, ___, setOpenModal, setModalInfo) => (
      <Edit
        val={val}
        row={row}
        setOpenModal={setOpenModal}
        setModalInfo={setModalInfo}
        isReadOnly={isReadOnly}
      />
    ),
  },
  {
    dataField: 'delete',
    text: '',
    width: '50px',
    notClickable: true,
    formatter: (val, row, _, __, ___, setOpenModal, setModalInfo) => (
      <Delete
        val={val}
        row={row}
        setOpenModal={setOpenModal}
        setModalInfo={setModalInfo}
        isReadOnly={isReadOnly}
      />
    ),
  },
];
