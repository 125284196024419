import { ERROR_TYPES } from '../../../../../utils';

export const validationfields = {
  companyName: ERROR_TYPES.NOT_EMPTY,
  website: ERROR_TYPES.NOT_EMPTY,
  address: ERROR_TYPES.NOT_EMPTY,
  city: /^[a-zA-Z]+(?:[\s][a-zA-Z]+)*$/,
  region: ERROR_TYPES.REGION,
  country: ERROR_TYPES.SELECTION_REQUIRED,
  postalCode: ERROR_TYPES.POSTAL_CODE,
  phone: ERROR_TYPES.PHONE,
  phoneTwo: ERROR_TYPES.PHONE_OPTIONAL,
  companyEmail: ERROR_TYPES.EMAIL_OPTIONAL,
  billingContact: ERROR_TYPES.EMAIL_OPTIONAL,
};
