import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../../../../../../components';
import { FINTEL_CHECK_ACTIONS } from '../../../../../reducers/useFintelCheckReducer';
import { MERCHANT_PREFIX, path } from '../../../../../../../../utils';
import * as Styled from './styles';

type IconProps = {
  row: any
  setModalInfo: (values: Record<string, any>) => void
}

export const IconCell = ({ row, setModalInfo }: IconProps) => {
  const navigate = useNavigate();

  return (
    <Button
      theme="text-only"
      onClick={() => {
        setModalInfo({
          type: FINTEL_CHECK_ACTIONS.SET_DATA_FROM_SUMMARY_REPORT,
          data: {
            publisherId: row.publisherId,
            publisherName: row.publisherName,
            checkDate: '',
            merchantId: '',
            merchantName: '',
          },
        });
        navigate(`${MERCHANT_PREFIX}${path.fintelCheckDetails.href}`, { state: { from: window.location.pathname } });
      }}
    >
      <Styled.IconStyled icon={faChevronRight} />
    </Button>
  );
};
