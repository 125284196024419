import React from 'react';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from '../../../../../../../components';
import { TitleStyled } from '../../../../../ManualPublisherBonuses/components/styles';
import * as Styled from './styles';
import { imageList } from '../../../../../../../utils';
import { PAYPAL_MODAL_TEXT } from './enums';
import { NO_MONEY } from '../../../../enums';

type PaypalProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  amountCad: number
  isLoading: boolean
  amountUsd: number
}

export const PaypalModal = ({
  isOpen,
  onClose,
  onConfirm,
  amountCad = NO_MONEY,
  isLoading,
  amountUsd = NO_MONEY,
}: PaypalProps) => (
  <Modal isOpen={isOpen}>
    <Styled.HeaderWrapper>
      <TitleStyled>
        {PAYPAL_MODAL_TEXT.CONFIRM}
      </TitleStyled>
      <Button
        onClick={onClose}
        theme="secondary"
      >
        {PAYPAL_MODAL_TEXT.CANCEL}
      </Button>
    </Styled.HeaderWrapper>
    <Styled.SingelLine>
      <div>{PAYPAL_MODAL_TEXT.YOU_ARE_ABOUT_TO_PAY}</div>
    </Styled.SingelLine>
    {amountCad !== NO_MONEY && (
      <Styled.Total>
        <div>
          {PAYPAL_MODAL_TEXT.TOTAL_CAD}
        </div>
        <div>
          {amountCad}
        </div>
      </Styled.Total>
    )}
    {amountUsd !== NO_MONEY && (
      <Styled.Total>
        <div>
          {PAYPAL_MODAL_TEXT.TOTAL_USD}
        </div>
        <div>
          {amountUsd}
        </div>
      </Styled.Total>
    )}
    <Styled.BodyWrapperStyled>
      <Styled.IconStyled icon={faCircleInfo} />
      <Styled.WordWrapperStyled>
        <Styled.BodyParagraphStyled>
          {PAYPAL_MODAL_TEXT.ENSURE_FUNDS}
        </Styled.BodyParagraphStyled>
      </Styled.WordWrapperStyled>
    </Styled.BodyWrapperStyled>
    <Styled.MiddleWrapper>
      <Button
        loading={isLoading}
        theme="text-only"
        onClick={onConfirm}
      >
        <img
          src={imageList.paypalLogoImage.src}
          alt={imageList.paypalLogoImage.alt}
        />
      </Button>
      <Styled.PaypalDesc>
        {PAYPAL_MODAL_TEXT.PAYPAL_DESC}
      </Styled.PaypalDesc>
    </Styled.MiddleWrapper>
  </Modal>
);
