import { gql } from '@apollo/client';

export const GET_MERCHANT_CURRENCY = gql`
query company($id: ID!) {
  company(id: $id) {
    id
    program {
      transactionSettings {
        currency
      }
    }
  }
}
`;
