import { gql } from '@apollo/client';

export const GET_GROUPS = gql`
  query groups($programId: String) {
    groups(programId: $programId) {
      count
      groups {
        id
        name
      }
    }
  }
`;

export type GetGroupsInput = {
  programId: string
}

export type GetGroupsOutput = {
  groups: {
    count: number
    groups: {
      id: string
      name: string
    }[]
  }
}
