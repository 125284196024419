import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../styles/theme';
import { hexToRGB, toRem } from '../../utils';

type AccordionManagementProps = {
  readonly show: boolean;
}

type AccordionProps = {
  show: boolean;
  isCollapsed: boolean;
};

type AccordionSubSectionsProps = {
  show: boolean;
};

type MenuLinkProps = {
  isCollapsed?: boolean;
};

export const LogoPlaceStyled = styled.figure<MenuLinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${toRem(26)} auto;
  height: 70px;
  width: ${({ isCollapsed }) => (isCollapsed ? '30px' : '100px')};
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MenuLinkStyled = styled(NavLink).withConfig({
  shouldForwardProp: (prop) => !['isCollapsed'].includes(prop),
})<MenuLinkProps>`
  display: grid;
  grid-template-columns: 30px 1fr 15px;
  align-content: center;
  color: ${colors.color7};
  text-decoration: none;
  padding: 1rem;
  ${fonts.gotham.H12M}

  &.active {
    color: ${colors.color4};
  }

  p {
    margin-top: 2px;

    &:nth-child(2) {
      display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
    }
  }

  svg {
    margin-right: ${({ isCollapsed }) => (isCollapsed ? '0' : '0.7rem')};
    font-size: ${({ isCollapsed }) => (isCollapsed ? '1.3rem' : '1rem')};
  }
`;

export const IconLinkStyled = styled(NavLink).withConfig({
  shouldForwardProp: (prop) => !['isCollapsed'].includes(prop),
})<MenuLinkProps>`
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
  color: ${colors.color7};

  :active {
    color: ${colors.color7};
  }

  :hover {
    color: ${colors.color7};
  }
`;

export const MenuRowStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  border-left: 3px solid transparent;
  &:hover {
    border-left: 3px solid ${colors.color2};
    background: rgba(${hexToRGB(colors.color4)}, 0.1);
    color: ${colors.color4};
  }
`;

export const LinkIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  &:hover {
    cursor: pointer;
  }
`;

export const AccordionContentStyled = styled.div`
  overflow: hidden;
  max-height: 0;
`;

export const AccordionHeaderStyled = styled.button`
  width: 100%;
  text-align: left;
  display: grid;
  padding: 1rem;
  align-content: center;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  ${fonts.gotham.H12M}

  p {
    margin-top: 2px;
  }
`;

export const AccordionHeaderStyledActAs = styled.button`
  width: 100%;
  text-align: left;
  display: flex;
  padding: 1rem 1.2rem 1rem 2rem;
  align-items: center;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  ${fonts.gotham.H12M}
  background: transparent;
  color: ${colors.color4};
  justify-content: space-between;

  P {
    width: 80%;
  }
`;

export const AccordionStyled = styled.div<AccordionProps>`
  overflow: hidden;

  ${AccordionContentStyled} {
    transition: max-height 0.1s ease-in-out linear, background 0.1s ease-in-out linear;
    border-left: 3px solid ${({ show }) => (show ? colors.color2 : 'transparent')};
    background: ${({ show }) => (show ? `rgba(${hexToRGB(colors.color4)}, 0.1)` : 'transparent')};

    ${({ show, isCollapsed }) => (show && !isCollapsed && 'max-height: 1000px;')};
  }

  ${AccordionHeaderStyled} {
    transition: background 0.1s linear;
    border-left: 3px solid ${({ show }) => (show ? colors.color2 : 'transparent')};
    background: ${({ show }) => (show ? `rgba(${hexToRGB(colors.color4)}, 0.1)` : 'transparent')};
    color: ${({ show }) => (show ? colors.color4 : colors.color7)};
    grid-template-columns: ${({ isCollapsed }) => (isCollapsed ? '1fr' : '30px 1fr 10px')};
    white-space: nowrap;

    &:hover {
      border-left: 3px solid ${colors.color2};
      background: rgba(${hexToRGB(colors.color4)}, 0.1);
      color: ${colors.color4};
    }

    p:nth-child(2) {
      display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
    }

    svg {
      margin-right: ${({ isCollapsed }) => (isCollapsed ? '0' : '0.7rem')};
      font-size: ${({ isCollapsed }) => (isCollapsed ? '1.3rem' : '1rem')};

      &:nth-child(3) {
        font-size: 0.8rem;
        margin-top: 3px;
        transition: all 0.2s;
        transform: rotate(${({ show }) => (show ? '90deg' : '0')});
        display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
      }
    }
  }
`;

export const AccordionSectionSeparatorStyled = styled.div`
  height: 1px;
  background: ${colors.color7};
  width: 90%;
  margin: 0 auto;
  margin-top: 1rem;
`;

export const AccordionNavLinkStyled = styled(NavLink)`
  color: ${colors.color7};
  border-left: 3px solid transparent;
  padding: 0.5rem 1rem 0.5rem 1.8rem;
  display: flex;
  text-decoration: none;
  font-size: 0.9rem;
  ${fonts.gotham.H12M}
  line-height: 1.5;
  white-space: break-spaces;

  &:hover {
    color: ${colors.color4};
  }

  &.active {
    color: ${colors.color4};
  }
`;

export const SubAccordionContentStyled = styled.div`
  overflow: hidden;

  ${AccordionNavLinkStyled} {
    padding: 0.5rem 1rem 0.5rem 1.8rem;
  }
`;

export const AccordionSubSectionHeaderStyled = styled.button`
  display: grid;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1.8rem;
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  border-left: 3px solid transparent;
  cursor: pointer;
  color: ${colors.color7};
  font-size: 0.9rem;
  grid-template-columns: 1fr 10px;
  ${fonts.gotham.H12M}

  p {
    margin-top: 2px;
  }

  &:hover {
    color: ${colors.color4};
  }
`;

export const AccordionSubSectionsStyled = styled.div<AccordionSubSectionsProps>`
  ${SubAccordionContentStyled} {
    max-height: ${({ show }) => (show ? '100%' : '0')};
  }
  
  ${AccordionSubSectionHeaderStyled} {
    svg:last-child {
      font-size: 0.8rem;
      margin-top: 3px;
      transition: transform 0.3s;
      transform: rotate(${({ show }) => (show ? '90deg' : '0')});
    }
  }
`;

export const FAQLinkStyled = styled.a<MenuLinkProps>`
  display: flex;
  background: ${colors.color2};
  color: ${colors.color4};
  text-decoration: none;
  border-radius: 2rem;
  cursor: pointer;
  ${fonts.gotham.H16M}
  justify-content: center;
  align-items: center;
  width: ${({ isCollapsed }) => (isCollapsed ? '40px' : '110px')};
  height: ${({ isCollapsed }) => (isCollapsed ? '40px' : '50px')};
  position: fixed;
  bottom: 2rem;

  &:hover {
    background: ${colors.color8};
    text-decoration: underline;
  }

  svg {
    margin-right: ${({ isCollapsed }) => (isCollapsed ? '0' : '0.5rem')};
  }
`;

export const IconStyled = styled(FontAwesomeIcon)`
  height: 24px;
`;

export const WrapperStyled = styled.div<MenuLinkProps>`
  width: ${({ isCollapsed }) => (isCollapsed ? '60px' : '220px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: ${colors.color1};
  position: relative;
  z-index: 30;
`;

export const CollapseButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: ${colors.color1};
  border: none;
  font-size: 1rem;
  color: ${colors.color4};
  right: -1rem;
  top: 5.5rem;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  cursor: pointer;
  z-index: 3;
`;

export const AccordionManagementStyled = styled.div<AccordionManagementProps>`
  height: ${({ show }) => (show ? '100%' : '0')};
  overflow: hidden;

  &:last-of-type {
    padding-bottom:  ${({ show }) => (show ? '1rem' : '0')};
  }
`;
