import { gql } from '@apollo/client';

export const LIST_COUNTRIES = gql`
  query GetCountries {
    getCountries {
      countries {
        name
        subdivisions {
          id
          name
        }
      }
    }
  }
`;
