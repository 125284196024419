import styled from 'styled-components';
import { Button, InputText, Select } from '../../../../../components';
import { colors } from '../../../../../styles/theme';

export const WrapperStyled = styled.div`
  width: 600px;
  padding: 1rem;
`;

export const CloseButtonStyled = styled(Button)`
  border: transparent;
  font-size: 1.2rem;

  &:hover {
    border: transparent;
    background: transparent;
    color: ${colors.color2};
  }
`;

export const TopStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const FooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const InputTextStyled = styled(InputText)`
  margin-bottom: 2rem;
`;

export const SelectStyled = styled(Select)`
  margin-bottom: 2rem;
`;
