import { ChangeEvent, useState } from 'react';
import { changePassword } from '../../../services';
import { REGEX_VALIDATORS, TOAST_ERR_MESSAGES_NO_PAGE } from '../../../utils';

export const useForgotPassword = () => {
  const [emailValue, setEmailValue] = useState<string>('');
  const [emailInputError, setEmailInputError] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const setEmailValueHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  const setModalOpenHandler = (newState: boolean) => {
    setModalOpen(newState);
  };

  const onSubmitHandler = async () => {
    const validEmailAddress = emailValue.match(REGEX_VALIDATORS.EMAIL.REGEX);
    if (!validEmailAddress) {
      setEmailInputError(REGEX_VALIDATORS.EMAIL.MESSAGE);
      return;
    }
    setEmailInputError('');
    try {
      setErrorMessage('');
      await changePassword(emailValue);
    } catch (err) {
      setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE('Unable to send email'));
      return;
    }
    setModalOpen(true);
  };

  return {
    hookOnSubmitHandler: onSubmitHandler,
    hookEmailValue: emailValue,
    hookEmailInputError: emailInputError,
    hookSetEmailValue: setEmailValueHandler,
    hookErrorMessage: errorMessage,
    hookModalOpen: modalOpen,
    hookSetModalOpen: setModalOpenHandler,
  };
};
