import { gql } from '@apollo/client';

export const GET_MERCHANT_INFO = gql`
  query publisherDashboardMerchants($input: PublisherDashboardInput!) {
    publisherDashboardMerchants(input: $input) {
      merchantList {
        id
        companyName
      }
      newPrograms {
        id
        productCategories
        merchant {
          id
          country
          companyImgUrl
        }
      }
    }
  }
`;
