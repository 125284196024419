import React from 'react';

import { AppWrapper, PageTitle } from '../../components';
import CurrencyRateTabs from '../../components/CurrencyTabs';
import { path } from '../../utils';
import { DefaultPropsType } from '../../types';

type CurrencyRatesProps = DefaultPropsType;

const CurrencyRates = ({ permissionsCodeList = [] }: CurrencyRatesProps) => (
  <AppWrapper permissionsCodeList={permissionsCodeList}>
    <PageTitle>{path.currencyRates.name}</PageTitle>
    <CurrencyRateTabs />
  </AppWrapper>
);

export default CurrencyRates;
