import styled from 'styled-components';
import { Button, FileUpload } from '../../../../../../components';
import { fonts, colors } from '../../../../../../styles/theme';

export const WrapperStyled = styled.div`
  margin-top: 2rem;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
  height: 40px;

  svg {
    margin-right: 0.5rem;
  }
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
`;

export const StepListStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepStyled = styled.div`
  margin-bottom: 3rem;
  ${fonts.gotham.H16}
  color: ${colors.color1};

  h2 {
    margin-bottom: 1rem;
    ${fonts.gotham.H18}
  }

  p {
    ${fonts.lato.H16}
    line-height: 1.5;
    width: 70%;
  }
`;

export const StepButtonAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FooterStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2rem;
`;

export const CheckboxAreaStyled = styled.div`
  display: flex;
  ${fonts.lato.H16}
`;

export const FileUploadStyled = styled(FileUpload)`
  width: 70%;
`;
