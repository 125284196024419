import React, { useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { Auth0UserProfile } from 'auth0-js';
import { LoadingPage } from '../../components';
import environment from '../../config/environment';
import {
  useCreateSession, useLogOut,
} from '../../hooks';
import { CREATE_ENCRYPTION } from './graphql/mutations';
import { USER_TYPES_ID, path } from '../../utils';
import { Permission, PermissionType } from '../../entities';
import { CHECK_EMAIL, DELETE_USER_FROM_AUTH0 } from '../Publishers/SignUp/graphql';
import * as Styled from './styles';
import * as ERROR_MESSAGES from '../InvalidUser/enums';
import { ErrorType } from '../InvalidUser/enums';
import { UPDATE_USER } from '../../components/ManageUser/graphql/mutations/updateUser';
import { GET_USER_BY_EMAIL } from './graphql/getUserByEmail';

type UserInfoType = {
  userTypesId: number
  verified: boolean
  merchantsId: number
  publishersId: number
  accStatus: string
  userStatus: string
  userTypeIdEncrypted: string
  dayCookieEncrypted: string
}

const Callback = () => {
  const auth0Hook = useAuth0();
  const history = useNavigate();
  const { hookLogout } = useLogOut();
  const [trigger] = useMutation(CREATE_ENCRYPTION, {});
  const [deleteUserFromAuth0] = useMutation(DELETE_USER_FROM_AUTH0, {});
  const [updateUser] = useMutation(UPDATE_USER);
  const [getUserByEmail] = useLazyQuery(GET_USER_BY_EMAIL, {});
  const [checkEmailExists] = useLazyQuery(CHECK_EMAIL, {});
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const auth0Error = urlParams.get('error');
  const auth0ErrorDescrip = urlParams.get('error_description');
  /**
   * If any error happens in this page show generic error message and logout.
   */
  const handleOnLoginError = async (error: ErrorType) => {
    await hookLogout(false);
    history(path.invalidUser.href, {
      state: {
        error,
      },
    });
  };

  /**
   * Creates a cookie with the user information.
   * If user signs in with SSO and there's not account in DB yet, redirects to Signup.
   */
  const setUserCookieHandler = async (crypted?: string) => {
    if (!auth0Hook.user) {
      handleOnLoginError(ERROR_MESSAGES.UNEXPECTED_ERROR);
      return;
    }
    const accessToken = await auth0Hook.getAccessTokenSilently({ detailedResponse: true });
    const crypt = crypted || await useCreateSession(accessToken.access_token, accessToken.id_token);

    const { data, errors } = await trigger({
      variables: {
        input: {
          crypt,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (errors && errors.length > 0) {
      if (errors[0].message.toLowerCase() === 'user not found') {
        handleOnLoginError(ERROR_MESSAGES.INVALID_SSO_ACCOUNT_CREATION);
        return;
      }
      handleOnLoginError(ERROR_MESSAGES.UNEXPECTED_ERROR);
      return;
    }

    if (!data.createEncryption) {
      handleOnLoginError(ERROR_MESSAGES.UNEXPECTED_ERROR);
      return;
    }

    const now = new Date();
    const expiration = new Date(new Date(now.getTime() + 15 * 60 * 1000));

    /* Uses the hash to create the cookie */
    if (data.createEncryption.hash) {
      document.cookie = `${environment.app.cookieName}=${data.createEncryption.hash}; expires=${expiration.toUTCString()}; path=/; SameSite=none;secure`;

      localStorage.setItem(environment.app.permissionStorageName, data.createEncryption.permissionsHash);

      const userInfo = jwtDecode<UserInfoType>(data.createEncryption.hash);

      /* Legacy cookies to be used with Knowledge Base and Support App */
      document.cookie = `FA_UT=${userInfo.userTypeIdEncrypted}; expires=${expiration.toUTCString()}; domain=${environment.app.domain}`;
      document.cookie = `24-hour-cookie=${userInfo.dayCookieEncrypted}; expires=${new Date(new Date(now.getTime() + 1440 * 60000))}; domain=${environment.app.domain}`;

      /* Sets the permissions based on the JWT token */
      const permissionsDecoded = jwtDecode<{ permissions: PermissionType[] }>(data.createEncryption.permissionsHash);
      Permission.setPermissions(permissionsDecoded.permissions);

      /* Redirects to the correct page based on user type and status */
      if (!userInfo.verified) {
        history('/unverified');
      } else if (userInfo.userStatus !== 'Active' || ([2, 3].includes(userInfo.userTypesId) && ['Closed', 'Pending'].includes(userInfo.accStatus))) {
        history('/invalid-user');
      } else if (userInfo.userTypesId === USER_TYPES_ID.ADMIN) {
        history('/');
      } else if (userInfo.userTypesId === USER_TYPES_ID.MERCHANT) {
        history('/merchant/');
      } else if (userInfo.userTypesId === USER_TYPES_ID.PUBLISHER) {
        history('/publisher/');
      }
    }
  };

  /**
   * Removes user from Auth0 if the error is 'access_denied' due to Social Account sign up.
   */
  const handlerRemoveUserFromAuth0 = async (errorMessage: string, isLoggedIn = false) => {
    if (!errorMessage) return;
    let userId = '';
    let accessToken = '';
    try {
      /* This condition will be true if the user selects "Back to Fintel App" from Account Link Page */
      if (isLoggedIn) {
        // eslint-disable-next-line prefer-destructuring
        accessToken = errorMessage.split('error_description=')[1];
        const userInfo: Auth0UserProfile = jwtDecode(accessToken);
        userId = userInfo.sub;
      } else userId = errorMessage.substring(errorMessage.indexOf('(') + 1, errorMessage.indexOf(')'));
      /* The following code will happen when the user tries to Signup using SSO - their account is deleted after blocking the login */
      const input = {
        userId,
        token: isLoggedIn ? accessToken : undefined,
      };
      const { data, errors } = await deleteUserFromAuth0({
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });
      if (errors && errors.length > 0) {
        handleOnLoginError(ERROR_MESSAGES.UNEXPECTED_ERROR);
        return;
      }
      if (data.deleteUserFromAuth0) {
        handleOnLoginError(isLoggedIn ? ERROR_MESSAGES.INCOMPLETE_SSO_ACCOUNT_LINK : ERROR_MESSAGES.INVALID_SSO_ACCOUNT_CREATION);
      }
    } catch (error) {
      handleOnLoginError(ERROR_MESSAGES.UNEXPECTED_ERROR);
    }
  };

  const handleAuth0Error = async () => {
    /* This will force auth0 to generate a new access token for the user
      * It's a workaround for when the user is linking their accounts due to the custom domain
    */
    try {
      /* After getting the token, the useEffect below will trigger setUserCookieHandler
      * and proceed with the app as usual
       */
      const token = await auth0Hook.getAccessTokenSilently();
      if (!token) {
        handleOnLoginError(ERROR_MESSAGES.UNEXPECTED_ERROR);
      }
    } catch (error) {
      handleOnLoginError(ERROR_MESSAGES.UNEXPECTED_ERROR);
    }
  };

  /**
   * Links user accounts by adding the new auth0Id to the user record in DB.
   * If successful, proceeds with the app.
   */
  const handleLinkUserAccounts = async () => {
    const accessToken = await auth0Hook.getAccessTokenSilently({ detailedResponse: true });
    const crypted = await useCreateSession(accessToken.access_token, accessToken.id_token);
    const { data: userData, error: userError } = await getUserByEmail({
      variables: {
        email: auth0Hook.user?.email,
      },
      fetchPolicy: 'no-cache',
    });
    if (userError) {
      handleOnLoginError(ERROR_MESSAGES.UNEXPECTED_ERROR);
      return;
    }

    const input = {
      ...userData.userByEmail,
      auth0Ids: [userData.userByEmail.auth0Id, auth0Hook.user?.sub],
    };
    const { data, errors } = await updateUser({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
    if (errors && errors.length > 0) {
      handleOnLoginError(ERROR_MESSAGES.INCOMPLETE_SSO_ACCOUNT_LINK);
      return;
    }
    if (data) {
      await setUserCookieHandler(crypted);
    }
  };

  /**
   * Validates login by checking if user exists in DB and if user is logging in with SSO.
   */
  const handleValidateUserLogin = async () => {
    const auth0Id = auth0Hook.user?.sub;
    if (!auth0Id) {
      handleOnLoginError(ERROR_MESSAGES.INCOMPLETE_SSO_ACCOUNT_LINK);
      return;
    }
    /* If user logged in using FC account, just proceed with the app. */
    if (auth0Id.includes('auth0|')) {
      setUserCookieHandler();
    } else {
      /* If user has logged in using SSO */
      const { data, error } = await checkEmailExists({
        variables: {
          email: auth0Hook.user?.email,
        },
        fetchPolicy: 'no-cache',
      });

      if (error) {
        /* Error checking if user exist */
        handleOnLoginError(ERROR_MESSAGES.INCOMPLETE_SSO_ACCOUNT_LINK);
        return;
      }
      if (!data?.checkEmailExists) {
        /* If user DOESNT exists, cancel flow and remove user from auth0 */
        handlerRemoveUserFromAuth0(`error_description=(${auth0Id})`, false);
      } else if (data.checkEmailExists) {
        /* If user exists, link accounts by adding to auth0Ids array. */
        handleLinkUserAccounts();
      }
    }
  };

  useEffect(() => {
    if (auth0Hook.isAuthenticated) {
      handleValidateUserLogin();
    }
    if (!auth0Error && auth0Hook.error) {
      handleAuth0Error();
    }
  }, [auth0Hook]);

  useEffect(() => {
    if (!auth0Error) return;
    /* If user tries to sign up using SSO - block login and remove from Auth0 */
    if (auth0Error && auth0Error === 'access_denied' && auth0ErrorDescrip) {
      handlerRemoveUserFromAuth0(auth0ErrorDescrip);
    } else if (auth0Error && auth0Error.includes('not_linked')) {
      /* If user comes from a refused account link - delete user in Auth0 */
      handlerRemoveUserFromAuth0(auth0Error, true);
    } else {
      handleOnLoginError(ERROR_MESSAGES.UNEXPECTED_ERROR);
    }
  }, [auth0Error, auth0ErrorDescrip]);

  return (
    <Styled.WrapperStyled>
      <LoadingPage />
    </Styled.WrapperStyled>
  );
};

export default Callback;
