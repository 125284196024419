import { gql } from '@apollo/client';

export const LIST_COUNTRIES = gql`
  query listCountries {
    getCountries {
      countries {
        name
        subdivisions {
          id
          name
        }
      }
    }
  }
`;

export type LCSubdivisionType = {
  id: string
  name: string
}

export type LCCountryType = {
  name: string
  subdivisions: LCSubdivisionType[]
}

export type ListCountriesOutput = {
  getCountries: {
    countries: LCCountryType[]
  }
}
