export const PAYMENTS_INFO = {
  BACK: 'Previous step',
  NEXT: 'Next',
  CURRENCY: 'desired payout currency',
  METHOD: 'payment method',
  MINIMUM_PAYOUT: 'Minimum payout CAD $20.00',
  PAYABLE: {
    LABEL: 'payable to',
    PLACEHOLDER: 'Personal/Company Name',
  },
  BANK_NAME: 'Bank Name',
  BANK_ACCOUNT_NUMBER: 'Bank Account Number',
  SWIFT_CODE: 'Swift Code / IBAN / Routing',
  TRANSIT: 'Transit',
  WIRE_TRANSFER_NAME: 'Wire Transfer Name',
  ACCOUNT_HOLDER: 'Account Holder',
  ACCOUNT_EMAIL_ADDRESS: 'Account Email Address',
  INFO_AS_COMPANY: 'Payment contact info is same as company',
  EMAIL: {
    LABEL: 'email address',
    PLACEHOLDER: 'Email Address',
  },
  PHONE: 'phone number',
  ADDRESS1: 'Address 1',
  ADDRESS2: 'Address 2',
  CITY: 'City',
  COUNTRY: 'country',
  STATE: 'state/province',
  ZIP: 'zip/postal code',
  INFO: {
    TITLE: 'Tax Number',
    DESCRIPTION: 'You can choose to enter your tax information at any time in Fintel Connect by navigating to your Account Settings. Please note, tax will only calculate once your number has been added and verified.',
  },
  GST: {
    LABEL: 'GST #',
    PLACEHOLDER: '123456789RT0001',
  },
  PST: {
    LABEL: 'PST/QST #',
    PLACEHOLDER: 'PST12345678',
  },
  HST: {
    LABEL: 'HST #',
    PLACEHOLDER: '123456789RT0001',
  },
  TAX: {
    LABEL: 'TAX #',
    PLACEHOLDER: '123 456 789',
  },

  VALIDATION: {
    NONE: '',
    ALBERTA: 'Alberta',
    NORTHWEST: 'Northwest Territories',
    NUNAVIT: 'Nunavut',
    YUKON: 'Yukon',
    BC: 'British Columbia',
    MANITOBA: 'Manitoba',
    QUEBEC: 'Quebec',
    SASKATCHEWAN: 'Saskatchewan',
    BRUNSWICK: 'New Brunswick',
    NEWFOUNDLAND_LABRADOR: 'Newfoundland and Labrador',
    NOVA_SCOTIA: 'Nova Scotia',
    ONTARIO: 'Ontario',
    PRINCE_EDWARD: 'Prince Edward Island',
  },
};
