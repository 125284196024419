import { ERROR_TYPES } from '../../../../../utils';

export const validationFields = {
  companyName: ERROR_TYPES.NOT_EMPTY,
  address: ERROR_TYPES.NOT_EMPTY,
  city: /^[a-zA-Z]+(?:[\s][a-zA-Z]+)*$/,
  region: ERROR_TYPES.REGION,
  country: ERROR_TYPES.SELECTION_REQUIRED,
  postalCode: ERROR_TYPES.POSTAL_CODE,
  phone: ERROR_TYPES.PHONE,
  phoneTwo: ERROR_TYPES.PHONE_OPTIONAL,
  publisherEmail: ERROR_TYPES.EMAIL_OPTIONAL,

};

export const paymentValidationFields = {
  paymentRegion: ERROR_TYPES.SELECTION_REQUIRED,
  paymentCountry: ERROR_TYPES.SELECTION_REQUIRED,
  paymentCity: ERROR_TYPES.NOT_EMPTY,
  paymentAddress: ERROR_TYPES.NOT_EMPTY,
  paymentPostal: ERROR_TYPES.POSTAL_CODE,
  paymentPrimaryPhone: ERROR_TYPES.PHONE,
  paymentSecondaryPhone: ERROR_TYPES.PHONE_OPTIONAL,
};

export const paypalValidationFields = {
  accountHolder: ERROR_TYPES.NOT_EMPTY,
  accountEmail: ERROR_TYPES.EMAIL,
  accountPhoneNumber: ERROR_TYPES.PHONE,
};

export const chequeValidationFields = {
  payableTo: ERROR_TYPES.NOT_EMPTY,
};

export const wireTransferValidationFields = {
  bankName: ERROR_TYPES.NOT_EMPTY,
  bankAccountNumber: ERROR_TYPES.BANK_ACCOUNT_NUMBER,
  swiftCode: ERROR_TYPES.NOT_EMPTY,
  wireTransferName: ERROR_TYPES.NOT_EMPTY,
};
