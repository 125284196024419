import React from 'react';
import * as Styled from './styles';
import { ConfirmationModal } from '../../../../../../components/Modal/ConfirmationModal';
import { Table, Pagination } from '../../../../../../components';
import { columns } from './columns';
import { GENERATE_PAYMENT } from '../../enums';

type EstimatePaymentsModal = {
    hooks: any
}

export const EstimateModal = ({
  hooks,
}: EstimatePaymentsModal) => (
  <ConfirmationModal
    isOpen={hooks.hookIsEstimateOpen}
    onClose={hooks.hookHandleEstimateCancel}
    onConfirm={hooks.hookHandleEstimateConfirm}
    onCloseText={GENERATE_PAYMENT.ESTIMATE_MODAL.CLOSE}
    onConfirmText={GENERATE_PAYMENT.ESTIMATE_MODAL.CONFIRM}
    title={GENERATE_PAYMENT.ESTIMATE_MODAL.TITLE}
    isLoading={hooks.hookEstimateModalConfirmLoading || hooks.hookEstimateModalTableLoading}
    onConfirmDisabled={hooks.hookEstimateModalConfirmLoading || hooks.hookEstimateModalTableLoading}
  >
    <Styled.StyledNotifyer isShow={hooks.hookHeaderCheckModal}>
      <div>
        {GENERATE_PAYMENT.ESTIMATE_MODAL.ALL}
        &nbsp;
        <b>
          {hooks.hookAccountsBelowMinBalanceCount}
        </b>
        &nbsp;
        {GENERATE_PAYMENT.ESTIMATE_MODAL.MERCHANTS_WITH}
        &nbsp;
        <b>
          {GENERATE_PAYMENT.ESTIMATE_MODAL.LOWER_ACCOUNT_BALANCE}
        </b>
        &nbsp;
        {GENERATE_PAYMENT.ESTIMATE_MODAL.NOTIFY}
      </div>
    </Styled.StyledNotifyer>
    <Table
      noTableOverflow
      columns={columns}
      headerCheckBox={hooks.hookHeaderCheckModal}
      handleHeaderCheckBox={hooks.hookHandleHeaderCheckModal}
      data={hooks.hookAccountBalances}
      handleCheckBox={hooks.hookHandleChangeCheckModal}
      isLoading={hooks.hookEstimateModalTableLoading}
      sortColumn={hooks.hookSortEstimate}
      onSort={hooks.hookHandleSortEstimate}
    />
    {hooks.hookEstimateModalTableLoading ? (
      <Styled.Padding />
    ) : (
      <Pagination
        currentPage={hooks.hookEstimatePage}
        total={hooks.hookEstimateTotalPages}
        onPageChange={hooks.hookHandleChangePageModal}
      />
    )}
  </ConfirmationModal>

);
