import { gql } from '@apollo/client';

export const LIST_PRODUCT_FEED_DATA = gql`
  query ListProductFeedData($input: ListProductFeedDataInput!) {
    listProductFeedData(input: $input) {
      data {
        category
        values {
          productName
          value
        }
      }
    }
  }
`;
