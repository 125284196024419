import React, { useState, useEffect } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';
import { PageTitle, Modal } from '../../../components';
import * as Styled from './styles';

const DeleteRuleModal = ({
  hook,
}: {
  hook: any
}) => (
  <Modal isOpen={hook.isDeleteModalOpen}>
    <Styled.WrapperStyled>
      <Styled.TopStyled>
        <PageTitle>
          Delete Postback Rule
        </PageTitle>

        <Styled.CloseButtonStyled
          theme="secondary"
          onClick={() => { hook.setIsDeleteModalOpen(false); }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Styled.CloseButtonStyled>
      </Styled.TopStyled>

      <Styled.ContentStyled>
        Are you sure you want to delete this postback rule?
      </Styled.ContentStyled>
      <Styled.FooterStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => { hook.setIsDeleteModalOpen(false); }}
        >
          Cancel
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          theme="primary"
          onClick={() => { hook.deletePostback(); }}
        >
          Delete
        </Styled.ButtonStyled>
      </Styled.FooterStyled>
    </Styled.WrapperStyled>
  </Modal>
);

export default DeleteRuleModal;
