import { gql } from '@apollo/client';

export const LIST_PUBLISHERS = gql`
  query ListPublisher($id: ID!) {
    adPublisherOptions(id: $id) {
      id
      companyName
    }
  }
`;
