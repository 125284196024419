import { gql } from '@apollo/client';

export const LIST_TRACKING_PROFILES_BY_PUBLISHER = gql`
  query ListPublisherTrackingProfiles($companyId: String) {
    trackings(companyId: $companyId) {
      count
      trackings {
        id
        profileName
        primaryWebsite
        websiteDescription
      }
    }
  }
`;
