import { gql } from '@apollo/client';
import { MembershipQueryType } from '../../types';

export const GET_MEMBERSHIPS = gql`
  query getMemberships($input: MembershipSearchInput!) {
    memberships(input: $input) {
      memberships {
        publisher {
          id
          companyName
        }
        merchant {
          id
          companyName
        }
      }
    }
  }
`;

export type GetMembershipsInputType = {
  input: MembershipQueryType
};

export type GetMembershipsOutputType = {
  memberships: {
    memberships: {
      publisher: {
        id: string
        companyName: string
      }
      merchant: {
        id: string
        companyName: string
      }
    }[]
  }
};
