import styled from 'styled-components';
import { colors, fonts } from '../../../../../styles/theme';

export const CellWrapperStyled = styled.div`
  display: flex;
  min-height: 40px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-flow: wrap row;
  ${fonts.lato.H16}
`;

export const SecondLineStyled = styled.div`
  ${fonts.lato.H14}
  color: ${colors.color16};
  opacity: 0.4;
  width: 100%;
`;
