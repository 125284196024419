import { gql } from '@apollo/client';

export const GET_MEMBERSHIP = gql`
  query listInvitationHistory($input: ListSearchPublisherInvitationInput!) {
    listInvitationHistory(input: $input) {
      count
      invitations {
        id
        status
        statusLastUpdatedBy
        createdAt
        publisher {
          companyName
          companyUrl
          id
        }
        publisherInvitation {
          subject
          message
        }
      }
    }
  }
`;
