import { gql } from '@apollo/client';

export const NEW_SITE_MON_CSV = gql`
  query newTrackingEventsCSV($input: NewTrackingEventsInput) {
    newTrackingEventsCSV(input: $input) {
      trackingEventReportItems {
        referralUrl
        merchantId
        merchantName
        publisherId
        publisherName
        trackingProfileId
        trackingProfileUrl
        pageType
        adId
        adStatus
        productId
        productName
        impressions
        uniqueImpressions
        clicks
        uniqueClicks
        customizedProductId
        newToSystem
        createdAt
        updatedAt
        CTR
        RPI
        totalTransactions
        totalCommissions
        approvedTransactions
      }
    }
  }
`;
