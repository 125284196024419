import styled from 'styled-components';
import { Button } from '../../../../../../components';
import { colors, fonts } from '../../../../../../styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 34rem;
  padding: 1rem;
`;

export const TitleStyled = styled.p`
  color: ${colors.color1};
  ${fonts.gotham.H26M}
`;

export const BodyStyled = styled.div`
  padding: 2rem 0 1rem 0;
`;

export const ButtonWrapper = styled.div`
  margin-right: 0;
  margin-left: auto;
`;

export const ButtonStyled = styled(Button)`
`;

export const EntryStyled = styled.div`
  margin: 0.5rem 0;
  ${fonts.lato.H16B}
`;

export const FooterStyled = styled.div`
  margin: 0 0 1rem 0;
  ${fonts.lato.H16}
  color: ${colors.color1};
`;
