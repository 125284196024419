import { useEffect, useState } from 'react';

export const useProductCategoryModal = (currentList: string[]) => {
  const [updatedProductCategory, setUpdatedProductCategory] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const resetProductCategoryHandler = (list: string[]) => {
    setUpdatedProductCategory(list);
  };

  const updateProductCategoryListHandler = (checked: boolean, value: SelectOption) => {
    if (!checked) {
      const productList = updatedProductCategory.filter((prod) => prod !== value.label);
      setUpdatedProductCategory(productList);
    } else {
      setUpdatedProductCategory([...updatedProductCategory, value.label]);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setUpdatedProductCategory(currentList);
    setIsLoading(false);
  }, [currentList]);

  return {
    hookUpdatedProductCategory: updatedProductCategory,
    hookUpdateProductCategoryList: updateProductCategoryListHandler,

    hookResetProductCategory: resetProductCategoryHandler,

    hookIsloading: isLoading,
  };
};
