import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../../../hooks';
import {
  COUNTRIES, ERROR_TYPES, path, URL_STATUSES, ERROR_MESSAGES, QST_STATES, TOAST_ERR_MESSAGES,
} from '../../../../utils';
import { CREATE_MERCHANT } from '../graphql';
import { useMerchant } from './useMerchant';
import { useMerchantAddress } from '../../../../hooks/useMerchantAddress';
import { useCountryRegions } from '../../../../hooks/useCountryRegions';
import { useTaxation } from '../../../../hooks/useTaxation';
import { useUser } from './useUser';
import { useValidation } from '../../../../utils/validation';
import { CHECK_EMAIL } from '../../../Publishers/SignUp/graphql';

export const useNewMerchant = () => {
  const { hookShowToast } = useToast();
  const navigate = useNavigate();
  const vali = useValidation();
  const {
    hookCompanyName,
    hookCompanyEmail,
    hookCompanyWebsite,

    hookSetCompanyName,
    hookSetCompanyEmail,
    hookSetWebsite,
  } = useMerchant();

  const {
    hookAddress,
    hookAddressTwo,
    hookPostalCode,
    hookCity,
    hookRegion,
    hookCountry,

    hookSetAddress,
    hookSetAddressTwo,
    hookSetPostalCode,
    hookSetCity,
    hookSetRegion,
    hookSetCountry,

    hookIsDisabled,
    hookGstDisabled,
    hookHstDisabled,
    hookPstDisabled,
    hookTaxationNumberDisabled,

  } = useMerchantAddress();

  const {
    hookCountriesList,
    hookOriginalCountryList,
    hookCountryRegionsLoading,
  } = useCountryRegions();

  const {
    hookTaxation,
    hookGst,
    hookPst,
    hookHst,

    hookSetTaxation,
    hookSetGst,
    hookSetPst,
    hookSetHst,
  } = useTaxation();

  const [phoneOne, setPhoneOne] = useState('');
  const [phoneTwo, setPhoneTwo] = useState('');

  const [billingInfo, setBillingInfo] = useState('');
  const [isCanada, setIsCanada] = useState(false);

  const {
    hookUserFirstName,
    hookUserLastName,
    hookUserEmail,
    hookUserPosition,
    hookUserSuscription,

    hookSetUserFirstName,
    hookSetUserLastName,
    hookSetUserEmail,
    hookSetUserPosition,
    hookSetUserSuscription,
  } = useUser();

  const [createMerchantErrors, setCreateMerchantErrors] = useState<{ [key: string]: string }>({});
  const [billingInfoErrors, setBillingInfoErrors] = useState<{ [key: string]: string }>({});
  const [urlStatus, setUrlStatus] = useState('');
  const [urlError, setUrlError] = useState('');
  const [secondRender, setSecondRender] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [createMerchant, { loading: createMerchantLoading }] = useMutation(CREATE_MERCHANT);
  const [checkEmail] = useLazyQuery(CHECK_EMAIL);

  const backButtonHandler = () => navigate(`${path.merchantManagement.href}`);

  const setPhoneOneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneOne(e === undefined || e.toString() === '' ? '' : e.toString());
  };
  const setPhoneTwoHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneTwo(e === undefined || e.toString() === '' ? '' : e.toString());
  };

  const setBillingInfoHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingInfo(e.target.value);
  };

  const setTextRegion = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedRegion = { label: e.target.value, value: e.target.value };
    hookSetRegion(selectedRegion);
  };

  const values: { [key: string]: string } = {
    firstName: hookUserFirstName,
    lastName: hookUserLastName,
    userEmail: hookUserEmail,
    companyName: hookCompanyName,
    website: hookCompanyWebsite,
    address: hookAddress,
    city: hookCity,
    region: hookRegion.label,
    country: hookCountry.label,
    postalCode: hookPostalCode,
    phone: phoneOne,
    phoneTwo: phoneTwo || '',
    companyEmail: hookCompanyEmail,
    billingContact: billingInfo,
  };

  const fields = {
    firstName: ERROR_TYPES.NOT_EMPTY,
    lastName: ERROR_TYPES.NOT_EMPTY,
    userEmail: ERROR_TYPES.EMAIL,
    companyName: ERROR_TYPES.NOT_EMPTY,
    website: ERROR_TYPES.NOT_EMPTY,
    address: ERROR_TYPES.NOT_EMPTY,
    city: /^[a-zA-Z]+(?:[\s][a-zA-Z]+)*$/,
    region: ERROR_TYPES.REGION,
    country: ERROR_TYPES.SELECTION_REQUIRED,
    postalCode: ERROR_TYPES.POSTAL_CODE,
    phone: ERROR_TYPES.PHONE,
    phoneTwo: ERROR_TYPES.PHONE_OPTIONAL,
    companyEmail: ERROR_TYPES.EMAIL_OPTIONAL,
    billingContact: ERROR_TYPES.EMAIL_OPTIONAL,
  };

  const handleValidation = () => {
    let noWebsiteErrors = true;
    if (urlStatus !== URL_STATUSES.ACTIVE_WEBSITE.STATUS && urlStatus !== URL_STATUSES.INACTIVE_WEBSITE.STATUS && urlStatus !== URL_STATUSES.UNSAFE_WEBSITE.STATUS) {
      noWebsiteErrors = false;
    }
    const pass = vali.validateAll(values, fields, setCreateMerchantErrors, secondRender);
    return pass && noWebsiteErrors;
  };

  const validateWebsite = () => {
    if (hookCompanyWebsite !== '') {
      vali.validateUrlStatus(hookCompanyWebsite, setUrlStatus);
      vali.renderUrlCheck(urlStatus, setUrlError);
    }
  };

  const validateBillingInfo = () => {
    const errors: { [key: string]: string } = {};
    let noErrors = true;

    if (!hookTaxationNumberDisabled && hookTaxation !== '' && (hookCountry.label === COUNTRIES.US || hookCountry.label === COUNTRIES.AU) && !hookTaxation.match(/^\d{3}\s?\d{3}\s?\d{3}$/)) {
      errors.taxation = ERROR_MESSAGES.TAXATION;
      noErrors = false;
    } else {
      errors.taxation = '';
    }

    if (!hookGstDisabled && hookGst !== '' && (hookGst.length !== 15 || !hookGst.includes('RT'))) {
      errors.gstTaxation = ERROR_MESSAGES.GST_HST;
      noErrors = false;
    } else {
      errors.gstTaxation = '';
    }

    if (!hookPstDisabled && hookPst !== '' && hookCountry.label === COUNTRIES.CA) {
      if (hookRegion.label === QST_STATES[0]) {
        if (hookPst.length !== 16 || !hookPst.includes('TQ')) {
          errors.pstTaxation = ERROR_MESSAGES.QST;
          noErrors = false;
        }
      } else if (hookPst.length !== 11 || !hookPst.includes('PST')) {
        errors.pstTaxation = ERROR_MESSAGES.PST;
        noErrors = false;
      } else {
        errors.pstTaxation = '';
      }
    }

    if (!hookHstDisabled && (hookHst !== '' && (hookHst.length !== 15 || !hookHst.includes('RT')))) {
      errors.hstTaxation = ERROR_MESSAGES.GST_HST;
      noErrors = false;
    } else {
      errors.hstTaxation = '';
    }

    if (secondRender) setBillingInfoErrors(errors);
    return noErrors;
  };

  const createMerchantHandler = async () => {
    setSecondRender(true);
    const noErrors = validateBillingInfo() && handleValidation();
    if (!noErrors) return;

    const alreadyExists = await checkEmail({
      variables: {
        email: hookCompanyEmail === '' ? hookUserEmail : hookCompanyEmail,
        checkInAuth0: true,
      },
    });
    if (alreadyExists.data?.checkEmailExists) {
      setCreateMerchantErrors({ ...createMerchantErrors, companyEmail: hookCompanyEmail ? 'Email already exists.' : createMerchantErrors.companyEmail, userEmail: hookUserEmail ? 'Email already exists.' : createMerchantErrors.userEmail });
      return;
    }

    const { data, errors } = await createMerchant({
      variables: {
        input: {
          companyName: hookCompanyName,
          companyUrl: hookCompanyWebsite,
          companyEmail: hookCompanyEmail === '' ? hookUserEmail : hookCompanyEmail,
          companyType: 'Merchant',
          merchantType: 'Self',
          accountStatus: 'Pending',
          networkStatus: 'Invisible',
          phone: phoneOne,
          phone2: phoneTwo || undefined,
          address1: hookAddress,
          address2: hookAddressTwo || undefined,
          city: hookCity,
          country: hookCountry.label,
          state: hookRegion.value,
          zip: hookPostalCode,
          paymentInfo: {
            tax: hookTaxationNumberDisabled ? undefined : hookTaxation,
            gst: hookGstDisabled ? undefined : hookGst,
            pst: hookPstDisabled ? undefined : hookPst,
            hst: hookHstDisabled ? undefined : hookHst,
            email: billingInfo || undefined,
          },
          user: {
            firstName: hookUserFirstName,
            lastName: hookUserLastName,
            email: hookUserEmail,
            position: hookUserPosition || undefined,
            subscribeOffers: hookUserSuscription,
          },
        },
      },
    });

    if (errors) {
      hookShowToast(TOAST_ERR_MESSAGES('create merchant', errors[0].message));
      return;
    }

    if (data) {
      hookShowToast(`Merchant ${hookCompanyName} created successfully`);
      backButtonHandler();
    }
  };

  useEffect(() => {
    hookSetRegion({ label: '', value: '' });
    validateBillingInfo();
    handleValidation();
    if (hookCountry.label === COUNTRIES.CA) {
      setIsCanada(true);
    } else {
      setIsCanada(false);
    }
    if (hookCountry.value !== '') {
      const regionsOfSelectedCountry = hookOriginalCountryList[hookCountry?.value]?.subdivisions;
      const formattedRegions = regionsOfSelectedCountry.map((region: any) => ({ value: region.name, label: region.name }));
      setRegionList(formattedRegions);
    }
  }, [hookCountry]);

  useEffect(() => {
    handleValidation();
  }, [hookRegion]);

  useEffect(() => {
    if (hookCompanyWebsite === '') {
      setUrlError('');
    }
    vali.validateUrlStatus(hookCompanyWebsite, setUrlStatus);
  }, [hookCompanyWebsite]);

  useEffect(() => {
    validateBillingInfo();
    handleValidation();
  }, [secondRender]);

  return {
    hookCreateMerchant: createMerchantHandler,
    hookCountryLoading: hookCountryRegionsLoading,
    hookCreateMerchantLoading: createMerchantLoading,

    hookSetCompanyName,
    hookSetCompanyEmail,
    hookSetWebsite,

    hookUserSuscription,
    hookCountry,
    hookCountryOptions: hookCountriesList,
    hookRegion,
    hookRegionOptions: regionList,
    hookSetAddress,
    hookSetAddressTwo,
    hookSetPostalCode,
    hookSetCity,
    hookSetSelectRegion: hookSetRegion,
    hookSetTextRegion: setTextRegion,
    hookSetCountry,

    hookSetUserFirstName,
    hookUserFirstName,
    hookSetUserLastName,
    hookSetUserEmail,
    hookSetUserPosition,
    hookSetUserSuscription,

    hookPhoneOne: phoneOne,
    hookSetPhoneOne: setPhoneOneHandler,
    hookPhoneTwo: phoneTwo,
    hookSetPhoneTwo: setPhoneTwoHandler,

    hookSetBillingInfo: setBillingInfoHandler,

    hookTaxation,
    hookGst,
    hookPst,
    hookHst,

    hookSetTaxation,
    hookSetGst,
    hookSetPst,
    hookSetHst,

    hookGstDisabled,
    hookHstDisabled,
    hookPstDisabled,
    hookTaxationNumberDisabled,
    hookIsCanada: isCanada,

    hookIsDisabled,

    hookBackButton: backButtonHandler,

    hookCreateMerchantErrors: createMerchantErrors,
    hookBillingInfoErrors: billingInfoErrors,
    hookUrlError: urlError,

    hookValidateFields: handleValidation,
    hookValidateBillingInfo: validateBillingInfo,
    hookValidateWebsite: validateWebsite,
  };
};
