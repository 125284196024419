import React, {
  createContext, useState, useMemo, useEffect,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_NOTIFICATIONS, GNNotificationType, GetNotificationsInput, GetNotificationsOutput,
} from '../../../pages/Notifications/graphql/queries/getNotifications';
import { MARK_NOTIFICATION_AS_READ } from '../../../pages/Notifications/graphql/mutations/markNotificationAsRead';
import { useUserInfo } from '../../../hooks';

const initalState = {
  notifications: [] as GNNotificationType[],
  numberOfUnread: 0,
};

type NotificationsContextType = {
  notifications: GNNotificationType[],
  numberOfUnread: number
  updateNotificationHandler: (id: string, read: boolean) => any
}

type NotificationsContextProps = {
  children: React.ReactNode
}

export const context = createContext<NotificationsContextType>({} as NotificationsContextType);

export const NotificationsContext = ({ children }: NotificationsContextProps) => {
  const { hookWhoAmI } = useUserInfo();
  const [notificationsData, setNotificationsData] = useState<GetNotificationsOutput>();
  const [notifications, setNotifications] = useState<GNNotificationType[]>(initalState.notifications);
  const [numberOfUnread, setNumberOfUnread] = useState<number>(initalState.numberOfUnread);

  const [notificationsQuery, { loading: notificationsLoading }] = useLazyQuery<GetNotificationsOutput, GetNotificationsInput>(GET_NOTIFICATIONS, {
    variables: {
      limit: 5,
    },
    pollInterval: 60000,
    onError() {
      return undefined;
    },
  });

  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ);

  const getNotificiationsHandler = async () => {
    if (!hookWhoAmI.id) return;
    const { data } = await notificationsQuery();
    setNotificationsData(data);
  };

  useEffect(() => {
    getNotificiationsHandler();
  }, [hookWhoAmI.id]);

  useEffect(() => {
    if (notificationsData && !notificationsLoading) {
      if (notificationsData.notifications) {
        setNumberOfUnread(notificationsData.notifications.numberOfUnread || 0);
        setNotifications(notificationsData.notifications.notifications || []);
      }
    }
  }, [notificationsData]);

  const updateNotificationHandler = async (id: string, read: boolean) => {
    if (read) return;
    const { data } = await markNotificationAsRead({
      variables: {
        input: {
          id,
          read: true,
        },
      },
    });

    if (data && data.updateNotification) {
      const notificationCopy = notifications.map((note) => {
        if (note.id === id) {
          return ({
            ...note,
            read: true,
          });
        }
        return note;
      });
      setNotifications(notificationCopy);
      setNumberOfUnread(numberOfUnread - 1);
      return data;
    }
  };

  const state = useMemo(() => ({
    notifications,
    numberOfUnread,
    updateNotificationHandler,
  }), [notifications, numberOfUnread]);

  return (
    <context.Provider value={state}>
      {children}
    </context.Provider>
  );
};
