import React, {
  ReactNode,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';

type IDropdownPropsTypes = {
  /** Controls the open/close state of the dropdown. */
  isOpen: boolean
  /** Function to set dropdowns open state */
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  /** useRef() to attach dropdown. */
  dropdownRef: React.MutableRefObject<null>
  /** Controls the text of the menu. */
  text: string
  /** Controls the size of the menu. */
  disabled?: boolean
  /** The menu shown is made up of the all the children. */
  children: ReactNode
  /** Controls the width of the menu, defaults to 100% */
  width?: string
  /** Controls the position of the menu, defaults to 100% */
  top?: string
  /** Controls the position of the menu, defaults to 0% */
  right?: string
  /** Controls whether or not to show an icon on the left.  */
  leftIcon?: boolean
  /** Controls Icon on leftside by giving the class name from fontawesome for the icon you want. */
  iconClass?: IconProp
  /** Shows the arrow on the right side of the menu. */
  expandableIcon?: boolean
}

export const Dropdown = ({
  isOpen, setIsOpen, dropdownRef, text, disabled, children, width, top, right, leftIcon, iconClass, expandableIcon,
}: IDropdownPropsTypes) => (
  <Styled.DropdownWrapperStyled ref={dropdownRef}>
    <Styled.DropdownShownStyled
      width={width}
      onClick={() => setIsOpen(!isOpen)}
      theme="text-only"
    >
      <Styled.DropdownTextStyled disabled={disabled}>
        <div>
          {leftIcon && iconClass && (<FontAwesomeIcon icon={iconClass} />)}
          {text}
        </div>
        <Styled.DropdownRightSideStyled>
          {expandableIcon && (<FontAwesomeIcon icon={faSortDown} />)}
        </Styled.DropdownRightSideStyled>
      </Styled.DropdownTextStyled>
    </Styled.DropdownShownStyled>

    {isOpen && (
      <Styled.ChildrenDivStyled
        top={top}
        right={right}
      >
        {children}
      </Styled.ChildrenDivStyled>

    )}
  </Styled.DropdownWrapperStyled>
);
