import React from 'react';
import {
  Button, ErrorBanner, InputText, PageTitle, Select,
} from '../../../../../../components';
import {
  BUTTON_LABELS, INPUT_LABELS, MODAL, PAYMENT_CURRENCY,
} from '../../contracts';
import * as Styled from './styles';
import { PaypalButton } from './component';

type AddDepositProps = {
  setIsOpen: () => void;
  productCategoryOptions: SelectOption[]
  seletedProjectCategory?: SelectOption
  selectedCurrency: SelectOption
  setSeletedProjectCategory: (value: SelectOption) => void
  despositAmount: string
  depositAmountHander: (value: React.ChangeEvent<HTMLInputElement>) => void
  additionalInfo: string
  additionalInfoHander: (value: React.ChangeEvent<HTMLInputElement>) => void
  addDepositHandler: () => any
  errors: { [key: string]: string }
  payPalSuccessHandler: (transaction: any) => void
  payPalFailedHandler: () => void
}

const AddDeposit = ({
  setIsOpen, productCategoryOptions, selectedCurrency, seletedProjectCategory,
  setSeletedProjectCategory, depositAmountHander, despositAmount, additionalInfo,
  additionalInfoHander, addDepositHandler, errors, payPalSuccessHandler, payPalFailedHandler,
}: AddDepositProps) => (
  <>
    <Styled.ErrorDiv>
      <ErrorBanner
        margin="10px 0px 0px 0px"
        message={errors.notifyGeneral || errors.paypal}
        isOpen={!!errors.notifyGeneral || !!errors.paypal}
      />
    </Styled.ErrorDiv>
    <Styled.WrapperStyled>
      <PageTitle>{MODAL.ADD.TITLE}</PageTitle>

      <Styled.ButtonAreaStyled>
        <Button
          theme="secondary"
          onClick={setIsOpen}
        >
          {BUTTON_LABELS.CANCEL}
        </Button>
      </Styled.ButtonAreaStyled>

      <Styled.SelectStyled
        label={INPUT_LABELS.CATEGORY}
        name="category"
        selected={seletedProjectCategory}
        onChange={setSeletedProjectCategory}
        options={productCategoryOptions}
        required
        error={errors.despositProductCategories}
      />
      <Select
        label={INPUT_LABELS.CURRENCY}
        name="currency"
        onChange={() => ''}
        options={PAYMENT_CURRENCY}
        selected={selectedCurrency}
        isDisabled
        required
        error={errors.depositCurrency}
      />
      <InputText
        label={INPUT_LABELS.AMOUNT}
        name="amount"
        value={despositAmount}
        type="text"
        onChange={depositAmountHander}
        required
        error={errors.amount}
      />

      <Styled.InputTextStyled
        label={INPUT_LABELS.ADDITIONAL_INFO}
        name="info"
        value={additionalInfo}
        type="text"
        onChange={additionalInfoHander}
      />

      <Styled.PaypayWrapperStyled>
        <PaypalButton
          currency={selectedCurrency?.value}
          amount={despositAmount.replace(/\$/g, '')}
          productCategory={seletedProjectCategory?.value || ''}
          onSuccess={payPalSuccessHandler}
          onFailed={payPalFailedHandler}
          createOrder={addDepositHandler}
        />
      </Styled.PaypayWrapperStyled>
    </Styled.WrapperStyled>
  </>
);

export default AddDeposit;
