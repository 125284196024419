import { gql } from '@apollo/client';

export const GET_PRODUCT_FEED = gql`
query productFeed($productFeedId: ID!) {
  productFeed(id: $productFeedId) {
    id
    trackingProfileId
    merchants {
      id
      companyName
    }
    productCategories
    subCategories
    regionCountry
    regionStates
    productFeedUrl
    products {
      id
      customizedProductId
      name
      productImageUrl
      productCategory
      subCategories
      updatedAt
      description
      status
      productUrl
      landingPage
      thirdPartyUrl
      createdAt
      program {
        id
        merchant {
          id
          companyName
        }
      }
      commissions {
        id
        startDate
        endDate
        description
        commissionType
        commissionStructure {
          cpaFlat {
            minimumSaleAmount
            commissionFee
            commissionBase
          }
          revShareFlat {
            commissionFee
            commissionBase
          }
          cpaTiered {
            commissionBase
            cutoffAmount
            tiers {
              upTo
              commissionAmount
            }
          }
          revShareTiered {
            cutoffAmount
            tiers {
              upTo
              commissionAmount
            }
          }
          referral {
            levels
          }
          bonusFlat {
            minimumSaleAmount
            commissionFee
            commissionBase
          }
          bonusTiered {
            commissionBase
            period
            calculationType
            tiers {
              upTo
              commissionAmount
            }
          }
        }
        commissionTarget {
          publisherGroupTargets {
            publisherGroupId
            startDate
            endDate
          }
          publisherTargets {
            publisherId
            startDate
            endDate
          }
        }
        products {
          id
          name
        }
        merchant {
          id
        }
        publisherGroups {
          id
          name
        }
        publishers {
          id
          companyName
        }
      }
      targetDemographics {
        gender
        geographicalCountry
        geographicalStates
        age
        commissionTypes
        commissionVisibility
        minimumIncome
        minimumHouseHoldIncome
        minimiumCreditScore
      }
      productCategory
      subCategories
      productFeedSection {
        validUntil
        cardNetwork
        userTypes
        annualFee
        monthlyFee
        signupBonus
        supplementaryCardFee
        balanceTransferIntroRate
        balanceTransferRegular
        interestRate
        rewardsEarningRate
        annualInterestRate
        balanceTransferIntroFee
        balanceTransferFee
        cashAdvanceFee
        foreignCurrencyConversion
        dishonouredPaymentFee
        gracePeriod
        marketingItems
        legalReference
        legalReferenceItems {
          name
          description
        }
        promoInterestRate
        transactionFeeATM
        transactionFeeETR
        transactionFeeInternal
        freeTransactionVolume
        closingBalanceToWaiveMonthlyFee
        noMinimumBalance
        term
        redeemable
        insurer
        minimumAccountBalance
        managementFee
        tradeCost
        numberOfFreeMonthlyTrade
        mer
        coverageAmount
        guaranteedPremiums
        exclusionItems
      }
    }
  }
}
`;
