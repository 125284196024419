import { gql } from '@apollo/client';

export const LIST_CATEGORY_PROGRAMS = gql`
  query listCategoryPrograms($productCategories: String) {
    categoryPrograms(productCategories: $productCategories) {
      programs {
        id
        termsConditionsTitle
        termsConditions
        merchant {
          id
          companyName
          companyUrl
        }
      }
    }
  }
`;
