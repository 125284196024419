export const GENERATE_PAYMENT = {
  ESTIMATE_MODAL: {
    CLOSE: 'Cancel',
    CONFIRM: 'Estimate Payments',
    TITLE: 'Merchant Account Balance',
    ALL: 'All',
    MERCHANTS_WITH: 'merchants with',
    LOWER_ACCOUNT_BALANCE: 'lower account balance',
    NOTIFY: 'than the minimum requirement are selected to be rolled over to the next payment period',
  },
  GENERATE_MODAL: {
    TITLE: 'Generate Payment',
    CHECKBOX: 'Notify Publisher',
    QUESTION: 'Are you sure you want to generate payment?',
    WARNING_TITLE: 'Payment reports can only be generated once a month',
    WARNING_BODY: 'Please ensure all pending transactions for the previous month have been reviewed and processed accordingly, so that any publisher commissions due are included for the current month.',
    CANCEL: 'Cancel',
    PAYMENTS: 'Generate Payments',
  },
  DOWNLOAD: 'Download CSV',
  ESTIMATE: 'Estimate Payments',
  GENERATE: 'Generate Payments',
  TEXT: 'Click estimate payments to get started',
  BUTTON_THEME: 'tertiary',
};

export const ERROR_MESSAGES = {
  ACCOUNT_BALANCES: 'Failed to fetch account balances',
  ESTIMATE_PERIOD_BALANCE: 'Failed to estimate period balance',
  ESTIMATE_PAYMENTS: 'Failed to estimate payments',
  GENERATE_PAYMENT: 'Failed to generate Payments',
  COUNT: 'Failed to get count',
};

export const CSV = {
  AMOUNTS: ['currentRollover', 'totalCommissions', 'bonus', 'bonusTax', 'totalTaxes', 'totalPayable', 'periodBalance'],
  DATES: ['paymentGeneratedDate', 'paymentPaidDate'],
  MONTH_NAMES_SUB: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  COLUMNS: [
    {
      dataField: 'publisher',
      text: 'PUBLISHER ID / NAME',
    },
    {
      dataField: 'feasible',
      text: 'FEASIBLE',
    },
    {
      dataField: 'totalCommissions',
      text: 'TOTAL COMMISSION',
    },
    {
      dataField: 'totalTaxes',
      text: 'TOTAL TAX',
    },
    {
      dataField: 'currentRollover',
      text: 'ROLLOVER AMOUNT',
    },
    {
      dataField: 'bonus',
      text: 'BONUS AMOUNT',
    },
    {
      dataField: 'bonusTax',
      text: 'BONUS TAX',
    },
    {
      dataField: 'paymentMethod',
      text: 'METHOD',
    },
    {
      dataField: 'currency',
      text: 'CURRENCY',
    },
    {
      dataField: 'totalPayable',
      text: 'TOTAL PAYABLE',
    },
    {
      dataField: 'periodBalance',
      text: 'PERIOD BALANCE',
    },
  ],
};

export const CASES = {
  MERCHANT: 'merchant',
  MERCHANTID: 'merchantId',
  BALANCE: 'balance',
  PRODUCT_CATEGORY: 'productCategory',
};
