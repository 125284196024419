import { gql } from '@apollo/client';

export const GET_PUBLISHER_INFO = gql`
  query getPublisherInfo($companyId: ID!) {
    company(id: $companyId) {
      id
      memberships {
        id
        status
        merchant {
          id
          companyName
          program {
            id
            products {
              id
              name
              customizedProductId
              status
            }
            ads {
              id
              adName
              adStatus
            }
          }
        }
      }
    }
  }
`;
