export const CHECK_BACK = {
  TITLE: 'Check back soon',
  DESCRIPTION: 'Your weekly check has been scheduled. Please try again later.',
};

export const NO_CHECK_RULES = {
  TITLE: 'No records found',
  DESCRIPTION: 'A tailored compliance report of your publishers will show up here once you set rules.',
  BUTTON: 'Create Rules',
};

export const GRAPHS = {
  PUBLISHER: 'Publisher Checks',
  RULE: 'Rule Checks',
};

export const BUTTONS = {
  DOWNLOAD: 'Download CSV',
};
