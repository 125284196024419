import { gql } from '@apollo/client';

export const LIST_ACTIVE_PRODUCTS = gql`
  query ListActiveProducts($input: ListActiveProductsInput) {
    listActiveProducts(input: $input) {
      list {
        id
        name
      }
    }
  }
`;
