import React from 'react';
import * as Styled from '../styles';
import { CellProps } from '../types';

export const PublishersCell = ({ val }: CellProps) => (
  <Styled.CellStyled>
    <p>All Publishers</p>
    <Styled.PublishersStyled>{`${val} publishers`}</Styled.PublishersStyled>
  </Styled.CellStyled>
);
