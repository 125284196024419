export const STATISTIC_CARD = {
  COMMISSIONS: {
    TITLE: 'Commission In CAD',
    TOOLTIP: 'This is your total commissions earned for the period selected.',
  },
  APPROVED_TRANSACTIONS: {
    TITLE: 'Approved Transactions',
    TOOLTIP: 'This the total number of approved transactions for the period selected.',
  },
  CONVERSION_RATE: {
    TITLE: 'Conversion Rate',
    TOOLTIP: 'This the percentage of traffic that completed a transaction (not necessarily an approval) within the period selected. It is calculated as the number of total transactions divided by the total number of clicks.',
  },
  PUBLISHER_BY_CLICKS: {
    TITLE: 'Active Publishers By Click',
    TOOLTIP: 'This is a count of publishers in your program that have generated at least one click during the period selected.',
  },
  PUBLISHER_BY_APPROVALS: {
    TITLE: 'Active Publishers By Approvals',
    TOOLTIP: 'This is a count of publishers in your program that have generated at least one approved transaction during the period selected.',
    TOOLTIP_WIDTH: 200,
  },
};

export const STATUS_CARD = {
  ACCOUNT_BALANCE: {
    TITLE: 'Account Balance',
    TOOLTIP: 'This is a status check for your account balance. If your balance is low, please replenish your account to ensure there are sufficient funds for the next payment period. Contact billing@fintelconnect.com if you have questions about your balance.',
  },
  PENDING_APPLICATIONS: {
    TITLE: 'Pending Applications',
    TOOLTIP: 'This is a count of applications that are pending and not yet approved. To view details of your pending applications, click the link below. If you are unsure what action to take, reach out to your account manager for support.',
  },
  PENDING_TRANSACTIONS: {
    TITLE: 'Pending Transactions',
    TOOLTIP: 'This is a count of transactions that are pending and not yet approved. To view details of your pending transactions, click the link below. If you are unsure what action to take, reach out to your account manager for support.',
  },
};

export const SNAPSHOT = {
  TOOLTIP: 'This graph provides a visual overview of total commissions, transactions and approved transactions by month over a 12-month period.',
};

export const TOP_PUBLISHERS = {
  TOOLTIP: 'This table displays the top five publishers that have earned the most commissions in the period selected. Easily see where they have increased or decreased from the previous period to identify performance trends.',
};

export const TRAFFIC_ANALYTICS = {
  TOOLTIP: 'This visual allows you to easily see where your traffic is by location for the period selected. See total number of visitors (clicks) in each area, and drill down for more specific geo-reporting.',
};

export const CLICKS_BY_DEVICE = {
  TOOLTIP: 'This graph reports the volume of approved transactions per device during the period selected. Click on a pie slice to drill down into specific product performance.',
};

export const PRODUCT_BY_APPROVALS = {
  TOOLTIP: 'This graph reports the volume of approved transactions per product category during the period selected. Click on a pie slice to drill down into specific product performance.',
  NO_DATA_TITLE: 'No data available',
  NO_DATA_DESCRIPTION: 'Your top products by approved transactions will be displayed here.',
};

export const TOP_PRODUCTS = {
  TOOLTIP: 'This table displays the top five products that have generated the most commissions in the period selected. Easily see where they have increased or decreased from the previous period.',
  NO_DATA: 'No Data Available',
  NO_DATA_DESCRIPTION: 'Your top products data will be displayed here once you apply to merchant programs.',
};
