import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileDownload, faSearch } from '@fortawesome/free-solid-svg-icons';

import {
  AppWrapper, InfoBox, InputText, Modal, PageTitle,
} from '../../../components';
import { ERROR_ICON, USER_TYPES_ID, path } from '../../../utils';
import { BUTTON_LABEL, columns, PLACEHOLDER } from './contracts';
import { useAccountBalanceManagementDetails } from './hooks';
import { FinalizeModal } from './Modals';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type AccountBalanceManagementDetailsProps = DefaultPropsType;

const AccountBalanceManagementDetails = ({ permissionsCodeList = [] }: AccountBalanceManagementDetailsProps) => {
  const navigate = useNavigate();
  const hook = useAccountBalanceManagementDetails();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      {hook.hookErrors && (
        <InfoBox
          theme="ERROR"
          title=""
          description={hook.hookErrors}
          icon={ERROR_ICON}
        />
      )}
      <Styled.HeaderStyled>
        <PageTitle
          loadingText="Loading"
        >
          {hook.hookMerchant}
          {' '}
          {path.merchantAccountBalanceManagementDetails.name}
        </PageTitle>
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={() => navigate(-1)}
        >
          {BUTTON_LABEL.BACK}
        </Styled.ButtonStyled>
      </Styled.HeaderStyled>
      <Styled.ButtonContainerStyled>
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={hook.hookGenerateCSV}
        >
          <FontAwesomeIcon icon={faFileDownload} />
          {BUTTON_LABEL.DOWNLOAD}
        </Styled.ButtonStyled>

        {hook.hookIsFinalized && (
          <Styled.ButtonStyled
            onClick={hook.hookStatementHandler}
            theme="tertiary"
          >
            <FontAwesomeIcon icon={faFile} />
            {BUTTON_LABEL.ACCOUNT_STATEMENT}
          </Styled.ButtonStyled>
        )}

        {!hook.hookIsFinalized && hook.hookUserType.userTypesId === USER_TYPES_ID.ADMIN && hook.hookWithInPeriod && (
          <Styled.ButtonStyled
            onClick={() => hook.hookSetModelOpen(!hook.hookModelOpen)}
          >
            {BUTTON_LABEL.STATEMENT}
          </Styled.ButtonStyled>
        )}
      </Styled.ButtonContainerStyled>

      <Styled.SearchInputStyled>
        <InputText
          faIcon={faSearch}
          type="text"
          placeholder={PLACEHOLDER.SEARCH}
          onChange={hook.hookSetSearchValue}
          value={hook.hookSearchValue}
        />
      </Styled.SearchInputStyled>

      <Styled.TableStyled
        columns={columns}
        data={hook.hookAccountRecord}
        isLoading={hook.hookIsLoading}
        onSort={hook.hookSetSortColumn}
        sortColumn={hook.hookSortColumn}
      />
      {hook.hookNumberOfPages > 1 && (
        <Styled.PaginationStyled
          total={hook.hookNumberOfPages}
          currentPage={hook.hookCurrentPage}
          onPageChange={hook.hookSetCurrentPage}
        />
      )}

      <Modal isOpen={hook.hookModelOpen}>
        <FinalizeModal
          handleCreateFinalize={hook.hookHandleFinlizeAccoundRecord}
          setModalOpen={hook.hookSetModelOpen}
          isLoading={hook.hookIsFinalizedLoading}
        />
      </Modal>
    </AppWrapper>
  );
};

export default AccountBalanceManagementDetails;
