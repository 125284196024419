import styled from 'styled-components';
import { colors, fonts } from '../../styles/theme';
import { toRem } from '../../utils';

type RowColumnProps = {
  readonly width?: string;
  readonly minHeight?: string;
  readonly clickable?: boolean;
  readonly turnOffOverflow?: boolean;
  readonly inactive?: boolean;
  readonly alignItems?: string;
  readonly inactiveModal?: boolean;
  readonly disableRow?: boolean
}

type SortButtonProps = {
  readonly isSorted: boolean;
  readonly position?: 'flex-start' | 'center' | 'flex-end'
  readonly theme?: string
  readonly isCheckBox?: boolean
}

type RowProps = {
  readonly inactive?: boolean;
  readonly turnOffOverflow?: boolean;
  readonly theme?: string;
  readonly width?: string
  readonly clickable?: boolean;
  readonly isFooter?: boolean;
  readonly wrapText?: boolean;
}

type TableProps = {
  readonly isLoading?: boolean;
  readonly loadWithData?: boolean
  readonly noTableOverflow?: boolean;
  readonly noHorizontalScroll?: boolean
}

export const WrapperStyled = styled.div<TableProps>`
  min-width: 100%;
  position: relative;
  ${fonts.lato.H16}
  height: ${({ isLoading, loadWithData }) => ((isLoading && !loadWithData) ? '3rem' : 'auto')};
  background: ${({ isLoading, loadWithData }) => ((isLoading && loadWithData) ? colors.color3 : '')};
  display: flex;
  overflow-x: ${({ noTableOverflow }) => ((noTableOverflow) ? 'visible' : 'auto')};
  color: ${colors.color1};
`;

export const TableStyled = styled.table<TableProps>`
  min-width: 100%;
  min-height: 100%;
  width: ${({ noHorizontalScroll }) => (noHorizontalScroll ? '100%' : 'auto')};
  table-layout: ${({ noHorizontalScroll }) => (noHorizontalScroll ? 'fixed' : 'auto')};
  overflow-x: ${({ noHorizontalScroll }) => (noHorizontalScroll ? 'clip' : 'auto')};
`;

export const TableHead = styled.thead<RowColumnProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${colors.color19};

  :first-child,
  :last-child
  {
    border: none;
  }
`;

export const TableBody = styled.tbody``;

export const TableHeaderRow = styled.tr`
  display: flex;
  width: 100%;
`;

export const TableRows = styled.tr<RowColumnProps>`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  ${({ disableRow }) => disableRow && `background-color: ${colors.color3}`}
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

    opacity: ${({ inactive, inactiveModal }) => { if (inactive && !inactiveModal) return '0.4'; }};  

  td {
    border-bottom: 1px solid ${colors.color19};
    background-color: ${({ inactive }) => { if (inactive) return colors.color11; }};
  }

  span {
    opacity: ${({ inactive, inactiveModal }) => { if (inactive && inactiveModal) return '0.4'; }};  
  }

    :last-child
  {
    border: none;
  }

  :hover td {
    background-color: ${colors.color6};
  }
`;

export const TableFooter = styled.tfoot`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${colors.color19};

  :first-child,
  :last-child
  {
    border: none;
  }

  :hover {
    background-color: ${colors.color6};
  }
`;

export const ColumnStyled = styled.th<RowColumnProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: ${({ width }) => width || '100%'};
`;

export const CellStyled = styled.td<RowProps>`
  padding: ${toRem(10)};
  white-space: ${({ wrapText }) => (wrapText ? '' : 'nowrap')};
  text-overflow: ${({ isFooter }) => (isFooter ? 'visible' : 'ellipsis')};;
  overflow:  ${({ turnOffOverflow, isFooter }) => {
    if (isFooter) {
      return 'visible';
    }
    return turnOffOverflow ? '' : 'hidden';
  }};
  min-width: ${({ width }) => width};
  ${fonts.lato.H14}
  color: ${({ isFooter }) => (isFooter ? colors.color2 : 'auto')};
  div {
    min-height: 100%;
    text-overflow: ${({ isFooter }) => (isFooter ? 'visible' : 'ellipsis')};;
    overflow:  ${({ turnOffOverflow, isFooter }) => {
    if (isFooter) {
      return 'visible';
    }
    return turnOffOverflow ? '' : 'hidden';
  }}};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
`;

export const SortButtonStyled = styled.button<SortButtonProps>`
  display: flex;
  align-items: center;
  background: transparent;
  text-align: left;
  border: none;
  justify-content: ${({ position }) => (position || 'flex-start')};
  color: ${colors.color13};
  width: 100%;
  ${fonts.gotham.H11M}
  text-transform: uppercase;
  padding: ${toRem(8)};

  cursor: ${({ isSorted }) => (isSorted ? 'pointer' : 'auto')};

  svg {
    color: ${({ theme }) => (theme === 'checkBox' ? colors.color4 : colors.color5)};
    font-size: ${toRem(12)};
    margin-left: ${({ theme }) => (theme === 'checkBox' ? 0 : toRem(8))};
  }
`;

export const LoadingStyled = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
`;
