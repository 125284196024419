import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import {
  path, paginator, dateFormatter, csvGenerator,
} from '../../../../utils';
import { GET_ACCOUNT_BALANCES } from '../graphql/queries/getAccountBalances';
import { GET_COUNTS } from '../graphql/queries';
import { ESTIMATE_PERIOD_BALANCE } from '../graphql/mutations/estimatePeriodBalance';
import { ESTIMATE_PAYMENTS } from '../graphql/mutations/estimatePayments';
import { GENERATE_PAYMENTS } from '../graphql/mutations/generatePayments';
import { PAYMENT_ESTIMATION } from '../graphql/mutations/paymentEstimation';
import { ERROR_MESSAGES, CSV, CASES } from '../PaymentGenerator/enums';
import exportCSV from '../../../../utils/exportCsv';
import { context } from '../../ReportDetails/context/reportDetailsContext';
import { Permission } from '../../../../entities';

export const usePaymentGenerator = (permissionsCodeList: string[] = []) => {
  const location = useLocation();
  const [initialLoad, setInititalLoad] = useState(false);

  const today = new Date();
  const { state, setHandler } = useContext(context);
  const previousMonth = ((moment(today).subtract(1, 'months').toDate())
    .getMonth() + 1)
    .toString();
  const previousYear = (moment(today).subtract(1, 'months').toDate())
    .getFullYear()
    .toString();
  const currentMonth = ((moment(today).toDate()).getMonth() + 1).toString();
  const currentYear = (moment(today).toDate()).getFullYear().toString();
  const [isGenerateOpen, setIsGenerateOpen] = useState<boolean>(false);
  const [isNotifyChecked, setIsNotifyChecked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [generateDisabled, setDisabled] = useState(false);
  console.log(`previousMonth: ${previousMonth}, previousYear: ${previousYear}, currentMonth: ${currentMonth}, currentYear: ${currentYear}`);
  const [month, setMonth] = useState(previousMonth);

  // Generate Payments
  const [generatePayments, { loading: generatePaymentsLoading }] = useMutation(GENERATE_PAYMENTS);

  // Estimate Payments
  const [rolloverMerchants, setRolloverMerchants] = useState<string[]>([]);
  const [estimatePeriodBalance, { loading: periodBalanceLoading }] = useMutation(ESTIMATE_PERIOD_BALANCE);
  const [estimatePayments, { loading: estimatePaymentsLoading }] = useMutation(ESTIMATE_PAYMENTS);
  const [paymentEstimation, { loading: paymentEstimationLoading }] = useMutation(PAYMENT_ESTIMATION);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const [selectedRecord, setSelectedRecords] = useState<SelectOption>({ label: '10', value: '10' });

  const [estimatedPayments, setEstimatePayments] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  // count
  const [count, setCount] = useState<number>(0);
  const [getCount] = useLazyQuery(GET_COUNTS);

  // Account Balances
  const [getAccountBalances, { loading: accountBalancesLoading }] = useLazyQuery(GET_ACCOUNT_BALANCES);
  const [estimateModalTable, setEstimateTable] = useState<any[]>([]);
  const [accountBalances, setAccountBalances] = useState<any[]>([]);
  const [numAccountsBelowMinBalance, setNumAccountsBelowMinBalance] = useState(0);
  const [sortColumnEstimateModal, setSortColumnEstimateModal] = useState<TableSortColumn>();
  const [headerCheckModal, setHeaderCheckModal] = useState<boolean>(false);
  const [estimatePage, setEstimatePage] = useState<number>(1);
  const [estimateTotalPages, setEstimateTotalPage] = useState<number>(1);
  const [isEstimateOpen, setIsEstimateOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  // Getting count
  const handleGetCount = async () => {
    const { data } = await getCount({
      variables: {
        input: {
          paymentStatus: 'NotEstimated',
          month: previousMonth,
          year: previousYear,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data !== null && data.countPayments !== null) {
      setCount(data.countPayments);
    } else {
      setErrorMessage(ERROR_MESSAGES.COUNT);
    }
  };

  useEffect(() => {
    handleGetCount();
  }, []);

  // Account Balances Modal Start
  const handleGetAccountBalances = async () => {
    const { data } = await getAccountBalances({
      variables: {
        input: {
          year: count > 0 ? currentYear : previousYear,
          month: count > 0 ? currentMonth : previousMonth,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.accountBalances?.accountBalances !== undefined) {
      setAccountBalances(data.accountBalances.accountBalances.map((balance: any) => ({ ...balance, checked: false })));
      setEstimateTable(paginator(data.accountBalances.accountBalances.map((balance: any) => ({ ...balance, checked: false })), 6, 1));
      setEstimateTotalPage(Math.ceil(data.accountBalances.count / 6));
      setNumAccountsBelowMinBalance(data.accountBalances.accountBalances.reduce((balanceCount: number, balance: any) => {
        if (Number(balance.balance) < Number(balance.minBalanceRequired)) {
          return balanceCount + 1;
        }
        return balanceCount;
      }, 0));
      setEstimatePage(1);
    } else {
      setErrorMessage(ERROR_MESSAGES.ACCOUNT_BALANCES);
    }
  };

  const handleChangePageModal = (value: any) => {
    setEstimateTable(paginator(accountBalances, 6, value));
    setEstimatePage(value);
  };

  const handleSortModal = (dataField: string, direction = 'asc') => {
    const nextDirection: number = direction === 'asc' ? -1 : 1;
    const compare = (a: any, b: any) => {
      let compareCondition = false;
      switch (dataField) {
        case CASES.MERCHANT: {
          compareCondition = b.merchant.companyName.toLowerCase() < a.merchant.companyName.toLowerCase();
          break;
        }
        case CASES.MERCHANTID: {
          compareCondition = b.merchant.id < a.merchant.id;
          break;
        }
        case CASES.BALANCE: {
          compareCondition = Number(b[dataField]) < Number(a[dataField]);
          break;
        }
        case CASES.PRODUCT_CATEGORY: {
          if (a.productCategory && b.productCategory) {
            compareCondition = a[dataField] > b[dataField];
          } else {
            compareCondition = (b.productCategory === null);
          }
          break;
        }
        default: {
          compareCondition = a[dataField] > b[dataField];
          break;
        }
      }
      return compareCondition ? nextDirection : nextDirection * -1;
    };
    const copyArray = [...accountBalances];
    const sortedArray = copyArray.sort((a, b) => compare(a, b));
    setAccountBalances(sortedArray);
    setEstimateTable(paginator(sortedArray, 6, 1));
    setSortColumnEstimateModal({ column: dataField, direction: sortColumnEstimateModal?.direction === 'desc' ? 'asc' : 'desc' });
    setEstimatePage(1);
  };
  // Account Balances Modal Done

  // Estimate Payments Starts
  useEffect((() => {
    const checkedInfo: string[] = [];
    accountBalances.forEach((estimate) => {
      if (estimate.checked) {
        if (estimate.productCategory !== null) {
          checkedInfo.push(`${estimate.merchant.id}-${estimate.productCategory}`);
        } else {
          checkedInfo.push(estimate.merchant.id);
        }
      }
    });
    setRolloverMerchants(checkedInfo);
  }), [accountBalances]);

  const handleEstimatePayments = async () => {
    setHeaderCheckModal(false);
    const { data } = await paymentEstimation({
      variables: {
        input: {
          rolloverMerchants,
          month: count > 0 ? currentMonth : previousMonth,
          year: count > 0 ? currentYear : previousYear,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.paymentEstimation !== undefined) {
      const stateClone = { ...state };
      stateClone.generatePaymentsData = data.paymentEstimation;
      setHandler(stateClone);
      setEstimatePayments(data.paymentEstimation.payments);
      setTableData(paginator(data.paymentEstimation.payments, Number(selectedRecord.value), 1));
      setTotalPages(Math.ceil(data.paymentEstimation.count / Number.parseInt(selectedRecord.value, 10)));
      setTotalRecords(data.paymentEstimation.count);
    } else {
      setErrorMessage(ERROR_MESSAGES.ESTIMATE_PAYMENTS);
    }
  };

  const changePageHandler = (value: any) => {
    setCurrentPage(value);
    setTableData(paginator(estimatedPayments, Number(selectedRecord.value), value));
  };

  const ChangeRecordsHandler = (value: any) => {
    setSelectedRecords(value);
    setCurrentPage(1);
    setTotalPages(Math.ceil(estimatedPayments.length / Number.parseInt(value.value, 10)));
    setTableData(paginator(estimatedPayments, Number(value.value), 1));
  };

  const onSort = (dataField: string, direction = 'asc') => {
    const nextDirection: number = direction === 'asc' ? -1 : 1;
    const numbers = ['currentRollover', 'totalCommissions', 'bonus', 'bonusTax', 'totalTaxes', 'totalPayable', 'periodBalance'];
    const compare = (a: any, b: any) => {
      let compareCondition = false;
      if (numbers.includes(dataField)) {
        compareCondition = Number(b[dataField]) < Number(a[dataField]);
      } else if (dataField === 'publisher') {
        compareCondition = b.publisher.companyName.toLowerCase() < a.publisher.companyName.toLowerCase();
      } else {
        compareCondition = b[dataField] < a[dataField];
      }
      return compareCondition ? nextDirection : nextDirection * -1;
    };
    const copyArray = [...estimatedPayments];
    const sortedArray = copyArray.sort((a, b) => compare(a, b));
    setEstimatePayments(sortedArray);
    setTableData(paginator(sortedArray, Number(selectedRecord.value), 1));
    setSortColumn({ column: dataField, direction: sortColumn?.direction === 'desc' ? 'asc' : 'desc' });
    setCurrentPage(1);
  };

  const handleConfirmEstimate = async () => {
    handleEstimatePayments();
    setIsEstimateOpen(false);
  };
  // Main estimated payments table data done

  const handleSetGenerateOpen = () => {
    setIsGenerateOpen(true);
  };

  const handelNotifyChecked = () => {
    setIsNotifyChecked(!isNotifyChecked);
  };

  const onRowClick = (value: any) => {
    navigate(path.paymentReportDetails.href, {
      state: {
        id: value.id,
        publisherID: value.publisher.id,
        paymentStatus: value.paymentStatus,
        paymentMethod: value.paymentMethod,
        month: value.month,
        year: value.year,
        previousPath: path.generatePayment.href,
      },
    });
  };
  const handleGenerateCancel = () => {
    setIsGenerateOpen(false);
  };

  const handleGenerateConfirm = async () => {
    const { data } = await generatePayments({
      variables: {
        month: previousMonth,
        year: previousYear,
        shouldNotifyPublisher: isNotifyChecked,
      },
    });
    if (data === null) {
      setErrorMessage(ERROR_MESSAGES.GENERATE_PAYMENT);
    } else {
      handleGetCount();
      setSelectedRecords({ label: '10', value: '10' });
      setCurrentPage(1);
      setTableData([]);
    }
    setIsGenerateOpen(false);
  };

  const handleCancelEstimate = () => {
    setHeaderCheckModal(false);
    setIsEstimateOpen(false);
  };

  const handleSetEstimateOpen = () => {
    handleGetAccountBalances();
    setIsEstimateOpen(true);
  };

  const handleHeaderCheckModal = () => {
    const newData = accountBalances.map((obj) => ({ ...obj, checked: !headerCheckModal }));
    setAccountBalances(newData);
    setEstimateTable(paginator(newData, 6, estimatePage));
    setHeaderCheckModal(!headerCheckModal);
  };

  const handleChangeCheckModal = (row: any, value: any) => {
    const newObj = [...estimateModalTable];
    const targetIndex = newObj.findIndex((obj) => (obj.merchant.id === row.merchant.id) && (obj.balance === row.balance) && (obj.productCategory === row.productCategory));
    newObj[targetIndex].checked = value;
    setEstimateTable(newObj);

    const checkedObj = newObj[targetIndex];
    const newData = accountBalances.map((obj) => {
      if ((obj.merchant.id === checkedObj.merchant.id) && (obj.balance === checkedObj.balance) && (obj.productCategory === checkedObj.productCategory)) {
        return checkedObj;
      }
      return obj;
    });
    setAccountBalances(newData);
  };

  const exportPaymentCsv = () => {
    const unpaginated = [...estimatedPayments];
    const formattedArray = unpaginated.map((item) => {
      const newItem = { ...item };
      CSV.COLUMNS.forEach((column: any) => {
        let data;
        if (column.dataField === 'month') {
          data = `${CSV.MONTH_NAMES_SUB[Number(item[column.dataField]) - 1]} / ${item.year}`;
        } else if (column.dataField === 'publisher') {
          data = `${item.publisher.id} - ${item.publisher.companyName} `;
        } else if (CSV.AMOUNTS.includes(column.dataField)) {
          data = Number(item[column.dataField]).toFixed(2);
        } else if (CSV.DATES.includes(column.dataField)) {
          data = dateFormatter(new Date(item[column.dataField]));
        } else if (column.dataField.includes('.')) {
          const [firstField, secondField] = column.dataField.split('.');
          data = item[firstField][secondField];
        } else {
          data = item[column.dataField];
        }
        newItem[column.dataField] = typeof data === 'string' ? data.replace(/,/g, '') : data;
      });
      return newItem;
    });
    const csv = exportCSV(CSV.COLUMNS, formattedArray);
    return csv;
  };

  const handleDownloadButton = () => {
    csvGenerator(exportPaymentCsv(), 'payment.csv');
  };

  if (location.state?.context && !initialLoad) {
    setEstimatePayments(state.generatePaymentsData.payments);
    setTableData(paginator(state.generatePaymentsData.payments, Number(selectedRecord.value), 1));
    setTotalPages(Math.ceil(state.generatePaymentsData.count / Number.parseInt(selectedRecord.value, 10)));
    setTotalRecords(state.generatePaymentsData.count);
    setInititalLoad(true);
  }

  useEffect(() => {
    if (count > 0) {
      setDisabled(true);
      setMonth(currentMonth);
    }
    if (tableData.length <= 0) {
      setDisabled(true);
    } else if (count <= 0) {
      setDisabled(false);
    }
  }, [count, tableData]);

  return {
    hookTableData: tableData,

    hookGeneratePaymentsDisabled: generateDisabled,

    hookTotalRecords: totalRecords,

    hookSelectedRecords: selectedRecord,

    hookAccountBalances: estimateModalTable,

    hookAccountsBelowMinBalanceCount: numAccountsBelowMinBalance,

    hookEstimatePage: estimatePage,

    hookEstimateTotalPages: estimateTotalPages,

    hookSort: sortColumn,

    hookIsEstimateOpen: isEstimateOpen,

    hookHeaderCheckModal: headerCheckModal,

    hookIsGenerateOpen: isGenerateOpen,

    hookNotifyChecked: isNotifyChecked,

    hookCurrentPage: currentPage,

    hookEstimateModalTableLoading: accountBalancesLoading,

    month,

    year: previousYear,

    hookSortEstimate: sortColumnEstimateModal,

    hookEstimateModalConfirmLoading: periodBalanceLoading,

    hookTableLoading: paymentEstimationLoading,

    hookTotalPages: totalPages,

    hookGenerateLoading: generatePaymentsLoading,

    hookErrorMessage: errorMessage,

    hookChangeSelectedRecords: ChangeRecordsHandler,

    hookHandleDownload: handleDownloadButton,

    hookHandleSortEstimate: handleSortModal,

    hookHandleChangePageModal: handleChangePageModal,

    hookHandleSetGenerateOpen: handleSetGenerateOpen,

    hookHandleNotifyChecked: handelNotifyChecked,

    hookGenerateCancel: handleGenerateCancel,

    hookGenerateConfirm: handleGenerateConfirm,

    hookHandleSetEstimateOpen: handleSetEstimateOpen,

    hookHandleChangeCheckModal: handleChangeCheckModal,

    hookHandleHeaderCheckModal: handleHeaderCheckModal,

    hookHandleSetSelectedRecord: ChangeRecordsHandler,

    hookHandleEstimateConfirm: handleConfirmEstimate,

    hookHandleEstimateCancel: handleCancelEstimate,

    hookOnRowClick: onRowClick,

    hookOnSort: onSort,

    hookChangePage: changePageHandler,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
