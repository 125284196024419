import React from 'react';
import {
  ActionsCell,
  NameCell, PermissionCell, ScheduleCell, SettingsCell,
} from './Components';

export const settingColumns: TableColumn[] = [
  {
    dataField: 'nameDescription',
    text: 'Name & Description',
    width: '25%',
    formatter: (val) => <NameCell val={val} />,
  },
  {
    dataField: 'settings',
    text: 'Filter Settings',
    width: '20%',
    formatter: (val, _, __, ___, ____, _____, ______, setModals) => (
      <SettingsCell
        val={val}
        setModals={setModals}
      />
    ),
  },
  {
    dataField: 'schedule',
    text: 'Schedule',
    width: '20%',
    formatter: (val) => <ScheduleCell val={val} />,
  },
  {
    dataField: 'company',
    text: 'Permission',
    width: '20%',
    formatter: (val) => <PermissionCell val={val} />,
  },
  {
    dataField: 'actions',
    text: 'Actions',
    width: '15%',
    formatter: (val, row, tableData, setTableData, __, setOpenModal, ____, setModals) => (
      <ActionsCell
        val={val}
        row={row}
        reportCreatedNotifier={setOpenModal}
        setModals={setModals}
      />
    ),
  },
];
