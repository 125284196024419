import React from 'react';
import {
  Page, Text, View, Document, Image,
} from '@react-pdf/renderer';
import { dateFormatter, imageList, formateDollarAmount } from '../../../utils';
import {
  BANKING_DEFAULTS,
  BANKING_LABEL,
  FOOTER_DEFAULTS,
  FOOTER_LABELS,
  LABELS, PAYEE_DEFAULTS, PAYEE_LABELS, PDF_CATEGORY_SUBTILE, PDF_DATE_KEYS, PDF_TABLE_HEADER, PDF_TABLE_KEYS, pdfStyles,
} from '../constracts';
import { ADDRESS } from '../../../pages/Payments/InvoiceStatement/contracts';

export const usePDFRender = (isMerchantPdf: boolean) => {
  const createLabel = (rowKey: string, date: string) => {
    let label = PDF_CATEGORY_SUBTILE[rowKey === 'other' ? 'manualBonusOther' : rowKey];
    if (PDF_DATE_KEYS.includes(rowKey) && !!date) {
      label = `${label} - [Payment period: ${date}]`;
    }
    return label;
  };

  const createCommonHeader = (headerInfo: PDFHeaderInfo) => {
    const { tax, address } = headerInfo;
    return (
      <View style={{ ...pdfStyles.rowSpaceBetween, marginBottom: 40 }}>
        <View>
          <Text style={pdfStyles.invoiceSection}>{LABELS.INVOICE}</Text>
        </View>
        <View>
          <Text style={{ ...pdfStyles.boldText, marginBottom: isMerchantPdf ? 10 : 0 }}>
            {address.name || address.companyName}
          </Text>
          <View style={pdfStyles.rowSpace}>
            {isMerchantPdf && (
              <Image
                source={imageList.logoBlueFull.src}
                style={{ width: 90, height: 63 }}
              />
            )}
            <View>
              <View style={pdfStyles.rowEnd}>
                <Text style={pdfStyles.text}>
                  {address.address1}
                  ,
                </Text>
              </View>
              <View style={pdfStyles.rowEnd}>
                <Text style={pdfStyles.text}>
                  {address.city}
                  ,
                  {' '}
                  {address.state}
                  ,
                </Text>
              </View>
              <View style={pdfStyles.rowEnd}>
                <Text style={pdfStyles.text}>
                  {address.country}
                  ,
                  {' '}
                  {address.zip}
                </Text>
              </View>
              {!isMerchantPdf && (
                <View style={pdfStyles.rowEnd}>
                  <Text style={pdfStyles.text}>
                    {address.phone}
                  </Text>
                </View>
              )}
              {tax.map((taxOption: { label: string, value: string }) => (
                <View
                  style={pdfStyles.rowEnd}
                  key={taxOption.label}
                >
                  <Text style={isMerchantPdf ? pdfStyles.textSmall : pdfStyles.text}>
                    {taxOption.label}
                    {' '}
                    :
                    {' '}
                    {taxOption.value}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </View>
      </View>
    );
  };

  const createPayeeSection = (payeeInfo: PDFPayeeInfo) => {
    const { address } = payeeInfo;
    return (
      <View style={pdfStyles.rowSpaceBetween}>
        <View style={pdfStyles.column}>
          <View style={pdfStyles.rowSpaceBetweenHalf}>
            <Text style={pdfStyles.label}>{PAYEE_LABELS.NUMBER}</Text>
            <Text style={pdfStyles.text}>{payeeInfo.invoiceNo}</Text>
          </View>

          <View style={pdfStyles.rowSpaceBetweenHalf}>
            <Text style={pdfStyles.label}>{PAYEE_LABELS.DATE}</Text>
            <Text style={pdfStyles.text}>{dateFormatter(new Date(payeeInfo.invoiceDate), ',')}</Text>
          </View>

          <View style={pdfStyles.rowSpaceBetweenHalf}>
            <Text style={pdfStyles.label}>{PAYEE_LABELS.PO}</Text>
            <Text style={pdfStyles.text} />
          </View>
          <View style={pdfStyles.rowSpaceBetweenHalf}>
            <Text style={pdfStyles.label}>{PAYEE_LABELS.TERMS}</Text>
            <Text style={pdfStyles.text}>{PAYEE_DEFAULTS.TERMS}</Text>
          </View>
          <View style={pdfStyles.rowSpaceBetweenHalf}>
            <Text style={pdfStyles.label}>{PAYEE_LABELS.DUE}</Text>
            <Text style={pdfStyles.text}>{dateFormatter(new Date(payeeInfo.dueDate), ',', false, true)}</Text>
          </View>

        </View>
        <View style={pdfStyles.column}>
          <View style={{ ...pdfStyles.rowSpaceBetween, paddingLeft: 30, marginBottom: 10 }}>
            <Text style={pdfStyles.label}>{PAYEE_LABELS.TO}</Text>
            <Text style={pdfStyles.boldText}>{payeeInfo.invoiceTo}</Text>
          </View>
          <View style={pdfStyles.rowEnd}>
            <Text style={pdfStyles.text}>
              {address.address1}
              ,
            </Text>
          </View>
          <View style={pdfStyles.rowEnd}>
            <Text style={pdfStyles.text}>
              {address.city}
              ,
              {address.state}
              ,
            </Text>
          </View>
          <View style={pdfStyles.rowEnd}>
            <Text style={pdfStyles.text}>
              {address.country}
              ,
              {address.zip}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const createPubisherPayeeSection = (payeeInfo: PDFPayeeInfo) => {
    const { address } = payeeInfo;
    return (
      <View style={pdfStyles.rowSpaceBetween}>
        <View style={{ ...pdfStyles.column, marginTop: 25 }}>
          <View style={pdfStyles.rowSpaceBetweenHalf}>
            <Text style={pdfStyles.label}>{PAYEE_LABELS.NUMBER}</Text>
            <Text style={pdfStyles.text}>{payeeInfo.invoiceNo}</Text>
          </View>
          <View style={pdfStyles.rowSpaceBetweenHalf}>
            <Text style={pdfStyles.label}>{PAYEE_LABELS.CUSTOMER}</Text>
            <Text style={pdfStyles.text}>{payeeInfo.customId}</Text>
          </View>
          <View style={pdfStyles.rowSpaceBetweenHalf}>
            <Text style={pdfStyles.label}>{PAYEE_LABELS.DATE}</Text>
            <Text style={pdfStyles.text}>{dateFormatter(new Date(payeeInfo.invoiceDate), ',', false, true)}</Text>
          </View>

        </View>
        <View style={pdfStyles.column}>
          <View style={{ ...pdfStyles.rowEnd, marginBottom: 10 }}>
            <Text style={pdfStyles.label}>{PAYEE_LABELS.PUBISHER_TO}</Text>
          </View>
          <View style={{ ...pdfStyles.rowEnd, marginBottom: 5 }}>
            <Text style={pdfStyles.boldText}>{payeeInfo.invoiceTo}</Text>
          </View>
          <View style={pdfStyles.rowEnd}>
            <Text style={pdfStyles.text}>
              {address.address1}
              ,
            </Text>
          </View>
          <View style={pdfStyles.rowEnd}>
            <Text style={pdfStyles.text}>
              {address.city}
              ,
              {address.state}
              ,
            </Text>
          </View>
          <View style={pdfStyles.rowEnd}>
            <Text style={pdfStyles.text}>
              {address.country}
              ,
              {address.zip}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const createTotalDue = (totalDue: string) => (
    <>
      <View style={{ ...pdfStyles.rowSpaceBetweenHalf, marginTop: 35 }}>
        <Text style={pdfStyles.label}>{LABELS.TOTAL}</Text>
        <Text style={pdfStyles.totalDue}>{formateDollarAmount(parseFloat(totalDue))}</Text>
      </View>
      <Text style={pdfStyles.textSmallItalic}>
        {PAYEE_DEFAULTS.DISCLAIMER}
      </Text>
      <Text style={pdfStyles.textSmallItalic}>{PAYEE_DEFAULTS.DISCLAIMER_TWO}</Text>
    </>
  );

  const createBankingInfo = (mailingAddress: AddressPropTypes, bankInfo: PDFBankInfo) => (
    <View>
      <Text style={{ ...pdfStyles.label, marginTop: 30, marginBottom: 10 }}>
        {LABELS.BANK}
      </Text>
      <View style={pdfStyles.rowSpaceBetween}>
        <View style={{ ...pdfStyles.rowSpaceBetween50, paddingRight: 10 }}>
          <View style={pdfStyles.column}>
            <Text style={pdfStyles.textSmallBold}>{BANKING_DEFAULTS.WIRE_TITLE}</Text>
            <Text style={pdfStyles.textSmallItalic}>
              {BANKING_DEFAULTS.WIRE_DESCRIPTION}
            </Text>
            <View style={pdfStyles.rowStart}>
              <View style={pdfStyles.row50Start}>
                <Text style={pdfStyles.textSmall}>{BANKING_LABEL.BANK}</Text>
              </View>
              <View style={pdfStyles.rowStart}>
                <Text style={pdfStyles.textSmall}>
                  {bankInfo.BANK}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.rowStart}>
              <View style={pdfStyles.row50Start}>
                <Text style={{ ...pdfStyles.textSmall, width: 70 }}>
                  {BANKING_LABEL.BANK_ADDRESS}
                </Text>
              </View>
              <View style={pdfStyles.rowStart}>
                <Text style={{ ...pdfStyles.textSmall, width: 150 }}>
                  {bankInfo.BANK_ADDRESS}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.rowStart}>
              <View style={pdfStyles.row50Start}>
                <Text style={{ ...pdfStyles.textSmall, width: 70 }}>{BANKING_LABEL.TRANSIT}</Text>
              </View>
              <View style={pdfStyles.rowStart}>
                <Text style={{ ...pdfStyles.textSmall, width: 150 }}>{bankInfo.TRANSIT}</Text>
              </View>
            </View>
            <View style={pdfStyles.rowStart}>
              <View style={pdfStyles.row50Start}>
                <Text style={{ ...pdfStyles.textSmall, width: 70 }}>{BANKING_LABEL.INSTITUTION}</Text>
              </View>
              <View style={pdfStyles.rowStart}>
                <Text style={{ ...pdfStyles.textSmall, width: 150 }}>{bankInfo.INSTITUTION}</Text>
              </View>
            </View>
            <View style={pdfStyles.rowStart}>
              <View style={pdfStyles.row50Start}>
                <Text style={{ ...pdfStyles.textSmall, width: 70 }}>{BANKING_LABEL.ACCOUNT}</Text>
              </View>
              <View style={pdfStyles.rowStart}>
                <Text style={{ ...pdfStyles.textSmall, width: 150 }}>{bankInfo.ACCOUNT}</Text>
              </View>
            </View>
            <View style={pdfStyles.rowStart}>
              <View style={pdfStyles.row50Start}>
                <Text style={{ ...pdfStyles.textSmall, width: 70 }}>{BANKING_LABEL.SWIFT}</Text>
              </View>
              <View style={pdfStyles.rowStart}>
                <Text style={{ ...pdfStyles.textSmall, width: 150 }}>{bankInfo.SWIFT}</Text>
              </View>
            </View>
            <View style={pdfStyles.rowStart}>
              <View style={pdfStyles.row50Start}>
                <Text style={{ ...pdfStyles.textSmall, width: 70 }}>{BANKING_LABEL.BENEFICIARY}</Text>
              </View>
              <View style={pdfStyles.rowStart}>
                <Text style={{ ...pdfStyles.textSmall, width: 150 }}>
                  {ADDRESS.name}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.rowStart}>
              <View style={pdfStyles.row50Start}>
                <Text style={{ ...pdfStyles.textSmall, width: 70 }}>{BANKING_LABEL.BENEFICIARY_ADDRESS}</Text>
              </View>
              <View style={pdfStyles.rowStart}>
                <Text style={{ ...pdfStyles.textSmall, width: 150 }}>
                  {mailingAddress.address1}
                  ,
                  {' '}
                  {mailingAddress.city}
                  ,
                  {' '}
                  {mailingAddress.stateShort || mailingAddress.state}
                  ,
                  {' '}
                  {mailingAddress.country}
                  ,
                  {' '}
                  {mailingAddress.zip}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ ...pdfStyles.rowSpaceBetween50, paddingLeft: 10 }}>
          <View style={pdfStyles.column}>
            <Text style={pdfStyles.textSmallBold}>{BANKING_DEFAULTS.CHEQUE}</Text>
            <View style={pdfStyles.rowStart}>
              <View style={pdfStyles.row50Start}>
                <Text style={{ ...pdfStyles.textSmall, width: 70 }}>{BANKING_LABEL.PAYABLE}</Text>
              </View>
              <View style={pdfStyles.rowStart}>
                <Text style={{ ...pdfStyles.textSmall, width: 150 }}>
                  {ADDRESS.name}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.rowStart}>
              <View style={pdfStyles.row50Start}>
                <Text style={{ ...pdfStyles.textSmall, width: 70 }}>{BANKING_LABEL.MAILING_ADDRESS}</Text>
              </View>
              <View style={pdfStyles.rowStart}>
                <Text style={{ ...pdfStyles.textSmall, width: 150 }}>
                  {mailingAddress.address1}
                  ,
                  {' '}
                  {mailingAddress.city}
                  ,
                  {' '}
                  {mailingAddress.stateShort || mailingAddress.state}
                  ,
                  {' '}
                  {mailingAddress.country}
                  ,
                  {' '}
                  {mailingAddress.zip}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  const createPageFooter = () => (
    <View style={pdfStyles.columnEnd}>
      <View style={pdfStyles.rowSpaceBetween}>
        <Text style={pdfStyles.label}>{FOOTER_DEFAULTS.LEFT_MESSAGE}</Text>
        <Text style={{ ...pdfStyles.textSmall, marginLeft: 30, marginTop: 2 }}>
          {FOOTER_DEFAULTS.RIGHT_MESSAGE}
        </Text>
      </View>
    </View>
  );

  const createTableProductCategory = (pdfData: any, hasProductCategory: boolean) => (
    <View>
      <View style={{ ...pdfStyles.rowSpaceBetween, marginBottom: 10 }}>

        {PDF_TABLE_HEADER.map((header) => (
          <View
            style={{ width: header.width }}
            key={header.name}
          >
            <Text style={pdfStyles.label}>{header.name}</Text>
          </View>
        ))}
      </View>

      {PDF_TABLE_KEYS.map((tableKey: string) => (
        <View key={`keys-${tableKey}`}>
          {pdfData[tableKey]?.length > 0 && (
            <View>
              {hasProductCategory && pdfData[tableKey][0].count > 0 && (
                <Text style={{ ...pdfStyles.boldText, ...pdfStyles.borderBottom }}>
                  {PDF_CATEGORY_SUBTILE[tableKey]}
                </Text>
              )}
              {pdfData[tableKey].map((row: any) => (
                <React.Fragment
                  key={`${tableKey}-${PDF_CATEGORY_SUBTILE[tableKey]}`}
                >
                  {row.count && row.count > 0 && (
                    <View
                      style={{ ...pdfStyles.rowSpaceBetween, marginTop: 10 }}
                    >
                      <View style={{ width: '25%' }}>
                        <Text style={pdfStyles.text}>{hasProductCategory ? row.publisher : PDF_CATEGORY_SUBTILE[tableKey]}</Text>
                      </View>
                      <View style={{ width: '10%' }}>
                        <Text style={pdfStyles.text}>{row.count}</Text>
                      </View>
                      <View style={{ width: '15%' }}>
                        <Text style={pdfStyles.text}>
                          {formateDollarAmount(parseFloat(row.amount))}
                        </Text>
                      </View>
                      <View style={{ width: '15%' }}>
                        <Text style={pdfStyles.text}>
                          {formateDollarAmount(parseFloat(row.tax))}
                        </Text>
                      </View>
                      <View style={{ width: '20%' }}>
                        <Text style={pdfStyles.text}>
                          {formateDollarAmount(parseFloat(row.systemUsageFee))}
                        </Text>
                      </View>
                      <View style={{ width: '10%' }}>
                        <Text style={pdfStyles.text}>
                          {formateDollarAmount(parseFloat(row.total))}
                        </Text>
                      </View>
                    </View>
                  )}
                </React.Fragment>
              ))}
            </View>

          )}
        </View>
      ))}

      {hasProductCategory && (
        <>
          <View style={{ ...pdfStyles.rowSpaceBetween, marginTop: 10 }}>
            <View style={{ width: '25%' }}>
              <Text style={pdfStyles.boldText}>{PDF_CATEGORY_SUBTILE.managementFee}</Text>
            </View>
            <View style={{ width: '10%' }}>
              <Text style={pdfStyles.text}>
                {formateDollarAmount(parseFloat(pdfData.managementFee[0]?.total))}
              </Text>
            </View>
          </View>
          <View style={{ ...pdfStyles.rowSpaceBetween, marginTop: 10 }}>
            <View style={{ width: '25%' }}>
              <Text style={pdfStyles.boldText}>{PDF_CATEGORY_SUBTILE.manualFee}</Text>
            </View>
            <View style={{ width: '10%' }}>
              <Text style={pdfStyles.text}>
                {formateDollarAmount(parseFloat(pdfData.manualFee[0]?.total))}
              </Text>
            </View>
          </View>
        </>
      )}
    </View>
  );

  const createPubisherTable = (pdfData: any, billPeriod: string, total: {
    subTotal: string,
    taxes: string,
    total: string,
  }) => (
    <>
      <View style={{ ...pdfStyles.rowSpaceBetween, marginTop: 40 }}>
        <Text style={pdfStyles.label}>DESCRIPTION</Text>
        <View style={pdfStyles.rowSpaceBetweenHalf}>
          <Text style={pdfStyles.label}>QUANTITY</Text>
          <Text style={pdfStyles.label}>AMOUNT</Text>
        </View>
      </View>

      <View>
        {PDF_TABLE_KEYS.map((tableKey: string) => (
          <React.Fragment key={`pubisher-key-${tableKey}`}>
            {pdfData[tableKey] && pdfData[tableKey].map((row: any) => (
              <React.Fragment key={`${tableKey}-${row.amount}`}>
                {row.count > 0 && (
                  <View
                    style={{ ...pdfStyles.rowSpaceBetween, ...pdfStyles.border }}

                  >
                    <Text style={pdfStyles.textInTable}>
                      {createLabel(tableKey, billPeriod)}
                    </Text>
                    <View style={pdfStyles.rowSpaceBetweenHalf}>
                      <Text style={pdfStyles.textInTable}>{row.count}</Text>
                      <Text style={pdfStyles.textInTable}>{formateDollarAmount(parseFloat(row.amount))}</Text>
                    </View>
                  </View>
                )}
              </React.Fragment>
            ))}
            <div />
          </React.Fragment>
        ))}
      </View>

      <View style={{ ...pdfStyles.rowSpaceBetween, ...pdfStyles.borderHeavy }}>
        <Text style={pdfStyles.textInTable} />
        <View style={pdfStyles.rowSpaceBetweenHalf}>
          <Text style={pdfStyles.textInTable}>Sub Total</Text>
          <Text style={pdfStyles.textInTable}>{formateDollarAmount(parseFloat(total.subTotal))}</Text>
        </View>
      </View>
      <View style={pdfStyles.rowSpaceBetween}>
        <Text style={pdfStyles.textInTable} />
        <View style={{ ...pdfStyles.rowSpaceBetweenHalf, ...pdfStyles.border }}>
          <Text style={pdfStyles.textInTable}>Taxes</Text>
          <Text style={pdfStyles.textInTable}>{formateDollarAmount(parseFloat(total.taxes))}</Text>
        </View>
      </View>
      <View style={pdfStyles.rowSpaceBetween}>
        <Text style={pdfStyles.textInTable} />
        <View style={{ ...pdfStyles.rowSpaceBetweenHalf, ...pdfStyles.borderAround }}>
          <Text style={pdfStyles.boldText}>Total</Text>
          <Text style={pdfStyles.boldText}>
            {formateDollarAmount(parseFloat(total.total))}
          </Text>
        </View>
      </View>
    </>
  );

  const createMerchantFooter = (payeeInfo: PDFPayeeInfo) => (
    <View style={pdfStyles.columnEnd}>
      <View style={{ ...pdfStyles.rowSpaceBetween, ...pdfStyles.borderHeavy, paddingTop: 10 }}>
        <View style={pdfStyles.row50Start} />
        <View style={{ ...pdfStyles.rowSpaceBetween, width: '40%' }}>
          <Text style={pdfStyles.boldText}>{FOOTER_LABELS.TOTAL}</Text>
          <Text style={pdfStyles.boldText}>
            {formateDollarAmount(parseFloat(payeeInfo.totalDue))}
          </Text>
        </View>
      </View>
      <View style={{ ...pdfStyles.rowSpaceBetween, marginTop: 5 }}>
        <View style={pdfStyles.row50Start} />
        <View style={{ ...pdfStyles.rowSpaceBetween, width: '40%' }}>
          <Text style={pdfStyles.text}>{FOOTER_LABELS.PAYMENT}</Text>
          <Text style={pdfStyles.text}>
            {formateDollarAmount(parseFloat(payeeInfo.payment || '0'))}
          </Text>
        </View>
      </View>
      <View style={{ ...pdfStyles.rowSpaceBetween, marginTop: 5 }}>
        <View style={pdfStyles.row50Start} />
        <View style={{ ...pdfStyles.rowSpaceBetween, width: '40%' }}>
          <Text style={pdfStyles.text}>{FOOTER_LABELS.BALANCE}</Text>
          <Text style={pdfStyles.text}>
            {formateDollarAmount(parseFloat(payeeInfo.balance || '0'))}
          </Text>
        </View>
      </View>
    </View>
  );

  const createPublisherFooter = (payeeInfo: PDFPayeeInfo, rate: string) => (
    <View style={pdfStyles.columnEnd}>
      <View style={pdfStyles.rowSpaceBetween}>
        <Text style={pdfStyles.label}>PAYMENT METHOD</Text>
        <View style={pdfStyles.rowSpaceBetween60}>
          <Text style={pdfStyles.label}>DUE DATE</Text>
          <Text style={pdfStyles.label}>TOTAL DUE</Text>
        </View>
      </View>
      <View style={{ ...pdfStyles.rowSpaceBetween, ...pdfStyles.borderFinal }}>
        <Text style={pdfStyles.textInTable}>{payeeInfo.paymentMethod}</Text>
        <View style={pdfStyles.rowSpaceBetween60}>
          <Text style={pdfStyles.textInTable}>{dateFormatter(new Date(payeeInfo.dueDate), ',', false, true)}</Text>
          <Text style={pdfStyles.totalDue}>{formateDollarAmount(parseFloat(payeeInfo.totalDue) * Number(rate))}</Text>
        </View>
      </View>
    </View>
  );
  const createDocment = (
    title: string,
    PDFHeaderInfo: PDFHeaderInfo,
    payeeInfo: PDFPayeeInfo,
    bankInfo: PDFBankInfo,
    PDFTableData: any,
    hasProductCategory: boolean,
    rate: string,
  ) => (
    <Document title={title}>
      <Page
        size="LETTER"
        style={pdfStyles.page}
      >
        {createCommonHeader(PDFHeaderInfo)}
        {isMerchantPdf ? createPayeeSection(payeeInfo) : createPubisherPayeeSection(payeeInfo)}

        {isMerchantPdf && (
          <>
            {createTotalDue(payeeInfo.totalDue)}
            {createBankingInfo(PDFHeaderInfo.address, bankInfo)}
            {createPageFooter()}
          </>
        )}

        {!isMerchantPdf && (
          <>
            {createPubisherTable(
              PDFTableData,
              payeeInfo.year && payeeInfo.month ? dateFormatter(new Date(parseInt(payeeInfo.year, 10), parseInt(payeeInfo.month, 10), 0), '-', true) : '',
              {
                subTotal: (Number(payeeInfo.subTotal) * Number(rate)).toString() || '$0.00',
                taxes: (Number(payeeInfo.taxes) * Number(rate)).toString() || '$0.00',
                total: (Number(payeeInfo.totalDue) * Number(rate)).toString() || '$0.00',
              },
            )}
            {createPublisherFooter(payeeInfo, rate)}
          </>
        )}
      </Page>

      {isMerchantPdf && (
        <Page
          size="LETTER"
          style={pdfStyles.page}
        >
          {createCommonHeader(PDFHeaderInfo)}
          {createTableProductCategory(PDFTableData, hasProductCategory)}
          {createMerchantFooter(payeeInfo)}
        </Page>
      )}
    </Document>
  );

  return {
    hookCommonHeader: createCommonHeader,
    hookCreateDocment: createDocment,
  };
};
