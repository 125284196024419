import { gql } from '@apollo/client';
import { FeedbackStatusType } from '../../Modal/reducers';

export const UPDATE_FINTEL_CHECK_SUMMARIES_FEEDBACK = gql`
  mutation updateFintelCheckSummaries($input: FintelCheckSummaryInput!) {
    updateFintelCheckSummaries(input: $input) {
      id
      feedback {
        correct
        currStatus
        inputType
        inputVal
        issue
        shouldBeStatus
      }
    }
  }
`;

export type UpdateFintelCheckSummariesInput = {
  input: {
    id: string,
    feedback: {
      correct: 'No' | 'Yes' | null
      currStatus: FeedbackStatusType | null,
      inputType: '',
      inputVal: '',
      issue: null,
      shouldBeStatus: FeedbackStatusType | null | ''
    }
  }
};

export type UpdateFintelCheckSummariesOutput = {
  updateFintelCheckSummaries: {
    id: string
    feedback: {
      correct: string | null
      currStatus: string | null
      inputType: string | null
      inputVal: string | null
      issue: string | null
      shouldBeStatus: string | null
    }
  }
}
