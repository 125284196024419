import { defaultTableTotals } from '../enums';
import { TMTotals } from '../types';

export const processTableTotals = (unprocessedTableTotals: TMTotals[]) => {
  const CADindex = unprocessedTableTotals.findIndex((row) => row.currency === 'CAD');
  const USDindex = unprocessedTableTotals.findIndex((row) => row.currency === 'USD');
  const tableTotals = structuredClone(defaultTableTotals);
  if (CADindex !== -1) {
    tableTotals.totalCommission[0] = Number(unprocessedTableTotals[CADindex].totalCommission);
    tableTotals.totalGrossSale[0] = Number(unprocessedTableTotals[CADindex].totalGrossSale);
    tableTotals.totalNetSale[0] = Number(unprocessedTableTotals[CADindex].totalNetSale);
  }
  if (USDindex !== -1) {
    tableTotals.totalCommission[1] = Number(unprocessedTableTotals[USDindex].totalCommission);
    tableTotals.totalGrossSale[1] = Number(unprocessedTableTotals[USDindex].totalGrossSale);
    tableTotals.totalNetSale[1] = Number(unprocessedTableTotals[USDindex].totalNetSale);
  }
  return tableTotals;
};
