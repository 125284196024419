import { gql } from '@apollo/client';

export const AD_OPTIONS = gql`
  query GetAdOptions($input: AdOptionsInput) {
    getAdOptions(input: $input) {
      merchant {
        companyUrl
      }
      adStatusTypes {
        type
      }
      products {
        id
        name
      }
      languages {
        language
      }
      campaigns {
        id
        name
      }
      tags {
        name
        id
      }
    }
  }
`;
