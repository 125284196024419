import * as utils from '../../../../../utils';

export const validationfields = {
  paymentAmount: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT_REQUIRE,
  paymentDate: utils.ERROR_TYPES.NOT_EMPTY,
  paymentEmail: utils.ERROR_TYPES.EMAIL,
  payersName: utils.ERROR_TYPES.NOT_EMPTY,
  productCategory: utils.ERROR_TYPES.NOT_EMPTY,
};

export const validationDepositfields = {
  amount: utils.ERROR_TYPES.IS_DOLLAR_AMOUNT_REQUIRE,
  productCategory: utils.ERROR_TYPES.NOT_EMPTY,
  currency: utils.ERROR_TYPES.NOT_EMPTY,
};
