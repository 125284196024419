import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal, useToast, useUserInfo } from '../../../../../hooks';
import {
  COMMISSION_TYPES,
  MERCHANT_PREFIX, defaultOption, path, toUTCHours,
} from '../../../../../utils';
import {
  LIST_ACTIVE_MERCHANT_COMMISSIONS, ListActiveMerchantCommissionsInput, ListActiveMerchantCommissionsOutput,
} from '../graphql/queries';
import { commissionsFormatter, CommissionFormatted } from '../../../../../utils/formatCommissions';
import { Permission } from '../../../../../entities';
import { DELETE_COMMISSION } from '../graphql/mutations';
import { ERROR } from '../utils';
import { GET_PRODUCTS } from '../../NewCommission/graphql/queries';
import {
  GET_MEMBERSHIPS, GET_PUBLISHER_GROUPS, GetMembershipsInput, GetMembershipsOutput, GetPublisherGroupsInput, GetPublisherGroupsOutput,
} from '../../EditCommissions/graphql/queries';

type TProduct = {
  id: string, name: string, status: string, productCategory: string
}

export const useManageCommissions = (permissionsCodeList: string[] = []) => {
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();
  const toast = useToast();

  const [addModal, setAddModal] = useModal(false);

  const [pageloading, setPageLoading] = useState(false);
  const [commissionsData, setCommissionsData] = useState<CommissionFormatted[]>([]);
  const [staticCommissionsData, setStaticCommissionsData] = useState<CommissionFormatted[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [records, setRecords] = useState({ label: '10', value: '10' });
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [refetchPage, setRefetchPage] = useState<boolean>(false);

  const [selectedCommission, setSelectedCommission] = useState<CommissionFormatted>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  const [getMerchantCommissionStructure, { loading: getMerchantCommissionStructureLoading }] = useLazyQuery<ListActiveMerchantCommissionsOutput, ListActiveMerchantCommissionsInput>(LIST_ACTIVE_MERCHANT_COMMISSIONS);
  const [deleteCommissionMutation] = useMutation(DELETE_COMMISSION, {});
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'createdAt', direction: 'asc' });

  const [selectedDate, setSelectedDate] = useState<Date>();
  const [calendarIsOpen, setCalendarIsOpen] = useState<boolean>(false);

  const defaultProduct = {
    id: '', name: defaultOption.product.label, status: '', productCategory: defaultOption.productCategory.label,
  };
  const [productCategory, setProductCategory] = useState<SelectOption>(defaultOption.productCategory);
  const [productList, setProductList] = useState<TProduct[]>([defaultProduct]);
  const [staticProductList, setStaticProductList] = useState<TProduct[]>([defaultProduct]);
  const [selectedProduct, setSelectedProduct] = useState<SelectOption>(defaultOption.product);

  const [publisherMembers, setPublisherMembers] = useState<SelectOption[]>([defaultOption.publisher]);
  const [selectedPublisherMember, setSelectedPublisherMember] = useState<SelectOption>(defaultOption.publisher);

  const [publisherGroups, setPublisherGroups] = useState<SelectOption[]>([defaultOption.publisherGroup]);
  const [selectedPublisherGroup, setSelectedPublisherGroup] = useState<SelectOption>(defaultOption.publisherGroup);

  /* Only show CPA & RevShare commission types as per FC-379 */
  const [commissionTypes] = useState<SelectOption[]>([defaultOption.commissionType, ...Object.entries(COMMISSION_TYPES).filter((ct) => ['REVSHARE', 'CPA'].includes(ct[0])).map(([key, value]) => ({
    label: value,
    value: key,
  }))]);
  const [selectedCommissionType, setSelectedCommissionType] = useState<SelectOption>(defaultOption.commissionType);

  const [getProducts, { loading: getProductsLoading }] = useLazyQuery(GET_PRODUCTS);
  const [getMemberships, { loading: getMembershipsLoading }] = useLazyQuery<GetMembershipsOutput, GetMembershipsInput>(GET_MEMBERSHIPS);
  const [getPublisherGroups, { loading: getPublisherGroupsLoading }] = useLazyQuery<GetPublisherGroupsOutput, GetPublisherGroupsInput>(GET_PUBLISHER_GROUPS);

  /**
   * Reset the page to its initial state, including the commisions data
   */
  const resetPage = () => {
    setCurrentPage(1);
    setTotalPages(0);
    setErrorMessage('');
    setCommissionsData([]);
    setSelectedCommission(undefined);
  };

  const setRecordsHandler = (value: any) => {
    setRecords({
      label: value.value,
      value: value.value,
    });
  };

  /**
   * Set the current page and refetch the data
   * @param chosenPage Page number selected
   */
  const setCurrentPageHandler = (chosenPage: number) => {
    setCurrentPage(chosenPage);
    setRefetchPage(true);
  };

  /**
   * Fetch the list of commissions
   * Include filters for date, product, publisher, publisher group, and commission type
   */
  const getMerchantCommissionStructureHandler = async (isFilter = false) => {
    setErrorMessage('');
    if (!hookWhoAmI.programId) {
      return;
    }
    setPageLoading(true);
    const { data } = await getMerchantCommissionStructure({
      variables: {
        input: {
          filters: {
            merchantId: hookWhoAmI.companyId?.toString() || '0',
            commissionType: selectedCommissionType?.label === defaultOption.commissionType.label ? undefined : selectedCommissionType?.label,
          },
          targetDate: selectedDate || toUTCHours(new Date(), 'beginning'),
          productId: selectedProduct !== defaultOption.product ? selectedProduct?.value : undefined,
          productCategory: productCategory.label,
          publisherGroupId: selectedPublisherGroup?.value === defaultOption.publisherGroup.value ? undefined : selectedPublisherGroup?.value,
          publisherId: selectedPublisherMember?.value === defaultOption.publisher.value ? undefined : selectedPublisherMember?.value,
          limit: Number(records.value),
          currentPage: isFilter ? 1 : currentPage,
          sortBy: sortColumn.column,
          sortDirection: sortColumn.direction,
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setPageLoading(false);
        resetPage();
        setErrorMessage(err.message);
        /* If filters returned error, rollback to previous saved data */
        if (staticCommissionsData.length > 0) {
          setCommissionsData(staticCommissionsData);
        }
      },
    });
    setPageLoading(false);
    if (data && data.getCommissionsNew) {
      setTotalPages(data.getCommissionsNew.totalPages || 0);
      const list = data.getCommissionsNew.commissions;
      const formattedList = commissionsFormatter(list, false);
      setCommissionsData(formattedList);
      setStaticCommissionsData(formattedList);
      setTotalRecords(data.getCommissionsNew.count || 0);
    }
  };

  /**
   * Get the list of publishers
   */
  const getMembershipListHandler = async () => {
    const { data, error } = await getMemberships({
      variables: {
        input: {
          merchantId: hookWhoAmI.companyId?.toString() || '0',
          status: 'Approved',
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (error) {
      setErrorMessage(error.message);
      return;
    }
    if (data && data.memberships) {
      const publisherList = data.memberships.memberships.map((m) => m.publisher);
      setPublisherMembers([defaultOption.publisher, ...publisherList.map((p: { id: string; companyName: string; }) => ({
        label: `${p.id} - ${p.companyName}`,
        value: p.id,
      }))]);
    }
  };

  /**
   * Get the list of publisher groups
   */
  const getPublisherGroupsHandler = async () => {
    const { data, error } = await getPublisherGroups({
      variables: {
        programId: hookWhoAmI.programId || '0',
      },
      fetchPolicy: 'no-cache',
    });
    if (error) {
      setErrorMessage(error.message);
      return;
    }
    if (data && data.groups) {
      setPublisherGroups([defaultOption.publisherGroup, ...data.groups.groups.map((p: { id: string; name: string; }) => ({
        label: p.name,
        value: p.id,
      }))]);
    }
  };

  /**
   * Get the list of products
   */
  const getProductListHandler = async () => {
    const { data, error } = await getProducts({ variables: { programId: hookWhoAmI.programId }, fetchPolicy: 'no-cache' });
    if (error) {
      setErrorMessage(error.message);
      return;
    }
    if (data.products) {
      const { products } = data.products;
      setStaticProductList((oldValues) => [...oldValues, ...products]);
      setProductList((oldValues) => [...oldValues, ...products]);
    }
  };

  /**
   * When the user selects a product category,
   * set the product list to the products in that category
   * @param e Category selected
   */
  const onProductCategoryChange = (e: SelectOption) => {
    setProductCategory(e);
    if (e.label === 'All Categories') {
      setProductList(staticProductList);
      return;
    }
    setProductList([defaultProduct, ...staticProductList.filter((p) => p.productCategory === e.label)]);
  };

  const onProductChange = (e: SelectOption) => {
    setSelectedProduct(e);
  };

  /**
   * This function is called when the user clicks on a row in the table
   * @param {CommissionFormatted} rowData Commission clicked
   */
  const onRowClick = (rowData: CommissionFormatted) => {
    navigate(`${MERCHANT_PREFIX}${path.editCommissions.href}`, {
      state: rowData.id,
    });
  };

  const onFiltersChangeHandler = async () => {
    setCurrentPage(1);
    await getMerchantCommissionStructureHandler(true);
  };

  /**
   * Clear all filters
   */
  const onClearFormClick = () => {
    setSelectedDate(undefined);
    setSelectedProduct(defaultOption.product);
    setSelectedCommissionType(defaultOption.commissionType);
    setSelectedPublisherGroup(defaultOption.publisherGroup);
    setSelectedPublisherMember(defaultOption.publisher);
    setProductCategory(defaultOption.productCategory);
    setCommissionsData(staticCommissionsData);
  };

  /**
   * If any of the filters change, refetch the data
   */
  useEffect(() => {
    onFiltersChangeHandler();
  }, [records, productCategory, selectedDate, selectedProduct, selectedPublisherGroup, selectedPublisherMember, selectedCommissionType]);

  /**
   * This function is called when the delete confirmation modal is confirmed
   * It calls the deleteCommissionMutation and shows a toast message if successful
   * If unsuccessful, it shows an error message
   * @returns {Promise<void>}
   */
  const deleteCommission = async () => {
    if (!selectedCommission) {
      setShowDeleteConfirmation(false);
      setSelectedCommission(undefined);
      setErrorMessage(ERROR.FAILED_TO_DELETE);
      return;
    }
    const { data, errors } = await deleteCommissionMutation({
      variables: {
        id: selectedCommission.idNum,
      },
      fetchPolicy: 'no-cache',
    });
    if (errors) {
      setErrorMessage(ERROR.FAILED_TO_DELETE);
      setShowDeleteConfirmation(false);
      setSelectedCommission(undefined);
      return;
    }
    if (data.deleteCommission) {
      setShowDeleteConfirmation(false);
      toast.hookShowToast(`Commission ${selectedCommission.id} deleted successfully.`);
      resetPage();
      getMerchantCommissionStructureHandler();
    }
  };

  /**
   * This function is called when the delete button is clicked
   * It sets the selectedCommission state and shows the delete confirmation modal
   * @param {CommissionFormatted} itemToBeDeleted state representing the commission to be deleted
   */
  const deleteCommissionHandler = (itemToBeDeleted: CommissionFormatted) => {
    setSelectedCommission(itemToBeDeleted);
    setShowDeleteConfirmation(true);
  };

  if (refetchPage) {
    setRefetchPage(false);
    getMerchantCommissionStructureHandler();
  }

  /**
   * Load inital page data
   */
  const loadPageData = () => {
    resetPage();
    getProductListHandler();
    getMembershipListHandler();
    getPublisherGroupsHandler();
    getMerchantCommissionStructureHandler();
  };

  const onSortHandler = (dataField: string, direction: any) => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
    setRefetchPage(true);
  };

  useEffect(() => {
    loadPageData();
  }, [window.location.href]);

  const setCalendarIsOpenHandler = () => {
    setCalendarIsOpen(!calendarIsOpen);
  };

  const onApplySelectedCalendarHandler = (start: Date) => {
    setSelectedDate(start);
    setCurrentPage(1);
    setCalendarIsOpen(false);
  };

  const onCancelSelectedCalendarHandler = () => {
    setCalendarIsOpen(false);
  };

  return {
    hookCommissionsData: commissionsData,
    hookSetCommissionsData: setCommissionsData,

    hookAddModal: addModal,
    hookSetAddModal: setAddModal,

    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookSetCurrentPage: setCurrentPageHandler,
    hookOnRowClick: onRowClick,

    hookIsLoading: getMerchantCommissionStructureLoading || pageloading,
    hookSetIsLoading: setPageLoading,
    hookErrorMessage: errorMessage,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
    deleteCommission,
    deleteCommissionHandler,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    selectedCommission,

    selectedDate,
    calendarIsOpen,
    openCalendar: setCalendarIsOpenHandler,
    onApplySelectedCalendar: onApplySelectedCalendarHandler,
    onCancelSelectedCalendar: onCancelSelectedCalendarHandler,

    productCategory,
    setProductCategory,
    onProductCategoryChange,

    productList,
    selectedProduct,
    setSelectedProduct,
    onProductChange,
    getProductsLoading,

    publisherMembers,
    selectedPublisherMember,
    setSelectedPublisherMember,
    getMembershipsLoading,

    publisherGroups,
    selectedPublisherGroup,
    setSelectedPublisherGroup,
    getPublisherGroupsLoading,

    commissionTypes,
    selectedCommissionType,
    setSelectedCommissionType,

    onClearFormClick,

    records,
    setRecords: setRecordsHandler,
    totalRecords,
    setTotalRecords,

    sortColumn,
    onSortHandler,
  };
};
