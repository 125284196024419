import { gql } from '@apollo/client';

export const LIST_DEMOGRAPHIC_PRIMARY_AUDIENCE_MARKET = gql`
  query List {
    listDemographicPrimaryAudienceMarket {
      list {
        id
        region
      }
    }
  }
`;
