import { gql } from '@apollo/client';

export const GET_TRACKING_DOMAINS = gql`
  query trackingDomains($input: TrackingDomainInput) {
    trackingDomains(input: $input) {
      domains {
        id
        trackingProfileId
        trackingDomainUrl
        firstActiveDate
        lastActiveDate
      }
      count
    }
  }
`;
