import styled from 'styled-components';
import { colors } from '../../../../../../styles/theme';

export const MapWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const MapStyled = styled.div`
  width: 100%;
  height: 600px;
  background-color: ${colors.transparent};
  z-index: 999;
`;
