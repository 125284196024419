import styled from 'styled-components';
import { Button } from '../../../Button';
import { Select } from '../../../Input/Select';
import { InputText } from '../../../Input/Text';
import { fonts, colors } from '../../../../styles/theme';
import { toRem } from '../../../../utils';
import { InputPhone } from '../../../Input';

type GridProps = {
  readonly grids: number;
}

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
  row-gap: 2.5rem;
  margin-top: 2rem;
`;

export const InputTextStyled = styled(InputText)<GridProps>`
  grid-column: span ${({ grids }) => grids};
`;

export const SelectStyled = styled(Select)<GridProps>`
  grid-column: span ${({ grids }) => grids};
`;

export const ButtonStyled = styled(Button)`
  grid-column: span 1;
  align-self: flex-end;
`;

export const ButtonWrapper = styled.div`
  margin-top: ${toRem(21)};
  max-height: ${toRem(38)};
  display: flex;
  flex-direction: column;
  color: ${colors.color42};
  gap: ${toRem(4)};
  align-items: flex-start;
  justify-content: flex-start;
`;

export const PasswordInputWrapper = styled.div`
  max-height: ${toRem(38)};
  display: flex;
  flex-direction: column;
  color: ${colors.color42};
  gap: ${toRem(4)};
  align-items: flex-start;
  justify-content: flex-start;
  ${fonts.lato.H12}
`;

export const InputPhoneStyled = styled(InputPhone)<GridProps>`
  grid-column: span ${({ grids }) => grids};
`;
