import React from 'react';
import {
  faArrowRightArrowLeft, faChevronRight, faFile,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Calendar, InputCalendar, PageTitle, Spinner, Table, Tabs, Tooltip,
} from '../../../components';
import { colors } from '../../../styles/theme';
import {
  ClickByDevice, LinkCard, StatusCard, Snapshot,
} from './components';
import { CanadaTab, UsaTab, WorldTab } from './Tabs';
import { MERCHANT_PREFIX, path } from '../../../utils';
import { useDashboard } from './hooks';
import {
  CLICKS_BY_DEVICE,
  PRODUCT_BY_APPROVALS,
  SNAPSHOT, STATISTIC_CARD, STATUS_CARD, TOP_PRODUCTS, TOP_PUBLISHERS, TRAFFIC_ANALYTICS,
} from './enums';
import { topProductColumns, topPublisherColumns } from './contracts';
import { StatisticCard } from '../../../components/StatisticCard';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type MerchantDashboardProps = DefaultPropsType;

const MerchantDashboard = ({ permissionsCodeList = [] }: MerchantDashboardProps) => {
  const navigate = useNavigate();
  const hook = useDashboard();

  return (
    <Styled.AppWrapperStyled permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <Styled.MerchantWrapperStyled>
          <PageTitle>
            Welcome,
            {' '}
            {hook.hookMerchantName}
          </PageTitle>
          <Styled.AccountStatusStyled status={hook.hookMerchantStatus}>
            {hook.hookMerchantStatus}
          </Styled.AccountStatusStyled>
        </Styled.MerchantWrapperStyled>

        <InputCalendar
          value={`${hook.hookStartDate.toDateString()} - ${hook.hookEndDate.toDateString()}`}
          width="350px"
          onClick={() => hook.hookOpenCalendar()}
        />
      </Styled.HeaderStyled>

      <Styled.StatisticWrapperStyled>
        <StatisticCard
          title={hook.hookLoadingProgram ? 'Loading...' : hook.hookCurrency}
          tooltip={STATISTIC_CARD.COMMISSIONS.TOOLTIP}
          statistic={hook.hookCommissions}
          loading={hook.hookLoadingMerchantDashboardThree}
          currency="$"
        />
        <StatisticCard
          title={STATISTIC_CARD.APPROVED_TRANSACTIONS.TITLE}
          tooltip={STATISTIC_CARD.APPROVED_TRANSACTIONS.TOOLTIP}
          statistic={hook.hookApprovedTransactions}
          loading={hook.hookLoadingMerchantDashboardThree}
        />
        <StatisticCard
          title={STATISTIC_CARD.CONVERSION_RATE.TITLE}
          tooltip={STATISTIC_CARD.CONVERSION_RATE.TOOLTIP}
          statistic={hook.hookConversionRate}
          loading={hook.hookLoadingMerchantInfo}
          currency="%"
        />
        <StatisticCard
          title={STATISTIC_CARD.PUBLISHER_BY_CLICKS.TITLE}
          tooltip={STATISTIC_CARD.PUBLISHER_BY_CLICKS.TOOLTIP}
          statistic={hook.hookPublisherByClick}
          loading={hook.hookLoadingMerchantDashboardTwo}
        />
        <StatisticCard
          title={STATISTIC_CARD.PUBLISHER_BY_APPROVALS.TITLE}
          tooltip={STATISTIC_CARD.PUBLISHER_BY_APPROVALS.TOOLTIP}
          statistic={hook.hookPublisherByApproval}
          loading={hook.hookLoadingMerchantInfo}
          tooltipWidth={STATISTIC_CARD.PUBLISHER_BY_APPROVALS.TOOLTIP_WIDTH}
        />
      </Styled.StatisticWrapperStyled>

      <Styled.BodyStyled>
        <Styled.VerticalContent>
          <Styled.WrapperStyled id="transactionsSnapshotChart">
            <Styled.SectionTitleStyled>
              Transactions & Commissions Snapshot
              {' '}
              <Tooltip text={SNAPSHOT.TOOLTIP} />
            </Styled.SectionTitleStyled>

            {hook.hookLoadingMerchantDashboardOne ? (
              <Styled.SpinnerWrapperStyled>
                <Spinner theme="secondary" />
              </Styled.SpinnerWrapperStyled>
            ) : (
              <Snapshot
                data={hook.hookSnapshotData}
              />
            )}
          </Styled.WrapperStyled>

          <Styled.WrapperStyled>
            <Styled.SectionTitleStyled>
              Top Publishers
              {' '}
              <Tooltip text={TOP_PUBLISHERS.TOOLTIP} />
            </Styled.SectionTitleStyled>
            {hook.hookLoadingMerchantDashboardTwo && (
            <Styled.SpinnerWrapperStyled>
              <Spinner theme="secondary" />
            </Styled.SpinnerWrapperStyled>
            )}
            {hook.hookTopPublishers && !hook.hookLoadingMerchantDashboardTwo && (
              <Table
                data={hook.hookTopPublishers}
                columns={topPublisherColumns}
                noTableOverflow
              />
            )}
            {hook.hookTopPublishers.length < 1 && !hook.hookLoadingMerchantDashboardTwo && (
              <Styled.TopPublisherStyled>
                <Styled.TitleStyled>No Publishers yet</Styled.TitleStyled>
                <Styled.DescriptionStyled>You have no publishers accepted to your program.</Styled.DescriptionStyled>
                <Button
                  onClick={() => navigate(`${MERCHANT_PREFIX}/${path.publisherInvitations}`)}
                >
                  Invite New Publisher
                </Button>
              </Styled.TopPublisherStyled>
            )}
          </Styled.WrapperStyled>

          <Styled.WrapperStyled>
            <Styled.SectionTitleStyled>
              Traffic Analytics
              {' '}
              <Tooltip text={TRAFFIC_ANALYTICS.TOOLTIP} />
            </Styled.SectionTitleStyled>
            <Tabs
              tabNames={['World', 'United States', 'Canada']}
              elements={[<WorldTab
                trafficAnalytics={hook.hookClicksData.trafficAnallytics}
                loading={hook.hookLoadingMerchantDashboardOne}
              />, <UsaTab
                trafficAnalytics={hook.hookClicksData.trafficAnallytics}
                loading={hook.hookLoadingMerchantDashboardOne}
              />, <CanadaTab
                trafficAnalytics={hook.hookClicksData.trafficAnallytics}
                loading={hook.hookLoadingMerchantDashboardOne}
              />]}
            />

          </Styled.WrapperStyled>

        </Styled.VerticalContent>
        <Styled.VerticalContent>
          <Styled.CardsWrapper>
            {hook.hookDisplayAnnouncements(hook.hookShowAnnouncements, hook.hookAnnouncements)}
            {hook.hookDisplayMessages(hook.hookShowMessages, hook.hookMessages)}
            {hook.hookDisplayBalance(hook.hookShowBalance)}
            <StatusCard
              color={colors.color2}
              title={STATUS_CARD.PENDING_APPLICATIONS.TITLE}
              tooltip={STATUS_CARD.PENDING_APPLICATIONS.TOOLTIP}
              amount={hook.hookPendingApplications}
              loading={hook.hookLoadingMerchantInfo}
              icon={faFile}
              link={`${MERCHANT_PREFIX}${path.membershipManagement.href}`}
            />
            <StatusCard
              color={colors.color21}
              title={STATUS_CARD.PENDING_TRANSACTIONS.TITLE}
              tooltip={STATUS_CARD.PENDING_TRANSACTIONS.TOOLTIP}
              amount={hook.hookPendingTransactions}
              loading={hook.hookLoadingMerchantDashboardOne}
              icon={faArrowRightArrowLeft}
              link={`${MERCHANT_PREFIX}${path.pendingTransactions.href}`}
            />
            <LinkCard
              title="View your Fintel Check report"
              color={colors.color2}
              link={`${MERCHANT_PREFIX}${path.fintelCheckReport.href}`}
              icon={faChevronRight}
            />
          </Styled.CardsWrapper>

          <Styled.WrapperStyled>
            <Styled.SectionTitleStyled>
              Clicks by Device
              {' '}
              <Tooltip text={CLICKS_BY_DEVICE.TOOLTIP} />
            </Styled.SectionTitleStyled>
            {hook.hookLoadingMerchantDashboardOne ? (
              <Styled.SpinnerWrapperStyled>
                <Spinner theme="secondary" />
              </Styled.SpinnerWrapperStyled>
            ) : (
              <ClickByDevice
                clickData={hook.hookClicksData}
              />
            )}
          </Styled.WrapperStyled>

          <Styled.WrapperStyled>
            <Styled.SectionTitleStyled>
              Products by Approvals
              {' '}
              <Tooltip text={PRODUCT_BY_APPROVALS.TOOLTIP} />
            </Styled.SectionTitleStyled>
            {hook.hookLoadingMerchantDashboardTwo ? (
              <Styled.SpinnerWrapperStyled>
                <Spinner theme="secondary" />
              </Styled.SpinnerWrapperStyled>
            ) : hook.hookDisplayProductsApproval(hook.hookProductsData)}
          </Styled.WrapperStyled>
        </Styled.VerticalContent>
      </Styled.BodyStyled>

      <Styled.WrapperStyled>
        <Styled.SectionTitleStyled>
          Top Products
          {' '}
          <Tooltip text={TOP_PRODUCTS.TOOLTIP} />
        </Styled.SectionTitleStyled>
        {hook.hookLoadingMerchantDashboardTwo && (
          <Styled.SpinnerWrapperStyled>
            <Spinner theme="secondary" />
          </Styled.SpinnerWrapperStyled>
        )}
        {hook.hookTopProducts.length > 0 && !hook.hookLoadingMerchantDashboardTwo && (
          <Table
            data={hook.hookTopProducts}
            columns={topProductColumns}
          />
        )}
        {hook.hookTopProducts.length < 1 && !hook.hookLoadingMerchantDashboardTwo && (
          <Styled.TopProductStyled>
            <Styled.TitleStyled>{TOP_PRODUCTS.NO_DATA}</Styled.TitleStyled>
            <Styled.DescriptionStyled>{TOP_PRODUCTS.NO_DATA_DESCRIPTION}</Styled.DescriptionStyled>
            <Button
              onClick={() => undefined}
            >
              Search Programs
            </Button>
          </Styled.TopProductStyled>
        )}
      </Styled.WrapperStyled>

      <Calendar
        isOpen={hook.hookCalendarOpen}
        date={hook.hookStartDate}
        byRange={hook.hookDateRange}
        endDate={hook.hookEndDate}
        onApply={(startDate, endDate, range) => hook.hookSetDateRange(startDate, endDate, range)}
        onCancel={() => hook.hookCloseCalendar()}
        hasRange
      />
    </Styled.AppWrapperStyled>
  );
};

export default MerchantDashboard;
