import styled from 'styled-components';
import { toRem } from '../../../../../../../utils';
import { fonts, colors } from '../../../../../../../styles/theme';
import { Button, InputTextarea, InputText } from '../../../../../../../components';
import { Tooltip } from '../../../../../../../components/Tooltip';

type InputWrappersProps = {
  inverted?: boolean
  isLast?: boolean
};

type MidSectionWrapperProps = {
  isRow?: boolean
};

type InnerWrapperStyledProps = {
  isFirst?: boolean
};

type HalfWrapperProps = {
  isEnd?: boolean
};

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InnerWrapperStyled = styled.div<InnerWrapperStyledProps>`
  width: 100%;
  margin-top: ${toRem(30)};
  margin-bottom: ${({ isFirst }) => (isFirst ? 0 : toRem(48))};
`;

export const ButtonTextWrapperStyled = styled.p`
  color: ${colors.color4};
  margin-left: ${toRem(8)};
`;

export const HalfWrapper = styled.div<HalfWrapperProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${toRem(32)};
  margin-top: ${toRem(40)};
  margin-bottom: ${({ isEnd }) => (isEnd ? toRem(30) : 0)};
`;

export const MarketingItemWrapper = styled.div`
  margin: ${toRem(30)} 0;
  display: flex;
  gap: ${toRem(16)};
  max-width: 69%;
  align-items: center;
`;

export const LegalReferenceItemWrapper = styled.div`
margin: ${toRem(30)} 0;
max-width: 69%;
display: flex;
flex-direction: column;
`;

export const LegalReferenceDescriptionWrapper = styled.div`
max-width: 100%;
display: flex;
align-items: center;
`;

export const NameLabel = styled.div`
  font-size: ${toRem(11)};
  text-transform: uppercase;
  color: ${colors.color13};
  ${fonts.gotham.H12M}
  margin-right: ${toRem(10)};
`;

export const LegalReferenceNameWrapper = styled.div`
max-width: 100%;
display: flex;
align-items: center;
`;

export const LegalReferenceToolTipWrapper = styled.div`
display: flex;
flex-direction: column;
`;

export const LegalReferenceButtonWrapper = styled.div`
max-width: 100%;
display: flex;
align-items: center;
`;

export const LegalReferenceErrorMessage = styled.div`
max-width: 100%;
display: flex;
margin-left: ${toRem(10)};
color: ${colors.color13};
  ${fonts.gotham.H12M}
`;

export const MarketingItemTextArea = styled(InputTextarea)`
  max-width: 80%;
`;

export const LegalReferenceItemTextInput = styled(InputText)`
 max-width: 25%;
 margin-left: ${toRem(4)}
`;

export const LegalReferenceItemTextArea = styled(InputTextarea)`
  max-width: 80%;
  margin-top: ${toRem(5)};
  margin-right: ${toRem(10)};
`;

export const XButton = styled(Button)`
  border: 1px solid ${colors.color1}
  max-height: ${toRem(40)};
  margin-top: ${toRem(10)};
`;

export const InputWrappers = styled.div<InputWrappersProps>`
  display: grid;
  grid-template-columns: ${({ inverted }) => (!inverted ? '1fr 2fr' : '2fr 1fr')};
  gap: ${toRem(32)};
  margin-bottom: ${toRem(40)};
  margin-top: ${({ isLast }) => (isLast ? 0 : toRem(30))};
`;

export const TooltipStyled = styled(Tooltip)`
  height: auto;

  div {
    margin-left: 2px;
  }
`;

export const MidSectionWrapper = styled.div<MidSectionWrapperProps>`
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
  gap: ${toRem(36)};
`;

export const DoubleWrapper = styled.div`
  display: flex;
  gap: ${toRem(32)};
`;

export const LabeledCheckWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
`;

export const LabelTextStyled = styled.span`
  display: flex;
  justify-content: flex-start;
  text-transform: uppercase;
  color: ${colors.color13};
  ${fonts.gotham.H12M}
  margin-bottom: ${toRem(16)};
`;

export const CheckBoxesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-width: ${toRem(500)};
  row-gap: ${toRem(12)};
  
`;

export const WrapperTitleStyled = styled.span`
  ${fonts.gotham.H26};
  color: ${colors.color1};
`;
