import React from 'react';
import { formatAmount } from '../../../../../utils/amountFormatter';

export const columns: TableColumn[] = [
  {
    dataField: 'publisher',
    text: 'publishers',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value:any) => (
      <div>
        {`${value.id} - ${value.companyName}`}
      </div>
    ),
    width: '13%',
  },
  {
    dataField: 'feasible',
    text: 'feasible',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8.6%',
  },
  {
    dataField: 'totalCommissions',
    text: 'total commissions',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '150px',
  },
  {
    dataField: 'totalTaxes',
    text: 'total tax',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8.6%',
  },
  {
    dataField: 'currentRollover',
    text: 'rollover amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8.6%',
  },
  {
    dataField: 'bonus',
    text: 'bonus amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8.6%',
  },
  {
    dataField: 'bonusTax',
    text: 'bonus tax',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8.6%',
  },
  {
    dataField: 'paymentMethod',
    text: 'method',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8.6%',
  },
  {
    dataField: 'currency',
    text: 'currency',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8.6%',
  },
  {
    dataField: 'totalPayable',
    text: 'total payable',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8.6%',
  },
  {
    dataField: 'periodBalance',
    text: 'period balance',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8.6%',
  },
];
