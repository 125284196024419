import { gql } from '@apollo/client';

export const SAVE_INVITATION_TEMPLATE = gql`
mutation createPublisherInvitation($input: PublisherInvitationInput!) {
  createPublisherInvitation(input: $input) {
    id
    merchantId
    name
    description
    subject
    message
    template
  }
}
`;
