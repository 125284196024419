import { gql } from '@apollo/client';

export const GET_SAVED_REPORTS = gql`
  query listReportProfiles($user: ReportProfilesInput) {
    listReportProfiles(input: $user) {
      reports {
        id
        name
        description
        frequency
        every
        company
        report
        user {
          id
          email
        }
        filters {
          field
          value {
            label
            value
          }
        }
        columns {
          label
          value
        }
      }
      count
    }
  }
`;
