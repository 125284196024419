/* eslint-disable no-nested-ternary */
import React from 'react';
import * as Styled from './styles';
import { usePerformanceReport } from './hooks';
import {
  LoadingDots, Modal, Pagination, Table,
} from '../../../../../components';
import { settingColumns } from '../../contracts';
import { FilterSettingsModal } from '../../FilterSettingsModal';
import { MODAL_KEYS } from '../../reducers';
import { DeleteReportModal } from '../../DeleteReportModal';
import { Placeholder } from '../../Placeholder';
import { path } from '../../../../../utils';

export const PerformanceReports = () => {
  const hook = usePerformanceReport();

  return (
    <Styled.WrapperStyled>
      {hook.hookLoading
        ? (<LoadingDots />)
        : (
          hook.hookData.length === 0 ? (
            <Placeholder link={path.performanceReport.href} />
          ) : (
            <>
              <Table
                data={hook.hookData}
                columns={settingColumns}
                setData={hook.hookSetData}
                setContext={hook.hookSetModals}
                setOpenModal={hook.hookReportCreated}
              />
              <Pagination
                currentPage={hook.hookCurrentPage}
                total={hook.hookTotalPages}
                onPageChange={hook.hookSetCurrentPage}
              />

              <Modal isOpen={hook.hookModals[MODAL_KEYS.openSettingModal]}>
                <FilterSettingsModal
                  info={hook.hookModals.modalInfo}
                  setOpenModal={hook.hookSetModals}
                  reportType="Performance"
                />
              </Modal>

              <Modal isOpen={hook.hookModals[MODAL_KEYS.openDeleteModal]}>
                <DeleteReportModal
                  info={hook.hookModals.modalInfo}
                  setOpenModal={hook.hookSetModals}
                  reportDeleted={hook.hookReportDeleted}
                />
              </Modal>
            </>
          ))}
    </Styled.WrapperStyled>
  );
};
