const exportCSV = (columns: any, data: any) => {
  // This is a duplicate of the export csv function in the backend
  // ONLY TO BE USED FOR EDGE CASES
  const CSVHeader: any[] = [];
  const CSVDataJoined: any[] = [];
  const CSVFooter: any[] = [];

  columns.forEach((column: any) => {
    CSVHeader.push(`"${column.text}"`);
  });

  data.forEach((row: any) => {
    const CSVData: any[] = [];
    columns.forEach((column: any) => {
      CSVData.push(`"${row[column.dataField]}"`);
    });
    CSVDataJoined.push(CSVData.join(','));
  });

  return `${CSVHeader.join(',')}\n${CSVDataJoined.join('\n')}${CSVFooter.join(',')}`;
};

export default exportCSV;
