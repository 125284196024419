import { gql } from '@apollo/client';

export const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationAsRead($input: NotificationInput) {
    updateNotification(input: $input) {
      notification {
        id
      }
    }
  }
`;
