import React from 'react';
import { URL_UPLOAD } from './enums';
import * as Styled from './styles';
import { FileUpload } from '../../../../../../components';
import { useUrlBulkUpload } from './hooks';
import { FILE_EXTENSIONS } from '../../../../../../utils';

type FintelCheckUrlBulkUploadProps = {
  isReadOnly: boolean;
}

const FintelCheckUrlBulkUpload = ({ isReadOnly }: FintelCheckUrlBulkUploadProps) => {
  const hook = useUrlBulkUpload();

  return (
    <>
      <Styled.DescriptionStyled>
        {URL_UPLOAD.DESCRIPTION}
      </Styled.DescriptionStyled>
      <Styled.InstructionStyled>
        {URL_UPLOAD.INSTRUCTION}
      </Styled.InstructionStyled>
      <Styled.InstructionStyled>
        {URL_UPLOAD.SAMPLE_TITLE}
      </Styled.InstructionStyled>
      <Styled.SampleStyled>
        <p>{URL_UPLOAD.SAMPLE_1}</p>
        <p>{URL_UPLOAD.SAMPLE_2}</p>
      </Styled.SampleStyled>
      <FileUpload
        label={URL_UPLOAD.LABEL}
        onChange={hook.hookFileHandler}
        info="Max file size 4MB"
        // Bug in Windows version of Firefox to see csv files as Excel files. see https://support.mozilla.org/en-US/questions/1401889
        accept={`.csv, .txt, *.csv, *.txt, text/csv, text/plain, ${FILE_EXTENSIONS.MS_EXCEL}, ${FILE_EXTENSIONS.SPREADSHEET}`}
        type="csv"
        required
        disabled={hook.hookLoading || isReadOnly}
        maxFileSize={4000000}
        width="25%"
      />
    </>
  );
};

export default FintelCheckUrlBulkUpload;
