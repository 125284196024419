import {
  useEffect, useReducer, useState, Reducer,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useModal, useUserInfo, useToast } from '../../../../../hooks';
import {
  csvGenerator, dateFormatter, MERCHANT_PREFIX, path,
} from '../../../../../utils';
import { FEEDBACK_ACTIONS, feedbackModalReducer, FeedbackReducerModalProps } from '../Modal/reducers';
import { useFintelCheckContext } from '../../hooks';
import {
  FINTEL_CHECK_DETAILS_REPORT, detailsReducer, initialState, FintelCheckDetailsReducerProps, DetailsActionType,
} from '../reducers';
import {
  GET_FINTEL_CHECK_SUMMARIES, GetFintelCheckSummariesInput, GetFintelCheckSummariesOutput, GFCSSummaryType,
  GET_FINTEL_CHECK_SUMMARIES_CSV, GetFintelCheckSummariesCSVOutput, GetFintelCheckSummariesCSVInput,
  GET_FINTEL_CHECK_DETAILS_DROPDOWNS, GetFintelCheckDetailsDropdownsInput, GetFintelCheckDetailsDropdownsOutput,
} from '../graphql/queries';
import { UPDATE_FINTEL_CHECK_SUMMARIES_FEEDBACK, UpdateFintelCheckSummariesInput, UpdateFintelCheckSummariesOutput } from '../graphql/mutations';
import { DETAILS } from '../enums';
import { CSVColumns, formatDataForCSV, processDataToCSVString } from '../utils';

export const useFintelCheckDetails = () => {
  const { hookWhoAmI } = useUserInfo();
  const { contextState, contextDispatchHandler } = useFintelCheckContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { hookShowToast } = useToast();

  const [feedbackModalOpen, setFeedbackModal] = useModal();
  const [modalState, modalDispatcher] = useReducer<Reducer<FeedbackReducerModalProps, BaseReducerType>>(feedbackModalReducer, {
    correctStatus: undefined, currStatus: undefined, id: undefined, shouldBeStatus: undefined,
  });
  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState<string>('');

  const [filtersState, filtersDispatch] = useReducer<Reducer<FintelCheckDetailsReducerProps, DetailsActionType>>(detailsReducer, {
    ...initialState,
    ...(contextState?.status) && { status: contextState.status },
    ...(contextState?.product) && { product: contextState.product },
    ...(contextState?.productCategory) && { productCategory: contextState.productCategory },
  });
  const [categoryList, setCategoryList] = useState<SelectOption[]>([]);
  const [productList, setProductList] = useState<SelectOption[]>([]);
  const [statusList, setStatusList] = useState<SelectOption[]>([]);

  const [tableData, setTableData] = useState<GFCSSummaryType[]>([]);
  const [sortBy, setSortBy] = useState<TableSortColumn>({ column: '_id', direction: 'asc' });

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [refetchTable, setRefetchTable] = useState<boolean>(false);

  const [getSummaries, { loading: summariesLoading }] = useLazyQuery<GetFintelCheckSummariesOutput, GetFintelCheckSummariesInput>(GET_FINTEL_CHECK_SUMMARIES);
  const [getSummariesCSV, { loading: summariesCSVLoading }] = useLazyQuery<GetFintelCheckSummariesCSVOutput, GetFintelCheckSummariesCSVInput>(GET_FINTEL_CHECK_SUMMARIES_CSV);
  const [getDropdowns, { loading: getDropdownsLoading }] = useLazyQuery<GetFintelCheckDetailsDropdownsOutput, GetFintelCheckDetailsDropdownsInput>(GET_FINTEL_CHECK_DETAILS_DROPDOWNS);
  const [updateSummary, { loading: updateSummaryLoading }] = useMutation<UpdateFintelCheckSummariesOutput, UpdateFintelCheckSummariesInput>(UPDATE_FINTEL_CHECK_SUMMARIES_FEEDBACK);

  const filtersDispatchHandler = (value: SelectOption | number, type: symbol) => {
    filtersDispatch({ type, data: value });
  };

  const setFeedbackModalHandler = () => {
    setFeedbackModal();
    setFeedbackErrorMessage('');
  };

  const getSummariesHandler = async () => {
    setErrorMessage('');
    /* eslint-disable-next-line padded-blocks */
    const { data, error: summaryError } = await getSummaries({
      variables: {
        input: {
          filter: {
            merchantId: contextState.merchantId || hookWhoAmI.companyId?.toString() || '',
            publisherId: contextState.publisherId || hookWhoAmI.companyId?.toString() || '',
            ...contextState.publisherName && { publisherName: contextState.publisherName },
            checkDate: contextState.checkDate || contextState.date,
            ...(filtersState.product.value !== 'All Products') && { productId: filtersState.product.value },
            ...filtersState.status.value && { ruleStatus: filtersState.status.value },
            ...filtersState.productCategory.value && { productCategory: filtersState.productCategory.value },
          },
          page: filtersState.currentPage,
          limit: Number(filtersState.records.value),
          sortBy: sortBy.column,
          sortDirection: sortBy.direction || 'desc',
        },
      },
      fetchPolicy: 'no-cache',
      onError(error) {
        setErrorMessage(error.message);
      },
    });

    if (data && data.fintelCheckSummariesPaginated && !summaryError) {
      const formatted = data.fintelCheckSummariesPaginated.fintelCheckSummary.map((item) => ({
        ...item,
        trackingEventDate: item.trackingEventDate ? dateFormatter(new Date(item.trackingEventDate), '/') : null,
      }));

      setTableData(formatted);
      filtersDispatchHandler(data.fintelCheckSummariesPaginated.count, FINTEL_CHECK_DETAILS_REPORT.SET_TOTAL);
      filtersDispatchHandler(data.fintelCheckSummariesPaginated.totalPages, FINTEL_CHECK_DETAILS_REPORT.SET_TOTAL_PAGES);
    } else {
      setTableData([]);
      filtersDispatchHandler(0, FINTEL_CHECK_DETAILS_REPORT.SET_TOTAL);
      filtersDispatchHandler(0, FINTEL_CHECK_DETAILS_REPORT.SET_TOTAL_PAGES);
      filtersDispatchHandler(1, FINTEL_CHECK_DETAILS_REPORT.SET_CURRENT_PAGE);
    }
  };

  const getSummariesCSVHandler = async () => {
    setErrorMessage('');
    const { data } = await getSummariesCSV({
      variables: {
        input: {
          merchantId: contextState.merchantId || hookWhoAmI.companyId?.toString() || '',
          publisherId: contextState.publisherId || hookWhoAmI.companyId?.toString() || '',
          ...contextState.publisherName && { publisherName: contextState.publisherName },
          checkDate: contextState.checkDate || contextState.date,
          ...(filtersState.product.value !== 'All Products') && { productId: filtersState.product.value },
          ...filtersState.status.value && { ruleStatus: filtersState.status.value },
          ...filtersState.productCategory.value && { productCategory: filtersState.productCategory.value },
        },
      },
      fetchPolicy: 'no-cache',
      onError(error) {
        setErrorMessage(error.message);
      },
    });

    if (data && data.fintelCheckSummaries) {
      const formattedData = formatDataForCSV(data.fintelCheckSummaries.fintelCheckSummary);
      const csvString = processDataToCSVString(formattedData, CSVColumns);
      csvGenerator(csvString, 'fintel-check-report-detail');
    }
  };

  const updateFintelCheckSummariesHandler = async () => {
    setFeedbackErrorMessage('');

    const correctString = (() => {
      if (modalState.correctStatus === undefined || modalState.correctStatus === null) return null;
      if (modalState.correctStatus === false) return 'No';
      if (modalState.correctStatus === true) return 'Yes';
      return null;
    })();

    const updateSummaryInput: UpdateFintelCheckSummariesInput = {
      input: {
        id: modalState.id || '',
        feedback: {
          correct: correctString,
          currStatus: modalState.currStatus || null,
          inputType: '',
          inputVal: '',
          issue: null,
          shouldBeStatus: modalState.correctStatus ? '' : modalState.shouldBeStatus || null,
        },
      },
    };

    const { data } = await updateSummary({
      variables: updateSummaryInput,
      fetchPolicy: 'no-cache',
      onError(error) {
        setFeedbackErrorMessage(error.message);
      },
    });

    if (data && data.updateFintelCheckSummaries) {
      setFeedbackModalHandler();
      setRefetchTable(true);
      hookShowToast(DETAILS.UPDATE_FEEDBACK_TOAST);
    }
  };

  const getDropdownValues = async () => {
    setErrorMessage('');
    const { data } = await getDropdowns({
      variables: {
        input: {
          merchantId: contextState.merchantId || hookWhoAmI.companyId?.toString() || '',
          publisherId: contextState.publisherId || hookWhoAmI.companyId?.toString() || '',
          ...contextState.publisherName && { publisherName: contextState.publisherName },
          checkDate: contextState.checkDate || contextState.date,
        },
      },
      fetchPolicy: 'no-cache',
      onError(error) {
        setErrorMessage(error.message);
      },
    });

    if (data && data.fintelCheckDetailsDropdowns) {
      const { productFilterOptions, ruleStatusOptions, productCategoriesOptions } = data.fintelCheckDetailsDropdowns;
      if (productFilterOptions) setProductList(productFilterOptions);
      if (ruleStatusOptions) setStatusList(ruleStatusOptions);
      if (productCategoriesOptions) setCategoryList(productCategoriesOptions);
    }
  };

  const modalDispatcherHandler = (type: symbol, data: Record<string, any>) => {
    modalDispatcher({ type, data });
  };

  const openModalHandler = () => {
    setFeedbackModalHandler();
    modalDispatcher({ type: FEEDBACK_ACTIONS.RESET_MODAL, data: { correctStatus: undefined, realStatus: undefined } });
  };

  const modalHandler = (modalInfo: FeedbackReducerModalProps) => {
    modalDispatcher({ type: FEEDBACK_ACTIONS.SET_STATUS_CONFIRMATION, data: modalInfo });
  };

  const clearFormHandler = (value: typeof initialState, type: symbol) => {
    filtersDispatch({ type, data: value });
  };
  // back
  const backButtonHandler = () => {
    if (location.state?.from) navigate(location.state.from, { state: { from: window.location.pathname } });
    else navigate(-1);
  };

  const changePageHandler = (newPage: number) => {
    filtersDispatchHandler(newPage, FINTEL_CHECK_DETAILS_REPORT.SET_CURRENT_PAGE);
    setRefetchTable(true);
  };

  const setSortByHandler = (dataField: string, direction: any) => {
    if (sortBy.direction === null) {
      setSortBy({ column: dataField, direction });
    } else {
      setSortBy({ column: dataField, direction: sortBy.direction === 'asc' ? 'desc' : 'asc' });
    }
    setRefetchTable(true);
  };

  // Props for the Feedback Modal
  const modalInfo = {
    state: modalState,
    dispatch: modalDispatcherHandler,
    modalOpenDispatcher: setFeedbackModalHandler,
    updateFeedback: updateFintelCheckSummariesHandler,
    isLoading: updateSummaryLoading,
    errorMessage: feedbackErrorMessage,
  };

  if (refetchTable) {
    setRefetchTable(false);
    getSummariesHandler();
  }

  // here
  useEffect(() => {
    // If no date in context leave page, as won't function correctly
    if (!contextState.date && !contextState.checkDate) {
      navigate(`${MERCHANT_PREFIX}${path.fintelCheckReport.href}`);
    }
    getSummariesHandler();
  }, [hookWhoAmI.companyId, filtersState.productCategory, filtersState.product, filtersState.status, filtersState.records]);

  useEffect(() => {
    getDropdownValues();
  }, [window.location.href]);

  return {
    hookLoading: summariesLoading,
    hookDropdownsLoading: getDropdownsLoading,
    hookErrorMessage: errorMessage,

    hookNavigateBack: backButtonHandler,
    hookContext: contextState,
    hookSetContext: contextDispatchHandler,

    hookData: tableData,
    hookChangePageHandler: changePageHandler,
    hookSetSortByHandler: setSortByHandler,
    hookSortBy: sortBy,
    hookSetRefetchTable: setRefetchTable,

    hookCSVLoading: summariesCSVLoading,
    hookGetSummariesCSV: getSummariesCSVHandler,

    hookCategoryList: categoryList,
    hookProductList: productList,
    hookStatusList: statusList,

    hookSelectState: filtersState,
    hookChangeSelect: filtersDispatchHandler,
    hookClearForm: clearFormHandler,

    hookModalOpen: feedbackModalOpen,
    hookSetModalOpen: openModalHandler,
    hookModalState: modalState,
    hookModalDispatcher: modalDispatcherHandler,
    hookModalInfo: modalInfo,
    hookModalSetInfo: modalHandler,
  };
};
