import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useModal } from '../../../../hooks';
import { csvGenerator, timezoneOffset } from '../../../../utils';
import { reportColumnsCSV } from '../contracts';
import { LIST_SEARCH_PUBLISHERS_CSV, LIST_SEARCH_PUBLISHER } from '../graphql/queries';
import { usePublisherFilterOptions } from './usePublisherFilterOptions';
import { useActAsContext } from '../../../../context';
import { useDebounce } from '../../../../utils/useDebounce';
import { SUBSCRIBED } from '../../../../utils/enums';

export const usePublisherSearch = () => {
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [publisherList, setPublisherList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [accountStatus, setAccountStatus] = useState<SelectOption>();
  const [taxStatus, setTaxStatus] = useState<SelectOption>();
  const [primaryAudienceMarket, setPrimaryAudienceMarket] = useState<SelectOption>();
  const [productCategory, setProductCategory] = useState<SelectOption>();
  const [promotionMethod, setPromotionMethod] = useState<SelectOption>();
  const [averageMonthlyAudienceSize, setAverageMonthlyAudienceSize] = useState<SelectOption>();
  const [activityStatus, setActivityStatus] = useState<SelectOption>();
  const [age, setAge] = useState<SelectOption>();
  const [gender, setGender] = useState<SelectOption>();
  const [creditScoreRating, setCreditScoreRating] = useState<SelectOption>();
  const [averageIncome, setAverageIncome] = useState<SelectOption>();
  const [subscription, setSubscription] = useState<SelectOption>();
  const [country, setCountry] = useState<SelectOption>();
  const [paymentMethod, setPaymentMethod] = useState<SelectOption>();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState<SelectOption>({ value: '10', label: '10' });
  const [openStartCalendar, setOpenStartCalendar] = useState(false);
  const [openEndCalendar, setOpenEndCalendar] = useState(false);
  const [customizedColumnsModal, setCustomizedColumnsModal] = useModal(false);
  const [defaultColumns] = useState([
    'Id',
    'Company Name',
    'Member Since',
    'Audience Markets',
    'Product Categories',
    'Promotion Method',
    'Monthly Audience Size',
    'Last Login',
    'Status',
  ]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(defaultColumns);
  const [loadingMessage, setLoadingMessage] = useState('Loading Publishers...');
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'id', direction: 'asc' });
  const [recordsCount, setRecordsCount] = useState(0);
  const [searchClicked, setSearchClicked] = useState<boolean>(false);
  const [currentlyDebounced, setCurrentlyDebounced] = useState<boolean>(false);

  const [getCompanies, { loading }] = useLazyQuery(LIST_SEARCH_PUBLISHER);
  const [getCompaniesCSV, { loading: publisherCSVLoading }] = useLazyQuery(LIST_SEARCH_PUBLISHERS_CSV);
  const {
    hookAccountStatusTypeList,
    hookTaxStatusTypeList,
    hookAudienceMarketList,
    hookCountriesList,
    hookProductCategoryList,
    hookPromotionMethodList,
    hookMonthlyAudienceList,
    hookActivityStatusTypeList,
    hookAudienceAgesList,
    hookAudienceGenderList,
    hookCreditScoreList,
    hookAverageIncomeList,
    hookPaymentMethodList,
    hookFilterOptionsLoading,
  } = usePublisherFilterOptions();
  const hookActAs = useActAsContext();
  const debouncedSearch = useDebounce(search, 800);

  const handleSort = (dataField: string, direction: any) => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const setColumnsHandler = (columns: string[]) => {
    setSelectedColumns([...columns]);
  };

  const getPublishersHandler = async () => {
    setLoadingMessage('Loading Publishers...');
    let subscriptionValue;
    if (subscription?.value !== SUBSCRIBED.ANY && subscription?.value === SUBSCRIBED.YES) subscriptionValue = true;
    if (subscription?.value !== SUBSCRIBED.ANY && subscription?.value === SUBSCRIBED.NO) subscriptionValue = false;

    const { data } = await getCompanies({
      variables: {
        input: {
          filter: {
            accountStatusType: accountStatus?.value || undefined,
            taxStatusType: taxStatus?.value || undefined,
            audienceMarket: primaryAudienceMarket?.value || undefined,
            productCategory: productCategory?.value || undefined,
            promotionMethod: promotionMethod?.value || undefined,
            averageMonthlyAudienceSize: averageMonthlyAudienceSize?.value || undefined,
            activityStatus: activityStatus?.value || undefined,
            age: age?.value || undefined,
            gender: gender?.value || undefined,
            creditScoreRating: creditScoreRating?.value || undefined,
            averageIncome: averageIncome?.value || undefined,
            country: country?.value || undefined,
            paymentMethod: paymentMethod?.value || undefined,
            startDate: timezoneOffset(startDate) || undefined,
            endDate: timezoneOffset(endDate) || undefined,
            subscription: subscriptionValue,
            companyType: 'Publisher',
            q: search,
          },
          options: {
            page: currentPage,
            items: parseInt(recordsPerPage.value, 10),
            order: sortColumn.direction?.toUpperCase(),
          },
          sortBy: sortColumn.column,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.listSearchPublishers?.companies) {
      setPublisherList(data?.listSearchPublishers?.companies);
    }
    if (data?.listSearchPublishers?.count) {
      setRecordsCount(data.listSearchPublishers.count);
      setTotalPages(Math.ceil(data.listSearchPublishers.count / parseInt(recordsPerPage.value, 10)));
    } else {
      setCurrentPage(1);
      setRecordsCount(0);
      setTotalPages(0);
    }
    setLoadingMessage('');
  };

  const getCSVPublisherHandler = async () => {
    setLoadingMessage('Loading CSV...');
    let subscriptionValue;
    if (subscription?.value !== SUBSCRIBED.ANY && subscription?.value === SUBSCRIBED.YES) subscriptionValue = true;
    if (subscription?.value !== SUBSCRIBED.ANY && subscription?.value === SUBSCRIBED.NO) subscriptionValue = false;

    const { data } = await getCompaniesCSV({
      variables: {
        input: {
          filter: {
            accountStatusType: accountStatus?.value || undefined,
            taxStatusType: taxStatus?.value || undefined,
            audienceMarket: primaryAudienceMarket?.value || undefined,
            productCategory: productCategory?.value || undefined,
            promotionMethod: promotionMethod?.value || undefined,
            averageMonthlyAudienceSize: averageMonthlyAudienceSize?.value || undefined,
            activityStatus: activityStatus?.value || undefined,
            age: age?.value || undefined,
            gender: gender?.value || undefined,
            creditScoreRating: creditScoreRating?.value || undefined,
            averageIncome: averageIncome?.value || undefined,
            country: country?.value || undefined,
            paymentMethod: paymentMethod?.value || undefined,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            subscription: subscriptionValue,
            companyType: 'Publisher',
            q: search,
          },
          columns: reportColumnsCSV(selectedColumns),
        },
      },
    });
    if (data?.listSearchCompaniesCSV.csv) {
      csvGenerator(data?.listSearchCompaniesCSV.csv, 'Publisher Report');
    }

    setLoadingMessage('');
  };

  const setShowAdvancedSearchHandler = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };

  const setSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentlyDebounced(true);
  };

  const setAccountStatusHandler = (selected: SelectOption) => {
    setAccountStatus(selected);
  };

  const setTaxStatusHandler = (selected: SelectOption) => {
    setTaxStatus(selected);
  };

  const setPrimaryAudienceMarketHandler = (selected: SelectOption) => {
    setPrimaryAudienceMarket(selected);
  };

  const setProductCategoryHandler = (selected: SelectOption) => {
    setProductCategory(selected);
  };

  const setPromotionMethodHandler = (selected: SelectOption) => {
    setPromotionMethod(selected);
  };

  const setAverageMonthlyAudienceSizeHandler = (selected: SelectOption) => {
    setAverageMonthlyAudienceSize(selected);
  };

  const setActivityStatusHandler = (selected: SelectOption) => {
    setActivityStatus(selected);
  };

  const setAgeHandler = (selected: SelectOption) => {
    setAge(selected);
  };

  const setGenderHandler = (selected: SelectOption) => {
    setGender(selected);
  };

  const setCreditScoreRatingHandler = (selected: SelectOption) => {
    setCreditScoreRating(selected);
  };

  const setAverageIncomeHandler = (selected: SelectOption) => {
    setAverageIncome(selected);
  };

  const setSubscriptionHandler = (selected: SelectOption) => {
    setSubscription(selected);
  };

  const setCountryHandler = (selected: SelectOption) => {
    setCountry(selected);
  };

  const setPaymentMethodHandler = (selected: SelectOption) => {
    setPaymentMethod(selected);
  };

  const setStartDateHandler = (date: Date) => {
    setStartDate(date.toLocaleDateString('en-CA'));
  };

  const setEndDateHandler = (date: Date) => {
    setEndDate(date.toLocaleDateString('en-CA'));
  };

  const setChangePageHandler = (page: number) => {
    setCurrentPage(page);
  };

  const setRecordsPerPageHandler = (selected: SelectOption) => {
    setRecordsPerPage(selected);
  };

  const setResetHandler = () => {
    setSearch('');
    setAccountStatus(undefined);
    setTaxStatus(undefined);
    setPrimaryAudienceMarket(undefined);
    setProductCategory(undefined);
    setPromotionMethod(undefined);
    setAverageMonthlyAudienceSize(undefined);
    setActivityStatus(undefined);
    setAge(undefined);
    setGender(undefined);
    setCreditScoreRating(undefined);
    setAverageIncome(undefined);
    setSubscription(undefined);
    setCountry(undefined);
    setPaymentMethod(undefined);
    setStartDate('');
    setEndDate('');
    setSearchClicked(false);
    setPublisherList([]);
  };

  const setApplyStartHandler = (date: Date) => {
    setStartDateHandler(date);
    setOpenStartCalendar(false);
  };

  const setApplyEndHandler = (date: Date) => {
    setEndDateHandler(date);
    setOpenEndCalendar(false);
  };

  const setStartOpenCloseHandler = () => {
    setOpenStartCalendar(!openStartCalendar);
  };

  const setEndOpenCloseHandler = () => {
    setOpenEndCalendar(!openEndCalendar);
  };

  const rowClickActAsHandler = (row: any) => {
    hookActAs.setActAsPublisherHandler(row.id);
  };

  const checkRowInactiveHandler = (row: any) => row.accountStatus === 'Closed' || row.accountStatus === 'Pending' || row.accountStatus === 'Declined';

  const searchButtonHandler = () => {
    setSearchClicked(true);
    if (currentPage !== 1) setCurrentPage(1);
    else getPublishersHandler();
    setCurrentlyDebounced(false);
  };

  useEffect(() => {
    if (searchClicked) {
      getPublishersHandler();
    }
  }, [
    accountStatus?.value,
    taxStatus?.value,
    primaryAudienceMarket?.value,
    productCategory?.value,
    promotionMethod?.value,
    averageMonthlyAudienceSize?.value,
    activityStatus?.value,
    age?.value,
    gender?.value,
    creditScoreRating?.value,
    averageIncome?.value,
    subscription?.value,
    country?.value,
    paymentMethod?.value,
    startDate,
    endDate,
    recordsPerPage?.value,
    sortColumn,
    currentPage,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    accountStatus?.value,
    taxStatus?.value,
    primaryAudienceMarket?.value,
    productCategory?.value,
    promotionMethod?.value,
    averageMonthlyAudienceSize?.value,
    activityStatus?.value,
    age?.value,
    gender?.value,
    creditScoreRating?.value,
    averageIncome?.value,
    subscription?.value,
    country?.value,
    paymentMethod?.value,
    startDate,
    endDate,
    recordsPerPage?.value,
  ]);

  useEffect(() => {
    if (searchClicked && currentlyDebounced && currentPage === 1) getPublishersHandler();
    else if (currentPage !== 1) setCurrentPage(1);
  }, [debouncedSearch]);

  return {
    hookShowAdvancedSearch: showAdvancedSearch,
    hookSetShowAdvancedSearch: setShowAdvancedSearchHandler,
    hookPublisherList: publisherList,
    hookLoading: loading || publisherCSVLoading,
    hookOptionsLoading: hookFilterOptionsLoading,
    hookLoadingMessage: loadingMessage,
    hookCurrentPage: currentPage,
    hookChangePage: setChangePageHandler,
    hookTotalPages: totalPages,

    hookSearch: search,
    hookSetSearch: setSearchHandler,

    hookAccountStatus: accountStatus,
    hookSetAccountStatus: setAccountStatusHandler,
    hookNetworkStatusOptions: hookAccountStatusTypeList,

    hookTaxStatus: taxStatus,
    hookSetTaxStatus: setTaxStatusHandler,
    hookTaxStatusOptions: hookTaxStatusTypeList,

    hookPrimaryAudienceMarket: primaryAudienceMarket,
    hookSetPrimaryAudienceMarket: setPrimaryAudienceMarketHandler,
    hookPrimaryAudienceMarketOptions: hookAudienceMarketList,

    hookProductCategory: productCategory,
    hookSetProductCategory: setProductCategoryHandler,
    hookProductCategoryOptions: hookProductCategoryList,

    hookPromotionMethod: promotionMethod,
    hookSetPromotionMethod: setPromotionMethodHandler,
    hookPromotionMethodOptions: hookPromotionMethodList,

    hookAverageMonthlyAudienceSize: averageMonthlyAudienceSize,
    hookSetAverageMonthlyAudienceSize: setAverageMonthlyAudienceSizeHandler,
    hookAverageMonthlyAudienceSizeOptions: hookMonthlyAudienceList,

    hookActivityStatus: activityStatus,
    hookSetActivityStatus: setActivityStatusHandler,
    hookActivityStatusOptions: hookActivityStatusTypeList,

    hookAge: age,
    hookSetAge: setAgeHandler,
    hookAgeOptions: hookAudienceAgesList,

    hookGender: gender,
    hookSetGender: setGenderHandler,
    hookGenderOptions: hookAudienceGenderList,

    hookCreditScoreRating: creditScoreRating,
    hookSetCreditScoreRating: setCreditScoreRatingHandler,
    hookCreditScoreRatingOptions: hookCreditScoreList,

    hookAverageIncome: averageIncome,
    hookSetAverageIncome: setAverageIncomeHandler,
    hookAverageIncomeOptions: hookAverageIncomeList,

    hookSubscription: subscription,
    hookSetSubscription: setSubscriptionHandler,

    hookCountry: country,
    hookSetCountry: setCountryHandler,
    hookCountryOptions: hookCountriesList,

    hookPaymentMethod: paymentMethod,
    hookSetPaymentMethod: setPaymentMethodHandler,
    hookPaymentMethodOptions: hookPaymentMethodList,

    hookStartDate: startDate,

    hookEndDate: endDate,

    hookRecordsPerPage: recordsPerPage,
    hookTotalRecords: recordsCount,
    hookSetRecordsPerPage: setRecordsPerPageHandler,

    hookReset: setResetHandler,

    hookOpenStartCalendar: openStartCalendar,
    hookOnApplyStart: setApplyStartHandler,
    hookStartOpenClose: setStartOpenCloseHandler,

    hookOpenEndCalendar: openEndCalendar,
    hookOnApplyEnd: setApplyEndHandler,
    hookEndOpenClose: setEndOpenCloseHandler,

    hookModal: customizedColumnsModal,
    hookSetModal: setCustomizedColumnsModal,

    hookColumns: selectedColumns,
    hookSetColumns: setColumnsHandler,

    hookCSV: getCSVPublisherHandler,

    hookSortColumn: sortColumn,
    hookHandleSort: handleSort,

    hookHandleRowClickActAs: rowClickActAsHandler,

    hookCheckInactive: checkRowInactiveHandler,

    hookSearchClicked: searchClicked,
    hookSearchButtonHandler: searchButtonHandler,
  };
};
