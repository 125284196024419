import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../styles/theme';
import { imageList } from '../../../utils';

type CellProps = {
  val: any,
}

type InconsistantCellWrapperProps = {
  isPublisher?: boolean
}

const InconsistantCellWrapper = styled.div<InconsistantCellWrapperProps>`
  display: flex;
  flex-flow: column;
  justify-content: left;
  min-width: ${({ isPublisher }) => (isPublisher ? '100px' : '')};
`;

const CellWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const StartCell = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CenterCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EndCell = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MultiLineCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const CellDescription = styled.span`
  ${fonts.lato.H12}
  color: ${colors.color13};
  margin-top: 5px;
`;

const ImgWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
`;

const Overflow = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EndCellFormatted = ({ val }: CellProps) => (
  <CellWrapper>
    <EndCell>
      $
      {val}
    </EndCell>
  </CellWrapper>
);

const ProductMultiLineFormatted = ({ val }: CellProps) => (
  <CellWrapper>
    <Overflow>{val.name}</Overflow>
    {val.program.merchant.companyName && (
      <CellDescription>{val.program.merchant.companyName}</CellDescription>
    )}
  </CellWrapper>
);

const AdMultiLineFormatted = ({ val }: CellProps) => (
  <CellWrapper>
    <MultiLineCell>
      <span>{val.id}</span>
      {val.program.merchant.companyName && (
        <CellDescription>{val.program.merchant.companyName}</CellDescription>
      )}
    </MultiLineCell>
  </CellWrapper>
);

const PublisherMultiLineFormatted = ({ val }: CellProps) => (
  <InconsistantCellWrapper>
    <MultiLineCell>
      <p>{val.companyName}</p>
      {val.companyUrl && (
        <CellDescription>{val.companyUrl}</CellDescription>
      )}
    </MultiLineCell>
  </InconsistantCellWrapper>
);

const ImageFormatted = ({ val }: CellProps) => (
  <CellWrapper>
    <ImgWrapper>
      <img
        src={val.adContent || imageList.noPic.src}
        alt="cover"
      />
    </ImgWrapper>
  </CellWrapper>
);

const IndexCellFormatted = ({ val }: CellProps) => (
  <CellWrapper>
    <StartCell>
      {val}
      .
    </StartCell>
  </CellWrapper>
);

const IndexInconsistantCellFormatted = ({ val }: CellProps) => (
  <InconsistantCellWrapper>
    <StartCell>
      {val}
      .
    </StartCell>
  </InconsistantCellWrapper>
);

const SingleLineStartCellFormatted = ({ val }: CellProps) => (
  <InconsistantCellWrapper>
    <StartCell>
      <Overflow>{val}</Overflow>
    </StartCell>
  </InconsistantCellWrapper>
);

const SingleLineCenterCellFormatted = ({ val, isPublisher }: {val: any, isPublisher?: boolean}) => (
  <InconsistantCellWrapper isPublisher={isPublisher}>
    <CenterCell>
      {val}
    </CenterCell>
  </InconsistantCellWrapper>
);

const SingleLineEndCellFormatted = ({ val }: CellProps) => (
  <InconsistantCellWrapper>
    <EndCell>
      $
      {val}
    </EndCell>
  </InconsistantCellWrapper>
);

export const topMerchantsColumns: TableColumn[] = [
  {
    dataField: 'index',
    text: '',
    width: '40px',
    formatter: (val: any) => <IndexInconsistantCellFormatted val={val} />,
  },
  {
    dataField: 'merchant',
    text: 'Name',
    width: '30%',
    position: 'flex-start',
    formatter: (val: any) => <SingleLineStartCellFormatted val={val.companyName} />,
  },
  {
    dataField: 'totalClicks',
    text: 'Total Click',
    width: '15%',
    position: 'center',
    formatter: (val: any) => <SingleLineCenterCellFormatted val={val} />,
  },
  {
    dataField: 'totalTransactions',
    text: 'Total Transactions',
    width: '17%',
    position: 'center',
    formatter: (val: any) => <SingleLineCenterCellFormatted val={val} />,
  },
  {
    dataField: 'approvedTransactions',
    text: 'Approved Transactions',
    width: '18%',
    position: 'center',
    formatter: (val: any) => <SingleLineCenterCellFormatted val={val} />,
  },
  {
    dataField: 'totalCommissions',
    text: 'Total Commissions',
    width: 'calc(20% - 10px)',
    position: 'flex-end',
    formatter: (val: any) => <SingleLineEndCellFormatted val={val} />,
  },
];

export const topProductsColumns: TableColumn[] = [
  {
    dataField: 'index',
    text: '',
    width: '40px',
    formatter: (val: any) => <IndexCellFormatted val={val} />,
  },
  {
    dataField: 'product',
    text: 'Name/Merchant',
    width: '59%',
    position: 'flex-start',
    formatter: (val: any) => <ProductMultiLineFormatted val={val} />,
  },
  {
    dataField: 'totalCommissions',
    text: 'Total Commissions',
    width: 'calc(41% - 40px)',
    position: 'flex-end',
    formatter: (val: any) => <EndCellFormatted val={val} />,
  },
];

export const topAdsColumns: TableColumn[] = [
  {
    dataField: 'index',
    text: '',
    width: '30px',
    formatter: (val: any) => <IndexCellFormatted val={val} />,
  },
  {
    dataField: 'ad',
    text: '',
    width: '50px',
    formatter: (val: any) => <ImageFormatted val={val} />,
  },
  {
    dataField: 'ad',
    text: 'Id/Merchant',
    width: '55%',
    position: 'flex-start',
    formatter: (val: any) => <AdMultiLineFormatted val={val} />,
  },
  {
    dataField: 'epc',
    text: 'EPC',
    width: 'calc(45% - 80px)',
    position: 'flex-end',
    formatter: (val: any) => <EndCellFormatted val={Math.round((val + Number.EPSILON) * 100) / 100} />,
  },
];

export const topPublishersColumns: TableColumn[] = [
  {
    dataField: 'index',
    text: '',
    width: '50px',
    formatter: (val: any) => <IndexInconsistantCellFormatted val={val} />,
  },
  {
    dataField: 'publisher',
    text: 'Name',
    width: '30%',
    position: 'flex-start',
    wrapText: true,
    formatter: (val: any) => <PublisherMultiLineFormatted val={val} />,
  },
  {
    dataField: 'totalClicks',
    text: 'Total Click',
    width: '15%',
    position: 'center',
    formatter: (val: any) => <SingleLineCenterCellFormatted val={val} />,
  },
  {
    dataField: 'totalTransactions',
    text: 'Total Transactions',
    width: '17%',
    position: 'center',
    formatter: (val: any) => <SingleLineCenterCellFormatted val={val} />,
  },
  {
    dataField: 'approvedTransactions',
    text: 'Approved Transactions',
    width: '18%',
    position: 'center',
    formatter: (val: any) => <SingleLineCenterCellFormatted val={val} />,
  },
  {
    dataField: 'totalCommissions',
    text: 'Total Commissions',
    width: 'calc(20% - 10px)',
    position: 'flex-end',
    formatter: (val: any) => <SingleLineEndCellFormatted val={val} />,
  },
];
