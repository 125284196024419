import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useUserInfo } from '../../../hooks';
import { USER_TYPES_ID } from '../../../utils';
import { LineChartStructure } from '../Charts/Line/types';
import { ADMIN_DASHBOARD, ADMIN_DASHBOARD_MERCHANT_PERFORMANCE } from '../graphql/queries';
import { Permission } from '../../../entities';

type MerchantData = {
  id: string
  merchant: {
    companyName: string
  }
  totalClicks: number | string
  totalTransactions: number | string
  approvedTransactions: number | string
  totalCommissions: number | string
}

type ProductStructure = {
  name: string,
  program: {
    merchant: {
      companyName: string
    }
  }
}

type ProductData = {
  product: ProductStructure
  totalCommissions: number | string
}

type AdStructure = {
  id: string
  adContent: string
  program: {
    merchant: {
      companyName: string
    }
  }
}

type AdData = {
  ad: AdStructure
  epc: number | string
}

type PublisherStructure = {
  companyName: string
  companyUrl: string
}

type PublisherData = {
  publisher: PublisherStructure
  totalClicks: number | string
  totalTransactions: number | string
  approvedTransactions: number | string
  totalCommissions: number | string
}

type DashboardData = {
  pendingApplications: number | string
  merchantAccountBalances: {
    warning: number | string
    danger: number | string
  }
  topMerchants: MerchantData[]
  merchantPerformance: LineChartStructure
  topPublishers: PublisherData[]
  topProducts: ProductData[]
  topAds: AdData[]
}

export const useDashboard = (permissionsCodeList: string[] = []) => {
  const today = new Date();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date(today.setHours((23 - 8), 59, 59, 999)));
  const [range, setRange] = useState(`${startDate.toDateString()} - ${endDate.toDateString()}`);
  const [merchantPerformance, setMerchantPerformance] = useState<LineChartStructure>({
    totalTransactions: [0],
    approvedTransactions: [0],
    totalCommissions: [0],
  });
  const [merchantList, setMerchantList] = useState<SelectOption[]>([]);
  const [topMerchant, setTopMerchant] = useState<SelectOption>({ label: 'Select...', value: '' } as SelectOption);
  const [pendingApplications, setPendingApplications] = useState<number | string | undefined>();
  const [merchantData, setMerchantData] = useState<MerchantData[]>([]);
  const [productData, setProductData] = useState<ProductData[]>([]);
  const [adData, setAdData] = useState<AdData[]>([]);
  const [publisherData, setPublisherData] = useState<PublisherData[]>([]);
  const [loading, setLoading] = useState(true);
  const userHook = useUserInfo();

  // queries
  const [getAdminDashboard, { loading: getAdminDashboardLoading }] = useLazyQuery(ADMIN_DASHBOARD);
  const [getAdminDashboardMerchantPerformance] = useLazyQuery(ADMIN_DASHBOARD_MERCHANT_PERFORMANCE);

  const setCalendarOpenHandler = () => {
    setCalendarOpen(!calendarOpen);
  };

  const closeCalendarHandler = () => {
    setCalendarOpen(false);
  };

  const setDateRangeHandler = (firstDate: Date, lastDate: Date | undefined, dateRange: string | undefined) => {
    setStartDate(firstDate);
    if (lastDate) {
      setEndDate(lastDate);
      setRange(`${firstDate.toDateString()} - ${lastDate.toDateString()}`);
    }
    if (dateRange) {
      setRange(dateRange);
    }
    closeCalendarHandler();
  };

  const setMerchantListHandler = (topMerchantsData: MerchantData[]) => {
    setMerchantList(topMerchantsData.map(({ id, merchant: { companyName } }) => ({
      label: `${id} - ${companyName}`,
      value: id,
    })));
  };

  const setLineChartValues = async (selected: SelectOption) => {
    setTopMerchant(selected);
    const { data } = await getAdminDashboardMerchantPerformance({
      variables: {
        input: {
          endDate: endDate.toISOString(),
          merchantId: selected.value,
        },
      },
    });
    setMerchantPerformance(data.adminDashboardMerchantPerformance);
  };

  const formatWithIndex = (tableRecords: any[]) => tableRecords.map((item, index) => ({
    index: index + 1,
    ...item,
  }));

  const setTableData = (adminDashboardData: DashboardData) => {
    setPendingApplications(adminDashboardData.pendingApplications);
    setMerchantData(formatWithIndex(adminDashboardData.topMerchants));
    setProductData(formatWithIndex(adminDashboardData.topProducts));
    setAdData(formatWithIndex(adminDashboardData.topAds));
    setPublisherData(formatWithIndex(adminDashboardData.topPublishers));
  };

  useEffect(() => {
    const loadData = async () => {
      const { data } = await getAdminDashboard({
        variables: {
          input: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        },
      });
      setMerchantPerformance(data.adminDashboard.merchantPerformance);
      setMerchantListHandler(data.adminDashboard.topMerchants);
      const [{ id, merchant: { companyName } }] = data.adminDashboard.topMerchants;
      setTopMerchant({ label: `${id} - ${companyName}`, value: id });
      setTableData(data.adminDashboard);
    };

    loadData()
      .then(() => setLoading(false));
  }, [startDate, endDate]);

  return {
    hookIsLoading: getAdminDashboardLoading || loading,
    hookStartDate: startDate,
    hookEndDate: endDate,
    hookDateRange: range,
    hookCalendarOpen: calendarOpen,
    hookOpenCalendar: setCalendarOpenHandler,
    hookCloseCalendar: closeCalendarHandler,
    hookSetDateRange: setDateRangeHandler,

    hookPendingApplications: pendingApplications,
    hookMerchantData: merchantData,
    hookProductData: productData,
    hookAdData: adData,
    hookPublisherData: publisherData,

    hookTopMerchants: merchantList,
    hookTopMerchant: topMerchant,

    hookMerchantPerformance: merchantPerformance,
    hookSetMerchantPerformance: setLineChartValues,
    hookIsAdmin: userHook.hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN,
    hookCanAccess: userHook.hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN && Permission.canAccess(permissionsCodeList),
  };
};
