import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination, Table } from '../../../components';
import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

type ButtonContainerStyledProps = {
  readonly width?: string
}

type ButtonWrapperStyledProps = {
  readonly width?: string
}

type ModalSpanStyledProps = {
  readonly bold?: boolean
}

type TableDataMothlyStyledProps = {
  readonly theme?: boolean
}

type TableDataStyledProps = {
  readonly theme?: string
}

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${toRem(50)};
`;

export const TableStyled = styled(Table)`
  margin: ${toRem(40)} 0;
`;

export const TableDataStyled = styled.div<TableDataStyledProps>`
  display: flex;
  flex-direction: ${({ theme }) => (theme === 'secondary' ? 'column' : 'row')};
  align-items: ${({ theme }) => (theme === 'secondary' ? 'flex-start' : 'center')};
  min-height: 48px;
  line-height: ${toRem(24)};

  svg {
    margin-right: ${toRem(10)};
    color: ${colors.color2};
  }
`;

export const TableDataMothlyStyled = styled.div<TableDataMothlyStyledProps>`
  display: flex;
  min-height: ${toRem(64)};
  line-height: ${toRem(24)};
  justify-content: ${({ theme }) => (theme === 'secondary' ? 'space-between' : 'flex-start')};
  align-items: center;
  flex-direction: row;

  svg {
    margin-right: ${toRem(10)};
    color: ${({ theme }) => (theme === 'secondary' ? colors.color1 : colors.color2)};
  }
`;

export const TableDataDetailStyled = styled.div`
  min-height: 35px;

  svg {
    margin-right: ${toRem(10)};
    color: ${colors.color10};
  }
`;

export const TableFooterStyled = styled.div`
  color: ${colors.color2};
`;

export const PaginationStyled = styled(Pagination)``;

export const SubtitleStyled = styled.div`
  ${fonts.gotham.H22}
`;

export const SubsectionStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${toRem(40)};
`;

export const ButtonContainerStyled = styled.div<ButtonContainerStyledProps>`
  display: flex;
  align-items: flex-end;
  width: ${({ width }) => width || ''};
`;

export const ButtonWrapperStyled = styled.div<ButtonWrapperStyledProps>`
  margin-left: ${toRem(24)};
  min-width: fit-content;
  width: ${({ width }) => width || ''};
`;

export const IconWrapperStyled = styled.span`
  margin-right: ${toRem(10)};
`;

export const ModalBodyStyled = styled.div`
  min-width: 798px;
  padding: ${toRem(50)} ${toRem(50)} ${toRem(10)};
`;

export const ModalGridStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${toRem(40)};
`;

export const ModalInputContainerStyled = styled.div`
  width: 45%;
`;

export const ModalTitleStyled = styled.div`
  ${fonts.gotham.H26M};
`;

export const ModalDescriptionStyled = styled.div`
  ${fonts.lato.H16};
  margin: ${toRem(50)} 0;
`;

export const ModalListStyled = styled.ul`
  ${fonts.lato.H16};
  list-style: disc;
  list-style-position: inside;
  line-height: ${toRem(24)};
  margin:  0 0 ${toRem(50)};
`;

export const ModalSpanStyled = styled.span<ModalSpanStyledProps>`
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BalanceRepostingWrapperStyled = styled.div`
  margin-bottom: ${toRem(20)};
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: ${toRem(8)};
`;

export const TableCellLink = styled.div`
  &:hover {
    svg {
      color: ${colors.color2};
    }
  }
`;
