import { gql } from '@apollo/client';

export const UPDATE_MEMBERSHIP_GROUP = gql`
  mutation updateMembershipGroup($input: [MembershipInput]) {
    updateMembershipGroup(inputs: $input) {
      count
      memberships {
        id
      }
    }
  }
`;

export type UpdateMembershipGroupInput = {
  input: {
    id: string
    publisherGroupIds: string[]
  }[]
}

export type UpdateMembershipGroupOutput = {
  updateMembershipGroup: {
    count: number
    memberships: {
      id: string
    }
  }
}
