export const MERCHANTS_LOADING = 'Loading Merchants...';

export const NEW_MERCHANT_STATUS_OPTIONS = [
  {
    label: 'Pending Application',
    value: 'Pending',
  },
  {
    label: 'Integrating',
    value: 'Integrating',
  },
  {
    label: 'Go Live Pending',
    value: 'LivePending',
  },
  {
    label: 'Live',
    value: 'Approved',
  },
  {
    label: 'Paused',
    value: 'Paused',
  },
  {
    label: 'Closed',
    value: 'Closed',
  },
  {
    label: 'Closed',
    value: 'Suspended',
  },
  {
    label: 'Closed',
    value: 'Declined',
  },
];

export const ACCOUNT_STATUS_OPTIONS = [
  {
    label: 'Approved',
    value: 'Approved',
  },
  {
    label: 'Pending',
    value: 'Pending',
  },
  {
    label: 'Suspended',
    value: 'Suspended',
  },
  {
    label: 'Declined',
    value: 'Declined',
  },
  {
    label: 'Closed',
    value: 'Closed',
  },
];

export const NEW_MERCHANT_TYPES_OPTIONS = [
  {
    label: 'Self Served',
    value: 'Self',
  },
  {
    label: 'Managed',
    value: 'Managed',
  },
  {
    label: 'Results',
    value: 'Results',
  },
];
