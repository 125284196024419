export const SAVE_REPORT_PROFILE_PERMISSIONS_OPTIONS = [
  {
    label: 'Only Me',
    value: null,
  },
  {
    label: 'All users on the account',
    value: 1,
  },
];

export const SAVE_REPORT_PROFILE_SCHEDULE_FREQUENCY_OPTIONS = [
  {
    label: 'Never',
    value: '',
  },
  {
    label: 'Daily',
    value: 'Daily',
  },
  {
    label: 'Weekly',
    value: 'Weekly',
  },
  {
    label: 'Monthly',
    value: 'Monthly',
  },
];

export const SAVE_REPORT_PROFILE_SCHEDULE_WEEK_OPTIONS = [
  {
    label: 'Every Monday',
    value: 'Monday',
  },
  {
    label: 'Every Tuesday',
    value: 'Tuesday',
  },
  {
    label: 'Every Wednesday',
    value: 'Wednesday',
  },
  {
    label: 'Every Thursday',
    value: 'Thursday',
  },
  {
    label: 'Every Friday',
    value: 'Friday',
  },
];

export const SAVE_REPORT_PROFILE_SCHEDULE_MONTH_OPTIONS = [
  {
    label: 'Every 1st Day',
    value: '1',
  },
  {
    label: 'Every 2nd Day',
    value: '2',
  },
  {
    label: 'Every 3rd Day',
    value: '3',
  },
  {
    label: 'Every 4th Day',
    value: '4',
  },
  {
    label: 'Every 5th Day',
    value: '5',
  },
  {
    label: 'Every 6th Day',
    value: '6',
  },
  {
    label: 'Every 7th Day',
    value: '7',
  },
  {
    label: 'Every 8th Day',
    value: '8',
  },
  {
    label: 'Every 9th Day',
    value: '9',
  },
  {
    label: 'Every 10th Day',
    value: '10',
  },
  {
    label: 'Every 11th Day',
    value: '11',
  },
  {
    label: 'Every 12th Day',
    value: '12',
  },
  {
    label: 'Every 13th Day',
    value: '13',
  },
  {
    label: 'Every 14th Day',
    value: '14',
  },
  {
    label: 'Every 15th Day',
    value: '15',
  },
  {
    label: 'Every 16th Day',
    value: '16',
  },
  {
    label: 'Every 17th Day',
    value: '17',
  },
  {
    label: 'Every 18th Day',
    value: '18',
  },
  {
    label: 'Every 19th Day',
    value: '19',
  },
  {
    label: 'Every 20th Day',
    value: '20',
  },
  {
    label: 'Every 21st Day',
    value: '21',
  },
  {
    label: 'Every 22nd Day',
    value: '22',
  },
  {
    label: 'Every 23rd Day',
    value: '23',
  },
  {
    label: 'Every 24th Day',
    value: '24',
  },
  {
    label: 'Every 25th Day',
    value: '25',
  },
  {
    label: 'Every 26th Day',
    value: '26',
  },
  {
    label: 'Every 27th Day',
    value: '27',
  },
  {
    label: 'Every 28th Day',
    value: '28',
  },
  {
    label: 'Every 29th Day',
    value: '29',
  },
  {
    label: 'Every 30th Day',
    value: '30',
  },
  {
    label: 'Every 31st Day',
    value: '31',
  },
];

export const PERMISSION_OPTIONS = {
  ONLY_ME: SAVE_REPORT_PROFILE_PERMISSIONS_OPTIONS[0].value,
  ALL_USERS: SAVE_REPORT_PROFILE_PERMISSIONS_OPTIONS[1].value,
};
