import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, ErrorBanner } from '../../../../components';
import Progress from '../../../../components/Progress';
import environment from '../../../../config/environment';
import { imageList, path } from '../../../../utils';
import * as Styled from './styles';
import { useConfirmation } from './hooks';
import { SIGN_UP_ACTIONS } from '../../reducers';
import { SIGN_UP } from '../enums';
import { CONFIRMATION } from './enums';

const Confirmation = () => {
  const hook = useConfirmation();

  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img
            src={imageList.logoFull.src}
            alt="logo"
          />
        </figure>

        <Progress step={6} />
      </Styled.LeftStyled>

      <Styled.RightStyled>
        {!hook.hookShowConfirmation ? (
          <Styled.RightContentStyled>
            <ErrorBanner
              message={hook.hookErrorMessage}
              isOpen={!!hook.hookErrorMessage}
            />
            <h1>{SIGN_UP.TITLE}</h1>
            <h2><pre>{`Step 6   ${path.createAccountStep6.name}`}</pre></h2>
            <Styled.CheckboxStyled
              label={CONFIRMATION.CONDITIONS}
              checked={hook.hookContextState.subscribeUpdates}
              onChange={(e: boolean) => hook.hookContextDispatcher({
                type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                data: {
                  subscribeUpdates: e,
                },
              })}
            />
            <Styled.CheckboxStyled
              label={CONFIRMATION.OFFERS}
              checked={hook.hookContextState.subscribeOffers}
              onChange={(e: boolean) => hook.hookContextDispatcher({
                type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                data: {
                  subscribeOffers: e,
                },
              })}
            />
            <Styled.TermsAndConditionsStyled>
              <Styled.CheckboxStyled
                label={CONFIRMATION.TERMS}
                checked={hook.hookContextState.agreesTC}
                onChange={(e: boolean) => hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: {
                    agreesTC: e,
                  },
                })}
              />
              <a
                href="https://www.fintelconnect.com/terms-and-conditions/"
                target="_blank"
                rel="noreferrer"
              >
                Terms And Conditions
              </a>
            </Styled.TermsAndConditionsStyled>

            <ReCAPTCHA
              sitekey={environment.google.captcha}
              onChange={hook.hookCaptchaHandler}
              grecaptcha={hook.hookCaptchaRef}
              onExpired={() => hook.hookCaptchaHandler(undefined)}
            />
            <Styled.FormStyled>
              <Button
                onClick={() => hook.hookNavigate(path.createAccountStep5.href)}
                theme="secondary"
              >
                {CONFIRMATION.BACK}
              </Button>
              <Button
                disabled={hook.hookNextDisabled}
                onClick={hook.hookCreatePublisher}
                loading={hook.hookNextLoading}
              >
                {CONFIRMATION.SUBMIT}
              </Button>
            </Styled.FormStyled>
          </Styled.RightContentStyled>
        ) : (
          <Styled.ShowConfirmStyled>
            <Styled.FigureStyled>
              <Styled.ImageStyled
                src={imageList.newPublisher.src}
                alt="logo"
              />
            </Styled.FigureStyled>
            <Styled.TitleStyled>{CONFIRMATION.TITLE}</Styled.TitleStyled>
            <Styled.DescriptionStyled>
              {CONFIRMATION.DESCRIPTION}
            </Styled.DescriptionStyled>
            <Styled.DescriptionStyled>
              {CONFIRMATION.REVIEW}
            </Styled.DescriptionStyled>
            <Styled.DescriptionStyled>
              {CONFIRMATION.REACH_OUT}
              <a href="mailto:info@fintelconnect.com">{CONFIRMATION.EMAIL}</a>
              .
            </Styled.DescriptionStyled>
            <Styled.ReturnToLoginStyled>
              <Button
                onClick={() => hook.hookNavigate(path.login.href)}
                theme="secondary"
              >
                {CONFIRMATION.RETURN_TO_LOGIN}
              </Button>
            </Styled.ReturnToLoginStyled>
          </Styled.ShowConfirmStyled>
        )}
      </Styled.RightStyled>
    </Styled.WrapperStyled>
  );
};

export default Confirmation;
