import { gql } from '@apollo/client';

export const LIST_SEARCH_MERCHANTS = gql`
  query companies($input: ListSearchCompanyInput) {
    listSearchMerchants(input: $input) {
      companies {
        id
        companyImgUrl
        companyName
        companyUrl
        country
        accountStatus
        networkStatus
        createdAt
        merchantType
      }
      count
    }
  }
`;
