import { useQuery } from '@apollo/client';
import { LIST_PUBLISHER_FILTER_OPTIONS } from '../graphql/queries/listPublisherFilterOptions';

export const usePublisherFilterOptions = () => {
  const APPROVED = 'Approved';
  const statusTypes: any[] = [
    {
      label: 'All Account Statuses',
      value: '',
    },
  ];

  const taxTypes: any[] = [
    {
      label: 'All Tax Statuses',
      value: '',
    },
  ];

  const markets: any[] = [
    {
      label: 'All Audience Markets',
      value: '',
    },
  ];

  const categories: any[] = [
    {
      label: 'All Product Categories',
      value: '',
    },
  ];

  const methods: any[] = [
    {
      label: 'All Promotion Methods',
      value: '',
    },
  ];

  const audiences: any[] = [
    {
      label: 'All Audience Size',
      value: '',
    },
  ];

  const activities: any[] = [
    {
      label: 'All Activity Status',
      value: '',
    },
  ];

  const ages: any[] = [
    {
      label: 'All Ages',
      value: '',
    },
  ];
  const genders: any[] = [];

  const creditScoreRatings: any[] = [
    {
      label: 'All Credit Score Ratings',
      value: '',
    },
  ];

  const incomes: any[] = [
    {
      label: 'All Incomes',
      value: '',
    },
  ];

  const paymentMethods: any[] = [
    {
      label: 'All Payment Methods',
      value: '',
    },
  ];

  const countries: any[] = [
    {
      label: 'All Countries',
      value: '',
    },
  ];

  const { data, loading } = useQuery(LIST_PUBLISHER_FILTER_OPTIONS, {
    variables: {
      input: {
        companyType: 'Publisher',
      },
    },
  });

  data?.getSearchCompaniesFilterOptions.accountStatusTypes.forEach((item: any) => {
    if (item.publisherDisplayValue !== APPROVED || (item.publisherDisplayValue === APPROVED && item.type === APPROVED)) {
      statusTypes.push({
        label: item.publisherDisplayValue,
        value: item.type,
      });
    }
  });

  data?.getSearchCompaniesFilterOptions.taxStatusTypes.forEach((item: any) => {
    taxTypes.push({
      label: item.type,
      value: item.type,
    });
  });

  data?.getSearchCompaniesFilterOptions.audienceMarkets.forEach((item: any) => {
    markets.push({
      label: item.name,
      value: item.name,
    });
  });

  data?.getSearchCompaniesFilterOptions.productCategories.forEach((item: any) => {
    categories.push({
      label: item.name,
      value: item.name,
    });
  });

  data?.getSearchCompaniesFilterOptions.promotionMethods.forEach((item: any) => {
    methods.push({
      label: item.type,
      value: item.type,
    });
  });

  data?.getSearchCompaniesFilterOptions.averageMonthlyAudience.forEach((item: any) => {
    audiences.push({
      label: item.range,
      value: item.range,
    });
  });

  data?.getSearchCompaniesFilterOptions.activityStatusTypes.forEach((item: any) => {
    activities.push({
      label: item.type,
      value: item.type,
    });
  });

  data?.getSearchCompaniesFilterOptions.audienceAges.forEach((item: any) => {
    ages.push({
      label: item.range,
      value: item.range,
    });
  });

  data?.getSearchCompaniesFilterOptions.audienceGenders.forEach((item: any) => {
    genders.push({
      label: item.type,
      value: item.type,
    });
  });

  data?.getSearchCompaniesFilterOptions.creditScoreRatings.forEach((item: any) => {
    creditScoreRatings.push({
      label: item.type,
      value: item.type,
    });
  });

  data?.getSearchCompaniesFilterOptions.averageIncomes.forEach((item: any) => {
    incomes.push({
      label: item.range,
      value: item.range,
    });
  });

  data?.getSearchCompaniesFilterOptions.paymentMethods.forEach((item: any) => {
    paymentMethods.push({
      label: item.type,
      value: item.type,
    });
  });

  data?.getSearchCompaniesFilterOptions.countries.forEach((item: any) => {
    countries.push({
      label: item.name,
      value: item.name,
    });
  });

  return {
    hookAccountStatusTypeList: statusTypes,
    hookTaxStatusTypeList: taxTypes,
    hookAudienceMarketList: markets,
    hookCountriesList: countries,
    hookProductCategoryList: categories,
    hookPromotionMethodList: methods,
    hookMonthlyAudienceList: audiences,
    hookActivityStatusTypeList: activities,
    hookAudienceAgesList: ages,
    hookAudienceGenderList: genders,
    hookCreditScoreList: creditScoreRatings,
    hookAverageIncomeList: incomes,
    hookPaymentMethodList: paymentMethods,
    hookFilterOptionsLoading: loading,
  };
};
