import { gql } from '@apollo/client';

export const MERCHANT_ACCOUNT_BALANCES = gql`
  query accountBalances($input: AccountBalanceInputV2!) {
      accountBalancesV2(input: $input) {
        accountBalances {
          id
          merchantId
          merchant
          month
          year
          finalized
          balance
          deposits
          rollover
          productCategory
          minBalanceRequired
          totalCommissions
          otherFee
          manualAdjustment
          systemUsageFee
          totalTax
        }
        accountBalanceTotals {
          rollover
          deposits
          commissions
          manualAdjustments
          systemFee
          otherFees
          taxes
          balance
        }
        count
      }
    }
`;
