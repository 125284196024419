import { GMMembershipType } from '../graphql/queries';
import { PublisherMember } from '../types';

export const formatMemberships = (memberships: GMMembershipType[]) => {
  const formattedMemberships: PublisherMember[] = memberships.map((mem): PublisherMember => ({
    ...mem,
    publisherId: mem.publisher.id,
    publisherNum: Number(mem.publisher.id),
    companyName: mem.publisher.companyName || '',
    primaryWebsite: mem.publisher.trackings[0].primaryWebsite || '',
    primaryCategory: mem.publisher.trackings[0].primaryCategory || '',
    promotingProduct: mem.publisher.trackings[0].promotingProduct?.reduce((acc, curr, index) => (index ? `${acc}, ${curr}` : curr), '') || '',
    websiteTraffic: mem.publisher.trackings[0].websiteTraffic || '',
    promotionMethod: [...mem.publisher.trackings[0].promotionMethod || []].sort(),
    applicationDate: mem.createdAt || '',
    publisherGroups: mem.publisherGroups || [],
    country: mem.publisher?.country || '', // For Search
    state: mem.publisher?.state || '', // For Search
    pendingTransactions: !!mem.pendingTrans,
    emailString: mem.publisher.users.map((x) => x.email).join(), // For Search
    trackingUrlString: mem.publisher.trackings.map((x) => x.primaryWebsite).join(), // For Search
    checked: false,
    disabled: false,
  }));
  return formattedMemberships;
};
