import { gql } from '@apollo/client';

export const CHECK_COMMISSION_CONFLICTS = gql`
  query CheckCommissionConflicts($input: CheckCommissionInput) {
    checkCommissionConflicts(input: $input) {
      count
      commissions {
        id
        startDate
        endDate
      }
    }
  }
`;
