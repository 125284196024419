import styled from 'styled-components';
import { colors, fonts } from '../../../../../../styles/theme';

export const FeedModalWrapperStyled = styled.div`
  color: ${colors.color1};
  display: flex;
  flex-direction: column;
  width: 725px;
  min-height: 300px;
  padding: 16px;
`;

export const FeedTableSectionStyled = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 24px;
  align-items: center;
`;

export const TitleText = styled.h2`
  ${fonts.gotham.H24M};
  color: ${colors.color1};
`;
