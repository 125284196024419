import styled from 'styled-components';
import { Button, InputText, Select } from '../../../../../../components';
import { colors, fonts } from '../../../../../../styles/theme';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
`;

export const SectionStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 4rem;
`;

export const SectionTitleStyled = styled.div`
  width: 100%;
  color: ${colors.color1};
  ${fonts.gotham.H16M}
`;

export const SectionSubtitleStyled = styled.div`
  width: 100%;
  ${fonts.lato.H14}
  margin: 1.2rem 0;
`;

export const SectionContentStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SectionContentRadioStyled = styled(SectionContentStyled)`
  flex-direction: column;
  align-items: flex-start;
`;

export const RadioStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const RadioLabelStyled = styled.div`
  ${fonts.lato.H14}
  margin-left: 0.5rem;
`;

export const ButtonStyled = styled(Button)`
  margin-right: 1rem;
`;

export const TimeFrameStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectionAreaStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;

  i {
    margin-right: 0.5rem;
    ${fonts.lato.H14}
  }
`;

export const SelectStyled = styled(Select)`
  width: 150px;
  margin-right: 1rem;
  z-index: 10;
`;

export const ShowPublisherSelectionStyled = styled.div`
  ${fonts.lato.H14}
  color: ${colors.color12};
  margin-top: 1rem;
`;

export const InstructionsHeaderStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10.5rem;
  ${fonts.lato.H16M}
`;

export const InstructionsTextStyled = styled.div`
  width: 18rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
  line-height: 1.5em;
  ${fonts.lato.H14}
`;

export const NextReport = styled.p`
  margin-top: 1rem;
  ${fonts.lato.H14}
  color: ${colors.color2};
`;

export const InputStyled = styled(InputText)`
  width: 150px;
  margin-right: 1rem;
`;
