import React from 'react';
import * as Styled from './styles';

export const LoadingDots = () => (
  <Styled.WrapperStyled>
    <Styled.EllipsisStyled>
      <Styled.DotsStyled />
      <Styled.DotsStyled />
      <Styled.DotsStyled />
      <Styled.DotsStyled />
    </Styled.EllipsisStyled>
  </Styled.WrapperStyled>
);
