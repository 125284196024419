import { gql } from '@apollo/client';

export const LIST_PUBLISHER_GROUPS = gql`
  query ListPublisherGroup($input: ListPublisherGroupInput) {
    listPublisherGroup(input: $input) {
      list {
        id
        name
      }
    }
  }
`;
