import React from 'react';
import { useAddMonitoringRuleModal } from './hooks';
import { MonitoringRuleSummaryModal } from '../MonitoringRuleSummaryModal';
import {
  AddRuleStepOneModal, AddRuleStepTwoModal, CancelAddRuleModal,
} from './components';

type RuleDetailsModalProps = {
  closeModalFn: (state: boolean) => void
  isOpen: boolean
  newRuleCreated: () => void
}

export const AddMonitoringRuleModal = ({
  closeModalFn, isOpen, newRuleCreated,
}: RuleDetailsModalProps) => {
  const hook = useAddMonitoringRuleModal(closeModalFn, newRuleCreated);

  return (
    <>
      <CancelAddRuleModal
        isOpen={hook.hookCancelOpen && isOpen}
        cancelButtonHandler={hook.hookCancelButtonHandler}
        exitAddRuleModalHandler={hook.hookExitAddRuleModalHandler}
      />
      <AddRuleStepOneModal
        isOpen={hook.hookCurrentModal === 1 && !hook.hookCancelOpen && isOpen}
        errorMessage={hook.hookErrorMessage}
        ruleName={hook.hookRuleName}
        setRuleName={hook.hookSetRuleName}
        ruleNameError={hook.hookRuleNameError}
        selectedCheckRuleGroup={hook.selectedCheckRuleGroup}
        checkRuleGroupsList={hook.checkRuleGroupsList}
        setSelectedCheckRuleGroupHandler={hook.setSelectedCheckRuleGroupHandler}
        cancelButtonHandler={() => hook.hookCancelButtonHandler(true)}
        navigateModalTwo={hook.hookNavigateModalTwo}
        canContinueOne={hook.hookCanContinueOne}
        ruleNameCheckLoading={hook.hookLoadingNavigateModalTwo}
      />

      <AddRuleStepTwoModal
        isOpen={hook.hookCurrentModal === 2 && !hook.hookCancelOpen && isOpen}
        uniqueErrors={hook.uniqueErrors}
        backButtonHandler={hook.hookBackButtonHandler}
        customTextList={hook.customTextList}
        updateCustomTextValue={hook.updateCustomTextValue}
        addNewCustomText={hook.addNewCustomText}
        stepTwoErrors={hook.hookStepTwoErrors}
        onBlurCheck={hook.hookOnBlurCheck}
        removeCustomTextById={hook.removeCustomTextById}
        cancelButtonHandler={() => hook.hookCancelButtonHandler(true)}
        navigateModalThree={hook.hookNavigateModalThree}
        canContinueTwo={hook.hookCanContinueTwo}
        canAddMoreCustomText={hook?.canAddMoreCustomText}
      />

      <MonitoringRuleSummaryModal
        closeModalFn={() => hook.hookCancelButtonHandler(true)}
        goBackFn={() => hook.hookSetCurrentModal(2)}
        isOpen={hook.hookCurrentModal === 4 && !hook.hookCancelOpen && isOpen}
        errorMessage={hook.hookCreateRuleError}
        isNewRule
        value={hook.newSummary}
        createRuleFn={hook.hookCreateRule}
        createRuleLoading={hook.hookCreateRuleLoading}
      />
    </>
  );
};
