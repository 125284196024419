import { gql } from '@apollo/client';

export const CREATE_KEY = gql`
  mutation CreateApiKey($input: ApiAccessKeyInput) {
    createApiAccessKey(input: $input) {
      apiAccessKey
      status
    }
  }
`;
