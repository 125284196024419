import { gql } from '@apollo/client';

export const GET_PUBLISHER_INVITES = gql`
  query publisherInvitationManagement {
    publisherInvitationManagement {
      id
      merchantType
      enabled
      limitPerMonth
    }
  }
`;
