import React from 'react';
import { CellWrapper } from '../../../../ManageCommissions/components';
import { Checkbox } from '../../../../../../../components';
import * as Styled from '../styles';
import { EDIT_COMMISSIONS } from '../../../enums';
import { dateFormatter } from '../../../../../../../utils';
import { PublisherRow } from '../../../hooks';

const CheckBoxCellFormatter = (checked: boolean, onChange: (state: any) => void, isReadOnly: boolean) => (
  <Styled.CellWrapperStyled theme="checkBox">
    <Checkbox
      checked={checked}
      onChange={onChange}
      disabled={isReadOnly}
    />
  </Styled.CellWrapperStyled>
);

export const columns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'checkBox',
    text: '',
    formatter: (_: any, row: PublisherRow, __: any, ___: any, handleCheckBox?: (publisher: PublisherRow, add: boolean) => void) => (CheckBoxCellFormatter(row.selected, ((state: boolean) => { if (handleCheckBox) handleCheckBox(row, state); }), isReadOnly)),
    width: '48px',
    isCheckBox: true,
  },

  {
    dataField: 'idNum',
    text: EDIT_COMMISSIONS.PUBLISHERS.HEADING.ID,
    formatter: (cell: any) => (
      <CellWrapper>
        {cell}
      </CellWrapper>
    ),
    width: '100px',
  },
  {
    dataField: 'name/type',
    text: EDIT_COMMISSIONS.PUBLISHERS.HEADING.NAME_TYPE,
    formatter: (cell: any, row: PublisherRow) => (
      <CellWrapper>
        <b>
          {row.name}
        </b>
        <p>
          {row.type || 'Custom'}
        </p>
      </CellWrapper>
    ),
    width: 'calc(95% - 360px)',
  },
  {
    dataField: 'startDate',
    text: EDIT_COMMISSIONS.PUBLISHERS.HEADING.START_END,
    formatter: (cell: any, row: PublisherRow) => (
      <Styled.DateCellStyled>
        <span>{row.startDate ? dateFormatter(row.startDate) : ''}</span>
        <span>-</span>
        <span>{row.endDate ? dateFormatter(row.endDate) : ''}</span>
      </Styled.DateCellStyled>
    ),
    width: '280px',
  },
];
