import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { dateFormatter, path, PUBLISHER_PREFIX } from '../../../../utils';
import { GET_PROGRAM } from '../graphql/ queries';
import { useQueries, useUserInfo } from '../../../../hooks';
import {
  MENU_BACK, MENU_CANCEL, MENU_APPLY, MENU_APPROVE, MENU_DECLINE, PENDING, APPROVED, DECLINED, CLOSED,
} from '../enum';
import { GET_PRODUCTS } from '../graphql/ queries/getProducts';
import { UPDATE_MEMBERSHIPS, CREATE_MEMBERSHIP } from '../graphql/mutations';
import { Permission } from '../../../../entities';

export const useProgramDetails = (permissionsCodeList: string[] = []) => {
  const { id } = useQueries();
  const navigate = useNavigate();
  const [program, setProgram] = useState<any>();
  const [termsTitle, setTermsTitle] = useState('');
  const [termsCondition, setTermsCondition] = useState('');
  const [description, setDescription] = useState('');
  const [detailedDescription, setDetailedDescription] = useState('');
  const [productCategories, setProductCategories] = useState('');
  const [website, setWebsite] = useState('');
  const [productMatrix, setProductMatrix] = useState('');
  const user = useUserInfo();
  const [getProgram, { loading: getProgramLoading }] = useLazyQuery(GET_PROGRAM);
  const [buttonOptions, setButtonOptions] = useState<string[]>([]);
  const [merchant, setMerchant] = useState<any>({});
  const [targetMarkets, setTargetMarkets] = useState('');
  const [date, setDate] = useState('');
  const [isMember, setMember] = useState(false);
  const [productData, setProductData] = useState<any[]>([]);
  const [getProducts, { loading: getPorductsLoading }] = useLazyQuery(GET_PRODUCTS);
  const [specialOffer, setSpecialOffer] = useState('');
  const [stats, setStats] = useState('');
  const [cookie, setCookie] = useState('');
  const [term, setTerm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [member, setMemberShip] = useState<any>();
  let programidUrl = '';

  const handleMarkets = (products: any) => {
    const countrySet = new Set();

    products.forEach((item: any) => {
      const country = item.targetDemographics?.geographicalCountry;
      if (country && country !== '') {
        countrySet.add(country);
      }
    });
    return Array.from(countrySet).join(', ');
  };

  const handleProductCategories = (products: any) => {
    const categorySet = new Set();

    products.forEach((item: any) => {
      const category = item.productCategory;
      if (category && category !== '') {
        categorySet.add(category);
      }
    });

    const categories = Array.from(categorySet);
    return categories.join(', ');
  };

  const handleGetProducts = async () => {
    const { data } = await getProducts({
      variables: {
        programId: id ?? programidUrl,
        status: 'Active',
      },
      fetchPolicy: 'no-cache',
    });
    if (data.products !== undefined) {
      const { products } = data.products;
      setProductData(products);
      setTargetMarkets(handleMarkets(products));
      setProductCategories(handleProductCategories(products));
    } else {
      setErrorMessage('Oops! Failed to Fetch Products');
    }
  };

  const getMenuActions = (membership: any) => {
    // publisher is not a member of the merchant
    if (!membership?.status) return [MENU_BACK, MENU_APPLY];
    const merchantInvitePublisher = !!membership?.publisherInvitation?.id;
    const lastUpdated = membership?.statusLastUpdatedBy;
    if (membership.status === CLOSED || membership.status === DECLINED) {
      setTerm(false);
      return (lastUpdated === 'Merchant' || (!lastUpdated && !merchantInvitePublisher)) ? [MENU_BACK] : [MENU_BACK, MENU_APPLY];
    }
    switch (membership.status) {
      case PENDING: return (lastUpdated === 'Merchant' || (!lastUpdated && merchantInvitePublisher)) ? [MENU_BACK, MENU_DECLINE, MENU_APPROVE] : [MENU_BACK];
      case APPROVED: return [MENU_BACK, MENU_CANCEL];
      default: return [MENU_BACK];
    }
  };

  const onCloseHandler = (onClose: () => void) => {
    onClose();
  };

  const getProgramsHandler = async () => {
    const { data } = await getProgram({
      variables: {
        programId: id ?? programidUrl,
        currentPeriodAccountStats: false,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    if (data?.program !== undefined) {
      setProgram(data?.program);
      setTermsTitle(data?.program.termsConditionsTitle);
      setTermsCondition(data?.program.termsConditions);
      setDescription(data?.program.descriptionHighLevel);
      setDetailedDescription(data?.program.descriptionDetailed);
      setWebsite(data?.program.merchant.companyUrl);
      setProductMatrix(data?.program.productMatrixUrl);
      setMerchant(data.program.merchant);
      const membership = data.program.merchant?.memberships.find((obj: any) => obj.publisher.id === user.hookWhoAmI.companyId?.toString());
      setMemberShip(membership);
      setButtonOptions(getMenuActions(membership));
      setDate(dateFormatter(new Date(data.program.updatedAt)));
      const status = membership?.status || 'Apply Now';
      setMember(status === 'Approved');
      if (status === 'Approved' && data.program.membersOfferVisibility && data.program.membersOffer !== undefined) setSpecialOffer(data.program.membersOffer);
      if (status !== 'Approved' && data.program.signupBonusVisibility && data.program.signupBonus) setSpecialOffer(data.program.signupBonus);
      if (data.program.CookieDurationVisibility) setCookie(data.program.CookieDuration);
      if (data.program.statsFrequencyVisibility) setStats(data.program.statsFrequency);
    } else {
      setErrorMessage('Oops! Failed to Fetch Program');
    }
  };

  const onArrowHandler = (value: Record<string, any>) => {
    navigate(`${PUBLISHER_PREFIX}${path.productDetails.href}`, { state: value });
  };

  const checkTerm = () => {
    setTerm(!term);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    programidUrl = urlParams.get('programId') || '';
    getProgramsHandler();
    handleGetProducts();
  }, []);

  const [updateMembership, { loading: updateLoading }] = useMutation(UPDATE_MEMBERSHIPS);

  const updateMemebShips = async (status: string) => {
    await updateMembership({
      variables: {
        inputs: [{
          id: member.id,
          status,
        }],
        status,
        statusLastUpdatedBy: 'Publisher',
      },
    });
  };

  const [createMembership] = useMutation(CREATE_MEMBERSHIP);

  const handleCreateMembership = async (status: string) => {
    await createMembership({
      variables: {
        input: {
          programId: id ?? programidUrl,
          merchantId: merchant.id,
          publisherId: user.hookWhoAmI.companyId?.toString(),
          status,
          statusLastUpdatedBy: 'Publisher',
          subscription: false,
        },
      },
    });
  };

  const [cancelOpen, setCancelOpen] = useState(false);

  const handleCancel = () => {
    setCancelOpen(!cancelOpen);
  };

  const handleConfirmCancel = async () => {
    await updateMemebShips('Closed');
    getProgramsHandler();
    handleCancel();
  };

  const [decline, setDecline] = useState(false);

  const handleDecline = () => {
    setDecline(!decline);
  };

  const handleDeclineConfirm = async () => {
    await updateMemebShips('Declined');
    getProgramsHandler();
    handleDecline();
  };

  const [accept, setAccept] = useState(false);

  const handleAccept = () => {
    if (term) {
      setAccept(!accept);
      setErrorMessage('');
    } else {
      setErrorMessage('Oops! You must read and accept the Terms and Conditions by checking the box below.');
    }
  };

  const handleAcceptConfirm = async () => {
    await updateMemebShips('Approved');
    getProgramsHandler();
    handleAccept();
  };

  const handleApply = async () => {
    if (term) {
      if (member) {
        await updateMemebShips('Pending');
      } else {
        await handleCreateMembership('Pending');
      }
      setErrorMessage('');
      getProgramsHandler();
    } else {
      setErrorMessage('Oops! You must read and accept the Terms and Conditions by checking the box below.');
    }
  };

  return {

    hookHandleApply: handleApply,

    hookCancelOpen: cancelOpen,
    hookHandleCancel: handleCancel,
    hookCancelConfirm: handleConfirmCancel,
    hookCancelLoading: updateLoading,

    hookAcceptOpen: accept,
    hookHandleAccept: handleAccept,
    hookAcceptConfirm: handleAcceptConfirm,
    hookAcceptLoading: updateLoading,

    hookDecline: decline,
    hookHandleDecline: handleDecline,
    hookConfirmDecline: handleDeclineConfirm,
    hookDeclineLoading: updateLoading,

    hookOnClose: onCloseHandler,
    hookProgram: program,
    hookTermsTitle: termsTitle,
    hookTermsCondition: termsCondition,
    hookDescription: description,
    hookDetailedDescription: detailedDescription,
    hookProductCategories: productCategories,
    hookWebsite: website,
    hookProductMatrix: productMatrix,
    hookProgramsLoading: getProgramLoading,
    hookButtonOptions: buttonOptions,
    hookTargetMarkets: targetMarkets,
    hookDate: date,

    hookPageLoading: getProgramLoading || getPorductsLoading,
    hookOnArrow: onArrowHandler,

    hookMerchant: merchant,

    hookIsMember: isMember,

    hookProductData: productData,

    hookSpecialOffer: specialOffer,
    hookStats: stats,
    hookCookie: cookie,

    hookTermChecked: term,

    hookCheckTerm: checkTerm,

    hookErrorMessage: errorMessage,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
