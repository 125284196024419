import React, {
  useLayoutEffect, useRef, useState,
} from 'react';
import { useOutsideClickDetector } from '../../hooks';
import * as Styled from './styles';

type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
  theme?: string;
  closeModalFunction?: () => void
}

export const Modal = ({
  children, isOpen, className, theme, closeModalFunction = () => undefined,
}: ModalProps) => {
  const [isLarge, setIsLarge] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  // Changes the modals location if it too large to fit on the screen.
  useLayoutEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().height > (window.innerHeight + 16)) {
      setIsLarge(true);
    } else setIsLarge(false);
  }, [children, isOpen]);

  // Closes the Modal on clicks outside Modal if function to close modal given as prop
  useOutsideClickDetector(ref, (closeModalFunction));

  return (
    <Styled.WrapperStyled
      isOpen={isOpen}
      className={className}
      isLarge={isLarge}
    >
      <Styled.ContentStyled
        theme={theme}
        ref={ref}
      >
        {children}
      </Styled.ContentStyled>
    </Styled.WrapperStyled>
  );
};
