import React from 'react';
import {
  CellWrapper, DescriptionStyled, ProfileStyled, WebsiteStyled,
} from '../../styles';
import { ColumnProps } from '../../types';

export const WebsiteCell = ({ value }: ColumnProps) => {
  if (value.website !== '') {
    return (
      <CellWrapper value={value}>
        <ProfileStyled>
          {value.name}
        </ProfileStyled>
        <WebsiteStyled
          href={value.website}
          target="_blank"
        >
          {value.website}
        </WebsiteStyled>
      </CellWrapper>
    );
  }
  return (
    <CellWrapper value={value}>
      <ProfileStyled>
        {value.name}
      </ProfileStyled>
      <DescriptionStyled>{value.description}</DescriptionStyled>
    </CellWrapper>
  );
};
