import {
  Component, SetStateAction, useEffect, useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { findIndex, filter, isArray } from 'lodash';
import { useToast, useUserInfo } from '../../../hooks';
import { Permission } from '../../../entities';
import {
  RECORDS_PER_PAGE_OPTIONS, USER_TYPES_ID,
} from '../../../utils';
import {
  POSTBACK_DEFAULTS, defaultFilters, defaultSelectedFilters, inputDefaults,
} from '../enums';
import {
  CompanyOptionsType, PostbackType, FilterType, SelectedFilterType, MembershipQueryType as MembershipsQueryType, MembershipsResultType,
} from '../types';
import { GET_MEMBERSHIPS, GetMembershipsInputType, GetMembershipsOutputType } from '../graphql/queries/getMemberships';
import { GET_POSTBACKS, GetPostbacksInputType, GetPostbacksOutputType } from '../graphql/queries/getPostbacks';
import { CREATE_POSTBACK } from '../graphql/mutations/createPostback';
import { UPDATE_POSTBACK } from '../graphql/mutations/updatePostback';

/**
 * @name usePostbackDetails
 * @description A custom hook to encapsulate all the data and logic for the PostbackDetails component
 * @param {string} companyType The current user's company type (Merchant or Publisher)
 * @param {string} targetType The inverse of the current user's company type (Publisher or Merchant)
 * @param {string[]} permissionsCodeList A list of permission codes to check against
 * @returns {object} An object that contains all the data and logic for the PostbackDetails component
 */
export const usePostbackDetails = (companyType: string, targetType: string, permissionsCodeList: string[] = []) => {
  const { hookWhoAmI: whoAmI, hookUserInfo: userInfo } = useUserInfo();
  const { hookShowToast: showToast } = useToast();
  // Declare state variables
  const [memberships, setMemberships] = useState<MembershipsResultType[]>([]);
  const [postbacks, setPostbacks] = useState<PostbackType[]>([]);
  const [postback, setPostback] = useState<PostbackType>(POSTBACK_DEFAULTS);
  const [postbackId, setPostbackId] = useState<string | null>(null);
  const [companyId, setCompanyId] = useState<string>(whoAmI.companyId?.toString() || '0');
  const [filters, setFilters] = useState<FilterType>(defaultFilters);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilterType>(defaultSelectedFilters);
  const [filteredPostbacks, setFilteredPostbacks] = useState<PostbackType[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalPages, setTotalPages] = useState(1);

  // Table
  const [tableGenerated, setTableGenerated] = useState<boolean>(false);
  const [recordsPerPageList] = useState<SelectOption[]>(RECORDS_PER_PAGE_OPTIONS);
  const [selectedRecordsPerPage, setRecordsPerPage] = useState<SelectOption>(RECORDS_PER_PAGE_OPTIONS[0]);
  const [currentPage, setCurrentPage] = useState(1);

  // Calendar
  const today = new Date();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [startDate, setStartDate] = useState(defaultSelectedFilters.createdAt.start);
  const [endDate, setEndDate] = useState(today);
  const [dateRange, setDateRange] = useState('');

  // Queries and Mutations Declarations
  const [getPostbacksQuery, { loading: postbacksLoading }] = useLazyQuery<GetPostbacksOutputType, GetPostbacksInputType>(GET_POSTBACKS);
  const [getMembershipsQuery, { loading: membershipsLoading }] = useLazyQuery<GetMembershipsOutputType, GetMembershipsInputType>(GET_MEMBERSHIPS);
  const [createPostbackMutation] = useMutation(CREATE_POSTBACK);
  const [updatePostbackMutation] = useMutation(UPDATE_POSTBACK);

  /**
   * @name filterPostbacks
   * @description Applies the selected filters to the list of postbacks
   * @param {SelectedFilterType} criteria An object representing the filter selections
   * @param {PostbackType[]} updatedPostbacks (optional) A list of postbacks to filter
   * @returns {PostbackType[]} The filtered list of postbacks
   */
  const filterPostbacks = (criteria: SelectedFilterType, updatedPostbacks: PostbackType[] | boolean = false): PostbackType[] => {
    const { eventType, createdAt, companyOptions } = criteria;
    let newFilteredPostbacks = (updatedPostbacks && isArray(updatedPostbacks))
      ? [...updatedPostbacks]
      : [...postbacks];

    newFilteredPostbacks = filter(newFilteredPostbacks, (item) => { // Filter the postbacks
      let eventTypePredicate = true;
      let targetIdPredicate = true;
      let createdAtPredicate = true;

      // Apply transaction event type filter
      if (eventType.value !== '*') {
        eventTypePredicate = item.eventType === eventType.value;
      }

      // Apply merchant filter
      if (companyOptions.value === '*') { // "All transactions"
        targetIdPredicate = item.targetId === null;
      } else if (companyOptions.value !== '') {
        targetIdPredicate = item.targetId === companyOptions.value;
      }

      // Apply created date filter
      createdAtPredicate = moment(item.createdAt).isBetween(
        moment(createdAt.start).startOf('day'),
        moment(createdAt.end).endOf('day'),
      );

      return eventTypePredicate && targetIdPredicate && createdAtPredicate && !item.disabled;
    });
    setFilteredPostbacks(newFilteredPostbacks);

    return newFilteredPostbacks;
  };

  /**
   * @name buildComparator
   * @description Returns a function that compares two values for sorting
   * @param {string} keyName The name of the key to compare
   * @param {string} order The order to sort by
   */
  const buildComparator = (keyName: any, order: 'asc' | 'desc' | undefined = 'asc') => {
    const adjustment = order === 'desc' ? -1 : 1;
    const innerSort = (firstValue: any, secondValue: any) => {
      if (keyName === 'createdAt') {
        return (moment(firstValue.createdAt).isAfter(moment(secondValue.createdAt)) ? 1 : -1) * adjustment;
      }
      if (!Object.hasOwn(firstValue, keyName)) return -1;
      if (!Object.hasOwn(secondValue, keyName)) return 1;

      const left = typeof firstValue[keyName] === 'string' ? firstValue[keyName].toUpperCase().trim() : firstValue[keyName];
      const right = typeof secondValue[keyName] === 'string' ? secondValue[keyName].toUpperCase().trim() : secondValue[keyName];

      return ((left > right) ? 1 : -1) * adjustment;
    };

    return innerSort;
  };

  /**
   * @name sortPostbacks
   * @description Sorts the list of postbacks
   * @param {object} criteria An object representing the filter selections
   * @param {array} updatedPostbacks (optional) A list of postbacks to sort
   * @returns {array} The sorted list of postbacks
   */
  const sortPostbacks = (criteria: SelectedFilterType, updatedPostbacks: PostbackType[] | boolean = false): PostbackType[] => {
    const comparator = buildComparator(criteria.sortBy, criteria.sortDirection);
    const newFilteredPostbacks = (updatedPostbacks && isArray(updatedPostbacks))
      ? [...updatedPostbacks]
      : [...postbacks];
    const newSortedFilteredPostbacks = newFilteredPostbacks.sort(comparator);
    setPostbacks(newSortedFilteredPostbacks);

    const copy = structuredClone(
      filterPostbacks(criteria, newSortedFilteredPostbacks),
    );
    const sortedCopy = copy.sort(comparator);
    setFilteredPostbacks(sortedCopy.slice(
      (criteria.currentPage - 1) * criteria.limit,
      (criteria.currentPage) * criteria.limit,
    ));
    setTotalPages(Math.ceil(sortedCopy.length / criteria.limit));
    return sortedCopy;
  };

  /**
   * @name flushPostbacks
   * @description Replaces the list of postbacks, applies the selected filters, and sorts the results
   * @param {PostbackType[]} updatedPostbacks New list of postbacks
   * @returns {PostbackType[]} The filtered and sorted list of postbacks
   */
  const flushPostbacks = (updatedPostbacks: PostbackType[], page = 0): PostbackType[] => {
    setPostbacks(updatedPostbacks);
    setIsLoading(false);
    const newSelectedFilters = { ...selectedFilters };
    if (page > 0) newSelectedFilters.currentPage = page;
    return sortPostbacks(newSelectedFilters, updatedPostbacks);
  };

  /**
   * @name createMembershipListHandler
   * @description Creates a list of options for the company select dropdown
   * @param {MembershipsResultType[]} membershipList
   */
  const createMembershipListHandler = (membershipList: MembershipsResultType[]) => {
    const newCompanyList = [
      {
        value: '',
        label: `All ${targetType}s`,
      },
      inputDefaults.companyOptions,
    ];
    if (membershipList) {
      const listOptions: SelectOption[] = membershipList.map((membership: MembershipsResultType) => {
        let company: CompanyOptionsType = { id: '', companyName: '' };
        // eslint-disable-next-line default-case
        switch (targetType) {
          case 'Publisher':
            company = membership.publisher;
            break;
          case 'Merchant':
            company = membership.merchant;
            break;
        }
        return {
          label: `${company.id} - ${company.companyName}`,
          value: company.id,
        };
      });
      newCompanyList.push(...listOptions);
    }

    // Configure filters and their respective 'selected' values
    const newFilters = { ...filters, companyOptions: newCompanyList };
    const newSelectedFilters = { ...selectedFilters, companyOptions: newCompanyList[0] };

    setFilters(newFilters);
    setSelectedFilters(newSelectedFilters);
  };

  /**
   * @name getMembershipList
   * @description Fetches a list of memberships for a given company
   * @param {string} newCompanyId The ID of the company whose memberships we are fetching
   */
  const getMembershipList = async (newCompanyId: string | null = null) => {
    setErrorMessage('');

    const input: MembershipsQueryType = { status: 'Approved' };
    // eslint-disable-next-line default-case
    switch (companyType) {
      case 'Publisher':
        input.publisherId = (newCompanyId === null) ? companyId : newCompanyId;
        break;
      case 'Merchant':
        input.merchantId = (newCompanyId === null) ? companyId : newCompanyId;
        break;
    }
    const { data } = await getMembershipsQuery({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });

    if (data && data.memberships) {
      setMemberships(data.memberships.memberships);
      createMembershipListHandler(data.memberships.memberships);
    }
  };

  /**
   * @name setRecordsPerPageHandler
   * @description Sets the number of Records-Per-Page option then applies the filters
   * @param {SelectOption} option The selected Records-Per-Page option
   */
  const setRecordsPerPageHandler = (option: SelectOption) => {
    setRecordsPerPage(option);
    setCurrentPage(1);
    const newSelectedFilters = { ...selectedFilters, currentPage: 1, limit: Number.parseInt(option.value, 10) };
    setSelectedFilters(newSelectedFilters);
    sortPostbacks(newSelectedFilters);
  };

  /**
   * @name setCurrentPageHandler
   * @description Sets the current page then applies the filters
   * @param {number} page The selected page number
   */
  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page);
    const newSelectedFilters = { ...selectedFilters, currentPage: page };
    setSelectedFilters(newSelectedFilters);
    sortPostbacks(newSelectedFilters);
  };

  /**
   * @name getPostbacksList
   * @description Fetches a list of postbacks for a given company
   * @param {string} newCompanyId The ID of the company whose memberships we are fetching
   */
  const getPostbacksList = async (newCompanyId: string | null = null, page = 0) => {
    const { data } = await getPostbacksQuery({
      variables: {
        input: {
          companyId: (newCompanyId === null) ? companyId : newCompanyId,
          disabled: false,
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });

    if (data && data.postbacks) {
      flushPostbacks(data.postbacks.postbacks, page);
    }
  };

  /**
   * @name createPostback
   * @description Creates a new postback, inserts it into the list of postbacks, then flushes the list
   * @param {PostbackType} data The new postback
   */
  const createPostback = async (data: PostbackType) => {
    try {
      const input = data;
      input.targetType = targetType;
      const { data: { createPostback: createPostbackResponse }, errors } = await createPostbackMutation({
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });
      if (errors) throw errors;
      if (createPostbackResponse.postback.id) {
        const newPostbacks = [...postbacks];
        newPostbacks.push(createPostbackResponse.postback);
        flushPostbacks(newPostbacks);
      }
      return !!createPostbackResponse.postback.id;
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  /**
   * @name updatePostback
   * @description Updates a postback in the list of postbacks then flushes the list
   * @param {PostbackType} data The updated postback
   */
  const updatePostback = async (data: PostbackType) => {
    try {
      const input = data;
      input.targetType = targetType;
      const { data: { updatePostback: updatePostbackResponse }, errors } = await updatePostbackMutation({
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });
      if (errors) throw errors;
      if (updatePostbackResponse.postback.id) {
        const newPostbacks = [...postbacks];
        const index = findIndex(newPostbacks, ({ id }) => id === updatePostbackResponse.postback.id);
        newPostbacks[index] = updatePostbackResponse.postback;
        flushPostbacks(newPostbacks);
      }
      return !!updatePostbackResponse.postback.id;
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  /**
   * @name deletePostback
   * @description Deletes a postback from the list of postbacks (by setting
   * its 'disabled' flag to true) then refreshes the list from the server
   */
  const deletePostback = async () => {
    try {
      const { data: { updatePostback: deletePostbackResponse }, errors } = await updatePostbackMutation({
        variables: {
          input: {
            id: postbackId,
            disabled: true,
          },
        },
        fetchPolicy: 'no-cache',
      });
      if (errors) throw errors;
      if (deletePostbackResponse.postback.id) {
        setCurrentPageHandler(1);
        getPostbacksList(whoAmI.companyId?.toString() || '0', 1);
      }
      setIsDeleteModalOpen(false);
      return !!deletePostbackResponse.postback.id;
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  /**
   * @name onChangeHandler
   * @description Sets the state variable representing a filter's selection then applies the
   * filter to the list of postbacks
   * @param {string} name Filter name
   * @param {SelectOption} selected Selected value of the respective filter
   */
  const onChangeHandler = (name: string, selected: SelectOption) => {
    const newSelectedFilters = {
      ...selectedFilters,
      [name]: selected,
    };
    setSelectedFilters(newSelectedFilters);

    // Update the table
    sortPostbacks(newSelectedFilters);
  };

  /**
   * @name selectPostback
   * @description Sets the state variables that represent the postback to be acted upon
   * @param {object} selected The selected postback
   * @returns {array} An array containing the postback and its id
   */
  const selectPostback = (selected: any) => {
    const newPostback = {
      eventType: selected?.eventType || 'Created',
      targetId: selected?.targetId,
      target: selected?.target,
      url: selected?.url,
      queryString: selected?.queryString,
    };
    const newPostbackId = selected?.id;
    setPostback(newPostback);
    setPostbackId(newPostbackId);

    return [newPostback, newPostbackId];
  };

  /**
   * @name handleAdd
   * @description Sets the postback state variable to it's default then opens the add modal
   */
  const handleAdd = () => {
    selectPostback(POSTBACK_DEFAULTS);
    setIsAddModalOpen(true);
  };

  /**
   * @name handleDelete
   * @description Sets the state variables that represent the postback to be deleted then
   * opens the delete modal
   * @param {object} selected The selected postback
   */
  const handleDelete = (selected: any) => {
    selectPostback(selected);
    setIsDeleteModalOpen(true);
  };

  /**
   * @name handleEdit
   * @description Sets the state variables that represent the postback to be edited then
   * opens the edit dialog
   * @param {object} selected The selected postback
   */
  const handleEdit = (selected: any) => {
    selectPostback(selected);
    setIsEditModalOpen(true);
  };

  // Calendar Handlers
  /**
   * @name onChangeDateHandler
   * @description Sets the state variables representing the selected date filter then applies the
   * filters to the list of postbacks
   * @param {Date} startDateValue Start Date
   * @param {Date} endDateValue End Date
   * @param {string} range Date Range
   */
  const onChangeDateHandler = (startDateValue: Date, endDateValue?: Date, range?: string) => {
    const stDate = moment(startDateValue).format('YYYY-MM-DD 00:00:00');
    const edDate = moment(endDateValue).format('YYYY-MM-DD 23:59:59');

    setStartDate(startDateValue);
    if (endDateValue) setEndDate(endDateValue);
    if (range) setDateRange(range);

    const newSelectedFilters = {
      ...selectedFilters,
      createdAt: {
        start: new Date(stDate),
        end: new Date(edDate),
      },
      dateRange: range,
    };

    setSelectedFilters(newSelectedFilters);
    setIsCalendarOpen(false);
    // Update the table
    sortPostbacks(newSelectedFilters);
  };

  /**
   * @name onSortHandler
   * @description Sets the current sort state then applies the filters
   * @param {string} column The column to sort by
   * @param {string} direction The direction to sort by
   */
  const onSortHandler = (column: string, direction: 'desc' | 'asc' | undefined) => {
    const newDirection = direction === 'desc' ? 'asc' : 'desc';
    const newSelectedFilters: SelectedFilterType = { ...selectedFilters, sortBy: column, sortDirection: newDirection };
    setSelectedFilters(newSelectedFilters);
    sortPostbacks(newSelectedFilters);
  };

  /**
   * @name initialize
   * @description Cold initializes the component by resetting the state and fetching the list of postbacks
   */
  const initialize = () => {
    setIsLoading(true);
    const newCompanyId = whoAmI.companyId?.toString() || '0';
    setCompanyId(newCompanyId);
    setFilters(defaultFilters);
    setSelectedFilters(defaultSelectedFilters);
    setCurrentPageHandler(1);
    setTableGenerated(false);
    getMembershipList(newCompanyId);
    getPostbacksList(newCompanyId);
    sortPostbacks(defaultSelectedFilters);
    setIsLoading(false);
  };

  /**
   * @name clearForm
   * @description Warm resets the state and updates the list of postbacks
   */
  const clearForm = () => {
    setIsLoading(true);
    const newCompanyId = whoAmI.companyId?.toString() || '0';
    setCompanyId(newCompanyId);
    setCurrentPageHandler(1);
    setTableGenerated(false);
    const newSelectedFilters = {
      ...defaultSelectedFilters,
      companyOptions: {
        value: '',
        label: `All ${targetType}s`,
      },
    };
    sortPostbacks(newSelectedFilters, postbacks);
    setSelectedFilters(newSelectedFilters);
    setIsLoading(false);
  };

  // Initialize the component and ensure the list of postbacks is updated when the URL changes
  useEffect(initialize, [window.location.href]);

  return {
    errorMessage,
    isLoading,
    memberships,
    membershipsLoading,
    postbacks,
    postbacksLoading,
    postback,
    setPostback,
    postbackId,
    companyId,
    filteredPostbacks,
    selectPostback,
    createPostback,
    updatePostback,
    deletePostback,
    handleAdd,
    handleDelete,
    handleEdit,
    // Filters
    filters,
    selectedFilters,
    onChangeHandler,
    // Calendar
    isCalendarOpen,
    setIsCalendarOpen,
    onChangeDateHandler,
    selectedDateRange: (): string => `${selectedFilters.createdAt.start.toDateString()} / ${selectedFilters.createdAt.end.toDateString()}`,
    startDate,
    endDate,
    dateRange,
    // Clear Form
    clearForm,
    // Records
    recordsPerPage: recordsPerPageList,
    selectedRecordsPerPage,
    setRecordsPerPageHandler,
    // Table Data
    totalPages,
    totalRecords: (): number => filteredPostbacks.length,
    // Table Status
    tableGenerated,
    currentPage,
    setCurrentPageHandler,
    sortColumn: (): TableSortColumn => ({ column: selectedFilters.sortBy, direction: selectedFilters.sortDirection }),
    onSortHandler,
    // Modals
    isAddModalOpen,
    setIsAddModalOpen,
    isEditModalOpen,
    setIsEditModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    showToast,
    readOnlyPermissionsList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
