export const CURRENCY_TABS = {
  EMPTY_LABEL: '',
  CALENDAR_LABEL: 'Date Range',
  PUBLISHER_SELECT: 'publisherSelect',
  CALENDAR_PLACEHOLDER: 'Select Date',
  CURRENCY_OPTIONS: [
    {
      label: 'All Currencies',
      value: 'ALLALL',
    },
    {
      label: 'CAD - USD',
      value: 'CADUSD',
    },
    {
      label: 'CAD - AUD',
      value: 'CADAUD',
    },
    {
      label: 'USD - CAD',
      value: 'USDCAD',
    },
    {
      label: 'USD - AUD',
      value: 'USDAUD',
    },
    {
      label: 'AUD - USD',
      value: 'AUDUSD',
    },
    {
      label: 'AUD - CAD',
      value: 'AUDCAD',
    },
  ],
};
