import { gql } from '@apollo/client';

export const LIST_SEARCH_PUBLISHER_ADS = gql`
  query ListSearchPublisherAds($input: ListSearchPublisherAdInput) {
    listSearchPublisherAds(input: $input) {
      list {
        id
        width
        createdAt
        name
        coverUrl
        height
        merchantName
        productName
        productCategoryName
        campaignId
        campaignName
        typeName
        landingPage
        programId
      }
      options {
        page
        pages
        count {
          type
          count
        }
        countAll
        records
      }
    }
  }
`;
