import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AGE_DEFAULT,
  GENDER_DEFAULT,
  MARKET_DEFAULT,
  RATING_DEFAULT,
  RECORDS_PER_PAGE_OPTIONS,
  path,
  MERCHANT_PREFIX,
  TOAST_ERR_MESSAGES_NO_PAGE,
} from '../../../../../utils';
import {
  DEFAULT_COLUMNS, customizeColumns, PUBLISHER_GROUP_DEFAULT, MODAL_TYPE,
} from '../contracts';
import { useModal, useUserInfo } from '../../../../../hooks';
import { PublisherSearchRowType, SearchFilterOptions, SearchFilterType } from '../types';
import {
  GET_FILTER_LIST, GetFilterListInput, GetFilterListOutput,
  GET_GROUP_LIST, GetGroupListInput, GetGroupListOutput,
  LIST_SEARCH_PUBLISHER, ListSearchPublshersInput, ListSearchPublshersOutput, LSPPublisherType,
} from '../graphql/queries';
import { useDebounce } from '../../../../../utils/useDebounce';
import { UPDATE_MEMBERSHIP_GROUP, UpdateMembershipGroupInput, UpdateMembershipGroupOutput } from '../graphql/mutations';
import { Permission } from '../../../../../entities';

const DEFAULT_SEARCH_FILTER: SearchFilterType = {
  membershipStatus: undefined,
  publisherGroupIds: undefined,
  audienceMarkets: undefined,
  productCategories: undefined,
  promotionMethods: undefined,
  avgMonthlyAudience: undefined,
  activityStatus: undefined,
  audienceAges: undefined,
  gender: undefined,
  creditScoreRating: undefined,
  avgIncomes: undefined,
};

const formatterForOptions = (arr: string[]) => arr.map((value, index) => {
  if (!index) return { label: value, value: '' };
  return { label: value, value };
});

export const usePublisherSearch = (permissionsCodeList: string[] = []) => {
  const navigate = useNavigate();
  const { hookWhoAmI } = useUserInfo();

  const [customizeColumnModal, setCustomizeColumnModal] = useModal();
  const [addRemoveModal, setAddRemoveModal] = useModal();
  const [modalType, setModalType] = useState('');

  const [errorMessage, setErrorMessage] = useState<string>('');

  // Filter State
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 800);
  const [filter, setFilter] = useState<SearchFilterType>(structuredClone(DEFAULT_SEARCH_FILTER));
  const [recordsPerPage, setRecordsPerPage] = useState<SelectOption>(RECORDS_PER_PAGE_OPTIONS[0]);
  const [advancedFilterIsOpen, setAdvancedFilterIsOpen] = useState<boolean>(false);

  // Filter Options
  const [publisherGroupOptions, setPublisherGroupOptions] = useState<SelectOption[]>([PUBLISHER_GROUP_DEFAULT]);
  const [genderOptions, setGenderOptions] = useState<SelectOption[]>([GENDER_DEFAULT]);
  const [ageOptions, setAgeOptions] = useState<SelectOption[]>([AGE_DEFAULT]);
  const [martketOptions, setMartketOptions] = useState<SelectOption[]>([MARKET_DEFAULT]);
  const [ratingOptions, setRatingOptions] = useState<SelectOption[]>([RATING_DEFAULT]);

  // Table Data
  const [publishers, setPublishers] = useState<LSPPublisherType[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  // Table State
  const [customColumns, setCustomColumns] = useState<string[]>(DEFAULT_COLUMNS);
  const [tableColumns, setTableColumns] = useState<TableColumn[]>(customizeColumns(DEFAULT_COLUMNS));
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'id', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [refetchTable, setRefetchTable] = useState<boolean>(false);
  const [searchClicked, setSearchClicked] = useState<boolean>(false);

  // Table Checkboxes
  const [selectedPublishers, setSelectedPublishers] = useState<string[]>([]);
  const [headerCheckPublishers, setHeaderCheckPublishers] = useState<boolean>(false);

  // Footer
  const [selectedPublisherGroupFooter, setSelectedPublisherGroupFooter] = useState<SelectOption| undefined>();

  // Queries
  const [getFilterList, { loading: getFilterListLoading }] = useLazyQuery<GetFilterListOutput, GetFilterListInput>(GET_FILTER_LIST);
  const [getGroupList, { loading: getGroupListLoading }] = useLazyQuery<GetGroupListOutput, GetGroupListInput>(GET_GROUP_LIST);
  const [listPublishers, { loading: listPublishersLoading }] = useLazyQuery<ListSearchPublshersOutput, ListSearchPublshersInput>(LIST_SEARCH_PUBLISHER);
  const [updatePublisherGroup, { loading: updatePublisherGroupLoading }] = useMutation<UpdateMembershipGroupOutput, UpdateMembershipGroupInput>(UPDATE_MEMBERSHIP_GROUP);

  const handleFilterChange = (name: SearchFilterOptions, value: SelectOption) => {
    setFilter((prev) => ({ ...prev, [name]: value }));
    setCurrentPage(1);
    setRefetchTable(true);
  };

  const getPublishers = async () => {
    setErrorMessage('');
    setSelectedPublishers([]);
    setHeaderCheckPublishers(false);
    const listPublisherInput: ListSearchPublshersInput = {
      input: {
        programId: hookWhoAmI.programId || '0',
        sortBy: sortColumn.column,
        options: {
          items: recordsPerPage ? Number(recordsPerPage.value) : 10,
          order: sortColumn.direction === 'asc' ? 'ASC' : 'DESC',
          page: currentPage,
        },
        filter: {
          ...filter.membershipStatus && { membershipStatus: filter.membershipStatus.value },
          ...filter.publisherGroupIds && { publisherGroupIds: filter.publisherGroupIds.value },
          ...filter.audienceMarkets && { audienceMarkets: filter.audienceMarkets.value },
          ...filter.creditScoreRating && { creditScoreRating: filter.creditScoreRating.value },
          ...filter.avgMonthlyAudience && { avgMonthlyAudience: filter.avgMonthlyAudience.value },
          ...filter.gender && { gender: filter.gender.value },
          ...filter.promotionMethods && { promotionMethods: filter.promotionMethods.value },
          ...filter.productCategories && { productCategories: filter.productCategories.value },
          ...filter.avgIncomes && { avgIncomes: filter.avgIncomes.value },
          ...filter.audienceAges && { audienceAges: filter.audienceAges.value },
          ...filter.activityStatus && { activityStatus: filter.activityStatus.value },
          ...search && { q: search?.trim() },
        },
      },
    };

    const { data } = await listPublishers({
      variables: listPublisherInput,
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });

    if (data && data.searchPublishersAsMerchant) {
      setPublishers(data.searchPublishersAsMerchant.companies);
      setTotalPages(Math.ceil(data.searchPublishersAsMerchant.count / Number(recordsPerPage.value)));
    } else {
      setPublishers([]);
      setTotalPages(0);
    }
  };

  const getFilterValues = async () => {
    if (!hookWhoAmI.programId || !hookWhoAmI.companyId) {
      setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE('Missing User Info'));
      return;
    }
    setErrorMessage('');

    const filterListInput: GetFilterListInput = {
      input: {
        merchantId: hookWhoAmI.companyId?.toString() || '0',
        isMember: true,
        options: { page: 1, items: 1, order: 'ASC' },
        showTestAccounts: true,
      },
    };

    const [{ data: filterArrays }, { data: groupsList }] = await Promise.all([
      getFilterList({
        variables: filterListInput,
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      }),
      getGroupList({
        variables: { programId: hookWhoAmI.programId },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      }),
    ]);

    if (filterArrays && filterArrays.publishersForInvitationV2) {
      const { filterValues } = filterArrays.publishersForInvitationV2;
      setGenderOptions(formatterForOptions(filterValues.gender));
      setAgeOptions(formatterForOptions(filterValues.audienceAges));
      setMartketOptions(formatterForOptions(filterValues.audienceMarkets));
      setRatingOptions(formatterForOptions(filterValues.creditScoreRating));
    }
    if (groupsList && groupsList.groups) {
      const { groups } = groupsList.groups;
      setPublisherGroupOptions(groups.map((group) => ({ label: group.name, value: group.id })));
    }
  };

  const setCustomizeColumnsHandler = (columns: string[]) => {
    setCustomColumns(columns);
    setCustomizeColumnModal();
    setTableColumns(customizeColumns(columns));
  };

  const clearFilterHandler = () => {
    setSearch('');
    setCurrentPage(1);
    setFilter(structuredClone(DEFAULT_SEARCH_FILTER));
    setRecordsPerPage(RECORDS_PER_PAGE_OPTIONS[0]);
    setRefetchTable(true);
    setSearchClicked(false);
    setPublishers([]);
  };

  const setSelectedPublisherHandler = (id: string, value: boolean) => {
    const updatedPublishers = publishers.map((pub) => {
      if (pub.membershipId === id) {
        return { ...pub, checked: value };
      }
      return pub;
    });
    setPublishers(updatedPublishers);
    if (value) {
      setSelectedPublishers([...selectedPublishers, id]);
      setHeaderCheckPublishers(selectedPublishers.length + 1 === publishers.length);
    } else {
      const updatePublisherList = selectedPublishers.filter((membershipId) => membershipId !== id);
      setSelectedPublishers(updatePublisherList);
      setHeaderCheckPublishers(false);
    }
  };

  const setHeaderCheckPublishersHandler = (value: boolean) => {
    setHeaderCheckPublishers(value);
    const updatedPublishers = publishers.map((pub) => ({ ...pub, checked: value }));
    setPublishers(updatedPublishers);
    if (value) {
      setSelectedPublishers(publishers.map((pub) => pub.id));
    } else {
      setSelectedPublishers([]);
    }
  };

  const addRemoveSubmitHandler = async () => {
    if (!selectedPublisherGroupFooter) return;

    // If adding should add selected groupId to the selectedRows(memberships) publisherGroupIds Array
    // if removing should remove the selected groupId from the selectedRows(memberships) publisherGroupIds Array
    let updatePublisherGroupInput: UpdateMembershipGroupInput;
    if (modalType === MODAL_TYPE.ADD) {
      updatePublisherGroupInput = {
        input: publishers
          .filter((pub) => selectedPublishers.includes(pub.membershipId))
          .map((pub): {id: string, publisherGroupIds: string[]} => {
            const newPublisherGroupIds = new Set<string>();
            newPublisherGroupIds.add(selectedPublisherGroupFooter.value);
            pub.publisherGroupIds?.forEach((group) => newPublisherGroupIds.add(group));
            return {
              id: pub.membershipId,
              publisherGroupIds: Array.from(newPublisherGroupIds),
            };
          }),
      };
    } else {
      updatePublisherGroupInput = {
        input: publishers
          .filter((pub) => selectedPublishers.includes(pub.membershipId))
          .map((pub): {id: string, publisherGroupIds: string[]} => {
            const newPublisherGroupIds = pub.publisherGroupIds ? pub.publisherGroupIds.filter((group) => group !== selectedPublisherGroupFooter.value) : [];
            return {
              id: pub.membershipId,
              publisherGroupIds: Array.from(newPublisherGroupIds),
            };
          }),
      };
    }

    await updatePublisherGroup({
      variables: updatePublisherGroupInput,
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });

    setAddRemoveModal();
    setModalType('');
    setRefetchTable(true);
  };

  const navigationHandler = (row: PublisherSearchRowType) => {
    navigate(`${MERCHANT_PREFIX}${path.membershipDetails.href}`, {
      state: {
        publisherId: row.id,
        membershipId: row.membershipId,
      },
    });
  };

  const footerButtonHandler = (type: string) => {
    setModalType(type);
    setAddRemoveModal();
  };

  const setAdvancedFilterIsOpenHandler = (value: boolean) => setAdvancedFilterIsOpen(value);
  const setSearchHandler = (value: React.ChangeEvent<HTMLInputElement>) => setSearch(value.target.value);
  const setSelectedPublisherGroupFooterHandler = (value: SelectOption) => setSelectedPublisherGroupFooter(value);
  const setRecordsPerPageHandler = (value: SelectOption) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
    setRefetchTable(true);
  };
  const setCustomizeColumnModalHandler = () => setCustomizeColumnModal();
  const setAddRemoveModalHandler = () => setAddRemoveModal();

  const setSortColumnHandler = (dataField: string, direction: any) => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
    setRefetchTable(true);
  };

  const setCurrentPageHandler = (newPage: number) => {
    setCurrentPage(newPage);
    setRefetchTable(true);
  };

  const searchButtonHandler = () => {
    setSearchClicked(true);
    setRefetchTable(true);
  };

  useEffect(() => {
    getFilterValues();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setRefetchTable(true);
  }, [debouncedSearch]);

  if (refetchTable && searchClicked) {
    setRefetchTable(false);
    getPublishers();
  }

  return {
    // Page State
    hookIsLoading: getFilterListLoading || getGroupListLoading || listPublishersLoading,
    hookUpdateLoading: updatePublisherGroupLoading,
    hookErrorMessage: errorMessage,

    // Filter Options
    hookPublisherGroupOptions: publisherGroupOptions,
    hookGenderOptions: genderOptions,
    hookAgeOptions: ageOptions,
    hookMartketOptions: martketOptions,
    hookRatingOptions: ratingOptions,

    // Filter State
    hookSearch: search,
    hookFilter: filter,
    hookRecordsPerPage: recordsPerPage,
    hookAdvancedFilterIsOpen: advancedFilterIsOpen,
    hookSearchClicked: searchClicked,

    // Filter Handlers
    hookSetSearch: setSearchHandler,
    hookHandleFilterChange: handleFilterChange,
    hookSetRecordsPerPage: setRecordsPerPageHandler,
    hookClearFilter: clearFilterHandler,
    hookSetAdvancedFilterIsOpen: setAdvancedFilterIsOpenHandler,
    hookSearchButtonHandler: searchButtonHandler,

    // Customize Columns Modal
    hookCustomizeColumns: customColumns,
    hookSetCustomizeColumns: setCustomizeColumnsHandler,
    hookCustomizeColumnModal: customizeColumnModal,
    hookSetCustomizeColumnModal: setCustomizeColumnModalHandler,

    // Table State
    hookPublishers: publishers,
    hookTableColumns: tableColumns,
    hookSortColumn: sortColumn,
    hookHeaderCheckPublishers: headerCheckPublishers,
    hookSelectedPublishers: selectedPublishers,
    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,

    // Table Handlers
    hookSetSortColumn: setSortColumnHandler,
    hookNavigationHandler: navigationHandler,
    hookSetHeaderCheckPublishers: setHeaderCheckPublishersHandler,
    hookSetSelectedPublisher: setSelectedPublisherHandler,
    hookSetCurrentPage: setCurrentPageHandler,

    // Footer
    hookSelectedPublisherGroupFooter: selectedPublisherGroupFooter,
    hookSetSelectedPublisherGroupFooter: setSelectedPublisherGroupFooterHandler,

    // Footer Modals
    hookAddRemoveModal: addRemoveModal,
    hookSetAddRemoveModal: setAddRemoveModalHandler,
    hookModalType: modalType,
    hookFooterButtonHandler: footerButtonHandler,
    hookAddRemoveSubmit: addRemoveSubmitHandler,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
