import { gql } from '@apollo/client';

export const EDIT_CAMPAIGN = gql`
  mutation editCampaign($input: CampaignInput) {
    editCampaign(input: $input) {
      id
      name
    }
  }
`;
