import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { colors, fonts } from '../../../../styles/theme';

type ColorIconProps = {
  readonly $iconColor?: string
}

export const CellStyled = styled.div`
  height: 40px;
  padding: 0.4rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100%); // Makes the ellipis work
  
  a {
    color: ${colors.color1};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${colors.color2};
    }
  }

  p {
    ${fonts.lato.H16B}
    color: ${colors.color1};
    margin-bottom: 0.3rem;
  }

  span {
    ${fonts.lato.H16}
    color: ${colors.color13};
  }
`;

export const CellSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100%); // Makes the ellipis work
`;

export const ItemContainer = styled.div`
  display: grid;
  justify-content: space-between;
  flex-direction: row;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

export const CellLinkStyled = styled.a`
  color: ${colors.color1};
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100%);

  &:hover {
    color: ${colors.color2};
  }
`;

export const NewImage = styled.img`
  margin-left: 10px;
  width: 32px;
  min-width: 32px;
`;

export const ColoredIcon = styled(FontAwesomeIcon)<ColorIconProps>`
  color: ${({ $iconColor }) => ($iconColor === 'red' ? colors.color31 : colors.color29)};
`;
