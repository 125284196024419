import React from 'react';
import * as Styled from './styles';
import { imageList } from '../../utils';
import * as MAINTENANCE_TEXTS from './enums';

const Maintenance = () => (
  <Styled.WrapperStyled>
    <Styled.LeftStyled>
      <figure>
        <img
          src={imageList.logoFull.src}
          alt="logo"
        />
      </figure>
    </Styled.LeftStyled>
    <Styled.RightStyled>
      <h1>{MAINTENANCE_TEXTS.TITLE}</h1>
      <span>{MAINTENANCE_TEXTS.DESCRIPTION}</span>
    </Styled.RightStyled>
  </Styled.WrapperStyled>
);

export default Maintenance;
