import React from 'react';
import {
  BUTTON_LABEL, MODAL_DESCRIPTION_END, MODAL_DESCRIPTION_START, MODAL_TITLE,
} from '../../contracts';
import { Button } from '../../../../../../components';
import * as Styled from '../../styles';
import { SelectedPublisherType } from '../../types';

type ApproveDelineMembershipModalProps = {
  modalType: string
  publishers: SelectedPublisherType[]
  closeHandler: (type: string) => void
  submitHandler: (modalType: string) => void
  loading: boolean
}

export const ApproveDelineMembershipModal = ({
  modalType, publishers, closeHandler, submitHandler, loading,
}: ApproveDelineMembershipModalProps) => (
  <Styled.ModalBodyStyled>
    <Styled.ModalTitleStyled>{MODAL_TITLE[modalType]}</Styled.ModalTitleStyled>
    <p>{MODAL_DESCRIPTION_START[modalType]}</p>
    <Styled.ModalCompanyStyled>
      {publishers.map((publisher) => (
        <p
          key={publisher.publisherId}
          id={publisher.publisherId}
        >
          {publisher.publisherName}
        </p>
      ))}
    </Styled.ModalCompanyStyled>
    <p>{MODAL_DESCRIPTION_END[modalType]}</p>
    <Styled.ModalFooterStyled>
      <Styled.FooterButtonWrapperStyled>
        <Button
          onClick={() => closeHandler('')}
          theme="octonary"
        >
          {BUTTON_LABEL.CANCEL}
        </Button>
      </Styled.FooterButtonWrapperStyled>
      <Button
        onClick={() => submitHandler(modalType)}
        loading={loading}
        disabled={loading}
        width="86px"
      >
        {BUTTON_LABEL[modalType]}
      </Button>
    </Styled.ModalFooterStyled>
  </Styled.ModalBodyStyled>
);
