import React from 'react';
import * as Styled from './styles';
import { ConfirmationModal } from '../../../../../../../components/Modal/ConfirmationModal';
import { ADD_NOTE } from './enums';
import { InputTextarea, InputText } from '../../../../../../../components';

type AddNoteModalProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  referenceText: string
  referenceChange: (value: any) => void
  noteText: string
  noteChange: (value: any) => void
  isLoading: boolean
  isReadOnly: boolean
  isPaymentPayReadOnly: boolean
}

export const AddNoteModal = ({
  isOpen, onClose, onConfirm, referenceChange, referenceText, noteChange, noteText, isLoading, isReadOnly, isPaymentPayReadOnly,
}: AddNoteModalProps) => (
  <ConfirmationModal
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    onCloseText={ADD_NOTE.BUTTON_TEXT.CANCEL}
    onConfirmText={ADD_NOTE.BUTTON_TEXT.SAVE}
    isLoading={isLoading}
    onConfirmDisabled={isReadOnly}
  >
    <Styled.WrapperStyled>
      <Styled.UserDescTitleStyled>
        {ADD_NOTE.REFERENCE.TITLE}
      </Styled.UserDescTitleStyled>
      <InputText
        label={ADD_NOTE.REFERENCE.SUBTITLE}
        value={referenceText}
        onChange={referenceChange}
        type="text"
        disabled={isReadOnly || isPaymentPayReadOnly}
      />
    </Styled.WrapperStyled>
    <Styled.WrapperStyled>
      <Styled.UserDescTitleStyled>
        {ADD_NOTE.NOTE.TITLE}
      </Styled.UserDescTitleStyled>
      <Styled.UserDescInformationStyled>
        {ADD_NOTE.NOTE.DESCRIPTION}
      </Styled.UserDescInformationStyled>
      <InputTextarea
        value={noteText}
        onChange={noteChange}
        disabled={isReadOnly}
      />
    </Styled.WrapperStyled>
  </ConfirmationModal>
);
