import { gql } from '@apollo/client';

export const ADD_RULE_GROUP = gql`
  mutation addRuleGroup($input: FintelCheckRuleGroupInput) {
    addRuleGroup(input: $input) {
      merchantId
      groupName
    }
  }
`;
