import { gql } from '@apollo/client';

export const MEMBER_DETAILS = gql`
  query membership($id: ID!) {
    membership(id: $id) {
      id
      createdAt
      approvedDate
      status
      statusLastUpdatedBy
      publisherInvitation {
        id
      }
      updatedAt
      publisherGroups {
        id
        name
      }
    }
  }
`;
