import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import {
  AppWrapper, Button, DeactivationForm, DropZone, InfoBox, InputText, ManageUserModal, Modal, PageTitle, Select, Table,
} from '../../../../components';
import { ERROR_ICON, path } from '../../../../utils';
import { useMerchantDetails } from './hooks';
import {
  BUTTON_LABEL, DEACTIVE_MODAL, FORM_SECTION_TITLE, INPUT_LABEL, MERCHANT_TYPES, NETWORK_STATUS, PAGE_TITLE, PLACEHOLDER, TAX_PLACEHOLDER, useColumns,
} from './contracts';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type MerchantAccountDetailsProps = DefaultPropsType & {
  isAdmin: boolean;
}

const MerchantAccountDetails = ({ isAdmin, permissionsCodeList = [] }: MerchantAccountDetailsProps) => {
  const navigate = useNavigate();
  const { hookIsReadOnlyList, ...hook } = useMerchantDetails(isAdmin, permissionsCodeList);
  const [isReadOnly, isUserManagementReadOnly] = isAdmin ? [hookIsReadOnlyList[1], hookIsReadOnlyList[2]] : hookIsReadOnlyList;

  const isSuperUser = hook.hookIsSuperUser;
  const [columnHook, setColumnHook] = useState<{ columns: TableColumn[] } >(useColumns(hook.hookSetModalHandler, !isUserManagementReadOnly && isSuperUser, hook.hookId));
  const themeForNonSuper = isSuperUser ? 'default' : 'readonlyTernary';
  const isAdminPermissionManaged = isAdmin && !hookIsReadOnlyList[0];

  useEffect(() => {
    setColumnHook(useColumns(hook.hookSetModalHandler, !isUserManagementReadOnly && isSuperUser, hook.hookId));
  }, [hook.hookIsSuperUser]);

  return (
    <AppWrapper
      permissionsCodeList={isAdmin ? permissionsCodeList.slice(1) : permissionsCodeList}
      modalOpen={hook.hookOpenModal || hook.hookDeactivateModal}
    >
      {hook.hookErrorMessages.message && (
      <InfoBox
        title=""
        description={hook.hookErrorMessages.message}
        icon={ERROR_ICON}
        theme="ERROR"
      />
      )}
      <Styled.WrapperStyled>
        {hook.hookAccountDetailsCanAccess && (
        <>
          <Styled.HeaderStyled>
            <PageTitle
              isLoading={hook.hookPageLoading}
              loadingText={hook.hookLoadingMessage}
            >
              {PAGE_TITLE}
            </PageTitle>

            <Styled.HeaderButtonAreaStyled>
              <Button
                theme="secondary"
                width="104px"
                onClick={() => navigate(path.dashboard.href)}
                disabled={!isSuperUser}
              >
                {BUTTON_LABEL.CANCEL}
              </Button>
              <Button
                width="104px"
                onClick={hook.hookUpdateMerchant}
                disabled={isAdmin ? (hookIsReadOnlyList[0] && isReadOnly) : (isReadOnly && !isSuperUser)}
                loading={hook.hookIsSubmiting}
              >
                {BUTTON_LABEL.SAVE}
              </Button>
            </Styled.HeaderButtonAreaStyled>
          </Styled.HeaderStyled>

          <Styled.GridedRowStyled>
            <Styled.GridContentStyled>
              <InputText
                label={INPUT_LABEL.ID}
                value={hook.hookMerchantId}
                type="text"
                readonly
                theme="readonlyTernary"
              />
              <Select
                label={INPUT_LABEL.ACCOUNT_STATUS}
                selected={hook.hookAccountStatus}
                options={hook.hookAccountStatusTypeOptions}
                onChange={hook.hookSetAccountStatus}
                isDisabled={!isAdminPermissionManaged}
              />
              <Select
                label={INPUT_LABEL.NETWORK_STATUS}
                selected={hook.hookNetworkStatus}
                options={NETWORK_STATUS}
                onChange={hook.hookSetNetworkStatus}
                isDisabled={isAdmin ? (!isAdminPermissionManaged) : (isReadOnly && !isSuperUser)}
              />
            </Styled.GridContentStyled>

            <Styled.GridContentStyled>
              <InputText
                label={INPUT_LABEL.COMPANY_NAME}
                value={hook.hookMerchantName}
                onChange={hook.hookSetCompanyName}
                readonly={isReadOnly}
                disabled={isReadOnly}
                type="text"
                required
                error={hook.hookMerchantErrors.companyName}
                onBlur={() => hook.hookValidateFields()}
                theme={themeForNonSuper}
              />
              <Select
                label={INPUT_LABEL.HISTORY}
                selected={hook.hookStatusHistory}
                options={[]}
                onChange={() => ''}
                isDisabled
              />
              <Select
                label={INPUT_LABEL.MARCHANT_TYPE}
                selected={hook.hookMerchantType}
                options={MERCHANT_TYPES}
                onChange={hook.hookSetMerchantType}
                isDisabled={!isAdminPermissionManaged}
              />
            </Styled.GridContentStyled>

            <Styled.GridContentStyled>
              <Styled.DropZoneStyled>
                <DropZone
                  label={INPUT_LABEL.PROFILE_PHOTO}
                  name="upload"
                  previewUrl={hook.hookCoverUrl}
                  disabled={!isSuperUser || isReadOnly}
                  maxFileSize={3000000}
                  accept=".jpg, *.jpg, .jpeg, *.jpeg, .png, *.png"
                  type="ProfileImage"
                  onChange={hook.hookOnUploadHandler}
                />
              </Styled.DropZoneStyled>
            </Styled.GridContentStyled>
          </Styled.GridedRowStyled>

          <Styled.FlexRowStyled>
            <InputText
              label={INPUT_LABEL.COMPANY_URL}
              value={hook.hookUrl}
              onChange={hook.hookSetUrl}
              type="text"
              required
              error={isSuperUser ? hook.hookUrlError || hook.hookMerchantErrors.website : ''}
              onBlur={() => {
                if (isSuperUser) {
                  hook.hookValidateWebsite();
                }
              }}
              theme={themeForNonSuper}
              disabled={isReadOnly}
            />
          </Styled.FlexRowStyled>

          <Styled.SectionStyled>
            <Styled.SectionTitleStyled>
              {FORM_SECTION_TITLE.CONTACT}
            </Styled.SectionTitleStyled>

            {(!isAdmin && isReadOnly) ? (
              <Styled.GridedRowStyled template-rows="120px">
                <Styled.GridContentStyled gridColumns="1/3">
                  <InputText
                    label={INPUT_LABEL.ADDRESS}
                    value={`${hook.hookAddressOne}, ${hook.hookCity}, ${hook.hookRegion.label}, ${hook.hookCountry.label}, ${hook.hookPostalCode}`}
                    onChange={hook.hookSetAddressOne}
                    type="text"
                    required
                    error={hook.hookMerchantErrors.address}
                    onBlur={() => hook.hookValidateFields()}
                    theme="readonlyTernary"
                    readonly={isReadOnly}
                  />
                </Styled.GridContentStyled>
                <InputText
                  label={INPUT_LABEL.ADDRESS_TWO}
                  value={hook.hookAddressTwo}
                  onChange={hook.hookSetAddressTwo}
                  type="text"
                  theme="readonlyTernary"
                  readonly={isReadOnly}
                />
                <InputText
                  label={INPUT_LABEL.PHONE_NUMBER}
                  value={hook.hookPhoneOne}
                  onChange={hook.hookSetPhoneOne}
                  onBlur={() => hook.hookValidateFields()}
                  type="text"
                  required
                  error={hook.hookMerchantErrors.phone}
                  theme="readonlyTernary"
                  readonly={isReadOnly}
                />
                <InputText
                  label={INPUT_LABEL.PHONE_NUMBER_SECONDARY}
                  value={hook.hookPhoneTwo}
                  onChange={hook.hookSetPhoneTwo}
                  onBlur={() => hook.hookValidateFields()}
                  type="text"
                  error={hook.hookMerchantErrors.phoneTwo}
                  theme="readonlyTernary"
                  readonly={isReadOnly}
                />
                <InputText
                  label={INPUT_LABEL.COMPANY_EMAIL}
                  value={hook.hookEmail}
                  onChange={hook.hookSetEmail}
                  onBlur={() => hook.hookValidateFields()}
                  type="email"
                  error={hook.hookMerchantErrors.companyEmail}
                  theme="readonlyTernary"
                  readonly={isReadOnly}
                />
              </Styled.GridedRowStyled>
            ) : (
              <Styled.GridedRowStyled template-rows={!isSuperUser ? '250px' : '200px'}>
                <Styled.GridContentStyled>
                  <InputText
                    label={INPUT_LABEL.ADDRESS}
                    value={hook.hookAddressOne}
                    onChange={hook.hookSetAddressOne}
                    type="text"
                    required
                    error={hook.hookMerchantErrors.address}
                    onBlur={() => hook.hookValidateFields()}
                    disabled={isReadOnly}
                  />
                  <Select
                    label={INPUT_LABEL.REGION}
                    options={hook.hookRegionOptions}
                    onChange={hook.hookSetRegion}
                    selected={hook.hookRegion}
                    required
                    error={hook.hookMerchantErrors.region}
                    isDisabled={isReadOnly}
                  />
                  <InputText
                    label={INPUT_LABEL.PHONE_NUMBER}
                    value={hook.hookPhoneOne}
                    onChange={hook.hookSetPhoneOne}
                    onBlur={() => hook.hookValidateFields()}
                    type="text"
                    required
                    error={hook.hookMerchantErrors.phone}
                    disabled={isReadOnly}
                  />
                </Styled.GridContentStyled>

                <Styled.GridContentStyled>
                  <InputText
                    label={INPUT_LABEL.ADDRESS_TWO}
                    value={hook.hookAddressTwo}
                    onChange={hook.hookSetAddressTwo}
                    type="text"
                    disabled={isReadOnly}
                  />
                  <Select
                    label={INPUT_LABEL.COUNTRY}
                    name="country"
                    options={hook.hookCountryOptions}
                    onChange={hook.hookSetSelectedCountry}
                    selected={hook.hookCountry}
                    required
                    error={hook.hookMerchantErrors.country}
                    isDisabled={isReadOnly}
                  />
                  <InputText
                    label={INPUT_LABEL.PHONE_NUMBER_SECONDARY}
                    value={hook.hookPhoneTwo}
                    onChange={hook.hookSetPhoneTwo}
                    onBlur={() => hook.hookValidateFields()}
                    type="text"
                    error={hook.hookMerchantErrors.phoneTwo}
                    disabled={isReadOnly}
                  />
                </Styled.GridContentStyled>

                <Styled.GridContentStyled>
                  <InputText
                    label={INPUT_LABEL.CITY}
                    onChange={hook.hookSetCity}
                    disabled={isReadOnly}
                    value={hook.hookCity}
                    onBlur={() => hook.hookValidateFields()}
                    type="text"
                    required
                    error={hook.hookMerchantErrors.city}
                  />
                  <InputText
                    label={INPUT_LABEL.POST_CODE}
                    value={hook.hookPostalCode}
                    disabled={isReadOnly}
                    onChange={hook.hookSetPostalCode}
                    onBlur={() => hook.hookValidateFields()}
                    type="text"
                    required
                    error={hook.hookMerchantErrors.postalCode}
                  />
                  <InputText
                    label={INPUT_LABEL.COMPANY_EMAIL}
                    value={hook.hookEmail}
                    onChange={hook.hookSetEmail}
                    disabled={isReadOnly}
                    onBlur={() => hook.hookValidateFields()}
                    type="email"
                    error={hook.hookMerchantErrors.companyEmail}
                  />
                </Styled.GridContentStyled>
              </Styled.GridedRowStyled>
            )}

          </Styled.SectionStyled>

          <Styled.SectionStyled>
            <Styled.SectionTitleStyled>
              {FORM_SECTION_TITLE.BILLING}
            </Styled.SectionTitleStyled>

            <Styled.GridedRowStyled template-rows="auto">
              <Styled.InputTextStyled
                label={INPUT_LABEL.TAXATION}
                value={hook.hookTax}
                onChange={hook.hookSetTax}
                placeholder={!isSuperUser ? '' : TAX_PLACEHOLDER.TAX}
                type="text"
                theme={themeForNonSuper}
                onBlur={() => hook.hookValidateBillingInfo()}
                error={hook.hookBillingInfoErrors.taxation}
                disabled={hook.hookTaxationNumberDisabled || isReadOnly}
              />
              <Styled.InputTextStyled
                label={INPUT_LABEL.BILLING_CONTACT}
                placeholder={!isSuperUser ? '' : PLACEHOLDER.BILLING_CONTACT}
                value={hook.hookBillingEmail}
                onChange={hook.hookSetBillingEmail}
                onBlur={() => hook.hookValidateFields()}
                type="text"
                theme={themeForNonSuper}
                error={hook.hookMerchantErrors.billingContact}
                disabled={isReadOnly}
              />
              <Styled.InputTextStyled
                label={INPUT_LABEL.GST}
                value={hook.hookGst}
                onChange={hook.hookSetGst}
                onBlur={() => hook.hookValidateBillingInfo()}
                placeholder={!isSuperUser ? '' : TAX_PLACEHOLDER.GST}
                type="text"
                theme={themeForNonSuper}
                error={hook.hookBillingInfoErrors.gstTaxation}
                disabled={hook.hookGstDisabled || isReadOnly}
              />
              <Styled.InputTextStyled
                label={INPUT_LABEL.PST}
                value={hook.hookPst}
                onChange={hook.hookSetPst}
                onBlur={() => hook.hookValidateBillingInfo()}
                placeholder={!isSuperUser ? '' : TAX_PLACEHOLDER.PST}
                type="text"
                theme={themeForNonSuper}
                error={hook.hookBillingInfoErrors.pstTaxation}
                disabled={hook.hookPstDisabled || isReadOnly}
              />
              <Styled.InputTextStyled
                label={INPUT_LABEL.HST}
                value={hook.hookHst}
                onChange={hook.hookSetHst}
                onBlur={() => hook.hookValidateBillingInfo()}
                placeholder={!isSuperUser ? '' : TAX_PLACEHOLDER.HST}
                type="text"
                theme={themeForNonSuper}
                error={hook.hookBillingInfoErrors.hstTaxation}
                disabled={hook.hookHstDisabled || isReadOnly}
              />
            </Styled.GridedRowStyled>
          </Styled.SectionStyled>
        </>
        )}
        {hook.hookUserManagementCanAccess && (
          <>
            <Styled.SectionStyled noMarginTop={!hook.hookAccountDetailsCanAccess}>
              <Styled.SectionTitleStyled>
                {FORM_SECTION_TITLE.USER}

                <Styled.HeaderButtonAreaStyled theme="secondary">
                  <Styled.ButtonStyled
                    theme="tertiary"
                    onClick={hook.hookSetToggleModal}
                    disabled={isUserManagementReadOnly}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    {BUTTON_LABEL.ADD_USER}
                  </Styled.ButtonStyled>
                </Styled.HeaderButtonAreaStyled>
              </Styled.SectionTitleStyled>

              <Table
                columns={columnHook.columns}
                data={hook.hookUserList}
                checkInactive={hook.hookcheckActiveStatus}
                onSort={hook.hookSetSortColumn}
                sortColumn={hook.hookSortColumn}
                isLoading={hook.hookIsLoading}
                inactiveModal
              />

            </Styled.SectionStyled>

            {hook.hookOpenModal && (
            <Modal isOpen={hook.hookOpenModal}>
              <ManageUserModal
                userTypesId={2}
                closeFn={hook.hookSetSaveCloseModal}
                userToEdit={hook.hookCurrentUser}
                edit={!!hook.hookCurrentUser}
                add={!hook.hookCurrentUser}
                hasWritePermission={!isUserManagementReadOnly}
              />
            </Modal>
            )}

            {hook.hookCurrentUser && (
            <DeactivationForm
              isOpen={hook.hookDeactivateModal}
              onClose={hook.hookSetDeactivateModal}
              onCloseText={BUTTON_LABEL.CANCEL}
              onConfirm={() => hook.hookHandleDeactiveUser(hook.hookCurrentUser.auth0Id)}
              onConfirmText={BUTTON_LABEL.DEACTIVATE}
              title={DEACTIVE_MODAL.TITLE}
              desc={hook.hookCurrentUser.isSuperUser ? DEACTIVE_MODAL.SUPER_USER : DEACTIVE_MODAL.DESCRIPTION}
              userInformation={{
                firstName: hook.hookCurrentUser.firstName,
                lastName: hook.hookCurrentUser.lastName,
                email: hook.hookCurrentUser.email,
              }}
              isConfirmDisable={hook.hookCurrentUser.isSuperUser}
            />
            )}
          </>
        )}
      </Styled.WrapperStyled>
    </AppWrapper>
  );
};

export default MerchantAccountDetails;
