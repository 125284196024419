import React from 'react';
import {
  Button, Link, Radio, FixedFooter, LeavePage,
} from '../../../../../../components';
import {
  MERCHANT_PREFIX, path,
} from '../../../../../../utils';
import * as Styled from './styles';
import { SETTINGS_CHECK } from './enums';
import { UpdateSettingsModal } from './Components';

type SettingProps = {
  hook: any
  isReadOnly: boolean
}

const CheckTab = ({ hook, isReadOnly }: SettingProps) => (
  <Styled.WrapperStyled>
    {!hook.hookOpenSettings && hook.hookPageDataLoad && (
    <Styled.InstructionsHeaderStyled>
      <p>{SETTINGS_CHECK.DISABLED.TITLE}</p>
      <Styled.InstructionsTextStyled>
        <p>{SETTINGS_CHECK.DISABLED.INSTRUCTION}</p>
        <p>{SETTINGS_CHECK.DISABLED.DESCRIPTION}</p>
      </Styled.InstructionsTextStyled>
    </Styled.InstructionsHeaderStyled>
    )}
    {hook.hookOpenSettings && (
    <>
      <Styled.SectionStyled>
        <Styled.SectionTitleStyled>{SETTINGS_CHECK.FREQUENCY.TITLE}</Styled.SectionTitleStyled>
        <Styled.SectionSubtitleStyled>{SETTINGS_CHECK.FREQUENCY.DESCRIPTION}</Styled.SectionSubtitleStyled>

        <Styled.SectionContentStyled>
          <Styled.ButtonStyled
            theme={hook.hookSelectWeeklyButton === true ? 'tertiary' : 'secondary'}
            onClick={hook.hookSetSelectWeeklyButton}
            disabled={isReadOnly}
          >
            {SETTINGS_CHECK.FREQUENCY.WEEKLY}
          </Styled.ButtonStyled>
          <Styled.ButtonStyled
            theme={hook.hookSelectBiweeklyButton === true ? 'tertiary' : 'secondary'}
            onClick={hook.hookSetSelectBiweeklyButton}
            disabled={isReadOnly}
          >
            {SETTINGS_CHECK.FREQUENCY.BIWEEKLY}
          </Styled.ButtonStyled>
          <Styled.ButtonStyled
            theme={hook.hookSelectMonthlyButton === true ? 'tertiary' : 'secondary'}
            onClick={hook.hookSetSelectMonthlyButton}
            disabled={isReadOnly}
          >
            {SETTINGS_CHECK.FREQUENCY.MONTHLY}
          </Styled.ButtonStyled>
        </Styled.SectionContentStyled>
      </Styled.SectionStyled>
      <Styled.SectionStyled>
        <Styled.SectionTitleStyled>{SETTINGS_CHECK.SCAN.TITLE}</Styled.SectionTitleStyled>
        <Styled.SectionSubtitleStyled>{SETTINGS_CHECK.SCAN.DESCRIPTION}</Styled.SectionSubtitleStyled>

        <Styled.TimeFrameStyled>
          <Styled.SelectStyled
            name="number"
            onChange={hook.hookSetSelectedScan}
            options={SETTINGS_CHECK.SCAN.OPTIONS}
            selected={hook.hookSelectedScan}
            isDisabled={isReadOnly}
          />
        </Styled.TimeFrameStyled>
        <Styled.NextReport>
          {`${SETTINGS_CHECK.SCAN.NEXT_REPORT} ${hook.hookNextReport}`}
        </Styled.NextReport>
      </Styled.SectionStyled>
      <Styled.SectionStyled>
        <Styled.SectionTitleStyled>{SETTINGS_CHECK.NON_APPLICABLE.TITLE}</Styled.SectionTitleStyled>
        <Styled.SectionSubtitleStyled>{SETTINGS_CHECK.NON_APPLICABLE.DESCRIPTION}</Styled.SectionSubtitleStyled>

        <Styled.TimeFrameStyled>
          <Styled.SelectionAreaStyled>
            <Styled.InputStyled
              type="number"
              value={hook.hookDays}
              onChange={hook.hookSetDays}
              disabled={isReadOnly}
            />
            <Styled.SelectStyled
              name="number"
              onChange={hook.hookSetDayFrequency}
              selected={hook.hookDayFrequency}
              options={SETTINGS_CHECK.NON_APPLICABLE.OPTIONS}
              isDisabled={isReadOnly}
            />
          </Styled.SelectionAreaStyled>
        </Styled.TimeFrameStyled>
      </Styled.SectionStyled>

      <Styled.SectionStyled>
        <Styled.SectionTitleStyled>{SETTINGS_CHECK.PUBLISHER.TITLE}</Styled.SectionTitleStyled>
        <Styled.SectionSubtitleStyled>{SETTINGS_CHECK.PUBLISHER.DESCRIPTION}</Styled.SectionSubtitleStyled>

        <Styled.SectionContentStyled>
          <Styled.ButtonStyled
            theme={hook.hookSelectAllSettingsButton === true ? 'tertiary' : 'secondary'}
            onClick={hook.hookSetSelectAllSettingsButton}
            disabled={isReadOnly}
          >
            {SETTINGS_CHECK.PUBLISHER.ALL}
          </Styled.ButtonStyled>
          <Styled.ButtonStyled
            theme={hook.hookSelectListPublishersButton === true ? 'tertiary' : 'secondary'}
            onClick={() => {
              hook.hookSetSelectListPublishersButton();
              hook.hookNavigate(`${MERCHANT_PREFIX}${path.fintelCheckPublisherSearch.href}`);
            }}
            disabled={isReadOnly}
          >
            {SETTINGS_CHECK.PUBLISHER.LIST}
          </Styled.ButtonStyled>
          {hook.hookSelectListPublishersButton && (
            <Link to={`${MERCHANT_PREFIX}${path.fintelCheckPublisherSearch.href}`}>{SETTINGS_CHECK.PUBLISHER.EDIT}</Link>
          )}
        </Styled.SectionContentStyled>

        {hook.hookSelectListPublishersButton && (
          <Styled.ShowPublisherSelectionStyled>
            {`${hook.hookPublishersSelected} ${SETTINGS_CHECK.PUBLISHER.SELECTED}`}
          </Styled.ShowPublisherSelectionStyled>
        )}
      </Styled.SectionStyled>

      <Styled.SectionStyled>
        <Styled.SectionTitleStyled>{SETTINGS_CHECK.URL_TYPES.TITLE}</Styled.SectionTitleStyled>
        <Styled.SectionSubtitleStyled>{SETTINGS_CHECK.URL_TYPES.DESCRIPTION}</Styled.SectionSubtitleStyled>

        <Styled.SectionContentRadioStyled>
          <Styled.RadioStyled>
            <Radio
              name="tracking"
              value="relevant"
              onChange={hook.hookSetSelectRelevant}
              checked={hook.hookSelectRelevant}
              disabled={isReadOnly}
            />
            <Styled.RadioLabelStyled>{SETTINGS_CHECK.URL_TYPES.RELEVANT}</Styled.RadioLabelStyled>
          </Styled.RadioStyled>
          <Styled.RadioStyled>
            <Radio
              name="tracking"
              value="manual"
              onChange={hook.hookSetSelectManual}
              checked={hook.hookSelectManual}
              disabled={isReadOnly}
            />
            <Styled.RadioLabelStyled>{SETTINGS_CHECK.URL_TYPES.MANUAL}</Styled.RadioLabelStyled>
          </Styled.RadioStyled>
          <Styled.RadioStyled>
            <Radio
              name="tracking"
              value="both"
              onChange={hook.hookSetSelectBoth}
              checked={hook.hookSelectBoth}
              disabled={isReadOnly}
            />
            <Styled.RadioLabelStyled>{SETTINGS_CHECK.URL_TYPES.BOTH}</Styled.RadioLabelStyled>
          </Styled.RadioStyled>
        </Styled.SectionContentRadioStyled>
        <Button
          onClick={() => hook.hookNavigate(`${MERCHANT_PREFIX}${path.fintelCheckManualUrlsList.href}`)}
          disabled={isReadOnly}
        >
          {SETTINGS_CHECK.URL_TYPES.EDIT}
        </Button>
      </Styled.SectionStyled>

      <Styled.SectionStyled>
        <Styled.SectionTitleStyled>{SETTINGS_CHECK.URL_PREFERENCES.TITLE}</Styled.SectionTitleStyled>
        <Styled.SectionSubtitleStyled>{SETTINGS_CHECK.URL_PREFERENCES.DESCRIPTION}</Styled.SectionSubtitleStyled>

        <Styled.SectionContentRadioStyled>
          <Styled.RadioStyled>
            <Radio
              name="tracking"
              value="all"
              onChange={hook.hookSetSelectAllDomain}
              checked={hook.hookSelectAllDomain}
              disabled={isReadOnly}
            />
            <Styled.RadioLabelStyled>{SETTINGS_CHECK.URL_PREFERENCES.ALL}</Styled.RadioLabelStyled>
          </Styled.RadioStyled>

          <Styled.RadioStyled>
            <Radio
              name="tracking"
              value="specific"
              onChange={hook.hookSetSelectTimeFrame}
              checked={hook.hookSelectTimeFrame}
              disabled={isReadOnly}
            />
            <Styled.RadioLabelStyled>{SETTINGS_CHECK.URL_PREFERENCES.SPECIFIED}</Styled.RadioLabelStyled>
          </Styled.RadioStyled>
        </Styled.SectionContentRadioStyled>

        {hook.hookSelectTimeFrame && (
          <Styled.TimeFrameStyled>
            <Styled.SectionSubtitleStyled>{SETTINGS_CHECK.URL_PREFERENCES.TIMEFRAME}</Styled.SectionSubtitleStyled>

            <Styled.SelectionAreaStyled>
              <i>{SETTINGS_CHECK.URL_PREFERENCES.LAST}</i>
              <Styled.SelectStyled
                name="number"
                onChange={hook.hookSetTimeframeDay}
                selected={hook.hookTimeframeDay}
                options={hook.hookTimeframeDayOptions}
                isDisabled={isReadOnly}
              />
              <Styled.SelectStyled
                name="range"
                onChange={hook.hookSetTimeframeFrequency}
                selected={hook.hookTimeframeFrequency}
                options={hook.hookTimeframeFrequencyOptions}
                isDisabled={isReadOnly}
              />
            </Styled.SelectionAreaStyled>
          </Styled.TimeFrameStyled>
        )}
      </Styled.SectionStyled>

      <Styled.SectionStyled>
        <Styled.SectionTitleStyled>{SETTINGS_CHECK.BLACKLIST.TITLE}</Styled.SectionTitleStyled>
        <Styled.SectionSubtitleStyled>{SETTINGS_CHECK.BLACKLIST.DESCRIPTION}</Styled.SectionSubtitleStyled>
        <Button
          onClick={() => hook.hookNavigate(`${MERCHANT_PREFIX}${path.fintelCheckBlacklist.href}`)}
          disabled={isReadOnly}
        >
          {SETTINGS_CHECK.BLACKLIST.EDIT}
        </Button>
      </Styled.SectionStyled>

      <FixedFooter>
        <Button
          onClick={hook.hookSetModalOpen}
          disabled={hook.hookUpdateSettingsButton || isReadOnly}
        >
          {SETTINGS_CHECK.FOOTER.SAVE}

        </Button>
      </FixedFooter>
    </>
    )}
    <UpdateSettingsModal
      hook={hook}
      isOpen={hook.hookIsModalOpen}
      setIsOpen={hook.hookSetModalOpen}
    />
    <LeavePage
      isOpen={hook.hookIsBlockerOpen}
      onStay={hook.hookOnStay}
      onLeave={hook.hookOnLeave}
    />
  </Styled.WrapperStyled>
);

export default CheckTab;
