import { gql } from '@apollo/client';

export const GET_OVERVIEW = gql`
query getFintelCheckOverview($input: FintelCheckSearchInput!) {
  fintelChecksOverview(input: $input) {
    lastCheckDate
    publisherCheckPass
    publisherCheckFail
    publisherCheckReview
    publisherCheckIncomplete
    publisherCheckNf
    prevCheckDate
    prevPublisherCheckPass
    prevPublisherCheckFail
    prevPublisherCheckReview
    prevPublisherCheckIncomplete
    prevPublisherCheckNf
    ruleCheckPass
    ruleCheckReview
    ruleCheckFail
    ruleCheckNf
    ruleCheckIncomplete
    ruleCheckDetails {
      pass {
        name
        value
      }
      review {
        name
        value
      }
      fail {
        name
        value
      }
      nf {
        name
        value
      }
      incomplete {
        name
        value
      }
    }
  }
}
`;
