import { useEffect, useState } from 'react';

type RecordsState = {
  label: string;
  value: string;
}

type UsePaginationProps = {
  totalPages: number;
  currentPage: number;
  cbPage?: (page: number) => void;
  cbRecordsPerPage?: (recordsPerPage: number) => void;
}

export const usePagination = ({
  currentPage, totalPages, cbPage, cbRecordsPerPage,
}: UsePaginationProps) => {
  const [currentPageState, setCurrentPageState] = useState(currentPage);
  const [totalPagesState, setTotalPages] = useState(totalPages);
  const [recordsState, setRecordsState] = useState<any>();

  const handlePagination = (page: number) => {
    if (page >= 1 && page <= totalPagesState) {
      setCurrentPageState(page);

      if (cbPage) {
        cbPage(page);
      }
    }
  };

  const handleRecordsPerPage = (records: RecordsState) => {
    setRecordsState(records);

    if (cbRecordsPerPage) {
      const { value } = records;
      cbRecordsPerPage(Number(value));
    }
  };

  useEffect(() => {
    setCurrentPageState(currentPage);
    setTotalPages(totalPages);
  }, [totalPages, currentPage]);

  return {
    hookCurrentPage: currentPageState,
    hookTotalPages: totalPagesState,
    hookSetCurrentPage: handlePagination,
    hookRecordsPerPage: recordsState,
    hookSetRecordsPerPage: handleRecordsPerPage,
  };
};
