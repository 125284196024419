import { gql } from '@apollo/client';

export const GET_MERCHANTS_LIST = gql`
  query companies($input: CompanyInput) {
    companies(input: $input) {
      companies {
        id
        companyName
      }
    }
  }
`;
