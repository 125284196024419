import React from 'react';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper,
  Button,
  InputText,
  Modal,
  Radio,
  Select,
  ErrorBanner,
} from '../../../components';
import { useSecurityPermission } from './hooks';
import { SECURITY_PERMISSIONS_PAGE } from './enums';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type SecurityPermissionProps = DefaultPropsType;

const SecurityPermission = ({ permissionsCodeList = [] }: SecurityPermissionProps) => {
  const { hookIsReadOnlyList, ...hook } = useSecurityPermission(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        message={hook.hookErrorMsg}
        isOpen={!!hook.hookErrorMsg}
      />
      <Styled.WrapperStyled>
        <Styled.TitleStyled
          isLoading={hook.hookLoading}
          loadingText={hook.hookLoadingMessage}
        >
          {SECURITY_PERMISSIONS_PAGE.PAGE_TITLE}
        </Styled.TitleStyled>

        <Styled.RolesStyled>
          {SECURITY_PERMISSIONS_PAGE.ROLES_TITLE}
          <Button
            onClick={() => hook.hookSetIsModalOpenHandler(true)}
            disabled={hook.hookLoading || isReadOnly}
          >
            <Styled.NewRoleStyled>
              <FontAwesomeIcon icon={faPlus} />
              {SECURITY_PERMISSIONS_PAGE.NEW_ROLE_BUTTON_TEXT}
            </Styled.NewRoleStyled>
          </Button>
        </Styled.RolesStyled>

        <Styled.RoleTypeStyled>
          <Styled.RoleTypeTitleStyled>
            {SECURITY_PERMISSIONS_PAGE.ROLE_TYPE_LABEL}
          </Styled.RoleTypeTitleStyled>
          <Styled.RoleTypeListStyled>
            {hook.hookUserTypeList.map((item) => (
              <Styled.RadioBoxStyled key={item.value}>
                <Radio
                  name="role"
                  value={item.value}
                  checked={item.value === hook.hookSelectedUserType}
                  onChange={hook.hookSetSelectedUserType}
                  disabled={hook.hookLoading}
                />
                <Styled.RadioBoxLabelStyled>
                  {item.label}
                </Styled.RadioBoxLabelStyled>
              </Styled.RadioBoxStyled>
            ))}
          </Styled.RoleTypeListStyled>
        </Styled.RoleTypeStyled>

        <Styled.RoleNameWrapper>
          <Select
            label={`${hook.hookSelectedUserType} ${SECURITY_PERMISSIONS_PAGE.ROLE_SELECTION_LABEL_PARTIAL}`}
            name="role name"
            onChange={hook.hookSetSelectedUserRole}
            options={hook.hookUserRoleList}
            selected={hook.hookSelectedUserRole}
            isLoading={hook.hookLoading}
            isDisabled={hook.hookLoading}
          />
        </Styled.RoleNameWrapper>

        {hook.hookSelectedUserType && hook.hookSelectedUserRole && (
          <Styled.UpdatePermissionsStyled>
            {SECURITY_PERMISSIONS_PAGE.PERMISSIONS.TITLE}

            {hook.hookPermissionsList?.map((item: any) => (
              <Styled.SettingsOptionsStyled key={item.permissionId}>
                <Styled.OptionTitleWrapper>
                  <Styled.OptionTitleStyled>
                    <Button
                      onClick={() => hook.hookSetSectionOpen(item.permissionId)}
                      theme="quaternary"
                    >
                      {item.appSubSections.length > 0 && (
                      <FontAwesomeIcon icon={hook.hookSectionOpen === item.permissionId ? faChevronUp : faChevronDown} />
                      )}

                      {item.name}
                      {' '}
                      {item.appSubSections.length > 0 && `[${item.appSubSections.length}]`}
                    </Button>
                  </Styled.OptionTitleStyled>
                  <Styled.OptionCRUDStyled>
                    {item.appSubSections.length > 0 && (
                    <>
                      <Styled.RadioBoxPemissionStyled>
                        <Styled.RadioBoxPemissionLabelStyled>
                          Write
                        </Styled.RadioBoxPemissionLabelStyled>
                        <Radio
                          name={item.permissionId}
                          value="write"
                          checked={item.permission === 'write'}
                          onChange={() => hook.hookSetSectionPermission(item, 'write')}
                          disabled={hook.hookLoading || isReadOnly}
                          partial={hook.hookCheckIfAllSubSectionsAreSelected(item, 'write')}
                        />
                      </Styled.RadioBoxPemissionStyled>
                      <Styled.RadioBoxPemissionStyled>
                        <Styled.RadioBoxPemissionLabelStyled>
                          Read Only
                        </Styled.RadioBoxPemissionLabelStyled>
                        <Radio
                          name={item.permissionId}
                          value="read"
                          checked={item.permission === 'read'}
                          onChange={() => hook.hookSetSectionPermission(item, 'read')}
                          disabled={hook.hookLoading || isReadOnly}
                          partial={hook.hookCheckIfAllSubSectionsAreSelected(item, 'read')}
                        />
                      </Styled.RadioBoxPemissionStyled>
                      <Styled.RadioBoxPemissionStyled>
                        <Styled.RadioBoxPemissionLabelStyled>
                          No Access
                        </Styled.RadioBoxPemissionLabelStyled>
                        <Radio
                          name={item.permissionId}
                          value="noAccess"
                          checked={item.permission === 'noAccess'}
                          onChange={() => hook.hookSetSectionPermission(item, 'noAccess')}
                          disabled={hook.hookLoading || isReadOnly}
                          partial={hook.hookCheckIfAllSubSectionsAreSelected(item, 'noAccess')}
                        />
                      </Styled.RadioBoxPemissionStyled>
                    </>
                    )}

                    {item.appSubSections.length === 0 && (
                    <>
                      <Styled.RadioBoxPemissionStyled>
                        <Styled.RadioBoxPemissionLabelStyled>
                          Write
                        </Styled.RadioBoxPemissionLabelStyled>
                        <Radio
                          name={item.permissionId}
                          value="write"
                          checked={item.permission === 'write'}
                          onChange={() => hook.hookSetSectionPermission(item, 'write')}
                          disabled={hook.hookLoading || isReadOnly}
                        />
                      </Styled.RadioBoxPemissionStyled>
                      <Styled.RadioBoxPemissionStyled>
                        <Styled.RadioBoxPemissionLabelStyled>
                          Read Only
                        </Styled.RadioBoxPemissionLabelStyled>
                        <Radio
                          name={item.permissionId}
                          value="read"
                          checked={item.permission === 'read'}
                          onChange={() => hook.hookSetSectionPermission(item, 'read')}
                          disabled={hook.hookLoading || isReadOnly}
                        />
                      </Styled.RadioBoxPemissionStyled>
                      <Styled.RadioBoxPemissionStyled>
                        <Styled.RadioBoxPemissionLabelStyled>
                          No Access
                        </Styled.RadioBoxPemissionLabelStyled>
                        <Radio
                          name={item.permissionId}
                          value="noAccess"
                          checked={item.permission === 'noAccess'}
                          onChange={() => hook.hookSetSectionPermission(item, 'noAccess')}
                          disabled={hook.hookLoading || isReadOnly}
                        />
                      </Styled.RadioBoxPemissionStyled>
                    </>
                    )}
                  </Styled.OptionCRUDStyled>
                </Styled.OptionTitleWrapper>

                {item.appSubSections.length > 0 && (
                <Styled.OptionPageWrapper isOpen={hook.hookSectionOpen === item.permissionId}>
                  {item.appSubSections.map((subItem: any) => (
                    <Styled.PermissionListItemStyled>
                      <Styled.OptionPageStyled>
                        {subItem.name}
                      </Styled.OptionPageStyled>
                      <Styled.OptionPageCRUDStyled>
                        <Styled.RadioBoxPemissionStyled>
                          <Radio
                            name={subItem.permissionId}
                            value="write"
                            checked={subItem.permission === 'write'}
                            onChange={() => hook.hookSetSubSectionPermission(item.permissionId, subItem.permissionId, 'write')}
                            disabled={hook.hookLoading || isReadOnly}
                          />
                        </Styled.RadioBoxPemissionStyled>
                        <Styled.RadioBoxPemissionStyled>
                          <Radio
                            name={subItem.permissionId}
                            value="read"
                            checked={subItem.permission === 'read'}
                            onChange={() => hook.hookSetSubSectionPermission(item.permissionId, subItem.permissionId, 'read')}
                            disabled={hook.hookLoading || isReadOnly}
                          />
                        </Styled.RadioBoxPemissionStyled>
                        <Styled.RadioBoxPemissionStyled>
                          <Radio
                            name={subItem.permissionId}
                            value="noAccess"
                            checked={subItem.permission === 'noAccess'}
                            onChange={() => hook.hookSetSubSectionPermission(item.permissionId, subItem.permissionId, 'noAccess')}
                            disabled={hook.hookLoading || isReadOnly}
                          />
                        </Styled.RadioBoxPemissionStyled>
                      </Styled.OptionPageCRUDStyled>
                    </Styled.PermissionListItemStyled>
                  ))}
                </Styled.OptionPageWrapper>
                )}
              </Styled.SettingsOptionsStyled>
            ))}

          </Styled.UpdatePermissionsStyled>
        )}

        <Modal isOpen={hook.hookIsModalOpen}>
          <Styled.CreateRoleStyled>
            <Styled.CreateRoleTitleStyled>
              {SECURITY_PERMISSIONS_PAGE.CREATE_ROLE_MODAL.TITLE}
            </Styled.CreateRoleTitleStyled>
            <Styled.CreateRoleTypeStyled>
              <Select
                label={SECURITY_PERMISSIONS_PAGE.CREATE_ROLE_MODAL.ROLE_TYPE_LABEL}
                name="roleType"
                selected={hook.hookNewRoleType}
                options={hook.hookUserTypeList}
                onChange={hook.hookSetNewRoleTypeHandler}
                required
              />
            </Styled.CreateRoleTypeStyled>

            <InputText
              label={SECURITY_PERMISSIONS_PAGE.CREATE_ROLE_MODAL.ROLE_NAME_LABEL}
              name="roleName"
              type="text"
              value={hook.hookNewRoleName}
              onChange={(e) => hook.hookSetNewRoleNameHandler(e)}
              required
            />

            <Styled.CreateRoleButtonsStyled>
              <Button
                onClick={() => hook.hookSetIsModalOpenHandler(false)}
                theme="secondary"
              >
                {SECURITY_PERMISSIONS_PAGE.CREATE_ROLE_MODAL.CANCEL_BUTTON}
              </Button>
              <Button
                onClick={hook.hookCreateNewRoleHandler}
                disabled={!hook.hookNewRoleName}
              >
                {SECURITY_PERMISSIONS_PAGE.CREATE_ROLE_MODAL.CONFIRM_BUTTON}
              </Button>
            </Styled.CreateRoleButtonsStyled>
          </Styled.CreateRoleStyled>
        </Modal>
      </Styled.WrapperStyled>
    </AppWrapper>
  );
};

export default SecurityPermission;
