import styled from 'styled-components';
import { Button } from '../Button';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  padding: 2rem;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const HeaderButtonAreaStyled = styled.div`
  display: flex;
`;

export const HeaderButtonStyled = styled(Button)`
  margin-right: 1rem;
`;
