import { gql } from '@apollo/client';

export const CREATE_ENCRYPTION = gql`
  mutation createEncryption($input: CreateEncryptionInput!) {
    createEncryption(input: $input) {
      hash
      permissionsHash
    }
  }
`;
