import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { ClickStructure } from '../components/Graph/ClickByDevice/types';
import { SnapshotStructureProps } from '../components/Graph/Snapshot/types';
import { ProductsApprovalStructure } from '../components/Graph/ProductByApproval/types';
import { useRenderCards } from './useRenderCards';
import { MerchantData } from '../../../../components/StatisticCard/types';
import {
  MERCHANT_DASHBOARD_ONE,
  MERCHANT_DASHBOARD_TWO,
  MERCHANT_DASHBOARD_THREE,
  MERCHANT_DASHBOARD_FOUR,
} from '../queries';
import { useCalendar, useUserInfo } from '../../../../hooks';
import { MERCHANT_STATUS_OPTIONS } from '../../../../utils';
import { PROGRAM_V2 } from '../queries/program';

type PublishersProps = {
  id: string
  totalCommissions: number
  approvedTransactions: number
  commissionIncrease: number
  approvedTransactionsIncrease: number
  publisher: {
    companyName: string
  }
}

type ProductProps = {
  id: string
  totalCommissions: number
  totalTransactions: number
  approvedTransactions: number
  clicks: number
  commissionIncrease: number
  product: {
    name: string
  }
}

export const useDashboard = () => {
  const hookRenderCards = useRenderCards();
  const userHook = useUserInfo();
  const hookCalendar = useCalendar();

  const [commission, setCommission] = useState<MerchantData>({
    currentPerformance: 0,
    prevPerformance: 0,
    todaysPerformance: 0,
    performanceIncrease: 0,
  });
  const [approvedTransactions, setApprovedTransactions] = useState<MerchantData>({
    currentPerformance: 0,
    prevPerformance: 0,
    todaysPerformance: 0,
    performanceIncrease: 0,
  });
  const [conversionRate, setConversionRate] = useState<MerchantData>({
    currentPerformance: 0,
    prevPerformance: 0,
    todaysPerformance: 0,
    performanceIncrease: 0,
  });
  const [publisherClick, setPublisherClick] = useState<MerchantData>({
    currentPerformance: 0,
    prevPerformance: 0,
    todaysPerformance: 0,
    performanceIncrease: 0,
  });
  const [publisherApproval, setPublisherApproval] = useState<MerchantData>({
    currentPerformance: 0,
    prevPerformance: 0,
    todaysPerformance: 0,
    performanceIncrease: 0,
  });

  const [announcements, setAnnouncements] = useState(0);
  const [messages, setMessages] = useState(0);
  const [pendingApplications, setPendingApplications] = useState(0);
  const [pendingTransactions, setPendingTransactions] = useState(0);

  const [showAnnouncements, setShowAnnouncements] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [showBalance, setShowBalance] = useState(false);

  const [topPublishers, setTopPublishers] = useState<PublishersProps[]>([]);
  const [topProducts, setTopProducts] = useState<ProductProps[]>([]);

  const [snapshotData, setSnapshotData] = useState<SnapshotStructureProps>({
    totalTransactions: [0],
    approvedTransactions: [0],
    commissions: [0],
  });
  const [clicksData, setClicksData] = useState<ClickStructure>({
    total: 0,
    desktop: 0,
    mobile: 0,
    tablet: 0,
    desktopPercentage: 0,
    mobilePercentage: 0,
    tabletPercentage: 0,
    desktopPerformanceIncrease: 0,
    mobilePerformanceIncrease: 0,
    tabletPerformanceIncrease: 0,
    trafficAnallytics: {
      canadaTotal: 0,
      usTotal: 0,
      total: 0,
      canada: [],
      us: [],
      countries: [],
    },
  });
  const [productApprovalData, setProductApprovalData] = useState<ProductsApprovalStructure[]>([]);

  // queries
  const [getMerchantDashboardOne, { loading: getMerchantDashboardOneLoading }] = useLazyQuery(MERCHANT_DASHBOARD_ONE);
  const [getMerchantDashboardTwo, { loading: getMerchantDashboardTwoLoading }] = useLazyQuery(MERCHANT_DASHBOARD_TWO);
  const [getMerchantDashboardThree, { loading: getMerchantDashboardThreeLoading }] = useLazyQuery(MERCHANT_DASHBOARD_THREE);
  const [getMerchantDashboardFour, { loading: getMerchantDashboardFourLoading }] = useLazyQuery(MERCHANT_DASHBOARD_FOUR);
  const [getProgramCurrency, { loading: getProgramCurrencyLoading }] = useLazyQuery(PROGRAM_V2);
  const [currency, setCurrency] = useState('CAD');

  const getProgramCurrencyQuery = async () => {
    const { data } = await getProgramCurrency({
      variables: {
        programV2Id: userHook.hookWhoAmI.programId,
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.programV2?.transactionSettings?.currency !== undefined) {
      setCurrency(`Commission In ${data.programV2.transactionSettings.currency}`);
    }
  };

  const setStatisticsHandler = async () => {
    getMerchantDashboardOne({
      variables: {
        input: {
          merchantId: userHook.hookWhoAmI.companyId?.toString(),
          endDate: hookCalendar.hookEndDateToUTCHours,
          startDate: hookCalendar.hookStartDateToUTCHours,
          prevEndDate: hookCalendar.hookPreviousMonthEndDate,
          prevStartDate: hookCalendar.hookPreviousMonthStartDate,
        },
      },
      fetchPolicy: 'no-cache',
    }).then(({ data: { merchantDashboardOne } }) => {
      const {
        announcementsNumber,
        messageHubMessagesNumber,
        isBellowMinimunBalance,
        pendingTransactions: pendingTransactionsAmount,
        merchantPerformance,
        clicks,
      } = merchantDashboardOne;

      setAnnouncements(announcementsNumber);
      setMessages(messageHubMessagesNumber);
      setShowBalance(isBellowMinimunBalance);
      setPendingTransactions(pendingTransactionsAmount);
      setSnapshotData(merchantPerformance);
      setClicksData({
        total: clicks.total,
        desktop: clicks.desktop,
        mobile: clicks.mobile,
        tablet: clicks.tablet,
        desktopPercentage: clicks.desktopPercentage,
        mobilePercentage: clicks.mobilePercentage,
        tabletPercentage: clicks.tabletPercentage,
        desktopPerformanceIncrease: clicks.desktopPerformanceIncrease,
        mobilePerformanceIncrease: clicks.mobilePerformanceIncrease,
        tabletPerformanceIncrease: clicks.tabletPerformanceIncrease,
        trafficAnallytics: {
          canadaTotal: clicks.canadaTotal,
          usTotal: clicks.usTotal,
          total: clicks.total,
          canada: clicks.canada,
          us: clicks.us,
          countries: clicks.countries,
        },
      });

      if (announcementsNumber > 0) {
        setShowAnnouncements(true);
      }

      if (messageHubMessagesNumber > 0) {
        setShowMessages(true);
      }
    });

    getMerchantDashboardTwo({
      variables: {
        input: {
          merchantId: userHook.hookWhoAmI.companyId?.toString(),
          endDate: hookCalendar.hookEndDateToUTCHours,
          startDate: hookCalendar.hookStartDateToUTCHours,
          prevEndDate: hookCalendar.hookPreviousMonthEndDate,
          prevStartDate: hookCalendar.hookPreviousMonthStartDate,
        },
      },
      fetchPolicy: 'no-cache',
    }).then(({ data: { merchantDashboardTwo } }) => {
      const {
        activePublishersByClick,
        topPublishers: topPublishersPerformance,
        topProducts: topProductsPerformance,
        productsByApprovals,
      } = merchantDashboardTwo;

      setPublisherClick(activePublishersByClick);
      setTopPublishers(topPublishersPerformance);
      setTopProducts(topProductsPerformance);

      if (productsByApprovals.length > 0) {
        setProductApprovalData(productsByApprovals);
      }
    });

    getMerchantDashboardThree({
      variables: {
        input: {
          merchantId: userHook.hookWhoAmI.companyId?.toString(),
          endDate: hookCalendar.hookEndDateToUTCHours,
          startDate: hookCalendar.hookStartDateToUTCHours,
          prevEndDate: hookCalendar.hookPreviousMonthEndDate,
          prevStartDate: hookCalendar.hookPreviousMonthStartDate,
        },
      },
      fetchPolicy: 'no-cache',
    }).then(({ data: { merchantDashboardThree } }) => {
      const {
        approvedTransactions: approvedTransactionsPerformance,
        commissions,
      } = merchantDashboardThree;

      setApprovedTransactions(approvedTransactionsPerformance);
      setCommission(commissions);
    });

    getMerchantDashboardFour({
      variables: {
        input: {
          merchantId: userHook.hookWhoAmI.companyId?.toString(),
          endDate: hookCalendar.hookEndDateToUTCHours,
          startDate: hookCalendar.hookStartDateToUTCHours,
          prevEndDate: hookCalendar.hookPreviousMonthEndDate,
          prevStartDate: hookCalendar.hookPreviousMonthStartDate,
        },
      },
      fetchPolicy: 'no-cache',
    }).then(({ data: { merchantDashboardFour } }) => {
      const {
        activePublishersByApprovals,
        conversionRate: conversionRatePerformance,
        pendingApplications: pendingApplicationsPerformance,
      } = merchantDashboardFour;

      setPublisherApproval(activePublishersByApprovals);
      setConversionRate(conversionRatePerformance);
      setPendingApplications(pendingApplicationsPerformance);
    });
  };

  useEffect(() => {
    setStatisticsHandler();
  }, [hookCalendar.hookStartDate, hookCalendar.hookEndDate]);

  useEffect(() => {
    getProgramCurrencyQuery();
  }, []);

  return {
    hookMerchantName: userHook.hookWhoAmI.companyName,
    hookMerchantStatus: MERCHANT_STATUS_OPTIONS.find((opt) => opt.value === userHook.hookWhoAmI.accountStatus)?.label || '',

    hookStartDate: hookCalendar.hookStartDate,
    hookEndDate: hookCalendar.hookEndDate,
    hookDateRange: hookCalendar.hookDateRange,
    hookCalendarOpen: hookCalendar.hookIsCalendarOpen,
    hookOpenCalendar: hookCalendar.hookOpenCalendar,
    hookCloseCalendar: hookCalendar.hookCloseCalendar,
    hookSetDateRange: hookCalendar.hookSetDateRange,

    hookCommissions: commission,
    hookApprovedTransactions: approvedTransactions,
    hookConversionRate: conversionRate,
    hookPublisherByClick: publisherClick,
    hookPublisherByApproval: publisherApproval,

    hookSnapshotData: snapshotData,
    hookClicksData: clicksData,
    hookProductsData: productApprovalData,

    hookAnnouncements: announcements,
    hookMessages: messages,
    hookShowAnnouncements: showAnnouncements,
    hookShowMessages: showMessages,
    hookShowBalance: showBalance,
    hookDisplayAnnouncements: hookRenderCards.hookDisplayAnnouncements,
    hookDisplayMessages: hookRenderCards.hookDisplayMessages,
    hookDisplayBalance: hookRenderCards.hookDisplayAccountBalance,
    hookDisplayProductsApproval: hookRenderCards.hookDisplayProductsByApproval,
    hookPendingApplications: pendingApplications.toString(),
    hookPendingTransactions: pendingTransactions.toString(),

    hookTopPublishers: topPublishers,
    hookTopProducts: topProducts,
    hookCurrency: currency,

    hookLoadingMerchantDashboardOne: getMerchantDashboardOneLoading,
    hookLoadingMerchantDashboardTwo: getMerchantDashboardTwoLoading,
    hookLoadingMerchantDashboardThree: getMerchantDashboardThreeLoading,
    hookLoadingMerchantInfo: getMerchantDashboardFourLoading,
    hookLoadingProgram: getProgramCurrencyLoading,
  };
};
