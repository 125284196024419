import { gql } from '@apollo/client';

export const UPDATE_FINTEL_CHECK_DETAILS = gql`
  mutation updateFintelCheck($input: FintelCheckInput!) {
    updateFintelCheck(input: $input) {
      id
      feedback {
        correct
        currStatus
        inputType
        inputVal
        issue
        shouldBeStatus
      }
    }
  }
`;
