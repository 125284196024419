import { gql } from '@apollo/client';

export const LIST_ACTIVE_CAMPAIGNS = gql`
  query ListActiveCampaigns($input: ListActiveCampaignsInput) {
    listActiveCampaigns(input: $input) {
      list {
        id
        name
      }
    }
  }
`;
