import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import { Button } from '../../../../../../components';

export const ArrowCell = () => (
  <Button
    theme="text-only"
    onClick={() => null}
  >
    <Styled.IconCell>
      <Styled.IconStyled icon={faChevronRight} />
    </Styled.IconCell>
  </Button>
);
