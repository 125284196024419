import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Table, Pagination, RecordsDropdown,
  Calendar,
} from '../../../../../components';
import * as Styled from './styles';
import { columns } from './contracts';
import { RECORDS_PER_PAGE_OPTIONS_WITH_150, ORDER_STATUS } from '../../../../../utils';
import { PAYPAL } from './enums';
import type { usePaypalOrder } from '../../hooks/usePaypalOrder';

type PaypalOrdersProps = {
  hooks: ReturnType<typeof usePaypalOrder>
  changeTabs: (index: number) => void
}

const PayPalOrders = ({
  hooks, changeTabs,
}: PaypalOrdersProps) => (
  <>
    <Styled.FiltersStyled>
      <Styled.FilterCalendarStyled
        label={PAYPAL.CALENDER_LABEL}
        value={hooks.hookDisplayRange}
        onClick={hooks.hookHandleIsCalendarOpen}
      />
      <Styled.FilterStatusStyled
        label={PAYPAL.STATUS.LABEL}
        name={PAYPAL.STATUS.NAME}
        options={ORDER_STATUS}
        onChange={hooks.hookHandleSetStatus}
        selected={hooks.hookStatus}
      />
    </Styled.FiltersStyled>
    <Styled.ButtonLineWrapper>
      <Styled.FilterButtonStyled
        theme={PAYPAL.CLEAR_FORM.THEME}
        onClick={hooks.hookHandleClearForm}
      >
        {PAYPAL.CLEAR_FORM.TEXT}
      </Styled.FilterButtonStyled>
    </Styled.ButtonLineWrapper>

    <Styled.RecordRowContainer>
      <RecordsDropdown
        selected={hooks.hookRecords}
        onChange={hooks.hookHandleSetRecords}
        options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
        currentPage={hooks.hookPage}
        totalRecords={hooks.hookTotalRecords}
      />
      <Styled.FilterButtonStyled
        theme={PAYPAL.DOWNLOAD_BUTTON.THEME}
        loading={hooks.hookCsvLoading}
        onClick={hooks.hookHandleDownloadCsv}
      >
        <FontAwesomeIcon icon={faArrowDown} />
        {PAYPAL.DOWNLOAD_BUTTON.TEXT}
      </Styled.FilterButtonStyled>
    </Styled.RecordRowContainer>

    {(hooks.hookPaypalOrders.length !== 0 || hooks.hookTableLoading) && (
      <Table
        columns={columns}
        data={hooks.hookPaypalOrders}
        isLoading={hooks.hookTableLoading}
        sortColumn={hooks.hookSortColumn}
        onSort={hooks.hookOnSort}
      />
    )}
    {(hooks.hookPaypalOrders.length !== 0 || hooks.hookTableLoading) && (
      <Pagination
        currentPage={hooks.hookPage}
        onPageChange={hooks.hookChangePage}
        total={hooks.hookTotalPages}
      />
    )}

    <Calendar
      date={hooks.hookDateFilter}
      onApply={hooks.hookHandleAplyDate}
      onCancel={hooks.hookHandleIsCalendarOpen}
      isOpen={hooks.hookIsCalenderOpen}
    />

    {hooks.hookPaypalOrders.length === 0 && !hooks.hookTableLoading && (
      <Styled.NoRecordsWrapperStyled>
        <Styled.NoRecordsTitleStyled>{PAYPAL.NO_RECORDS.NO_TEXT}</Styled.NoRecordsTitleStyled>
        <Styled.NoRecordsDescriptionStyled>{PAYPAL.NO_RECORDS.SHOW_UP}</Styled.NoRecordsDescriptionStyled>
        <Button onClick={() => changeTabs(0)}>
          {PAYPAL.NO_RECORDS.PENDING}
        </Button>
      </Styled.NoRecordsWrapperStyled>
    )}
  </>
);

export default PayPalOrders;
