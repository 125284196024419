import React from 'react';
import Styled from 'styled-components';
import { colors } from '../../../../../../styles/theme';
import { IconCell } from './Component';

const CellWrapperStyled = Styled.div`
  color: ${colors.color1};
  height: 25px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

type Totals = {
  pageCount: number | null
  passCount: number | null
  failCount: number | null
  reviewCount: number | null
  incompleteCount: number | null
  nfCount: number | null
}

export const columns = (totals?: Totals | undefined) : TableColumn[] => {
  const theColumns: TableColumn[] = [
    {
      dataField: 'publisher',
      text: 'Publisher/Url',
      width: '45%',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      footerFormatter: () => (
        <p>TOTAL</p>
      ),
    },
    {
      dataField: 'page',
      text: 'Page',
      width: '6%',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      footerFormatter: () => (
        <p>{totals?.pageCount ? totals.pageCount : ''}</p>
      ),
    },
    {
      dataField: 'pass',
      text: 'Pass',
      width: '5%',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      footerFormatter: () => (
        <p>{totals?.passCount ? totals.passCount : ''}</p>
      ),
    },
    {
      dataField: 'review',
      text: 'Review',
      width: '7%',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      footerFormatter: () => (
        <p>{totals?.reviewCount ? totals.reviewCount : ''}</p>
      ),
    },
    {
      dataField: 'fail',
      text: 'Fail',
      width: '5%',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      footerFormatter: () => (
        <p>{totals?.failCount ? totals.failCount : ''}</p>
      ),
    },
    {
      dataField: 'incomplete',
      text: 'Incomplete',
      width: '12%',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      footerFormatter: () => (
        <p>{totals?.incompleteCount ? totals.incompleteCount : ''}</p>
      ),
    },
    {
      dataField: 'notApplicable',
      text: 'Not Applicable',
      width: '15%',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      footerFormatter: () => (
        <p>{totals?.nfCount ? totals.nfCount : ''}</p>
      ),
    },
    {
      dataField: 'context',
      text: '',
      width: '5%',
      formatter: (_, row, __, ___, ____, _____, setModalInfo) => (
        <IconCell
          row={row}
          setModalInfo={setModalInfo}
        />
      ),
    },
  ];
  return theColumns;
};
