import { gql } from '@apollo/client';

export const LIST_PRODUCT_CATEGORIES = gql`
  query productCategories {
    listProductCategories {
      id
      name
    }
  }
`;
