import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toRem } from '../../utils';
import { Button } from '../../components';
import { colors, fonts } from '../../styles/theme';

type ModalStyledPropTypes = {
  success?: boolean
}

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colors.color1};
  padding-top: ${toRem(30)};
`;

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${toRem(60)} ${toRem(20)};
  gap: ${toRem(12)};
  background-color: ${colors.color4};
`;

export const IconStyled = styled(FontAwesomeIcon)<ModalStyledPropTypes>`
  width: 100%;
  color: ${colors.color17};
  opacity: ${({ success }) => (success ? 1 : 0.3)};
  margin-bottom: ${toRem(20)};
`;

export const TitleStyled = styled.h1`
  ${fonts.lato.H26R}
  color: ${colors.color17};
`;

export const MessageStyled = styled.p`
  ${fonts.lato.H14R}
  color: ${colors.color7};
`;

export const ButtonStyled = styled(Button)`
  margin-top: 1rem;
`;
