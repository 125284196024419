import { gql } from '@apollo/client';

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($input: CampaignInput) {
    createNewCampaign(input: $input) {
      id
      name
      publisherGroupIds
    }
  }
`;
