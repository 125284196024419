import { gql } from '@apollo/client';

export const LIST_DEMOGRAPHIC_AVERAGE_MONTHLY_AUDIENCE_SIZE = gql`
  query List {
    listDemographicAverageMonthlyAudienceSize {
      list {
        id
        range
      }
    }
  }
`;
