import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MEMBERSHIPS_STATUS, MERCHANT_PREFIX, USER_TYPES_ID, imageList, path,

} from '../../../../../utils';
import {
  DEFAULT_PUBLISHER, MENU_BUTTON_INIT, MERCHANT, MODAL, NA, SORT_DEFAULT, STATUS, STATUS_TEXT, TAB_TYPES, USER_TYPE, DEFAULT_OVERVIEW,
} from '../contracts';
import { useModal, useUserInfo } from '../../../../../hooks';
import {
  GET_COMPANY, GET_PUBLISHER_USER, MEMBER_DETAILS, TRACKINGS_DOMAIN, TRACKINGS_PROFILE,
} from '../graphql/queries';
import { UPDATE_MEMBERSHIPS } from '../graphql/mutations';
import { Permission } from '../../../../../entities';
import { formatDomain } from '../../../Dashboard/hooks/useRenderCards';

export const useMembershipDetails = (permissionsCodeList: string[] = []) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { hookWhoAmI, hookUserInfo } = useUserInfo();
  const [getCompany] = useLazyQuery(GET_COMPANY);
  const [getPublisherUser] = useLazyQuery(GET_PUBLISHER_USER);
  const [getTrackingProfile] = useLazyQuery(TRACKINGS_PROFILE);
  const [getTrackingDomain] = useLazyQuery(TRACKINGS_DOMAIN);
  const [getMemebrshipDetails] = useLazyQuery(MEMBER_DETAILS);

  const [updateMemebership] = useMutation(UPDATE_MEMBERSHIPS);

  const [isModalOpen, setIsModalOpen] = useModal(false);

  const [publisherDetails, setPublisherDetails] = useState<PublisherDetailsType>(DEFAULT_PUBLISHER);
  const [publisherDefaultUser, setPublisherDefaultUser] = useState('');
  const [trackingProfiles, setTrackingProfiles] = useState<PublisherDetailsTrackingProfile[]>([]);
  const [trackingDomainProfiles, setTrackingDomainProfiles] = useState<PublisherDetailsTrackingDomains[]>([]);
  const [membershipDetails, setMemebershipDetails] = useState<MembershipDetailsType | undefined>();

  const [modalType, setModalType] = useState<string | undefined>();

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(SORT_DEFAULT);
  const [sortProfileColumn, setProfileSortColumn] = useState(SORT_DEFAULT);

  const [menuButtons, setMenuButtons] = useState(MENU_BUTTON_INIT);
  const [memberPage, setMemberPage] = useState(false);

  const [isLoading, setIsloading] = useState(true);
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>({});
  const [reload, setReload] = useState<string>('');

  const ButtonSetup = (
    membership: {
      status?: string, statusLastUpdatedBy?: string, publisherInvitation?: boolean, invitesOnHold?:boolean,
    },
  ) => {
    const {
      publisherInvitation, statusLastUpdatedBy, status, invitesOnHold,
    } = membership;

    if (invitesOnHold) {
      return {
        ...MENU_BUTTON_INIT, sendInvitation: false,
      };
    }

    if (!status || status === undefined) {
      return {
        ...MENU_BUTTON_INIT, sendInvitation: true,
      };
    }

    switch (status) {
      case MEMBERSHIPS_STATUS.PENDING:
        return (statusLastUpdatedBy === MERCHANT || (!statusLastUpdatedBy && publisherInvitation))
          ? {
            ...MENU_BUTTON_INIT, invited: true,
          }
          : {
            ...MENU_BUTTON_INIT, appove: true, decline: true,
          };
      case MEMBERSHIPS_STATUS.APPROVED:
        return {
          ...MENU_BUTTON_INIT, close: true,
        };
      case MEMBERSHIPS_STATUS.DECLINED:
        return (statusLastUpdatedBy === MERCHANT || (!statusLastUpdatedBy && !publisherInvitation))
          ? {
            ...MENU_BUTTON_INIT, appove: true,
          }
          : MENU_BUTTON_INIT;
      case MEMBERSHIPS_STATUS.CLOSED:
        return (statusLastUpdatedBy === MERCHANT || (!statusLastUpdatedBy && !publisherInvitation))
          ? {
            ...MENU_BUTTON_INIT, appove: true,
          }
          : MENU_BUTTON_INIT;
      default:
        return MENU_BUTTON_INIT;
    }
  };

  const getPublisherDetails = async () => {
    const { data: { company }, error } = await getCompany({
      variables: {
        id: location.state?.publisherId.toString(),
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessages({ message: err.message });
      },
      errorPolicy: 'all',
    });
    const companyInfo = { ...company };
    companyInfo.companyImgUrl = (() => {
      if (company?.companyImgUrl) return company.companyImgUrl;
      if (company?.overview?.profilePhotoURL) return company.overview.profilePhotoURL;
      return imageList.publisherProfilePlaceholder.src;
    })();
    companyInfo.createdAt = new Date(company.createdAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });
    companyInfo.overview = companyInfo?.overview || { ...DEFAULT_OVERVIEW };
    setPublisherDetails(companyInfo);
    if (error) {
      setErrorMessages({ message: error.message });
    }
  };

  const getPublisherInfo = async () => {
    try {
      const { data: { users: { users } }, error } = await getPublisherUser({
        variables: {
          companyId: location.state?.publisherId.toString(),
          usertype: USER_TYPE.PUBLISHER,
          limit: 1,
        },
        fetchPolicy: 'no-cache',
      });
      if (error) throw error;
      setPublisherDefaultUser(`${users?.[0]?.firstName} ${users?.[0]?.lastName}`);
    } catch (error: any) {
      setErrorMessages({ message: error.message });
    }
  };

  const getTrackingProfiles = async () => {
    setIsloading(true);
    try {
      const { data: { listTrackings: { trackings, trackingsCount } }, error } = await getTrackingProfile({
        variables: {
          input: {
            filter: {
              companyId: location.state?.publisherId.toString(),
            },
            options: {
              page: currentPage,
              items: 10,
              order: sortProfileColumn.direction?.toUpperCase(),
            },
            sortBy: sortProfileColumn.column,
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (error) throw error;

      setTrackingProfiles(trackings);
      setTotalPages(Math.ceil(trackingsCount / 10));
      setIsloading(false);
    } catch (error: any) {
      setErrorMessages({ message: error.message });
      setIsloading(false);
    }
  };

  const getTrackingDomains = async () => {
    setIsloading(true);
    try {
      const { data: { trackingDomains: { domains, count } }, error } = await getTrackingDomain({
        variables: {
          input: {
            publisherId: location.state?.publisherId.toString(),
            sizeParPage: 10,
            currentPage,
            sortType: sortColumn.direction,
            sortField: sortColumn.column,
          },
        },
        fetchPolicy: 'no-cache',
      });
      if (error) throw error;
      setTrackingDomainProfiles(domains);
      setTotalPages(Math.ceil(count / 10));
    } catch (error: any) {
      setErrorMessages({ message: error.message });
    }
    setIsloading(false);
  };

  const getMembership = async () => {
    if (!location.state?.membershipId) {
      const buttons = ButtonSetup({ publisherInvitation: false, invitesOnHold: location.state.invitesOnHold });
      setMenuButtons(buttons);
      return;
    }
    try {
      const { data: { membership }, error } = await getMemebrshipDetails({
        variables: {
          id: location.state?.membershipId.toString(),
        },
        fetchPolicy: 'no-cache',
      });

      if (error) throw error;

      const buttons = ButtonSetup({
        status: membership.status,
        statusLastUpdatedBy: membership.statusLastUpdatedBy,
        publisherInvitation: !!membership.publisherInvitation,
      });
      setMenuButtons(buttons);

      setMemebershipDetails(membership);
    } catch (error: any) {
      setErrorMessages({ message: error.message });
    }
  };

  const formatMembershipHistory = (status: string, approvedDate: string, updatedAt: string) => {
    switch (status) {
      case STATUS.APPROVED: return STATUS_TEXT.APPROVED(new Date(approvedDate || updatedAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }));
      case STATUS.DECLINED: return STATUS_TEXT.DECLINED(new Date(updatedAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }));
      case STATUS.CLOSED: return STATUS_TEXT.CLOSED(
        new Date(approvedDate || updatedAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }),
        new Date(updatedAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }),
      );
      default: return NA;
    }
  };

  const convertMembershipStatus = (publisherInvitationId: string, statusLastUpdatedBy: string, status: string) => {
    if (!status) return 'N/A';
    const isPublisherInvitation = !!publisherInvitationId;
    const lastUpdated = statusLastUpdatedBy;
    switch (status) {
      case STATUS.PENDING: return (lastUpdated === 'Merchant' || (!lastUpdated && isPublisherInvitation)) ? STATUS.INVITED : STATUS.PENDING;
      case STATUS.APPROVED: return STATUS.APPROVED;
      case STATUS.DECLINED: return (lastUpdated === 'Merchant' || (!lastUpdated && !isPublisherInvitation)) ? STATUS.DECLINED : STATUS.INVITATION_DECLINED;
      case STATUS.CLOSED: return (lastUpdated === 'Merchant' || (!lastUpdated && !isPublisherInvitation)) ? STATUS.CLOSED_BY_MERCHANT : STATUS.CLOSED_BY_PUBLISHER;
      default: return status;
    }
  };

  const navigateBackHandler = () => {
    if (location?.state.from) {
      navigate(location.state.from, {
        state: {
          ...location.state,
          from: `${MERCHANT_PREFIX}${path.membershipDetails.href}`,
        },
      });
    } else {
      navigate(-1);
    }
  };

  const navagtionInviteHandler = () => {
    navigate(`${MERCHANT_PREFIX}${path.sendPublisherInvitation.href}`, {
      state: {
        publisherId: publisherDetails.id,
        publisherName: publisherDetails?.companyName,
      },
    });
  };

  const navigateMessageHandler = () => {
    window.open(`https://support.${formatDomain('.')}app.fintelconnect.com/newmessage?${hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN ? `cid=${hookWhoAmI.companyId}&` : ''}id=${publisherDetails.id}&companyName=${publisherDetails.companyName}&companyType=${publisherDetails.companyType}&companyEmail=${publisherDetails.companyEmail}`);
  };

  const setModalActionHandler = async () => {
    try {
      if (!modalType || !membershipDetails?.id) return;
      const { errors } = await updateMemebership({
        variables: {
          inputs: [{
            id: membershipDetails.id,
            status: MODAL[modalType].STATUS,
          }],
          status: MODAL[modalType].STATUS,
          statusLastUpdatedBy: 'Merchant',
        },
      });

      if (errors) throw errors;
      getMembership();
      setIsModalOpen();
    } catch (error: any) {
      setErrorMessages({ message: error.message });
    }
  };

  const setModalTypeHandler = (type: string | undefined) => {
    setModalType(type);
    setIsModalOpen();
  };

  const setModalCancelHander = () => {
    setIsModalOpen();
  };

  const setCurrentPageHandler = (value: number, type: string, loading = false) => {
    setCurrentPage(value);
    if (!loading && type === TAB_TYPES.PROFILE) setReload(TAB_TYPES.PROFILE);
    if (!loading && type === TAB_TYPES.DOMAIN) setReload(TAB_TYPES.DOMAIN);
  };

  const setSortColumnHandler = (dataField: string, direction: 'asc' | 'desc' | undefined) => {
    if (sortColumn?.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const setProfileSortColumnHandler = (dataField: string, direction: 'asc' | 'desc' | undefined) => {
    if (sortProfileColumn?.direction === null) {
      setProfileSortColumn({ column: dataField, direction });
    } else {
      setProfileSortColumn({ column: dataField, direction: sortProfileColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  if (reload) {
    setReload('');
    if (reload === TAB_TYPES.PROFILE) getTrackingProfiles();
    if (reload === TAB_TYPES.DOMAIN) getTrackingDomains();
  }

  useEffect(() => {
    setMemberPage(location.pathname.includes(path.membershipDetails.href));
    getMembership();
    getPublisherDetails();
    getPublisherInfo();
  }, []);

  useEffect(() => {
    getTrackingDomains();
  }, [JSON.stringify(sortColumn)]);

  useEffect(() => {
    getTrackingProfiles();
  }, [JSON.stringify(sortProfileColumn)]);

  return {
    hookPublisherDetails: publisherDetails,
    hookPublisherDefaultUser: publisherDefaultUser,
    hookMembershipDetails: membershipDetails,

    hookConvertMembershipStatus: convertMembershipStatus,
    hookFormatMembershipHistory: formatMembershipHistory,

    hookGetTrackingProfiles: getTrackingProfiles,
    hookTrackingProfiles: trackingProfiles,

    hookTrackingDomains: trackingDomainProfiles,
    hookGetTrackingDomains: getTrackingDomains,

    hookIsLoading: isLoading,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,
    hookTotalPages: totalPages,
    hookSortColumn: sortColumn,
    hookProfileSortColumn: sortProfileColumn,
    hookSetSortColumnHandler: setSortColumnHandler,
    hookSetProfileSortColumnHandler: setProfileSortColumnHandler,

    hookMenuButtons: menuButtons,
    hookNavagtionInvite: navagtionInviteHandler,

    hookModalType: modalType,
    hookSetModalType: setModalTypeHandler,
    hookIsModalOpen: isModalOpen,
    hookSetModalCancel: setModalCancelHander,
    hookSetModalAction: setModalActionHandler,
    hookNavigateBackHandler: navigateBackHandler,
    hookNavigateMessageHandler: navigateMessageHandler,

    hookErrorMessages: errorMessages,
    hookMemberPage: memberPage,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
