import styled from 'styled-components';
import { fonts, colors } from '../../../../../../../styles/theme';
import { toRem } from '../../../../../../../utils';

export const WrapperStyled = styled.div`
  margin: ${toRem(32)};
  width: ${toRem(560)};
`;

export const UserDescTitleStyled = styled.div`
  display: flex;
  color: ${colors.color1};
  ${fonts.gotham.H26M};
  line-height: ${toRem(16)};
  margin-bottom: ${toRem(5)};
  padding-bottom: ${toRem(12)};
`;

export const UserDescInformationStyled = styled.div`
  width: 100%;
  ${fonts.lato.H16};
  line-height: ${toRem(20)};
  color: ${colors.color1};
  margin-bottom: ${toRem(12)};
`;
