import React from 'react';
import { InputText } from '../../../../../components';
import * as Styled from '../../styles';
import { DISCLAIMER } from '../../contracts';

type BasicStatsOrignalStatsProps = {
  title: string
  currentLabel: string
  newLabel: string
  currentValue: string
  newValue: string
  updateNewValue: (value: React.ChangeEvent<HTMLInputElement>) => void
  error: string
  isReadOnly: boolean
}
export const BasicStatsOrignalStats = ({
  currentLabel,
  currentValue,
  error,
  newLabel,
  newValue,
  title,
  updateNewValue,
  isReadOnly,
}: BasicStatsOrignalStatsProps) => (
  <Styled.SectionStyled theme="secondary">
    <Styled.SectionTitleStyled theme="secondary">
      {title}
    </Styled.SectionTitleStyled>
    <Styled.InputContainerStyled
      theme="secondary"
      marginBottom="sm"
    >
      <Styled.InputWrapperStyled>
        <InputText
          type="text"
          label={currentLabel}
          value={`$${currentValue}`}
          disabled
        />
      </Styled.InputWrapperStyled>
      <Styled.InputWrapperStyled>
        <InputText
          type="text"
          label={newLabel}
          value={newValue}
          onChange={updateNewValue}
          disclaimer={DISCLAIMER}
          error={error}
          disabled={isReadOnly}
        />
      </Styled.InputWrapperStyled>
    </Styled.InputContainerStyled>
  </Styled.SectionStyled>
);
