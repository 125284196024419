import styled from 'styled-components';
import { colors, fonts } from '../../styles/theme';
import { toRem } from '../../utils';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
`;

export const TitleStyled = styled.p`
  margin-bottom: 1rem;
  color: ${colors.color1};
  ${fonts.lato.H20}
`;

export const SubtitleStyled = styled.p`
  margin-bottom: 2rem;
  color: ${colors.color1};
  width: 350px;
  text-align: center;
  line-height: 1.5;
  ${fonts.lato.H16}
`;

export const ImageStyled = styled.img`
  width: ${toRem(200)};
`;
