import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Pagination, Table } from '../../../../../../components';
import * as Styled from './styles';
import { columns } from './contracts';
import { useProductCatalog } from './hooks';
import { PRODUCTS } from './enums';
import { PRODUCT_CATEGORY_SELECT_OPTIONS } from '../../../../../../utils';

export const Products = () => {
  const hook = useProductCatalog();
  return (
    <Styled.WrapperStyled>
      <Styled.FilterRowStyled>
        <Styled.SelectStyled
          label={PRODUCTS.PRODUCT_ID.LABEL}
          name={PRODUCTS.PRODUCT_ID.NAME}
          onChange={hook.hookSetSelectedProductID}
          options={hook.hookProductIDLIst}
          isLoading={hook.hookTableLoading || hook.hookGetProductOptionsLoading}
          selected={hook.hookSelectedProductID}
        />
        <Styled.SelectStyled
          label={PRODUCTS.PRODUCT_CATEGORY.LABEL}
          name={PRODUCTS.PRODUCT_CATEGORY.NAME}
          onChange={hook.hookSetSelectedProductCategory}
          options={PRODUCT_CATEGORY_SELECT_OPTIONS}
          selected={hook.hookSelectedProductCategory}
        />
        <Styled.ButtonStyled
          theme={PRODUCTS.THEME}
          width={PRODUCTS.BUTTON_WIDTH}
          loading={hook.hookCsvLoading}
          onClick={hook.hookDownloadCSV}
        >
          <FontAwesomeIcon icon={faFileDownload} />
          {PRODUCTS.DOWNLOAD}
        </Styled.ButtonStyled>
      </Styled.FilterRowStyled>

      <Table
        columns={columns}
        data={hook.hookProductList}
        setModalInfo={hook.hookHandleArrowClick}
        onRowClick={hook.hookHandleArrowClick}
        isLoading={hook.hookTableLoading}
      />
      <Pagination
        currentPage={hook.hookPage}
        total={hook.hookTotalPages}
        onPageChange={hook.hookChangePage}
      />
    </Styled.WrapperStyled>
  );
};
