import React, { createContext, useMemo, useReducer } from 'react';
import { initialState, useSignUpReducer } from '../../reducers';

type PublisherSignUpType = {
  state: typeof initialState
  dispatcherHandler: (value: Record<string, any>) => void
}

type PublisherSignUpProps = {
  children: React.ReactNode
}

export const publisherSignUpContext = createContext<PublisherSignUpType>({} as PublisherSignUpType);

export const SignUpContext = ({ children }: PublisherSignUpProps) => {
  const [signUpState, signUpDispatcher] = useReducer(useSignUpReducer, initialState);

  const singUpDispatcherHandler = (value: Record<string, any>) => {
    signUpDispatcher({ type: value.type, data: value.data });
  };

  const state = useMemo(() => ({
    state: signUpState,
    dispatcherHandler: singUpDispatcherHandler,
  }), [signUpState]);

  return (
    <publisherSignUpContext.Provider value={state}>
      {children}
    </publisherSignUpContext.Provider>
  );
};
