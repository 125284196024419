export const getDaysInMonth = (month: number, year: number) => {
  const daysInCurrentMonth = new Date(year, month + 1, 0).getDate();
  const firstDateOfCurrentMonth = new Date(year, month, 1).getDay();
  const lastDateOfCurrentMonth = new Date(year, month, daysInCurrentMonth).getDay();
  const currentMonthDbug = new Date(year, month, 1).toDateString();
  const daysInPreviousMonth = new Date(year, month, 0).getDate();
  const lastDateOfPreviousMonth = new Date(year, month - 1, daysInPreviousMonth).getDay();
  const daysInNextMonth = new Date(year, month + 1, 0).getDate();
  const firstDateOfNextMonth = new Date(year, month, 1).getDay();
  const today = new Date().getDate();

  return {
    daysInCurrentMonth,
    firstDateOfCurrentMonth,
    lastDateOfCurrentMonth,
    currentMonthDbug,
    daysInPreviousMonth,
    lastDateOfPreviousMonth,
    daysInNextMonth,
    firstDateOfNextMonth,
    today,
  };
};
