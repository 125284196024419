import { gql } from '@apollo/client';

export const CREATE_TRACKING_PROFILE = gql`
  mutation createTracking($input: TrackingInput) {
    createTracking(input: $input) {
      tracking {
        id
        profileName
        primaryWebsite
        trackingProfileType
        trackingStatus
        websiteDescription
        createdAt
      }
    }
  }
`;
