import { gql } from '@apollo/client';

export const LIST_TRACKINGS = gql`
  query trackings($companyId: String) {
    trackings(companyId: $companyId) {
      trackings {
        id
        profileName
      }
    }
  }
`;
