import React from 'react';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '../../../../../../../components';
import * as Styled from './styles';
import { SUCESS_MODAL_TEXT } from './enums';
import { NO_MONEY } from '../../../../enums';

type PaypalProps = {
  isOpen: boolean
  onClose: () => void
  amountCad: number
  amountUsd: number
}

export const SucessModal = ({
  isOpen,
  onClose,
  amountCad,
  amountUsd,
}: PaypalProps) => (
  <Modal isOpen={isOpen}>
    <Styled.WholeWrapper>
      <Styled.BigIcon icon={faCircleCheck} />
      <Styled.TitleStyled>
        {SUCESS_MODAL_TEXT.PAYMENT_SUCCESSFUL}
      </Styled.TitleStyled>
      {amountCad !== NO_MONEY && amountUsd === NO_MONEY && (
      <Styled.DescriptionStyled>
        {SUCESS_MODAL_TEXT.DESCRIPTION_CAD}
            &nbsp;
        {amountCad}
        .
        {' '}
        {SUCESS_MODAL_TEXT.NOTIFICATION_EMAIL}
      </Styled.DescriptionStyled>
      )}
      {amountCad === NO_MONEY && amountUsd !== NO_MONEY && (
      <Styled.DescriptionStyled>
        {SUCESS_MODAL_TEXT.DESCRIPTION_USD}
            &nbsp;
        {amountUsd}
        .
        {' '}
        {SUCESS_MODAL_TEXT.NOTIFICATION_EMAIL}
      </Styled.DescriptionStyled>
      )}
      {amountCad !== NO_MONEY && amountUsd !== NO_MONEY && (
      <Styled.DescriptionStyled>
        {SUCESS_MODAL_TEXT.DESCRIPTION_CAD}
            &nbsp;
        {amountCad}
        &nbsp;
        {SUCESS_MODAL_TEXT.AND_USD}
        &nbsp;
        {amountUsd}
        .
        {' '}
        {SUCESS_MODAL_TEXT.NOTIFICATION_EMAIL}
      </Styled.DescriptionStyled>
      )}
      <Styled.ButtonStyled
        theme="primary"
        onClick={onClose}
      >
        {SUCESS_MODAL_TEXT.BACK_TO_PENDING_LIST}
      </Styled.ButtonStyled>
    </Styled.WholeWrapper>
  </Modal>
);
