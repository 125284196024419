import { gql } from '@apollo/client';

export const DEPOSIT_NOTIFICATION = gql`
  mutation createDepositNotification($input: DepositNotificationInput) {
    createDepositNotification(input: $input) {
      depositNotification {
        id
      }
    }
  }
`;
