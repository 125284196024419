import React, { useEffect } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  Placeholder, InputCalendar, Pagination, Select, Table,
} from '../../../../../../components';
import {
  BUTTON_LABELS, INPUT_LABELS, ORDER_STATUS, PLACEHOLDERS, paypalColumns, paypalOriginalColumns,
} from '../../contracts';
import * as Styled from '../styles';
import { defaultOption } from '../../../../../../utils';

type PaypalOrderProps = {
  getOrders: () => void
  paypalOrders: any[]
  sortColumn?: TableSortColumn
  handleSort: (dataField: string, direction: 'asc' | 'desc' | undefined) => void
  productCategoryOptions: SelectOption[]
  selectedProjectCategory: SelectOption
  setSelectedProjectCategory: (value: SelectOption) => void
  dateInputValue: string
  setIsCalendarOpen: () => void
  paypalStatus: SelectOption
  setPaypalStatus: (value: SelectOption) => void
  clearFilterHandler: () => void
  addDepositHandler: () => void
  enableNewStats: boolean
  currentPage: number
  numberOfPages: number
  onChangePage: (value: any) => void
  isReadOnly: boolean
}

const PaypalOrder = ({
  getOrders,
  paypalOrders,
  productCategoryOptions,
  selectedProjectCategory,
  setSelectedProjectCategory,
  onChangePage,
  dateInputValue,
  setIsCalendarOpen,
  paypalStatus,
  setPaypalStatus,
  clearFilterHandler,
  addDepositHandler,
  enableNewStats,
  currentPage,
  numberOfPages,
  sortColumn,
  handleSort,
  isReadOnly,
}: PaypalOrderProps) => {
  useEffect(() => {
    getOrders();
  }, []);

  return (
    <Styled.WrapperStyled>
      <Styled.FiltersStyled>
        {enableNewStats && (
          <Select
            name="product"
            label={INPUT_LABELS.CATEGORY}
            onChange={setSelectedProjectCategory}
            selected={selectedProjectCategory}
            options={[defaultOption.productCategory, ...productCategoryOptions]}
          />
        )}
        <InputCalendar
          label={INPUT_LABELS.RANGE}
          value={dateInputValue}
          onClick={setIsCalendarOpen}
        />
        <Select
          name="product"
          label={INPUT_LABELS.STATUS}
          selected={paypalStatus}
          onChange={setPaypalStatus}
          options={ORDER_STATUS}
        />
        <Styled.ButtonStyled
          theme="text-only"
          wrapperTheme="third"
          onClick={clearFilterHandler}
        >
          {BUTTON_LABELS.CLEAR}
        </Styled.ButtonStyled>
      </Styled.FiltersStyled>

      {paypalOrders.length > 0 ? (
        <>
          <Table
            columns={enableNewStats ? paypalColumns : paypalOriginalColumns}
            data={paypalOrders}
            sortColumn={sortColumn}
            onSort={handleSort}
          />
          <Pagination
            total={numberOfPages}
            currentPage={currentPage}
            onPageChange={onChangePage}
          />
        </>
      ) : (
        <Placeholder
          title={PLACEHOLDERS.NO_RECODER}
          subtitle={PLACEHOLDERS.PAYPAL}
          buttonIcon={faPlus}
          buttonText={BUTTON_LABELS.ADD}
          action={addDepositHandler}
          isReadOnly={isReadOnly}
        />
      )}

    </Styled.WrapperStyled>
  );
};

export default PaypalOrder;
