import { gql } from '@apollo/client';

export const GET_MEMBERSHIPS = gql`
  query memberships($input: MembershipSearchInput!) {
    memberships(input: $input) {
      memberships {
        id
        merchant {
          id
          companyName
        }
        status
        approvedDate
      }
    }
  }
`;

export type GetMembershipsInput = {
  input: {
    publisherId: string
    status: 'Approved'
  }
};

export type GMMembershipType = {
  id: string
  merchant: {
    id: string
    companyName: string
  }
  status: string
  approvedDate: string
}

export type GetMembershipsOutput = {
  memberships: {
    memberships: GMMembershipType[]
  }
};
