import {
  useContext, useEffect, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { find, reject } from 'lodash';
import {
  path, paginator, csvGenerator, USER_TYPES_ID, MERCHANT_PREFIX, dateFormatter, PUBLISHER_PREFIX,
} from '../../../../utils';
import { context } from '../../ReportDetails/context/reportDetailsContext';
import { useDebounce } from '../../../../utils/useDebounce';
import { generateMonthList } from '../../../../utils/generateMonths';
import { useUserInfo } from '../../../../hooks';
import { toUTC } from '../../../../utils/formatCommissions';
import exportCSV from '../../../../utils/exportCsv';
import {
  GET_PAYMENT_REPORTS, GET_MEMBERSHIPS, GET_MERCHANT_PAYMENT_REPORTS, GET_PUBLISHERS, GET_PAYMENT_CSV, GET_MERCHANT_CURRENCY, GET_CURRENCIES_CONVERSION,
} from '../graphql/queries';
import { CSV } from '../enums';

export const usePaymentReport = () => {
  const { hookWhoAmI, hookUserInfo } = useUserInfo();
  const isMerchant = hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT || hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT;
  const isPublisher = hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER || hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER;

  const [selectedPublisher, setSelectedPublisher] = useState<SelectOption>({ label: 'All Publishers', value: '1' });
  const [selectedPayment, setSelectedPayment] = useState<SelectOption>({ label: 'All Methods', value: '1' });
  const [selectedPeriod, setSelectedPeriod] = useState<SelectOption>(generateMonthList(isMerchant)[0]);
  const navigate = useNavigate();
  const { state, setHandler } = useContext(context);
  const [initialState, setInitialState] = useState(false);
  const location = useLocation();
  const [selectedRecord, setSelectedRecords] = useState<SelectOption>({ label: '10', value: '10' }); // this is for sl

  const [errorMessage, setErrorMessage] = useState<string>();
  const [paymentReports, setPaymentReports] = useState<any[]>([]);
  const [loadingMessage, setLoadingMessage] = useState<string>('Loading');
  const [getPaymentReports, { loading: getPaymentsLoading }] = useLazyQuery(GET_PAYMENT_REPORTS);
  const [merchantPaymentReports, setMerchantPaymentReports] = useState<any[]>([]);
  const [merchantDisplayedPaymentReports, setMerchantDisplayedPaymentReports] = useState<any[]>([]);
  const [getPaymentsForMerchant, { loading: getPaymentsForMerchantLoading }] = useLazyQuery(GET_MERCHANT_PAYMENT_REPORTS);
  const [getMerchantCurrency, { loading: getMerchantCurrencyLoading }] = useLazyQuery(GET_MERCHANT_CURRENCY);
  const [currenciesConversion, { loading: currenciesConversionLoading }] = useLazyQuery(GET_CURRENCIES_CONVERSION);

  const [getPublishers, { loading: getPublishersLoading }] = useLazyQuery(GET_PUBLISHERS);
  const [getPaymentsCsv, { loading: getCsvLoading }] = useLazyQuery(GET_PAYMENT_CSV);

  const [isGenerated, setIsGenerated] = useState(false);

  const [publisherOptions, setPublisherOptions] = useState<SelectOption[]>([]);

  const [currentMonth, setCurrentMonth] = useState(1);
  const [currentYear, setCurrentYear] = useState(2024);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const [filter, setFilter] = useState('');
  const [footerValues, setFooterValues] = useState({});
  const debouncedSearch = useDebounce(filter, 800);

  const [getMemberShips, { loading: membershipsLoading }] = useLazyQuery(GET_MEMBERSHIPS);

  const merchantId = hookWhoAmI.companyId?.toString() || '0';
  const handleClearForm = () => {
    setSelectedPublisher({ label: 'All Publishers', value: '1' });
    setSelectedPayment({ label: 'All Methods', value: '1' });
    setSelectedPeriod(generateMonthList(isMerchant)[0]);
    setSelectedRecords({ label: '10', value: '10' });
    setFilter('');
  };

  const getMonth = () => {
    if (selectedPeriod.value.charAt(0) === '0') {
      return selectedPeriod.value.charAt(1);
    }
    return selectedPeriod.value.slice(0, 2);
  };

  const getYear = () => selectedPeriod.value.slice(2, 6);

  const setSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setFilter(e.target.value);
  };

  const PublisherOptionsMaker = (options: any[]) => {
    const orginalOptions = options.map((obj) => ({
      label: `${obj.publisher ? obj.publisher.id : obj.id} - ${obj.publisher ? obj.publisher.companyName : obj.companyName}`,
      value: obj.publisher ? obj.publisher.id : obj.id,
    }));
    orginalOptions.unshift({ label: 'All Publishers', value: '1' });
    return orginalOptions;
  };

  const onRowClick = (value: any) => {
    if (isMerchant) {
      navigate(MERCHANT_PREFIX + path.detailedPaymentRecords.href, {
        state: {
          publisherId: value.publisherId,
          publisher: value.publisher,
          merchantId,
          month: value.month,
          year: value.year,
          previousPath: MERCHANT_PREFIX + path.paymentReport.href,
        },
      });
    } else if (isPublisher) {
      navigate(PUBLISHER_PREFIX + path.paymentReportDetails.href, {
        state: {
          id: value.id,
          publisherID: value.publisher.id,
          publisherName: value.publisher.companyName,
          paymentStatus: value.paymentStatus,
          paymentMethod: value.paymentMethod,
          month: value.month,
          year: value.year,
          previousPath: PUBLISHER_PREFIX + path.paymentReport.href,
        },
      });
    } else {
      navigate(path.paymentReportDetails.href, {
        state: {
          id: value.id,
          publisherID: value.publisher.id,
          publisherName: value.publisher.companyName,
          paymentStatus: value.paymentStatus,
          paymentMethod: value.paymentMethod,
          month: value.month,
          year: value.year,
          previousPath: path.paymentReport.href,
        },
      });
    }
  };

  const onSortHandler = (column: string, direction: 'desc' | 'asc' | undefined) => {
    setSortColumn({
      column,
      direction: direction === 'desc' ? 'asc' : 'desc',
    });
    if (isMerchant) {
      const nextDirection: number = direction === 'asc' ? -1 : 1;
      const numbers = ['cpaCommission', 'revShareCommission', 'totalCommissions', 'bonus', 'bonusTax', 'totalTaxes', 'totalPayable'];
      const compare = (a: any, b: any) => {
        let compareCondition = false;
        if (numbers.includes(column)) {
          compareCondition = Number(b[column]) < Number(a[column]);
        } else {
          compareCondition = b[column] < a[column];
        }
        return compareCondition ? nextDirection : nextDirection * -1;
      };
      const copyArray = [...merchantPaymentReports];
      const sortedArray = copyArray.sort((a, b) => compare(a, b));
      setMerchantPaymentReports(sortedArray);
      const paymentsCurrentPage = paginator(sortedArray, Number(selectedRecord.value), 1);
      setMerchantDisplayedPaymentReports(paymentsCurrentPage);
      setCurrentPage(1);

      setHandler((prevState: any) => ({
        ...prevState,
        merchantPaymentReportsData: {
          ...prevState.merchantPaymentReportsData,
          merchantPaymentReports: sortedArray,
          payments: paymentsCurrentPage,
          currentPage: 1,
          sortColumn,
          firstLoad: false,
        },
      }));
    }
  };

  const handleAdminPublisherGenerateCsv = async () => {
    let publisherId = hookWhoAmI.companyId?.toString();
    if (publisherId === null || publisherId === undefined) {
      if (selectedPublisher.value === '1') {
        publisherId = undefined;
      } else {
        publisherId = selectedPublisher.value;
      }
    }
    const { data } = await getPaymentsCsv({
      variables: {
        input: {
          filter,
          sortOrder: sortColumn?.direction === 'asc' ? -1 : 1,
          sortBy: sortColumn?.column || 'id',
          paymentMethod: selectedPayment.label === 'All Methods' ? undefined : selectedPayment.label,
          publisherId,
          month: selectedPeriod.label === 'All Periods' ? undefined : getMonth(),
          year: selectedPeriod.label === 'All Periods' ? undefined : selectedPeriod.value.slice(2, 6),
          isPublisher,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getPaymentCsv?.csv) {
      csvGenerator(data.getPaymentCsv.csv, 'Payment Report List');
    }
  };
  const exportMerchantPaymentCsv = () => {
    let columns = [...CSV.COLUMNS];
    if (Number(currentMonth) <= 9 && Number(currentYear) <= 2023) {
      columns = reject(columns, { dataField: 'totalPayable' });
      columns = reject(columns, { dataField: 'periodBalance' });
      columns = reject(columns, { dataField: 'currentRollover' });
    }
    const unpaginated = [...merchantPaymentReports];
    const formattedArray = unpaginated.map((item) => {
      const newItem = { ...item };
      columns.forEach((column: any) => {
        let data;
        if (column.dataField === 'month') {
          data = `${CSV.MONTH_NAMES_SUB[Number(item[column.dataField]) - 1]} / ${item.year}`;
        } else if (CSV.AMOUNTS.includes(column.dataField)) {
          data = (!item[column.dataField] || Number.isNaN(Number(item[column.dataField])))
            ? '0.00'
            : Number(item[column.dataField]).toFixed(2);
        } else if (CSV.DATES.includes(column.dataField)) {
          data = dateFormatter(new Date(item[column.dataField]));
        } else {
          data = item[column.dataField];
        }
        newItem[column.dataField] = typeof data === 'string' ? data.replace(/,/g, '') : data;
      });
      return newItem;
    });
    return exportCSV(columns, formattedArray);
  };

  const handleMerchantGenerateCSV = () => {
    csvGenerator(exportMerchantPaymentCsv(), 'payment.csv');
  };

  const handleGenerateCSV = () => {
    if (!isMerchant) {
      handleAdminPublisherGenerateCsv();
    } else {
      handleMerchantGenerateCSV();
    }
  };

  const handleSetSelectedPublisher = (value: any) => {
    setSelectedPublisher(value);
  };
  const handleSetSelectedPayment = (value: any) => {
    setSelectedPayment(value);
  };
  const handleSetSelectedPeriod = (value: any) => {
    setSelectedPeriod(value);
  };
  const handleSetSelectedRecord = (value: any) => {
    setSelectedRecords(value);
    if (isMerchant) {
      setCurrentPage(1);
      const totalPagesNew = Math.ceil(merchantPaymentReports.length / Number(value.value));
      setTotalPages(totalPagesNew);
      setMerchantDisplayedPaymentReports(paginator(merchantPaymentReports, Number(value.value), 1));
      setHandler((prevState: any) => ({
        ...prevState,
        merchantPaymentReportsData: {
          ...prevState.merchantPaymentReportsData,
          payments: paginator(merchantPaymentReports, Number(value.value), 1),
          currentPage: 1,
          totalPages: totalPagesNew,
          selectedRecords: value,
          firstLoad: false,
        },
      }));
    }
  };
  const handleSetSelectPage = (value: any) => {
    setCurrentPage(value);
    if (isMerchant) {
      setMerchantDisplayedPaymentReports(paginator(merchantPaymentReports, Number(selectedRecord.value), value));
      setHandler((prevState: any) => ({
        ...prevState,
        merchantPaymentReportsData: {
          ...prevState.merchantPaymentReportsData,
          payments: paginator(merchantPaymentReports, Number(selectedRecord.value), value),
          currentPage: value,
          firstLoad: false,
        },
      }));
    }
  };

  const handleAdminGenerateReports = async () => {
    let publisherId = hookWhoAmI?.companyId?.toString();
    if (publisherId === undefined) {
      publisherId = selectedPublisher.value === '1' ? undefined : selectedPublisher.value;
    }
    setIsGenerated(true);
    setErrorMessage('');
    setLoadingMessage('Loading Payments');
    const { data, error } = await getPaymentReports({
      variables: {
        input: {
          currentPage,
          limit: Number.parseInt(selectedRecord.value, 10),
          filter,
          sortOrder: sortColumn?.direction === 'asc' ? 1 : -1,
          sortBy: sortColumn?.column || 'paymentGeneratedDate',
          paymentMethod: selectedPayment.label === 'All Methods' ? undefined : selectedPayment.label,
          publisherId,
          month: selectedPeriod.label === 'All Periods' ? undefined : getMonth(),
          year: selectedPeriod.label === 'All Periods' ? undefined : selectedPeriod.value.slice(2, 6),
        },
      },
      onError(err) {
        setLoadingMessage('');
        setErrorMessage(err.message);
        setPaymentReports([]);
      },
    });
    if (error) {
      setPaymentReports([]);
      setErrorMessage(error.message);
      return;
    }
    if (data && data.paymentsV2 && data.paymentsV2.paymentReports) {
      setPaymentReports(data.paymentsV2.paymentReports);
      setTotalPages(Math.ceil(data.paymentsV2.count / Number.parseInt(selectedRecord.value, 10)));
      setTotalRecords(data.paymentsV2.count);
      setFooterValues(data.paymentsV2.paymentReportSum);
      const prevData = state.paymentReportsData;
      if (prevData.selectPublisher !== selectedPublisher || prevData.selectPaymentMethod !== selectedPayment || prevData.selectedPaymentPeriod !== selectedPeriod || prevData.selectedRecords !== selectedRecord) {
        setCurrentPage(1);
      }

      setHandler((prevState: any) => ({
        ...prevState,
        paymentReportsData: {
          ...prevState.paymentReportsData,
          count: data.paymentsV2.count,
          payments: data.paymentsV2.paymentReports,
          footerValues: data.paymentsV2.paymentReportSum,
          selectPublisher: selectedPublisher,
          selectPaymentMethod: selectedPayment,
          selectedPaymentPeriod: selectedPeriod,
          filter,
          selectedRecords: selectedRecord,
          currentPage,
          sortColumn,
          totalPages,
          totalRecords,
          firstLoad: false,
        },
      }));
    }
  };

  const convertCurrencies = (payments: any, conversion: any, currencyFrom: string) => {
    if (!Array.isArray(payments)) {
      return [];
    }
    return payments.map((x: any) => {
      let rate = 1;
      const { month, year } = x;

      if (x.currency !== currencyFrom) {
        if (Number(month) >= 7 && Number(year) >= 2020) {
          rate = Math.floor(
            (Number(
              conversion.find((currency: any) => currency.currencyTo === x.currency)
                ?.conversionRate,
            )
                - 0.04)
                * 100,
          ) / 100;
        } else {
          rate = Number(
            conversion.find((currency: any) => currency.currencyTo === x.currency)
              ?.conversionRate,
          ) - 0.04;
        }
      }
      return {
        ...x,
        isChecked: false,
        note: x.note || '',
        publisher: `${x.publisher?.id || ''} - ${x.publisher?.companyName || ''}`,
        publisherId: x.publisher?.id || '',
        publisherName: x.publisher?.companyName || '',
        paymentGeneratedDate: (x.paymentGeneratedDate),
        paymentPaidDate: (x.paymentPaidDate) || '',
        totalCommissions: (Number(x.totalCommissions) * rate),
        totalTaxes: (Number(x.totalTaxes) * rate),
        cpaCommission: (Number(x.cpaCommission) * rate),
        revShareCommission: (Number(x.revShareCommission) * rate),
        currentRollover: Number(x.currentRollover),
        previousRollover: (Number(x.previousRollover) * rate),
        bonus: (Number(x.bonus) * rate),
        bonusTax: (Number(x.bonusTax) * rate),
        period: `${(x.month)} / ${x.year}`,
        totalPayable: (Number(x.totalPayable) * rate),
        referenceNumber: x.referenceNumber || '',
        ...x.paymentInfo,
      };
    });
  };

  const handleMerchantGenerateReports = async () => {
    setIsGenerated(true);
    setErrorMessage('');
    setLoadingMessage('Loading Payments');
    setCurrentMonth(Number(getMonth()));
    setCurrentYear(Number(getYear()));
    const { data, error } = await getPaymentsForMerchant({
      variables: {
        input: {
          paymentStatus: 'NotEstimated',
          merchantId,
          publisherId: selectedPublisher.value === '1' ? undefined : selectedPublisher.value,
          month: getMonth(),
          year: getYear(),
        },
        fetchPolicy: 'no-cache',
      },
      onError(err) {
        setLoadingMessage('');
        setErrorMessage(err.message);
        setPaymentReports([]);
      },
    });
    if (error) {
      setPaymentReports([]);
      setErrorMessage(error.message);
      return;
    }
    const { data: company } = await getMerchantCurrency({
      variables: {
        id: merchantId,
      },
    });
    const merchantCurrency = company?.company?.program?.transactionSettings?.currency || 'CAD';

    const month = getMonth();
    const year = selectedPeriod.value.slice(2, 6);
    const date = new Date(Number(year), Number(month), 0);
    const { data: currency } = await currenciesConversion({
      variables: {
        input: {
          startDate: toUTC(date, 'startDate'),
          endDate: toUTC(date),
          currencyFrom: merchantCurrency,
        },
      },
    });
    if (data?.paymentsForMerchant.payments !== undefined && data?.paymentsForMerchant.merchantPayments !== undefined) {
      const newPayments: any[] = [];
      // Iterate over the merchantPayments...
      data.paymentsForMerchant.merchantPayments.forEach((payment: any) => {
        // Find a matching rawCommission for the merchantPayment...
        const rcData = find(data.paymentsForMerchant.payments, {
          publisher: { id: payment.publisher.id },
        });
        // Merge the cpaCommission and revShareCommission from the rawCommissions (if they exist) into the merchantPayment
        newPayments.push({ ...rcData, ...payment });
      });
      const allPaymentsForMerchant = convertCurrencies(newPayments, currency.currenciesConversion.currenciesConversion, merchantCurrency);
      const paymentsCurrentPage = paginator(allPaymentsForMerchant, Number(selectedRecord.value), 1);
      setMerchantPaymentReports(allPaymentsForMerchant);
      setMerchantDisplayedPaymentReports(paymentsCurrentPage);
      setTotalPages(Math.ceil(data.paymentsForMerchant.count / Number(selectedRecord.value)));
      setTotalRecords(data.paymentsForMerchant.count);
      setCurrentPage(1);

      setHandler((prevState: any) => ({
        ...prevState,
        merchantPaymentReportsData: {
          ...prevState.merchantPaymentReportsData,
          count: data.paymentsForMerchant.count,
          merchantPaymentReports: allPaymentsForMerchant,
          payments: paymentsCurrentPage,
          selectPublisher: selectedPublisher,
          selectedPaymentPeriod: selectedPeriod,
          selectedRecords: selectedRecord,
          currentPage: 1,
          sortColumn,
          totalPages: Math.ceil(data.paymentsForMerchant.count / Number(selectedRecord.value)),
          totalRecords: data.paymentsForMerchant.count,
          firstLoad: false,
        },
      }));
    }
  };

  const handleGenerateReportsButton = async () => {
    if (isMerchant) {
      handleMerchantGenerateReports();
    } else {
      handleAdminGenerateReports();
    }
  };

  const goToPdfPage = (value: any) => {
    navigate(path.invoiceStatement.href, {
      state: {
        publisherId: value.publisherId,
        month: value.month,
        year: value.year,
        merchantId,
        previousPath: MERCHANT_PREFIX + path.paymentReport.href,
      },
    });
  };

  const handleGetPublishers = async () => {
    setLoadingMessage('Loading Publishers');
    if (isMerchant) {
      const { data, error } = await getMemberShips({
        variables: {
          input: {
            merchantId: hookWhoAmI.companyId?.toString() || '0',
            status: 'Approved',
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (error) {
        setPublisherOptions([]);
        setErrorMessage(error.message);
        return;
      }

      if (data?.memberships !== undefined) {
        const merchantPublisherOptions = PublisherOptionsMaker(data.memberships.memberships);
        setPublisherOptions(merchantPublisherOptions);
        setHandler((prevState: any) => ({
          ...prevState,
          merchantPaymentReportsData: {
            ...prevState.merchantPaymentReportsData,
            publisherOptions: merchantPublisherOptions,
          },
        }));
      }
    } else {
      const { data, error } = await getPublishers();
      if (error) {
        setPublisherOptions([]);
        setErrorMessage(error.message);
        return;
      }
      if (data?.getPublishers !== undefined) {
        setPublisherOptions(PublisherOptionsMaker(data.getPublishers));
        setHandler((prevState: any) => ({
          ...prevState,
          paymentReportsData: {
            ...prevState.paymentReportsData,
            publisherOptions: data.getPublishers,
          },
        }));
      }
    }
  };
  useEffect(() => {
    handleClearForm();
    setInitialState(false);
    setPaymentReports([]);
    setMerchantPaymentReports([]);
    setMerchantDisplayedPaymentReports([]);
    setCurrentPage(1);
    setTotalPages(0);
    setTotalRecords(0);
    handleGetPublishers();
  }, [window.location.href]);

  useEffect(() => {
    if (!isMerchant) {
      if (location.state?.context) {
        if (!initialState && !state.paymentReportsData.firstLoad) {
          const payData = state.paymentReportsData;
          setPaymentReports(payData.payments);
          setFooterValues(payData.footerValues);
          setSelectedPublisher(payData.selectPublisher);
          setSelectedPayment(payData.selectPaymentMethod);
          setSelectedPeriod(payData.selectedPaymentPeriod);
          setFilter(payData.filter);
          setSelectedRecords(payData.selectedRecords);
          setCurrentPage(payData.currentPage);
          setSortColumn(sortColumn);
          setTotalPages(payData.totalPages);
          setTotalRecords(payData.totalRecords);
          setPublisherOptions(payData.publisherOptions);
          setInitialState(true);
        }
      }

      if (initialState || (!location.state?.context && !state.paymentReportsData.firstLoad)) {
        handleGenerateReportsButton();
      }
      setIsGenerated(false);
    } else {
      if (location.state?.context) {
        if (!initialState && !state.merchantPaymentReportsData.firstLoad) {
          const payData = state.merchantPaymentReportsData;
          setMerchantDisplayedPaymentReports(payData.payments);
          setMerchantPaymentReports(payData.merchantPaymentReports);
          setSelectedPublisher(payData.selectPublisher);
          setSelectedPeriod(payData.selectedPaymentPeriod);
          setSelectedRecords(payData.selectedRecords);
          setCurrentPage(payData.currentPage);
          setSortColumn(sortColumn);
          setTotalPages(payData.totalPages);
          setTotalRecords(payData.totalRecords);
          setPublisherOptions(payData.publisherOptions);
          setInitialState(true);
        }
      }

      setIsGenerated(false);
    }
  }, [debouncedSearch, selectedRecord, currentPage, sortColumn]);

  return {
    hookIsPublisher: isPublisher,
    hookIsMerchant: isMerchant,

    hookSelectedRecords: selectedRecord,

    hookSelectedPeriod: selectedPeriod,

    hookSelectedPayment: selectedPayment,

    hookSelectedPublisher: selectedPublisher,

    hookPaymentReports: isMerchant ? merchantDisplayedPaymentReports : paymentReports,

    hookIsLoading: getPaymentsLoading || getPublishersLoading || membershipsLoading || getPaymentsForMerchantLoading || getMerchantCurrencyLoading || currenciesConversionLoading,

    hookTotalPages: totalPages,

    hookIsPublisherLoading: getPublishersLoading || membershipsLoading,

    hookCurrentPage: currentPage,

    hookLoadingMessage: loadingMessage,

    hookErrorMessage: errorMessage,

    hookTotalRecords: totalRecords,

    hookSortColumn: sortColumn,

    hookPublisherOptions: publisherOptions,

    hookPaymentsLoading: getPaymentsLoading || getPaymentsForMerchantLoading || getMerchantCurrencyLoading || currenciesConversionLoading,

    hookFilter: filter,

    hookFooterValues: footerValues,

    hookDownloadLoading: getCsvLoading,

    hookIsGenerated: isGenerated,

    month: currentMonth,

    year: currentYear,

    hookHandleDownload: handleGenerateCSV,

    hookHandleClear: handleClearForm,

    hookOnSearch: setSearchHandler,

    hookOnSort: onSortHandler,

    hookChangePage: handleSetSelectPage,

    hookOnRowClick: onRowClick,

    hookHandleSetSelectedPublisher: handleSetSelectedPublisher,

    hookHandleSetSelectedPayment: handleSetSelectedPayment,

    hookHandleSetSelectedPeriod: handleSetSelectedPeriod,

    hookHandleGenerateReportsButton: handleGenerateReportsButton,

    hookHandleSetSelectedRecord: handleSetSelectedRecord,

    hookGoToPdfPage: goToPdfPage,

  };
};
