import React from 'react';
import { Modal } from '../../../../../../../components';
import * as Styled from './styles';
import { URLS_LIST } from '../enums';

type UrlModalProps = {
  hook: any
}

const UrlModal = ({ hook }: UrlModalProps) => (
  <Modal isOpen={hook.hookIsOpen}>
    <Styled.Wrapper>
      <Styled.TitleStyled>
        {hook.hookModalType === URLS_LIST.MODAL.TYPES.ADD && URLS_LIST.MODAL.TITLE.ADD}
        {hook.hookModalType === URLS_LIST.MODAL.TYPES.EDIT && URLS_LIST.MODAL.TITLE.EDIT}
        {(hook.hookModalType === URLS_LIST.MODAL.TYPES.REMOVE || hook.hookModalType === URLS_LIST.MODAL.TYPES.REMOVE_MANY) && URLS_LIST.MODAL.TITLE.REMOVE}
      </Styled.TitleStyled>
      {hook.hookModalType !== URLS_LIST.MODAL.TYPES.REMOVE && hook.hookModalType !== URLS_LIST.MODAL.TYPES.REMOVE_MANY ? (
        <Styled.InputStyled
          value={hook.hookUrl}
          onChange={hook.hookSetUrl}
          type="url"
          label="url"
          required
          error={hook.hookUrlError}
          placeholder="https://www.fintelconnect.com"
        />
      ) : (
        <Styled.RemovePromptStyled>
          You are about to remove the following URL(s) from
          {' '}
          <Styled.BoldText>Manual URLs List</Styled.BoldText>
          {' '}
          <Styled.UrlWrapper>
            {
          hook.hookModalType === URLS_LIST.MODAL.TYPES.REMOVE
            ? <Styled.BoldTextDiv>{hook.hookUrl}</Styled.BoldTextDiv>
            : hook.hookSelectedUrls.map((item: any) => (<Styled.BoldTextDiv>{item}</Styled.BoldTextDiv>))
            }
          </Styled.UrlWrapper>
          Are you sure you want to continue?
        </Styled.RemovePromptStyled>
      )}
      <Styled.ButtonsWrapperStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => {
            hook.hookSetIsOpen();
            hook.hookResetModal();
          }}
        >
          {URLS_LIST.MODAL.BUTTON.CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          loading={hook.hookValidateLoading}
          disabled={hook.hookValidateLoading}
          onClick={hook.hookHandleModalRightButton}
        >
          {(hook.hookModalType === URLS_LIST.MODAL.TYPES.ADD) && URLS_LIST.MODAL.BUTTON.ADD}
          {(hook.hookModalType === URLS_LIST.MODAL.TYPES.EDIT) && URLS_LIST.MODAL.BUTTON.EDIT}
          {(hook.hookModalType === URLS_LIST.MODAL.TYPES.REMOVE) && URLS_LIST.MODAL.BUTTON.REMOVE}
          {(hook.hookModalType === URLS_LIST.MODAL.TYPES.REMOVE_MANY) && URLS_LIST.MODAL.BUTTON.REMOVE}
        </Styled.ButtonStyled>
      </Styled.ButtonsWrapperStyled>
    </Styled.Wrapper>
  </Modal>
);

export default UrlModal;
