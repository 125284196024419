import React from 'react';
import { Spinner } from '../Spinner';

import * as Styled from './styles';

type ButtonProps = {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
  theme?: Theme;
  width?: string;
  className?: string;
};

export const Button = ({
  children,
  onClick,
  type = 'button',
  disabled = false,
  loading = false,
  theme = 'primary',
  width,
  className,
}: ButtonProps) => (
  <Styled.WrapperStyled
    onClick={onClick}
    type={type}
    disabled={disabled}
    theme={theme}
    width={width}
    className={className}
  >
    {!loading && children}
    {loading && <Spinner theme={theme} />}
  </Styled.WrapperStyled>
);
