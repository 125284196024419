import { gql } from '@apollo/client';

export const GET_SECURITY_ROLES = gql`
  query GetSecurityRoles($type: RoleType) {
    newRolesByType(type: $type) {
      id
      name
      oldId
    }
  }
`;

export type GetSecurityRolesInput = {
  type: 'Publisher' | 'Merchant' | 'Admin'
}

export type GSRRoleType = {
  id: string
  name: string
  oldId: number | null
}
export type GetSecurityRolesOutput = {
  newRolesByType: GSRRoleType[]
}
