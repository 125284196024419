import { monthNames } from './enums';

export const dateFormatter = (date: Date, separator = '/', noDay = false, dayFirst = false, noSuffix = false): string => {
  if (!(date instanceof Date)) return '';
  if (date < new Date('2000-01-01T00:00:00.000Z')) return '';
  if (Number.isNaN(date?.getTime())) return '';

  const getNumberSuffix = (num: number): string => {
    if (num > 3 && num < 21) return 'th';
    switch (num % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const day: number = date.getUTCDate();
  const suffix: string = getNumberSuffix(day);
  const year: number = date.getUTCFullYear();
  const monthName: string = monthNames[date.getUTCMonth()];

  if (noDay) return `${monthName} ${separator} ${year}`;
  if (dayFirst) return ` ${day}${suffix} ${monthName}${separator} ${year}`;
  if (noSuffix) return `${monthName} ${day}${separator} ${year}`;
  return `${monthName} ${day}${suffix} ${separator} ${year}`;
};

export const timeFormatter = (date: Date): string => {
  if (date < new Date('2000-01-01T00:00:00.000Z')) return '';
  const hour24 = date.getUTCHours();
  const minute = date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : date.getUTCMinutes();
  const second = date.getUTCSeconds() < 10 ? `0${date.getUTCSeconds()}` : date.getUTCSeconds();
  const end = hour24 >= 12 ? 'PM' : 'AM';
  const hour = (hour24 % 12) || 12;
  return `${hour}:${minute}:${second} ${end}`;
};

export const toUTCHours = (date: Date, period: 'beginning' | 'end') => {
  if (period === 'beginning') {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
  }

  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));
};

export const getPreviousMonth = (date: Date, type: 'start' | 'end') => {
  let pastOneMonthDate = new Date(date);
  const currentDate = new Date(date);
  const passedDays = type === 'start' ? 30 : 1;
  const pastTime = currentDate.getDate() - passedDays;
  pastOneMonthDate = new Date(pastOneMonthDate.setDate(pastTime));
  return pastOneMonthDate;
};

export const standardDateFormat = (dateString: Date) => {
  if (dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const monthIndex = date.getUTCMonth();
    const dayIndex = date.getUTCDate();
    const month = `0${monthIndex + 1}`.slice(-2);
    const day = `0${dayIndex}`.slice(-2);
    if (month && day && year) {
      return `${year}-${month}-${day}`;
    }
  }
  return '';
};
