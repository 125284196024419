import styled from 'styled-components';
import { Button, InputText } from '../../../components';

export const InputTextStyled = styled(InputText)`
  margin-right: 2rem;
  width: 90%;
`;

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

export const FiltersStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

export const ButtonStyled = styled(Button)`
  white-space: nowrap;
`;

export const RecordsStyled = styled.div`
  margin: 2rem 0;
`;
