import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useUserInfo, useToast } from '../../../../../hooks';
import { URL_STATUSES } from '../../../../../utils';
import { useValidation } from '../../../../../utils/validation';
import { UPDATE_TRACKING_SETTING } from '../graphql/mutations';
import { GET_COMPANY } from '../graphql/queries';

export const useTrackingSettings = () => {
  const { hookShowToast } = useToast();
  const userHook = useUserInfo();
  const vali = useValidation();

  const [trackingSettingId, setTrackingSettingId] = useState('');
  const [landingPageURL, setLandingPageURL] = useState('');
  const [queryURL, setQueryURL] = useState('');
  const [thirdPartyURLFormat, setThirdPartyURLFormat] = useState('');
  const [thirdPartyStatusTracker, setThirdPartyStatusTracker] = useState(false); // Checkbox value for third party tracking
  const [thirdPartyTrackingURL, setThirdPartyTrackingURL] = useState(''); // Third party tracking URL text
  const [landingUrlError, setLandingUrlError] = useState('');
  const [thirdPartyUrlError, setThirdPartyUrlError] = useState('');
  const [landingUrlStatus, setLandingUrlStatus] = useState('');
  const [thirdPartyUrlStatus, setThirdPartyUrlStatus] = useState('');
  const [cookieDuration, setCookieDuration] = useState('');
  const [noFollow, setNoFollow] = useState(null);
  const [statsRelay, setStatsRelay] = useState(null);

  const [editTrackingSetting] = useMutation(UPDATE_TRACKING_SETTING);

  const { data } = useQuery(GET_COMPANY, {
    variables: {
      id: userHook.hookWhoAmI.companyId,
    },
    fetchPolicy: 'no-cache',
  });
  const getCompanyHandler = () => {
    if (data?.company.program.trackingSettings) {
      setLandingPageURL(data.company.program.trackingSettings.landingPage);
      setQueryURL(data.company.program.trackingSettings.urlQueryString);
      setThirdPartyTrackingURL(data.company.program.trackingSettings.thirdPartyUrl);
      setThirdPartyStatusTracker(data.company.program.trackingSettings.thirdPartyStatus);
      setThirdPartyURLFormat(data.company.program.trackingSettings.thirdPartyUrlFormat);
      setCookieDuration(data.company.program.trackingSettings.cookieDuration);
      setNoFollow(data.company.program.trackingSettings.nofollow);
      setStatsRelay(data.company.program.trackingSettings.statsRelay);
      if (data.company.program.trackingSettings.id) setTrackingSettingId(data.company.program.trackingSettings.id);
    }
  };

  /**
   * Validate landing page URL
   * If the validation fails, set the error message
   */
  const validateLandingPage = () => {
    /* Validation for the Landing Page */
    vali.validateUrlStatus(landingPageURL, setLandingUrlStatus);
    vali.renderUrlCheck(landingUrlStatus, setLandingUrlError);
  };

  /**
   * Validate landing AND third party page URL
   * If the validation fails, set the error message
   */
  const validateAllURLs = () => {
    validateLandingPage();
    /* Validation for Third Party URL Tracker */
    vali.validateUrlStatus(thirdPartyTrackingURL, setThirdPartyUrlStatus);
    vali.renderUrlCheck(thirdPartyUrlStatus, setThirdPartyUrlError);
  };

  const editTrackingSettingHandler = async () => {
    if (landingPageURL === '' || landingUrlStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS || landingUrlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS || landingUrlStatus === URL_STATUSES.INACTIVE_WEBSITE.STATUS || (thirdPartyTrackingURL === '' || thirdPartyUrlStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS || thirdPartyUrlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS || thirdPartyUrlStatus === URL_STATUSES.INACTIVE_WEBSITE.STATUS)) return;

    const { errors } = await editTrackingSetting({
      variables: {
        input: {
          programsId: userHook.hookWhoAmI.programId,
          id: trackingSettingId,
          landingPage: landingPageURL,
          urlQueryString: queryURL,
          thirdPartyUrl: thirdPartyTrackingURL,
          thirdPartyUrlFormat: thirdPartyURLFormat,
          thirdPartyStatus: thirdPartyStatusTracker,
        },
      },
    });

    if (errors) {
      hookShowToast(errors[0].message);
    } else {
      hookShowToast('Tracking settings updated successfully');
    }
  };

  /**
   * Set the third party tracking status and validate the website URL
   */
  const setCheckThirdPartyStatusHandler = (checked: boolean) => {
    setThirdPartyStatusTracker(checked);
    if (checked) validateAllURLs();
  };

  /**
   * Reset the third party tracking URL error message when checkbox is unchecked and the URL is empty
   */
  useEffect(() => {
    if (!thirdPartyStatusTracker && thirdPartyTrackingURL.length <= 0) {
      setThirdPartyUrlError('');
    }
  }, [thirdPartyStatusTracker]);

  const setQueryURLHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryURL(e?.target.value);
  };

  const setLandingPageURLHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLandingPageURL(e?.target.value);
  };

  const setThirdPartyURLHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setThirdPartyURLFormat(e?.target.value);
  };

  const setThirdPartyTrackingURLHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setThirdPartyTrackingURL(e?.target.value);
  };

  useEffect(() => {
    getCompanyHandler();
  }, [data]);

  useEffect(() => {
    if (landingPageURL === '') {
      setLandingUrlError('');
    }
    vali.validateUrlStatus(landingPageURL, setLandingUrlStatus); // TODO should we use like this? It checks the status of the url every time the url changes
  }, [landingPageURL]);

  useEffect(() => {
    if (thirdPartyTrackingURL === '') {
      setThirdPartyUrlError('');
    }
    vali.validateUrlStatus(thirdPartyTrackingURL, setThirdPartyUrlStatus); // TODO should we use like this? It checks the status of the url every time the url changes
  }, [thirdPartyTrackingURL]);

  return {
    hookLandingPageURL: landingPageURL,
    hookSetLandingpageURL: setLandingPageURLHandler,

    hookQueryURL: queryURL,
    hookSetQueryURL: setQueryURLHandler,

    hookThirdPartyURLFormat: thirdPartyURLFormat,
    hookSetThirdPartyURLFormat: setThirdPartyURLHandler,

    hookThirdPartyStatus: thirdPartyStatusTracker,
    hookCheckThirdPartyStatus: setCheckThirdPartyStatusHandler,

    hookThirdPartyTrackingURL: thirdPartyTrackingURL,
    hookSetThirdPartyTrackingURL: setThirdPartyTrackingURLHandler,

    hookCookieDuration: cookieDuration,
    hookNoFollow: noFollow,
    hookStatsRelay: statsRelay,

    validateLandingPage,
    validateAllURLs,
    hookLandingUrlError: landingUrlError,
    hookThirdPartyUrlError: thirdPartyUrlError,

    hookTrackingSettingsId: trackingSettingId,

    hookEditTrackingSetting: editTrackingSettingHandler,

    hookLandingPageValidation: landingUrlStatus,
    hookTrackingUrl: thirdPartyUrlStatus,
  };
};
