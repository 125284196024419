import React from 'react';
import * as Styled from '../../styles';
import { CellProps } from '../../types';
import { imageList } from '../../../../../../../utils';

export const ImgCell = ({ val }: CellProps) => (
  <Styled.NameCellStyled>
    <img
      src={val !== null ? val : imageList.noPic.src}
      alt="logo"
      style={{
        maxWidth: '40px',
        maxHeight: '40px',
        width: 'auto',
        height: 'auto',
      }}
    />
  </Styled.NameCellStyled>
);
