import React from 'react';
import * as Styled from './styles';
import { OVERVIEW } from './enum';

export const OverviewTab = ({ hook }: {hook:any}) => (

  <Styled.WrapperStyled>
    <Styled.DescriptionStyled>
      {hook.hookDescription}
    </Styled.DescriptionStyled>
    <Styled.SubTitleStyled>{OVERVIEW.DETAILED_DESCRIPTION}</Styled.SubTitleStyled>
    <Styled.DetailDescriptionStyled>
      {hook.hookDetailedDescription}
    </Styled.DetailDescriptionStyled>

    {hook.hookSpecialOffer && (
    <>
      <Styled.Large>
        {OVERVIEW.NEW}
      </Styled.Large>
      <Styled.OfferWrapper>
        <Styled.Svg />
        <Styled.Caption>
          {hook.hookSpecialOffer}
        </Styled.Caption>
      </Styled.OfferWrapper>
    </>
    )}

    <Styled.FlexStyled>
      <div>
        <Styled.SubTitleStyled>{OVERVIEW.PRODUCT_CATEGORIES}</Styled.SubTitleStyled>
        <Styled.DescriptionStyled>{hook.hookProductCategories}</Styled.DescriptionStyled>
      </div>
      {hook.hookCookie !== '' && (
      <div>
        <Styled.SubTitleStyled>{OVERVIEW.COOKIE}</Styled.SubTitleStyled>
        <Styled.DescriptionStyled>{`${hook.hookCookie} days`}</Styled.DescriptionStyled>
      </div>
      )}
    </Styled.FlexStyled>
    <Styled.FlexStyled>
      <div>
        <Styled.SubTitleStyled>{OVERVIEW.TARGET_MARKET}</Styled.SubTitleStyled>
        <Styled.DescriptionStyled>{hook.hookTargetMarkets}</Styled.DescriptionStyled>
      </div>
      {hook.hookStats !== '' && (
      <div>
        <Styled.SubTitleStyled>{OVERVIEW.STATS}</Styled.SubTitleStyled>
        <Styled.DescriptionStyled>{`${hook.hookStats} days`}</Styled.DescriptionStyled>
      </div>
      )}
    </Styled.FlexStyled>

    <Styled.SubTitleStyled>{OVERVIEW.PRODUCT_MATRIX}</Styled.SubTitleStyled>
    <Styled.LinkStyled
      href={hook.hookProductMatrix}
      target="_blank"
    >
      {hook.hookProductMatrix}
    </Styled.LinkStyled>
  </Styled.WrapperStyled>
);
