import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
  ADDRESS, HEADER_TAX, PLACEHOLDER_PAYEE, PLACEHOLDER_RECEIVER, TAX_TYPES,
} from '../contracts';
import { PDF_TYPES, monthNames } from '../../../../utils';
import { INVOICE_STATEMENT } from '../graphql/queries';

export const useInvoiceStatement = () => {
  const location = useLocation();

  const [getInvoice] = useLazyQuery(INVOICE_STATEMENT);

  const [statementDate, setStatementDate] = useState('');
  const [payeeName, setPayeeName] = useState('');
  const [payee, setPayee] = useState<PDFPayeeInfo>(PLACEHOLDER_PAYEE);
  const [receiver, setReceiver] = useState<PDFReceiverInfo>(PLACEHOLDER_RECEIVER);
  const [PDFTableData, setPDFTableData] = useState<any>();
  const [hasProductCategory, setHasProductCategory] = useState(false);
  const [isMerchantPdf, setIsMerchantPdf] = useState(false);
  const [fixedRate, setRate] = useState<string>();

  const [isLoading, setIsLoading] = useState(true);
  const [loadedCorrectly, setLoadedCorrectly] = useState<boolean>(false);
  const [errors, setErrors] = useState<{[key: string]: string}>({});

  const fomateTaxSelect = (data: {[key: string]: string}) => {
    const taxValues: SelectOption[] = [];
    Object.keys(data).forEach((key) => {
      if (TAX_TYPES.includes(key) && data[key] !== '') {
        taxValues.push({
          label: key.toUpperCase(),
          value: data[key],
        });
      }
    });
    return taxValues;
  };

  const formatTableData = (data: any, rate: any) => {
    Object.keys(data).forEach((key: any) => {
      // eslint-disable-next-line no-param-reassign
      data[key] = data[key].map((tx: any) => ({
        ...tx,
        amount: (Number(tx.amount) * rate).toString(),
      }));
    });
    return data;
  };

  const getInvoiceStatement = async () => {
    const isMerchant = PDF_TYPES.MERCHANT === location.state?.pdfType;
    const merchantId = location.state?.merchantId;
    const publisherId = location.state?.publisherId;
    const paymentId = location.state?.paymentId;
    const productCategory = location.state?.productCategory;
    const month = location.state?.month;
    const year = location.state?.year;
    const accountBalancesId = location.state?.accountBalancesId;
    const rate = location.state?.rate;
    setRate(rate);

    setIsMerchantPdf(isMerchant);
    try {
      const { data: { invoiceStatement }, error } = await getInvoice({
        variables: {
          input: {
            publisherId,
            productCategory,
            month,
            year,
            merchantId: `${merchantId}`,
            paymentId,
          },
        },
        onError(err) {
          setErrors({ message: err.message });
        },
        fetchPolicy: 'no-cache',
      });

      if (error) throw error;

      if (invoiceStatement) {
        setStatementDate(publisherId
          ? `${monthNames[parseInt(invoiceStatement.invoiceDetails.month, 10)]} ${invoiceStatement.invoiceDetails.year}`
          : `${monthNames[parseInt(invoiceStatement.invoiceDetails.month, 10) - 1]} / ${invoiceStatement.invoiceDetails.year}`);
        setPayeeName(invoiceStatement.merchant.companyName);
        setPayee({
          ...invoiceStatement.invoiceDetails,
          customId: paymentId || accountBalancesId,
          invoiceTo: invoiceStatement.merchant.companyName,
          address: invoiceStatement.merchant,
        });
        if (rate) {
          setPDFTableData(formatTableData(invoiceStatement.tableData, rate));
        } else {
          setPDFTableData(invoiceStatement.tableData);
        }
        setHasProductCategory(!!productCategory);
        setReceiver({
          address: isMerchant ? ADDRESS : { ...invoiceStatement.publisher.paymentInfo, companyName: invoiceStatement.publisher.companyName },
          tax: isMerchant ? HEADER_TAX : fomateTaxSelect(invoiceStatement.publisher.paymentInfo),
        });
      }
      setLoadedCorrectly(true);
    } catch (error: any) {
      setErrors({ message: error.message });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getInvoiceStatement();
  }, []);

  return {
    hookStatementDate: statementDate,
    hookPayeeName: payeeName,
    hookPayee: payee,
    hookReceiver: receiver,
    hookPDFTableData: PDFTableData,
    hookHasProductCategory: hasProductCategory,
    hookisMerchantPdf: isMerchantPdf,
    hookRate: fixedRate,
    hookIsLoading: isLoading,
    hookLoadedCorrectly: loadedCorrectly,
    hookErrors: errors,
  };
};
