import React from 'react';
import { FintelCheckSelect, Select } from '../../../../../../components';
import { SUMMARY_REPORT_DROPDOWN_ACTIONS } from '../Reducers';
import { useFintelCheckContext } from '../../../hooks';
import { dateFormatter } from '../../../../../../utils';
import type { useSummaryReport } from './useSummaryReport';

type UseRenderDropdownProps = {
  isMainReport: boolean
  hook: ReturnType<typeof useSummaryReport>
}

export const ChecksDropdown = ({ isMainReport, hook }: UseRenderDropdownProps) => {
  const { contextState } = useFintelCheckContext();
  if (!isMainReport) {
    return (
      <Select
        label="Report Date"
        isDisabled
        selected={{ label: dateFormatter(new Date(contextState.date)), value: contextState.date }}
        onChange={(e: SelectOption) => hook.hookFiltersDispatch(e, SUMMARY_REPORT_DROPDOWN_ACTIONS.DEFINE_REPORT_DATE)}
      />
    );
  }
  return (
    <FintelCheckSelect
      label="Last Check"
      onChange={(e: SelectOption) => hook.hookFiltersDispatch(e, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_LAST_CHECK)}
      options={hook.hookLastCheckOptions}
      isLoading={hook.hookFilterOptionsLoading}
      isDisabled={hook.hookFilterOptionsLoading || hook.hookGenerateLoading}
      selected={hook.hookFiltersState.lastCheck}
    />
  );
};
