import React from 'react';
import {
  ActionCell, CategoriesCell, ImgCell, NameCell, StatusCell,
} from './Components';
import * as Styled from './styles';

export const columns: TableColumn[] = [
  {
    dataField: 'img',
    text: '',
    width: '5%',
    formatter: (val) => (
      <ImgCell val={val} />
    ),
  },
  {
    dataField: 'id',
    text: 'ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '7%',
    formatter: (val) => (
      <Styled.CellWrapper>
        {val}
      </Styled.CellWrapper>
    ),
  },
  {
    dataField: 'companyName',
    text: 'Company Name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '20%',
    formatter: (val) => <NameCell val={val} />,
  },
  {
    dataField: 'productCategories',
    text: 'Product Categories',
    width: '28%',
    formatter: (val) => <CategoriesCell val={val} />,
  },
  {
    dataField: 'targetMarket',
    text: 'Target Market',
    width: '25%',
    formatter: (val) => <CategoriesCell val={val} />,
  },
  {
    dataField: 'membershipStatus',
    text: 'Status',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '10%',
    formatter: (val, row) => (
      <StatusCell
        val={val}
        row={row}
      />
    ),
  },
  {
    dataField: 'programId',
    text: '',
    width: '5%',
    formatter: (val) => <ActionCell val={val} />,
  },
];
