import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ModalInformation, TransactionItem, GetTransactionsOutputType } from '../types.ts';
import { GET_TRANSACTIONS_LIST } from '../graphql/queries';
import { GET_MERCHANTS_LIST } from '../../graphql/queries';
import { UPDATE_TRANSACTIONS } from '../graphql/mutations/updateTransactions';
import { useUserInfo } from '../../../../hooks';
import { Permission } from '../../../../entities';

const moneyFormater = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const placeholderModalInfo:ModalInformation[] = [{
  currency: 'cad', transactions: 0, grossSalesString: '$0.00', netSalesString: '$0.00', netSales: 0, grossSales: 0,
}];

export const usePendingTransactions = (permissionsCodeList: string[] = []) => {
  const { hookWhoAmI, hookUserInfo } = useUserInfo();

  const getInitialMerchantInfo = () => {
    if (hookUserInfo.userTypesId === 1) {
      if (hookWhoAmI?.companyId) {
        return { label: 'not visible', value: hookWhoAmI.companyId?.toString() };
      }
      return { label: 'All Merchants', value: '-1' };
    }
    return { label: 'not visible', value: hookWhoAmI.companyId?.toString() || '0' };
  };

  const [transactionsData, setTransactionsData] = useState<TransactionItem[]>([]);
  const [merchantsList, setMerchantsList] = useState<SelectOption[]>([]);
  const [merchantSelected, setMerchantSelected] = useState<SelectOption>(getInitialMerchantInfo());
  const [recordsSelected, setRecordsSelected] = useState<SelectOption>({ label: '10', value: '10' });

  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState<boolean>(false);
  const [checkedInformationModal, setCheckedInformationModal] = useState<ModalInformation[]>(placeholderModalInfo);
  const [declineReasonValue, setDeclineReasonValue] = useState('');

  const [headerCheck, setHeaderCheck] = useState<boolean>(false);
  const [transactionsSelected, setTransactionsSelected] = useState<string[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'id', direction: 'desc' });

  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [modalErrorMessage, setModalErrorMessage] = useState<string>('');

  // Detect if admin is moving between acting as company and as acting as admin.
  const [companyId, setCompanyId] = useState(hookWhoAmI.companyId?.toString());
  if (companyId !== hookWhoAmI.companyId?.toString()) {
    setCompanyId(hookWhoAmI.companyId?.toString());
    setMerchantSelected(getInitialMerchantInfo());
  }

  const { data: merchantsData, loading: merchantsLoading } = useQuery(GET_MERCHANTS_LIST, {
    variables: {
      input: {
        companyType: 'Merchant',
        accountStatus: 'Approved',
      },
    },
    fetchPolicy: 'no-cache',
    onError(err) {
      setErrorMessage(err.message);
    },
  });

  const [getTransactions, { loading: transactionsLoading, client: transactionClient }] = useLazyQuery(GET_TRANSACTIONS_LIST);

  const [updateTransactions, { loading: loadingUpdate }] = useMutation(UPDATE_TRANSACTIONS);

  const updateModalInformation = () => {
    const modalInformation: ModalInformation[] = [];

    transactionsData.forEach((transaction) => {
      if (transaction.checked === true) {
        const index = modalInformation.findIndex((currencyGroup) => currencyGroup.currency === transaction.currency);
        if (index === -1) {
          modalInformation[modalInformation.length] = {
            currency: transaction.currency,
            transactions: 1,
            grossSales: Number.parseFloat(transaction.grossSale || '') || 0,
            netSales: Number.parseFloat(transaction.netSale || '') || 0,
            grossSalesString: transaction.grossSale,
            netSalesString: transaction.netSale,
          };
        } else {
          modalInformation[index].transactions += 1;
          modalInformation[index].grossSales += Number.parseFloat(transaction.grossSale || '') || 0;
          modalInformation[index].netSales += Number.parseFloat(transaction.netSale || '') || 0;
        }
      }
    });

    if (modalInformation.length === 0) {
      // This should never be visible.
      setCheckedInformationModal(placeholderModalInfo);
    } else {
      const newModalInfo = modalInformation.map((currencyGroup) => (
        { ...currencyGroup, grossSalesString: moneyFormater.format(currencyGroup.grossSales), netSalesString: moneyFormater.format(currencyGroup.netSales).toString() }
      ));
      setCheckedInformationModal(newModalInfo);
    }
  };

  const handleDeclineReasonInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDeclineReasonValue(event.target.value);
  };

  const handleHeaderCheck = (state: boolean) => {
    const newData = transactionsData.map((obj) => ({ ...obj, checked: state }));
    setTransactionsData(newData);
    if (state && transactionsData) {
      const allIds = transactionsData.map((obj): string => obj.id || '');
      setTransactionsSelected(allIds);
    } else {
      setTransactionsSelected([]);
    }
    setHeaderCheck(state);
  };

  const handleChangeCheck = (id: string, add: boolean) => {
    if (add) {
      setTransactionsSelected([...transactionsSelected, id]);
    } else {
      setTransactionsSelected(transactionsSelected.filter((transaction) => transaction !== id));
    }

    const newData = [...transactionsData];
    const targetIndex = newData.findIndex((obj) => obj.id === id);
    if (targetIndex === -1) return;
    newData[targetIndex].checked = add;
    setTransactionsData(newData);

    // Check if all are checked to control header check box
    let allChecked = true;
    newData.forEach((transaction) => {
      if (transaction.checked === false) {
        allChecked = false;
      }
    });
    setHeaderCheck(allChecked);
  };

  const handleSetModalIsOpen = (type: 'Approve' | 'Decline') => {
    setModalErrorMessage('');
    if (type === 'Approve') {
      setIsApproveModalOpen(!isApproveModalOpen);
    } else {
      setIsDeclineModalOpen(!isDeclineModalOpen);
    }
  };

  const handleSetRecordsSelected = (value: any) => {
    setCurrentPage(1);
    setRecordsSelected(value);
  };

  const handleMerchantSelected = (value: any) => {
    setCurrentPage(1);
    setMerchantSelected(value);
  };

  const createTransactionListHandler = async () => {
    setErrorMessage('');
    setLoadingMessage('Loading Transactions');
    const input: any = {
      transactionStatus: 'Pending',
      isTrackerLinked: true,
      currentPage,
      limit: Number.parseInt(recordsSelected.value, 10),
      sortBy: sortColumn.column,
      sortDirection: sortColumn.direction,
    };
    if (merchantSelected.value !== '-1') {
      input.merchantId = merchantSelected.value;
    }
    const { data: newTransactionsData, error } = await getTransactions({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setLoadingMessage('');
        setErrorMessage(err.message);
        setTransactionsData([]);
      },
    });
    setLoadingMessage('');
    if (error) {
      setTransactionsData([]);
      setErrorMessage(error.message);
      return;
    }
    const userData: GetTransactionsOutputType = newTransactionsData.transactions;
    // Adds a field to control checkbox status on the table
    const transactions = userData.transactions.map((transaction) => ({ ...transaction, checked: false }));
    setTransactionsData(transactions);
    setTotalPages(userData.totalPages);
    setTransactionsSelected([]);
    setHeaderCheck(false);
  };

  const handleUpdateTransactions = async (newStatus: 'Approved' | 'Declined') => {
    setErrorMessage('');
    setModalErrorMessage('');
    setLoadingMessage('Updating Transaction Status');

    const input: any = {
      ids: transactionsSelected,
      transactionStatus: newStatus,
      saleDate: new Date(),
      firstTransaction: true,
    };
    if (newStatus === 'Declined') {
      input.note = declineReasonValue;
    }
    const { errors } = await updateTransactions({
      variables: {
        input,
      },
      onError(err) {
        setModalErrorMessage(err.message);
      },
    });
    setLoadingMessage('');
    if (errors && errors[0]) {
      setModalErrorMessage(errors[0]?.message);
    }
    if (errors) return false;
    // Clear Cache after update as it is no longer valid.
    await transactionClient.resetStore();
    createTransactionListHandler();
    if (newStatus === 'Declined') handleSetModalIsOpen('Decline');
    else handleSetModalIsOpen('Approve');
  };

  const createMerchantsListHandler = () => {
    let merchantsListData: SelectOption[] = [{ label: 'All Merchants', value: '-1' }];
    merchantsListData = [...merchantsListData, ...merchantsData.companies.companies.map((merchant: any): SelectOption => ({ label: `${merchant.id} - ${merchant.companyName}`, value: merchant.id }))];
    setMerchantsList(merchantsListData);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    setTransactionsSelected([]);
  };

  const setSortByHandler = (dataField: string, _: 'asc' | 'desc' | undefined) => {
    if (sortColumn.direction === 'desc' && sortColumn.column === dataField) {
      setSortColumn({ column: dataField, direction: 'asc' });
    } else {
      setSortColumn({ column: dataField, direction: 'desc' });
    }
  };

  useEffect(() => {
    if (merchantsData) createMerchantsListHandler();
  }, [merchantsData]);

  useEffect(() => {
    createTransactionListHandler();
  }, [merchantSelected, recordsSelected, sortColumn, currentPage]);

  useEffect((() => {
    updateModalInformation();
  }), [transactionsData]);

  return {
    hookData: transactionsData,

    hookCheckedInformation: checkedInformationModal,
    hookIsApproveModalOpen: isApproveModalOpen,
    hookIsDeclineModalOpen: isDeclineModalOpen,
    hookHandleSetModalIsOpen: handleSetModalIsOpen,

    hookInputValue: declineReasonValue,
    hookHandleDeclineReasonInput: handleDeclineReasonInput,

    hookHandleUpdateTransactions: handleUpdateTransactions,

    hookHeaderCheck: headerCheck,
    hookHeaderCheckOnChange: handleHeaderCheck,
    hookHandleChangeCheck: handleChangeCheck,

    hookTransactionsSelected: transactionsSelected,
    hookAreButtonDisabled: transactionsSelected.length < 1,

    hookRecordsSelected: recordsSelected,
    hookHandleRecordsSelected: handleSetRecordsSelected,

    hookMerchantsList: merchantsList,
    hookMerchantSelected: merchantSelected,
    hookHandleMerchantSelected: handleMerchantSelected,

    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookOnPageChange: onPageChange,
    hookSortByHandler: setSortByHandler,
    hookSortColumn: sortColumn,

    hookIsLoading: merchantsLoading || transactionsLoading || loadingUpdate,
    hookLoadingMessage: loadingMessage,
    hookErrorMessage: errorMessage,
    hookModalErrorMessage: modalErrorMessage,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
