import React from 'react';

import {
  AppWrapper, Button, Modal, PageTitle, Wysiwyg,
} from '../../../../components';
import { path } from '../../../../utils';
import DeleteTemplateModal from './Modal';
import { INVITATION_TEMPLATE } from './enum';
import { useInvitationTemplate } from './hooks/useInvitationTemplate';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type InvitationTemplateProps = DefaultPropsType;

const InvitationTemplate = ({ permissionsCodeList = [] }: InvitationTemplateProps) => {
  const { hookIsReadOnlyList, ...hook } = useInvitationTemplate(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle>
          {hook.hookQuery.edit ? hook.hookTemplateName : `${INVITATION_TEMPLATE.PAGE_TITLE.NEW} ${path.invitationTemplate.name}`}
        </PageTitle>

        {hook.hookQuery.edit && (
          <Styled.ButtonAreaStyled>
            <Button
              theme="secondary"
              onClick={hook.hookSetModal}
              disabled={isReadOnly}
            >
              {INVITATION_TEMPLATE.BUTTON.DELETE}
            </Button>
            <Styled.ButtonStyled
              theme="secondary"
              onClick={hook.hookCopyTemplate}
              disabled={isReadOnly}
            >
              {INVITATION_TEMPLATE.BUTTON.DUPLICATE}
            </Styled.ButtonStyled>
          </Styled.ButtonAreaStyled>
        )}
      </Styled.HeaderStyled>

      <Styled.InputTextStyled
        label={INVITATION_TEMPLATE.INPUT.TEMPLATE_NAME.LABEL}
        placeholder={INVITATION_TEMPLATE.INPUT.TEMPLATE_NAME.PLACEHOLDER}
        required
        type="text"
        value={hook.hookTemplateName}
        onChange={hook.hookNameHandler}
        onBlur={hook.hookHandleValidation}
        error={hook.hookCreateUserErrors.templateName}
        disabled={isReadOnly}
      />
      <Styled.InputTextStyled
        label={INVITATION_TEMPLATE.INPUT.DESCRIPTION.LABEL}
        placeholder={INVITATION_TEMPLATE.INPUT.DESCRIPTION.PLACEHOLDER}
        type="text"
        value={hook.hookDescription}
        onChange={hook.hookSetDescription}
        disabled={isReadOnly}
      />
      <Styled.InputTextStyled
        label={INVITATION_TEMPLATE.INPUT.SUBJECT.LABEL}
        required
        type="text"
        placeholder={INVITATION_TEMPLATE.INPUT.SUBJECT.PLACEHOLDER}
        value={hook.hookSubject}
        onChange={hook.hookSetSubject}
        error={hook.hookCreateUserErrors.subject}
        onBlur={hook.hookHandleValidation}
        disabled={isReadOnly}
      />

      <Styled.SpecialFieldStyled>
        <Styled.SelectStyled
          placeholder={INVITATION_TEMPLATE.INPUT.MERGE_FIELDS.PLACEHOLDER}
          name="merge"
          options={INVITATION_TEMPLATE.INPUT.MERGE_FIELDS.OPTIONS}
          selected={hook.hookSelectedMergeField}
          onChange={hook.hookMergeFieldsHandler}
          isDisabled={isReadOnly}
        />
        <Styled.TooltipStyled text={INVITATION_TEMPLATE.INPUT.MERGE_FIELDS.TOOLTIP} />
        {hook.hookSelectedMergeField.value && (<Styled.CopiedStyled>{INVITATION_TEMPLATE.INPUT.MERGE_FIELDS.MERGE_FIELD_COPIED}</Styled.CopiedStyled>)}
      </Styled.SpecialFieldStyled>

      <Wysiwyg
        label={INVITATION_TEMPLATE.INPUT.MESSAGE.LABEL}
        required
        value={hook.hookMessage}
        onChange={hook.hookSetMessage}
        placeholder={INVITATION_TEMPLATE.INPUT.MESSAGE.PLACEHOLDER}
        readonly={isReadOnly}
        error={hook.hookCreateUserErrors.message}
      />

      <Styled.ButtonAreaStyled>
        <Button
          theme="secondary"
          onClick={hook.hookBackNavigation}
        >
          {INVITATION_TEMPLATE.BUTTON.CANCEL}
        </Button>
        <Styled.ButtonStyled
          onClick={hook.hookSaveHandler}
          disabled={isReadOnly}
        >
          {INVITATION_TEMPLATE.BUTTON.SAVE}
        </Styled.ButtonStyled>
      </Styled.ButtonAreaStyled>

      <Modal isOpen={hook.hookModal}>
        <DeleteTemplateModal
          id={hook.hookQuery.id}
          name={hook.hookQuery.edit}
          deleteTemplate={hook.hookDeleteTemplate}
          closeFn={hook.hookSetModal}
        />
      </Modal>
    </AppWrapper>
  );
};

export default InvitationTemplate;
