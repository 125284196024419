export type ReportProfileProps = {
  id: string
  name: string
  description: string | null
  report: string
  user: {
    id: string
    email: string
  }
  frequency: string | null
  every: SelectOption | null
  company: number
  filters: {
    field: string
    value: SelectOption
  }[]
  columns: SelectOption[]
}

export const useEditReportReducer = (state: any, { data }: ComponentReducerType): ReportProfileProps => ({
  ...state,
  ...data,
});
