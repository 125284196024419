import styled from 'styled-components';
import { colors, fonts } from '../../styles/theme';

type ButtonProps = {
  readonly isActive: boolean;
};

export const WrapperStyled = styled.div``;

export const TopAreaStyled = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.color15};
`;

export const ButtonStyled = styled.button<ButtonProps>`
  background: transparent;
  border: none;
  border-bottom: 3px solid ${({ isActive }) => (isActive ? colors.color2 : 'transparent')};
  cursor: pointer;
  padding: 1rem;
  color: ${({ isActive }) => (isActive ? colors.color1 : colors.color7)};
  ${fonts.gotham.H16M}
  letter-spacing: 0.06rem;
`;

export const ContentStyled = styled.div``;
