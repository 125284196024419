import styled from 'styled-components';
import { fonts } from '../../../../../styles/theme';

type CellProps = {
  compressed?: boolean
}

export const CellStyled = styled.div<CellProps>`
  ${({ compressed }) => (compressed ? fonts.lato.H14 : fonts.lato.H16)}
  display: flex;
  width: 100%;
  white-space: pre-wrap;
  line-height: ${({ compressed }) => (compressed ? 1.2 : 1.5)};
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;
