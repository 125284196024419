import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import * as Styled from '../styles';
import { CellProps } from '../types';
import { Button } from '../../../../../../../../../components';
import { MERCHANT_PREFIX, path } from '../../../../../../../../../utils';
import { useUserInfo } from '../../../../../../../../../hooks';

export const IconCell = ({ val }: CellProps) => {
  const navigate = useNavigate();
  const { hookWhoAmI } = useUserInfo();

  const redirect = () => {
    navigate(`${MERCHANT_PREFIX}${path.editCampaign.href}`, {
      state: {
        campaignId: val,
        programId: hookWhoAmI.programId,
        companyId: hookWhoAmI.companyId,
      },
    });
  };

  return (
    <Styled.CellWrapper>
      <Button
        onClick={redirect}
        theme="text-only"
      >
        <Styled.IconStyled icon={faChevronRight} />
      </Button>
    </Styled.CellWrapper>
  );
};
