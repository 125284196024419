import { useEffect, useState } from 'react';
import { TRANSACTION_SETTINGS } from '../Tabs/TransactionSettings/enums';
import { useValidation } from '../../../../../utils/validation';
import { URL_STATUSES } from '../../../../../utils';

type AddTrackingSettingsProps = {
  setModalOpen: () => void
  tableData: any[]
  setTableData: (data: any[]) => void
}

export const useAddTrackingSettings = ({ tableData, setTableData, setModalOpen }: AddTrackingSettingsProps) => {
  const validator = useValidation();
  const emptyState = { label: '', value: '' };
  const [type, setType] = useState<SelectOption>(emptyState);
  const [typeError, setTypeError] = useState(false);
  const [tracking, setTracking] = useState<SelectOption>(emptyState);
  const [trackingError, setTrackingError] = useState(false);
  const [url, setUrl] = useState('');
  const [urlStatus, setUrlStatus] = useState('');
  const [urlError, setUrlError] = useState('');
  const [newTrackingSettingSettings, setNewTrackingSetting] = useState({ type, tracking });
  const [error, setError] = useState(false);

  const setTypeHandler = (selected: SelectOption) => {
    setType(selected);
    setNewTrackingSetting({ type: selected, tracking });
  };

  const setTrackingHandler = (selected: SelectOption) => {
    setTracking(selected);
    setNewTrackingSetting({ type, tracking: selected });
  };

  const setUrlHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const validateForm = () => {
    validator.validateUrlStatus(url, setUrlStatus);
    validator.renderUrlCheck(urlStatus, setUrlError);
  };

  const saveNewSettings = () => {
    let exisits;
    if (type.label !== emptyState.label && tracking.label !== emptyState.label) {
      tableData.map((item) => {
        if ((item.type === type.value && item.url === tracking.value) || (item.type === type.value && item.url === url)) {
          setError(true);
          exisits = true;
        }
      });

      if (tracking.value === TRANSACTION_SETTINGS.URLS[1].value && (urlStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS || urlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS || urlStatus === URL_STATUSES.PENDING_WEBSITE.STATUS)) return;

      if (!exisits) {
        const newtableData = [
          ...tableData,
          {
            id: tableData.length,
            enabled: true,
            type: type.value,
            url: tracking.value !== TRANSACTION_SETTINGS.URLS[1].value ? tracking.value : url,
            autoApprove: false,
          },
        ];

        setTableData(newtableData);
        setModalOpen();
        setError(false);
      }
    }
    if (type.label === emptyState.label) {
      setTypeError(!typeError);
    }
    if (tracking.label === emptyState.label) {
      setTrackingError(!trackingError);
    }
  };

  const closeModalHandler = () => {
    setModalOpen();
    setError(false);
  };

  useEffect(() => {
    setError(false);
    setTypeError(false);
    setTrackingError(false);
  }, [type, tracking]);

  useEffect(() => {
    if (url === '') {
      setUrlError('');
    }
    validator.validateUrlStatus(url, setUrlStatus);
  }, [url]);

  return {
    hookType: type,
    hookSetType: setTypeHandler,
    hookTracking: tracking,
    hookSetTracking: setTrackingHandler,
    hookUrl: url,
    hookSetUrl: setUrlHandler,
    hookUrlError: urlError,
    hookSaveNewTracking: saveNewSettings,
    hookError: error,
    hookTypeError: typeError,
    hookTrackingError: trackingError,
    hookNewSetting: newTrackingSettingSettings,
    hookCloseModal: closeModalHandler,

    hookValidateForm: validateForm,
  };
};
