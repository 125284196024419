import { gql } from '@apollo/client';

export const CREATE_FINTEL_MONITORING_CHECK_RULE = gql`
  mutation createMonitoringCheckRule($input: MonitoringCheckRuleInput!) {
    createMonitoringCheckRule(input: $input) {
      merchantId
      customTextList
      ruleName
      ruleGroup
    }
  }
`;
