import { gql } from '@apollo/client';

export const GET_FILTER_LIST = gql`
  query getPublisherSearchFilters($input: PublisherForInvitationInput!) {
    publishersForInvitationV2(input: $input) {
      filterValues {
        audienceMarkets
        gender
        audienceAges
        creditScoreRating
      }
    }
  }
`;

export type GetFilterListInput = {
  input: {
    merchantId: string
    isMember: true
    showTestAccounts: true
    options: {
      page: number
      items: number
      order: 'ASC'
    }
  }
}

export type GetFilterListOutput = {
  publishersForInvitationV2: {
    filterValues: {
      audienceMarkets: string[]
      gender: string[]
      audienceAges: string[]
      creditScoreRating: string[]
    }
  }
}
