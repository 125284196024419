import styled from 'styled-components';
import { Button, PageTitle } from '../../../../../components';
import { fonts, colors } from '../../../../../styles/theme';
import { toRem } from '../../../../../utils';

export const WrapperStyled = styled.div`
  width: 640px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem;
`;

export const PageTitleStyled = styled(PageTitle)`
  width: 100%;
`;

export const SectionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  width: 100%;
`;

export const SectionLabelStyled = styled.div`
  ${fonts.gotham.H14M}
  color: ${colors.color16};
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
`;

export const SectionContentStyled = styled.div`
  ${fonts.lato.H16}
  color: ${colors.color1};
  line-height: 1.5;
`;

export const FooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
`;

export const FooterButtonStyled = styled(Button)`
  margin-left: 1rem;
`;
