import React from 'react';
import { FormatedCell } from '../components';

export const columns: TableColumn[] = [
  {
    dataField: 'status',
    text: 'Status',
    formatter: (value: any) => (
      <FormatedCell
        value={value}
      />
    ),
    width: '15%',
  },
  {
    dataField: 'message',
    text: 'Message',
    formatter: (value: any) => (
      <FormatedCell
        value={value}
      />
    ),
    width: '85%',
  },
];
