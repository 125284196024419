import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import * as Styled from './styles';
import { INVITATION_HISTORY } from '../enum';

type InvitationSentModalProps = {
  data: any
  closeFn: () => void;
}

const InvitationSentModal = ({ data, closeFn }: InvitationSentModalProps) => (
  <Styled.WrapperStyled>
    <Styled.PageTitleStyled>{`${INVITATION_HISTORY.MODAL.HEADER} ${data?.publisherName}`}</Styled.PageTitleStyled>

    <Styled.SectionsStyled>
      <Styled.SectionLabelStyled>
        {INVITATION_HISTORY.MODAL.SUBJECT}
      </Styled.SectionLabelStyled>

      <Styled.SectionContentStyled>
        {data?.subject}
      </Styled.SectionContentStyled>
    </Styled.SectionsStyled>

    <Styled.SectionsStyled>
      <Styled.SectionLabelStyled>
        {INVITATION_HISTORY.MODAL.MESSAGE}
      </Styled.SectionLabelStyled>

      <Styled.SectionContentStyled>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {data?.message}
        </ReactMarkdown>
      </Styled.SectionContentStyled>
    </Styled.SectionsStyled>

    <Styled.FooterStyled>
      <Styled.FooterButtonStyled
        theme="secondary"
        onClick={closeFn}
      >
        {INVITATION_HISTORY.BUTTON.CANCEL}
      </Styled.FooterButtonStyled>
    </Styled.FooterStyled>
  </Styled.WrapperStyled>
);

export default InvitationSentModal;
