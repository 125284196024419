import React, { useState } from 'react';
import { Button, Checkbox, PageTitle } from '../../../../../components';
import * as Styled from './styles';
import { SEARCH_PUBLISHER_COLUMNS } from '../../../../../utils';

type CustomizeColumnsProps = {
  closeFn: () => void;
  submitFn: (column: string[]) => void;
  columns: string[];
};

const CustomizeColumnsModal = ({ closeFn, submitFn, columns }: CustomizeColumnsProps) => {
  const [selectedColumns, setSelectedColumns] = useState(columns);

  const checkFn = (columnsParam: string) => {
    const findColumn = selectedColumns.find((column) => column === columnsParam);

    if (findColumn) {
      setSelectedColumns(selectedColumns.filter((column) => column !== columnsParam));
    } else {
      setSelectedColumns([...selectedColumns, columnsParam]);
    }
  };

  return (
    <Styled.WrapperStyled>
      <Styled.HeaderStyled>
        <PageTitle>Customize Columns</PageTitle>
      </Styled.HeaderStyled>

      <Styled.ContentStyled>
        {SEARCH_PUBLISHER_COLUMNS.map((row, index) => {
          const isTitle = row === row.toUpperCase();
          if (isTitle) {
            return (
              <Styled.ColumnTitleStyled key={row}>
                {row}
              </Styled.ColumnTitleStyled>
            );
          }
          return (
            <React.Fragment key={row}>
              <Styled.RowStyled>
                <Checkbox
                  checked={selectedColumns.includes(row)}
                  onChange={() => checkFn(row)}
                  label={row}
                />
              </Styled.RowStyled>
              {index === SEARCH_PUBLISHER_COLUMNS.length - 1 && (
                <Styled.ButtonsFooterStyled>
                  <Button
                    theme="secondary"
                    onClick={closeFn}
                  >
                    Cancel
                  </Button>
                  <Button onClick={() => { submitFn(selectedColumns); closeFn(); }}>Customize</Button>
                </Styled.ButtonsFooterStyled>
              )}
            </React.Fragment>
          );
        })}
      </Styled.ContentStyled>
    </Styled.WrapperStyled>
  );
};

export default CustomizeColumnsModal;
