import { useState, useEffect } from 'react';

export const useModal = (innitial?: boolean): [S: boolean, Dispatch: () => void] => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(innitial || false);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpenModal(false);
    }
  };

  const setChangeStateHandler = () => {
    setIsOpenModal(!isOpenModal);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return [isOpenModal, setChangeStateHandler];
};
