import { path, PUBLISHER_PREFIX, securityMap } from '../../../utils';

export const publisher = [
  {
    key: securityMap.publisher.publisherDashboard,
    flag: true,
    icon: path.dashboard.icon,
    href: PUBLISHER_PREFIX + path.dashboard.href,
    label: path.dashboard.name,
  },
  {
    key: securityMap.publisher.publisherAccount,
    flag: true,
    label: path.account.name,
    icon: path.account.icon,
    subSections: [
      {
        key: [securityMap.publisher.publisherAccountAccountDetails, securityMap.publisher.publisherAccountUserManagement],
        flag: true,
        href: PUBLISHER_PREFIX + path.accountDetails.href,
        label: path.accountDetails.name,
      },
      {
        key: securityMap.publisher.publisherAccountTrackingDetails,
        flag: true,
        href: PUBLISHER_PREFIX + path.trackingDetails.href,
        label: path.trackingDetails.name,
      },
      {
        key: securityMap.publisher.publisherPostbackDetails,
        flag: true,
        href: PUBLISHER_PREFIX + path.postbackDetails.href,
        label: path.postbackDetails.name,
      },
    ],
  },
  {
    key: securityMap.publisher.publisherOverview,
    flag: true,
    href: PUBLISHER_PREFIX + path.overview.href,
    label: path.overview.name,
    icon: path.overview.icon,
  },
  {
    key: securityMap.publisher.publisherMerchants,
    flag: true,
    label: path.merchants.name,
    icon: path.merchants.icon,
    subSections: [
      {
        key: securityMap.publisher.publisherMerchantsMerchantManagement,
        flag: true,
        href: PUBLISHER_PREFIX + path.memberships.href,
        label: path.memberships.name,
      },
      {
        key: securityMap.publisher.publisherMerchantsSearchPrograms,
        flag: true,
        href: PUBLISHER_PREFIX + path.searchProgram.href,
        label: path.searchProgram.name,
      },
    ],
  },
  {
    key: securityMap.publisher.publisherCommissions,
    flag: true,
    href: PUBLISHER_PREFIX + path.activeComissions.href,
    label: path.activeComissions.name,
    icon: path.activeComissions.icon,
  },
  {
    key: securityMap.publisher.publisherAds,
    flag: true,
    label: path.ads.name,
    icon: path.ads.icon,
    subSections: [
      {
        key: securityMap.publisher.publisherAdsAdSearch,
        flag: true,
        href: PUBLISHER_PREFIX + path.adSearch.href,
        label: path.adSearch.name,
      },
      {
        key: securityMap.publisher.publisherAdsProductFeed,
        flag: true,
        href: PUBLISHER_PREFIX + path.productFeed.href,
        label: path.productFeed.name,
      },
    ],
  },
  {
    key: securityMap.publisher.publisherReports,
    flag: true,
    label: path.reports.name,
    icon: path.reports.icon,
    subSections: [
      {
        key: securityMap.publisher.publisherReportsPerformanceReport,
        flag: true,
        href: PUBLISHER_PREFIX + path.performanceReport.href,
        label: path.performanceReport.name,
      },
      {
        key: securityMap.publisher.publisherReportsTransactionMaster,
        flag: true,
        href: PUBLISHER_PREFIX + path.transactionMaster.href,
        label: path.transactionMaster.name,
      },
      {
        key: securityMap.publisher.publisherReportsPaymentReport,
        flag: true,
        href: PUBLISHER_PREFIX + path.paymentReport.href,
        label: path.paymentReport.name,
      },
      {
        key: securityMap.publisher.publisherReportsManageSavedReport,
        flag: true,
        href: PUBLISHER_PREFIX + path.manageSavedReports.href,
        label: path.manageSavedReports.name,
      },
    ],
  },
  {
    key: securityMap.publisher.publisherFintelMonitor,
    flag: true,
    label: path.fintelMonitor.name,
    icon: path.fintelMonitor.icon,
    subSections: [
      {
        key: securityMap.publisher.publisherFintelMonitorLinkManager,
        flag: true,
        href: PUBLISHER_PREFIX + path.linkManager.href,
        label: path.linkManager.name,
      },
    ],
  },
  {
    key: securityMap.publisher.publisherMessageHub,
    flag: true,
    label: path.messageHub.name,
    icon: path.messageHub.icon,
    href: path.messageHub.href,
    link: path.messageHub.link,
  },
];
