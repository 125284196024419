import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import {
  ChangeMonitoringDetailsInput, ChangeMonitoringDetailsOutput, GET_MONITORING_DETAILS_DATA, MonitoringDetailsType,
} from '../graphql/queries';

export const useMonitoringDetails = () => {
  const [changedData, setChangedData] = useState<MonitoringDetailsType>();
  const [errorMessage, setErrorMessage] = useState('');
  const [getMonitoringDetailsData, { loading: getMonitoringDetailsDataLoading }] = useLazyQuery<ChangeMonitoringDetailsOutput, ChangeMonitoringDetailsInput>(GET_MONITORING_DETAILS_DATA);

  const fetchPageData = async (id: string) => {
    const { data, error } = await getMonitoringDetailsData({
      variables: {
        input: {
          id,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (error) {
      setErrorMessage(error.message);
      return;
    }
    if (data && data.changeMonitoringDetailsData) {
      setChangedData(data.changeMonitoringDetailsData);
    }
  };

  return {
    errorMessage,

    fetchPageData,
    changedData,
    isLoading: getMonitoringDetailsDataLoading,
  };
};
