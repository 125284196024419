import React from 'react';
import {
  CommissionCell, DateCell, DefaultCell, IconCell,
} from './components';

export const columns: TableColumn[] = [
  {
    dataField: 'id',
    text: 'Id',
    width: '80px',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'commissionMembers',
    text: 'Commission/Members',
    width: '25%',
    formatter: (val) => <CommissionCell val={val} />,
  },
  {
    dataField: 'transaction',
    text: 'Transaction',
    width: 'calc(25% - 140px)',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'products',
    text: 'Products',
    width: '15%',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'payable',
    text: 'Commission Payable',
    width: '15%',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'rangeDate',
    text: 'Start/End Date',
    width: '20%',
    formatter: (val) => <DateCell val={val} />,
  },
  {
    dataField: 'detail',
    text: '',
    width: '60px',
    formatter: (val) => <IconCell val={val} />,
  },
];
