export const INTERNAL_PUBLISHER = {
  TABS: {
    ACCOUNT_INFO: 'Account Information',
    TRACKING_PROFILES: 'Tracking Profiles',
    TRACKING_DOMAINS: 'Tracking Domains',
  },
  DESCRIPTION: 'This is your internal publisher account with the ability to add a tracking profile.',
  ADD_PROFILE: 'Add Profile',
  NO_PROFILES: 'No tracking profiles found',
  NO_DOMAINS: 'No tracking domains found',
  NO_DOMAINS_DESCRIPTION: 'Your tracking domain will show up here once a tracking event happens.',
};
