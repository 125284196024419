import { gql } from '@apollo/client';

// adtrackers / tracking profile/url/id, tags, file asset...???
export const SHOW_AD = gql`
  query ad($input: ShowAdInput) {
    showAd(input: $input) {
      programId
      id
      adContent
      adName
      adStatus
      adType
      altText
      campaignId
      campaignName
      createdAt
      defaultLandingPage
      description
      endDate
      height
      isJavascript
      language
      merchantName
      productId
      productName
      publisherGroups
      startDate
      tagIds
      updatedAt
      width
    }
  }
`;
