import { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import animatedTheme from '@amcharts/amcharts5/themes/Animated';
import { useModal } from '../../../../../../../../hooks';
import { useGraphs } from '../../hooks';
import { colors } from '../../../../../../../../styles/theme';
import { SUMMARY_REPORT_DROPDOWN_ACTIONS } from '../../../../../Components/SummaryReport/Reducers';

export const useRuleChart = (hook: any) => {
  const { hookLoadingOverview, hookRuleData, hookRuleDetailData } = useGraphs();
  const [isModalOpen, setIsModalOpen] = useModal();
  const [ruleCheckGraphData, setRuleCheckGraphData] = useState<any[]>([]);
  const [ruleCheckDetailGraphData, setRuleCheckDetailGraphData] = useState<any[]>([]);

  useEffect(() => {
    if (ruleCheckGraphData.length > 0 && ruleCheckDetailGraphData !== undefined) {
      am5.ready(() => {
        am5.array.each(am5.registry.rootElements, (root) => {
          if (root?.dom.id === 'ruleCheck') {
            root.dispose();
          }
        });
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        const root = am5.Root.new('ruleCheck');

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([animatedTheme.new(root)]);

        const mainContainer = root.container.children.push(
          am5.Container.new(root, {
            width: am5.p100,
            height: am5.p100,
            layout: root.horizontalLayout,
          }),
        );

        const tooltip = am5.Tooltip.new(root, {
          autoTextColor: false,
        });
        tooltip.label.setAll({
          fill: am5.color(0xffffff),
          fontSize: 12,
        });

        // Create main chart container
        const mainChartContainer = mainContainer.children.push(
          am5.Container.new(root, {
            width: am5.percent(70), // Initial width for the main chart container
            height: am5.p100, // Height can be 100% or as needed
          }),
        );

        // Create main chart
        const chart = mainChartContainer.children.push(
          am5percent.PieChart.new(root, {
            radius: am5.percent(80),
            tooltip,
            marginLeft: 150,
          }),
        );

        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        const series = chart.series.push(
          am5percent.PieSeries.new(root, {
            valueField: 'value',
            categoryField: 'category',
            innerRadius: am5.percent(50),
            id: 'statusChart',
            alignLabels: true,
          }),
        );

        series.labels.template.setAll({
          fontSize: 11,
          textAlign: 'left',
          textType: 'aligned',
          width: am5.percent(50),
          maxWidth: 150,
          oversizedBehavior: 'wrap',
          text: "{category}\n{valuePercentTotal.formatNumber('0.00')}%",
        });

        series.slices.template.set('toggleKey', 'none');
        series.slices.template.setAll({
          tooltipText: "{category}: {valuePercentTotal.formatNumber('0.00')}%[/] ({value})",
        });

        const subTooltip = am5.Tooltip.new(root, {
          autoTextColor: false,
        });
        subTooltip.label.setAll({
          fill: am5.color(0xffffff),
          fontSize: 12,
        });

        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        // Create sub chart container (responsive)
        const subChartContainer = mainContainer.children.push(
          am5.Container.new(root, {
            width: am5.percent(30),
            height: am5.percent(60), // Height can be 100% or as needed
            centerY: am5.percent(-40),
          }),
        );

        // Create sub chart
        const subChart = subChartContainer.children.push(
          am5percent.PieChart.new(root, {
            radius: am5.percent(80),
            tooltip: subTooltip,
            id: 'publisherChart',
            marginRight: -130,
          }),
        );
        // Create sub series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        const subSeries = subChart.series.push(
          am5percent.PieSeries.new(root, {
            valueField: 'value',
            categoryField: 'category',
            innerRadius: am5.percent(45),
          }),
        );

        subSeries.data.processor = am5.DataProcessor.new(root, {
          numericFields: ['value'],
        });

        let selectedSlice: any;

        // lines
        const line0 = mainContainer.children.push(
          am5.Line.new(root, {
            position: 'absolute',
            stroke: root.interfaceColors.get('text'),
            strokeDasharray: [2, 2],
          }),
        );
        const line1 = mainContainer.children.push(
          am5.Line.new(root, {
            position: 'absolute',
            stroke: root.interfaceColors.get('text'),
            strokeDasharray: [2, 2],
          }),
        );

        function updateLines() {
          if (selectedSlice) {
            const startAngle = selectedSlice.get('startAngle');
            const arc = selectedSlice.get('arc');
            const radius = selectedSlice.get('radius');

            const x00 = radius * am5.math.cos(startAngle);
            const y00 = radius * am5.math.sin(startAngle);

            const x10 = radius * am5.math.cos(startAngle + arc);
            const y10 = radius * am5.math.sin(startAngle + arc);

            // if no values for subchart don't draw lines
            if (!subSeries.slices.getIndex(0)) {
              line0.set('points', []);
              line1.set('points', []);
              return;
            }
            const subRadius = subSeries.slices.getIndex(0)?.get('radius') || 0;
            const x01 = 0;
            const y01 = -subRadius;

            const x11 = 0;
            const y11 = subRadius;

            const point00 = series.toGlobal({ x: x00, y: y00 });
            const point10 = series.toGlobal({ x: x10, y: y10 });

            const point01 = subSeries.toGlobal({ x: x01, y: y01 });
            const point11 = subSeries.toGlobal({ x: x11, y: y11 as number });

            line0.set('points', [point00, point01]);
            line1.set('points', [point10, point11]);
          }
        }

        // graph colors
        series.get('colors')?.set('colors', [
          am5.color(colors.color37),
          am5.color(colors.color38),
          am5.color(colors.color14),
          am5.color(colors.color12),
          am5.color(colors.color39),
        ]);

        const total = series.children.push(
          am5.Label.new(root, {
            fontSize: 30,
            textAlign: 'center',
            centerY: am5.p50,
            centerX: am5.p50,
            text: '{valueSum}',
            populateText: true,
          }),
        );
        total.set('text', '{valueSum}');
        series.slices.template.set('toggleKey', 'none');
        subSeries.slices.template.set('toggleKey', 'none');
        subSeries.labels.template.setAll({
          visible: false,
          text: "{name}: {valuePercentTotal.formatNumber('0.00')}%[/] ({value})",
        });
        subSeries.ticks.template.set('visible', false);

        subSeries.slices.template.setAll({
          strokeWidth: 1,
          stroke: am5.color(0xffffff),
          tooltipText: "{name}: {valuePercentTotal.formatNumber('0.00')}%[/] ({value})",
        });

        // Adjust Subcharts Slice Colors depending on Status and percentSize of slice
        subSeries.slices.template.adapters.add('fill', (value, target) => {
          if (!target.dataItem || !target.dataItem.dataContext) {
            return value;
          }
          const color = am5.color((target.dataItem.dataContext as any).color);
          const percentSize = target.dataItem.get('valuePercentTotal' as any) || 0;
          const brightenAmount = ((percentSize * -1) / 100 + 0.15) / 2;
          // Negative Numbers Make color darker
          const newColor = am5.Color.brighten(color, brightenAmount);
          return newColor;
        });

        series.on('startAngle', () => {
          updateLines();
        });

        mainContainer.events.on('boundschanged', () => {
          root.events.on('frameended', () => {
            updateLines();
          });
        });

        function selectSlice(slice: any) {
          selectedSlice = slice;
          const { dataItem } = slice;
          const { dataContext } = dataItem;

          if (dataContext) {
            let i = 0;
            subSeries.data.each(() => {
              const dataObj = dataContext.subData[i];
              if (dataObj) {
                if (!subSeries.dataItems[i].get('visible')) {
                  subSeries.dataItems[i].show();
                }
                subSeries.data.setIndex(i, dataObj);
              } else {
                subSeries.dataItems[i].hide();
              }

              // eslint-disable-next-line no-plusplus
              i++;
            });
          }

          const middleAngle = slice.get('startAngle') + slice.get('arc') / 2;
          const firstAngle = series.dataItems[0].get('slice').get('startAngle');

          series.animate({
            key: 'startAngle',
            to: firstAngle! - middleAngle,
            duration: 1600,
            easing: am5.ease.out(am5.ease.cubic),
          });
          series.animate({
            key: 'endAngle',
            to: firstAngle! - middleAngle + 360,
            duration: 1600,
            easing: am5.ease.out(am5.ease.cubic),
          });
        }

        // add events
        series.slices.template.events.on('click', (e) => {
          selectSlice(e.target);
        });

        mainContainer.appear(1000, 10);

        const updateDetailSeriesData = (status: string) => {
          switch (status) {
            case 'Fail':
              subSeries.data.setAll(hookRuleDetailData.fail);
              break;
            case 'Pass':
              subSeries.data.setAll(hookRuleDetailData.pass);
              break;
            case 'Review':
              subSeries.data.setAll(hookRuleDetailData.review);
              break;
            case 'Incomplete':
              subSeries.data.setAll(hookRuleDetailData.incomplete);
              break;
            case 'Not Applicable':
              subSeries.data.setAll(hookRuleDetailData.nf);
              break;
            default:
              subSeries.data.setAll(hookRuleDetailData.fail);
              break;
          }
        };

        series.slices.template.events.on('click', (e: any) => {
          const status = e.target.dataItem.dataContext.category.split(':')[0].replace('Check ', '');
          updateDetailSeriesData(status);
          hook.hookFiltersDispatch({
            label: status,
            value: status,
          }, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_STATUS);
          hook.hookFiltersDispatch({
            label: 'All Publishers',
            value: '',
          }, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PUBLISHER);
          hook.hookHandleGenerate(status);
        });
        subSeries.slices.template.events.on('click', (e: any) => {
          const publisher = e.target.dataItem.dataContext.name;
          const publisherId = publisher.split('-')[0].replace(' ', '');
          hook.hookFiltersDispatch({
            label: publisher,
            value: publisherId,
          }, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PUBLISHER);
          hook.hookHandleGenerate(undefined, undefined, publisherId);
        });

        series.data.setAll(ruleCheckGraphData);
        subSeries.data.setAll(hookRuleDetailData.fail);

        series.events.on('datavalidated', () => {
          selectSlice(series.slices.getIndex(2));
        });
      });
    }
  }, [ruleCheckGraphData]);

  useEffect(() => {
    setRuleCheckGraphData(hookRuleData);
  }, [hookRuleData]);

  useEffect(() => {
    setRuleCheckDetailGraphData(hookRuleDetailData);
  }, [hookRuleDetailData]);

  return {
    hookGraphLoading: hookLoadingOverview,
    hookIsModalOpen: isModalOpen,
    hookSetIsModalOpen: setIsModalOpen,
  };
};
