import React from 'react';
import { faTriangleExclamation, faChevronRight, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styled from '../styles';
import { TABLE_FOOTER, formateDollarAmount, monthNames } from '../../../../utils';

export const tableColumns: TableColumn[] = [
  {
    dataField: 'year',
    text: 'Period',
    width: '150px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any, row: any) => (
      <Styled.TableDataStyled>
        {row.minBalanceRequired > row.balance && (
          <FontAwesomeIcon
            icon={faTriangleExclamation}
          />
        )}
        {`${monthNames[parseInt(row.month, 10) - 1]} / ${val}`}
      </Styled.TableDataStyled>
    ),
    footerFormatter: () => (
      <Styled.TableFooterStyled>
        {TABLE_FOOTER}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'merchant',
    text: 'Merchant',
    width: '150px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        <p>
          {val}
        </p>
      </Styled.TableDataStyled>
    ),
    footerFormatter: () => (
      <Styled.TableFooterStyled />
    ),
  },
  {
    dataField: 'productCategory',
    text: 'Category',
    width: '150px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
    footerFormatter: () => (
      <Styled.TableFooterStyled />
    ),
  },
  {
    dataField: 'rollover',
    text: 'Rollover',
    width: '150px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val) || 0)}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(footerData?.rollover || 0)}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'deposits',
    text: 'Deposits',
    width: '150px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val || 0))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(footerData?.deposits || 0)}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'totalCommissions',
    text: 'Commissions',
    width: '150px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(val)}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(footerData?.commissions || 0)}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'manualAdjustment',
    text: 'Manual Adjustment',
    width: '180px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(val)}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(footerData?.manualAdjustments || 0)}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'systemUsageFee',
    text: 'System Usage Fee',
    width: '180px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(val)}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(footerData?.systemFee || 0)}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'otherFee',
    text: 'Other Fees',
    width: '225px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(val)}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(footerData?.otherFees || 0)}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'totalTax',
    text: 'Taxes',
    width: '225px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val || 0))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>

        {formateDollarAmount(footerData?.taxes || 0)}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'balance',
    text: 'Balance',
    width: '125px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val || 0))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(footerData?.balance || 0)}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'finalized',
    text: 'Statement',
    width: '125px',
    notClickable: true,
    formatter: (val: any, row: any, _: any, __: any, ___: any, ____: any, detailsClick: any, handleClick: any) => (
      <Styled.TableDataStyled theme="secondary">
        {val ? (
          <Styled.TableCellLink onClick={() => handleClick(row)}>
            <FontAwesomeIcon
              icon={faFile}
              size="lg"
            />
          </Styled.TableCellLink>
        ) : (
          <div />
        )}
        <Styled.TableCellLink onClick={() => detailsClick(row)}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Styled.TableCellLink>
      </Styled.TableDataStyled>
    ),
    footerFormatter: () => (
      <Styled.TableFooterStyled />
    ),
  },
];
