import React from 'react';

import {
  AppWrapper, LoadingDots, PageTitle, Tabs, Toggle,
} from '../../../../components';
import { path } from '../../../../utils';
import CheckTab from './Tabs/Check';
import { useFintelCheckSettings } from './hooks';
import { FINTEL_CHECK_SETTINGS } from './enums';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type FintelCheckSettingsProps = DefaultPropsType;

const FintelCheckSettings = ({ permissionsCodeList = [] }: FintelCheckSettingsProps) => {
  const { hookIsReadOnlyList, ...hook } = useFintelCheckSettings(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      {hook.hookLoading ? (
        <Styled.LoadingDotsWrapper>
          <LoadingDots />
        </Styled.LoadingDotsWrapper>
      ) : (
        <>
          <Styled.HeaderStyled>
            <PageTitle
              isLoading={hook.hookUpdateLoading}
              loadingText={FINTEL_CHECK_SETTINGS.LOADING_TEXT}
            >
              {path.fintelCheckSettings.name}
            </PageTitle>

            <Styled.HeaderToggleStyled>
              <Styled.HeaderLabelStyled>
                {FINTEL_CHECK_SETTINGS.ENABLE}
              </Styled.HeaderLabelStyled>
              <Toggle
                checked={hook.hookFintelCheckToggle}
                onChange={hook.hookSetFintelCheckToggle}
                disabled={isReadOnly}
              />
            </Styled.HeaderToggleStyled>
          </Styled.HeaderStyled>

          <Tabs
            tabNames={['Check']}
            elements={[<CheckTab
              hook={hook}
              isReadOnly={isReadOnly}
            />]}
          />
        </>
      )}
    </AppWrapper>
  );
};

export default FintelCheckSettings;
