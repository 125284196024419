import styled from 'styled-components';
import { Button } from '../../../../../components';
import { toRem } from '../../../../../utils';

type ButtonStyledProps = {
  readonly wrapperTheme?: string
}

type ButtonAreaStyledProps = {
  readonly enableNewStats?: boolean
}

export const WrapperStyled = styled.div`
  margin-top: 2.5rem;
`;

export const FiltersStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  justify-items: start;
  margin-bottom: ${toRem(30)};
`;

export const ButtonAreaStyled = styled.div<ButtonAreaStyledProps>`
  grid-column: ${({ enableNewStats }) => (enableNewStats ? '3/4' : '2/4')};
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1rem;
  width: 100%;
`;

export const ButtonStyled = styled(Button)<ButtonStyledProps>`
  width: fit-content;

  justify-self:  ${({ wrapperTheme }) => {
    switch (wrapperTheme) {
      case 'secondary':
        return 'end';
      case 'third':
        return 'end';
      default:
        return 'start';
    }
  }};

    margin-top:  ${({ wrapperTheme }) => {
    switch (wrapperTheme) {
      case 'secondary':
        return toRem(40);
      default:
        return 0;
    }
  }};

  grid-column: ${({ wrapperTheme }) => {
    switch (wrapperTheme) {
      case 'secondary':
        return '2/3';
      case 'third':
        return '3/4';
      default:
        return '1/2';
    }
  }};

  grid-row: ${({ wrapperTheme }) => {
    switch (wrapperTheme) {
      case 'secondary':
        return '2/3';
      case 'third':
        return '2/3';
      default:
        return '1/2';
    }
  }}
`;
