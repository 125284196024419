import styled from 'styled-components';
import { colors, fonts } from '../../../../styles/theme';
import { InputText } from '../../../../components';

export const WrapperStyled = styled.div`
  padding: 0.5rem 1rem;
`;

export const TitleStyled = styled.h1`
  ${fonts.gotham.H24M}
  color: ${colors.color10};
`;

export const FiltersWrapper = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
`;

export const InputStyled = styled(InputText)`
  div {
    height: auto;
  }
`;

export const ColumnsTitleStyled = styled.h2`
  ${fonts.lato.H12M}
  color: ${colors.color13};
  text-transform: uppercase;
  margin: 3rem 0 1rem;
`;

export const ColumnsWrapper = styled.ol`
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1rem;
  column-gap: 4rem;
  list-style: decimal;
  ${fonts.lato.H14}
  color: ${colors.color1};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
