import { dateFormatter } from '../../../../../utils';
import { PublisherMember } from '../types';

export const processDataToCSVString = (allTableData: any[], allReportColumns: {text: string, dataField: string}[]) => {
  // Create the Row of Column Titles
  const headerRow = allReportColumns.map((column) => column.text).toString();

  // Create the Data Rows
  const newData = allTableData.map((row) => {
    const rowArray = allReportColumns.map((column) => {
      if (!column?.dataField || row[column?.dataField] == null) return '';
      if (!['"', '\r', '\n', ','].some((e) => row[column.dataField].toString().indexOf(e) !== -1)) {
        if (typeof row[column.dataField] === 'number') return +row[column.dataField].toFixed(2);
        return row[column.dataField];
      }
      return `"${row[column.dataField].toString().replace(/"/g, '""')}"`;
    });
    return rowArray.toString();
  });

  return [headerRow, ...newData].join('\n');
};

export const formatDataForCSV = (membershipsList: PublisherMember[]) => membershipsList.map((membership) => ({
  ...membership,
  applicationDate: dateFormatter(new Date(membership.applicationDate), ','),
}));
