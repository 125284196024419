import React from 'react';
import { FormatedCell } from '../../../components/FormatedCell';
import { SelectProduct } from '../../../components/FormatedCell/SelectProduct';
import { MerchantManagementThumbnail } from '../../../components/FormatedCell/Thumbnail';

export const columns: TableColumn[] = [
  {
    dataField: 'customizedProductId',
    text: 'Product Id',
    formatter: (value: any, row: any) => (
      <FormatedCell>
        <MerchantManagementThumbnail
          value={value}
          url={row.productImageUrl}
        />
      </FormatedCell>
    ),
    width: '24%',
  },
  {
    dataField: 'name',
    text: 'Product Name',
    formatter: (value: any, row: any) => (
      <FormatedCell
        value={value}
        secondValue={row?.status !== undefined ? row.status : 'Inactive'}
      />
    ),
    width: '26%',
  },
  {
    dataField: 'productCategory',
    text: 'Product Categories',
    formatter: (value: any, row: any) => (
      <FormatedCell
        value={value}
        secondValue={row.subCategories?.join(', ')}
      />
    ),
    width: '35%',
  },
  {
    dataField: 'updatedAt',
    text: 'Last Update',
    formatter: (value: any, row: any, _:any, __:any, ___:any, ____, setModalInfo: (product: Record<string, any>) => void) => (
      <FormatedCell>
        <SelectProduct
          handleArrowClick={setModalInfo}
          date={value}
          value={row}
        />
      </FormatedCell>
    ),
    width: '15%',
  },
];
