import { gql } from '@apollo/client';

export const GET_CUSTOM_PUBLISHER_LIST_OPTIONS = gql`
  query getCustomPublisherListOptions($input: CheckSettingsInput) {
    getCustomPublisherListOptions(input: $input) {
      categories {
        label
        value
      }
      countries {
        label
        value
      }
    }
  }
`;
