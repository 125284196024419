import { gql } from '@apollo/client';

export const UPLOAD_BULK_IMPORT = gql`
  mutation newUploadTransactionsFile($input: TransactionsFileInput) {
    newUploadTransactionsFile(input: $input) {
      count
      transactions {
        grossSale
        netSale
        createdAt
      }
      totalGrossSale
      totalNetSale
      maxDate
      minDate
    }
}
`;
