import React from 'react';

import * as Styled from './styles';

type CodeProps = {
  value?: string;
  onChange: (value: string) => void;
  label?: string;
  name?: string;
  required?: boolean;
  type?: string;
  theme?: string;
  placeholder?: string;
  width?: string;
  className?: string;
  error?: string
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const CodeEditor = ({
  value,
  onChange,
  label,
  name = 'html',
  required = false,
  type = 'html',
  theme = 'textmate',
  placeholder,
  width = '100%',
  error,
  className,
  disabled = false,
  onBlur,
}: CodeProps) => (
  <Styled.WrapperStyled
    width={width}
    className={className}
  >
    {label && (
    <Styled.LabelStyled>
      {label}
      {' '}
      {required && <Styled.RequiredStyled>*</Styled.RequiredStyled>}
    </Styled.LabelStyled>
    )}
    <Styled.AceStyled
      placeholder={placeholder}
      mode={type}
      theme={theme}
      name={name}
      onChange={onChange}
      showPrintMargin={false}
      showGutter
      highlightActiveLine
      value={value}
      setOptions={{
        showLineNumbers: true,
        tabSize: 2,
      }}
      width={width}
      readOnly={disabled}
      onBlur={onBlur}
    />
    {error && <Styled.ErrorStyled>{error}</Styled.ErrorStyled>}
  </Styled.WrapperStyled>
);
