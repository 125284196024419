import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppWrapper, PageTitle, Tabs } from '../../../../components';
import { MERCHANT_PREFIX, path } from '../../../../utils';
import { BulkImport, Products } from './Tabs';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';
import { Permission } from '../../../../entities';

type ProductCatalogProps = DefaultPropsType;

const ProductCatalog = ({ permissionsCodeList = [] }: ProductCatalogProps) => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);

  const setTabHandler = (value: number) => {
    setTab(value);
  };

  const [isReadOnly] = Permission.readOnlyPermissionsList(permissionsCodeList);

  const hookTabs = { activeTabOveride: tab, setActiveTab: setTabHandler };

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle>{path.productCatalog.name}</PageTitle>
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={() => navigate(`${MERCHANT_PREFIX}${path.addNewProduct.href}`, { replace: true })}
          disabled={isReadOnly}
        >
          <FontAwesomeIcon icon={faPlus} />
          Add Product
        </Styled.ButtonStyled>
      </Styled.HeaderStyled>

      <Tabs
        tabNames={['Products', 'Bulk Import']}
        elements={[
          <Products />,
          <BulkImport
            changeTabs={hookTabs.setActiveTab}
            isReadOnly={isReadOnly}
          />,
        ]}
        hookOveride={hookTabs}
      />
    </AppWrapper>
  );
};

export default ProductCatalog;
