import { gql } from '@apollo/client';

export const GET_ADMIN_ROLES = gql`
  query GetAdminRoles {
    newRolesByType(type: Admin) {
      id
      name
      oldId
    }
  }
`;
