import { useState } from 'react';

export const useDocumentUpload = (editorRef: any) => {
  const [documentState, setDocumentState] = useState<File>();

  const handleDocumentChange = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '.pdf, .csv, .txt, .bin, .zip');
    input.click();
    input.onchange = (e: any) => {
      const editor = editorRef.current.getEditor();
      const range = editor.getSelection(true);
      const fileName = e.target.files[0].name || 'file';
      // TODO: update the url for the S3 object
      editor.insertText(range.index, fileName, 'link', 'file');
      setDocumentState(e.target.files[0]);
    };
  };

  return {
    hookDocument: documentState,
    hookDocumentChange: handleDocumentChange,
  };
};
