import { gql } from '@apollo/client';

export const GET_REPORT = gql`
  query reportProfile($id: ID!) {
    reportProfile(id: $id) {
      id
      name
      description
      report
      columns {
        label
        value
      }
      filters {
        field
        value {
          label
          value
        }
      }
      frequency
      every
      company
      createdAt
      updatedAt
    }
  }
`;
