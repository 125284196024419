import { gql } from '@apollo/client';

export const MERCHANT_PROGRAM = gql`
  query getProgram($id: ID!, $currentPeriodAccountStats: Boolean) {
    programV2(id: $id, currentPeriodAccountStats: $currentPeriodAccountStats) {
      id
      merchant {
        id
        companyName
        companyUrl
        companyImgUrl
      }
      accountBalanceStats {
        adminRate
        adminRateStartDate
        adminRateEndDate
        minUsageFee
        managementFee
        validUntilDate
        validStartDate
        minBalanceRequired
        warningBalance
      }
      enableNewStats
      salesTaxRate
      accountStat{
        id
        programId
        startDate
        endDate
        adminRate
        minUsageFee
        minBalanceRequired
        activeCategories
        managementFee {
          banking
          businesses
          creditCards
          creditReporting
          crypto
          insurance
          investments
          loans
          mortgage
          realEstate
          taxServices
          planning
        }
        newUseTieredRate {
          id
          newAdminAfterTierRate
          newAdminTierCommission
          newAdminTierRate
        }
      }
    }
}
`;
