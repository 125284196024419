export const monthLabelsGenerator = () => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();
  const first = months.slice(0, month + 1);
  const second = months.slice(month + 1, months.length);

  const monthLabels = second.concat(first);

  if (monthLabels[0] === 'Jan') {
    monthLabels[0] += `/${year}`;
  } else {
    monthLabels[0] += `/${year - 1}`;
  }
  monthLabels[monthLabels.length - 1] += `/${year}`;

  return {
    hookMonths: monthLabels,
  };
};
