import { gql } from '@apollo/client';

export const UPDATE_FINTEL_CHECK_SUMMARIES = gql`
  mutation updateFintelCheckSummaries($input: FintelCheckSummaryInput!) {
    updateFintelCheckSummaries(input: $input) {
      id
      eligibilityFeedback {
        correct
        currStatus
        inputType
        inputVal
        issue
        shouldBeStatus
      }
    }
  }
`;
