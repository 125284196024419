import { gql } from '@apollo/client';

export const LIST_PRODUCT_CATEGORIES = gql`
  query List {
    listProductCategory {
      list {
        id
        name
      }
    }
  }
`;
