import uaParser from 'ua-parser-js';
import { TMRow, TMTableRowFlat } from '../types';

export const processTableData = (unprocessedData: TMRow[]) => {
  const tableData = unprocessedData.map((row): TMTableRowFlat => ({
    ...row,
    processedDate: row?.saleDate || null,
    merchantId: row.merchant?.id || null,
    merchantName: row.merchant?.companyName || null,
    publisherId: row.publisher?.id || null,
    publisherName: row.publisher?.companyName || null,
    productId: row.product?.id || null,
    productName: row.product?.name || null,
    productCategory: row.product?.productCategory || null,
    thirdPartyUrl: row.product?.thirdPartyUrl || null,
    adId: row.ad?.id || null,
    adName: row.ad?.adName || null,
    processedByFirstName: row.processedBy?.firstName || null,
    processedByLastName: row.processedBy?.lastName || null,
    country: row.geoIp?.country || null,
    trackingProfilePrimaryWebsite: row.trackingProfile?.primaryWebsite || null,
    trackingProfileUrl: row.trackingProfile?.primaryWebsite || null,
    trackingProfileId: row.trackingProfile?.id || null,
    programId: row.program?.id || null,
    userOS: `${uaParser(row?.userAgent || '').os.name} / ${uaParser(row?.userAgent || '').browser.name}`,
    osBrowser: `${uaParser(row?.userAgent || '').os.name} / ${uaParser(row?.userAgent || '').browser.name}`,
    commissionAmount: row.transactionStatus === 'Approved' ? row.commissionAmount : 0,
  }
  ));
  return tableData;
};
