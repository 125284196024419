import { gql } from '@apollo/client';

export const GET_SNAPSHOT = gql`
  query publisherDashboardSnapshot($input: PublisherDashboardInput!) {
    publisherDashboardSnapshot(input: $input) {
      totalTransactions
      approvedTransactions
      commissions
    }
  }
`;
