export const NOTIFY_PUBLISHERS = 'Notify Publishers';

export const STEPS = {
  ONE: 'Importing multiple products is done using a Fintel Connect .csv template. If you\'re creating your feed for the first time, please download the file template, by clicking on \'Download CSV\' to get started. If you\'re updating your existing feed, please export your existing CSV, available via Program > Product Catalog.',
  TWO_ONE: 'Following the headings in the template, please carefully insert product data for the relevant fields. You can leave any fields that don\'t apply to your product empty.',
  TWO_TWO: 'There are some fields where multiple values are allowed. Please replace <Multiple values> with value1, value2, value3 and use commas (",") to separate values. For example, if you would like to use both Travel and Cashback for Sub Categories, you need to insert "Travel, Cash back".',
  THREE_ONE: 'Once you\'re satisfied with the data, save the file and upload it below.',
  THREE_TWO: 'After uploading, you will be asked to confirm the changes once more.',
};

export const BUTTONS = {
  CSV: 'Download .CSV',
  CANCEL: 'Cancel',
  NEXT: 'Next',
};

export const FILE_UPLOAD = {
  LABEL: 'Upload CSV',
  INFO: 'Max file size is 4MB',
};
