import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../../../styles/theme';
import { Button, Tooltip } from '../../../../components';
import { toRem } from '../../../../utils';

type TooltipProps = {
  readonly fontSize?: string
}

export const HeaderStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const HeaderLeftWrapper = styled(HeaderStyled)`
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderLeftStyled = styled(HeaderStyled)`
  justify-content: flex-start;
  gap: 1rem;
`;

export const RuleNameStyled = styled.h4`
  ${fonts.gotham.H20M}
  color: ${colors.color1};
  margin: 1rem 0;
`;

export const RefferalUrlStyled = styled.a`
  ${fonts.gotham.H16M}
  color: ${colors.color2};
`;

export const DateStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.color19};
  color: ${colors.color1};
  padding: 0.5rem;
  ${fonts.lato.H12}
  border-radius: 4px;
  margin-left: 1rem;
`;

export const ReportWrapper = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  background-color: ${colors.color4};
`;

export const ReportTitleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  ${fonts.gotham.H20M}
  color: ${colors.color1};
`;

export const TableWrapper = styled.div`
  margin: 2rem 0;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

export const ButtonStyled = styled(Button)`
  padding: 0.5rem;
  color: ${colors.color1};
  border-radius: 2px;

  :hover {
    color: ${colors.color1};
    background-color: ${colors.color19};
  }
`;

export const TooltipStyled = styled(Tooltip)<TooltipProps>`
  div:first-child { font-size: ${({ fontSize }) => (fontSize || '')}; };
  svg {
    color: ${colors.color1};
  }
  margin: 0;
`;

export const CriteriaRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${fonts.lato.H14};
  width: 85%;
  padding: ${toRem(24)} 0 ${toRem(48)} 0;
`;

export const CriteriaItemStyled = styled.div`
`;

export const CriteriaTitleStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-transform: uppercase;
  padding-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12M}
`;

export const CriteriaValueStyled = styled.span`
  width: 100%;
  ${fonts.lato.H14}
`;

export const LoadingStyled = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
`;
