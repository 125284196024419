import React from 'react';
import { EMPTYSTATE_TITLE, TAB_DESCRIPTION } from '../../contracts';
import * as Styled from './styles';

export const DomainEmptyState = () => (
  <Styled.EmptyStateStyled>
    <Styled.TitleStyled>{EMPTYSTATE_TITLE.DOMAIN}</Styled.TitleStyled>
    <Styled.DecsriptionStyled>{TAB_DESCRIPTION.DOMAIN_EMPTY}</Styled.DecsriptionStyled>
  </Styled.EmptyStateStyled>
);
