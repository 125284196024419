import { gql } from '@apollo/client';

export const LIST_ACCOUNT_STATUS_TYPE = gql`
  query List {
    listAccountStatusType {
      list {
        id
        type
      }
    }
  }
`;
