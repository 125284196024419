export const ADD_NOTE = {
  REFERENCE: {
    TITLE: 'Reference',
    SUBTITLE: 'reference #',
  },
  NOTE: {
    TITLE: 'Note',
    DESCRIPTION: 'Please add note if payment is having a processing issue or the admin user is awaiting confirmation',
  },
  BUTTON_TEXT: {
    CANCEL: 'Cancel',
    SAVE: 'Save',
  },
};
