import styled from 'styled-components';
import { colors, fonts } from '../../../styles/theme';
import { hexToRGB, toRem } from '../../../utils';
import { Tooltip } from '../../Tooltip';

type ZoneProps = {
  readonly isDragging: boolean;
  readonly disabled: boolean;
}

type DropZoneProps = {
  readonly isDisplayed: boolean;
  readonly disabled: boolean;
}

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const LabelTextStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12M}
`;

export const EmptyZoneStyled = styled.div<DropZoneProps>`
  display: ${({ isDisplayed }) => (!isDisplayed ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;

  svg {
    font-size: 2rem;
    color: ${({ disabled }) => (disabled ? colors.color13 : colors.color3)};
  }
`;

export const ZoneStyled = styled.div<ZoneProps>`
  border-radius: 4px;
  border: 2px dashed ${({ isDragging }) => (isDragging ? colors.color12 : colors.color3)};
  background-color: ${({ isDragging, disabled }) => {
    if (disabled) {
      return colors.color15;
    }

    if (isDragging) {
      return `rgba(${hexToRGB(colors.color11)}, 0.5)`;
    }

    return 'transparent';
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  input {
    display: none;
  }
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
  margin-left: ${toRem(4)};
`;

export const EmptyZoneTextStyled = styled.span`
  margin-top: 2rem;
  color: ${colors.color16};
  ${fonts.gotham.H16M}
`;

export const UploadLabelStyled = styled.label`
  color: ${colors.color2};
  cursor: pointer;

  &:hover {
    color: ${colors.color8};
  }
`;

export const NonEmptyZoneStyled = styled.div<DropZoneProps>`
  display: ${({ isDisplayed }) => (isDisplayed ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

export const ImagePreviewBoxStyled = styled.figure`
  display: flex;
  justify-content: center;
  width: 580px;
`;

export const OptionsAreaStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin-top: 1rem;
`;

export const OptionsAreaTextStyled = styled.span`
  ${fonts.lato.H16}
  line-height: 1rem;
  color: ${colors.color7};
  text-align: center;
  padding-bottom: 1rem;
`;

export const ButtonStyled = styled.label`
  ${fonts.lato.H14}
  padding: ${toRem(10)} ${toRem(16)};
  border: 1px solid ${colors.color1};
  background: ${colors.color1};
  color: ${colors.color4};
  border-radius: ${toRem(4)};
  transition: all 0.2s ease-in-out;
  text-align: center;

  &:hover {
    border: 1px solid ${colors.color10};
    background: ${colors.color10};
    color: ${colors.color4};
  }
`;

export const TooltipStyled = styled(Tooltip)`
  height: auto;

  div {
    margin-left: 2px;
  }
`;

export const InfoStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: ${toRem(11)};
  margin: 0.625rem 0;
  color: ${colors.color13};
  ${fonts.lato.H14}
`;

export const ErrorStyled = styled.span`
  display: flex;
  width: 100%;
  color: ${colors.color14};
  margin: 0.5rem 0;
  ${fonts.lato.H14}
`;
