import React from 'react';
import { faPlus, faFileDownload } from '@fortawesome/free-solid-svg-icons';

import {
  AppWrapper, Modal, PageTitle, Tabs,
} from '../../../../components';
import { path } from '../../../../utils';
import TrackingProfilesTab from './Tabs/TrackingProfiles';
import AddProfileTab from './Modal';
import TrackingDomainsTab from './Tabs/TrackingDomains';
import { TRACKING_DETAILS } from './enums';
import { useTrackingDetails } from './hooks';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type TrackingDetailsProps = DefaultPropsType;

const TrackingDetails = ({ permissionsCodeList = [] }: TrackingDetailsProps) => {
  const { hookIsReadOnlyList, ...hook } = useTrackingDetails(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle>{path.trackingDetails.name}</PageTitle>

        <Styled.HeaderButtonAreaStyled>
          {hook.hookDownloadButton && (
            <Styled.ButtonStyled
              theme="tertiary"
              onClick={hook.hookTrackingDomains.hookDownloadCSV}
            >
              <Styled.IconStyled icon={faFileDownload} />
              {TRACKING_DETAILS.DOWNLOAD_CSV}
            </Styled.ButtonStyled>
          )}

          <Styled.ButtonStyled
            onClick={() => {
              hook.hookTrackingProfile.hookAddProfile.hookSetModal();
              hook.hookTrackingProfile.hookAddProfile.hookAddModal();
            }}
            disabled={isReadOnly}
          >
            <Styled.IconStyled icon={faPlus} />
            {TRACKING_DETAILS.ADD_PROFILE}
          </Styled.ButtonStyled>
        </Styled.HeaderButtonAreaStyled>
      </Styled.HeaderStyled>

      <Tabs
        tabNames={[TRACKING_DETAILS.TABS.TRACKING_PROFILES, TRACKING_DETAILS.TABS.TRACKING_DOMAINS]}
        elements={[
          <TrackingProfilesTab
            modalHook={hook.hookTrackingProfile.hookAddProfile.hookSetModal}
            isOpen={false}
            hook={hook.hookTrackingProfile}
            downloadButton={() => hook.hookSetDownloadButton(false)}
            isReadOnly={isReadOnly}
          />,
          <TrackingDomainsTab
            modalHook={hook.hookTrackingProfile.hookAddProfile.hookSetModal}
            isOpen={false}
            hook={hook.hookTrackingDomains}
            downloadButton={() => hook.hookSetDownloadButton(true)}
          />,
        ]}
      />

      <Modal isOpen={hook.hookTrackingProfile.hookAddProfile.hookModal}>
        <AddProfileTab
          hook={hook.hookTrackingProfile.hookAddProfile}
        />
      </Modal>
    </AppWrapper>
  );
};

export default TrackingDetails;
