/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  faArrowsLeftRight, faGripHorizontal, faSort, faSortDown, faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { hoverManyElements } from '../../utils';
import { useResize } from './hooks';
import * as Styled from './styles';
import { Spinner } from '../Spinner';
import { useTableColumns, useTableData } from '../../hooks';

type DragTableProps = {
  columns: TableColumn[],
  data: any[],
  onDragEnd?: (result: any) => void,
  onSort?: (column: string, direction: 'asc' | 'desc' | undefined) => void,
  sortColumn?: TableSortColumn,
  isLoading?: boolean,
  className?: string,
  rowName?: string,
  setOpenModal?: () => void
  setModalInfo?: (values: Record<string, any>) => void
  setContext?: (values: Record<string, any>) => void
  headers?: boolean
};

export const DragTable = ({
  columns, data, setOpenModal = () => {}, setModalInfo = () => {}, setContext = () => {}, onDragEnd, isLoading = false, onSort = undefined, sortColumn = undefined, className, rowName = 'table-row', headers = true,
}: DragTableProps) => {
  const [dataAttribute] = useState(`data-${rowName}`);
  const { hookColumns, hookOnDragEnd, hookSetColumns } = useTableColumns(columns);
  const { hookData, hookSetData } = useTableData(data);
  const { hookOnResize, hookFixedHeader, hookResetColumnSize } = useResize();

  useEffect(() => {
    hookSetColumns(columns);
  }, [JSON.stringify(columns)]);

  useEffect(() => {
    hookSetData(data);
  }, [JSON.stringify(data)]);

  const renderRows = (isDragging: boolean, column: TableColumn) => hookData.map((row, index: number) => (
    <Styled.RowStyled
      key={index}
      isDragging={isDragging}
      onMouseOver={() => hoverManyElements(index, true, dataAttribute)}
      onMouseLeave={() => hoverManyElements(index, false, dataAttribute)}
      {...{ [dataAttribute]: index }}
    >
      <Styled.CellStyled>
        {!column.formatter ? row[column.dataField] : column.formatter(row[column.dataField], row, hookData, hookSetData, () => undefined, setOpenModal, setModalInfo, setContext, columns)}
      </Styled.CellStyled>
    </Styled.RowStyled>
  ));

  const renderColumns = () => hookColumns.map((column: TableColumn, index: number) => (
    <Draggable
      key={index}
      draggableId={`drag-${index}`}
      index={index}
    >
      {(provided, snapshot) => (
        <Styled.ColumnStyled
          isDragging={snapshot.isDragging}
          {...provided.draggableProps}
          ref={provided.innerRef}
          minWidth={column.width}
          data-column={index}
          id={`column-${column.dataField}`}
        >
          {headers ? (
            <Styled.HeaderStyled>
              <Styled.HeaderButtonsStyled>
                <Styled.HeaderDragButtonStyled {...provided.dragHandleProps}>
                  <FontAwesomeIcon icon={faGripHorizontal} />
                </Styled.HeaderDragButtonStyled>

                <Styled.HeaderResizeButtonStyled onMouseDown={() => hookOnResize(index)}>
                  <FontAwesomeIcon icon={faArrowsLeftRight} />
                </Styled.HeaderResizeButtonStyled>
              </Styled.HeaderButtonsStyled>

              <Styled.SortButtonStyled
                isSorted={!!column.sortFunction}
                onClick={() => onSort?.(column.dataField, sortColumn?.direction)}
              >
                {column.text}
                {sortColumn?.column !== column.dataField && !!column.sortFunction && <FontAwesomeIcon icon={faSort} />}
                {sortColumn && sortColumn.column === column.dataField && <FontAwesomeIcon icon={sortColumn.direction === 'asc' ? faSortUp : faSortDown} />}
              </Styled.SortButtonStyled>
            </Styled.HeaderStyled>
          ) : (
            <Styled.DragButton>
              <Styled.HeaderDragButtonStyled {...provided.dragHandleProps}>
                <FontAwesomeIcon icon={faGripHorizontal} />
              </Styled.HeaderDragButtonStyled>
            </Styled.DragButton>
          )}

          {renderRows(snapshot.isDragging, column)}

          {column.footer && (
            <Styled.FooterStyled>
              {column.footerFormatter ? column.footerFormatter(column.footer, column) : column.footer}
            </Styled.FooterStyled>
          )}
        </Styled.ColumnStyled>
      )}
    </Draggable>
  ));

  return (
    <Styled.ContainerStyled
      className="drag-table-component"
    >
      <Styled.FixedHeaderStyled
        shown={hookFixedHeader}
        id="drag-table-fixed-header"
      >
        {hookColumns.map((col, index) => (
          <Styled.FixedHeaderSpanStyled
            key={`fixed-header-span-${index}`}
            id={`fixed-header-span-${index}`}
            width={col.width}
          >
            {col.text}
          </Styled.FixedHeaderSpanStyled>
        ))}
      </Styled.FixedHeaderStyled>
      <Styled.BodyStyled
        className={className}
        id="drag-table-component"
      >
        <DragDropContext onDragEnd={(results) => {
          if (results) hookResetColumnSize(results.source.index);
          hookOnDragEnd(results, onDragEnd);
        }}
        >
          <Droppable
            droppableId="droppable"
            direction="horizontal"
            type="column"
          >
            {(provided) => (
              <Styled.DropZoneStyled
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {renderColumns()}
                {provided.placeholder}
              </Styled.DropZoneStyled>
            )}
          </Droppable>
        </DragDropContext>
      </Styled.BodyStyled>
      {isLoading && (
        <Styled.LoadingStyled>
          <Spinner
            size="3rem"
            theme="quaternary"
          />
        </Styled.LoadingStyled>
      )}
    </Styled.ContainerStyled>

  );
};
