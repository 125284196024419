import React from 'react';
import { CellWrapper } from '../components';
import { dateFormatter } from '../../../../utils';
import { CommissionFormatted, Transaction } from '../../../../utils/formatCommissions';

export const columns: TableColumn[] = [
  {
    dataField: 'id',
    text: 'ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (cell: any) => (
      <CellWrapper>
        {cell}
      </CellWrapper>
    ),
    width: '100px',
  },
  {
    dataField: 'commissionType',
    text: 'Commission/Members',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (val: any, row: CommissionFormatted) => (
      <CellWrapper>
        <b>
          {`${!row?.publishers?.length && !row?.publisherGroups?.length ? 'Default' : 'Custom'} ${val}`}
        </b>
        <p>
          {row.memberType}
        </p>
      </CellWrapper>
    ),
    width: '15%',
  },
  {
    dataField: 'payable',
    text: 'Commission Payable',
    formatter: (cell: any) => (
      <CellWrapper>
        {cell || ''}
      </CellWrapper>
    ),
    width: '12%',
  },
  {
    dataField: 'transaction',
    text: 'Transaction',
    formatter: (cell: Transaction) => (
      <CellWrapper>
        <b>
          {cell.commissionBase}
        </b>
        <p>
          {cell.minimumSaleAmount}
        </p>
      </CellWrapper>
    ),
    width: '12%',
  },
  {
    dataField: 'product',
    text: 'Merchant/Products',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (cell: any, row: CommissionFormatted) => (
      <CellWrapper>
        <b>
          {row.merchant.companyName || ''}
        </b>
        <p>
          {row.product.name || ''}
        </p>
      </CellWrapper>
    ),
    width: '18%',
  },
  {
    dataField: 'productCategory',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    text: 'Product Category',
    formatter: (cell: any, row: CommissionFormatted) => (
      <CellWrapper>
        <b>
          {row.product.productCategory || ''}
        </b>
      </CellWrapper>
    ),
    width: '15%',
  },
  {
    dataField: 'publisherStartDate',
    text: 'Start/End Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (cell: any, row: CommissionFormatted) => (
      <CellWrapper>
        {row.publisherStartDate ? dateFormatter(row.publisherStartDate) : ''}
        {' '}
        -
        {' '}
        {row.publisherEndDate ? dateFormatter(row.publisherEndDate) : ''}
      </CellWrapper>
    ),
    width: '20%',
  },
];
