import { gql } from '@apollo/client';

export const GET_PROGRAM = gql`
  query Program($programId: ID!, $currentPeriodAccountStats: Boolean) {
  program(id: $programId, currentPeriodAccountStats: $currentPeriodAccountStats) {
    id
    merchant {
      id
      companyName
      productCategories
      companyUrl
      companyImgUrl
      memberships {
        id
        status
        publisher {
          id
        }
        publisherInvitation {
          id
          subject
          message
        }
        subscription
        statusLastUpdatedBy
      }
      apiAccessKey {
        status
        apiAccessKey
        apiCreatedAt
      }
    }
    descriptionHighLevel
    termsConditionsTitle
    termsConditions
    signupBonus
    signupBonusVisibility
    trackingSettings {
      landingPage
      urlQueryString
      cookieDuration
      statsRelay
      nofollow
      thirdPartyUrl
      thirdPartyUrlFormat
      thirdPartyStatus
    }
    transactionSettings {
      autoApprovePending
      autoApproveHeld
      currency
      tracked
      allowNoOrderId
      allowPixeltracking
      allowPostback
      autoDeclineDays
      integrations {
        type
        enabled
        autoApproveEnabled
        url
      }
    }
    accountBalanceStats {
      adminRate
      adminRateStartDate
      adminRateEndDate
      minUsageFee
      useRateSettings
      newUseTieredRate {
        id
        newAdminAfterTierRate
        newAdminTierCommission
        newAdminTierRate
      }
      managementFee
      validUntilDate
      validStartDate
      minBalanceRequired
      warningBalance
    }
    enableNewStats
    salesTaxRate
    descriptionDetailed
    primaryContact
    primaryContactEmail
    primaryContactPhone
    membersOffer
    membersOfferVisibility
    CookieDuration
    CookieDurationVisibility
    statsFrequency
    statsFrequencyVisibility
    productMatrixUrl
    updatedAt
    updatedBy {
      firstName
      lastName
    }
    accountStat {
      id
      programId
      startDate
      endDate
      adminRate
      minUsageFee
      minBalanceRequired
      activeCategories
      useRateSettings
      newUseTieredRate {
        id
        newAdminAfterTierRate
        newAdminTierCommission
        newAdminTierRate
      }
      managementFee {
        banking
        businesses
        creditCards
        creditReporting
        crypto
        insurance
        investments
        loans
        mortgage
        realEstate
        taxServices
        planning
      }
    }
  }
}
`;
