import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as Styled from '../../styles';
import {
  Button, Checkbox, Modal, InputText, Tooltip, Select,
} from '../../../../../../../../components';
import {
  ALL_PRODUCTS, NO_PRODUCTS, ProductType, RuleRequirementType,
} from '../../hooks';
import { STEP_THREE_MODAL } from '../../enums';

type AddRuleStepThreeProps = {
  isOpen: boolean
  backButtonHandler: () => void
  product: ProductType | undefined
  selectedProductCategory: SelectOption | undefined
  requirements: RuleRequirementType[]
  requirementChoices: SelectOption[]
  updateRequirementFeed: (id: number, newField: SelectOption) => void
  stepThreeErrors: string[]
  onBlurCheck: () => void
  updateRequirementValue: (id: number, value: React.ChangeEvent<HTMLInputElement>) => void
  handleSetFeedDataOpen: (state: boolean, feedName?: string | undefined) => void
  removeRequirement: (id: number) => void
  updateRequirementRequired: (id: number, state: boolean) => void
  addRequirement: () => void
  cancelButtonHandler: () => void
  navigateModalFour: () => void
  canContinueThree: boolean
}

export const AddRuleStepThreeModal = ({
  isOpen,
  backButtonHandler,
  product,
  selectedProductCategory,
  requirements,
  requirementChoices,
  updateRequirementFeed,
  stepThreeErrors,
  onBlurCheck,
  updateRequirementValue,
  handleSetFeedDataOpen,
  removeRequirement,
  updateRequirementRequired,
  addRequirement,
  cancelButtonHandler,
  navigateModalFour,
  canContinueThree,
}: AddRuleStepThreeProps) => (
  <Modal isOpen={isOpen}>
    <Styled.ModalWrapperStyled>
      <Styled.PageTitleStyled>
        <Styled.BackIconStyled
          onClick={backButtonHandler}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Styled.BackIconStyled>
        {STEP_THREE_MODAL.TITLE}
      </Styled.PageTitleStyled>
      <Styled.DropdownSectionStyled>
        <Styled.SubTitleTextStyled>
          {STEP_THREE_MODAL.DESCRIPTION}
        </Styled.SubTitleTextStyled>
        <Styled.TextStyled>
          {STEP_THREE_MODAL.GUIDE}
          <Styled.BoldTextStyled>
            {product?.productName
              ? ` ${product?.customizedProductId} - ${product?.productName} (${selectedProductCategory?.label}).`
              : ` ${product?.customizedProductId} (${selectedProductCategory?.label}).`}
          </Styled.BoldTextStyled>
        </Styled.TextStyled>
      </Styled.DropdownSectionStyled>
      <div>
        {requirements.map((requirement, index) => (
          <React.Fragment key={requirement.id}>
            {index !== 0 && (
              <Styled.AndSection>
                <Styled.AndLine />
                <Styled.AndText>
                  {STEP_THREE_MODAL.AND}
                </Styled.AndText>
                <Styled.AndLine />
              </Styled.AndSection>
            )}
            <Styled.RequirementRowStyled>
              <Select
                required
                name={`ruleRequirement${requirement.id}`}
                label={STEP_THREE_MODAL.RULE_REQUIREMENT.LABEL}
                tooltip={STEP_THREE_MODAL.RULE_REQUIREMENT.TOOLTIP}
                placeholder={STEP_THREE_MODAL.RULE_REQUIREMENT.PLACEHOLDER}
                selected={requirement.productFeed}
                options={requirementChoices}
                onChange={(selectOption) => updateRequirementFeed(requirement.id, selectOption)}
                error={stepThreeErrors[(1000000000 + requirement.id)]}
                onBlur={onBlurCheck}
              />
              {product?.type !== ALL_PRODUCTS && (
                <InputText
                  required
                  type="text"
                  name={`productFeedData${requirement.id}`}
                  label={STEP_THREE_MODAL.PRODUCT_FEED_DATA.LABEL}
                  tooltip={STEP_THREE_MODAL.PRODUCT_FEED_DATA.TOOLTIP}
                  placeholder={product?.type === NO_PRODUCTS ? STEP_THREE_MODAL.PRODUCT_FEED_DATA.PLACEHOLDER.NO_PRODUCTS : STEP_THREE_MODAL.PRODUCT_FEED_DATA.PLACEHOLDER.DEFAULT}
                  value={requirement.value}
                  onChange={(value) => updateRequirementValue(requirement.id, value)}
                  disabled={!requirement.productFeed || product?.type !== NO_PRODUCTS}
                  error={stepThreeErrors[requirement.id]}
                  onBlur={onBlurCheck}
                  maxLength={500}
                />
              )}
              {product?.type === ALL_PRODUCTS && (
                <div>
                  <Styled.ProductFeedSection>
                    {STEP_THREE_MODAL.PRODUCT_FEED_DATA.LABEL}
                    <Tooltip text={STEP_THREE_MODAL.PRODUCT_FEED_DATA.TOOLTIP} />
                  </Styled.ProductFeedSection>
                  <Styled.ProductFeedButton
                    active={!!requirement.productFeed}
                    onClick={() => handleSetFeedDataOpen(true, requirement.productFeed?.value)}
                  >
                    {requirement.productFeed ? STEP_THREE_MODAL.PRODUCT_FEED_DATA.BUTTON : ''}
                  </Styled.ProductFeedButton>
                </div>
              )}
              <Styled.DeleteRequirementButton
                onClick={() => removeRequirement(requirement.id)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Styled.DeleteRequirementButton>
              <Styled.RequiredCheckboxWrapper>
                <Checkbox
                  theme="tertiary"
                  label={STEP_THREE_MODAL.REQUIRED.LABEL}
                  checked={requirement.required}
                  onChange={(value) => updateRequirementRequired(requirement.id, value)}
                  tooltip={STEP_THREE_MODAL.REQUIRED.TOOLTIP}
                />
              </Styled.RequiredCheckboxWrapper>
            </Styled.RequirementRowStyled>
          </React.Fragment>
        ))}
      </div>
      <Styled.AddRuleButtonWrapper>
        <Button
          theme="tertiary"
          onClick={addRequirement}
          width="auto"
        >
          <FontAwesomeIcon
            icon={faPlus}
            style={{ paddingRight: '8px' }}
          />
          {STEP_THREE_MODAL.ADD_RULE_REQUIREMENT}
        </Button>
      </Styled.AddRuleButtonWrapper>
      <Styled.ModalFooterStyled>
        <Styled.PageCounterStyled>
          {STEP_THREE_MODAL.STEP_COUNTER}
        </Styled.PageCounterStyled>
        <Styled.NavButtonsWrapperStyled>
          <Button
            theme="secondary"
            onClick={cancelButtonHandler}
            width="100px"
          >
            {STEP_THREE_MODAL.CANCEL_BUTTON}
          </Button>
          <Button
            theme="primary"
            onClick={navigateModalFour}
            disabled={!canContinueThree}
            width="120px"
          >
            {STEP_THREE_MODAL.CONTINUE_BUTTON}
          </Button>
        </Styled.NavButtonsWrapperStyled>
      </Styled.ModalFooterStyled>
    </Styled.ModalWrapperStyled>
  </Modal>
);
