import styled from 'styled-components';
import { colors } from '../../styles/theme';
import { toRem } from '../../utils';

type DropdownTextStyledPropsTypes = {
  readonly disabled?: boolean
}

type DropdownShownStyledPropsTypes = {
  readonly width?: string
  readonly top?: string
  readonly right?: string
  readonly disabled?: boolean
}

export const DropdownWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  left: 0;
  top: 0;
  white-space: nowrap;
`;

export const DropdownIconStyled = styled.i`
  padding-right: ${toRem(8)};
`;

export const ExpandableIconStyled = styled.i`
  padding-left: ${toRem(8)};
  padding-bottom:${toRem(6)};
`;

export const DropdownTextStyled = styled.div<DropdownTextStyledPropsTypes>`
  width: 100%;
  color: ${({ disabled }) => (disabled ? colors.color10 : colors.color1)};
  display: flex;
  align-content: space-around;
  align-items: center;
  justify-content: space-between;
`;

export const DropdownShownStyled = styled.div<DropdownShownStyledPropsTypes>`
  padding: ${toRem(10)};
  width: ${({ width }) => (width || '100%')};
`;

export const ChildrenDivStyled = styled.div<DropdownShownStyledPropsTypes>`
  position: absolute;
  background-color: white;
  border: 1px solid ${colors.color3};
  box-shadow: 0px 6px 20px -2px rgba(26, 26, 26, 0.14), 0px 4px 6px rgba(26, 26, 26, 0.06);
  border-radius: 1px;
  z-index: 9999;
  top: 100%;
  right: 0%;
  top: ${({ top }) => (top || '100%')};
  right: ${({ right }) => (right || '0%')};
  border-radius: ${toRem(4)};
`;

export const DropdownRightSideStyled = styled.div`
  display: flex;
  align-items: center;
`;
