import React, { useEffect } from 'react';
import { LoadingDots, Pagination } from '../../../../../../components';
import { columns } from '../../contracts';
import * as Styled from '../styles';
import type { useMembershipManagement } from '../../hooks';

type MembershipTabProps = {
  statusType: string
  hook: ReturnType<typeof useMembershipManagement>
  isReadOnly: boolean
}

export const MembershipTab = ({
  statusType, hook, isReadOnly,
}: MembershipTabProps) => {
  useEffect(() => {
    hook.hookSetStatusType(statusType);
  }, [statusType]);

  return (
    <Styled.WrapperStyled>
      {hook.hookIsLoading ? (
        <LoadingDots />
      ) : (
        <>
          <Styled.TableStyled
            columns={columns(isReadOnly)}
            data={hook.hookMemberList}
            cellAlignItems="flex-start"
            headerCheckBox={hook.hookHeaderCheck}
            handleHeaderCheckBox={hook.hookSetSelectedPublishersAll}
            handleCheckBox={hook.hookSetSelectedPublishers}
            onRowClick={hook.hookNavigationHandler}
            onSort={hook.hookHandleSort}
            sortColumn={hook.hookSortColumn}
          />
          <Pagination
            total={hook.hookTotalPages}
            currentPage={hook.hookCurrentPage}
            onPageChange={hook.hookSetCurrentChange}
          />
        </>
      )}
    </Styled.WrapperStyled>
  );
};
