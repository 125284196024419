import styled from 'styled-components';
import { colors, fonts } from '../../../../../styles/theme';

export const ModalWrapper = styled.div`
  padding: 1.5rem;
  max-width: 570px;
`;

export const ModalTitle = styled.h3`
  ${fonts.gotham.H26M}
  color: ${colors.color1};
`;

export const ConfirmationStyled = styled.div`
  color: ${colors.color17};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
  line-height: 1.5;

  ul {
    list-style: disc;
  }
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
`;
