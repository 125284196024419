import { useEffect, useState } from 'react';
import { FeedCategoryType } from '../../RuleDetailsModal/hooks';

export const useProductFeedModal = (tableData: FeedCategoryType | undefined) => {
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const [displayedData, setDisplayedData] = useState<FeedCategoryType>();

  const sortTableHandler = (dataColumn: string, direction: 'asc' | 'desc' | undefined) => {
    if (tableData && tableData.values[0] && Object.hasOwn(tableData.values[0], dataColumn)) {
      const newTableData: FeedCategoryType = structuredClone(tableData);
      newTableData.values.sort((a, b) => {
        const nameA = a[dataColumn as keyof typeof a].toUpperCase();
        const nameB = b[dataColumn as keyof typeof a].toUpperCase();
        if (nameA < nameB) {
          return direction === 'asc' ? 1 : -1;
        }
        if (nameA > nameB) {
          return direction === 'asc' ? -1 : 1;
        }
        return 0;
      });
      setDisplayedData(newTableData);
    }
  };

  const setSortByHandler = (dataColumn: string, _: 'asc' | 'desc' | undefined) => {
    if (sortColumn?.direction === 'desc' && sortColumn.column === dataColumn) {
      setSortColumn({ column: dataColumn, direction: 'asc' });
      sortTableHandler(dataColumn, 'asc');
    } else {
      setSortColumn({ column: dataColumn, direction: 'desc' });
      sortTableHandler(dataColumn, 'desc');
    }
  };

  // Handles setting initial state on opening
  useEffect(() => {
    setDisplayedData(structuredClone(tableData));
    setSortColumn(undefined);
  }, [tableData]);

  return {
    hookDisplayedData: displayedData,
    hookSortColumn: sortColumn,
    hookSortByHandler: setSortByHandler,
  };
};
