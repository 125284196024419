import { gql } from '@apollo/client';

export const ADMIN_DASHBOARD_MERCHANT_PERFORMANCE = gql`
  query AdminDashboard($input: AdminDashboardMerchantPerformanceInput!) {
    adminDashboardMerchantPerformance(input: $input) {
      totalCommissions
      totalTransactions
      approvedTransactions
    }
  }
`;
