export const PAGE_TITLE = 'Merchant Account Settings';

export const FORM_SECTION_TITLE = {
  CONTACT: 'Contact Information',
  BILLING: 'Billing Information',
  USER: 'User Management',
};

export const BUTTON_LABEL = {
  EDIT: 'Edit',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  ADD_USER: 'Add User',
  DEACTIVATE: 'Deactivate',
};

export const INPUT_LABEL = {
  ID: 'ID',
  ACCOUNT_STATUS: 'Account Status',
  NETWORK_STATUS: 'Network Status',
  COMPANY_NAME: 'Company Name',
  HISTORY: 'Merchant Status History',
  MARCHANT_TYPE: 'Merchant Type',
  PROFILE_PHOTO: 'Profile Photo',
  COMPANY_URL: 'Company Url',
  ADDRESS: 'Address 1',
  REGION: 'State/Province',
  PHONE_NUMBER: 'Primary Phone Number',
  ADDRESS_TWO: 'Address 2',
  COUNTRY: 'Country',
  PHONE_NUMBER_SECONDARY: 'Secondary Phone Number',
  CITY: 'City',
  POST_CODE: 'Zip/Postal Code',
  COMPANY_EMAIL: 'Company Email Address',
  TAXATION: 'Taxation #',
  BILLING_CONTACT: 'Billing Contact',
  GST: 'GST #',
  PST: 'PST #',
  HST: 'HST #',
};

export const TAX_PLACEHOLDER = {
  TAX: '123 456 789',
  HST: '123456789RT0001',
  GST: '123456789RT0001',
  PST: 'PST12345678',
};

export const PLACEHOLDER = {
  BILLING_CONTACT: 'account@merchant.com',
};

export const NEVER = 'Never';

export const NO_ROLE = 'there is no roles in BE';

export const SORT_DEFAULT: TableSortColumn = { column: 'id', direction: 'desc' };

export const ERROR_PREFLEX = 'Oops! Something went wrong,';
export const UPDATE_ERROR = 'Please update the required field and submit again';

export const NETWORK_STATUS = [
  { value: 'Visible', label: 'Visible' },
  { value: 'Invisible', label: 'Invisible' },
];

export const MERCHANT_TYPES = [
  {
    label: 'Self Served',
    value: 'Self',
  },
  {
    label: 'Managed',
    value: 'Managed',
  },
  {
    label: 'Results',
    value: 'Results',
  },
];

export const TABLE_ACTIONS = {
  EDIT: 'EDIT',
  ACTIVE: 'ACTIVE',
  DEACTIVE: 'DEACTIVE',
};

export const STATUS_TYPES = {
  INACTIVE: 'Inactive',
  ACTIVE: 'Active',
};

export const DROPDOWN_LABEL = {
  EDIT: 'Edit',
  INACTIVE: 'Deactivate',
  ACTIVE: 'Reactivate',
};

export const DEACTIVE_MODAL = {
  TITLE: 'Deactivate user',
  DESCRIPTION: 'Are you sure you would like to deactivate this user?',
  SUPER_USER: 'You can not deactivate a super user.',
};
