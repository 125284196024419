import React from 'react';
import * as Styled from './styles';
import { Button, Radio } from '../../../../../../components';
import { ELIGIBILITY_FEEDBACK_ACTIONS, EligibilityFeedbackReducerModalProps } from './reducers';
import { ELIGIBILITY_FEEDBACK_MODAL } from './enums';

type FeedbackModalProps = {
  hook: {
    state: EligibilityFeedbackReducerModalProps;
    dispatch: (type: symbol, data: Record<string, any>) => void;
    modalOpenDispatcher: () => void;
    updateEligibilityHandler: () => void
    updateEligibiltyLoading: boolean
  }
}

export const EligibilityFeedbackModal = ({ hook }: FeedbackModalProps) => (
  <Styled.Wrapper>
    <Styled.TitleStyled>{ELIGIBILITY_FEEDBACK_MODAL.TITLE}</Styled.TitleStyled>

    <p>{ELIGIBILITY_FEEDBACK_MODAL.CONFIMRATION}</p>
    <Styled.RadioWrapper>
      <Radio
        name="correct feedback"
        label="Yes"
        value="check"
        checked={hook.state.correctStatus === true}
        onChange={() => hook.dispatch(ELIGIBILITY_FEEDBACK_ACTIONS.SET_STATUS_CONFIRMATION, { correctStatus: true })}
      />
      <Radio
        name="incorrect feedback"
        label="No"
        value="check"
        checked={hook.state.correctStatus === false}
        onChange={() => hook.dispatch(ELIGIBILITY_FEEDBACK_ACTIONS.SET_STATUS_CONFIRMATION, { correctStatus: false })}
      />
    </Styled.RadioWrapper>

    <Styled.ButtonWrapperStyled>
      <Button
        theme="secondary"
        onClick={hook.modalOpenDispatcher}
      >
        {ELIGIBILITY_FEEDBACK_MODAL.CANCEL}
      </Button>
      <Button
        loading={hook.updateEligibiltyLoading}
        disabled={hook.updateEligibiltyLoading}
        onClick={() => {
          hook.updateEligibilityHandler();
        }}
      >
        {ELIGIBILITY_FEEDBACK_MODAL.SUBMIT}
      </Button>
    </Styled.ButtonWrapperStyled>
  </Styled.Wrapper>
);
