import { gql } from '@apollo/client';

export const LIST_USERS = gql`
  query usersList($input: UsersListInput!) {
    usersList(input: $input) {
      count
      users {
        id
        email
        auth0Id
        firstName
        lastName
        lastLogin
        status
        newRoles {
          id
          name
        }
      }
    }
  }
`;
