import styled, { keyframes } from 'styled-components';
import { colors } from '../../styles/theme';

const elipsi1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const elipsi2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const elipsi3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

export const WrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const EllipsisStyled = styled.div`
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
`;

export const DotsStyled = styled.div`
  position: absolute;
  top: 33px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${colors.color2};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: 8px;
    animation: ${elipsi1} 0.6s infinite;
  }

  &:nth-child(2) {
    left: 8px;
    animation: ${elipsi2} 0.6s infinite;
  }

  &:nth-child(3) {
    left: 32px;
    animation: ${elipsi2} 0.6s infinite;
  }

  &:nth-child(4) {
    left: 56px;
    animation: ${elipsi3} 0.6s infinite;
  }
`;
