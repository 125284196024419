import { gql } from '@apollo/client';

export const GET_FILTERS = gql`
  query ManualBonusFilterOptions {
  manualBonusfiltersV2 {
    merchantOptions {
      companyName
      id
    }
    publisherOptions {
      companyName
      id
    }
  }
}
`;
