export const NEW_TRANSACTION_SETTING = {
  TITLE: 'Add Transaction Setting',
  ERROR: 'A duplicate tracking setting exists. Please set other conditions',
  INTEGRATION: {
    LABEL: 'Type of integration',
    PLACEHOLDER: 'Select a type of integration',
    ERROR: 'Please select a type of integration',
  },
  TRACKING: {
    LABEL: 'Transaction Settings',
    PLACEHOLDER: 'Select a tracking setting',
    ERROR: 'Please select a tracking setting',
  },
  URL: {
    LABEL: 'URL',
    PLACEHOLDER: 'Enter an URL',
    ERROR: 'Please enter an URL',
  },
  BUTTONS: {
    CANCEL: 'Cancel',
    SAVE: 'Add Transaction Setting',
  },
};
