import React from 'react';
import * as Styled from './styles';

type FixedFooterProps = {
  children: React.ReactNode;
  className?: string;
};

export const FixedFooter = ({ children, className }: FixedFooterProps) => (
  <Styled.WrapperStyled className={className}>
    {children}
  </Styled.WrapperStyled>
);
