import React from 'react';
import { chartColors } from '../../../../../../styles/theme';
import * as Styled from './styles';
import { useSnapshot } from './hooks';
import { SnapshotStructureProps } from './types';

type SnapshotProps = {
  data: SnapshotStructureProps
}

export const Snapshot = ({ data }: SnapshotProps) => {
  const hook = useSnapshot();

  const formattedData = {
    labels: hook.hookMonths,
    datasets: [
      {
        label: 'Total Transactions',
        fill: false,
        backgroundColor: chartColors.color4,
        borderColor: chartColors.color4,
        pointBorderColor: chartColors.color4,
        pointHoverBackgroundColor: chartColors.color4,
        pointHoverBorderColor: chartColors.color1,
        data: data.totalTransactions,
        yAxisID: 'transactions',
        tension: 0.3,
      },
      {
        label: 'Approved Transactions',
        fill: false,
        backgroundColor: chartColors.color2,
        borderColor: chartColors.color2,
        pointBorderColor: chartColors.color2,
        pointHoverBackgroundColor: chartColors.color2,
        pointHoverBorderColor: chartColors.color1,
        data: data.approvedTransactions,
        yAxisID: 'transactions',
        tension: 0.3,
      },
      {
        label: 'Total Commissions',
        fill: false,
        backgroundColor: chartColors.color6,
        borderColor: chartColors.color6,
        pointBorderColor: chartColors.color6,
        pointHoverBackgroundColor: chartColors.color6,
        pointHoverBorderColor: chartColors.color1,
        data: data.commissions,
        yAxisID: 'commissions',
        tension: 0.3,
      },
    ],
  };

  return (
    <>
      <Styled.ChartStyled
        data={formattedData}
        height={430}
        width={hook.hookChartSize}
        key={hook.hookChartSize}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: false,
          scales: {
            commissions: {
              grid: {
                display: false,
              },
              min: 0,
              beginAtZero: true,
              type: 'linear',
              position: 'right',
              title: {
                display: true,
                text: 'Commissions',
                font: {
                  size: 12,
                  weight: '500',
                },
              },
              ticks: {
                precision: 0,
              },
            },
            transactions: {
              min: 0,
              beginAtZero: true,
              type: 'linear',
              position: 'left',
              title: {
                display: true,
                text: 'Transactions',
                font: {
                  size: 12,
                  weight: 'bold',
                },
              },
              ticks: {
                precision: 0,
              },
            },
          },
        }}
      />

      <Styled.FooterStyled>
        <Styled.FooterInnerStyled>
          <Styled.CaptionWrapperStyled>
            <Styled.CaptionColorStyled color={chartColors.color4} />
            <Styled.CaptionTextStyled>Total Transactions</Styled.CaptionTextStyled>
          </Styled.CaptionWrapperStyled>
          <Styled.CaptionWrapperStyled>
            <Styled.CaptionColorStyled color={chartColors.color2} />
            <Styled.CaptionTextStyled>Approved Transactions</Styled.CaptionTextStyled>
          </Styled.CaptionWrapperStyled>
          <Styled.CaptionWrapperStyled>
            <Styled.CaptionColorStyled color={chartColors.color6} />
            <Styled.CaptionTextStyled>Total Commissions</Styled.CaptionTextStyled>
          </Styled.CaptionWrapperStyled>
        </Styled.FooterInnerStyled>
      </Styled.FooterStyled>
    </>
  );
};
