import { useQuery } from '@apollo/client';
import { GET_COUNTRIES } from './graphql/queries/getCountries';

export const useCountryRegions = () => {
  const countries: any[] = [];

  const { data, loading } = useQuery(GET_COUNTRIES);

  data?.getCountries.countries.forEach((item: any, index: number) => {
    countries.push({
      label: item.name,
      value: index.toString(),
    });
  });

  return {
    hookOriginalCountryList: data?.getCountries?.countries,
    hookCountriesList: countries,
    hookCountryRegionsLoading: loading,
  };
};
