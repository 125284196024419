// Function from fintel-app for transforming productFeed Names to display
export const toSentenceCase = (str: string) => {
  if (!str) return '';
  if (str === 'APY' || str === 'apyRate') return 'APY';
  if (str === 'APR' || str === 'mortgageAprRate') return 'APR';
  return str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};
