import { gql } from '@apollo/client';

export const UPDATE_TRACKING_SETTING = gql`
  mutation Mutation($input: UpdateTrackingSettingInput) {
    updateTrackingSetting(input: $input) {
      id
      landingPage
      urlQueryString
      thirdPartyUrl
      thirdPartyUrlFormat
      thirdPartyStatus
    }
  }
`;
