import { gql } from '@apollo/client';

export const GET_USER_INFO = gql`
  query getUserInfo($id: ID!) {
    user(id: $id) {
      id
      auth0Id
      email
      firstName
      lastName
      position
      phone
      preferredLanguage
      userType
      roles {
        id
        name
      }
      newRoles {
        id
        name
        oldId
      }
      adminMerchantAccessList {
        id
        companyName
      }
      adminHasAccessAll
    }
  }
`;
