import styled from 'styled-components';
import { Button, Pagination, Table } from '../../../components';
import { toRem } from '../../../utils';
import { fonts } from '../../../styles/theme';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${toRem(24)};
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;

  svg {
    margin-right: ${toRem(10)};
  }
`;

export const ButtonContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${toRem(20)};
`;

export const SearchInputStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H14}
  width: 50%;
  min-width: ${toRem(272)};
  margin-bottom: ${toRem(24)};
`;

export const TableStyled = styled(Table)`
  margin: ${toRem(40)} 0;
`;

export const PaginationStyled = styled(Pagination)``;

export const TableDataStyled = styled.div`
  min-height: 35px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;
