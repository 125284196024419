import styled from 'styled-components';
import { toRem } from '../../../../../utils';
import { fonts, colors } from '../../../../../styles/theme';

type TextStyledProps = {
  readonly theme?: string
}

type CellWrapperStyledProps = {
  readonly theme?: string
}

export const CellStyled = styled.div`
  display: flex;
  align-items: center;
  height: ${toRem(40)};
`;

export const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 24px;

  p {
    margin-left: 10px;
  }
`;

export const DropdownChildStyled = styled.div`
  min-width: 140px;
  padding: 10px 0;
`;

export const CellWrapperStyled = styled.div<CellWrapperStyledProps>`
  height: 100%;
  min-height: ${({ theme }) => (theme === 'checkBox' ? 'auto' : toRem(38))};
  height: ${({ theme }) => (theme === 'checkBox' ? 'auto' : toRem(38))};
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: ${({ theme }) => (theme === 'checkBox' ? 'row' : 'column')};
  text-overflow: ellipsis;
  line-height: 1.5;
`;

export const TextStyled = styled.p<TextStyledProps>`
  ${({ theme }) => (theme === 'main' ? fonts.lato.H14 : fonts.lato.H12)}
  color: ${({ theme }) => (theme === 'main' ? colors.color1 : colors.color13)};
  max-width: 100%;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Regular = styled.div`
  display: flex;
  align-items: center;
`;
