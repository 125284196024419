import { gql } from '@apollo/client';

export const GET_PERMISSIONS_BY_ROLE = gql`
  query PermissionsByIdQuery($roleId: String) {
    permissionsById(roleId: $roleId) {
      id
      name
      appSections {
        name
        appSectionId
        permission
        permissionId
        appSubSections {
          name
          permission
          permissionId
        }
      }
    }
  }
`;
