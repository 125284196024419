import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

import * as Styled from './styles';

type SelectProps = {
  label?: string;
  name?: string;
  selected?: {
    label: string;
    value: string | boolean | number | null | undefined;
  };
  selectedMulti?: {
    label: string;
    value: string;
  }[];
  onChange: (value: any) => void;
  options?: any[];
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  width?: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
  error?: string;
  tooltip?: string;
  description?: string;
  creatable?: boolean;
  onBlur?: () => void;
  menuPlacement?: 'auto' | 'top';
  optionBorder?: boolean;
}

export const Select = ({
  label,
  name,
  selected,
  selectedMulti = [],
  onChange,
  options = [],
  isMulti = false,
  isDisabled = false,
  isLoading = false,
  width = '100%',
  className,
  required = false,
  placeholder,
  error,
  tooltip,
  description,
  creatable = false,
  onBlur,
  menuPlacement = 'auto',
  optionBorder = false,
}: SelectProps) => {
  const [selectedState, setSelectedState] = useState(selected);

  useEffect(() => {
    setSelectedState(selected);
  }, [JSON.stringify(selected)]);

  return (
    <Styled.WrapperStyled
      id={name}
      width={width}
      className={className}
    >
      <Styled.LabelStyled htmlFor={name}>
        {label && (
          <Styled.LabelTextStyled>
            {label}
            {' '}
            {required && <Styled.RequiredStyled>*</Styled.RequiredStyled>}
            {' '}
            {tooltip && <Styled.TooltipStyled text={tooltip} />}
            {' '}
            {isLoading && (
              <Styled.SpinnerStyled
                theme="secondary"
                size=".7rem"
              />
            )}
          </Styled.LabelTextStyled>
        )}

        {description && (
          <Styled.DescriptionStyled>
            {description}
          </Styled.DescriptionStyled>
        )}
        {creatable ? (
          <CreatableSelect
            isDisabled={isDisabled}
            options={options}
            onChange={onChange}
            defaultValue={selected || selectedMulti}
            value={selectedState || selectedMulti}
            styles={Styled.SelectStyles(error)}
            isMulti={isMulti}
            name={name}
            isLoading={isLoading}
            placeholder={placeholder}
            menuPlacement="auto"
            onBlur={onBlur}
          />
        ) : (
          <ReactSelect
            isDisabled={isDisabled}
            options={options}
            onChange={onChange}
            defaultValue={selected || selectedMulti}
            value={selectedState || selectedMulti}
            menuPortalTarget={document.body}
            styles={Styled.SelectStyles(error, optionBorder)}
            isMulti={isMulti}
            name={name}
            isLoading={isLoading}
            placeholder={placeholder}
            menuPlacement={menuPlacement || 'auto'}
            onBlur={onBlur}
          />
        )}

        {error && (
          <Styled.ErrorStyled>{error}</Styled.ErrorStyled>
        )}
      </Styled.LabelStyled>
    </Styled.WrapperStyled>
  );
};
