/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Toggle, Button } from '../../../../components';
import { FormatedCell } from '../components/FormatedCell';
import { COLUMNS_FIELDS } from '../../../../utils';

const statusToBoolean = (value: string) => {
  if (value === 'Active') {
    return true;
  }
  return false;
};

type DeactivationModalFields = {
  hookOnClose: () => void;
  hookOnConfirm: () => void;
  isOpen: boolean;
  handleSetModalOpen: (value: boolean) => void
}

export const columns = (setToggleHandler: (input: any) => void, deactHook: DeactivationModalFields, navigateProfile: (user: string) => void, isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'firstName',
    text: 'Full Name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any, row: any) => (
      <FormatedCell
        value={`${value.toLowerCase()} ${row.lastName.toLowerCase()}`}
        secondValue={row.email}
      />
    ),
    width: '25%',
  },
  {
    dataField: 'roleNames',
    text: 'Security Role',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => {
      let text = '';
      row.newRoles.forEach((element: any, count: number) => {
        if (count > 0) text += ', ';
        text += element.name;
      });
      return <FormatedCell value={text} />;
    },
    width: '25%',
  },
  {
    dataField: 'lastLogin',
    text: 'Last Login',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <FormatedCell
        value={value === null ? COLUMNS_FIELDS[8].value : new Date(value).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}
      />
    ),
    width: '20%',
  },
  {
    dataField: 'status',
    text: 'Status',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any, row: any) => (
      <FormatedCell
        theme={value === 'Inactive' ? 'ToggleColumn2' : 'ToggleColumn'}
        value={value}
      >
        <Toggle
          checked={statusToBoolean(value)}
          onChange={() => setToggleHandler(row)}
          disabled={isReadOnly}
        />
      </FormatedCell>

    ),
    width: '20%',
  },
  {
    dataField: 'id',
    text: '',
    formatter: (value: any, row: any) => (
      <FormatedCell
        theme=""
        value=""
      >
        <Button
          onClick={() => navigateProfile(value)}
          theme="quaternary"
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
      </FormatedCell>

    ),
    width: '10%',
  },
];
