import { gql } from '@apollo/client';

export const SAVE_TRANSACTION_MASTER_REPORT = gql`
  mutation createUpdateReportProfile($save: ReportProfileInput) {
    createUpdateReportProfile(input: $save) {
      id
    }
  }
`;

export type STMRInputType = {
  save: {
    report: 'Transaction Master',
    filters: {
      field: string
      value: {
        label: string
        value: string
      }
    }[],
    columns: SelectOption[],
    user: {
      id: string,
      email: string,
    },
    name: string,
    description: string,
    company?: number,
    frequency?: string,
    every?: string,
    id?: string,
  }
}

export type STMROutputType = {
  createUpdateReportProfile: {
    id: string
  }
};
