import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../../styles/theme';
import { useFileUpload } from './hooks';

import * as Styled from './styles';

type FileUploadProps = {
  required?: boolean;
  label?: string;
  className?: string;
  theme?: Theme;
  disabled?: boolean;
  buttonText?: string;
  info?: string;
  error?: string;
  multiple?: boolean;
  accept?: string;
  name?: string;
  width?: string;
  maxFileSize?: number;
  onChange: (files: File | FileList) => void;
  fileSizeUnit?: 'KB' | 'MB';
  type?: string;
}

export const FileUpload = ({
  theme = 'primary',
  label,
  required = false,
  disabled = false,
  className,
  buttonText = 'Choose File',
  info,
  error,
  multiple = false,
  accept = '*',
  name = 'file',
  width = 'auto',
  onChange,
  maxFileSize = 1000,
  fileSizeUnit = 'KB',
  type,
}: FileUploadProps) => {
  const { hookSetFile, hookFileName, hookError } = useFileUpload(maxFileSize, type, fileSizeUnit);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    hookSetFile(e, onChange);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    hookSetFile(e, onChange, e.dataTransfer);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  return (
    <Styled.WrapperStyled
      className={className}
      disabled={disabled}
      width={width}
    >
      {label && (
        <Styled.LabelStyled>
          {label}
          {' '}
          {required && <Styled.RequiredStyled>*</Styled.RequiredStyled>}
        </Styled.LabelStyled>
      )}

      <Styled.ButtonWrapperStyled
        error={!!error || !!hookError}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <Styled.ButtonStyled theme={theme}>
          {buttonText}
        </Styled.ButtonStyled>

        <Styled.FakeInputStyled>
          {hookFileName}
        </Styled.FakeInputStyled>
        {!!error && (
          <FontAwesomeIcon
            icon={faTimes}
            style={{ color: colors.color14 }}
          />
        )}
      </Styled.ButtonWrapperStyled>

      {info && (
        <Styled.InfoStyled>
          {info}
        </Styled.InfoStyled>
      )}

      {(error || hookError) && (
        <Styled.ErrorStyled>
          {error || hookError}
        </Styled.ErrorStyled>
      )}

      <Styled.InputStyled
        type="file"
        disabled={disabled}
        onChange={handleChange}
        multiple={multiple}
        accept={accept}
        name={name}
      />
    </Styled.WrapperStyled>
  );
};
