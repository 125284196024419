import { gql } from '@apollo/client';

export const GET_PUBLISHER_INVITATIONS = gql`
query publisherInvitations($input: PublisherInvitationInput!) {
  publisherInvitations(input: $input) {
    id
    merchantId
    name
    description
    subject
    message
    template
  }
}
`;
