import React from 'react';
import { faFileArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Button, FixedFooter, InputText, Pagination, Table,
} from '../../../../../../components';
import { URLS_LIST } from './enums';
import * as Styled from './styles';
import { useUrlsList } from './hooks';
import UrlModal from './Modal';
import { columns } from './contracts';

type FintelCheckUrlsListProps = {
  isReadOnly: boolean;
}

const FintelCheckUrlsList = ({ isReadOnly }: FintelCheckUrlsListProps) => {
  const hook = useUrlsList(isReadOnly);

  return (
    <>
      <Styled.HeaderStyled>
        <Styled.FilterWrapperStyled>
          <InputText
            faIcon={faSearch}
            type="url"
            placeholder={URLS_LIST.PLACEHOLDERS.INPUT}
            value={hook.hookSearch}
            onChange={hook.hookSetSearch}
          />
          <Styled.ButtonStyled
            theme="text-only"
            onClick={hook.hookClearSearch}
          >
            {URLS_LIST.BUTTONS.CLEAR}
          </Styled.ButtonStyled>
        </Styled.FilterWrapperStyled>

        <Styled.ButtonsWrapperStyled>
          <Styled.ButtonStyled
            onClick={() => {
              hook.hookSetIsOpen();
              hook.hookSetModalInfo({ type: URLS_LIST.MODAL.TYPES.ADD });
            }}
            disabled={isReadOnly}
          >
            {URLS_LIST.BUTTONS.ADD}
          </Styled.ButtonStyled>
          <Styled.ButtonStyled
            theme="tertiary"
            onClick={hook.hookDownload}
            loading={hook.hookCSVLoading}
            disabled={hook.hookCSVLoading}
          >
            <Styled.IconStyled icon={faFileArrowDown} />
            {URLS_LIST.BUTTONS.DOWNLOAD}
          </Styled.ButtonStyled>
        </Styled.ButtonsWrapperStyled>
      </Styled.HeaderStyled>
      <Table
        columns={columns(isReadOnly)}
        data={hook.hookList}
        onSort={hook.hookHandleSort}
        setOpenModal={hook.hookSetIsOpen}
        setModalInfo={hook.hookSetModalInfo}
        sortColumn={hook.hookSortColumn}
        onRowClick={hook.hookOnRowClick}
      />
      <Styled.PaginationWrapper>
        <Pagination
          currentPage={hook.hookCurrentPage}
          total={hook.hookTotalPages}
          onPageChange={hook.hookOnPageChange}
        />
      </Styled.PaginationWrapper>
      <FixedFooter>
        <Button
          theme="tertiary"
          onClick={hook.hookRemoveButton}
          disabled={hook.hookList.filter((item) => item.checked).length < 1 || isReadOnly}
        >
          {URLS_LIST.BUTTONS.REMOVE}
        </Button>
      </FixedFooter>

      <UrlModal hook={hook} />
    </>
  );
};

export default FintelCheckUrlsList;
