import styled from 'styled-components';
import { Button, InputText } from '../../../../../../../components';
import { colors, fonts } from '../../../../../../../styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 34rem;
  padding: 1rem;
`;

export const TitleStyled = styled.p`
  color: ${colors.color1};
  ${fonts.gotham.H26M}
`;

export const InputStyled = styled(InputText)`
  margin: 2rem 0;
`;

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  white-space: nowrap;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1.5rem;
`;

export const RemovePromptStyled = styled.div`
  margin: 24px 0px;
  line-height: 1.5rem;
`;

export const BoldText = styled.span`
  ${fonts.lato.H16B}
`;
export const BoldTextDiv = styled.div`
  ${fonts.lato.H16B}
`;

export const UrlWrapper = styled.div`
  padding: 0.5rem 0;
`;
