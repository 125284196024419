import React from 'react';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../../../../components';
import {
  BUTTON_LABELS, MODAL,
} from '../../contracts';
import * as Styled from './styles';

type PayPalDepositFailProps = {
  cancelHandler: () => void
  retryHandler: () => void
}

const PayPalDepositFail = ({
  cancelHandler, retryHandler,
}: PayPalDepositFailProps) => (
  <Styled.WrapperStyled>
    <Styled.IconStyled icon={faMinusCircle} />
    <div>
      <Styled.TitleStyled>{MODAL.PAYPAL_FAILED.TITLE}</Styled.TitleStyled>
    </div>
    <Styled.DescriptionStyled>{MODAL.PAYPAL_FAILED.DESCRIPTION}</Styled.DescriptionStyled>

    <Styled.ButtonAreaStyled>
      <Styled.ButtonWrapperStyled>
        <Button
          theme="secondary"
          onClick={cancelHandler}
        >
          {BUTTON_LABELS.CANCEL}
        </Button>
      </Styled.ButtonWrapperStyled>

      <Button
        onClick={retryHandler}
      >
        {BUTTON_LABELS.TRY_AGAIN}
      </Button>
    </Styled.ButtonAreaStyled>

  </Styled.WrapperStyled>
);

export default PayPalDepositFail;
