import styled from 'styled-components';
import { Button, Select } from '../../../components';
import { colors, fonts } from '../../../styles/theme';

type AdvanceFiltersProps = {
  readonly isAdvance: boolean;
}

type DefaultFilterProps = {
  readonly isPublisher: boolean;
}

export const SelectStyled = styled(Select)`
  &:first-of-type {
    margin-right: 2rem;
  }
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

export const HeaderButtonsAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    margin-right: 0.5rem;
  }
`;

export const DefaultFiltersStyled = styled.div<DefaultFilterProps>`
  display: grid;
  grid-template-columns: ${({ isPublisher }) => (isPublisher ? '2fr' : '1fr 1fr')} 1fr;
  column-gap: ${({ isPublisher }) => (isPublisher ? '1rem' : '2rem')};
  row-gap: 1.5rem;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
`;

export const FiltersAdvancedStyled = styled.div<DefaultFilterProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  padding-left: ${({ isPublisher }) => (isPublisher ? '1.1rem' : '0')};

  span:nth-child(2) {
    ${fonts.lato.H16};
    color: ${colors.color1};
  }
  button {
    ${fonts.lato.H16}
    padding-right: 0;
  }
`;

export const FiltersStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const OptionsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem;
`;

export const AdvanceFiltersStyled = styled.div<AdvanceFiltersProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  width: 100%;
  height: ${({ isAdvance }) => (isAdvance ? 'auto' : '0')};
  margin-top: 2rem;
`;

export const RecordsDisplayStyled = styled.span`
  ${fonts.gotham.H22B}
  color: ${colors.color1};
  width: 32%;
`;

export const SortAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 32%;

  p {
    margin-right: 1rem;
  }
`;

export const SearchRowStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;
