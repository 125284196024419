import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown, faCircleExclamation, faPlus, faSearch,
} from '@fortawesome/free-solid-svg-icons';

import {
  AppWrapper, Button, Calendar, DragTable, ErrorBanner, InputText, PageTitle, Pagination, RecordsDropdown, ReportPlaceholder, Select,
} from '../../../components';
import { InputCalendar } from '../../../components/Input/Calendar';
import {
  PAGE_TYPE, path, RECORDS_PER_PAGE_OPTIONS_WITH_150, USER_TYPES_ID,
} from '../../../utils';
import { useLinkManager } from './hooks';
import { useUserInfo } from '../../../hooks';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type LinkManagerPropsType = DefaultPropsType

const LinkManager = ({ permissionsCodeList = [] }: LinkManagerPropsType) => {
  const hook = useLinkManager();
  const userHook = useUserInfo();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        message={hook.hookErrorMessage}
        isOpen={!!hook.hookErrorMessage}
      />
      <Styled.HeaderStyled>
        <PageTitle
          isLoading={hook.hookPageLoading}
          loadingText="Loading"
        >
          {path.linkManager.name}
        </PageTitle>
      </Styled.HeaderStyled>

      <Styled.FiltersStyled>
        <InputCalendar
          label="Date Range"
          value={hook.hookSelectDate}
          width="100%"
          onClick={hook.hookSetOpenCalendar}
          isDisabled={hook.hookPageLoading}
        />
        {/* Remove field while acting as merchant */}
        {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.MERCHANT && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT && (
        <Select
          label="Merchant ID - Name"
          name="merchantSelect"
          onChange={hook.hookSetMerchant}
          options={hook.hookMerchantList}
          isDisabled={hook.hookPageLoading}
          selected={hook.hookMerchant}
          width="100%"
        />
        )}
        {/* Remove field while acting as publisher */}
        {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER && (
          <Select
            label="Publisher ID - Name"
            name="publisherSelect"
            onChange={hook.hookSetPublisher}
            selected={hook.hookPublisher}
            options={hook.hookPublisherList}
            isDisabled={hook.hookPageLoading || hook.hookDisabledFields}
            isLoading={hook.hookMerchantInfoLoading}
            width="100%"
          />
        )}
        <Select
          label="Product ID - Name"
          name="productSelect"
          onChange={hook.hookSetProduct}
          options={hook.hookProductList}
          selected={hook.hookProduct}
          isDisabled={hook.hookPageLoading || hook.hookDisabledFields}
          isLoading={hook.hookMerchantInfoLoading}
          width="100%"
        />
        <Select
          label="Ad Id"
          name="adId"
          onChange={hook.hookSetAd}
          options={hook.hookAdList}
          selected={hook.hookAd}
          isDisabled={hook.hookPageLoading || hook.hookDisabledFields}
          isLoading={hook.hookMerchantInfoLoading}
          width="100%"
        />
        <Select
          label="Page Type"
          name="pageType"
          onChange={hook.hookSetPageType}
          options={PAGE_TYPE}
          selected={hook.hookPageType}
          width="100%"
          isDisabled={hook.hookGenerateReportLoading}
        />
        <Select
          label="Tracking Profile"
          name="trackingProfile"
          onChange={hook.hookSetTrackingProfile}
          options={hook.hookTrackingProfileList}
          selected={hook.hookTrackingProfile}
          isDisabled={hook.hookPageLoading || hook.hookDisabledFields || !hook.hookTrackingProfileActive}
          isLoading={hook.hookMerchantInfoLoading || hook.hookTrackingProfileLoading}
          width="100%"
        />
        <InputText
          faIcon={faSearch}
          type="text"
          label="URL Search"
          name="search"
          placeholder="Search for Referral Page URL"
          onChange={hook.hookSetSearch}
          value={hook.hookSearch}
          width="100%"
          disabled={hook.hookGenerateReportLoading}
        />

        <Styled.ClearFormStyled>
          <Button
            theme="text-only"
            onClick={hook.hookClearForm}
          >
            Clear Form
          </Button>
        </Styled.ClearFormStyled>
      </Styled.FiltersStyled>

      <Styled.OptionsRowStyled>
        <Styled.LeftStyled>
          <RecordsDropdown
            onChange={hook.hookSetRecords}
            selected={hook.hookRecords}
            options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
            currentPage={hook.hookCurrentPage}
            totalRecords={hook.hookTotalRecords}
            isDisabled={hook.hookPageLoading || hook.hookGenerateReportLoading}
          />
        </Styled.LeftStyled>

        <Styled.RightStyled>
          <Styled.MaxDownloadStyled>
            <FontAwesomeIcon icon={faCircleExclamation} />
            {' '}
            Maximum download size: 3000
          </Styled.MaxDownloadStyled>

          <Styled.ButtonStyled
            theme="tertiary"
            onClick={hook.hookGetCSVHandler}
            loading={hook.hookPageLoading || hook.hookGenerateReportLoading || hook.hookCSVLoading}
            disabled={hook.hookPageLoading || hook.hookGenerateReportLoading}
            width="148px"
          >
            <FontAwesomeIcon
              icon={faArrowDown}
              style={{ marginRight: 5 }}
            />
            {' '}
            Download CSV
          </Styled.ButtonStyled>

          <Styled.ButtonStyled
            onClick={hook.hookGenerateReport}
            loading={hook.hookPageLoading || hook.hookGenerateReportLoading}
            disabled={hook.hookPageLoading || hook.hookGenerateReportLoading}
            width="154px"
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{ marginRight: 5 }}
            />
            {' '}
            Generate Report
          </Styled.ButtonStyled>
        </Styled.RightStyled>
      </Styled.OptionsRowStyled>

      {((hook.hookTableData && hook?.hookTableData?.length > 0) || (hook.hookGenerateReportLoading && hook.hookIsGenerated)) && (
      <>
        <DragTable
          columns={hook.hookTableColumns}
          data={hook.hookTableData ?? []}
          isLoading={hook.hookGenerateReportLoading}
          onSort={hook.hookSortTableHandler}
          sortColumn={hook.hookSortColumn}
          onDragEnd={hook.hookSetOnDragEnd}
        />
        <Pagination
          total={hook.hookTotalPages}
          currentPage={hook.hookCurrentPage}
          onPageChange={hook.hookSetCurrentPage}
        />
      </>
      )}

      {((!hook.hookTableData || !hook.hookTableData.length) && !(hook.hookGenerateReportLoading && hook.hookIsGenerated)) && (
        <ReportPlaceholder
          display={hook.hookIsGenerated ? 'not-found' : 'wait'}
          text={hook.hookIsGenerated ? 'Sorry! No results found' : 'Click generate reports to get started'}
          subtext={hook.hookIsGenerated ? "We couldn't find what you're looking for. Please try it again." : ''}
        />
      )}

      <Calendar
        date={hook.hookStartDate}
        onApply={hook.hookOnApplyCalendar}
        onCancel={hook.hookOnCancelCalendar}
        isOpen={hook.hookOpenCalendar}
        endDate={hook.hookEndDate}
        hasRange
      />
    </AppWrapper>
  );
};

export default LinkManager;
