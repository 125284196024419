import React from 'react';
import * as Styled from '../styled';
import { CellProps } from '../types';

export const MultiLineCell = ({ val, disabled = false }: CellProps) => {
  const primary = val.name || val.country;
  const secondary = val.website || val.ip;

  return (
    <Styled.CellStyled>
      <Styled.MainValStyled>{primary}</Styled.MainValStyled>
      <Styled.SecondaryValStyled disabled={disabled}>{secondary}</Styled.SecondaryValStyled>
    </Styled.CellStyled>
  );
};
