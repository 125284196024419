export const PAYPAL = {
  CALENDER_LABEL: 'Date Range',
  STATUS: {
    LABEL: 'Status',
    NAME: 'paymentStatus',
  },
  CLEAR_FORM: {
    THEME: 'text-only',
    TEXT: 'Clear Form',
  },
  DOWNLOAD_BUTTON: {
    TEXT: 'Download CSV',
    THEME: 'tertiary',
  },
  NO_RECORDS: {
    NO_TEXT: 'No records found',
    SHOW_UP: 'A PayPal order report will show up here once you deposit values into your account using PayPal',
    PENDING: 'See pending payments list',
  },
};
