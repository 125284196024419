import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_NOTIFICATIONS } from '../graphql/queries/getNotifications';
import type { GetNotificationsInput, GetNotificationsOutput, GNNotificationType } from '../graphql/queries/getNotifications';
import { context } from '../../../components/Notifications/context/notificationsContext';

const ROWS_PER_PAGE = 10;

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<GNNotificationType[]>([]);
  const [numberOfUnread, setNumberOfUnread] = useState<number>(0);
  const [tableData, setTableData] = useState<GNNotificationType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const { data: notificationsData, loading: notificationsLoading } = useQuery<GetNotificationsOutput, GetNotificationsInput>(GET_NOTIFICATIONS, {
    fetchPolicy: 'no-cache',
    onError() {
      return undefined;
    },
  });
  const { updateNotificationHandler } = useContext(context);

  const updateNotificationHandlerWrapper = async (row: GNNotificationType) => {
    if (row.read) return;
    const data = await updateNotificationHandler(row.id, false);

    if (data && data.updateNotification) {
      const notificationCopy = notifications.map((note) => {
        if (note.id === row.id) {
          return ({
            ...note,
            read: true,
          });
        }
        return note;
      });
      setNotifications(notificationCopy);
      setTableData(notificationCopy.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE));
    }
  };

  const setPageHandler = (newPage: number) => {
    setTableData(notifications.slice((newPage - 1) * ROWS_PER_PAGE, newPage * ROWS_PER_PAGE));
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (notificationsData && !notificationsLoading) {
      if (notificationsData.notifications) {
        setNumberOfUnread(notificationsData.notifications.numberOfUnread || 0);
        setNotifications(notificationsData.notifications.notifications || []);
        setTableData((notificationsData.notifications.notifications || []).slice(0, ROWS_PER_PAGE));
        setTotalPages(Math.ceil((notificationsData.notifications.notifications || []).length / ROWS_PER_PAGE));
      }
    }
  }, [notificationsData]);

  return {
    hookNotifications: notifications,
    hookNumberOfUnread: numberOfUnread,
    hookTableData: tableData,
    hookCurrentPage: currentPage,
    hookSetPage: setPageHandler,
    hookTotalPages: totalPages,
    hookNotificationsLoading: notificationsLoading,
    hookUpdateNotificationHandler: updateNotificationHandlerWrapper,
  };
};
