import React from 'react';
import * as Styled from './styles';
import { FILTER_SETTINGS } from './enums';
import { Button } from '../../../../components';
import { MODAL_KEYS } from '../reducers';
import { dateFormatter } from '../../../../utils';

type ModalProps = {
  info: Record<string, any>
  setOpenModal: (value: Record<string, any>) => void
  reportType: string
}

const sortFunction = (reportType: string) => {
  if (reportType === 'Transaction Master') {
    return (a: any, b: any) => (FILTER_SETTINGS.TRANSACTION_FILTER_OPTIONS.findIndex((opt) => opt === a.field) > FILTER_SETTINGS.TRANSACTION_FILTER_OPTIONS.findIndex((opt) => opt === b.field) ? 1 : -1);
  }
  return (a: any, b: any) => (FILTER_SETTINGS.PERFORMANCE_FILTER_OPTIONS.findIndex((opt) => opt === a.field) > FILTER_SETTINGS.PERFORMANCE_FILTER_OPTIONS.findIndex((opt) => opt === b.field) ? 1 : -1);
};

export const FilterSettingsModal = ({ info, setOpenModal, reportType }: ModalProps) => {
  const sorter = sortFunction(reportType);
  const filters = (info.filters || []).slice();

  return (
    <Styled.WrapperStyled>
      <Styled.TitleStyled>
        {FILTER_SETTINGS.TITLE}
      </Styled.TitleStyled>
      <Styled.FiltersWrapper>
        {filters.sort(sorter).map((filter: any) => {
          if (FILTER_SETTINGS.FILTER_TEXT_FORMATTED[filter.field as keyof typeof FILTER_SETTINGS.FILTER_TEXT_FORMATTED] !== undefined) {
            if (filter.field === FILTER_SETTINGS.VALIDATION.DATE_RANGE) {
              return (
                <Styled.InputStyled
                  key={filter.field}
                  type="text"
                  theme="readonlyTernary"
                  label={FILTER_SETTINGS.FILTER_TEXT_FORMATTED[filter.field as keyof typeof FILTER_SETTINGS.FILTER_TEXT_FORMATTED]}
                  value={FILTER_SETTINGS.DATE_RANGE_FORMATTED[filter.value.value as keyof typeof FILTER_SETTINGS.DATE_RANGE_FORMATTED]}
                  readonly
                />
              );
            }
            if (filter.field === FILTER_SETTINGS.VALIDATION.START_DATE || filter.field === FILTER_SETTINGS.VALIDATION.END_DATE) {
              const date = new Date(filter.value.value);
              return (
                <Styled.InputStyled
                  key={filter.field}
                  type="text"
                  theme="readonlyTernary"
                  label={FILTER_SETTINGS.FILTER_TEXT_FORMATTED[filter.field as keyof typeof FILTER_SETTINGS.FILTER_TEXT_FORMATTED]}
                  value={dateFormatter(date)}
                  readonly
                />
              );
            }
            return (
              <Styled.InputStyled
                key={filter.field}
                type="text"
                theme="readonlyTernary"
                label={FILTER_SETTINGS.FILTER_TEXT_FORMATTED[filter.field as keyof typeof FILTER_SETTINGS.FILTER_TEXT_FORMATTED]}
                value={filter.field === 'search' ? filter.value.value : filter.value.label}
                readonly
              />
            );
          }
        })}
      </Styled.FiltersWrapper>
      <Styled.ColumnsTitleStyled>
        {FILTER_SETTINGS.COLUMN_TITLE}
      </Styled.ColumnsTitleStyled>
      <Styled.ColumnsWrapper>
        {info.columns?.map((column: any) => (
          <li key={column.label}>{column.label}</li>
        ))}
      </Styled.ColumnsWrapper>
      <Styled.ButtonWrapper>
        <Button
          theme="secondary"
          onClick={() => {
            setOpenModal({
              [MODAL_KEYS.openSettingModal]: false,
            });
          }}
        >
          {FILTER_SETTINGS.CLOSE}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.WrapperStyled>
  );
};
