import React from 'react';
import { faChevronRight, faFile, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styled from '../styles';
import { TABLE_FOOTER } from './enum';
import { Button } from '../../../../../components';
import { dateFormatter, formateDollarAmount, monthNames } from '../../../../../utils';

export const accountBalanceStatsColumns: TableColumn[] = [
  {
    dataField: 'productCategory',
    text: 'Category',
    width: '325px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'systemUserRate',
    text: 'System Usage Rate',
    width: '325px',
    formatter: (val: string[]) => (
      <Styled.TableDataStyled>
        {val?.map((v: string) => (
          <div key={v}>
            {v}
          </div>
        ))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'minUsageFee',
    text: 'Min Usage Fee',
    width: '325px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'minBalanceRequired',
    text: 'Min Balance',
    width: '325px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'salesTaxRate',
    text: 'Sales Tax',
    width: '325px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'managementFee',
    text: 'Management Fee',
    width: '325px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
  },
];

export const accountBalanceStatsOriginalColumns: TableColumn[] = [
  {
    dataField: 'adminRate',
    text: 'System Usage Rate',
    width: '300px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {val ? `${val}%` : ''}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'minUsageFee',
    text: 'Min Usage Fee',
    width: '360px',
    formatter: (val: string, row: any) => (
      <Styled.TableDataStyled>
        <div>
          {formateDollarAmount(parseFloat(val))}
          <p>
            {row.validStartDate && dateFormatter(new Date(row.validStartDate))}
            {' '}
            -
            {' '}
            {row.validUntilDate && dateFormatter(new Date(row.validUntilDate))}
          </p>
        </div>
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'minBalanceRequired',
    text: 'Min Balance',
    width: '320px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'salesTaxRate',
    text: 'Sales Tax',
    width: '325px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'managementFee',
    text: 'Management Fee',
    width: '330px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
  },
];

export const monthlyAccountBalanceColumns = (handleNavigation: (row: any) => void, handleDetailsNavigation: (row: any) => void) => [
  {
    dataField: 'year',
    text: 'Period',
    formatter: (val: string[], row: any) => (
      <Styled.TableDataStyled
        theme="row"
        isWarning
      >
        {(parseFloat(row.balance) < parseFloat(row.minBalanceRequired)) && (
          <FontAwesomeIcon icon={faTriangleExclamation} />
        )}
        {`${monthNames[Number(row.month) - 1]} / ${val}`}
      </Styled.TableDataStyled>
    ),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '120px',
    footerFormatter: () => (
      <Styled.TableFooterStyled>
        {TABLE_FOOTER}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'productCategory',
    text: 'Category',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '120px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
    footerFormatter: () => (
      <Styled.TableFooterStyled />
    ),
  },
  {
    dataField: 'rollover',
    text: 'Roll Over',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '130px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.rollover))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'deposits',
    text: 'Deposits',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '140px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.deposits))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'totalCommissions',
    text: 'Commission',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '140px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.commissions))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'manualAdjustment',
    text: 'Manual Adjustments',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '140px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.manualAdjustments))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'systemUsageFee',
    text: 'System Usage Fee',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '140px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.systemFee))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'otherFee',
    text: 'Other Fees',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '180px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.otherFees))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'totalTax',
    text: 'Taxes',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '180px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.taxes))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'balance',
    text: 'Balance',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '180px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.balance))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'finalized',
    text: 'Statement',
    formatter: (val: string, row: any) => (
      <Styled.TableDataStyled theme="rowSecondary">
        {row.finalized ? (
          <Button
            onClick={() => handleNavigation(row)}
            theme="quaternary"
          >
            <FontAwesomeIcon icon={faFile} />
          </Button>
        ) : (
          <div />
        )}
        <Button
          onClick={() => handleDetailsNavigation(row)}
          theme="quaternary"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </Styled.TableDataStyled>
    ),
    notClickable: true,
    width: '140px',
    footerFormatter: () => (
      <Styled.TableFooterStyled />
    ),
  },
];

export const monthlyAccountBalanceOriginalColumns = (handleNavigation: (row: any) => void, handleDetailsNavigation: (row: any) => void) => [
  {
    dataField: 'year',
    text: 'Period',
    formatter: (val: string[], row: any) => (
      <Styled.TableDataStyled
        theme="row"
        isWarning
      >
        {(parseFloat(row.balance) < parseFloat(row.minBalanceRequired)) && (
          <FontAwesomeIcon icon={faTriangleExclamation} />
        )}
        {`${monthNames[Number(row.month) - 1]} / ${val}`}
      </Styled.TableDataStyled>
    ),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '200px',
    footerFormatter: () => (
      <Styled.TableFooterStyled>
        {TABLE_FOOTER}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'merchant',
    text: 'merchant',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
    footerFormatter: () => (
      <Styled.TableFooterStyled />
    ),
  },
  {
    dataField: 'rollover',
    text: 'Roll Over',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '150px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.rollover))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'deposits',
    text: 'Deposits',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.deposits))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'totalCommissions',
    text: 'Commission',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '12%',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.commissions))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'manualAdjustment',
    text: 'Manual Adjustments',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '200px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.manualAdjustments))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'systemUsageFee',
    text: 'System Usage Fee',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '6%',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.systemFee))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'otherFee',
    text: 'Other Fees',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '170px',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.otherFees))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'totalTax',
    text: 'Taxes',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '6%',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.taxes))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'balance',
    text: 'Balance',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.balance))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'finalized',
    text: 'Statement',
    formatter: (val: string, row: any) => (
      <Styled.TableDataStyled theme="rowSecondary">
        {row.finalized ? (
          <Button
            onClick={() => handleNavigation(row)}
            theme="quaternary"
          >
            <FontAwesomeIcon icon={faFile} />
          </Button>
        ) : (
          <div />
        )}

        <Button
          onClick={() => handleDetailsNavigation(row)}
          theme="quaternary"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </Styled.TableDataStyled>
    ),
    width: '8%',
    notClickable: true,
    footerFormatter: () => (
      <Styled.TableFooterStyled />
    ),
  },
];

export const paypalColumns: TableColumn[] = [
  {
    dataField: 'depositId',
    text: 'deposit id',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
    width: '200px',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: 'productCategory',
    text: 'category',
    width: '200px',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: 'amount',
    text: 'deposit amount',
    width: '200px',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: 'currency',
    text: 'curreny',
    width: '180px',
  },
  {
    dataField: 'status',
    text: 'status',
    width: '250px',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: 'createdAt',
    text: 'created at',
    width: '200px',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {dateFormatter(new Date(val))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'updatedAt',
    text: 'updated at',
    width: '200px',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {dateFormatter(new Date(val))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'notes',
    text: 'note',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
    width: '200px',
  },
];

export const paypalOriginalColumns: TableColumn[] = [
  {
    dataField: 'depositId',
    text: 'deposit id',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
    width: '200px',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: 'amount',
    text: 'deposit amount',
    width: '200px',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: 'currency',
    text: 'curreny',
    width: '200px',
  },
  {
    dataField: 'status',
    text: 'status',
    width: '200px',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: 'createdAt',
    text: 'created at',
    width: '200px',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {dateFormatter(new Date(val))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'updatedAt',
    text: 'updated at',
    width: '200px',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {dateFormatter(new Date(val))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'notes',
    text: 'note',
    formatter: (val: string) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
    width: '200px',
  },
];
