import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { colors, fonts } from '../../../../../styles/theme';
import { Spinner } from '../../../../../components';

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CountryWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const CountryStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H14}
`;

export const IconStyled = styled(FontAwesomeIcon)`
  color: ${colors.color2};
  margin-right: 5px;
`;

export const StatisticsStyled = styled.span`
  ${fonts.lato.H14}
  margin-top: 1.5rem;
`;

export const SpinnerStyled = styled(Spinner)`
  margin: 4rem;
`;
