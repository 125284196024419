import { gql } from '@apollo/client';

export const TRANSACTION_MASTER_REPORT = gql`
  query TransactionMaster($input: TransactionSearchInput!) {
    transactionMaster(input: $input) {
      transactions {
        acid
        ad {
          adName
          id
        }
        clickId
        commissionIds
        commissionAmount
        commissionTypes
        createdAt
        currency
        customerId
        fintelTag
        firstTransaction
        geoIp {
          city
          country
          region
        }
        grossSale
        id
        merchant {
          companyName
          id
        }
        netSale
        note
        orderId
        processedBy {
          firstName
          lastName
        }
        product {
          id
          name
          productCategory
          thirdPartyUrl
        }
        publisher {
          companyName
          id
        }
        referralUrl
        saleDate
        systemUsageFee
        trackingProfile {
          id
          primaryWebsite
        }
        transactionStatus
        userAgent
        program {
          id
        }
      }
      limit
      totalPages
      currentPage
      count
      calculatedTotal {
        currency
        totalCommission
        totalGrossSale
        totalNetSale
      }
    }
  }
`;
