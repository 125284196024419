import styled from 'styled-components';
import { InputTextarea } from '../../../Input';

type AdAreaStyled = {
  readonly previewType?: number
}

export const ContentStyled = styled.div<AdAreaStyled>`
  display: grid;
  width: 850px;
  grid-template-columns: ${({ previewType }) => (previewType === 1 ? 'repeat(3, 1fr)' : 'auto 1fr 1fr')};
  grid-template-rows: auto;
  grid-gap: 1rem;
  padding-top: 2rem;
`;

export const FirstPosition = styled(InputTextarea)<AdAreaStyled>`
  grid-column: ${({ previewType }) => (previewType === 1 ? '1' : '2')};
`;

export const Input2Column = styled(InputTextarea)<AdAreaStyled>`
  grid-column: ${({ previewType }) => (previewType === 1 ? 'span 2' : 'span 1')};
  word-break: break-word;
`;

export const AdAreaStyled = styled.div<AdAreaStyled>`
  grid-column: ${({ previewType }) => (previewType === 1 ? 'span 3' : 'span 1')};
  grid-row: ${({ previewType }) => (previewType === 1 ? 'span 1' : 'span 6')};
  margin-bottom: 2rem;
  display: flex;
  img {
    object-fit: contain;
  }
`;

export const FooterAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column: span 4;
  margin-top: 2rem;
`;
