import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import {
  AppWrapper, Button, PageTitle, Tabs,
} from '../../../../components';
import TrackingSettings from './Tabs/TrackingSettings';
import TransactionSettings from './Tabs/TransactionSettings';
import { useManageSettings } from './hooks';
import { MANAGE_SETTINGS } from './enum';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';
import { useConfirmationModal } from '../../../../components/Modal/ConfirmationModal/hooks';
import { ConfirmationModal } from '../../../../components/Modal/ConfirmationModal';

type ManageTrackingTransactionSettingsProps = DefaultPropsType;

const ManageTrackingTransactionSettings = ({ permissionsCodeList = [] }: ManageTrackingTransactionSettingsProps) => {
  const navigate = useNavigate();
  const { hookIsReadOnlyList, ...hook } = useManageSettings(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(true);

  /* Hook used to manage states from Confirmation Modal */
  const saveChangesModalHook = useConfirmationModal();

  /**
   * Handle save changes click and close confirmation modal
   */
  const handleOnSaveChanges = async () => {
    await hook.hookSaveChanges();
    saveChangesModalHook.hookSetIsOpen(false);
  };

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>

      {useMemo(() => (
        <ConfirmationModal
          isOpen={saveChangesModalHook.hookIsOpen}
          title={MANAGE_SETTINGS.SAVE_DIALOG_TITLE}
          onCloseText={MANAGE_SETTINGS.BUTTONS.CANCEL}
          onConfirmText={MANAGE_SETTINGS.BUTTONS.SAVE}
          onClose={() => saveChangesModalHook.hookSetIsOpen(false)}
          onConfirm={() => handleOnSaveChanges()}
          width="500px"
          desc={MANAGE_SETTINGS.SAVE_DIALOG_DESCRIPTION}
        />
      ), [saveChangesModalHook])}

      {hook.hookTransaction.hookCurrencyError && !hook.hookIsTracking && (
        <Styled.ErrorStyled>
          <Styled.IconStyled icon={faTriangleExclamation} />
          {MANAGE_SETTINGS.CURRENCY_ERROR}
        </Styled.ErrorStyled>
      )}
      <Styled.HeaderStyled>
        <PageTitle>{MANAGE_SETTINGS.TITLE}</PageTitle>

        <Styled.HeaderButtonAreaStyled>
          <Button
            theme="secondary"
            onClick={() => navigate(-1)}
          >
            {MANAGE_SETTINGS.BUTTONS.CANCEL}
          </Button>

          <Button
            onClick={() => saveChangesModalHook.hookSetIsOpen(true)}
            disabled={isReadOnly || !isSaveEnabled}
          >
            {MANAGE_SETTINGS.BUTTONS.SAVE}
          </Button>
        </Styled.HeaderButtonAreaStyled>
      </Styled.HeaderStyled>

      <Tabs
        tabNames={[MANAGE_SETTINGS.TRACKING_SETTINGS_TAB_TITLE, MANAGE_SETTINGS.TRANSACTION_SETTINGS_TAB_TITLE]}
        elements={[
          <TrackingSettings
            setIsTracking={() => hook.hookSetIsTracking(true)}
            setIsSaveEnabled={setIsSaveEnabled}
            isReadOnly={isReadOnly}
            trackingHook={hook.hookTracking}
          />,
          <TransactionSettings
            hook={hook.hookTransaction}
            setIsTracking={() => hook.hookSetIsTracking(false)}
            isReadOnly={isReadOnly}
          />,
        ]}
      />
    </AppWrapper>
  );
};

export default ManageTrackingTransactionSettings;
