import React from 'react';
import { useAddRuleModal } from './hooks';
import { ProductFeedModal } from '../ProductFeedModal';
import { RuleSummaryModal } from '../RuleSummaryModal';
import {
  AddRuleStepOneModal, AddRuleStepTwoModal, AddRuleStepThreeModal, CancelAddRuleModal,
} from './components';

type RuleDetailsModalProps = {
  closeModalFn: (state: boolean) => void
  isOpen: boolean
  newRuleCreated: () => void
}

export const AddRuleModal = ({
  closeModalFn, isOpen, newRuleCreated,
}: RuleDetailsModalProps) => {
  const hook = useAddRuleModal(closeModalFn, newRuleCreated);

  return (
    <>
      <CancelAddRuleModal
        isOpen={hook.hookCancelOpen && isOpen}
        cancelButtonHandler={hook.hookCancelButtonHandler}
        exitAddRuleModalHandler={hook.hookExitAddRuleModalHandler}
      />

      <AddRuleStepOneModal
        isOpen={hook.hookCurrentModal === 1 && !hook.hookCancelOpen && !hook.hookProductFeedOpen && isOpen}
        errorMessage={hook.hookErrorMessage}
        ruleName={hook.hookRuleName}
        setRuleName={hook.hookSetRuleName}
        ruleNameError={hook.hookRuleNameError}
        selectedProductCategory={hook.hookSelectedProductCategory}
        productCategoryList={hook.hookProductCategoryList}
        productCategoryListLoading={hook.hookProductCategoryLoading}
        setSelectedProductCategory={hook.hookSetSelectedProductCategory}
        selectedProduct={hook.hookSelectedProduct}
        productsList={hook.hookProductsList}
        setSelectedProduct={hook.hookSetSelectedProduct}
        cancelButtonHandler={() => hook.hookCancelButtonHandler(true)}
        navigateModalTwo={hook.hookNavigateModalTwo}
        canContinueOne={hook.hookCanContinueOne}
        ruleNameCheckLoading={hook.hookLoadingNavigateModalTwo}
      />

      <AddRuleStepTwoModal
        isOpen={hook.hookCurrentModal === 2 && !hook.hookCancelOpen && !hook.hookProductFeedOpen && isOpen}
        criteriaList={hook.hookCriteriaList}
        backButtonHandler={hook.hookBackButtonHandler}
        selectedCriteria={hook.hookSelectedCriteria}
        setSelectedCriteriaHandler={hook.hookSetSelectedCriteriaHandler}
        selectedProductFeed={hook.hookSelectedProductFeed}
        productFeedList={hook.hookProductFeedList}
        setSelectedProductFeed={hook.hookSetSelectedProductFeed}
        customTerm={hook.hookCustomTerm}
        setCustomTerm={hook.hookSetCustomTerm}
        cancelButtonHandler={() => hook.hookCancelButtonHandler(true)}
        navigateModalThree={hook.hookNavigateModalThree}
        canContinueTwo={hook.hookCanContinueTwo}
      />

      <AddRuleStepThreeModal
        isOpen={hook.hookCurrentModal === 3 && !hook.hookCancelOpen && !hook.hookProductFeedOpen && isOpen}
        backButtonHandler={hook.hookBackButtonHandler}
        product={hook.hookProduct}
        selectedProductCategory={hook?.hookSelectedProductCategory}
        requirements={hook.hookRequirements}
        requirementChoices={hook.hookRequirementChoices}
        updateRequirementFeed={hook.hookUpdateRequirementFeed}
        stepThreeErrors={hook.hookStepThreeErrors}
        onBlurCheck={hook.hookOnBlurCheck}
        updateRequirementValue={hook.hookUpdateRequirementValue}
        handleSetFeedDataOpen={hook.hookHandleSetFeedDataOpen}
        removeRequirement={hook.hookRemoveRequirement}
        updateRequirementRequired={hook.hookUpdateRequirementRequired}
        addRequirement={hook.hookAddRequirement}
        cancelButtonHandler={() => hook.hookCancelButtonHandler(true)}
        navigateModalFour={hook.hookNavigateModalFour}
        canContinueThree={hook.hookCanContinueThree}
      />

      <RuleSummaryModal
        closeModalFn={() => hook.hookCancelButtonHandler(true)}
        goBackFn={() => hook.hookSetCurrentModal(3)}
        isOpen={hook.hookCurrentModal === 4 && !hook.hookCancelOpen && !hook.hookProductFeedOpen && isOpen}
        errorMessage={hook.hookCreateRuleError}
        feedLoading={hook.hookFeedDataLoading}
        setParametersOpen={hook.hookHandleSetFeedDataOpen}
        isNewRule
        value={hook.hookSummary}
        createRuleFn={hook.hookCreateRule}
        createRuleLoading={hook.hookCreateRuleLoading}
      />

      <ProductFeedModal
        closeModalFn={() => hook.hookHandleSetFeedDataOpen(false)}
        isOpen={hook.hookProductFeedOpen && isOpen}
        tableData={hook.hookTableData}
      />
    </>
  );
};
