import { gql } from '@apollo/client';

export const UPDATE_TRACKING_PROFILE = gql`
  mutation updateTracking($input: TrackingInput) {
    updateTracking(input: $input) {
      tracking {
        id
      }
    }
  }
`;
