import { gql } from '@apollo/client';

export const LIST_PROMOTION_METHODS = gql`
  query ListPromotionMethods {
    listPromotionMethods {
      id
      type
    }
  }
`;
