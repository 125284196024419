import React from 'react';
import { Button } from '../../../../../../../components/Button';
import { PageTitle } from '../../../../../../../components/PageTitle';
import * as Styled from './styles';

type UpdateReportProps = {
  closeFn: () => void;
  saveFn: () => void;
  reportName?: string;
};

const UpdateReport = ({
  closeFn, saveFn, reportName = '',
}: UpdateReportProps) => (
  <Styled.WrapperStyled>
    <Styled.HeaderStyled>
      <PageTitle>Update Report Profile</PageTitle>
    </Styled.HeaderStyled>

    <Styled.ContentStyled>
      <p>
        You have modified the filter setting of the
        {' '}
        <Styled.BoldText>{reportName}</Styled.BoldText>
        {' '}
        report profile.
      </p>
      <p>Are you sure you want to update this report profile?</p>
    </Styled.ContentStyled>

    <Styled.FooterAreaStyled>
      <Styled.ButtonAreaStyled>
        <Button
          onClick={closeFn}
          theme="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={saveFn}
        >
          Update
        </Button>
      </Styled.ButtonAreaStyled>
    </Styled.FooterAreaStyled>
  </Styled.WrapperStyled>
);

export default UpdateReport;
