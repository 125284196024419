import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button, Calendar, InputCalendar, PageTitle, Spinner, Table, Tooltip,
} from '../../../components';
import * as Styled from './styles';
import { PUBLISHER_PREFIX, path } from '../../../utils';
import { useDashboard } from './hooks';
import { newMerchantColumns, topProductColumns } from './contracts';
import { StatisticCard } from '../../../components/StatisticCard';
import {
  NEW_MERCHANTS, PRODUCT_BY_APPROVALS, SNAPSHOT, STATISTIC_CARD, TOP_PRODUCTS,
} from './enums';
import { ProductByApproval, Snapshot } from './components';
import { DefaultPropsType } from '../../../types';
import { PublisherJoyride } from './components/PublisherJoyride';

type MerchantDashboardProps = DefaultPropsType;

const MerchantDashboard = ({ permissionsCodeList = [] }: MerchantDashboardProps) => {
  const hook = useDashboard();

  return (
    <Styled.AppWrapperStyled permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <Styled.MerchantWrapperStyled>
          <PageTitle>
            {path.dashboard.name}
          </PageTitle>
        </Styled.MerchantWrapperStyled>

        <Styled.LeftHeaderStyled>
          <Styled.SelectStyled
            selected={hook.hookMerchant}
            options={hook.hookMerchantList}
            onChange={hook.hookSetMerchant}
            isLoading={hook.hookMerchantInfoLoading}
          />
          <InputCalendar
            value={hook.hookDateRange}
            width="350px"
            onClick={() => hook.hookOpenCalendar()}
          />
        </Styled.LeftHeaderStyled>
      </Styled.HeaderStyled>

      <Styled.StatisticWrapperStyled>
        <StatisticCard
          title={STATISTIC_CARD.COMMISSIONS.TITLE}
          tooltip={STATISTIC_CARD.COMMISSIONS.TOOLTIP}
          statistic={hook.hookCommissions}
          currency="$"
          loading={hook.hookPerformancesLoading}
        />
        <StatisticCard
          title={STATISTIC_CARD.APPROVED_TRANSACTIONS.TITLE}
          tooltip={STATISTIC_CARD.APPROVED_TRANSACTIONS.TOOLTIP}
          statistic={hook.hookApprovedTransactions}
          loading={hook.hookPerformancesLoading}
        />
        <StatisticCard
          title={STATISTIC_CARD.EPC.TITLE}
          tooltip={STATISTIC_CARD.EPC.TOOLTIP}
          statistic={hook.hookEPC}
          loading={hook.hookPerformancesLoading}
        />
        <StatisticCard
          title={STATISTIC_CARD.CONVERSION_RATE.TITLE}
          tooltip={STATISTIC_CARD.CONVERSION_RATE.TOOLTIP}
          statistic={hook.hookConversionRate}
          currency="%"
          loading={hook.hookPerformancesLoading}
        />
      </Styled.StatisticWrapperStyled>

      <Styled.BodyStyled>
        <Styled.WrapperStyled id="transactionsSnapshotChart">
          <Styled.SectionTitleStyled>
            {SNAPSHOT.TITLE}
            <Tooltip text={SNAPSHOT.TOOLTIP} />
          </Styled.SectionTitleStyled>
          {hook.hookSnapshotLoading ? (
            <Styled.CenteredSpinnerStyled>
              <Spinner
                size="1.5rem"
                theme="secondary"
              />
            </Styled.CenteredSpinnerStyled>
          ) : (
            <Snapshot data={hook.hookSnapshotData} />
          )}

        </Styled.WrapperStyled>
        <Styled.CardsWrapper>
          {hook.hookDisplayCard(hook.hookShowAnnouncements, hook.hookAnnouncements, hook.hookAddMessageHubLink('announcements'), true)}
          {hook.hookDisplayCard(hook.hookShowInvitations, hook.hookInvitations, `${PUBLISHER_PREFIX}${path.memberships.href}`)}
          {hook.hookDisplayCard(hook.hookShowMessageUpdates, hook.hookMessageUpdates, hook.hookAddMessageHubLink('inbox'), true)}
          {hook.hookDisplayUpdateProfile(hook.hookShowUpdateProfile, hook.hookUpdateProfileData)}
          <Styled.MerchantTableStyled>
            <Styled.TableWrapper>
              <Styled.SectionTitleStyled>
                {NEW_MERCHANTS.TITLE}
                <Tooltip text={NEW_MERCHANTS.TOOLTIP} />
              </Styled.SectionTitleStyled>
              <Table
                data={hook.hookMerchantData}
                columns={newMerchantColumns}
                noHorizontalScroll
                isLoading={hook.hookMerchantInfoLoading}
                spinner
              />
            </Styled.TableWrapper>
            {!hook.hookMerchantInfoLoading && (
              <Styled.ButtonStyled
                onClick={() => hook.hookNavigate(`${PUBLISHER_PREFIX}${path.searchProgram.href}`)}
                theme="quaternary"
              >
                Search Programs
                <FontAwesomeIcon icon={faChevronRight} />
              </Styled.ButtonStyled>
            )}
          </Styled.MerchantTableStyled>
        </Styled.CardsWrapper>
      </Styled.BodyStyled>

      <Styled.FooterBodyStyled>
        <Styled.WrapperStyled>
          <Styled.SectionTitleStyled>
            {TOP_PRODUCTS.TITLE}
            <Tooltip text={TOP_PRODUCTS.TOOLTIP} />
          </Styled.SectionTitleStyled>
          {(hook.hookTopProducts?.length > 0 || hook.hookProductsLoading) ? (
            <Styled.TableStyled
              data={hook.hookTopProducts}
              columns={topProductColumns}
              spinner
              isLoading={hook.hookProductsLoading}
            />
          ) : (
            <Styled.TopProductStyled>
              <Styled.TitleStyled>{TOP_PRODUCTS.NO_DATA}</Styled.TitleStyled>
              <Styled.DescriptionStyled>{TOP_PRODUCTS.NO_DATA_DESCRIPTION}</Styled.DescriptionStyled>
              <Button
                onClick={() => hook.hookNavigate(`${PUBLISHER_PREFIX}${path.searchProgram.href}`)}
              >
                Search Programs
              </Button>
            </Styled.TopProductStyled>
          )}
        </Styled.WrapperStyled>
        <Styled.WrapperStyled>
          <Styled.SectionTitleStyled>
            {PRODUCT_BY_APPROVALS.TITLE}
            <Tooltip text={PRODUCT_BY_APPROVALS.TOOLTIP} />
          </Styled.SectionTitleStyled>
          <Styled.ProductApprovalWrapper>
            <ProductByApproval
              productsData={hook.hookProductByApproval}
              loading={hook.hookProductsLoading}
            />
          </Styled.ProductApprovalWrapper>
        </Styled.WrapperStyled>
      </Styled.FooterBodyStyled>

      <Calendar
        isOpen={hook.hookCalendarOpen}
        date={hook.hookStartDate}
        endDate={hook.hookEndDate}
        onApply={(startDate, endDate) => hook.hookSetDateRange(startDate, endDate)}
        onCancel={() => hook.hookCloseCalendar()}
        hasRange
      />
      <PublisherJoyride />
    </Styled.AppWrapperStyled>
  );
};

export default MerchantDashboard;
