/* eslint-disable no-plusplus */
export const paginator = (data: any[], base: number, page: number) => {
  if (!data || data.length <= 0) return [];
  const indexToStart = base * (page - 1);
  const indexToEnd = indexToStart + base;
  const pageData = [];
  for (let i = indexToStart; i < indexToEnd; i++) {
    pageData.push({ ...data[i] });
  }
  return pageData.filter((item) => JSON.stringify(item) !== '{}');
};
