import React from 'react';
import * as Styled from './styles';
import { columns } from './contracts';

export const ProductsTab = ({ hook }:{hook:any}) => (
  <Styled.TableStyled
    columns={columns}
    data={hook.hookProductData}
    setModalInfo={hook.hookOnArrow}
  />
);
