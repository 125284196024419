import environment from '../../../../../../config/environment';

export const TRANSACTION_SETTINGS = {
  CURRENCY: {
    LABEL: 'Transaction Currency',
  },
  BUTTONS: {
    ADD_TRANSACTION_SETTINGS: 'Add Transaction Settings',
    GENERATE_API_KEY_URL: 'Generate New Key/URL',
    COPY_KEY: 'Copy Key',
    COPY_URL: 'Copy URL',
  },
  TYPE_SETTINGS: {
    LABEL: 'Transaction Type Settings',
    TOOLTIP: 'Transactions under enabled Transaction type can be approved or declined via Pending Transactions tab. Use the Auto Approve switch to auto approve Transactions under enabled Transaction type.',
  },
  AUTO_DECLINE: {
    LABEL: 'Days until auto decline',
    TOOLTIP: 'Set an amount of day that transactions can remain pending/soft declined before they are automatically declined. The maximum amount is 180 days.',
    ERROR: 'The maximum days of auto decline is 180 days',
  },
  API_KEY: {
    LABEL: 'Generate postback api key',
  },
  API_URL: {
    LABEL: 'Postback url',
    PREFIX: `${environment.api.baseUrl}/t/pb/?acckey=`,
  },

  TYPES: [
    {
      value: 'allowNoOrderId',
      label: 'Allow no orderID',
    },
    {
      value: 'allowFileManualUpload',
      label: 'File (Manual Upload)',
    },
    {
      value: 'allowFileViaFTP',
      label: 'File (Upload via FTP)',
    },
    {
      value: 'allowPixeltracking',
      label: 'Pixeltracking',
    },
    {
      value: 'allowPostback',
      label: 'Postback',
    },
  ],
  URLS: [
    {
      value: 'All URLs',
      label: 'All URLs',
    },
    {
      value: 'A specific URL',
      label: 'A specific URL',
    },
  ],
};
