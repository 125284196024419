import React from 'react';
import { CellProps } from '../../types';
import { CategoriesWrapper, CellWrapper } from '../../styles';

export const Categories = ({
  val,
}: CellProps) => {
  let categories = '';
  val.forEach((category: any, index: number) => {
    categories = categories.concat(index > 0 ? `, ${category}` : category);
  });

  return (
    <CellWrapper>
      <CategoriesWrapper>{categories}</CategoriesWrapper>
    </CellWrapper>
  );
};
