import React from 'react';
import { TableProps } from '../types';
import * as Styled from '../styles';
import { Checkbox } from '../../../../../../../components';
import { SIGN_UP_ACTIONS } from '../../../../../reducers';

export const NewsletterCell = ({
  val, row, tableData, setTableData, setContext,
}: TableProps) => {
  const handleChange = (checked: boolean) => {
    const tableDataCopy = [...tableData];
    tableDataCopy[row.index].newsletter = checked;
    setTableData(tableDataCopy);

    setContext({
      type: SIGN_UP_ACTIONS.SET_PROGRAM_SUBSCRIPTION,
      data: {
        programId: tableDataCopy[row.index].id,
        subscription: checked,
      },
    });
  };
  return (
    <Styled.CellStyled>
      <Checkbox
        label="Subscribe"
        checked={row.join ? val : false}
        onChange={() => handleChange(!val)}
        disabled={!row.join}
      />
    </Styled.CellStyled>
  );
};
