import React from 'react';

import { AppWrapper, PageTitle, Tabs } from '../../../../components';
import { useProductDetails } from './hooks/useProductDetails';
import { path, imageList, dateFormatter } from '../../../../utils';
import { VerticalTable } from '../components/VerticalTable';
import AdsTab from './Tabs/Ads';
import CommissionsTab from '../../../../components/CommissionsTab';
import { PRODUCT_DETAILS } from './enums';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type ProductDetailsProps = DefaultPropsType;

const ProductDetails = ({ permissionsCodeList = [] }: ProductDetailsProps) => {
  const hook = useProductDetails();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle isLoading={hook.loading || hook.hookMembershipsLoading}>{path.productDetails.name}</PageTitle>
        <Styled.ButtonWrapperStyled>
          <Styled.ButtonStyled
            theme="tertiary"
            onClick={hook.hookHandleBackButton}
          >
            {PRODUCT_DETAILS.BACK_BUTTON}
          </Styled.ButtonStyled>
        </Styled.ButtonWrapperStyled>
      </Styled.HeaderStyled>

      <Styled.ProductStyled>
        <Styled.ProductImgStyled src={hook.hookProduct?.productImageUrl || imageList.noPic.src} />
        <Styled.ProductProfileStyled>
          <Styled.ProductNameStyled>{hook.hookProduct?.name}</Styled.ProductNameStyled>
          <Styled.ProductLinkStyled
            target="_blank"
            href={hook.hookProduct?.productUrl}
          >
            {hook.hookProduct?.productUrl}
          </Styled.ProductLinkStyled>
        </Styled.ProductProfileStyled>
      </Styled.ProductStyled>
      <Styled.DescriptionWrapper>
        <Styled.DescriptiveSectionWrapper>
          <Styled.Subtitle>
            {PRODUCT_DETAILS.CATEGORY}
          </Styled.Subtitle>
          <Styled.ContentStyled>
            {hook.hookProduct?.productCategory}
          </Styled.ContentStyled>
        </Styled.DescriptiveSectionWrapper>
        <Styled.DescriptiveSectionWrapper>
          <Styled.Subtitle>
            {PRODUCT_DETAILS.SUBCATEGORY}
          </Styled.Subtitle>
          <Styled.ContentStyled>
            {hook.hookProduct?.subCategories.join(' , ')}
          </Styled.ContentStyled>
        </Styled.DescriptiveSectionWrapper>
        <Styled.DescriptiveSectionWrapper>
          <Styled.Subtitle>
            {PRODUCT_DETAILS.LAST_UPDATED}
          </Styled.Subtitle>
          <Styled.ContentStyled>
            {dateFormatter(new Date(hook.hookProduct?.updatedAt))}
          </Styled.ContentStyled>
        </Styled.DescriptiveSectionWrapper>
        <Styled.DescriptiveSectionWrapper>
          <Styled.Subtitle>
            {PRODUCT_DETAILS.PRODUCT_DESCRIPTION}
          </Styled.Subtitle>
          <Styled.ContentStyled>
            {hook.hookProduct?.description}
          </Styled.ContentStyled>
        </Styled.DescriptiveSectionWrapper>
      </Styled.DescriptionWrapper>

      <Tabs
        tabNames={['Product Feed', 'Ads', 'Commissions']}
        elements={[VerticalTable(hook.hookProductFeed), <AdsTab hook={hook} />, CommissionsTab(hook, false)]}
      />
    </AppWrapper>
  );
};

export default ProductDetails;
