import styled from 'styled-components';
import { colors, fonts } from '../../../../../../../styles/theme';

export const WrapperStyled = styled.div`
  padding: 1rem;
`;

export const TitleStyled = styled.h3`
  ${fonts.gotham.H24M}
  color: ${colors.color1};
  margin-bottom: 2rem;
`;

export const DescriptionStyled = styled.p`
  ${fonts.lato.H14}
  color: ${colors.color1};
`;

export const WarningStyled = styled.p`
  ${fonts.lato.H14}
  color: ${colors.color14};
  margin: 1rem 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;
