export type FintelCheckDetailsReducerProps = {
  productCategory: SelectOption
  product: SelectOption
  status: SelectOption
  records: SelectOption
  totalCount: number
  totalPages: number
  currentPage: number
}

export const initialState = {
  productCategory: { label: 'All Categories', value: '' },
  product: { label: 'All Products', value: 'All Products' },
  status: { label: 'All Statuses', value: '' },
  records: { label: '10', value: '10' },
  totalCount: 0,
  totalPages: 1,
  currentPage: 1,
};

export const FINTEL_CHECK_DETAILS_REPORT = {
  CHANGE_PRODUCT_CATEGORY: Symbol('change product category'),
  CHANGE_PRODUCT: Symbol('change product'),
  CHANGE_STATUS: Symbol('change status'),
  CHANGE_RECORDS: Symbol('change records'),
  SET_TOTAL: Symbol('set total count'),
  SET_TOTAL_PAGES: Symbol('set total page count'),
  SET_CURRENT_PAGE: Symbol('set current page'),
  CLEAR_FORM: Symbol('clear form'),
};

export type DetailsActionType = {
  type: symbol
  data: any
}

export const detailsReducer = (state: FintelCheckDetailsReducerProps, { type, data }: DetailsActionType) => {
  switch (type) {
    case FINTEL_CHECK_DETAILS_REPORT.CHANGE_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategory: data,
        currentPage: 1,
      };
    case FINTEL_CHECK_DETAILS_REPORT.CHANGE_PRODUCT:
      return {
        ...state,
        product: data,
        currentPage: 1,
      };
    case FINTEL_CHECK_DETAILS_REPORT.CHANGE_STATUS:
      return {
        ...state,
        status: data,
        currentPage: 1,
      };
    case FINTEL_CHECK_DETAILS_REPORT.CHANGE_RECORDS:
      return {
        ...state,
        records: data,
        currentPage: 1,
      };
    case FINTEL_CHECK_DETAILS_REPORT.SET_TOTAL:
      return {
        ...state,
        totalCount: data,
      };
    case FINTEL_CHECK_DETAILS_REPORT.SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: data,
      };
    case FINTEL_CHECK_DETAILS_REPORT.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: data,
      };
    case FINTEL_CHECK_DETAILS_REPORT.CLEAR_FORM:
      return {
        ...state,
        ...data,
      };
    default:
      return { ...state };
  }
};
