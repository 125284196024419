import { useLazyQuery } from '@apollo/client';
import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { path } from '../utils';
import { SHOW_MERCHANT, SHOW_PUBLISHER } from './graphql/queries';

type ActAsMerchantType = {
  id: string,
  name: string,
  activityStatus: string,
  programId: string,
  accountStatus: string
}

type ActAsPublisherType = {
  id: string,
  name: string,
  activityStatus: string,
  accountStatus: string,
}

type ActAsContextType = {
  actingAsMerchant: ActAsMerchantType;
  actingAsPublisher: ActAsPublisherType;
  setActingAsMerchant: (value: ActAsMerchantType) => void;
  setActingAsPublisher: (value: ActAsPublisherType) => void;
  setActAsPublisherHandler: (publisherId: string) => Promise<void>;
  setActAsMerchantHandler: (publisherId: string) => Promise<void>;
};

const ActAsContenxt = createContext<ActAsContextType>({} as ActAsContextType);

export const ActAsProvider = ({ children }: any) => {
  const getActingAsHandler = () => {
    const actAsMerchant = sessionStorage.getItem('actAsMerchant');
    const actAsPublisher = sessionStorage.getItem('actAsPublisher');

    return {
      publisher: actAsPublisher ? JSON.parse(actAsPublisher) : undefined,
      merchant: actAsMerchant ? JSON.parse(actAsMerchant) : undefined,
    };
  };

  const [actingAsMerchant, setActingAsMerchant] = useState<ActAsMerchantType>(getActingAsHandler().merchant);
  const [actingAsPublisher, setActingAsPublisher] = useState<ActAsPublisherType>(getActingAsHandler().publisher);

  const navigate = useNavigate();

  const [getMerchant] = useLazyQuery(SHOW_MERCHANT);
  const [getPublisher] = useLazyQuery(SHOW_PUBLISHER);

  const setActAsPublisherHandler = async (publisherId: string) => {
    const { data } = await getPublisher({
      variables: {
        input: {
          id: publisherId,
        },
      },
    });

    if (data?.showCompany) {
      const {
        id, companyName, activityStatus, accountStatus,
      } = data.showCompany;

      const actAs = {
        id,
        name: companyName,
        activityStatus,
        accountStatus,
      };

      sessionStorage.setItem('actAsPublisher', JSON.stringify(actAs));
      setActingAsPublisher(actAs);

      navigate(path.publisherSearchDetail.href, {
        state: {
          publisherId: id,
        },
      });
    }
  };

  const setActAsMerchantHandler = async (merchantId: string) => {
    const { data } = await getMerchant({
      variables: {
        input: {
          id: merchantId,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.showCompany) {
      const {
        id, companyName, program, activityStatus, accountStatus,
      } = data.showCompany;

      const actAs = {
        id,
        name: companyName,
        activityStatus,
        programId: program.id,
        accountStatus,
      };

      sessionStorage.setItem('actAsMerchant', JSON.stringify(actAs));
      setActingAsMerchant(actAs);

      navigate(path.merchantManagementDetail.href, {
        state: {
          merchantId: id,
        },
      });
    }
  };

  const esseMemo = useMemo(() => ({
    actingAsMerchant,
    actingAsPublisher,
    setActingAsMerchant,
    setActingAsPublisher,
    setActAsPublisherHandler,
    setActAsMerchantHandler,
  }), [actingAsMerchant, actingAsPublisher, window.location.pathname]);

  return (
    <ActAsContenxt.Provider
      value={esseMemo}
    >
      {children}
    </ActAsContenxt.Provider>
  );
};

export const useActAsContext = () => useContext(ActAsContenxt);
