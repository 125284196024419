import React from 'react';
import {
  Button, InfoBox, InputCalendar, InputText, PageTitle, Select,
} from '../../../../../../components';
import {
  BUTTON_LABELS, INPUT_LABELS, MODAL, PLACEHOLDERS,
} from '../../contracts';
import * as Styled from './styles';
import { ERROR_ICON, PAYMENT_METHOD_DEPOSIT } from '../../../../../../utils';

type DepositNotificationProps = {
  setIsOpen: () => void;
  productCategoryOptions: SelectOption[]
  seletedProjectCategory?: SelectOption
  setSeletedProjectCategory: (value: SelectOption) => void
  additionalInfo: string
  setAdditionalInfo: (value: React.ChangeEvent<HTMLInputElement>) => void
  paymentAmount: string
  setPaymentAmount: (value: React.ChangeEvent<HTMLInputElement>) => void
  selectedPaymentMethod: SelectOption
  setSelectedPaymentMethod: (value: SelectOption) => void
  paymentName: string
  setPaymentName: (value: React.ChangeEvent<HTMLInputElement>) => void
  paymentEmail: string
  setPaymentEmail: (value: React.ChangeEvent<HTMLInputElement>) => void
  paymentDate: string
  setIsCalendarOpen: () => void
  notifyDepositHandler: () => void
  errors: { [key: string]: string }
  notifyAdminLoading: boolean
}

const DepositNotification = ({
  setIsOpen, productCategoryOptions, seletedProjectCategory, setSeletedProjectCategory, additionalInfo, setAdditionalInfo, selectedPaymentMethod, setSelectedPaymentMethod, paymentEmail, paymentName,
  paymentAmount, setPaymentAmount, setPaymentEmail, setPaymentName, paymentDate, setIsCalendarOpen, notifyAdminLoading,
  notifyDepositHandler, errors,
}: DepositNotificationProps) => (
  <Styled.WrapperStyled>
    {errors.notifyGeneral && (
      <Styled.ErrorWrapperStyled>
        <InfoBox
          title=""
          description={errors.notifyGeneral}
          icon={ERROR_ICON}
          theme="ERROR_SECONDARY"
        />
      </Styled.ErrorWrapperStyled>
    )}
    <PageTitle>{MODAL.NOTIFICATION.TITLE}</PageTitle>

    <Styled.ButtonAreaStyled>
      <Styled.ButtonWrapper>
        <Button
          theme="secondary"
          onClick={setIsOpen}
        >
          {BUTTON_LABELS.CANCEL}
        </Button>
      </Styled.ButtonWrapper>
      <Button
        onClick={notifyDepositHandler}
        loading={notifyAdminLoading}
      >
        {BUTTON_LABELS.NOTIFY_ADMIN}
      </Button>
    </Styled.ButtonAreaStyled>

    <InputText
      label={INPUT_LABELS.PAYMENT}
      name="payment"
      value={paymentAmount}
      type="text"
      onChange={setPaymentAmount}
      required
      error={errors.paymentAmount}
    />
    <InputCalendar
      label={INPUT_LABELS.DATE}
      value={paymentDate}
      onClick={setIsCalendarOpen}
      placeholder={PLACEHOLDERS.DATE}
      required
      error={errors.paymentDate}
    />
    <Select
      label={INPUT_LABELS.METHOD}
      name="method"
      onChange={setSelectedPaymentMethod}
      selected={selectedPaymentMethod}
      options={PAYMENT_METHOD_DEPOSIT}
      required
    />
    <InputText
      label={INPUT_LABELS.EMAIL}
      name="email"
      value={paymentEmail}
      type="text"
      onChange={setPaymentEmail}
      required
      error={errors.paymentEmail}
    />
    <InputText
      label={INPUT_LABELS.PAYER}
      name="name"
      value={paymentName}
      type="text"
      onChange={setPaymentName}
      required
      error={errors.payersName}
    />
    <Select
      label={INPUT_LABELS.CATEGORY}
      name="category"
      onChange={setSeletedProjectCategory}
      selected={seletedProjectCategory}
      options={productCategoryOptions}
      required
      error={errors.productCategory}
    />

    <Styled.InputTextStyled
      label={INPUT_LABELS.ADDITIONAL_INFO}
      name="info"
      value={additionalInfo}
      type="text"
      onChange={setAdditionalInfo}
    />
  </Styled.WrapperStyled>
);

export default DepositNotification;
