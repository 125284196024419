import React, { useEffect } from 'react';
import { Pagination, Table } from '../../../../../../components';
import { columns, TAB_DESCRIPTION, TAB_TYPES } from '../../contracts';
import * as Styled from './styles';

type TrackingProfileTabProps = {
  data: any[]
  isLoading: boolean
  getTrackingProfiles: () => void
  totalPage: number
  currentPage: number
  pageChangeHandler: (page: number, type: string, loading?: boolean) => void;
  sortHandler: (column: string, direction: 'asc' | 'desc' | undefined) => void
  sortColumn: TableSortColumn
}

const TrackingProfileTab = ({
  data, getTrackingProfiles, isLoading, currentPage, totalPage, pageChangeHandler,
  sortColumn, sortHandler,
}: TrackingProfileTabProps) => {
  useEffect(() => {
    getTrackingProfiles();
    pageChangeHandler(1, TAB_TYPES.PROFILE, true);
  }, []);
  return (
    <Styled.WrapperStyled>
      <Styled.TitleStyled>
        {TAB_DESCRIPTION.TRACKING}
      </Styled.TitleStyled>
      {data.length > 0 && (
        <>
          <Table
            data={data}
            columns={columns}
            isLoading={isLoading}
            sortColumn={sortColumn}
            onSort={sortHandler}
          />
          <Pagination
            total={totalPage}
            currentPage={currentPage}
            onPageChange={(value) => pageChangeHandler(value, TAB_TYPES.PROFILE)}
          />
        </>
      )}
    </Styled.WrapperStyled>
  );
};

export default TrackingProfileTab;
