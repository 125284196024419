import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;
