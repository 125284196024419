import { gql } from '@apollo/client';

export const LIST_PRODUCTS_AND_CATEGORIES = gql`
  query ListAvailableProductsForRuleInput($input: ListAvailableProductsForRuleInput!) {
    listAvailableProductsForRule(input: $input) {
      products {
        category
        productFields
        products {
          id
          customizedProductId
          productCategory
          productName
          type
          validRuleRequirements {
            name
            value
          }
        }
      }
    }
  }
`;
