import { gql } from '@apollo/client';

export const UPDATE_FCHECK_SETTINGS = gql`
mutation updateFintelCheckSettings($input: FintelCheckSettingsInput!) {
  updateFintelSettingsFields(input: $input) {
    settings {
      id
      blacklistDomains
    }
  }
}
`;
