import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import * as Styled from '../styles';
import { dateFormatter, timeFormatter } from '../../../utils';

const formatSortCaret = (order: string) => {
  let icon;
  switch (order) {
    case 'asc':
      icon = <FontAwesomeIcon icon="caret-up" />;
      break;
    case 'desc':
      icon = <FontAwesomeIcon icon="caret-down" />;
      break;
    default:
      icon = <FontAwesomeIcon icon="sort" />;
      break;
  }
  return (
    <span>
        &nbsp;&nbsp;
      {icon}
    </span>
  );
};

export const buildTableColumns = (handleEdit: any, handleDelete: any, targetType: string, isReadonly: boolean): TableColumn[] => [
  {
    dataField: '',
    text: 'ACTIONS',
    formatter: (val: any, row: any) => (
      <Styled.TableDataStyled>
        {isReadonly && (
          <span>&nbsp;</span>
        )}
        {!isReadonly && (
          <>
            <Styled.FAButtonStyled
              theme="text-only"
              onClick={() => handleEdit(row)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Styled.FAButtonStyled>
            <Styled.FAButtonStyled
              theme="text-only"
              onClick={() => handleDelete(row)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Styled.FAButtonStyled>
          </>
        )}
      </Styled.TableDataStyled>
    ),
    width: '85px',
  },
  {
    dataField: 'eventType',
    text: 'TRANSACTION EVENT',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (val: any, row: any) => (
      <Styled.TableDataStyled>
        {val || ''}
      </Styled.TableDataStyled>
    ),
    width: '115px',
  },
  {
    dataField: 'targetId',
    text: targetType.toUpperCase(),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (val: any, row: any) => (
      <Styled.TableDataStyled>
        {row && row.targetId === null && (
          <em>All Transactions</em>
        )}
        {row && row.targetId !== null && row.target && (
          <span>{row.target.companyName}</span>
        )}
      </Styled.TableDataStyled>
    ),
    width: '220px',
  },
  {
    dataField: 'url',
    text: 'URL',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (val: any, row: any) => (
      <Styled.TableDataStyled>
        <Styled.TableDataAnchor
          target="_blank"
          rel="noopener noreferrer"
          href={val || '#'}
        >
          {val || ''}
          <FontAwesomeIcon
            icon={faCircleArrowRight}
            style={{ margin: 'auto 5px' }}
          />
        </Styled.TableDataAnchor>
      </Styled.TableDataStyled>
    ),
    width: '380px',
  },
  {
    dataField: 'createdAt',
    text: 'CREATED DATE',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (val: any, row: any) => (
      <Styled.TableDataStyled>
        {val ? moment(row.createdAt).format('MMM D/YYYY') : ''}
      </Styled.TableDataStyled>
    ),
    width: '115px',
  },
  {
    dataField: 'queryString',
    text: 'POSTBACK VALUE',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (val: any, row: any) => (
      <Styled.TableDataStyled>
        {val || ''}
      </Styled.TableDataStyled>
    ),
    width: '65%',
  },
];
