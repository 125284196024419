/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import { useNavigate } from 'react-router-dom';
import { MERCHANT_PREFIX, path } from '../../../utils';

import * as Styled from './styles';

type SelectProps = {
  label?: string;
  name?: string;
  selected?: {
    label: string;
    value: string;
  };
  selectedMulti?: {
    label: string;
    value: string;
  }[];
  onChange: (value: any) => void;
  options?: any[];
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
  placeholder?: string;
  error?: string;
  description?: string;
  onBlur?: () => void;
}

const MenuList = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      <Styled.HeaderStyled>Select a record from your check history to generate a Fintel Check report.</Styled.HeaderStyled>
      <components.MenuList {...props}>{props.children}</components.MenuList>
      <Styled.FooterStyled
        theme="text-only"
        onClick={() => navigate(`${MERCHANT_PREFIX}${path.fintelCheckHistory.href}`)}
      >
        View check history
      </Styled.FooterStyled>
    </>
  );
};

const formatDateOptions = (options: any) => options.map((option: any) => ({
  value: option.value,
  label: (
    <Styled.OptionWrapperStyled>
      <Styled.OptionLeftSideStyled>
        <Styled.OptionDateTextStyled>{option.label}</Styled.OptionDateTextStyled>
        <div>Full Run</div>
      </Styled.OptionLeftSideStyled>
      <Styled.OptionRightSideStyled>
        <div>All Publishers</div>
        <Styled.SubTextStyled>
          {`(${option.publisherCount} publishers)`}
        </Styled.SubTextStyled>
      </Styled.OptionRightSideStyled>
    </Styled.OptionWrapperStyled>
  ),
}));

export const FintelCheckSelect = ({
  label,
  name,
  selected,
  selectedMulti = [],
  onChange,
  options = [],
  isMulti = false,
  isDisabled = false,
  isLoading = false,
  className,
  placeholder,
  error,
  description,
  onBlur,
}: SelectProps) => {
  const [selectedState, setSelectedState] = useState(selected);
  MenuList(options);

  const formattedOptions = formatDateOptions(options);

  useEffect(() => {
    setSelectedState(selected);
  }, [JSON.stringify(selected)]);

  return (
    <Styled.WrapperStyled
      id={name}
      className={className}
    >
      <Styled.LabelStyled htmlFor={name}>
        {label && (
          <Styled.LabelTextStyled>
            {label}
            {isLoading && (
              <Styled.SpinnerStyled
                theme="secondary"
                size=".7rem"
              />
            )}
          </Styled.LabelTextStyled>
        )}

        {description && (
          <Styled.DescriptionStyled>
            {description}
          </Styled.DescriptionStyled>
        )}

        <ReactSelect
          isDisabled={isDisabled}
          options={formattedOptions}
          components={{ MenuList }}
          onChange={onChange}
          defaultValue={selected || selectedMulti}
          value={selectedState || selectedMulti}
          styles={Styled.SelectStyles(error)}
          isMulti={isMulti}
          name={name}
          isLoading={isLoading}
          placeholder={placeholder}
          menuPlacement="auto"
          onBlur={onBlur}
        />

        {error && (
          <Styled.ErrorStyled>{error}</Styled.ErrorStyled>
        )}
      </Styled.LabelStyled>
    </Styled.WrapperStyled>
  );
};
