import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, ErrorBanner } from '../../../../../../components';
import * as Styled from './styles';
import {
  BUTTONS, FILE_UPLOAD, NOTIFY_PUBLISHERS, STEPS,
} from '../utils';
import { FILE_EXTENSIONS } from '../../../../../../utils';
import { useProductUpload } from './hooks/useBulkProduct';
import { STEPS_TITLE } from './enums';

type BulkImportProps = {
  changeTabs: any,
  isReadOnly: boolean
}

export const BulkImport = ({ changeTabs, isReadOnly }: BulkImportProps) => {
  const hooks = useProductUpload(changeTabs);

  return (
    <Styled.WrapperStyled>
      <ErrorBanner
        message={hooks.hookErrorMessage}
        isOpen={!!hooks.hookErrorMessage}
      />
      <Styled.StepListStyled>
        <Styled.StepStyled>
          <h2>{STEPS_TITLE[1]}</h2>

          <Styled.StepButtonAreaStyled>
            <p>{STEPS.ONE}</p>
            <Styled.ButtonStyled
              theme="tertiary"
              onClick={hooks.hookCSVDownload}
              loading={hooks.hookLoading}
            >
              <FontAwesomeIcon icon={faFileDownload} />
              {BUTTONS.CSV}
            </Styled.ButtonStyled>
          </Styled.StepButtonAreaStyled>
        </Styled.StepStyled>

        <Styled.StepStyled>
          <h2>{STEPS_TITLE[2]}</h2>
          <p>{STEPS.TWO_ONE}</p>
          <br />
          <p>{STEPS.TWO_TWO}</p>
        </Styled.StepStyled>

        <Styled.StepStyled>
          <h2>{STEPS_TITLE[3]}</h2>

          <p>{STEPS.THREE_ONE}</p>
          <p>{STEPS.THREE_TWO}</p>
        </Styled.StepStyled>
      </Styled.StepListStyled>

      <Styled.FileUploadStyled
        label={FILE_UPLOAD.LABEL}
        info={FILE_UPLOAD.INFO}
        maxFileSize={4194304}
        onChange={hooks.hookUploadHandler}
        accept={`${FILE_EXTENSIONS.CSV}, ${FILE_EXTENSIONS.MS_EXCEL}, ${FILE_EXTENSIONS.SPREADSHEET}, ${FILE_EXTENSIONS.TXT}`}
        disabled={isReadOnly}
      />

      <Styled.FooterStyled>
        <Styled.CheckboxAreaStyled>
          <Checkbox
            checked={hooks.hookChecked}
            label=""
            onChange={hooks.hookHandleChecked}
            disabled={isReadOnly}
          />
          {NOTIFY_PUBLISHERS}
        </Styled.CheckboxAreaStyled>
        <Styled.ButtonAreaStyled>
          <Styled.ButtonStyled
            theme="secondary"
            width="150px"
            onClick={hooks.hookHandleCancel}
            loading={hooks.hookLoading}
          >
            {BUTTONS.CANCEL}
          </Styled.ButtonStyled>

          <Styled.ButtonStyled
            theme="primary"
            width="150px"
            onClick={hooks.hookSubmit}
            loading={hooks.hookLoading}
            disabled={isReadOnly}
          >
            {BUTTONS.NEXT}
          </Styled.ButtonStyled>
        </Styled.ButtonAreaStyled>
      </Styled.FooterStyled>

    </Styled.WrapperStyled>
  );
};
