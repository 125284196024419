import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { chartColors, colors } from '../../../../../styles/theme';
import * as Styled from './styles';
import { SnapshotStructureProps } from './types';
import { monthLabelsGenerator } from '../../../../../utils/monthLabelsGenerator';

type SnapshotProps = {
  data: SnapshotStructureProps
}

export const Snapshot = ({ data }: SnapshotProps) => {
  const labels = monthLabelsGenerator();
  const width = (document.getElementById('transactionsSnapshotChart')?.getBoundingClientRect().width || 980);
  const [chartSize, setChartSize] = useState<number>(width);

  useEffect(() => {
    const targetElement = document.getElementById('transactionsSnapshotChart');
    const resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        const widthTest = entry?.contentRect.width || 980;
        setChartSize((widthTest));
      }
    });
    if (targetElement) resizeObserver.observe(targetElement);

    return () => {
      if (targetElement) resizeObserver.unobserve(targetElement);
    };
  }, []);

  const formattedData = {
    labels: labels.hookMonths,
    datasets: [
      {
        label: 'Total Transactions',
        fill: false,
        backgroundColor: colors.color2,
        borderColor: colors.color2,
        pointBorderColor: colors.color2,
        pointHoverBackgroundColor: colors.color2,
        pointHoverBorderColor: chartColors.color1,
        data: data.totalTransactions,
        yAxisID: 'transactions',
        tension: 0.3,
      },
      {
        label: 'Approved Transactions',
        fill: false,
        backgroundColor: colors.color21,
        borderColor: colors.color21,
        pointBorderColor: colors.color21,
        pointHoverBackgroundColor: colors.color21,
        pointHoverBorderColor: chartColors.color1,
        data: data.approvedTransactions,
        yAxisID: 'transactions',
        tension: 0.3,
      },
      {
        label: 'Total Commissions',
        fill: false,
        backgroundColor: colors.color14,
        borderColor: colors.color14,
        pointBorderColor: colors.color14,
        pointHoverBackgroundColor: colors.color14,
        pointHoverBorderColor: chartColors.color1,
        data: data.commissions,
        yAxisID: 'commissions',
        tension: 0.3,
      },
    ],
  };

  return (
    <>
      <Line
        data={formattedData}
        height={440}
        width={chartSize}
        key={chartSize}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: false,
          scales: {
            commissions: {
              grid: {
                display: false,
              },
              min: 0,
              beginAtZero: true,
              type: 'linear',
              position: 'right',
              title: {
                display: true,
                text: 'Commissions',
              },
              ticks: {
                precision: 0,
              },
            },
            transactions: {
              min: 0,
              beginAtZero: true,
              type: 'linear',
              position: 'left',
              title: {
                display: true,
                text: 'Transactions',
              },
              ticks: {
                precision: 0,
              },
            },
          },
        }}
      />

      <Styled.FooterStyled>
        <Styled.FooterInnerStyled>
          <Styled.CaptionWrapperStyled>
            <Styled.CaptionColorStyled color={colors.color2} />
            <Styled.CaptionTextStyled>Total Transactions</Styled.CaptionTextStyled>
          </Styled.CaptionWrapperStyled>
          <Styled.CaptionWrapperStyled>
            <Styled.CaptionColorStyled color={colors.color21} />
            <Styled.CaptionTextStyled>Approved Transactions</Styled.CaptionTextStyled>
          </Styled.CaptionWrapperStyled>
          <Styled.CaptionWrapperStyled>
            <Styled.CaptionColorStyled color={colors.color14} />
            <Styled.CaptionTextStyled>Total Commissions</Styled.CaptionTextStyled>
          </Styled.CaptionWrapperStyled>
        </Styled.FooterInnerStyled>
      </Styled.FooterStyled>
    </>
  );
};
