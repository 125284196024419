import { gql } from '@apollo/client';

export const GET_FINTEL_CHECK_SUMMARY = gql`
  query fintelCheckSummary($input: FintelCheckSummaryInput!) {
    fintelCheckSummary(input: $input) {
      id
      merchantId
      merchantName
      publisherId
      publisherName
      productCategory
      productId
      customizedProductId
      productName
      referralUrl
      ruleName
      ruleStatus
      screenshotName
      zipfileName
      ruleRequired
      resultFound
      trackingEventDate
      feedback {
        correct
        currStatus
        inputType
        inputVal
        issue
        shouldBeStatus
      }
      eligibilityFeedback {
        correct
        currStatus
        inputType
        inputVal
        issue
        shouldBeStatus
      }
    }
  }
`;
