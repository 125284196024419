import { useLazyQuery, useQuery } from '@apollo/client';
import { ChangeEvent, useEffect, useState } from 'react';
import { useUserInfo } from '../../../../../../hooks';
import { QUEUE_STATUS, USER_TYPES_ID, rangeFormat } from '../../../../../../utils';

import { LIST_BULK_IMPORT_LOG } from '../graphql/queries';
import { GET_MERCHANTS_LIST } from '../../../../graphql/queries';
import { BulkUploadOutputType, BulkUploadInputType, BulkUploadsOutputListType } from '../types';

// Returns the current UTC date with time set to the end of that day.
const getDefaultEndTime = () => {
  const today = new Date();
  today.setUTCHours(23, 59, 59, 999);
  return today;
};

const defaultMerchantOption:SelectOption = {
  label: 'All Merchants',
  value: '',
};

const defaultStatusOption = {
  label: 'All Status',
  value: '',
};

const getLastMonth = () => {
  const defaultRange = rangeFormat('last30Days');
  const lastMonth = defaultRange.start;
  return lastMonth;
};

export const useBulkImportLog = () => {
  const { hookWhoAmI, hookUserInfo } = useUserInfo();
  const getInitialMerchantInfo = () => {
    if (hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN) {
      if (hookWhoAmI?.companyId) {
        return { label: 'not visible', value: hookWhoAmI.companyId?.toString() };
      }
      return defaultMerchantOption;
    }
    return { label: 'not visible', value: hookWhoAmI.companyId?.toString() || '0' };
  };

  const [selectedMerchant, setSelectedMerchant] = useState<SelectOption>(defaultMerchantOption);
  const [selectedStatus, setSelectedStatus] = useState<SelectOption>(defaultStatusOption);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState<Date>(getLastMonth());
  const [endDate, setEndDate] = useState<Date>(getDefaultEndTime());
  const [dateRange, setDateRange] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState(`${startDate.toDateString()} / ${endDate.toDateString()}`);
  const [search, setSearch] = useState('');
  const [tableData, setTableData] = useState<BulkUploadOutputType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [initialRender, setInitialRender] = useState(true);
  const [isGenerated, setIsGenerated] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [selectedBulkTransaction, setSelectedBulkTransaction] = useState<string>('');
  const [selectedBulkTransactionDetails, setSelectedBulkTransactionDetails] = useState<BulkUploadOutputType>();
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  // Detect if admin is moving between acting as company and as acting as admin.
  const [companyId, setCompanyId] = useState(hookWhoAmI.companyId);
  if (companyId !== hookWhoAmI.companyId) {
    setCompanyId(hookWhoAmI.companyId);
    setSelectedMerchant(getInitialMerchantInfo());
  }

  const { data: merchantsData, loading: merchantsLoading } = useQuery(GET_MERCHANTS_LIST, {
    variables: {
      input: {
        companyType: 'Merchant',
        accountStatus: 'Approved',
      },
    },
    fetchPolicy: 'no-cache',
    onError(err) {
      setErrorMessage(err.message);
    },
  });

  const [getBulkImport, { loading: bulkImportLoading }] = useLazyQuery(LIST_BULK_IMPORT_LOG);

  const setGenerateBulkImportLogHandler = async (page?: number) => {
    setErrorMessage('');
    const getBulkImportInput: BulkUploadInputType = {
      status: null,
      merchantId: null,
      startDate,
      endDate,
      originalFilename: search,
      currentPage: page || currentPage,
      limit: 10,
    };
    if (selectedStatus.value) {
      getBulkImportInput.status = selectedStatus.value;
    }
    if (selectedMerchant.value) {
      getBulkImportInput.merchantId = selectedMerchant.value;
    }

    const { data } = await getBulkImport({
      variables: {
        input: getBulkImportInput,
      },
      onError(error) {
        setErrorMessage(error.message);
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.minimizedBulkUploads) {
      const bulkUploadsList:BulkUploadsOutputListType = data.minimizedBulkUploads;
      setTableData(bulkUploadsList.bulkUploads);
      setIsGenerated(true);
      setTotalPages(Number.parseInt(bulkUploadsList.totalPages, 10));
    } else {
      setTableData([]);
      setTotalPages(1);
      setIsGenerated(true);
    }
  };

  const generateLogHandler = () => {
    setCurrentPage(1);
    setGenerateBulkImportLogHandler(1);
  };

  const getMerchantsHandler = (): SelectOption[] | [] => {
    let merchantsListData = [defaultMerchantOption];
    if (merchantsData && !merchantsLoading) {
      merchantsListData = [...merchantsListData, ...merchantsData.companies.companies.map((merchant: any): SelectOption => ({ label: `${merchant.id} - ${merchant.companyName}`, value: merchant.id }))];
    }
    return merchantsListData;
  };

  const setSelectedBulkTransactionHandler = (id: string, _: any) => {
    setSelectedBulkTransaction(id);
    const newData = tableData.find((row) => row.id === id);
    if (newData) setSelectedBulkTransactionDetails(newData);
    else setSelectedBulkTransactionDetails(undefined);
    setDetailsOpen(true);
  };

  const backButtonHandler = () => {
    setDetailsOpen(false);
  };

  const selectMerchantHandler = (merchantSelected: SelectOption) => {
    setTableData([]);
    setIsGenerated(false);
    setSelectedMerchant(merchantSelected);
  };

  const setOpenCalendarHandler = () => {
    setOpenCalendar(!openCalendar);
  };

  const setOnCancelCalendarHandler = () => {
    setOpenCalendar(false);
  };

  const setSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setTableData([]);
    setIsGenerated(false);
    setSearch(e.target.value);
  };

  const changePageHandler = (pageValue: number) => {
    setCurrentPage(pageValue);
  };

  const setOnApplyCalendarHandler = (startDateValue: Date, endDateValue?: Date, range?: string) => {
    setOpenCalendar(false);
    setStartDate(startDateValue);
    if (endDateValue) {
      // Sets Date to the end of the UTC day of the selected day.
      const newDate = new Date(Date.UTC(endDateValue.getFullYear(), endDateValue.getMonth(), endDateValue.getDate(), 23, 59, 59, 999));
      setEndDate(newDate);
    }

    if (range) {
      setDateRange(range);
    }

    setSelectedDateRange(`${startDateValue.toDateString()} / ${endDateValue?.toDateString()}`);
  };

  const setSelectedStatusHandler = (statusSelected: SelectOption) => {
    setTableData([]);
    setIsGenerated(false);
    setSelectedStatus(statusSelected);
  };

  const resetPage = () => {
    setIsGenerated(false);
    setTableData([]);
    setSelectedStatus(defaultStatusOption);
    setSelectedMerchant(getInitialMerchantInfo());
    setCurrentPage(1);
    setSearch('');
    setOnApplyCalendarHandler(getLastMonth(), getDefaultEndTime());
  };

  const setClearFormHandler = () => {
    setTableData([]);
    selectMerchantHandler(defaultMerchantOption);
    setSelectedStatus(defaultStatusOption);
    setSelectedDateRange(`${getLastMonth().toDateString()} / ${getDefaultEndTime().toDateString()}`);
    setSearch('');
    setIsGenerated(false);
  };

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      setGenerateBulkImportLogHandler();
    }
  }, [currentPage]);

  useEffect(() => {
    resetPage();
  }, [window.location.pathname]);

  return {
    hookPageLoading: merchantsLoading || bulkImportLoading,

    hookTableData: tableData,
    hookBulkImport: setGenerateBulkImportLogHandler,
    hookGenerateLogHandler: generateLogHandler,
    hookBulkImportLoading: bulkImportLoading,

    hookMerchantList: getMerchantsHandler,
    hookSelectedMerchant: selectedMerchant,
    hookSetMerchant: selectMerchantHandler,
    hookMerchantLoading: merchantsLoading,

    hookOpenCalendar: openCalendar,
    hookSetOpenCalendar: setOpenCalendarHandler,
    hookOnCancelCalendar: setOnCancelCalendarHandler,
    hookOnApplyCalendar: setOnApplyCalendarHandler,
    hookSelectDate: selectedDateRange,
    hookStartDate: startDate,
    hookEndDate: endDate,
    hookRange: dateRange,

    hookStatus: QUEUE_STATUS,
    hookSelectedStatus: selectedStatus,
    hookSetStatus: setSelectedStatusHandler,

    hookIsGenerated: isGenerated,
    hookSearch: search,
    hookSetSearch: setSearchHandler,

    hookTotalPages: totalPages,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: changePageHandler,

    hookErrorMessage: errorMessage,

    hookSetSelectedBulkTransactionHandler: setSelectedBulkTransactionHandler,
    hookSelectedBulkTransaction: selectedBulkTransaction,
    hookSelectedBulkTransactionDetails: selectedBulkTransactionDetails,
    hookDetailsOpen: detailsOpen,
    hookBackButtonHandler: backButtonHandler,

    hookClearForm: setClearFormHandler,
  };
};
