export const PRODUCT_INFORMATION = {
  PRODUCT_NAME: {
    LABEL: 'Product Name',
    PLACEHOLDER: 'First Auto Loan',
  },
  STATUS: 'Product Status',
  PRODUCT_URL: 'Product URL',
  ID: 'ID',
  PRODUCT_DESC: 'Product Description',
  LANDING: 'Default Landing Page URL',
  THIRD: 'Third Party Tracker URL',
  PRODUCT_IMAGE: 'Product Image',
  IMAGE_LABEL: 'Default Product Image',
  PRODUCT_CATEGORY: 'Product Category',
  SUB: 'Sub Category',
  TARGET: 'Target Demographics',
  GENDER_LABEL: 'Gender',
  COMMISSIONS: 'Commissions Visibility',
  AGE_RANGES: 'Age Range(s)',
  COUNTRY: 'Target Geographical Market Country',
  STATE: 'Target Geographical Market State/Province',
  INCOME: 'Minimum Personal Income Required',
  HOUSHOLD: 'Minimum Household Income Required',
  CREDIT: 'Minimum Credit Score Required',
  INCOME_PLACEHOLDER: 'Eg. CAD $75,000/yr',
  CREDIT_PLACEHOLDER: 'Eg. 750',
  CANCEL: 'Cancel',
  SAVE: 'Save',
};
