import React from 'react';
import { CellWrapper, WebsiteStyled } from '../../styles';

export const CellDomain = (value: any) => (
  <CellWrapper>
    <WebsiteStyled
      href={value.value}
      target="_blank"
    >
      {value.value}
    </WebsiteStyled>
  </CellWrapper>
);
