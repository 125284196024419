import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import * as Styled from '../../styles';
import { TableProps } from '../../types';
import { MODAL_KEYS } from '../../../reducers';

export const SettingsCell = ({
  val, setModals,
}: TableProps) => {
  const handleClick = () => {
    setModals({
      [MODAL_KEYS.openSettingModal]: true,
      modalInfo: {
        filters: val.filters,
        columns: val.columns,
      },
    });
  };
  return (
    <Styled.CellWrapper>
      <Styled.ButtonStyled
        theme="secondary"
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faFile} />
      </Styled.ButtonStyled>
    </Styled.CellWrapper>
  );
};
