import React from 'react';
import { Line } from 'react-chartjs-2';
import { chartColors } from '../../../../styles/theme';
import { monthLabelsGenerator } from '../../../../utils/monthLabelsGenerator';
import { LineChartStructure } from './types';

type LineChartProps = {
  data: LineChartStructure
}

const LineChart = ({
  data,
}: LineChartProps) => {
  const labels = monthLabelsGenerator();
  const formattedData = {
    labels: labels.hookMonths,
    datasets: [
      {
        label: 'Total Transactions',
        fill: false,
        backgroundColor: chartColors.color4,
        borderColor: chartColors.color4,
        pointBorderColor: chartColors.color4,
        pointHoverBackgroundColor: chartColors.color4,
        pointHoverBorderColor: chartColors.color3,
        data: data.totalTransactions,
        yAxisID: 'transactions',
        tension: 0.3,
      },
      {
        label: 'Approved Transactions',
        fill: false,
        backgroundColor: chartColors.color2,
        borderColor: chartColors.color2,
        pointBorderColor: chartColors.color2,
        pointHoverBackgroundColor: chartColors.color2,
        pointHoverBorderColor: chartColors.color3,
        data: data.approvedTransactions,
        yAxisID: 'transactions',
        tension: 0.3,
      },
      {
        label: 'Total Commissions',
        fill: false,
        backgroundColor: chartColors.color6,
        borderColor: chartColors.color6,
        pointBorderColor: chartColors.color6,
        pointHoverBackgroundColor: chartColors.color6,
        pointHoverBorderColor: chartColors.color3,
        data: data.totalCommissions,
        yAxisID: 'commissions',
        tension: 0.3,
      },
    ],
  };

  return (
    <Line
      width="100%"
      height={270}
      options={{
        maintainAspectRatio: false,
        scales: {
          commissions: {
            grid: {
              display: false,
            },
            min: 0,
            beginAtZero: true,
            type: 'linear',
            position: 'right',
            title: {
              display: true,
              text: 'Commissions',
            },
            ticks: {
              precision: 0,
            },
          },
          transactions: {
            min: 0,
            beginAtZero: true,
            type: 'linear',
            position: 'left',
            title: {
              display: true,
              text: 'Transactions',
            },
            ticks: {
              precision: 0,
            },
          },
        },
      }}
      data={formattedData}
    />
  );
};

export default LineChart;
