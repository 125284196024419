import React from 'react';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

import {
  AppWrapper, Button, ErrorBanner, PageTitle, Pagination, RecordsDropdown, Table,
} from '../../../../components';
import { path } from '../../../../utils';
import { CHANGE_HISTORY } from './enums';
import { useChangeHistory } from './hooks';
import { columns } from './contracts';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type FintelCheckChangeHistoryProps = DefaultPropsType;

const FintelCheckChangeHistory = ({ permissionsCodeList = [] }: FintelCheckChangeHistoryProps) => {
  const hook = useChangeHistory();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        message={hook.hookErrorMessage}
        isOpen={!!hook.hookErrorMessage}
      />
      { hook.hookContext.merchantName && (
      <PageTitle>
        { hook.hookContext.merchantName }
      </PageTitle>
      )}
      <Styled.HeaderStyled>
        <PageTitle isLoading={hook.hookLoading}>
          { hook.hookContext.publisherId && (hook.hookContext.publisherId !== hook.hookContext.merchantId)
            ? `${hook.hookContext.publisherId} - ${hook.hookContext.publisherName}`
            : hook.hookContext.referralUrl}
        </PageTitle>
        <Button
          onClick={hook.hookBackButtonhandler}
          theme="secondary"
        >
          {CHANGE_HISTORY.BACK}

        </Button>
      </Styled.HeaderStyled>
      <Styled.PageTitle>{path.fintelCheckChangeHistory.name}</Styled.PageTitle>
      <Styled.HeaderStyled>
        <RecordsDropdown
          selected={hook.hookRecords}
          onChange={hook.hookSetRecords}
          currentPage={hook.hookCurrentPage}
          totalRecords={hook.hookTotalRecords}
        />

        <Button
          onClick={hook.hookDownloadCSVHandler}
          theme="tertiary"
        >
          <Styled.IconStyled icon={faFileArrowDown} />
          {CHANGE_HISTORY.DOWNLOAD_CSV}
        </Button>
      </Styled.HeaderStyled>

      <Table
        columns={columns}
        data={hook.hookData}
        onSort={hook.hookOnSortHandler}
        sortColumn={hook.hookSortColumn}
        isLoading={hook.hookLoading}
      />
      <Pagination
        currentPage={hook.hookCurrentPage}
        total={hook.hookTotalPages}
        onPageChange={hook.hookSetCurrentPageHandler}
      />
    </AppWrapper>
  );
};

export default FintelCheckChangeHistory;
