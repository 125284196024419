import { gql } from '@apollo/client';

export const SHOW_PROGRAM = gql`
  query Programs($input: ShowMerchantInput) {
    showMerchant(input: $input) {
      programs {
        id
      }
    }
  }
`;
