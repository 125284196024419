export type FintelCheckReducerProps = {
  merchantId: number
  publisherId: number
  isMerchantAll: boolean
  publisherName: string
  productId: number
  productName: string
  ruleId: number
  ruleName: string
  date: string
  zipFile: string
  fintelCheckSummaryId: string
  productCategory: SelectOption
  product: SelectOption
  status: SelectOption
  publisher: SelectOption
  startDate: string
  endDate: string
}

export const FINTEL_CHECK_ACTIONS = {
  SET_MERCHANT_ID: Symbol('set merchant id'),
  SET_PUBLISHER_ID: Symbol('set publisher id'),
  SET_IS_MERCHANT_ALL: Symbol('set is merchant all'),
  SET_PUBLISHER_NAME: Symbol('set publisher name'),
  SET_PRODUCT_ID: Symbol('set product id'),
  SET_PRODUCT_NAME: Symbol('set product name'),
  SET_RULE_ID: Symbol('set rule id'),
  SET_RULE_NAME: Symbol('set rule name'),
  SET_DATE: Symbol('set date'),
  SET_ZIP_FILE: Symbol('set zip file'),
  SET_PRODUCT_CATEGORY: Symbol('set product category'),
  SET_PRODUCT: Symbol('set product'),
  SET_STATUS: Symbol('set status'),
  SET_PUBLISHER: Symbol('set publisher'),
  SET_MERCHANT: Symbol('set merchant'),
  SET_START_DATE: Symbol('set start date'),
  SET_END_DATE: Symbol('set end date'),

  SET_DATA_FROM_SUMMARY_REPORT: Symbol('set data from summary report'),
  SET_DATA_FROM_DETAILS_REPORT: Symbol('set data from details report'),
};

export const useFintelCheckReducer = (state: any, { type, data }: BaseReducerType) => {
  switch (type) {
    case FINTEL_CHECK_ACTIONS.SET_MERCHANT_ID:
      return {
        ...state,
        merchantId: data,
      };
    case FINTEL_CHECK_ACTIONS.SET_IS_MERCHANT_ALL:
      return {
        ...state,
        isMerchantAll: data,
      };
    case FINTEL_CHECK_ACTIONS.SET_PUBLISHER_ID:
      return {
        ...state,
        publisherId: data,
      };
    case FINTEL_CHECK_ACTIONS.SET_PUBLISHER_NAME:
      return {
        ...state,
        publisherName: data,
      };
    case FINTEL_CHECK_ACTIONS.SET_START_DATE:
      return {
        ...state,
        startDate: data,
      };
    case FINTEL_CHECK_ACTIONS.SET_END_DATE:
      return {
        ...state,
        endDate: data,
      };
    case FINTEL_CHECK_ACTIONS.SET_DATA_FROM_SUMMARY_REPORT:
      return {
        ...state,
        publisherId: data.publisherId,
        publisherName: data.publisherName,
        merchantId: data.merchantId,
        checkDate: data.checkDate,
        merchantName: data.merchantName || '',
      };
    case FINTEL_CHECK_ACTIONS.SET_DATA_FROM_DETAILS_REPORT:
      return {
        ...state,
        ruleName: data.ruleName,
        productId: data.productId,
        productName: data.productName,
        referralUrl: data.referralUrl,
        fintelCheckSummaryId: data.fintelCheckSummaryId,
      };
    case FINTEL_CHECK_ACTIONS.SET_DATE:
      return {
        ...state,
        date: data,
      };
    case FINTEL_CHECK_ACTIONS.SET_ZIP_FILE:
      return {
        ...state,
        zipFile: data,
      };
    case FINTEL_CHECK_ACTIONS.SET_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategory: data,
      };
    case FINTEL_CHECK_ACTIONS.SET_PRODUCT:
      return {
        ...state,
        product: data,
      };
    case FINTEL_CHECK_ACTIONS.SET_STATUS:
      return {
        ...state,
        status: data,
      };
    case FINTEL_CHECK_ACTIONS.SET_PUBLISHER:
      return {
        ...state,
        publisher: data,
      };
    default:
      return { ...state };
  }
};
