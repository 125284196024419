export const URLS_LIST = {
  BUTTONS: {
    ADD: 'Add URL',
    DOWNLOAD: 'Download CSV',
    REMOVE: 'Remove URLs',
    CLEAR: 'Clear Form',
  },
  PLACEHOLDERS: {
    INPUT: 'https://www.fintelconnect.com',
  },
  MODAL: {
    TITLE: {
      ADD: 'Add URL',
      EDIT: 'Edit URL',
      REMOVE: 'Remove URL',
    },
    BUTTON: {
      ADD: 'Add',
      EDIT: 'Save',
      REMOVE: 'Remove',
      CANCEL: 'Cancel',
    },
    TYPES: {
      ADD: 'ADD',
      EDIT: 'EDIT',
      REMOVE: 'REMOVE',
      REMOVE_MANY: 'REMOVE_MANY',
    },
  },
  ERROR: {
    DOMAIN_COLLISION: 'URL already in Manual URL list',
  },
};
