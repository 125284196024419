import { useEffect, useState } from 'react';

export const useSnapshot = () => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();
  const first = months.slice(0, month + 1);
  const second = months.slice(month + 1, months.length);

  const monthLabels = second.concat(first);

  if (monthLabels[0] === 'Jan') {
    monthLabels[0] += `/${year}`;
  } else {
    monthLabels[0] += `/${year - 1}`;
  }
  monthLabels[monthLabels.length - 1] += `/${year}`;
  const width = (document.getElementById('transactionsSnapshotChart')?.getBoundingClientRect().width || 980);
  const [chartSize, setChartSize] = useState<number>(width - 60);

  useEffect(() => {
    const calcChartSize = () => {
      const testTarget = document.getElementById('transactionsSnapshotChart');
      const widthTest = testTarget?.getBoundingClientRect();
      setChartSize((widthTest?.width || 980) - 60);
    };
    const detectMenuClose = () => {
      setTimeout(() => {
        const testTarget = document.getElementById('transactionsSnapshotChart');
        const widthTest = testTarget?.getBoundingClientRect()?.width || 980;
        if (widthTest + 60 !== chartSize) {
          setChartSize((widthTest || 980) - 60);
        }
      }, 400);
    };

    window.addEventListener('mousedown', detectMenuClose);
    window.addEventListener('resize', calcChartSize);

    return () => {
      window.removeEventListener('resize', calcChartSize);
      window.removeEventListener('mousedown', detectMenuClose);
    };
  }, []);

  return {
    hookMonths: monthLabels,
    hookChartSize: chartSize,
  };
};
