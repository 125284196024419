export const MERCHANT_INVITATIONS = {
  TITLE: 'Here you can take action on pending invitations from active merchants to join their programs.',
  FILTERS: 'Filters',
  BUTTON: {
    CLEAR: 'Clear Form',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    TERMS: 'View Terms and Conditions',
  },
  NO_RECORDS_FOUND: {
    TITLE: 'No records found',
    SUBTITLE: 'When you receive new invitations from active merchants to join their programs, they will show up here.',
  },
  FOOTER: {
    TEXT: 'Merchant Invitation',
    TOOLTIP: 'When set as Invites on Hold, you should not receive any invitation from any merchants.',
  },
  MODAL: {
    TITLE: 'Invites on Hold',
    DESC: 'You will no longer receive any more invitations when you choose Invites on Hold.',
    DESC_2: 'Are you sure you want to save this change?',
    MESSAGE_SUBJECT: 'Subject',
    MESSAGE: 'Message',
    MESSAGE_TITLE: 'Invitation from',
  },
  TOAST_MESSAGE: 'All invites are now on hold. Choose "Open to Invites" to receive invitations again.',
};
