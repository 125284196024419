import styled from 'styled-components';
import AceEditor from 'react-ace';
import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

type WrapperProps = {
  readonly width: string;
}

export const WrapperStyled = styled.label<WrapperProps>`
  width: ${({ width }) => (width)};
  height: 50px;
`;

export const LabelStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: ${toRem(11)};
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12M}
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
  margin-left: ${toRem(4)};
`;

export const AceStyled = styled(AceEditor)`
  display: flex;
  border: 1px solid ${colors.color19};
  border-radius: 2px;
  width: 100%;
`;

export const ErrorStyled = styled.div`
  color: ${colors.color14};
  ${fonts.lato.H14}
  margin-top: 0.5rem;
`;
