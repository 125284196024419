import { gql } from '@apollo/client';

export const TRACKINGS_DOMAIN = gql`
  query trackingDomains($input: TrackingDomainInput) {
      trackingDomains(input: $input) {
        domains {
          id
          trackingProfileId
          trackingDomainUrl
          firstActiveDate
          lastActiveDate
        }
        count
      }
    }
`;
