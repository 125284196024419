import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AppWrapper, Calendar, Checkbox, ErrorBanner, InputCalendar, InputText, PageTitle, Select,
} from '../../../../components';
import { path } from '../../../../utils';
import { useCreateCampaign } from './hooks';
import {
  BUTTON_TEXT, LABELS, NAMES, PLACEHOLDERS, REQUIRED_TEXT,
} from '../utils';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type CreateCampaignProps = DefaultPropsType;

const CreateCampaign = ({ permissionsCodeList = [] }: CreateCampaignProps) => {
  const navigate = useNavigate();
  const { hookIsReadOnlyList, ...hook } = useCreateCampaign(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        isOpen={!hook.hookValidated}
        message={REQUIRED_TEXT(true)}
      />
      <Styled.HeaderStyled>
        <PageTitle>{path.createCampaign.name}</PageTitle>
      </Styled.HeaderStyled>

      <Styled.FieldsStyled>
        <InputText
          type="text"
          name={NAMES.CAMPAIGN_NAME}
          label={LABELS.CAMPAIGN_NAME}
          required
          value={hook.hookCampaignName}
          disabled={hook.hookLoading || isReadOnly}
          onChange={hook.hookSetCampaignName}
          error={hook.hookCreateCampaignErrors.campaignName}
          onBlur={hook.hookHandleValidation}
        />
        <Select
          name={NAMES.STATUS}
          label={LABELS.STATUS}
          required
          options={hook.hookStatusOptions}
          selected={hook.hookStatus}
          isDisabled={hook.hookLoading || isReadOnly}
          isLoading={hook.hookLoading}
          onChange={hook.hookSetStatus}
          error={hook.hookCreateCampaignErrors.status}
          placeholder={PLACEHOLDERS.STATUS}
        />
        <Styled.TextareaStyled
          height="150px"
          name={NAMES.DESCRIPTION}
          label={LABELS.DESCRIPTION}
          value={hook.hookDescription}
          disabled={hook.hookLoading || isReadOnly}
          onChange={hook.hookSetDescription}
          error={hook.hookCreateCampaignErrors.description}
          onBlur={hook.hookHandleValidation}
          required
        />
        <Styled.DatesAreaStyled>
          <InputCalendar
            label={LABELS.START_DATE}
            placeholder={PLACEHOLDERS.DATE}
            required
            value={hook.hookStartDate?.toDateString() || ''}
            onClick={hook.hookOnOpenStartCalendar}
            isDisabled={hook.hookLoading || isReadOnly}
            error={hook.hookCreateCampaignErrors.startDate}
          />
          <InputCalendar
            label={LABELS.END_DATE}
            placeholder={PLACEHOLDERS.DATE}
            value={hook.hookEndDate?.toDateString() || ''}
            onClick={hook.hookOnOpenEndCalendar}
            error={hook.hookCreateCampaignErrors.endDate}
            isDisabled={hook.hookLoading || isReadOnly}
          />
        </Styled.DatesAreaStyled>
        <Select
          name={NAMES.TAGS}
          label={LABELS.TAGS}
          placeholder={PLACEHOLDERS.TAGS}
          selectedMulti={hook.hookTags}
          options={hook.hookTagsOptions}
          onChange={hook.hookSetTags}
          isDisabled={hook.hookLoading || isReadOnly}
          isLoading={hook.hookLoading}
          isMulti
          creatable
        />
        <Select
          name={NAMES.PRODUCTS}
          label={LABELS.PRODUCT}
          placeholder={PLACEHOLDERS.PRODUCT}
          selected={hook.hookProducts}
          onChange={hook.hookSetProducts}
          options={hook.hookProductsOptions}
          isDisabled={hook.hookLoading || isReadOnly}
          isLoading={hook.hookLoading}
        />
        <InputText
          type="url"
          name={NAMES.DEFAULT_URL}
          label={LABELS.DEFAULT_URL}
          value={hook.hookDefaultLandingPageUrl}
          onChange={hook.hookSetDefaultLandingPageUrl}
          placeholder={hook.hookUrlPlaceholder}
          error={hook.hookLandingUrlError}
          onBlur={hook.hookValidateWebsite}
          disabled={hook.hookLoading || isReadOnly}
        />
        <Select
          name={NAMES.LANGUAGE}
          label={LABELS.LANGUAGE}
          placeholder={PLACEHOLDERS.LANGUAGE}
          required
          selected={hook.hookLanguage}
          options={hook.hookLanguageOptions}
          onChange={hook.hookSetLanguage}
          error={hook.hookCreateCampaignErrors.language}
          isDisabled={hook.hookLoading || isReadOnly}
          isLoading={hook.hookLoading}
        />
        <InputText
          type="url"
          name={NAMES.THIRD_PARTY_URL}
          label={LABELS.THIRD_PARTY_URL}
          value={hook.hookThirdPartyTrackingUrl}
          onChange={hook.hookSetThirdPartyTrackingUrl}
          error={hook.hookThirdPartyUrlError}
          onBlur={hook.hookValidateWebsite}
          disabled={hook.hookLoading || isReadOnly}
        />
      </Styled.FieldsStyled>

      <Styled.SectionTitleStyled>{LABELS.PUBLISHER_GROUPS}</Styled.SectionTitleStyled>
      <Styled.PublisherGroupStyled>
        {hook.hookPublisherGroupOptions.map((publisherGroup) => (
          <Checkbox
            label={publisherGroup.label}
            checked={publisherGroup.checked}
            onChange={() => hook.hookSetPublisherGroupHandler(publisherGroup)}
            disabled={hook.hookLoading || isReadOnly}
          />
        ))}
      </Styled.PublisherGroupStyled>

      <Styled.FooterStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => navigate(-1)}
          disabled={hook.hookLoading}
        >
          {BUTTON_TEXT.CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          onClick={hook.hookSubmitCampaign}
          disabled={hook.hookLoading || isReadOnly}
        >
          {BUTTON_TEXT.CREATE}
        </Styled.ButtonStyled>
      </Styled.FooterStyled>

      <Calendar
        date={hook.hookStartDate}
        onApply={hook.hookOnApplyStartCalendar}
        onCancel={hook.hookOnCancelStartCalendar}
        isOpen={hook.hookStartCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
      />

      <Calendar
        date={hook.hookEndDate}
        onApply={hook.hookOnApplyEndCalendar}
        onCancel={hook.hookOnCancelEndCalendar}
        isOpen={hook.hookEndCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
      />
    </AppWrapper>
  );
};

export default CreateCampaign;
