import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AddCommissionModal, AppWrapper, Calendar, ErrorBanner, InputCalendar, PageTitle, Pagination, RecordsDropdown, Table,
} from '../../../../components';
import {
  PRODUCT_CATEGORY, RECORDS_PER_PAGE_OPTIONS, path,
} from '../../../../utils';
import { columns } from './contracts';
import { useManageCommissions } from './hooks';
import { BUTTON_LABELS, CONFIRMATION_MODAL } from './utils';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';
import { ConfirmationModal } from '../../../../components/Modal/ConfirmationModal';
import PlaceHolderNoResults from '../../../../components/NoResultsPlaceHolder';

type ManageCommissionsProps = DefaultPropsType;

const ManageCommissions = ({ permissionsCodeList = [] }: ManageCommissionsProps) => {
  const { hookIsReadOnlyList, ...hook } = useManageCommissions(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ConfirmationModal
        isOpen={hook.showDeleteConfirmation}
        onClose={() => hook.setShowDeleteConfirmation(false)}
        onCloseText={CONFIRMATION_MODAL.CANCEL}
        onConfirm={hook.deleteCommission}
        onConfirmText={CONFIRMATION_MODAL.DELETE}
        title={CONFIRMATION_MODAL.TITLE}
        desc={`${CONFIRMATION_MODAL.DESCRIPTION} ${hook.selectedCommission?.id} - ${hook.selectedCommission?.payable}?`}
        width="450px"
      />
      <ErrorBanner
        isOpen={!!hook.hookErrorMessage}
        message={hook.hookErrorMessage}
      />
      <Styled.HeaderStyled>
        <PageTitle>{path.manageCommissions.name}</PageTitle>
        <Styled.ButtonsStyled>
          <Styled.ButtonStyled
            onClick={hook.hookSetAddModal}
            disabled={isReadOnly}
          >
            <FontAwesomeIcon icon={faPlus} />
            {BUTTON_LABELS.ADD_COMMISSION}
          </Styled.ButtonStyled>
        </Styled.ButtonsStyled>
      </Styled.HeaderStyled>

      <Styled.FiltersStyled>
        <Styled.FiltersSelectStyled>
          <InputCalendar
            label="Active since"
            placeholder="Select Date"
            value={hook.selectedDate ? hook.selectedDate.toDateString() : ''}
            onClick={hook.openCalendar}
            isDisabled={hook.hookIsLoading}
          />
          <Styled.SelectStyled
            name="Product Category"
            label="Product Category"
            placeholder="Select Product Category"
            onChange={hook.onProductCategoryChange}
            selected={hook.productCategory}
            options={PRODUCT_CATEGORY}
            isDisabled={hook.hookIsLoading}
          />
          <Styled.SelectStyled
            name="Product"
            label="Product"
            placeholder="Select Product"
            onChange={hook.onProductChange}
            selected={hook.selectedProduct}
            options={hook.productList.map((p: { id: string; name: string; }) => ({
              label: p.name,
              value: p.id,
            }))}
            isDisabled={hook.hookIsLoading || hook.getProductsLoading}
          />
        </Styled.FiltersSelectStyled>
        <Styled.FiltersSelectStyled>
          <Styled.SelectStyled
            name="Publisher"
            label="Publisher ID - Name"
            placeholder="Select Publisher"
            onChange={hook.setSelectedPublisherMember}
            selected={hook.selectedPublisherMember}
            options={hook.publisherMembers}
            isDisabled={hook.hookIsLoading || hook.getMembershipsLoading}
          />
          <Styled.SelectStyled
            name="Publisher Group"
            label="Publisher Group"
            placeholder="Select Publisher Group"
            onChange={hook.setSelectedPublisherGroup}
            selected={hook.selectedPublisherGroup}
            options={hook.publisherGroups}
            isDisabled={hook.hookIsLoading || hook.getPublisherGroupsLoading}
          />
          <Styled.SelectStyled
            name="Commission Type"
            label="Commission Type"
            placeholder="Select Commission Type"
            onChange={hook.setSelectedCommissionType}
            selected={hook.selectedCommissionType}
            options={hook.commissionTypes}
            isDisabled={hook.hookIsLoading}
          />
        </Styled.FiltersSelectStyled>

        <Styled.RowStyled>
          <RecordsDropdown
            selected={hook.records}
            onChange={hook.setRecords}
            options={RECORDS_PER_PAGE_OPTIONS}
            totalRecords={hook.totalRecords}
            currentPage={hook.hookCurrentPage}
          />
          <Styled.ClearFormButtonStyled
            onClick={hook.onClearFormClick}
            type="reset"
            theme="text-only"
            disabled={hook.hookIsLoading}
          >
            Clear Form
          </Styled.ClearFormButtonStyled>
        </Styled.RowStyled>
      </Styled.FiltersStyled>

      {hook.hookCommissionsData.length > 0 || hook.hookIsLoading ? (
        <>
          <Table
            columns={columns(hook.deleteCommissionHandler, hook.hookOnRowClick)}
            data={hook.hookCommissionsData}
            isLoading={hook.hookIsLoading}
            loadWithData={hook.hookCommissionsData.length > 0}
            onSort={hook.onSortHandler}
            sortColumn={hook.sortColumn}
          />
          {hook.hookCommissionsData.length > 0 && (
          <Pagination
            currentPage={hook.hookCurrentPage}
            total={hook.hookTotalPages}
            onPageChange={hook.hookSetCurrentPage}
          />
          )}
        </>
      ) : (
        <PlaceHolderNoResults />
      )}

      <AddCommissionModal
        isOpen={hook.hookAddModal}
        onClose={hook.hookSetAddModal}
      />

      <Calendar
        date={hook.selectedDate}
        onApply={hook.onApplySelectedCalendar}
        onCancel={hook.onCancelSelectedCalendar}
        isOpen={hook.calendarIsOpen}
        hasRange={false}
        isSingle
      />
    </AppWrapper>
  );
};

export default ManageCommissions;
