import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_TRACKING_PROFILES } from '../graphql/queries/getTrackingProfiles';
import { useAddTrackingProfile } from './useAddTrackingProfile';
import { useAccountInformation } from './useAccountInformation';

export const useTrackingProfiles = () => {
  const { hookUser } = useAccountInformation();
  const [tableProfile, setTableProfile] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState<TableSortColumn>({ column: 'id', direction: 'asc' });
  const [listProfiles, { loading }] = useLazyQuery(GET_TRACKING_PROFILES);
  const modalHook = useAddTrackingProfile(setTableProfile);

  const listProfilesHandler = async () => {
    if (hookUser?.id) {
      const { data } = await listProfiles({
        variables: {
          input: {
            filter: {
              companyId: hookUser.id.toString(), // internal publisher id
            },
            options: {
              page: currentPage,
              order: sortBy.direction?.toUpperCase(),
              items: 10,
            },
            sortBy: sortBy.column,
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (data?.listTrackings?.trackings) {
        const dataFormatted = data?.listTrackings.trackings.map((item: any) => ({
          ...item,
          profileName: {
            name: item.profileName,
            website: item.primaryWebsite,
            description: item.websiteDescription,
          },
          createdAt: item.createdAt,
          lastActive: item.lastActive,
        }));
        setTableProfile(dataFormatted);
      }

      if (data?.listTrackings?.trackingsCount) {
        setTotalPages(Math.ceil(data.listTrackings.trackingsCount / 10));
      } else {
        setCurrentPage(1);
        setTotalPages(0);
      }
    }
  };

  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page);
  };

  const setSortByHandler = (dataField: string, direction: any) => {
    if (sortBy.direction === null) {
      setSortBy({ column: dataField, direction });
    } else {
      setSortBy({ column: dataField, direction: sortBy.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  useEffect(() => {
    listProfilesHandler();
  }, [hookUser, currentPage, sortBy]);

  return {
    hookLoading: loading,
    hookTrackingProfilesData: tableProfile,
    hookAddProfile: modalHook,

    hookSortBy: sortBy,
    hookSetSortBy: setSortByHandler,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,
    hookTotalPages: totalPages,
  };
};
