import { gql } from '@apollo/client';

export const LIST_PROGRAMS = gql`
  query listPublisherPrograms($input: ListSearchProgramInput!) {
    listPublisherPrograms(input: $input) {
      programs {
        id
        merchantId
        company {
          companyName
          companyUrl
          companyImgUrl
        }
        publisherMembershipStatus
        productCategories
        targetMarkets
      }
      count
    }
  }
`;
