import styled from 'styled-components';
import { Button, InputText, Select } from '../../../components';
import { fonts } from '../../../styles/theme';

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const InputTextStyled = styled(InputText)`
  margin-right: 2rem;
  width: 80%;
`;

export const SelectStyled = styled(Select)`
  margin-left: 1rem;
`;

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

export const HeaderButtonsAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    margin-right: 0.5rem;
  }
`;

export const FiltersSelectStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20%;

  span {
    margin-right: 1rem;
    ${fonts.lato.H14}
  }
`;

export const FiltersStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const OptionsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const RecordsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
