import { gql } from '@apollo/client';

export const CREATE_GROUP = gql`
mutation createGroup($input: GroupInput!) {
  createGroup(input: $input) {
    group {
      id
      name
    }
  }
}
`;
