import styled from 'styled-components';
import {
  Button, InputCalendar, InputText, Select,
} from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

type TableDataStyledProps = {
  readonly smallText?: boolean
}

export const SubtitleStyled = styled.div`
  margin-top: ${toRem(24)};
  margin-bottom: ${toRem(24)};
  ${fonts.gotham.H14M};
  color: ${colors.color1};
`;

export const FiltersStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${toRem(24)};
  margin-bottom: ${toRem(32)};
`;

export const InputTextStyled = styled(InputText)`
  grid-column: 1 / 11;
`;

export const ButtonStyled = styled(Button)`
  grid-column: 11 / 13;
`;

export const InputCalendarStyled = styled(InputCalendar)`
  &:first-of-type {
    grid-column: 1 / 3;
  }

  &:nth-of-type(2) {
    grid-column: 3 / 5;
  }
`;

export const SelectStyled = styled(Select)`
  &:nth-of-type(3) {
    grid-column: 5 / 9;
  }

  &:last-of-type {
    grid-column: 9 / 13;
  }
`;

export const FooterWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RecordsCountStyled = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${colors.color1};
    ${fonts.lato.H14}
  }
`;

export const CounterSelectStyled = styled(Select)`
  margin-left: 1rem;
  width: ${toRem(100)};
`;

export const FooterButtonAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterButtonWrapperStyled = styled.div`
  margin-right: ${toRem(16)};
`;

export const IconWrapperStyled = styled.span`
  margin-right: ${toRem(8)};
`;

export const TableDataStyled = styled.div<TableDataStyledProps>`
  width: 100%;
  max-width: 100%;
  ${({ smallText }) => (smallText ? fonts.lato.H12 : fonts.lato.H14)}
  min-height: ${toRem(48)};
  line-height: ${toRem(18.75)};
  white-space: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CheckBoxCellStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${fonts.lato.H14}
  color: ${colors.color1};
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-x: auto;
  overflow-y: visible;
  line-height: 1.5;
`;

export const TableCellLinkStyled = styled.a`
  color: ${colors.color2};
`;

export const CellTableSubTextStyled = styled.p`
  ${fonts.lato.H12}
  color: ${colors.color7};
`;

export const ModalBodyStyled = styled.div`
  padding: ${toRem(24)};
  ${fonts.lato.H14};
  max-width: ${toRem(500)};
  line-height: ${toRem(18)};
`;

export const ModalTitleStyled = styled.p`
  ${fonts.gotham.H24M};
  margin-bottom: ${toRem(24)};
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: ${toRem(16)};
`;

export const ModalCompanyStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${toRem(8)};
  ${fonts.lato.H16B};
  color: ${colors.color1};
  margin: ${toRem(8)} 0 ${toRem(16)};
`;
