import { gql } from '@apollo/client';

export const CREATE_NEW_ROLE = gql`
  mutation CreateNewRole($input: NewRoleInput) {
    createNewRole(input: $input) {
      role {
        name
        id
      }
    }
  }
`;
