import React from 'react';
import { TABLE_FOOTER } from '../../../../../utils';
import { formatAmount } from '../../../../../utils/amountFormatter';

export const columns = (isMerchant: boolean): TableColumn[] => [
  {
    dataField: isMerchant ? 'publisher' : 'merchant',
    text: isMerchant ? 'publisher' : 'merchant',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '175px',
    footer: TABLE_FOOTER,
    wrapText: true,
  },
  {
    dataField: 'transactionId',
    text: 'transaction id',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '150px',
  },
  {
    dataField: 'orderId',
    text: 'order id',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '225px',
    wrapText: true,
  },
  {
    dataField: 'recordDate',
    text: 'record date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '175px',
  },
  {
    dataField: 'productName',
    text: 'product name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '175px',
  },
  {
    dataField: 'grossSales',
    text: 'gross sales',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '150px',
    formatter: (value: any) => (
      <div>{formatAmount(value)}</div>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <div>{formatAmount(footerData?.grossSales)}</div>
    ),
  },
  {
    dataField: 'netSales',
    text: 'net sales',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '150px',
    formatter: (value: any) => (
      <div>{formatAmount(value)}</div>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <div>{formatAmount(footerData?.netSales)}</div>
    ),
  },
  {
    dataField: 'commissionId',
    text: 'commission id',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '150px',
  },
  {
    dataField: 'recordType',
    text: 'record type',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '150px',
  },
  {
    dataField: 'amount',
    text: 'amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '150px',
    formatter: (value: any) => (
      <div>{formatAmount(value)}</div>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <div>{formatAmount(footerData?.amounts)}</div>
    ),
  },
];
