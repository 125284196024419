import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useRef, useState } from 'react';
import { useSignUp } from '../../hooks';
import { CHECK_EMAIL, CREATE_PUBLISHER } from '../../graphql';
import { path } from '../../../../../utils';
import { USER_INFO } from '../../UserInfo/enums';
import { CONFIRMATION } from '../enums';

export const useConfirmation = () => {
  const { contextState, contextDispatcher } = useSignUp();
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [createPublisher, { loading: createPublisherLoading }] = useMutation(CREATE_PUBLISHER);
  const [checkEmail, { loading: checkEmailLoading }] = useLazyQuery(CHECK_EMAIL);

  const navigateHandler = (url: string) => {
    navigate(url);
  };

  const captchaRef = useRef();

  const captchaHandler = (value: any) => {
    captchaRef.current = value;
    if (value) setNextDisabled(false);
    else setNextDisabled(true);
  };

  const formatContext = {
    auth: {
      email: contextState.userEmail,
      password: contextState.userPassword,
    },
    company: {
      accountStatus: 'Pending',
      companyName: contextState.companyName,
      companyEmail: contextState.paymentEmail,
      companyUrl: contextState.companyUrl,
      phone: contextState.companyPhone,
      address1: contextState.companyAddress1,
      address2: contextState.companyAddress2,
      zip: contextState.companyZIP,
      country: contextState.companyCountry.value,
      state: contextState.companyState.value,
      city: contextState.companyCity,
      paymentInfo: {
        currency: contextState.paymentCurrency.value,
        payment: contextState.paymentMethod.value,
        payableTo: contextState.paymentPayable,
        email: contextState.paymentEmail,
        phone: contextState.paymentPhone,
        address1: contextState.paymentAddress1,
        address2: contextState.paymentAddress2,
        city: contextState.paymentCity,
        zip: contextState.paymentZip,
        country: contextState.paymentCountry.value,
        state: contextState.paymentState.value,
        gst: contextState.paymentGST,
        pst: contextState.paymentPST,
        hst: contextState.paymentHST,
        tax: contextState.paymentTAX,
        gstStatus: contextState.paymentGstStatus,
        pstStatus: contextState.paymentPstStatus,
        hstStatus: contextState.paymentHstStatus,
        taxStatus: contextState.paymentTaxStatus,
        accountHolder: contextState.accountHolder,
        accountEmail: contextState.accountEmailAddress,
        accountPhoneNumber: contextState.paymentMethod.value === 'Paypal' ? contextState.paymentPhone : '',
        bankName: contextState.bankName,
        bankAccountNumber: contextState.bankAccountNumber,
        bankCode: contextState.swiftCode,
        transit: contextState.transit,
        wireTransferName: contextState.wireTransferName,
      },
      overview: {
        businessDesc: contextState.businessDescription,
        profilePhotoURL: contextState.profilePhotoURL,
        primaryPlatformLink: contextState.companyUrl,
        hasWebsite: contextState.hasWebsite,
        audienceMarkets: [contextState.audienceMarket.value], // array ???
        avgMonthlyAudience: contextState.averageMonthlyAudience.value,
        productCategories: contextState.productCategories.filter((category) => category.checked).map((category) => category.label),
        promotionMethods: contextState.promotionMethods.filter((method) => method.checked).map((method) => method.label),
        audienceAges: [contextState.audienceAges.value], // array??
        gender: contextState.gender.value,
        creditScoreRating: contextState.creditScoreRating.value,
        avgIncomes: [contextState.averageIncome.value], // array ??
      },
    },
    trackingProfile: {
      primaryWebsite: contextState.companyUrl,
    },
    user: {
      firstName: contextState.userFirstName,
      lastName: contextState.userLastName,
      email: contextState.userEmail,
      preferredLanguage: contextState.preferredLanguage.value,
      subscribeUpdates: contextState.subscribeUpdates,
      subscribeOffers: contextState.subscribeOffers,
    },
    memberships: contextState.programs,
    token: captchaRef?.current,
  };

  const createPublisherHandler = async () => {
    setErrorMessage('');
    // Check Email not already taken
    const { data: emailData, error } = await checkEmail({
      variables: {
        email: contextState.userEmail,
        checkInAuth0: true,
      },
      onError(err) {
        setErrorMessage(err.message);
      },
      fetchPolicy: 'no-cache',
    });
    if (error) {
      setErrorMessage(error.message);
      return;
    }

    // Stop if Email Taken
    if (!emailData || emailData.checkEmailExists) {
      setErrorMessage(USER_INFO.VALIDATIONS.EMAIL.EXISTS);
      return;
    }

    // Create new Publisher
    const { data: publisherData, errors: publisherErrors } = await createPublisher({
      variables: {
        input: {
          auth: formatContext.auth,
          company: formatContext.company,
          trackingProfile: formatContext.trackingProfile,
          user: formatContext.user,
          memberships: formatContext.memberships,
          token: formatContext.token,
        },
      },
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (publisherErrors) {
      setErrorMessage(publisherErrors[0].message);
      return;
    }

    // Show Confirmation if Publisher Created
    if (publisherData && publisherData.createNewPublisher && publisherData.createNewPublisher.publisher) {
      setShowConfirmation(true);
    } else {
      setErrorMessage(CONFIRMATION.ERROR.PUBLISHER);
    }
  };

  if (nextDisabled && contextState.agreesTC && contextState.subscribeUpdates && captchaRef?.current) {
    setNextDisabled(false);
  }
  if (!nextDisabled && (!contextState.agreesTC || !contextState.subscribeUpdates || !captchaRef?.current)) {
    setNextDisabled(true);
  }

  // Navigate back to first Page if Context is lost (eg. Reloaded Page)
  if (!contextState.userEmail) {
    navigate(path.createAccountStep1.href);
  }

  return {
    hookErrorMessage: errorMessage,
    hookContextState: contextState,
    hookContextDispatcher: contextDispatcher,
    hookCreatePublisher: createPublisherHandler,
    hookNavigate: navigateHandler,
    hookShowConfirmation: showConfirmation,

    hookCaptchaRef: captchaRef,
    hookCaptchaHandler: captchaHandler,

    hookNextDisabled: nextDisabled,
    hookNextLoading: createPublisherLoading || checkEmailLoading,
  };
};
