import React from 'react';
import { CheckRulesOutputType } from '../../types';
import { useDetailsModal } from './hooks';
import { RuleSummaryModal } from '../RuleSummaryModal';
import { ProductFeedModal } from '../ProductFeedModal';

type RuleDetailsModalProps = {
  value?: CheckRulesOutputType
  closeModalFn: (state: boolean) => void
  isOpen: boolean
}

export const RuleDetailsModal = ({
  value, closeModalFn, isOpen,
}: RuleDetailsModalProps) => {
  const hook = useDetailsModal(value);

  return (
    <>
      <RuleSummaryModal
        value={value}
        closeModalFn={() => closeModalFn(false)}
        isOpen={isOpen}
        errorMessage={hook.hookErrorMessage}
        feedLoading={hook.hookFeedLoading}
        parametersOpen={hook.hookParametersOpen}
        setParametersOpen={hook.hookSetParametersOpen}
      />
      <ProductFeedModal
        closeModalFn={() => hook.hookSetParametersOpen(false)}
        isOpen={hook.hookParametersOpen}
        tableData={hook.hookTableData}
      />
    </>
  );
};
