import React from 'react';
import {
  Button, Checkbox, ErrorBanner, InputText, LoadingDots, Select, Tooltip,
} from '../../../../components';
import Progress from '../../../../components/Progress';
import { imageList, path } from '../../../../utils';

import * as Styled from './styles';
import { usePublisher } from './hooks';
import { SIGN_UP_ACTIONS } from '../../reducers';
import { SIGN_UP } from '../enums';
import { PUBLISHER_INFO } from './enums';

const Publisher = () => {
  const hook = usePublisher();

  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img
            src={imageList.logoFull.src}
            alt="logo"
          />
        </figure>

        <Progress step={3} />
      </Styled.LeftStyled>

      <Styled.RightStyled>
        <Styled.RightContentStyled>
          <ErrorBanner
            message={hook.hookErrorMessage}
            isOpen={!!hook.hookErrorMessage}
          />
          <h1>{SIGN_UP.TITLE}</h1>
          <h2><pre>{`Step 3   ${path.createAccountStep3.name}`}</pre></h2>
          <h3>{PUBLISHER_INFO.DESCRIPTION}</h3>

          <Styled.FormStyled>
            <Styled.DropzoneStyled
              name={PUBLISHER_INFO.PROFILE_PHOTO.LABEL}
              label={PUBLISHER_INFO.PROFILE_PHOTO.LABEL}
              tooltip={PUBLISHER_INFO.PROFILE_PHOTO.TOOLTIP}
              maxFileSize={3000000} // 3 MB
              accept=".jpg, *.jpg, .png, *.png, .jpeg, *.jpeg"
              onChange={hook.hookSetProfilePhoto}
              type="ProfileImage"
            />

            <InputText
              label={PUBLISHER_INFO.COMPANY_URL.LABEL}
              required
              tooltip={PUBLISHER_INFO.COMPANY_URL.TOOLTIP}
              type="url"
              placeholder={PUBLISHER_INFO.COMPANY_URL.PLACEHOLDER}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => hook.hookContextDispatcher({
                type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                data: { companyUrl: e.target.value },
              })}
              value={hook.hookContextState.companyUrl}
              disabled={!hook.hookContextState.hasWebsite}
              error={hook.hookContextState.hasWebsite ? hook.hookUrlError : ''}
              onBlur={hook.hookValidateUrl}
            />
            <Styled.CheckboxStyled
              checked={!hook.hookContextState.hasWebsite}
              onChange={(e: boolean) => {
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { hasWebsite: !e },
                });
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { companyUrl: '' },
                });
              }}
              label={PUBLISHER_INFO.WEBSITE.LABEL}
            />

            <Styled.TextareaStyled
              label={PUBLISHER_INFO.BUSINESS_DESCRIPTION.LABEL}
              name={PUBLISHER_INFO.BUSINESS_DESCRIPTION.LABEL}
              placeholder={PUBLISHER_INFO.BUSINESS_DESCRIPTION.PLACEHOLDER}
              value={hook.hookContextState.businessDescription}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => hook.hookContextDispatcher({
                type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                data: { businessDescription: e.target.value },
              })}
              maxLength={1500}
            />

            <Select
              label={PUBLISHER_INFO.AUDIENCE_MARKET.LABEL}
              tooltip={PUBLISHER_INFO.AUDIENCE_MARKET.TOOLTIP}
              onChange={(e: SelectOption) => hook.hookContextDispatcher({
                type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                data: { audienceMarket: e },
              })}
              selected={hook.hookContextState.audienceMarket}
              options={PUBLISHER_INFO.PRIMARY_AUDIENCE_MARKETS}
            />
            <Select
              label={PUBLISHER_INFO.AUDIENCE_SIZE.LABEL}
              tooltip={PUBLISHER_INFO.AUDIENCE_SIZE.TOOLTIP}
              onChange={(e: SelectOption) => hook.hookContextDispatcher({
                type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                data: { averageMonthlyAudience: e },
              })}
              selected={hook.hookContextState.averageMonthlyAudience}
              options={PUBLISHER_INFO.AVERAGE_MONTHLY_AUDIENCE_SIZE}
            />

            <Styled.CheckboxWrapperStyled>
              <Styled.CheckboxWrapperLabelStyled>
                {PUBLISHER_INFO.PRODUCT_CATEGORIES.LABEL}
                <Tooltip
                  text={PUBLISHER_INFO.PRODUCT_CATEGORIES.TOOLTIP}
                />
              </Styled.CheckboxWrapperLabelStyled>

              <Styled.ProductCategoryStyled>
                {hook.hookPromotionMethodsLoading ? (
                  <LoadingDots />
                ) : (
                  hook.hookPublisherProductCategories.map((productCategory) => (
                    <Checkbox
                      key={productCategory.value}
                      label={productCategory.label === 'Businesses' ? 'Business Products' : productCategory.label}
                      checked={productCategory.checked}
                      onChange={() => hook.hookSetPublisherProductCategoriesHandler(productCategory)}
                    />
                  ))
                )}
              </Styled.ProductCategoryStyled>
            </Styled.CheckboxWrapperStyled>

            <Styled.CheckboxWrapperStyled>
              <Styled.CheckboxWrapperLabelStyled>
                {PUBLISHER_INFO.PROMOTION_METHODS.LABEL}
                <Tooltip
                  text={PUBLISHER_INFO.PROMOTION_METHODS.TOOLTIP}
                />
              </Styled.CheckboxWrapperLabelStyled>

              <Styled.ProductCategoryStyled>
                {hook.hookPromotionMethodsLoading ? (
                  <LoadingDots />
                ) : (
                  hook.hookPublisherPromotionMethods.map((promotionMethod) => (
                    <Checkbox
                      key={promotionMethod.value}
                      label={promotionMethod.label}
                      checked={promotionMethod.checked}
                      onChange={() => hook.hookSetPublisherPromotionMethodsHandler(promotionMethod)}
                    />
                  ))
                )}

              </Styled.ProductCategoryStyled>
            </Styled.CheckboxWrapperStyled>

            <Styled.DemographicsStyled>
              {PUBLISHER_INFO.DEMOGRAPHICS.TITLE}
            </Styled.DemographicsStyled>
            <Styled.DemographicsDescriptionStyled>
              {PUBLISHER_INFO.DEMOGRAPHICS.DESCRIPTION}
            </Styled.DemographicsDescriptionStyled>
            <Select
              label="audience age(s)"
              onChange={(e: SelectOption) => hook.hookContextDispatcher({
                type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                data: { audienceAges: e },
              })}
              selected={hook.hookContextState.audienceAges}
              options={PUBLISHER_INFO.AUDIENCE_AGES}
            />
            <Select
              label="gender"
              onChange={(e: SelectOption) => hook.hookContextDispatcher({
                type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                data: { gender: e },
              })}
              selected={hook.hookContextState.gender}
              options={PUBLISHER_INFO.GENDERS}
            />
            <Select
              label="average income(s)"
              onChange={(e: SelectOption) => hook.hookContextDispatcher({
                type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                data: { averageIncome: e },
              })}
              selected={hook.hookContextState.averageIncome}
              options={PUBLISHER_INFO.AVERAGE_INCOMES}
            />
            <Select
              label="credit score rating"
              onChange={(e: SelectOption) => hook.hookContextDispatcher({
                type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                data: { creditScoreRating: e },
              })}
              selected={hook.hookContextState.creditScoreRating}
              options={PUBLISHER_INFO.CREDIT_SCORE_RATINGS}
            />

            <Button
              onClick={() => hook.hookNavigate(path.createAccountStep2.href)}
              theme="secondary"
            >
              {PUBLISHER_INFO.BACK}
            </Button>
            <Button
              onClick={hook.hookNavigateNextPageHandler}
            >
              {PUBLISHER_INFO.NEXT}
            </Button>
          </Styled.FormStyled>
        </Styled.RightContentStyled>
      </Styled.RightStyled>
    </Styled.WrapperStyled>
  );
};

export default Publisher;
