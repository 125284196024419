import styled from 'styled-components';
import {
  InputText,
  Select,
  Table,
  Button,
} from '../../../../../../components';
import { fonts, colors } from '../../../../../../styles/theme';
import { toRem } from '../../../../../../utils';

type AdvancedSearchProps = {
  readonly isOpen: boolean;
};

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${toRem(24)};
`;

export const DescriptionStyled = styled.div`
  color: ${colors.color1};
  ${fonts.gotham.H14M}
  margin-bottom: 2rem;
  line-height: 1.5;
`;

export const FiltersStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
`;

export const InputTextStyled = styled(InputText)`
  grid-column: span 2;
`;

export const SelectStyled = styled(Select)`
  grid-column: span 1;
  
`;

export const LeftSideStyled = styled.div`
  grid-column: span 2;
  display: flex;
  align-items: center;
  color: ${colors.color1};
  ${fonts.lato.H16}
`;

export const RightSideStyled = styled.div`
  grid-column: span 1;
  justify-self: end;
`;

export const AdvancedSearchStyled = styled.div<AdvancedSearchProps>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1rem;
  grid-gap: 1.5rem;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  @keyframes slideIn {
    from {
      transform: translateY(100);
    }
  }
  animation-name: slideIn;
  animation-duration: 3s;

`;

export const TableStyled = styled(Table)`
  margin-top: 2rem;
`;

export const CellStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 72px;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  color: ${colors.color1};
  ${fonts.lato.H14};
`;

export const LinkStyled = styled.a`
  color: ${colors.color2};
  ${fonts.lato.H12};
`;

export const ButtonStyled = styled(Button)`
  ${fonts.lato.H14};
`;

export const SearchRowStyled = styled.div`
  margin-top: 1rem;
  margin-left: auto;
`;
