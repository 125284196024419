import React from 'react';
import * as Styled from './styles';
import { useManageUser } from '../../hooks';
import { PREFERED_LANGAUGE_OPTIONS } from '../../../../utils';

type AccountTabProps = {
  hook: ReturnType<typeof useManageUser>;
  add: boolean;
  isSelf?: boolean;
};

export const AccountTab = ({
  hook, add, isSelf,
}: AccountTabProps) => (
  <Styled.WrapperStyled>
    <Styled.InputTextStyled
      grids={1}
      type="text"
      label="First Name"
      required
      value={hook.hookFirstName}
      error={hook.hookErrors.firstName}
      onChange={hook.hookSetFirstName}
    />

    <Styled.InputTextStyled
      grids={1}
      type="text"
      label="Last Name"
      required
      value={hook.hookLastName}
      error={hook.hookErrors.lastName}
      onChange={hook.hookSetLastName}
    />

    <Styled.InputTextStyled
      grids={2}
      type="text"
      label="Email Address"
      required
      disabled={!add}
      value={hook.hookEmail}
      error={hook.hookErrors.email}
      onChange={hook.hookSetEmail}
    />

    {!add && (
      <Styled.ButtonWrapper>
        <Styled.ButtonStyled
          width="100%"
          theme="tertiary"
          onClick={hook.hookResetPassword}
        >
          Reset Password
        </Styled.ButtonStyled>
        {hook.hookSuccessMessagePassword}
      </Styled.ButtonWrapper>
    )}

    <Styled.InputPhoneStyled
      grids={1}
      label="Phone Number"
      type="text"
      value={hook.hookPhone}
      onChange={hook.hookSetPhone}
      error={hook.hookErrors.phone}
    />

    <Styled.SelectStyled
      isDisabled={!add && !isSelf}
      grids={1}
      label="Preferred Language"
      onChange={hook.hookSetLanguage}
      options={PREFERED_LANGAUGE_OPTIONS}
      selected={hook.hookLanguage}
    />

    <Styled.SelectStyled
      grids={2}
      label="Security Role"
      required
      isDisabled={!hook.hookEditPermissions && !add}
      onChange={hook.hookSetUserSubTypes}
      options={hook.hookUserSubTypeOptions}
      selectedMulti={hook.hookUserSubTypes}
      isMulti
      error={hook.hookErrors.role}
      isLoading={hook.hookSecurityRolesLoading}
    />

    <Styled.InputTextStyled
      grids={1}
      type="text"
      label="Position"
      value={hook.hookPosition}
      onChange={hook.hookSetPosition}
    />
  </Styled.WrapperStyled>
);
