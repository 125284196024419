import React from 'react';
import * as Styled from './styles';
import { RUN_DATE } from './enums';

type RunDateProps = {
  date: string
}

export const RunDate = ({ date }: RunDateProps) => (
  <Styled.DateStyled>
    {`${RUN_DATE.RUN_DATE} ${date}`}
  </Styled.DateStyled>
);
