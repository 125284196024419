export const USER_MANAGEMENT = {
  ADD_USER_BUTTON: {
    THEME: 'tertiary',
    TEXT: 'Add User',
  },
  SEARCH_BAR: {
    PLACEHOLDER: 'Search by Name or Email',
    NAME: 'search',
    LABEL: '',
  },
  FILTER: {
    TEXT: 'Filter',
    label: '',
    NAME: 'status',
  },
  CLEAR_FORM: {
    TEXT: 'Clear Form',
  },
  CANCEL_BUTTON: {
    TEXT: 'Cancel',
  },
  DEACTIVATE_BUTTON: {
    TEXT: 'Deactivate',
    TITLE_TEXT: 'Deactivate User',
    QUESTION: 'Are you sure you would like to deactivate this user?',
  },
  PLACEHOLDER_FIRST_NAME: {
    TEXT: 'Ben',
  },
  PLACEHOLDER_LAST_NAME: {
    TEXT: 'Lu',
  },
  PLACEHOLDER_EMAIL: {
    TEXT: 'blu@nautilusdigital.com',
  },
};

export const USE_USER_MANAGEMENT_HOOK = {
  ACTIVATED_MESSAGE: 'User activated succesfully',
  DEACTIVATED_MESSAGE: 'User deactivated succesfully',
  PLACEHOLDER_ON_CLICK: 'Cancel has been clicked',
};
