import { useState } from 'react';
import jwtDecode from 'jwt-decode';
import environment from '../config/environment';
import { useLogOut } from './useLogOut';

const getCookie = (name: string) => {
  const cookies = document.cookie.split('; ');
  const cookie = cookies.find((c) => c.startsWith(name));
  const value = cookie && cookie.split('=')[1];

  if (!cookie || !value) {
    return {};
  }

  type DecodedDataType = {
    userTypesId: number;
    verified: boolean;
    merchantsId: number;
    publishersId: number;
    accStatus: string;
    userStatus: string;
  }

  const decrypt: DecodedDataType = jwtDecode(value);

  return {
    decrypt,
    value,
  };
};

export const useCheckLogin = () => {
  const { hookLogout } = useLogOut();
  const [cookie] = useState(getCookie(environment.app.cookieName));

  const renewCookie = () => {
    const { value } = getCookie(environment.app.cookieName);
    const { value: accessTokenValue } = getCookie('access_token');
    const { value: idTokenValue } = getCookie('id_token');

    const { value: accessTokenLegacyValue } = getCookie('accessToken');
    const { value: idTokenLegacyValue } = getCookie('idToken');
    const { value: FAUTTokenLegacyFalue } = getCookie('FA_UT');
    const { value: TweentyFourHourCookieLegacyValue } = getCookie('24-hour-cookie');

    if (!value) {
      hookLogout();
      return;
    }

    const now = new Date();
    const expiration = (['production', 'staging', 'demo'].includes(environment.app.environment)) ? new Date(now.getTime() + 15 * 60 * 1000) : new Date(now.getTime() + 10 * 60 * 60 * 1000);

    document.cookie = `${environment.app.cookieName}=${value};  expires=${expiration.toUTCString()}; path=/; Secure`;
    document.cookie = `id_token=${idTokenValue};  expires=${expiration.toUTCString()}; path=/; Secure`;
    document.cookie = `access_token=${accessTokenValue};  expires=${expiration.toUTCString()}; path=/; Secure`;

    // Legacy cookies to be used with Knowledge Base and Support App
    document.cookie = `accessToken=${accessTokenLegacyValue}; expires=${expiration.toUTCString()}; domain=${environment.app.domain}`;
    document.cookie = `idToken=${idTokenLegacyValue}; expires=${expiration.toUTCString()}; domain=${environment.app.domain}`;
    document.cookie = `FA_UT=${FAUTTokenLegacyFalue}; expires=${expiration.toUTCString()}; domain=${environment.app.domain}`;
    document.cookie = `24-hour-cookie=${TweentyFourHourCookieLegacyValue}; expires=${new Date(new Date(now.getTime() + 1440 * 60000))}; domain=${environment.app.domain}`;
  };

  return {
    hookCookie: cookie.decrypt,
    hookRenewCookie: renewCookie,
  };
};
