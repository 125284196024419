export const EDIT_REPORT = {
  BACK: 'Back',
  SAVE: 'Save',
  SETTINGS: 'Filter Settings',
  COLUMNS: 'Columns List',
  NEVER: 'Never',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  ALL_USERS: 'All Users on the Account',
  ONLY_ME: 'Only Me',
  EVERY: 'Every',
  PERFORMANCE: 'Performance',
  TRANSACTION: 'Transaction Master',
  LABELS: {
    REPORT_NAME: 'Report Name',
    SCHEDULE: 'Schedule Email Delivery',
    DESCRIPTION: 'Report Description',
    FREQUENCY: 'Frequency',
    PERMISSIONS: 'Permissions',
    DATE: 'Date Range',
    DATE_TYPE: 'Date type',
    MERCHANT: 'Merchant id - name',
    PUBLISHER: 'Publisher id - name',
    PRODUCT: 'Product id - name',
    CATEGORY: 'Product Category',
    TRACKING: 'Tracking Profile',
    GROUP_BY: 'Group By Options',
    CAMPAIGN: 'Ad Campaign',
    PUBLISHER_GROUP: 'Publisher Group',
    AD: 'Ad id',
  },

  DEFAULT_SELECT_VALUE: {
    MERCHANT: {
      label: 'All Merchants',
      value: '',
    },
    PUBLISHER: {
      label: 'All Publishers',
      value: '',
    },
    PRODUCT: {
      label: 'All Products',
      value: '',
    },
    CATEGORY: {
      label: 'All Categories',
      value: '',
    },
    TRACKING: {
      label: 'All Tracking Profiles',
      value: '',
    },
    GROUP_BY: {
      label: 'Merchant',
      value: '',
    },
    CAMPAIGN: {
      label: 'All Ad Campaigns',
      value: '',
    },
    PUBLISHER_GROUP: {
      label: 'All Publisher Groups',
      value: '',
    },
    AD: {
      label: 'All Ad IDs',
      value: '',
    },
    DATE_TYPE: {
      label: 'Transaction Date',
      value: 'transactionDateRange',
    },
    TRANSACTION_STATUS: {
      label: 'All Status',
      value: '',
    },
  },
};

export const PERFORMANCE_ALLOWED_FILTERS = [
  'startDate',
  'endDate',
  'merchantId',
  'publisherId',
  'productId',
  'productCategory',
  'trackingProfileId',
  'groupBy',
  'campaignId',
  'publisherGroupId',
  'adId',
  'sizePerPage',
  'dateRange',
  'sortBy',
  'sortDirection',
];

export const TRANSACTION_MASTER_ALLOWED_FILTERS = [
  'merchantId',
  'publisherId',
  'dataType',
  'productId',
  'productCategory',
  'trackingProfileId',
  'adCampaignId',
  'publisherGroupId',
  'adId',
  'limit',
  'transactionStatus',
  'sortBy',
  'sortDirection',
  'startDate',
  'endDate',
  'dateRange',
];
