import { gql } from '@apollo/client';

export const CREATE_AD = gql`
  mutation CreateAd($input: AdInput) {
    newAd(input: $input) {
      ad {
        id
        adName
      }
    }
  }
`;
