import { faSearch } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Button } from '../Button';
import { InputText } from '../Input';
import { ReportListItemType, useLoadReportProfile } from './hooks';
import * as Styled from './styles';

type LoadReportProfileProps = {
  disabled?: boolean
  className?: string
  loading?: boolean
  reportList?: ReportListItemType[]
  loadSavedReportHandler: (reportId: string) => void
};

export const LoadReportProfile = ({
  disabled, className, loading, reportList = [], loadSavedReportHandler,
}: LoadReportProfileProps) => {
  const {
    hookOpenList, hookSetOpenList, hookDisplayedList, hookSetSearchValue, hookSearchValue,
  } = useLoadReportProfile(reportList);

  return (
    <Styled.LoadReportAreaStyled className={className}>
      <Button
        theme="tertiary"
        onClick={() => hookSetOpenList(true)}
        disabled={disabled}
        loading={loading}
        width="174px"
      >
        Load Saved Report
      </Button>

      <Styled.LoadReportListWrapperStyled
        className="to-close"
        isOpen={hookOpenList}
      >
        <Styled.LoadReportListHeaderStyled>
          <Styled.LoadReportListHeaderTitleStyled>
            Select a saved report to run
          </Styled.LoadReportListHeaderTitleStyled>

          <InputText
            type="text"
            faIcon={faSearch}
            placeholder="Search Report Profile"
            value={hookSearchValue}
            onChange={hookSetSearchValue}
          />
        </Styled.LoadReportListHeaderStyled>

        <Styled.LoadReportContentStyled>
          {hookDisplayedList?.length > 0 && (
            hookDisplayedList.map((report) => (
              <Styled.LoadReportListItemStyled
                key={report.id}
                onClick={() => {
                  hookSetOpenList(false);
                  loadSavedReportHandler(report.id);
                }}
              >
                <Styled.LoadReportListTitleStyled>{report.name}</Styled.LoadReportListTitleStyled>
                <Styled.LoadReportListDescriptionStyled>{report.description}</Styled.LoadReportListDescriptionStyled>
              </Styled.LoadReportListItemStyled>
            ))
          )}

          {hookDisplayedList?.length === 0 && (
            <Styled.NoRecordsFoundBlockStyled>
              <Styled.NoRecordsFoundTitleStyled>No Records Found</Styled.NoRecordsFoundTitleStyled>
              <Styled.NoRecordsFoundDescriptionStyled>When you create a saved report, they will show up here.</Styled.NoRecordsFoundDescriptionStyled>
            </Styled.NoRecordsFoundBlockStyled>
          )}
        </Styled.LoadReportContentStyled>
      </Styled.LoadReportListWrapperStyled>
    </Styled.LoadReportAreaStyled>
  );
};
