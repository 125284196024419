import React from 'react';
import { AppWrapper } from '../../components';

const P404 = () => (
  <AppWrapper permissionsCodeList={[]}>
    404
  </AppWrapper>
);

export default P404;
