import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSummaryReport, useFintelCheckReport } from './hooks';
import {
  AppWrapper, Checkbox, ErrorBanner, Modal, PageTitle, Spinner,
} from '../../../components';
import { dateFormatter, path } from '../../../utils';
import {
  Placeholder, PublisherChecksGraph, RuleChecksGraph, UpdateVisibility,
} from './Components';
import { FINTEL_CHECK_ACTIONS } from './Reducers';
import { SummaryReport } from './AdminReport';
import { DefaultPropsType } from '../../../types';
import { Permission } from '../../../entities';

import * as Styled from './styles';

type FintelCheckReportProps = DefaultPropsType;

const NewCheckReport = ({ permissionsCodeList = [] }: FintelCheckReportProps) => {
  // this needs to be updated
  const hook = useFintelCheckReport();
  const summaryHook = useSummaryReport();

  const [isReadOnly] = Permission.readOnlyPermissionsList(permissionsCodeList);

  return (
    <AppWrapper
      background
      permissionsCodeList={permissionsCodeList}
    >
      <ErrorBanner
        message={hook.hookErrorMessage}
        isOpen={!!hook.hookErrorMessage}
      />
      <Styled.SectionWrapper>
        <SummaryReport
          isMainReport
          hook={summaryHook}
        />
      </Styled.SectionWrapper>

      <Modal isOpen={hook.hookModalIsOpen}>
        <UpdateVisibility hook={hook} />
      </Modal>
    </AppWrapper>
  );
};

export default NewCheckReport;
