import { useState } from 'react';
import { TAX_STATUS, useValidation } from '../utils';

const TAX_COUNTRIES = [
  'United States',
  'Australia',
];

export const useTaxation = () => {
  const validator = useValidation();

  const [taxation, setTaxation] = useState<string>('');
  const [gst, setGst] = useState<string>('');
  const [pst, setPst] = useState<string>('');
  const [hst, setHst] = useState<string>('');

  const [taxationStatus, setTaxationStatus] = useState<string>('');
  const [gstStatus, setGstStatus] = useState<string>('');
  const [pstStatus, setPstStatus] = useState<string>('');
  const [hstStatus, setHstStatus] = useState<string>('');

  const [taxVerifiedDate, setTaxVerifiedDate] = useState<Date | null>(null);
  const [gstVerifiedDate, setGstVerifiedDate] = useState<Date | null>(null);
  const [pstVerifiedDate, setPstVerifiedDate] = useState<Date | null>(null);
  const [hstVerifiedDate, setHstVerifiedDate] = useState<Date | null>(null);

  const [taxErrors, setTaxErrors] = useState<{[key: string]: string}>({});

  const setTaxationHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaxation(e.target.value);
  };

  const setGstHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGst(e.target.value);
  };

  const setPstHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPst(e.target.value);
  };

  const setHstHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHst(e.target.value);
  };

  const setTaxationStringHandler = (value: string) => {
    setTaxation(value);
  };

  const setGstStringHandler = (value: string) => {
    setGst(value);
  };

  const setPstStringHandler = (value: string) => {
    setPst(value);
  };

  const setHstStringHandler = (value: string) => {
    setHst(value);
  };

  const setTaxationStatusHandler = (value: string) => {
    if (value === TAX_STATUS[0].value) setTaxVerifiedDate(new Date());
    else setTaxVerifiedDate(null);
    setTaxationStatus(value);
  };

  const setGstStatusHandler = (value: string) => {
    if (value === TAX_STATUS[0].value) setGstVerifiedDate(new Date());
    else setGstVerifiedDate(null);
    setGstStatus(value);
  };

  const setPstStatusHandler = (value: string) => {
    if (value === TAX_STATUS[0].value) setPstVerifiedDate(new Date());
    else setPstVerifiedDate(null);
    setPstStatus(value);
  };

  const setHstStatusHandler = (value: string) => {
    if (value === TAX_STATUS[0].value) setHstVerifiedDate(new Date());
    else setHstVerifiedDate(null);
    setHstStatus(value);
  };

  const handleTaxValidation = (country: string, state: string) => {
    // Only validate Tax Fields if not empty
    const newTaxErrors: {[key: string]: string} = {};
    let isTaxValid = true;
    // United States & Australia
    if (taxation && TAX_COUNTRIES.includes(country)) {
      const taxError = validator.validateTax('us', taxation, false);
      newTaxErrors.paymentTAX = taxError;
      if (taxError) isTaxValid = false;
    }
    // GST
    if (gst) {
      const taxError = validator.validateTax('gst', gst, false);
      newTaxErrors.paymentGST = taxError;
      if (taxError) isTaxValid = false;
    }
    // PST & QST
    if (pst) {
      if (state === 'Quebec') {
        const qstError = validator.validateTax('qst', pst, false);
        newTaxErrors.paymentPST = qstError;
        if (qstError) isTaxValid = false;
      } else {
        const pstError = validator.validateTax('pst', pst, false);
        newTaxErrors.paymentPST = pstError;
        if (pstError) isTaxValid = false;
      }
    }
    // HST
    if (hst) {
      const hstError = validator.validateTax('hst', hst, false);
      newTaxErrors.paymentHST = hstError;
      if (hstError) isTaxValid = false;
    }
    setTaxErrors(newTaxErrors);
    return isTaxValid;
  };

  return {
    hookTaxation: taxation,
    hookGst: gst,
    hookPst: pst,
    hookHst: hst,

    hookTaxationStatus: taxationStatus,
    hookGstStatus: gstStatus,
    hookPstStatus: pstStatus,
    hookHstStatus: hstStatus,

    hookSetTaxation: setTaxationHandler,
    hookSetGst: setGstHandler,
    hookSetPst: setPstHandler,
    hookSetHst: setHstHandler,

    hookSetStringTaxation: setTaxationStringHandler,
    hookSetStringGst: setGstStringHandler,
    hookSetStringPst: setPstStringHandler,
    hookSetStringHst: setHstStringHandler,

    hookSetTaxationStatus: setTaxationStatusHandler,
    hookSetGstStatus: setGstStatusHandler,
    hookSetPstStatus: setPstStatusHandler,
    hookSetHstStatus: setHstStatusHandler,

    hookTaxVerifiedDate: taxVerifiedDate,
    hookGstVerifiedDate: gstVerifiedDate,
    hookPstVerifiedDate: pstVerifiedDate,
    hookHstVerifiedDate: hstVerifiedDate,

    hookSetTaxVerifiedDate: setTaxVerifiedDate,
    hookSetGstVerifiedDate: setGstVerifiedDate,
    hookSetPstVerifiedDate: setPstVerifiedDate,
    hookSetHstVerifiedDate: setHstVerifiedDate,

    hookHandleTaxValidation: handleTaxValidation,
    hookTaxErrors: taxErrors,
  };
};
