import styled from 'styled-components';
import { colors, fonts } from '../../styles/theme';
import { Select } from '../Input';

export const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const DetailsStyled = styled.div`
  color: ${colors.color1};
  ${fonts.lato.H16}
`;

export const PagesStyled = styled.button`
  ${fonts.lato.H16}
  background-color: ${colors.transparent};
  border: none;
  cursor: pointer;
  margin: 0 0.2rem;
  color: ${colors.color12};
  padding: 0.3rem 0.6rem;

  &:hover {
    background-color: ${colors.color12};
    color: ${colors.color4};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
      color: ${colors.color12};
    }
  }
`;

export const PagesAreaStyled = styled.div``;

export const RecordsStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const RecordsLabelStyled = styled.p`
  ${fonts.lato.H14}
  margin-right: 0.5rem;
  color: ${colors.color1};
`;

export const LeftSideStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectStyled = styled(Select)`
  width: 110px;
  margin-right: 1rem;
`;
