import { gql } from '@apollo/client';

export const CREATE_FINTEL_CHECK_RULE = gql`
  mutation createCheckRule($input: CheckRuleInput!) {
    createCheckRule(input: $input) {
      id
      merchantId
      applicableProducts
      productCategory
      productId
      nominatedField
      customizedProductId
      productName
      customText {
        id
        customText
        required
      }
      productFeed {
        id
        productFeed
        productFeedData
        required
      }
      ruleName
      status
      startDate
    }
  }
`;
