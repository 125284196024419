export const OVERVIEW = {
  DETAILED_DESCRIPTION: 'Detailed Description',
  PRODUCT_CATEGORIES: 'Product Categories',
  TARGET_MARKET: 'Target Market',
  CHANGE: 'Change',
  PRODUCT_MATRIX: 'Product Matrix URL',
  SUBSCRIPTIONS: 'Subscriptions',
  CHECK_LABEL: 'Newsletters, promotions and other announcements',
  COOKIE: 'cookie duration',
  STATS: 'stats update frequency',
  NEW: 'Special offer',
};
