export const CONFIRMATION = {
  CONDITIONS: 'I accept important program updates from Fintel Connect via email as part of my membership conditions.',
  OFFERS: 'I accept exclusive publisher offers, newsletters, promotions and other announcements from Fintel Connect via email.',
  TERMS: 'I have read and agree to the',
  BACK: 'Previous step',
  NEXT: 'Next',
  SUBMIT: 'Submit',
  RETURN_TO_LOGIN: 'Return to Welcome Page',

  TITLE: 'Nice to meet you!',
  DESCRIPTION: 'Thanks for applying to be a publisher in the Fintel Connect Network.',
  REVIEW: 'We\'re going to learn more about your profile and will come back to you via email in two-three business days.',
  REACH_OUT: 'In the meantime, if you have questions or would like to make changes to the information provided, please email the team at',
  EMAIL: ' info@fintelconnect.com',

  ERROR: {
    PUBLISHER: 'Error Creating New Publisher',
  },
};
