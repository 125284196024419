import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper, Button, Calendar, InputCalendar, PageTitle, Pagination, Select,
  ErrorBanner,
} from '../../../components';
import { Table } from '../../../components/Table';
import { path } from '../../../utils';
import { columns } from './contracts';
import { useManualPublisherBonus } from './hooks';
import { AddBonusModal, DetailsModal } from './components';
import { MANUAL_PUBLISHER_BONUS } from './enums';
import PlaceHolderNoResults from '../../../components/NoResultsPlaceHolder';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type ManualPublisherBonusesProps = DefaultPropsType;

const ManualPublisherBonuses = ({ permissionsCodeList = [] }: ManualPublisherBonusesProps) => {
  const hook = useManualPublisherBonus(permissionsCodeList);
  const { hookIsReadOnlyList } = hook;
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        message={hook.hookErrorMsg}
        isOpen={!!hook.hookErrorMsg}
      />
      <Styled.WrapperStyled>
        <Styled.HeaderStyled>
          <PageTitle
            isLoading={hook.hookLoading}
            loadingText={hook.hookLoadingMessage}
          >
            {path.manualPublisherBonuses.name}
          </PageTitle>

          <Button
            theme="tertiary"
            onClick={hook.hookHandleIsAddModalOpen}
            disabled={isReadOnly}
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{ marginRight: 5 }}
            />
            {MANUAL_PUBLISHER_BONUS.ADD_BONUS_BUTTON}
          </Button>
        </Styled.HeaderStyled>

        <AddBonusModal
          hooks={hook}
        />

        <DetailsModal
          handleBack={hook.hookHandleSaveManualModal}
          isOpen={hook.hookIsManualModalOpen}
          bonusDetails={hook.hookBonusModalInfo}
        />

        <Styled.FiltersStyled>
          <Styled.FiltersRowStyled>
            <Select
              label={MANUAL_PUBLISHER_BONUS.PUBLISHER_SELECT.LABEL}
              name={MANUAL_PUBLISHER_BONUS.PUBLISHER_SELECT.NAME}
              onChange={hook.hookHandlePublisherFilter}
              options={hook.hookPublisherOptions}
              isDisabled={hook.hookFiltersLoading}
              isLoading={hook.hookFiltersLoading}
              selected={hook.hookPublisherFilter}
            />
            <Select
              label={MANUAL_PUBLISHER_BONUS.MERCHANTS.LABEL}
              name={MANUAL_PUBLISHER_BONUS.MERCHANTS.NAME}
              onChange={hook.hookHandleMerchantFilter}
              options={hook.hookMerchantOptions}
              isDisabled={hook.hookFiltersLoading}
              isLoading={hook.hookFiltersLoading}
              selected={hook.hookMerchantFilter}
            />
            <InputCalendar
              label={MANUAL_PUBLISHER_BONUS.CALENDAR.LABEL}
              value={hook.hookDisplayRange}
              onClick={hook.hookHandleIsCalendarOpen}
            />
          </Styled.FiltersRowStyled>
        </Styled.FiltersStyled>

        <Styled.FlexStyled>
          <Button
            theme="text-only"
            onClick={hook.hookClearFormHandler}
          >
            Clear Form
          </Button>
        </Styled.FlexStyled>

        <Table
          columns={columns}
          data={hook.hookTableData}
          setModalInfo={hook.hookHandleIsManualModalOpen}
          sortColumn={hook.hookSort}
          onSort={hook.hookSortHandler}
          isLoading={hook.hookDataLoading}
          onRowClick={hook.hookHandleIsManualModalOpen}
        />

        {!(hook.hookTableData.length < 1 && !hook.hookLoading) && (
        <Pagination
          total={hook.hookTotalPage}
          currentPage={hook.hookCurrentPage}
          onPageChange={hook.hookChangePage}
        />
        )}

        <Calendar
          date={hook.hookDateFilter}
          onApply={hook.hookHandleApplyDate}
          onCancel={hook.hookHandleIsCalendarOpen}
          isOpen={hook.hookIsCalenderOpen}
          hasRange
        />

        {hook.hookTableData.length < 1 && !hook.hookLoading && (
          <PlaceHolderNoResults />
        )}
      </Styled.WrapperStyled>
    </AppWrapper>
  );
};

export default ManualPublisherBonuses;
