import { gql } from '@apollo/client';

export const GET_CAMPAIGN_PRODUCT = gql`
  query GetCampaignProduct($input: CampaignProductInput) {
    getCampaignProduct(input: $input) {
      productId
      productName
    }
  }
`;
