import { faCalendarDays, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { SAVE_REPORT_PROFILE_SCHEDULE_MONTH_OPTIONS } from '../../../pages/Reports/PerfomanceReport/Modals/SaveReportProfile/enums';
import * as Styled from './styles';

type SelectProps = {
  label?: string;
  name?: string;
  isLoading?: boolean;
  width?: string;
  className?: string;
  required?: boolean;
  error?: string;
  tooltip?: string;
  descrtiption?: string;
  onChange: (value: any) => void;
  selected: any;
}

export const CustomDropdownCalendar = ({
  label, name, isLoading = false, width = '100%', className, required = false, error, tooltip, descrtiption, onChange, selected,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderCalendar = () => SAVE_REPORT_PROFILE_SCHEDULE_MONTH_OPTIONS.map((item: any) => (
    <Styled.FakeInputOptionStyled
      key={item.value}
      onClick={() => { onChange(item); setIsOpen(false); }}
    >
      {item.value}
    </Styled.FakeInputOptionStyled>
  ));

  return (
    <Styled.WrapperStyled
      id={name}
      width={width}
      className={className}
    >
      <Styled.LabelStyled htmlFor={name}>
        {label && (
        <Styled.LabelTextStyled>
          {label}
          {' '}
          {required && <Styled.RequiredStyled>*</Styled.RequiredStyled>}
          {' '}
          {tooltip && <Styled.TooltipStyled text={tooltip} />}
          {' '}
          {isLoading && (
          <Styled.SpinnerStyled
            theme="secondary"
            size=".7rem"
          />
          )}
        </Styled.LabelTextStyled>
        )}

        {descrtiption && (
          <Styled.DescriptionStyled>
            {descrtiption}
          </Styled.DescriptionStyled>
        )}

        {error && (
          <Styled.ErrorStyled>{error}</Styled.ErrorStyled>
        )}
      </Styled.LabelStyled>

      <Styled.FakeInputStyled>
        <Styled.FakeInputTextStyled onClick={() => setIsOpen(!isOpen)}>
          <>
            <Styled.FakeInputLabelStyled>
              <FontAwesomeIcon icon={faCalendarDays} />
              {selected?.label && selected.label}
            </Styled.FakeInputLabelStyled>

            <Styled.FakeInputIconStyled>
              <FontAwesomeIcon icon={faChevronDown} />
            </Styled.FakeInputIconStyled>
          </>
        </Styled.FakeInputTextStyled>

        <Styled.FakeInputOptionListStyled open={isOpen}>
          {renderCalendar()}
          <Styled.FakeInputOptionLastStyled onClick={() => { onChange({ label: 'Every Last Day of the Month', value: 'Last Day' }); setIsOpen(false); }}>
            Every Last Day of the Month
          </Styled.FakeInputOptionLastStyled>
        </Styled.FakeInputOptionListStyled>
      </Styled.FakeInputStyled>
    </Styled.WrapperStyled>
  );
};
