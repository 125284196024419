import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { RESEND_EMAIL_VERIFICATION } from '../graphql/mutations';

export const useSendEmail = () => {
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [sendEmailVerification] = useMutation(RESEND_EMAIL_VERIFICATION);

  const sendEmailHandler = async () => {
    await sendEmailVerification();
    setConfirmModal(true);
  };
  const setCloseModalHandler = () => {
    setConfirmModal(false);
  };

  return {
    hookSendEmail: sendEmailHandler,
    hookCloseModal: setCloseModalHandler,
    hookModal: confirmModal,
  };
};
