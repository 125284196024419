import { gql } from '@apollo/client';

export const GET_PUBLISHER_PERFORMANCES = gql`
  query PublisherDashboardPerformances($input: PublisherDashboardInput) {
    publisherDashboardPerformances(input: $input) {
      EPC {
        currentPerformance
        performanceIncrease
        prevPerformance
        todaysPerformance
      }
      approvedTransactions {
        currentPerformance
        performanceIncrease
        prevPerformance
        todaysPerformance
      }
      commission {
        currentPerformance
        performanceIncrease
        prevPerformance
        todaysPerformance
      }
      conversionRate {
        currentPerformance
        performanceIncrease
        prevPerformance
        todaysPerformance
      }
    }
  }
`;
