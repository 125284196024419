import { gql } from '@apollo/client';

export const DEACTIVATE_RULES_BY_IDS = gql`
  mutation deactivateRuleByIds($ids: [Int]) {
    deactivateRuleByIds(ids: $ids) {
      id
      merchantId
      productCategory
      productId
      customizedProductId
      productName
      ruleName
      status
      startDate
      endDate
    }
  }
`;
