import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as ICONS from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import * as Styled from './styles';
import { useSideMenu } from './hooks';
import { admin, merchant, publisher } from './utils';
import {
  imageList, path, securityMap, USER_TYPES_ID,
} from '../../utils';
import { VisibilityHidden } from '../VisibilityHidden';
import { useUserInfo } from '../../hooks';
import { useActAsContext } from '../../context';
import type { PermissionType } from '../../entities';
import environment from '../../config/environment';

type SideMenuProps = {
  isCollapsed: boolean;
  className?: string;
  collapseFn?: () => void;
};

export const SideMenu = ({ isCollapsed, className, collapseFn }: SideMenuProps) => {
  const [subCollapsed, setSubCollapsed] = useState(isCollapsed);
  const {
    hookMenuOpen,
    hookSetMenuOpen,
    hookSetSubMenuOpen,
    hookSubMenuOpen,
    hookSetSubMenuManagementOpen,
    hookSubMenuManagementOpen,
    shouldMerchantChangeMonitoringBeAvailable,
  } = useSideMenu();
  const { hookGetPermissionByCode, hookUserInfo } = useUserInfo();

  const { actingAsMerchant, actingAsPublisher } = useActAsContext();

  const hasAccessToNavItem = (menu: any) => {
    /* Validates if merchant selected should present Change Monitoring side menu option */
    if (menu.key === securityMap.merchant.merchantFintelCheckChangeMonitoring && !shouldMerchantChangeMonitoringBeAvailable()) return false;
    /* ------ */

    if (Array.isArray(menu.key)) {
      const permissionCodeList = menu.key.map((code: any) => hookGetPermissionByCode(code));
      return !permissionCodeList.every((permission: PermissionType) => permission.permission === 'noAccess');
    }
    const find = hookGetPermissionByCode(menu.key);
    if (find.permission === 'noAccess') return false;
    return true;
  };

  const renderFourthLevel = (item: any) => {
    if (!hasAccessToNavItem(item)) return undefined;

    return (
      <Styled.AccordionNavLinkStyled
        to={item.href}
        key={item.label}
      >
        {item.label}
      </Styled.AccordionNavLinkStyled>
    );
  };

  const renderThirdLevel = (menu: any) => {
    if (menu.href) {
      if (!hasAccessToNavItem(menu)) return undefined;

      return (
        <Styled.AccordionNavLinkStyled
          to={menu.href}
          key={menu.label}
          id={menu.label.replace(/ /g, '')}
        >
          {menu.label}
        </Styled.AccordionNavLinkStyled>
      );
    }

    return (
      <Styled.AccordionSubSectionsStyled
        show={hookSubMenuOpen === menu.label}
        key={menu.label}
      >
        <Styled.AccordionSubSectionHeaderStyled onClick={() => hookSetSubMenuOpen(menu.label)}>
          <p>{menu.label}</p>
          <FontAwesomeIcon icon={ICONS.faChevronRight} />
        </Styled.AccordionSubSectionHeaderStyled>

        <Styled.SubAccordionContentStyled>
          {menu.subSections && menu.subSections.map((subSubSection: any) => renderFourthLevel(subSubSection))}
        </Styled.SubAccordionContentStyled>
      </Styled.AccordionSubSectionsStyled>
    );
  };

  const renderThirdLevelActAs = (menu: any) => {
    if (!hasAccessToNavItem(menu)) return undefined;
    if (menu.href) {
      return (
        <Styled.AccordionNavLinkStyled
          to={menu.href}
          key={menu.label}
          id={menu.label.replace(/ /g, '')}
        >
          {menu.label}
        </Styled.AccordionNavLinkStyled>
      );
    }

    return (
      <Styled.AccordionSubSectionsStyled
        show={hookSubMenuOpen === menu.label}
        key={menu.label}
      >
        <Styled.AccordionSubSectionHeaderStyled onClick={() => hookSetSubMenuOpen(menu.label)}>
          <p>{menu.label}</p>
          <FontAwesomeIcon icon={ICONS.faChevronRight} />
        </Styled.AccordionSubSectionHeaderStyled>

        <Styled.SubAccordionContentStyled>
          {menu.subSections && menu.subSections.map((subSubSection: any) => renderFourthLevel(subSubSection))}
        </Styled.SubAccordionContentStyled>
      </Styled.AccordionSubSectionsStyled>
    );
  };

  const renderSecondLevel = (menu: any) => {
    if (menu.href) {
      return (
        <Styled.MenuRowStyled
          key={menu.label}
          id={menu.label.replace(/ /g, '')}
        >
          <Styled.MenuLinkStyled
            isCollapsed={subCollapsed}
            to={menu.href}
            key={menu.label}
          >
            <FontAwesomeIcon icon={ICONS[menu.icon as keyof IconProp]} />
            <p>{menu.label}</p>
          </Styled.MenuLinkStyled>
          {menu.link && !subCollapsed && (
            <Styled.LinkIconWrapper>
              <Styled.IconLinkStyled
                isCollapsed={subCollapsed}
                to={menu.href}
                target="_blank"
              >
                <FontAwesomeIcon icon={ICONS[menu.link as keyof IconProp]} />
              </Styled.IconLinkStyled>
            </Styled.LinkIconWrapper>
          )}
        </Styled.MenuRowStyled>
      );
    }

    if (!menu.href) {
      return (
        <Styled.AccordionStyled
          isCollapsed={subCollapsed}
          show={hookMenuOpen === menu.label}
          key={menu.label}
          id={menu.label.replace(/ /g, '')}
        >
          <Styled.AccordionHeaderStyled onClick={() => hookSetMenuOpen(menu.label)}>
            <Styled.IconLinkStyled to={menu.management ? menu.management.href : menu.subSections[0].href}>
              <FontAwesomeIcon icon={ICONS[menu.icon as keyof IconProp]} />
            </Styled.IconLinkStyled>
            <p>{menu.label}</p>
            <FontAwesomeIcon icon={ICONS.faChevronRight} />
          </Styled.AccordionHeaderStyled>

          <Styled.AccordionContentStyled>
            {menu.management && (
              <>
                <Styled.AccordionNavLinkStyled to={menu.management.href}>
                  {menu.management.label}
                </Styled.AccordionNavLinkStyled>

                {actingAsMerchant?.id && menu.management.label === path.merchantManagement.name && (
                  <>
                    <Styled.AccordionSectionSeparatorStyled />
                    <Styled.AccordionHeaderStyledActAs onClick={() => hookSetSubMenuManagementOpen(menu.management.label)}>
                      <p>{actingAsMerchant.name}</p>
                      {' '}
                      <FontAwesomeIcon icon={hookSubMenuManagementOpen === menu.management.label ? ICONS.faChevronDown : ICONS.faChevronRight} />
                    </Styled.AccordionHeaderStyledActAs>
                  </>
                )}

                {actingAsMerchant?.id && menu.management.label === path.merchantManagement.name && menu.subSections && menu.subSections.map((subSection: any, index: number) => (
                  <Styled.AccordionManagementStyled
                    show={hookSubMenuManagementOpen === path.merchantManagement.name}
                    key={index}
                  >
                    {renderThirdLevelActAs(subSection)}
                  </Styled.AccordionManagementStyled>
                ))}

                {actingAsPublisher?.id && menu.management.label === path.publisherSearch.name && (
                  <>
                    <Styled.AccordionSectionSeparatorStyled />
                    <Styled.AccordionHeaderStyledActAs onClick={() => hookSetSubMenuManagementOpen(menu.management.label)}>
                      <p>{actingAsPublisher.name}</p>
                      {' '}
                      <FontAwesomeIcon icon={hookSubMenuManagementOpen === menu.management.label ? ICONS.faChevronDown : ICONS.faChevronRight} />
                    </Styled.AccordionHeaderStyledActAs>
                  </>
                )}
                {actingAsPublisher?.id && menu.management.label === path.publisherSearch.name && menu.subSections && menu.subSections.map((subSection: any, index: number) => (
                  <Styled.AccordionManagementStyled
                    show={hookSubMenuManagementOpen === path.publisherSearch.name}
                    key={index}
                  >
                    {renderThirdLevelActAs(subSection)}
                  </Styled.AccordionManagementStyled>
                ))}
              </>
            )}

            {!menu.management && menu.subSections && menu.subSections.map((subSection: any) => renderThirdLevel(subSection))}
          </Styled.AccordionContentStyled>
        </Styled.AccordionStyled>
      );
    }
  };

  const renderFirstLevel = (menu: any) => menu.map((item: any) => {
    const permission = hookGetPermissionByCode(item.key);

    if (permission.skip) {
      return renderSecondLevel(item);
    }

    if (!hasAccessToNavItem(item)) return undefined;
    return renderSecondLevel(item);
  });

  return (
    <Styled.WrapperStyled
      isCollapsed={isCollapsed}
      className={className}
    >
      <Styled.LogoPlaceStyled isCollapsed={subCollapsed}>
        <img
          src={subCollapsed ? imageList.logoSmall.src : imageList.logoFull.src}
          alt={imageList.logoFull.alt}
        />
      </Styled.LogoPlaceStyled>

      <Styled.MenuWrapper>
        {hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN && renderFirstLevel(admin)}
        {hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT && renderFirstLevel(merchant)}
        {hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER && renderFirstLevel(publisher)}
      </Styled.MenuWrapper>

      <Styled.FAQLinkStyled
        href={environment.app.kbUrl}
        target="_blank"
        isCollapsed={subCollapsed}
      >
        <Styled.IconStyled icon={faCircleQuestion} />
        {!subCollapsed && 'Help'}
      </Styled.FAQLinkStyled>

      <Styled.CollapseButtonStyled onClick={() => {
        if (collapseFn) collapseFn();
        setSubCollapsed(!subCollapsed);
      }}
      >
        <VisibilityHidden>Collapse</VisibilityHidden>
        <FontAwesomeIcon icon={isCollapsed ? ICONS.faAngleRight : ICONS.faAngleLeft} />
      </Styled.CollapseButtonStyled>
    </Styled.WrapperStyled>
  );
};
