import styled from 'styled-components';
import { colors } from '../../../../../../styles/theme';

type CellWrapperStyledProps = {
  readonly disabled?: boolean
}

export const CellWrapperStyled = styled.div<CellWrapperStyledProps>`
  display: flex;
  min-height: 35px;
  align-items: center;
  text-overflow: ellipsis;
  flex-flow: wrap row;
  white-space: initial;
  line-height: 20px;

  background-color: ${({ disabled }) => (disabled ? '#edf2f7' : 'inherit')}; 
  opacity: ${({ disabled }) => (disabled ? '.4' : 'initial')};
  margin: auto;
`;

export const SecondLineStyled = styled.div`
  color: ${colors.color16};
  width: 100%;
  text-transform: lowercase;
  
  a {
    text-decoration: none;
    color: ${colors.color3};

    &:hover {
      color: ${colors.color2};
    }
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  margin: 0 20px;
`;
