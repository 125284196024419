import React from 'react';
import * as Styled from '../styles';
import { dateFormatter, formateDollarAmount } from '../../../../utils';

export const columns: TableColumn[] = [
  {
    dataField: 'productCategory',
    text: 'category',
    width: '12%',
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'recordDate',
    text: 'record date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '13%',
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {dateFormatter(new Date(val))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'recordName',
    text: 'record name',
    width: '10%',
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'recordType',
    text: 'record type',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '12%',
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {val}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'value',
    text: 'amount',
    width: '13%',
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'systemFee',
    text: 'system useage fee',
    width: '10%',
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'taxes',
    text: 'taxes',
    width: '10%',
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'totalCost',
    text: 'total cost',
    width: '10%',
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'totalDeposit',
    text: 'total deposit',
    width: '10%',
    formatter: (val: any) => (
      <Styled.TableDataStyled>
        {formateDollarAmount(parseFloat(val))}
      </Styled.TableDataStyled>
    ),
  },
];
