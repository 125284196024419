import { gql } from '@apollo/client';

export const LIST_CURRENCIES = gql`
  query ListCurrencies {
    listCurrencies {
      id
      name
      currency
    }
  }
`;
