import styled from 'styled-components';
import { colors, fonts } from '../../styles/theme';

export const WrapperStyled = styled.h1`
  ${fonts.gotham.H26M}
  color: ${colors.color1};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 30px;
`;

export const LoadingStyled = styled.span`
  background: ${colors.color15};
  ${fonts.gotham.H12M}
  padding: 0.5rem;
  margin-left: 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;

  span {
    margin-right: 0.5rem;
  }
`;
