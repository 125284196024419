import styled from 'styled-components';
import { colors, fonts } from '../../../../../styles/theme';

export const DateStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.color19};
  color: ${colors.color1};
  padding: 0.5rem;
  ${fonts.lato.H12}
  border-radius: 4px;
`;
