import styled from 'styled-components';
import { Button, InputText, Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

export const ButtonStyled = styled(Button)`
  svg {
    margin-right: 5px;
  }
`;

export const ButtonsStyled = styled.div`
  display: flex;
  justify-content: space-between;

  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;

export const FooterButtonStyled = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${toRem(40)};
`;

export const ModalWrapperStyled = styled.div`
  padding: ${toRem(30)};
  width: 600px;
`;

export const ModalTitleStyled = styled.p`
  ${fonts.gotham.H26M}
`;

export const SelectStyled = styled(Select)`
`;

export const TableDataStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 48px;
  line-height: ${toRem(24)};
  align-items: center;
  white-space: normal;
  justify-content: flex-start;
  flex-direction: row;

  &:hover {
    color: ${colors.color2};
  }

  em {
    font-style: italic;
  }
}`;

export const FAButtonStyled = styled(Button)`
  display: flex;
  justify-content: flex-start;
  padding: 0;
  font-size: 1.2rem;
  width: 30px;
  color: ${colors.color1};

  &:hover {
    color: ${colors.color2};
  }
`;

export const CellStyled = styled.span`
  min-height: 42px;
  display: flex;
  gap: 0.5rem;
  word-wrap: normal;
  color: ${colors.color1};
  
  justify-content: flex-start;
  white-space: pre-wrap;
  ${fonts.lato.H14};

  align-items: center;
`;
export const InputTextStyled = styled(InputText)`
  flex: 2;
  margin-right: 2rem;
`;

export const FiltersSelectStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-basis: 30%;
  width: 100%;

  ${SelectStyled}:nth-of-type(2) {
    margin: 0 1rem;
  }
`;

export const FiltersStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;

  ${FiltersSelectStyled} > span {
    margin-right: 1rem;
    ${fonts.lato.H14M}
    color: ${colors.color17};
  }
  ${FiltersSelectStyled}:nth-of-type(2) {
    margin-top: 2rem;
  }
`;

export const ClearFormButtonStyled = styled(Button)`
  display: flex;
  align-self: flex-end;
`;

export const RowStyled = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
