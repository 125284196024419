import React from 'react';
import * as Styled from '../../styles';
import { CellProps } from '../../types';

export const CategoriesCell = ({ val }: CellProps) => (
  <Styled.CellWrapper>
    <Styled.OverflowStyled>
      {val.filter((item: string) => item !== '' && item !== ' ').join(', ')}
    </Styled.OverflowStyled>
  </Styled.CellWrapper>
);
