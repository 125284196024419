import { useLazyQuery } from '@apollo/client';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModal, useUserInfo } from '../../../../hooks';
import {
  MERCHANT_PREFIX, path, RECORDS_PER_PAGE_OPTIONS, USER_TYPES_ID,
} from '../../../../utils';
import { useDebounce } from '../../../../utils/useDebounce';
import { LIST_SEARCH_AD } from '../graphql/queries';
import { useAdFilterOptions } from './useAdFilterOptions';
import { useStaticAdFilterOptions } from './useStaticAdFilterOptions';
import { Permission } from '../../../../entities';

type Filter = {
  adType?: string;
  adStatusType?: string;
  merchantId?: string;
  campaignId?: string;
  productCategory?: string;
  programId?: string;
  productId?: string;
  language?: string;
  q: string;
  management?: boolean;
  publisherId?: string;
  publisherGroupId?: string;
};

export const useAdSearch = (permissionsCodeList: string[] = []) => {
  const userHook = useUserInfo();
  const navigate = useNavigate();
  const location = useLocation();

  const [savedState, setSavedState] = useState(location.state?.searchState);
  const [adList, setAdList] = useState([]);
  const [search, setSearch] = useState(savedState ? savedState.search : '');
  const debouncedSearch = useDebounce(search, 800);
  const [page, setPage] = useState(savedState ? savedState.page : 1);
  const [totalPages, setTotalPages] = useState(0);
  const [records, setRecords] = useState(savedState ? savedState.records : { label: '10', value: '10' });
  const [recordsCount, setRecordsCount] = useState(0);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>(savedState ? savedState.sortColumn : { column: 'id', direction: 'asc' });
  const [adModal, setAdModal] = useState(false);
  const [selectedAd, setSelectedAd] = useState('');
  const [addModal, setAddModal] = useModal(false);

  const {
    hookAdStatusTypesList,
    hookMerchantsList,
    hookAdTypesList,
    hookLanguagesList,
    hookProductCategoriesList,
    hookStaticFilterOptionsLoading,
  } = useStaticAdFilterOptions(userHook.hookUserInfo, userHook.hookWhoAmI);

  const [selectedStatus, setSelectedStatus] = useState<SelectOption>(savedState ? savedState.selectedStatus : hookAdStatusTypesList[0]);
  const [selectedMerchant, setSelectedMerchant] = useState<SelectOption>(savedState ? savedState.selectedMerchant : hookMerchantsList[0]);
  const [selectedAdType, setSelectedAdType] = useState<SelectOption>(savedState ? savedState.selectedAdType : hookAdTypesList[0]);
  const [selectedLanguage, setSelectedLanguage] = useState<SelectOption>(savedState ? savedState.selectedLanguage : hookLanguagesList[0]);
  const [selectedProductCategory, setSelectedProductCategory] = useState<SelectOption>(savedState ? savedState.selectedProductCategory : hookProductCategoriesList[0]);
  const [advancedSearchBox, setAdvancedSearchBox] = useState(savedState ? savedState.advancedSearchBox : false);
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(savedState ? savedState.openAdvancedSearch : false);
  const [searchClicked, setSearchClicked] = useState<boolean>(false);
  const [currentlyDebounced, setCurrentlyDebounced] = useState<boolean>(false);

  const {
    hookCampaignsList,
    hookProductsList,
    hookPublisherGroupsList,
    hookFilterOptionsLoading,
  } = useAdFilterOptions(userHook.hookUserInfo, userHook.hookWhoAmI, selectedMerchant.value);

  const [selectedCampaign, setSelectedCampaign] = useState<SelectOption>(hookCampaignsList[0]);
  const [selectedProduct, setSelectedProduct] = useState<SelectOption>(hookProductsList[0]);
  const [selectedPublisherGroup, setSelectedPublisherGroup] = useState<SelectOption>(hookPublisherGroupsList[0]);

  const [getAds, { loading: getAdsLoading }] = useLazyQuery(LIST_SEARCH_AD);

  const defaultFilter: Filter = {
    q: search,
  };

  const setSelectedAdTypeHandler = (ad: SelectOption) => {
    setSelectedAdType(ad);
  };

  const selectStatusHandler = (statusSelected: SelectOption) => {
    setSelectedStatus(statusSelected);
  };

  const selectedLanguageHandler = (languageSelected: SelectOption) => {
    setSelectedLanguage(languageSelected);
  };

  const selectProductCategoryHandler = (productCategorySelected: SelectOption) => {
    setSelectedProductCategory(productCategorySelected);
  };

  const selectProductHandler = (productSelected: SelectOption) => {
    setSelectedProduct(productSelected);
  };

  const selectPublisherGroupHandler = (publisherGroupSelected: SelectOption) => {
    setSelectedPublisherGroup(publisherGroupSelected);
  };

  const setSelectedAdCampaignHandler = (adCampaignSelected: SelectOption) => {
    setSelectedCampaign(adCampaignSelected);
  };

  const setAdvancedSearchBoxHandler = () => {
    setAdvancedSearchBox(!advancedSearchBox);
    setOpenAdvancedSearch(!openAdvancedSearch);
  };

  const setRecordsHandler = (value: any) => {
    setRecords({
      label: value.label,
      value: value.value,
    });
  };

  const setSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentlyDebounced(true);
  };

  const setSortByHandler = (dataField: string, direction: any) => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const setOpenAdModalHandler = (adId: string) => {
    setAdModal(true);
    setSelectedAd(adId);
  };

  const setCloseAdModalHandler = () => {
    setAdModal(false);
  };

  const selectMerchantHandler = (merchantSelected: SelectOption) => {
    setSelectedMerchant(merchantSelected);
    setSelectedCampaign(hookCampaignsList[0]);
    setSelectedProduct(hookProductsList[0]);
  };

  const changePageHandler = (pageValue: number) => {
    setPage(pageValue);
  };

  const rowClickHandler = (row: any) => {
    if (userHook.hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER || userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER) {
      setOpenAdModalHandler(row.id);
    } else {
      navigate(`${userHook.hookWhoAmI.companyId ? `${MERCHANT_PREFIX}` : ''}${path.editAd.href}`, {
        state: {
          adId: row.id,
          program: row.programId,
          from: `${userHook.hookWhoAmI.companyId ? `${MERCHANT_PREFIX}` : ''}${path.adSearch.href}`,
          searchState: {
            selectedStatus,
            selectedMerchant,
            selectedAdType,
            selectedLanguage,
            selectedProductCategory,
            selectedProduct,
            selectedCampaign,
            selectedPublisherGroup,
            search,
            page,
            records,
            sortColumn,
            openAdvancedSearch,
            advancedSearchBox,
          },
        },
      });
    }
  };

  const getAdsHandler = async () => {
    if (selectedAdType.value) {
      defaultFilter.adType = selectedAdType.value;
    }

    if ((userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT && userHook.hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN) || userHook.hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT) {
      defaultFilter.programId = userHook.hookWhoAmI.programId;
    } else if (selectedMerchant.value !== '') {
      defaultFilter.programId = selectedMerchant.value;
    }

    if (selectedStatus.value !== '') {
      defaultFilter.adStatusType = selectedStatus.value;
    }

    if (selectedProductCategory.value !== '') {
      defaultFilter.productCategory = selectedProductCategory.value;
    }

    if (selectedCampaign.value) {
      defaultFilter.campaignId = selectedCampaign.value;
    }

    if (selectedProduct.value !== '') {
      defaultFilter.productId = selectedProduct.value;
    }

    if (selectedPublisherGroup.value !== '') {
      defaultFilter.publisherGroupId = selectedPublisherGroup.value;
    }

    if (search) {
      defaultFilter.q = search;
    }

    if (selectedLanguage.value !== '') {
      defaultFilter.language = selectedLanguage.value;
    }

    if ((userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER && userHook.hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN) || userHook.hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER) {
      defaultFilter.adStatusType = 'Active';
      defaultFilter.publisherId = userHook.hookWhoAmI.companyId?.toString();
    }

    const { data } = await getAds({
      variables: {
        input: {
          filter: defaultFilter,
          options: {
            page,
            order: sortColumn.direction?.toUpperCase(),
            items: Number(records.value),
          },
          sortBy: sortColumn.column,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.listSearchAds?.ads) {
      setAdList(data.listSearchAds.ads);
    }

    if (data?.listSearchAds?.count) {
      setRecordsCount(data.listSearchAds.count);
      setTotalPages(data.listSearchAds.totalPages);
    } else {
      setPage(1);
      setRecordsCount(0);
      setTotalPages(0);
    }
  };

  const setClearFormHandler = () => {
    selectMerchantHandler(hookMerchantsList[0]);
    selectProductCategoryHandler(hookProductCategoriesList[0]);
    selectStatusHandler(hookAdStatusTypesList[0]);
    selectedLanguageHandler(hookLanguagesList[0]);
    setSelectedAdTypeHandler(hookAdTypesList[0]);
    selectProductHandler(hookProductsList[0]);
    setSelectedAdCampaignHandler(hookCampaignsList[0]);
    selectPublisherGroupHandler(hookPublisherGroupsList[0]);
    setSearch('');
    setSearchClicked(false);
  };

  const searchButtonHandler = () => {
    setSearchClicked(true);
    if (page !== 1) setPage(1);
    else getAdsHandler();
    setCurrentlyDebounced(false);
  };

  useEffect(() => {
    if (savedState) return;
    setPage(1);
  }, [
    records,
    selectedAdType,
    selectedCampaign,
    selectedLanguage,
    selectedMerchant,
    selectedProduct,
    selectedProductCategory,
    selectedPublisherGroup,
    selectedStatus,
  ]);

  useEffect(() => {
    if (searchClicked) getAdsHandler();
  }, [
    page,
    records,
    selectedAdType,
    selectedCampaign,
    selectedLanguage,
    selectedMerchant,
    selectedProduct,
    selectedProductCategory,
    selectedPublisherGroup,
    selectedStatus,
    sortColumn,
    window.location.pathname,
  ]);

  useEffect(() => {
    if (savedState) return;
    if (searchClicked && currentlyDebounced && page === 1) getAdsHandler();
    else if (page !== 1) setPage(1);
  }, [debouncedSearch]);

  useEffect(() => {
    if (savedState) {
      setSavedState(null);
      setSearchClicked(true);
      getAdsHandler();
    }
  }, []);

  return {
    hookAds: adList,

    hookAdTypesList,
    hookSetAdType: setSelectedAdTypeHandler,
    hookSelectedAdType: selectedAdType,
    hookSelectedAd: selectedAd,

    hookSearchButtonHandler: searchButtonHandler,
    hookSearchClicked: searchClicked,

    hookSearch: search,
    hookSetSearch: setSearchHandler,

    hookChangePage: changePageHandler,
    hookCurrentPage: page,
    hookTotalPages: totalPages,
    hookRecords: records,
    hookSetRecords: setRecordsHandler,
    hookTotalRecords: recordsCount,
    hookRecordOptions: RECORDS_PER_PAGE_OPTIONS,

    hookHandleSort: setSortByHandler,
    hookSortColumn: sortColumn,

    hookLoading: getAdsLoading || hookFilterOptionsLoading || hookStaticFilterOptionsLoading,
    hookTableLoading: getAdsLoading,
    hookOptionsLoading: hookFilterOptionsLoading,

    hookAdModal: adModal,
    hookSetOpenAdModal: setOpenAdModalHandler,
    hookSetCloseAdModal: setCloseAdModalHandler,

    hookAddModal: addModal,
    hookSetAddModal: setAddModal,

    hookMerchantList: hookMerchantsList,
    hookSelectedMerchant: selectedMerchant,
    hookSetSelectedMerchant: selectMerchantHandler,

    hookAdStatusTypesList,
    hookSelectedStatus: selectedStatus,
    hookSetSelectedStatus: selectStatusHandler,

    hookProductCategoryList: hookProductCategoriesList,
    hookSelectedProductCategory: selectedProductCategory,
    hookSetSelectedProductCategory: selectProductCategoryHandler,

    hookSelectedLanguage: selectedLanguage,
    hookSetSelectedLanguage: selectedLanguageHandler,
    hookLanguagesList,

    hookAdvancedSearchBox: advancedSearchBox,
    hookSetAdvancedSearchBox: setAdvancedSearchBoxHandler,

    hookOpenAdvancedSearch: openAdvancedSearch,
    hookClearForm: setClearFormHandler,

    hookSelectedProduct: selectedProduct,
    hookSetSelectedProduct: selectProductHandler,
    hookProductList: hookProductsList,

    hookSelectedPublisherGroups: selectedPublisherGroup,
    hookSetSelectedPublisherGroup: selectPublisherGroupHandler,
    hookPublisherGroupList: hookPublisherGroupsList,

    hookAdCampaignList: hookCampaignsList,
    hookSelectedAdCampaign: selectedCampaign,
    hookSetSelectedAdCampaign: setSelectedAdCampaignHandler,

    hookHandleRowClick: rowClickHandler,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
