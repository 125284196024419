import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputText, Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

type GridedRowProps = {
  readonly 'template-rows'?: string;
};

type HeaderButtonAreaStyledProps = {
  readonly theme?: string
}

type GridContentProps = {
  readonly gridColumns?: string
}

type SectionStyledProps = {
  readonly noMarginTop?: boolean
}

export const WrapperStyled = styled.div``;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const HeaderButtonAreaStyled = styled.div<HeaderButtonAreaStyledProps>`
  display: flex;
  width: 220px;
  justify-content: ${({ theme }) => (theme === 'secondary' ? 'flex-end' : 'space-between')};
  align-items: center;
`;

export const FlexRowStyled = styled.div`
  display: flex;
  margin-top: 2rem;
`;

export const GridedRowStyled = styled.div<GridedRowProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: ${({ 'template-rows': rows }) => rows || '250px'};
  grid-row-gap: 2rem;
  grid-gap: 2%;
`;

export const GridContentStyled = styled.div<GridContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  grid-column: ${({ gridColumns }) => (gridColumns || 'auto')};
`;

export const DropZoneStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const SectionStyled = styled.div<SectionStyledProps>`
  border-top: ${({ noMarginTop }) => (noMarginTop ? '' : ` 1px solid ${colors.color3};`)};
  margin-top: ${({ noMarginTop }) => (noMarginTop ? '' : '3rem')};
  padding-top: ${({ noMarginTop }) => (noMarginTop ? '' : '3rem')};
`;

export const SectionTitleStyled = styled.h2`
  ${fonts.gotham.H26}
  color: ${colors.color1};
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;

  ${HeaderButtonAreaStyled} {
    width: 300px;
  }
`;

export const InputTextStyled = styled(InputText)`
  margin-bottom: 1.5rem;

  &:first-of-type {
    grid-column: 1 / span 2;
  }
`;

export const SelectStyled = styled(Select)`
  font-size: 1rem;
  
  &:nth-of-type(2) {
    margin: 0 2%;
  }
`;

export const RecordStyled = styled.span`
  ${fonts.gotham.H14}
`;

export const ButtonStyled = styled(Button)`
  svg {
    margin-right: 0.5rem;
  }
`;

export const IconStyled = styled(FontAwesomeIcon)`
  font-size: 20px;
`;
