import { gql } from '@apollo/client';

export const GET_REPORT_DETAIL = gql`
query Query($input: RawCommissionsSearchInput!) {
  rawCommissionsGroupByMerchant(input: $input) {
    count
    rawCommissions {
      merchant {
        id
        companyName
      }
      productCategory
      cpaCommission
      revShareCommission
      totalCommissions
      totalTaxAmount
      bonusAmount
      bonusTax
      totalPayable
      periodBalance
      rate
    }
    merchantPayments {
      merchant {
        id
        companyName
      }
      publisher {
        id
        companyName
      }
      bonus
      bonusTax
      currency
      currentRollover
      merchantCurrency
      merchantHeld
      month
      year
      rate
      paymentMethod
      paymentStatus
      periodBalance
      productCategory
      totalCommissions
      totalPayable
      totalTaxes
    }
  }
}
`;
