export const PRODUCT_DETAILS = {
  BACK_BUTTON: 'Back',
  CATEGORY: 'Product Category',
  SUBCATEGORY: 'Product Subcategories',
  LAST_UPDATED: 'Last Updated',
  PRODUCT_DESCRIPTION: 'PRODUCT DESCRIPTION',
  TABS: ['Product Feed', 'Ads', 'Commissions'],
  COMMISSIONS: 'Associated Commissions',
  ADS: 'Associated Ads',
};
