import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_TRACKING_DOMAINS, GET_TRACKING_DOMAINS_CSV } from '../graphql/queries';
import { useUserInfo } from '../../../../../hooks';
import { csvGenerator } from '../../../../../utils';

export const useTrackingDomains = () => {
  const userHook = useUserInfo();
  const [tableDomains, setTableDomains] = useState<any[]>([]);
  const [listDomains, { loading }] = useLazyQuery(GET_TRACKING_DOMAINS);
  const [downloadDomainsCsv, { loading: csvLoading }] = useLazyQuery(GET_TRACKING_DOMAINS_CSV);
  const [sortBy, setSortBy] = useState<TableSortColumn>({ column: 'lastActiveDate', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const listDomainsHandler = async () => {
    const { data } = await listDomains({
      variables: {
        input: {
          publisherId: userHook.hookWhoAmI.companyId?.toString(),
          sizeParPage: 10,
          sortField: sortBy.column,
          sortType: sortBy.direction,
          currentPage,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.trackingDomains?.domains) {
      setTableDomains(data?.trackingDomains?.domains);
    }

    if (data?.trackingDomains?.count) {
      setTotalPages(Math.ceil(data.trackingDomains.count / 10));
    } else {
      setCurrentPage(1);
      setTotalPages(0);
    }
  };

  const downloadProfiles = async () => {
    const { data } = await downloadDomainsCsv({
      variables: {
        input: {
          publisherId: userHook.hookWhoAmI.companyId,
          isCsvDownload: true,
        },
      },
    });
    csvGenerator(data.trackingDomainsCsv.csv, 'tracking-domain');
  };

  const setSortByHandler = (dataField: string, direction: any) => {
    if (sortBy.direction === null) {
      setSortBy({ column: dataField, direction });
    } else {
      setSortBy({ column: dataField, direction: sortBy.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    listDomainsHandler();
  }, [userHook.hookWhoAmI.companyId, currentPage, sortBy]);

  return {
    hookLoading: loading,
    hookCsvLoading: csvLoading,
    hookTrackingDomainsData: tableDomains,

    hookDownloadCSV: downloadProfiles,

    hookSortBy: sortBy,
    hookSetSortBy: setSortByHandler,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,
    hookTotalPages: totalPages,
  };
};
