import { useEffect, useState } from 'react';

export type ReportListItemType = {
  id: string
  name: string
  description: string | null
}

export const useLoadReportProfile = (reportList: ReportListItemType[]) => {
  const [openListState, setOpenListState] = useState(false);
  const [displayedList, setDisplayedList] = useState(reportList);
  const [searchValue, setSearchValue] = useState<string>('');

  const setSearchValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    const displayedValues = reportList?.filter((report) => report.name?.toUpperCase().trim().includes(e.target.value.toUpperCase().trim())
      || report.description?.toUpperCase().trim().includes(e.target.value.toUpperCase().trim()));
    setDisplayedList(displayedValues);
  };

  const setOpenReportListHandler = (state: boolean) => {
    setOpenListState(state);
  };

  const closeListHandler = (e: Event) => {
    const arrayToCheck: boolean[] = [];

    e.composedPath().forEach((item: any) => {
      if (item.classList?.contains('to-close')) {
        arrayToCheck.push(true);
        return true;
      }

      arrayToCheck.push(false);
      return false;
    });

    if (!arrayToCheck.includes(true)) {
      setOpenListState(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', closeListHandler);

    return () => {
      window.removeEventListener('mousedown', closeListHandler);
    };
  }, []);

  useEffect(() => {
    setDisplayedList(reportList);
  }, [JSON.stringify(reportList)]);

  return {
    hookOpenList: openListState,
    hookSetOpenState: setOpenListState,
    hookSetOpenList: setOpenReportListHandler,
    hookDisplayedList: displayedList,
    hookSearchValue: searchValue,
    hookSetSearchValue: setSearchValueHandler,
  };
};
