export const commissionBaseLabels = (commissionBase: string) => {
  const labelOfSales = 'Number of Transactions';
  const labelOfPerSales = 'Transaction';
  let commissionBaseFormat;
  if (commissionBase === 'GrossSales') {
    commissionBaseFormat = 'Gross Sales';
  } else if (commissionBase === 'SalesNumber') {
    commissionBaseFormat = 'Units';
  } else if (commissionBase === 'NetSales') {
    commissionBaseFormat = 'Net Sales';
  }
  return { commissionBaseFormat, labelOfPerSales, labelOfSales };
};
