import uaParser from 'ua-parser-js';
import { moneyFormater } from '../../PerfomanceReport/utils';
import { defaultTableTotals } from '../enums';
import { TMTotalsProcessed } from '../types';

// Modified from fintel-app 'formatDataTableCSV.js'
export const formatDataTableCSV = (data: any[]) => {
  const result: any[] = [];
  const fallBackText = 'N/A';
  const tableTotals: TMTotalsProcessed = structuredClone(defaultTableTotals);

  if (data.length === 0) {
    return { results: result, totals: tableTotals };
  }

  data.forEach((item: any) => {
    result.push({
      ...item,
      processedDate: item?.saleDate || null,
      adName: item.ad?.adName || null,
      processedByFirstName: item.processedBy?.firstName || null,
      processedByLastName: item.processedBy?.lastName || null,
      trackingProfilePrimaryWebsite: item.trackingProfile?.primaryWebsite || null,
      userOS: `${uaParser(item?.userAgent || '').os.name} / ${uaParser(item?.userAgent || '').browser.name}`,
      id: item.id,
      clickId: item.clickId || '',
      publisherId: item.publisher?.id || fallBackText,
      publisherName: item.publisher?.companyName || fallBackText,
      merchantId: item.merchant?.id || fallBackText,
      merchantName: item.merchant?.companyName || fallBackText,
      saleDate: item.saleDate ? item.saleDate.split('T')[0] : '',
      createdAt: item.createdAt ? item.createdAt.split('T')[0] : '',
      adId: item.ad?.id || fallBackText,
      netSale: Number.parseFloat(item.netSale || 0).toFixed(2),
      grossSale: Number.parseFloat(item.grossSale || 0).toFixed(2),
      commissionAmount: item?.transactionStatus === 'Approved' ? Number.parseFloat(item.commissionAmount || 0).toFixed(2) : 0,
      currency: item.currency || fallBackText,
      productId: item.product?.id || fallBackText,
      productName: item.product?.name || fallBackText,
      productCategory: item.product?.productCategory || fallBackText,
      thirdPartyUrl: item.product?.thirdPartyUrl || fallBackText,
      commissionIds: `${item.commissionIds.join(',')}` || fallBackText,
      commissionTypes: item.commissionTypes.join() || fallBackText,
      firstTransaction: item.firstTransaction || 'False',
      processedBy: item?.processedBy?.firstName ? `${item.processedBy?.firstName} ${item.processedBy?.lastName}` : fallBackText,
      country: item.geoIp?.country || fallBackText,
      trackingProfileId: item.trackingProfile?.id || fallBackText,
      trackingProfileUrl: item.trackingProfile?.primaryWebsite || fallBackText,
      osBrowser: `${uaParser(item.userAgent).os.name} / ${uaParser(item.userAgent).browser.name}`,
      programId: item.programId || fallBackText,
      systemUsageFee: parseInt(item.systemUsageFee || 0, 10),
      referralUrl: item.referralUrl || fallBackText,
    });
    if (item?.currency === 'CAD') {
      tableTotals.totalCommission[0] += item?.transactionStatus === 'Approved' ? Number.parseFloat(item.commissionAmount || 0) : 0;
      tableTotals.totalGrossSale[0] += Number.parseFloat(item.grossSale || 0);
      tableTotals.totalNetSale[0] += Number.parseFloat(item.netSale || 0);
    }
    if (item?.currency === 'USD') {
      tableTotals.totalCommission[0] += item?.transactionStatus === 'Approved' ? Number.parseFloat(item.commissionAmount || 0) : 0;
      tableTotals.totalGrossSale[0] += Number.parseFloat(item.grossSale || 0);
      tableTotals.totalNetSale[0] += Number.parseFloat(item.netSale || 0);
    }
  });

  return { results: result, totals: tableTotals };
};

export const processDataToCSVString = (allTableData: any[], allTableTotals: TMTotalsProcessed | undefined, allReportColumns: TableColumn[]) => {
  // Create the Row of Column Titles
  const headerRow = allReportColumns.map((column) => column.text).toString();

  // Create the Row of Footer Totals
  const footerValues = { netSale: allTableTotals?.totalNetSale || [0, 0], grossSale: allTableTotals?.totalGrossSale || [0, 0], commissionAmount: allTableTotals?.totalCommission || [0, 0] };
  const footerColumns = ['netSale', 'grossSale', 'commissionAmount'];
  const footerRow = allReportColumns.map((column) => {
    if (column.dataType === 'key') return 'TOTAL CA / TOTAL US';
    if (!column?.dataField || !allTableTotals || !footerColumns.includes(column.dataField)) return '';
    if (column.dataType === 'money') {
      return `"${moneyFormater.format(Number(footerValues[column.dataField as keyof typeof footerValues][0]))} / ${moneyFormater.format(Number(footerValues[column.dataField as keyof typeof footerValues][1]))}"`;
    }
    return footerValues[column.dataField as keyof typeof footerValues].join(' / ');
  });

  // Create the Data Rows
  const newData = allTableData.map((row) => {
    const rowArray = allReportColumns.map((column) => {
      if (!column?.dataField || row[column?.dataField] == null) return 'N/A';
      if (!['"', '\r', '\n', ','].some((e) => row[column.dataField].toString().indexOf(e) !== -1)) {
        if (typeof row[column.dataField] === 'number') return +row[column.dataField].toFixed(2);
        return row[column.dataField];
      }
      return `"${row[column.dataField].toString().replace(/"/g, '""')}"`;
    });
    return rowArray.toString();
  });

  return [headerRow, ...newData, footerRow].join('\n');
};
