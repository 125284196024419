import styled from 'styled-components';
import { colors, fonts } from '../../../../../styles/theme';
import { toRem } from '../../../../../utils';
import {
  Checkbox,
} from '../../../../../components';

type SelectedListFooterStyledPropsTypes = {
  show: boolean
}

export const MerchantAccessWrapper = styled.div`
  width: 100%;
`;

export const AccessTitleStyled = styled.p`
  ${fonts.lato.H20B}
  margin: ${toRem(32)} 0 ${toRem(8)} 0;
`;

export const AccessDescriptionStyled = styled.div`
  ${fonts.lato.H16}
`;

export const AccessRadioButtonsStyled = styled.div`
  ${fonts.lato.H16}
  margin: ${toRem(48)} 0;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const AccessRadioStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MerchantManagementStyled = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${colors.color15};
`;

export const AllMerchantsListWrapper = styled.div`
  ${fonts.lato.H16}
  width: 40%;
  padding: ${toRem(24)};
  border-right: 1px solid ${colors.color15};
`;

export const AllMerchantsListStyled = styled.div`
  height: 425px;
  margin: ${toRem(20)} 0 0;
  overflow: auto;

  label {
    margin-bottom: ${toRem(12)};
  }
`;

export const AllMerchantsListFooterStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${toRem(20)};
`;

export const AllMerchantsListFooterButtonsStyled = styled.div`
  display: flex;
  gap: 16px;
`;

export const SelectedMerchantsListWrapper = styled.div`
  width: 60%;
  padding: ${toRem(24)};
`;

export const SelectedMerchantsTitleStyled = styled.div`
  ${fonts.lato.H20}
  margin-bottom: ${toRem(24)};
`;

export const SelectedListStyled = styled.div`
  ${fonts.lato.H16}
  height: 425px;
  margin: ${toRem(40)} 0 0;
  overflow: auto;
`;

export const MerchantItemStyled = styled.div`
  ${fonts.lato.H16}
  line-height: 1.5;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SelectedListFooterStyled = styled.div<SelectedListFooterStyledPropsTypes>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  width: 100%;
  margin-top: ${toRem(30)};
  justify-content: end;
`;

export const NewMerchantStyled = styled.div`
  width: 500px;
  padding: ${toRem(24)};
`;

export const NewMerchantItemTitleStyled = styled.div`
  ${fonts.lato.H24}
  margin-bottom: ${toRem(24)};
`;

export const NewMerchantItemDescriptionStyled = styled.div`
  ${fonts.lato.H16}
  margin-bottom: ${toRem(24)};
`;

export const LetterGroupTitleStyled = styled.p`
  ${fonts.lato.H14}
  margin-left: ${toRem(24)};
  padding-bottom: ${toRem(4)};
  color: ${colors.color2};
`;

export const ModalListItemStyled = styled.div`
  ${fonts.lato.H16B};
  line-height: 1.5;
  padding-bottom: ${toRem(8)};
`;

export const ModalListItemsBoxStyled = styled.div`
  max-height: ${toRem(430)};
  overflow-y: auto;
`;

export const ModalTextStyled = styled.div`
  ${fonts.lato.H14}
  padding-top: ${toRem(24)};
`;

export const AddMerchantCheckboxStyled = styled(Checkbox)`
  > span {
    ${fonts.lato.H14}
  }
  > span:first-child {
    border: 1px solid ${({ checked }) => (checked ? colors.color2 : colors.color15)};
    background-color: ${({ checked }) => (checked ? colors.color2 : colors.color4)};
    transition: none;
  }
`;

export const NonSelectedTextStyled = styled.div`
    ${fonts.lato.H16};
    color: ${colors.color1};
    line-height: 1.5;
    margin: 0 auto 0 auto;
    text-align: center;
    width: 380px;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
