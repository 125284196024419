import { faChevronRight, faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../../components';
import { ImagePreview } from '../../../../components/ImagePreview';
import { colors, fonts } from '../../../../styles/theme';
import {
  AD_TYPES, imageList, USER_TYPES_ID,
} from '../../../../utils';

type ActionProps = {
  row: any
  userType: any
  actingAs: any
  navigate: any
}

const ImgWrapperStyled = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  overflow: visible;
`;

const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  padding: 0;
  font-size: 1.3rem;
  width: 30px;
  color: ${colors.color1};
`;

const CellStyled = styled.div`
  padding: 5px 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  ${fonts.lato.H16}

  a {
    color: ${colors.color1};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${colors.color2};
    }
  }

  p {
    ${fonts.lato.H16R}
    color: ${colors.color1};
    line-height: 1.5rem;
    margin-bottom: 0.3rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    ${fonts.lato.H14}
    color: ${colors.color13};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  button {
    ${fonts.lato.H16}
  }
`;

const ImageCellStyled = styled.div`
  padding: 5px 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

const formattedCode = (row : any, openAdFn: (adId: string) => void) => (
  <CellStyled>
    <ButtonStyled
      theme="text-only"
      onClick={() => openAdFn(row?.id)}
    >
      <FontAwesomeIcon icon={faCode} />
    </ButtonStyled>
  </CellStyled>
);

const formattedProduct = (value: any, row: any) => (
  <CellStyled>
    <p>{row?.productName}</p>
    <span>{value}</span>
  </CellStyled>
);

const formattedId = (value: any, row: any) => (
  <CellStyled>
    <p>{value}</p>
    <span>{row?.campaignName}</span>
  </CellStyled>
);

const formattedName = (value: any, row: any, userType: number, actingAs: number | undefined) => (
  <CellStyled>
    {row?.defaultLandingPage && (
    <p>
      <a
        href={row?.defaultLandingPage}
        target="_blank"
        rel="noreferrer"
      >
        {value}
      </a>
    </p>
    )}
    {!row?.defaultLandingPage && (<p>{value}</p>)}
    {userType !== USER_TYPES_ID.MERCHANT && actingAs !== USER_TYPES_ID.MERCHANT && (
      <span>{row?.merchantName}</span>
    )}
  </CellStyled>
);

const formattedType = (value: any, row: any) => (
  <CellStyled>
    <p>{value}</p>
    <span>
      {row?.width && row?.height && `${row?.width}w x ${row?.height}h`}
      {!row?.width && !row?.height && 'N/A'}
    </span>
  </CellStyled>
);

const formattedDateCell = (value: any) => (
  <CellStyled>
    {new Date(value).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}
  </CellStyled>
);

const formattedImage = (value: string, row: any) => (
  <ImageCellStyled>
    <ImgWrapperStyled>
      {row.adType === AD_TYPES.BANNER ? (
        <ImagePreview
          src={value !== null ? value : imageList.noPic.src}
          width="40px"
          alt="cover"
          useType="table"
        />
      ) : (
        <img
          src={imageList.noPic.src}
          alt="cover"
          width="40px"
        />
      )}
    </ImgWrapperStyled>
  </ImageCellStyled>
);

const EditAd = ({
  row, userType, actingAs, navigate,
}: ActionProps) => (
  <CellStyled>
    {!(userType === USER_TYPES_ID.PUBLISHER || actingAs === USER_TYPES_ID.PUBLISHER) && (
    <ButtonStyled
      theme="text-only"
      onClick={() => navigate(row)}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </ButtonStyled>
    )}
  </CellStyled>
);

export const columns = (openAdFn: (adId: string) => void, userType: number, actingAs: number | undefined, cellOverflow: boolean, navigateToEdit: (row: any) => void): TableColumn[] => [
  {
    text: 'Thumbnail',
    dataField: 'adContent',
    width: '7%',
    cellOverflow,
    formatter: formattedImage,
  },
  {
    text: 'Type/Size',
    dataField: 'adType',
    width: '12%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: formattedType,
  },
  {
    text: `${userType === USER_TYPES_ID.MERCHANT || actingAs === USER_TYPES_ID.MERCHANT ? 'Name' : 'Name/Merchant'}`,
    dataField: 'adName',
    width: '22%',
    notClickable: true,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value, row) => formattedName(value, row, userType, actingAs),
  },
  {
    text: 'ID/Campaign',
    dataField: 'id',
    width: '18%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: formattedId,
  },
  {
    text: 'Product/Category',
    dataField: 'productCategory',
    width: '18%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: formattedProduct,
  },
  {
    text: 'Created Date',
    dataField: 'createdAt',
    width: '12%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: formattedDateCell,
  },
  {
    text: 'Code',
    dataField: '_',
    width: '6%',
    notClickable: true,
    formatter: (_, row) => formattedCode(row, openAdFn),
  },
  {
    text: '',
    dataField: '__',
    width: '6%',
    formatter: (_: any, row: any) => (
      <EditAd
        row={row}
        userType={userType}
        actingAs={actingAs}
        navigate={navigateToEdit}
      />
    ),
  },
];
