import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useActAsContext } from '../../../../../context';
import * as Styled from './styles';

type SelectMerchantProps = {
  value: any;
}

export const SelectMerchant = ({ value }: SelectMerchantProps) => {
  const hook = useActAsContext();

  return (
    <Styled.CellWrapperStyled>
      <Styled.ButtonStyled
        onClick={() => hook.setActAsMerchantHandler(value)}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Styled.ButtonStyled>
    </Styled.CellWrapperStyled>
  );
};
