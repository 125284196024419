import { ERROR_TYPES } from '../../../../utils';

export const validationfields: {[key: string]: string} = {
  publisherDescription: ERROR_TYPES.NOT_EMPTY,
  website: ERROR_TYPES.NOT_EMPTY,
  avgMonthlyAudience: ERROR_TYPES.NOT_EMPTY,
};

export const arrayValidationfields: {[key: string]: string} = {
  audienceMarkets: ERROR_TYPES.AT_LEAST_ONE,
  productCategories: ERROR_TYPES.AT_LEAST_ONE,
  promotionMethods: ERROR_TYPES.AT_LEAST_ONE,
};
