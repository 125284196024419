import styled from 'styled-components';
import { colors, fonts } from '../../styles/theme';

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 23% calc(100% - 23%);
  height: 100vh;
`;

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.color1};
`;

export const RightStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;

  h1 {
    color: ${colors.color1};
    margin-bottom: 1rem;
    line-height: 1.2;
    ${fonts.gotham.H36M};
    text-align: center;
  }

  span {
    margin-bottom: 3rem;
    text-align: center;
    ${fonts.lato.H18}
  }

  img{
    width: 75%;
    margin-bottom: 1rem;
  }

  a {
    color: ${colors.color1};
    &:hover {
      color: ${colors.color2};
    }
  }
`;
