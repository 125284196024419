import { gql } from '@apollo/client';

export const ADMIN_DASHBOARD = gql`
  query AdminDashboard($input: AdminDashboardInput!) {
    adminDashboard(input: $input) {
      pendingApplications
      merchantAccountBalances {
        warning
        danger
      }
      topMerchants {
        id
        totalClicks
        totalTransactions
        approvedTransactions
        totalCommissions
        merchant {
          companyName
        }
      }
      merchantPerformance {
        totalCommissions
        totalTransactions
        approvedTransactions
      }
      topPublishers {
        id
        totalClicks
        totalTransactions
        approvedTransactions
        totalCommissions
        publisher {
          companyName
          companyUrl
        }
      }
      topProducts {
        id
        totalCommissions
        product {
          id
          name
          program {
            id
            merchant {
              companyName
            }
          }
        }
      }
      topAds {
        id
        epc
        ad {
          id
          adContent
          program {
            id
            merchant {
              companyName
            }
          }
        }
      }
    }
  }
`;
