import React from 'react';
import { Modal } from '../../../../../../components';
import { CONFLICT_MODAL } from '../../enums';
import { ConflictModalProps } from '../../types';
import * as Styled from './styles';

const ConflictModal = ({ hook }: ConflictModalProps) => (
  <Modal isOpen={hook.hookHasConflicts && hook.hookSecondRender}>
    <Styled.Wrapper>
      <Styled.TitleStyled>
        {CONFLICT_MODAL.TITLE}
      </Styled.TitleStyled>
      <Styled.BodyStyled>
        {CONFLICT_MODAL.PROMPT}
        {hook.hookConflictingCommissions.map((item: any) => (
          <Styled.EntryStyled>
            {CONFLICT_MODAL.CONFLICT_COMMISSION(item.id, item.startDate, item.endDate)}
          </Styled.EntryStyled>
        ))}
      </Styled.BodyStyled>
      <Styled.FooterStyled>
        {hook.hookHasEndlessCommissions ? CONFLICT_MODAL.MESSAGE_HAS_ENDLESS : CONFLICT_MODAL.MESSAGE}
      </Styled.FooterStyled>
      <Styled.ButtonWrapper>
        <Styled.ButtonStyled
          onClick={hook.hookOnClose}
          theme="tertiary"
        >
          {CONFLICT_MODAL.BUTTON}
        </Styled.ButtonStyled>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  </Modal>
);

export default ConflictModal;
