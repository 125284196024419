import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useToast, useUserInfo } from '../../../hooks';
import {
  ERROR_TYPES, formatterForOptions, URL_STATUSES,
} from '../../../utils';
import { useValidation } from '../../../utils/validation';
import { CREATE_CAMPAIGN } from '../graphql/mutations';
import { CAMPAIGN_OPTION } from '../graphql/queries';

type Option = {
  label: string;
  value: string;
}

type CheckBoxOption = {
  label: string;
  value: string;
  checked: boolean;
}

export const useCreateCampaignModal = (isOpen: boolean) => {
  const { hookShowToast } = useToast();
  const { hookWhoAmI } = useUserInfo();
  const location = useLocation();
  const vali = useValidation();

  const [campaignName, setCampaignName] = useState('');
  const [description, setDescription] = useState('');

  const [status, setStatus] = useState<Option>();
  const [statusOptions, setStatusOptions] = useState<Option[]>([]);

  const [startDate, setStartDate] = useState<Date>();
  const [startCalendarOpen, setStartCalendarOpen] = useState(false);

  const [endDate, setEndDate] = useState<Date>();
  const [endCalendarOpen, setEndCalendarOpen] = useState(false);

  const [tags, setTags] = useState<Option[]>([]);
  const [tagsOptions, setTagOptions] = useState<Option[]>([]);

  const [products, setProducts] = useState<Option>();
  const [productsOptions, setProductsOptions] = useState<Option[]>([]);

  const [landingPageUrl, setLandingPageUrl] = useState('');
  const [urlPlaceholder, setUrlPlaceholder] = useState('');

  const [language, setLanguage] = useState<Option>({ label: 'English', value: 'English' });
  const [languageOptions, setLanguageOptions] = useState<Option[]>([]);

  const [thirdPartyTracking, setThirdPartyTracking] = useState('');

  const [publisherGroups, setPublisherGroups] = useState<string[]>([]);
  const [publisherGroupOptions, setPublisherGroupOptions] = useState<CheckBoxOption[]>([]);
  const [publisherGroupBackup, setPublisherGroupBackup] = useState<CheckBoxOption[]>([]);

  const [requiredFieldsError, setRequiredFieldsError] = useState(false);
  const [campaignModalErrors, setCampaignModalErrors] = useState<{ [key: string]: string }>({});
  const [secondRender, setSecondRender] = useState(false);
  const [landingUrlError, setLandingUrlError] = useState('');
  const [thirdPartyUrlError, setThirdPartyUrlError] = useState('');
  const [landingUrlStatus, setLandingUrlStatus] = useState('');
  const [thirdPartyUrlStatus, setThirdPartyUrlStatus] = useState('');

  const [programId, setProgramId] = useState();

  const [page, setPage] = useState(1);

  const [createCampaign, { loading: createCampaignLoading }] = useMutation(CREATE_CAMPAIGN);

  const [getOptions, { loading: getOptionsLoading }] = useLazyQuery(CAMPAIGN_OPTION);

  const getProgramHandler = async () => {
    if (location.state.program !== undefined) {
      setProgramId(location.state.program);
    }
  };

  const setCampaignNameHandler = (e: any) => {
    setCampaignName(e?.target ? e.target.value : e);
  };

  const setDescriptionHandler = (e: any) => {
    setDescription(e?.target ? e.target.value : e);
  };

  const setStatusHandler = (value: Option) => {
    setStatus(value);
  };

  const onApplyStartCalendarHandler = (start: Date) => {
    setStartDate(start);
    setStartCalendarOpen(false);
  };

  const onCancelStartCalendarHandler = () => {
    setStartCalendarOpen(false);
    setStartDate(new Date());
  };

  const onOpenStartCalendarHandler = () => {
    setStartCalendarOpen(true);
  };

  const onApplyEndCalendarHandler = (end: Date) => {
    setEndDate(end);
    setEndCalendarOpen(false);
  };

  const onCancelEndCalendarHandler = () => {
    setEndCalendarOpen(false);
    setEndDate(undefined);
  };

  const onOpenEndCalendarHandler = () => {
    setEndCalendarOpen(true);
  };

  const setTagsHandler = (value: Option[]) => {
    setTags(value);
  };

  const setProductHandler = (value: Option) => {
    setProducts(value);
  };

  const setDefaultLandingPageHandler = (e: any) => {
    setLandingPageUrl(e?.target ? e.target.value : e);
  };

  const setLanguageHandler = (value: Option) => {
    setLanguage(value);
  };

  const setThirdPartyTrackingHandler = (e: any) => {
    setThirdPartyTracking(e?.target ? e.target.value : e);
  };

  const setPublisherGroupCheckedHandler = (value: CheckBoxOption) => {
    const newPublisherGroupOptions = publisherGroupOptions.map((option) => {
      if (option.value === value.value) {
        return {
          ...option,
          checked: !option.checked,
        };
      }
      return option;
    });

    const selectedPublisherGroups = newPublisherGroupOptions.filter((option) => option.checked).map((option) => option.value);
    setPublisherGroups(selectedPublisherGroups);
    setPublisherGroupOptions(newPublisherGroupOptions);
  };

  const resetCampaignModalErrors = () => {
    const error: { [key:string]: string } = {};
    error.campaignName = '';
    error.status = '';
    error.description = '';
    error.language = '';
    error.endDate = '';
    setCampaignModalErrors(error);
  };

  const clearForm = () => {
    setCampaignName('');
    setDescription('');
    setStatus(statusOptions[0]);
    setStartDate(undefined);
    setEndDate(undefined);
    setTags([]);
    setProducts(undefined);
    setLandingPageUrl('');
    setLanguage({ label: 'English', value: 'English' });
    setThirdPartyTracking('');
    setPublisherGroups([]);
    setPublisherGroupOptions(publisherGroupBackup);
    resetCampaignModalErrors();
    setSecondRender(false);
    setRequiredFieldsError(false);
  };

  const getOptionsHandler = async () => {
    const { data } = await getOptions({
      variables: {
        input: {
          merchantId: hookWhoAmI?.companyId?.toString() || location?.state?.merchantId?.toString(),
          programId: hookWhoAmI.programId || location.state.program,
        },
      },
      fetchPolicy: 'no-cache',
    });
    setLanguageOptions(formatterForOptions(data.getCreateCampaignOptions.languages, 'language', 'language'));
    setProductsOptions(formatterForOptions(data.getCreateCampaignOptions.products, 'name', 'id'));
    setStatusOptions(formatterForOptions(data.getCreateCampaignOptions.campaignStatusTypes, 'type', 'type'));
    setTagOptions(formatterForOptions(data.getCreateCampaignOptions?.tags, 'name', 'id'));
    setUrlPlaceholder(data.getCreateCampaignOptions.merchant.companyUrl);

    const groupsFormatted = data.getCreateCampaignOptions?.publisherGroups.map((item: any) => ({
      label: item.name,
      value: item.id,
      checked: false,
    }));

    setPublisherGroupBackup(groupsFormatted);
    setPublisherGroupOptions(groupsFormatted);
  };

  const values: { [key: string]: string } = {
    campaignName,
    status: status?.label || '',
    description,
    language: language?.label || '',
    startDate: startDate?.toString() || '',
    endDate: endDate?.toString() || '',
  };

  const fields = {
    campaignName: ERROR_TYPES.NOT_EMPTY,
    status: ERROR_TYPES.SELECTION_REQUIRED,
    description: ERROR_TYPES.SELECTION_REQUIRED,
    language: ERROR_TYPES.SELECTION_REQUIRED,
    startDate: ERROR_TYPES.SELECTION_REQUIRED,
    endDate: ERROR_TYPES.END_DATE,
  };

  const validateWebsite = () => {
    if (landingPageUrl !== '') {
      vali.validateUrlStatus(landingPageUrl, setLandingUrlStatus);
      vali.renderUrlCheck(landingUrlStatus, setLandingUrlError, true);
    }
    if (thirdPartyTracking !== '') {
      vali.validateUrlStatus(thirdPartyTracking, setThirdPartyUrlStatus);
      vali.renderUrlCheck(thirdPartyUrlStatus, setThirdPartyUrlError, true);
    }
  };

  const handleValidation = () => {
    let noErrors = true;

    if (landingPageUrl !== '') {
      validateWebsite();
      if (landingUrlStatus !== URL_STATUSES.ACTIVE_WEBSITE.STATUS && landingUrlStatus !== URL_STATUSES.UNSAFE_WEBSITE.STATUS && landingUrlStatus !== URL_STATUSES.INACTIVE_WEBSITE.STATUS) {
        noErrors = false;
      }
    }
    if (thirdPartyTracking !== '') {
      validateWebsite();
      if (thirdPartyUrlStatus !== URL_STATUSES.ACTIVE_WEBSITE.STATUS && thirdPartyUrlStatus !== URL_STATUSES.UNSAFE_WEBSITE.STATUS && thirdPartyUrlStatus !== URL_STATUSES.INACTIVE_WEBSITE.STATUS) {
        noErrors = false;
      }
    }
    return vali.validateAll(values, fields, setCampaignModalErrors, secondRender) && noErrors;
  };

  const createPublisherGroupIDList = (listId: []) => {
    const newListId: string[] = [];

    listId.map((idObj: {id: string, typeName: string}) => {
      newListId.push(idObj.id);
    });

    return newListId;
  };

  const onCloseHandler = (onClose: () => void) => {
    clearForm();
    onClose();
  };

  const createAdCampaignHandler = async (setCampaign: any, onClose: () => void) => {
    setRequiredFieldsError(campaignName === '' || description === '' || status === undefined || startDate === undefined || language === undefined);
    setSecondRender(true);
    const validated = handleValidation();
    if (!validated) return;

    const formattedTags = tags.map((tag) => tag?.label);
    const { data, errors } = await createCampaign({
      variables: {
        input: {
          name: campaignName,
          description,
          startDate: startDate?.toUTCString(),
          endDate: endDate?.toUTCString() || undefined,
          defaultLandingPage: landingPageUrl || undefined,
          thirdPartyUrl: thirdPartyTracking || undefined,
          language: language?.value,
          productId: products?.value || undefined,
          status: status?.value,
          programId: programId || hookWhoAmI.programId,
          tags: formattedTags || undefined,
          publisherGroupIds: publisherGroups || undefined,
        },
      },
    });

    if (errors) {
      hookShowToast('Error creating campaign');
      return;
    }

    if (data) {
      setRequiredFieldsError(false);
      onCloseHandler(onClose);
      setCampaign({ value: data.createNewCampaign.id, label: data.createNewCampaign.name }, language, createPublisherGroupIDList(data.createNewCampaign.publisherGroupIds));
    }
  };

  useEffect(() => {
    if (isOpen) {
      setPage(1);
      getProgramHandler();
    }
  }, [isOpen]);

  useEffect(() => {
    getOptionsHandler();
  }, [programId]);

  useEffect(() => {
    if (statusOptions) setStatus(statusOptions[0]);
  }, [statusOptions, languageOptions]);

  useEffect(() => {
    if (landingPageUrl === '') {
      setLandingUrlError('');
    }
    vali.validateUrlStatus(landingPageUrl, setLandingUrlStatus);
  }, [landingPageUrl]);

  useEffect(() => {
    if (thirdPartyTracking === '') {
      setThirdPartyUrlError('');
    }
    vali.validateUrlStatus(thirdPartyTracking, setThirdPartyUrlStatus);
  }, [thirdPartyTracking]);

  useEffect(() => {
    if (secondRender) {
      handleValidation();
    }
  }, [secondRender, startDate, endDate]);

  return {
    hookWhoAmI,
    hookCampaignName: campaignName,
    hookSetCampaignName: setCampaignNameHandler,

    hookDescription: description,
    hookSetDescription: setDescriptionHandler,

    hookStatus: status,
    hookStatusOptions: statusOptions,
    hookSetStatus: setStatusHandler,

    hookStartDate: startDate,
    hookStartCalendarOpen: startCalendarOpen,
    hookOnOpenStartCalendar: onOpenStartCalendarHandler,
    hookOnApplyStartCalendar: onApplyStartCalendarHandler,
    hookOnCancelStartCalendar: onCancelStartCalendarHandler,

    hookEndDate: endDate,
    hookEndCalendarOpen: endCalendarOpen,
    hookOnOpenEndCalendar: onOpenEndCalendarHandler,
    hookOnApplyEndCalendar: onApplyEndCalendarHandler,
    hookOnCancelEndCalendar: onCancelEndCalendarHandler,

    hookTags: tags,
    hookTagsOptions: tagsOptions,
    hookSetTags: setTagsHandler,

    hookProducts: products,
    hookProductsOptions: productsOptions,
    hookSetProducts: setProductHandler,

    hookDefaultLandingPageUrl: landingPageUrl,
    hookUrlPlaceholder: urlPlaceholder,
    hookSetDefaultLandingPageUrl: setDefaultLandingPageHandler,

    hookLanguage: language,
    hookLanguageOptions: languageOptions,
    hookSetLanguage: setLanguageHandler,

    hookThirdPartyTrackingUrl: thirdPartyTracking,
    hookSetThirdPartyTrackingUrl: setThirdPartyTrackingHandler,

    hookPublisherGroups: publisherGroups,
    hookSetPublisherGroup: setPublisherGroups,

    hookPublisherGroupOptions: publisherGroupOptions,
    hookSetPublisherGroupOptions: setPublisherGroupOptions,
    hookSetPublisherGroupHandler: setPublisherGroupCheckedHandler,

    hookPublisherGroupBackup: publisherGroupBackup,

    hookLoading: getOptionsLoading || createCampaignLoading,
    hookSubmitCampaign: createAdCampaignHandler,
    hookOnClose: onCloseHandler,

    hookRequiredFieldsError: requiredFieldsError,
    hookCampaignErrors: campaignModalErrors,
    hookLandingUrlError: landingUrlError,
    hookThirdPartyUrlError: thirdPartyUrlError,
    hookValidateSingleField: handleValidation,
    hookValidateWebsite: validateWebsite,

    hookPage: page,
    hookSetPage: setPage,
  };
};
