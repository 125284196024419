import styled from 'styled-components';
import { toRem } from '../../../../../../utils';

export const DropdownChildWrapper = styled.div`
  min-width: ${toRem(140)};
`;

export const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${toRem(10)} ${toRem(24)};
  text-align: left;
`;
