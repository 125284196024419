import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
query company($companyId: ID!) {
  company(id: $companyId) {
    id
    overview {
      businessDesc
      profilePhotoURL
      primaryPlatformLink
      hasWebsite
      audienceMarkets
      avgMonthlyAudience
      productCategories
      promotionMethods
      audienceAges
      gender
      creditScoreRating
      avgIncomes
    }
  }
}
`;
