import React from 'react';

import {
  AppWrapper, PageTitle,
} from '../../../components';
import { path, QST_STATES } from '../../../utils';
import { useNewMerchant } from './hooks';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type NewMerchantProps = DefaultPropsType;

const NewMerchant = ({ permissionsCodeList = [] }: NewMerchantProps) => {
  const hook = useNewMerchant();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <PageTitle>{path.addNewMerchant.name}</PageTitle>
      <Styled.SectionWrapperStyled>
        <Styled.SectionTitleStyled>Primary Contact</Styled.SectionTitleStyled>
        <Styled.InputTwoColumnStyled
          label="first name"
          type="text"
          required
          onChange={hook.hookSetUserFirstName}
          error={hook.hookCreateMerchantErrors.firstName}
          onBlur={hook.hookValidateFields}
        />
        <Styled.InputTwoColumnStyled
          label="last name"
          type="text"
          required
          onChange={hook.hookSetUserLastName}
          error={hook.hookCreateMerchantErrors.lastName}
          onBlur={hook.hookValidateFields}
        />
        <Styled.InputTwoColumnStyled
          label="position"
          type="text"
          onChange={hook.hookSetUserPosition}
        />
        <Styled.InputFourColumnStyled
          label="email address"
          type="email"
          required
          onChange={hook.hookSetUserEmail}
          error={hook.hookCreateMerchantErrors.userEmail}
          onBlur={hook.hookValidateFields}
        />
        <Styled.CheckboxStyled
          label="Subscribe FC program emails"
          onChange={hook.hookSetUserSuscription}
          checked={hook.hookUserSuscription}
        />
      </Styled.SectionWrapperStyled>

      <Styled.SectionWrapperStyled>
        <Styled.SectionTitleStyled>Company Information</Styled.SectionTitleStyled>
        <Styled.InputThreeColumnStyled
          label="company name"
          type="text"
          required
          onChange={hook.hookSetCompanyName}
          error={hook.hookCreateMerchantErrors.companyName}
          onBlur={hook.hookValidateFields}
        />
        <Styled.InputThreeColumnStyled
          label="website url"
          type="text"
          required
          onChange={hook.hookSetWebsite}
          placeholder="https://www.your.site"
          error={hook.hookUrlError || hook.hookCreateMerchantErrors.website}
          onBlur={hook.hookValidateWebsite}
        />
        <Styled.InputTwoColumnStyled
          label="address 1"
          type="text"
          required
          onChange={hook.hookSetAddress}
          error={hook.hookCreateMerchantErrors.address}
          onBlur={hook.hookValidateFields}
        />
        <Styled.InputTwoColumnStyled
          label="address 2"
          type="text"
          onChange={hook.hookSetAddressTwo}
        />
        <Styled.InputTwoColumnStyled
          label="city"
          type="text"
          required
          onChange={hook.hookSetCity}
          error={hook.hookCreateMerchantErrors.city}
          onBlur={hook.hookValidateFields}
        />
        {hook.hookRegionOptions.length > 0 ? (
          <Styled.SelectStyled
            label="state/province"
            selected={hook.hookRegion}
            options={hook.hookRegionOptions}
            required
            onChange={hook.hookSetSelectRegion}
            isDisabled={hook.hookIsDisabled}
            error={hook.hookCreateMerchantErrors.region}
          />
        ) : (
          <Styled.InputTwoColumnStyled
            label="state/province"
            type="text"
            required
            value={hook.hookRegion.label}
            onChange={hook.hookSetTextRegion}
            onBlur={hook.hookValidateFields}
            disabled={hook.hookCountry.label === ''}
            error={hook.hookCreateMerchantErrors.region}
          />
        )}
        <Styled.SelectStyled
          label="country"
          selected={hook.hookCountry}
          options={hook.hookCountryOptions}
          required
          onChange={hook.hookSetCountry}
          isLoading={hook.hookCountryLoading}
          isDisabled={hook.hookCountryLoading}
          error={hook.hookCreateMerchantErrors.country}
        />
        <Styled.InputTwoColumnStyled
          label="zip/postal code"
          type="text"
          required
          onChange={hook.hookSetPostalCode}
          error={hook.hookCreateMerchantErrors.postalCode}
          onBlur={hook.hookValidateFields}
        />
        <Styled.InputPhoneStyled
          label="primary phone number"
          type="text"
          required
          value={hook.hookPhoneOne}
          onChange={hook.hookSetPhoneOne}
          error={hook.hookCreateMerchantErrors.phone}
          onBlur={hook.hookValidateFields}
        />
        <Styled.InputPhoneStyled
          label="secondary phone number"
          type="text"
          value={hook.hookPhoneTwo}
          onChange={hook.hookSetPhoneTwo}
          error={hook.hookCreateMerchantErrors.phoneTwo}
          onBlur={hook.hookValidateFields}
        />
        <Styled.InputTwoColumnStyled
          label="company email address"
          type="email"
          onChange={hook.hookSetCompanyEmail}
          error={hook.hookCreateMerchantErrors.companyEmail}
          onBlur={hook.hookValidateFields}
        />
      </Styled.SectionWrapperStyled>

      <Styled.SectionWrapperStyled>
        <Styled.SectionTitleStyled>Billing Information</Styled.SectionTitleStyled>

        <Styled.InputFourColumnStyled
          label="taxation #"
          type="text"
          onChange={hook.hookSetTaxation}
          placeholder="123 456 789"
          value={hook.hookTaxationNumberDisabled || hook.hookIsCanada ? '' : hook.hookTaxation}
          disabled={hook.hookTaxationNumberDisabled || hook.hookIsCanada}
          error={hook.hookBillingInfoErrors.taxation}
          onBlur={hook.hookValidateBillingInfo}
        />
        <Styled.InputTwoColumnStyled
          label="billing contact"
          type="email"
          onChange={hook.hookSetBillingInfo}
          placeholder="account@merchant.com"
          error={hook.hookCreateMerchantErrors.billingContact}
          onBlur={hook.hookValidateFields}
        />
        <Styled.InputTwoColumnStyled
          label="gst #"
          type="text"
          value={hook.hookGstDisabled ? '' : hook.hookGst}
          onChange={hook.hookSetGst}
          placeholder="123456789RT0001"
          disabled={hook.hookGstDisabled}
          error={hook.hookBillingInfoErrors.gstTaxation}
          onBlur={hook.hookValidateBillingInfo}
        />
        <Styled.InputTwoColumnStyled
          label={hook.hookRegion.label === QST_STATES[0] ? 'qst #' : 'pst #'}
          type="text"
          value={hook.hookPstDisabled ? '' : hook.hookPst}
          onChange={hook.hookSetPst}
          placeholder={hook.hookRegion.label === QST_STATES[0] ? '1234567890TQ1234' : 'PST12345678'}
          disabled={hook.hookPstDisabled}
          error={hook.hookBillingInfoErrors.pstTaxation}
          onBlur={hook.hookValidateBillingInfo}
        />
        <Styled.InputTwoColumnStyled
          label="hst #"
          type="text"
          value={hook.hookHstDisabled ? '' : hook.hookHst}
          onChange={hook.hookSetHst}
          placeholder="123456789RT0001"
          disabled={hook.hookHstDisabled}
          error={hook.hookBillingInfoErrors.hstTaxation}
          onBlur={hook.hookValidateBillingInfo}
        />
      </Styled.SectionWrapperStyled>

      <Styled.ButtonWrapperStyled>
        <Styled.ButtonStyled
          type="button"
          theme="secondary"
          onClick={hook.hookBackButton}
        >
          Cancel
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          type="button"
          loading={hook.hookCreateMerchantLoading}
          disabled={hook.hookCreateMerchantLoading}
          onClick={hook.hookCreateMerchant}
        >
          Save
        </Styled.ButtonStyled>
      </Styled.ButtonWrapperStyled>
    </AppWrapper>
  );
};

export default NewMerchant;
