import { gql } from '@apollo/client';

export const LIST_STATIC_AD_FILTER_OPTIONS = gql`
  query getSearchStaticAdsFilterOptions($input: AdsFilterOptionsInput) {
    getSearchStaticAdsFilterOptions(input: $input) {
      adStatusTypes {
        type
      }
      merchants {
        program {
          id
        }
        companyName
      }
      adTypes {
        type
      }
      languages {
        language
      }
      productCategories {
        name
      }
    }
  }
`;
