import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../../../../../../styles/theme';

export const CellStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  a {
    color: ${colors.color12};
  }
`;

export const PublishersStyled = styled.p`
  color: ${colors.color23};
  margin-top: 0.5rem;
`;

export const IconCell = styled(CellStyled)`
  align-items: flex-end;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  color: ${colors.color1};
`;
