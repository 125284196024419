/* eslint-disable no-param-reassign */
import { useLazyQuery, useMutation } from '@apollo/client';
import { SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { size, isEmpty } from 'lodash';
import { useModal, useQueries } from '../../../../../../../hooks';
import { GET_MEMBERS } from '../graphql/queries/getMembers';
import { GET_GROUP_MEMBERS } from '../graphql/queries/getGroupMembers';
import { PrimaryGroupMember, GroupMember } from '../types';
import { DELETE_MEMBERS } from '../graphql/mutations';

const ROWS_PER_PAGE = 10;

export const useMembers = () => {
  const [memberList, setMemberList] = useState<any>([]);
  const [disableRemove, setDisableRemove] = useState(true);
  const [search, setSearch] = useState<string>();
  const [publishersToRemove, setPublishersToRemove] = useState(0);
  const [openModal, setOpenModal] = useModal(false);
  const [dataCopy, setDataCopy] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const navigate = useNavigate();
  const { id } = useQueries();
  const [getMembers, { loading }] = useLazyQuery(GET_MEMBERS);
  const [getGroupMembers] = useLazyQuery(GET_GROUP_MEMBERS);

  const [deleteMembers] = useMutation(DELETE_MEMBERS);

  const setSearchHandler = (value: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(value.target.value);
    if (value.target.value !== '') {
      const newList = memberList.filter((member: any) => {
        if (member.publisher.id.includes(value.target.value)
        || member.company.name.toLowerCase().includes(value.target.value.toLowerCase())
        || member.company.website.includes(value.target.value.toLowerCase())
        ) return member;
      });
      setMemberList(newList);
      setTableData(newList.slice(0, ROWS_PER_PAGE));
      setTotalPages(Math.ceil(newList.length / ROWS_PER_PAGE));
      setCurrentPage(1);
    } else {
      setMemberList(dataCopy);
      setTableData(dataCopy.slice(0, ROWS_PER_PAGE));
      setTotalPages(Math.ceil(dataCopy.length / ROWS_PER_PAGE));
      setCurrentPage(1);
    }
  };

  /**
   * This takes the original (old) groupMember record and modifies data for frontend
   * @param primaryGroupMember
   * @returns groupMember
   */
  function prepareGroupMemberOriginal(primaryGroupMember: PrimaryGroupMember) {
    const publisherMember: GroupMember = {
      id: primaryGroupMember.id,
      check: false,
      publisher: {
        id: primaryGroupMember.publisher.id,
      },
      company: {
        name: primaryGroupMember.publisher.companyName,
        website: primaryGroupMember.publisher.trackings[0].primaryWebsite,
      },
      application: new Date(primaryGroupMember.createdAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }),
      memberSince: new Date(primaryGroupMember.approvedDate).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }),
      endDate: null,
      removedPublisher: false,
      url: primaryGroupMember.publisher.trackings[0].websiteStatus,
      status: primaryGroupMember.status,
      detail: primaryGroupMember.id,
      publisherGroups: primaryGroupMember.publisherGroups || [],
      disabled: primaryGroupMember.status === 'Closed',
    };
    return publisherMember;
  }
  const formatDateUTC = (isoDateString: string) => {
    const date = new Date(isoDateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC', // Ensuring UTC is used for formatting
    }).format(date);
  };

  /**
   * This takes the new publisherGroupMembership record (groupMember) modifies data for frontend
   * @param primaryGroupMember
   * @returns groupMember
   */
  function preparePublisherMemberNew(primaryGroupMember: PrimaryGroupMember) {
    let disabled = false;
    let endDate = null;
    if (primaryGroupMember.endDate !== null) {
      endDate = formatDateUTC(primaryGroupMember.endDate);
      disabled = true;
    }
    const publisherMember: GroupMember = {
      id: primaryGroupMember.membershipId,
      check: false,
      publisher: {
        id: primaryGroupMember.publisherId,
      },
      company: {
        name: primaryGroupMember.publisherName,
      },
      startDateObject: new Date(primaryGroupMember.startDate),
      memberSince: formatDateUTC(primaryGroupMember.startDate),
      endDate,
      removedPublisher: disabled,
      disabled,
      status: primaryGroupMember.status,
      detail: primaryGroupMember.membershipId,
      url: primaryGroupMember.websiteStatus,
      publisherGroups: primaryGroupMember.publisherGroups.map((item: any) => ({ id: item })) || [],
    };

    return publisherMember;
  }

  /**
   * Sorts the final group members with their timestamp of startDate.
   */
  function sortGroupMembers(groupMembers: GroupMember[]) {
    return groupMembers.sort((a, b) => {
      if (a.endDate === null && b.endDate !== null) {
        return -1;
      } if (a.endDate !== null && b.endDate === null) {
        return 1;
      }

      const startDateA: any = new Date(a.startDateObject || '');
      const startDateB: any = new Date(b.startDateObject || '');
      return startDateB - startDateA;
    });
  }

  const getMembersHandler = async () => {
    const finalPublishersList: GroupMember[] = [];
    const publishersRendered = new Set();

    const { data: membershipResults } = await getMembers({
      variables: {
        input: id,
      },
      fetchPolicy: 'no-cache',
    });

    const { data: publisherGroupMembershipResults } = await getGroupMembers({
      variables: {
        input: id,
      },
      fetchPolicy: 'no-cache',
    });

    const primaryGroupMembers = membershipResults.group.memberships;

    /**
     *  This is used to render new group members coming out of publisherGroupMemberships
    */
    if (!isEmpty(publisherGroupMembershipResults)) {
      const { groupMembers: newGroupMembers } = publisherGroupMembershipResults;
      if (size(newGroupMembers) > 0) {
        newGroupMembers.forEach((newGroupMember: PrimaryGroupMember) => {
          publishersRendered.add(newGroupMember.publisherId);
          const newGroupMemberModified = preparePublisherMemberNew(newGroupMember);
          finalPublishersList.push(newGroupMemberModified);
        });
      }
    }

    /**
     *  This is used to render original group members coming out of memberships
    */
    if (primaryGroupMembers !== undefined) {
      primaryGroupMembers.forEach((primaryGroupMember: PrimaryGroupMember) => {
        if (!publishersRendered.has(primaryGroupMember.publisher.id)) {
          const groupMember = prepareGroupMemberOriginal(primaryGroupMember);
          finalPublishersList.push(groupMember);
        }
      });
    }

    const sortedFinalPublishersList = sortGroupMembers(finalPublishersList);

    setDataCopy(sortedFinalPublishersList);
    setMemberList(sortedFinalPublishersList);
    setCurrentPage(1);
    setTableData(sortedFinalPublishersList.slice(0, ROWS_PER_PAGE));
    setTotalPages(Math.ceil(sortedFinalPublishersList.length / ROWS_PER_PAGE));
  };

  const deleteMembersHandler = async (list: Record<string, any>[]) => {
    await deleteMembers({
      variables: {
        inputs: list,
      },
    });
    getMembersHandler();
  };

  const setMemberListHandler = (value: any[]) => {
    const hasCheck = value.filter((row) => row.check);
    if (hasCheck.length > 0) {
      setPublishersToRemove(hasCheck.length);
      setDisableRemove(false);
    } else {
      setDisableRemove(true);
    }
    setTableData(value);
  };

  const removeFromGroupHandler = () => {
    const publishersChecked = memberList.filter((member: any) => member.check);
    const listToRemove = publishersChecked.map((publisher: any) => ({
      id: publisher.detail,
      publisherGroupIds: publisher.publisherGroups.filter((group: {id: string}) => group.id !== id).map((group: {id: string}) => group.id),
    }));

    setOpenModal();
    deleteMembersHandler(listToRemove);
  };

  const setPageHandler = (newPage: number) => {
    setTableData(memberList.slice((newPage - 1) * ROWS_PER_PAGE, newPage * ROWS_PER_PAGE));
    setCurrentPage(newPage);
  };

  useEffect(() => {
    getMembersHandler();
  }, []);

  return {
    hookSearch: search,
    hookSetSearch: setSearchHandler,
    hookLoading: loading,
    hookMembers: memberList,
    hookDisableRemove: disableRemove,
    hookSetTableData: setMemberListHandler,
    hookRemovePublishers: removeFromGroupHandler,
    hookOpenModal: openModal,
    hookSetOpenModal: setOpenModal,
    hookPublishersToRemove: publishersToRemove,
    hookNavigate: navigate,

    hookTableData: tableData,
    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookSetPageHandler: setPageHandler,
  };
};
