import React from 'react';
import * as Styled from '../styled';
import { CellProps } from '../types';
import { Checkbox } from '../../../../../../../../../components';

type CheckboxProps = CellProps & {
  row: any
  tableData: any[]
  setTableData: (tableData: any[]) => void
  isReadOnly: boolean
}

export const CheckboxCell = ({
  val, row, tableData, setTableData, isReadOnly,
}: CheckboxProps) => {
  const handleClick = (checked: boolean) => {
    const tableCopy = [...tableData];
    const index = tableCopy.findIndex((tableRow) => row.id === tableRow.id);
    if (index !== -1) {
      tableCopy[index].check = checked;
    }
    setTableData(tableCopy);
  };

  return (
    <Styled.CellStyled>
      <Checkbox
        checked={val}
        onChange={handleClick}
        disabled={row.removedPublisher}
      />
    </Styled.CellStyled>
  );
};
