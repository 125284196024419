import { gql } from '@apollo/client';

export const LIST_AVAILABLE_RULE_GROUPS = gql`
  query LIST_AVAILABLE_RULE_GROUPS($input: ListAvailableRuleGroupsInput!) {
    listAvailableRuleGroups(input: $input) {
      checkRuleGroups {
        id
        groupName
      }
    }
  }
`;
