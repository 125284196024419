import React from 'react';
import { faSearch, faArrowDown, faPlus } from '@fortawesome/free-solid-svg-icons';

import {
  AppWrapper, Button, InputText, PageTitle, Pagination, Select,
  RecordsDropdown,
  ReportPlaceholder,
} from '../../../components';
import { Table } from '../../../components/Table';
import {
  path, PAYMENT_METHOD, RECORDS_PER_PAGE_OPTIONS,
} from '../../../utils';
import { PAYMENT_REPORT } from './enums';
import { generateMonthList } from '../../../utils/generateMonths';
import { columns, merchantReportColumns, publisherColumns } from './contracts/columns';
import { usePaymentReport } from './hooks';
import PlaceHolderNoResults from '../../../components/NoResultsPlaceHolder';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type PaymentReportProps = DefaultPropsType;

const PaymentReport = ({ permissionsCodeList = [] }: PaymentReportProps) => {
  const reportListHook = usePaymentReport();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.WrapperStyled isAdvance>
        <Styled.HeaderStyled>
          <PageTitle
            isLoading={reportListHook.hookIsLoading}
            loadingText={reportListHook.hookLoadingMessage}
          >
            {path.paymentReport.name}
          </PageTitle>
        </Styled.HeaderStyled>
        { reportListHook.hookIsMerchant && (
        <Styled.InfoBox>
          <div> In this report, all monetary amounts (including commissions, bonuses, taxes, rollovers) are displayed in the Payment Currency, which is the currency the affiliate chooses to receive their payments in. This can be different from the currency merchants have chosen to run their program in. Exchange rates used for the conversion, where appropriate, are display in this report and any fluctuation to the actual payment date is at the publisher/affiliate’s own cost.</div>
          <div> For clarity, the Merchant Currency column is the original currency that commissions, bonuses and taxes were calculated in, and will match other reports generated in this platform and the monthly invoices issue to the merchants. </div>
        </Styled.InfoBox>
        )}

        <Styled.FiltersStyled>
          <Styled.FiltersRowStyled isNotAdmin={reportListHook.hookIsPublisher || reportListHook.hookIsMerchant}>
            {!reportListHook.hookIsPublisher
            && (
            <Select
              label={PAYMENT_REPORT.PUBLISHER_FILTER.LABEL}
              name={PAYMENT_REPORT.PUBLISHER_FILTER.NAME}
              onChange={reportListHook.hookHandleSetSelectedPublisher}
              options={reportListHook.hookPublisherOptions}
              width={reportListHook.hookIsPublisher || reportListHook.hookIsMerchant ? '48.5%' : '32%'}
              isLoading={reportListHook.hookIsPublisherLoading}
              selected={reportListHook.hookSelectedPublisher}
            />
            )}
            {!reportListHook.hookIsMerchant
            && (
            <Select
              label={PAYMENT_REPORT.MERCHANT_FILTER.LABEL}
              name={PAYMENT_REPORT.MERCHANT_FILTER.NAME}
              onChange={reportListHook.hookHandleSetSelectedPayment}
              options={PAYMENT_METHOD}
              width={reportListHook.hookIsPublisher || reportListHook.hookIsMerchant ? '48.5%' : '32%'}
              selected={reportListHook.hookSelectedPayment}
            />
            )}
            <Select
              label={PAYMENT_REPORT.PAYMENT_PERIOD}
              name={PAYMENT_REPORT.PUBLISHER_SELECT}
              onChange={reportListHook.hookHandleSetSelectedPeriod}
              options={generateMonthList(reportListHook.hookIsMerchant)}
              width={reportListHook.hookIsPublisher || reportListHook.hookIsMerchant ? '48.5%' : '32%'}
              selected={reportListHook.hookSelectedPeriod}
            />
          </Styled.FiltersRowStyled>
          {!reportListHook.hookIsMerchant
            && (
            <Styled.FiltersRowStyled>
              <Button
                theme="text-only"
                onClick={reportListHook.hookHandleClear}
              >
                {PAYMENT_REPORT.CLEAR_FORM}
              </Button>
            </Styled.FiltersRowStyled>
            )}
        </Styled.FiltersStyled>
        {!reportListHook.hookIsMerchant
            && (
            <Styled.LeftStyled>
              <InputText
                faIcon={faSearch}
                type="text"
                label={PAYMENT_REPORT.SEARCH_BAR.LABEL}
                name={PAYMENT_REPORT.SEARCH_BAR.INPUT_NAME}
                placeholder={PAYMENT_REPORT.SEARCH_BAR.PLACEHOLDER}
                onChange={reportListHook.hookOnSearch}
                value={reportListHook.hookFilter}
              />
            </Styled.LeftStyled>
            )}
        <Styled.OptionsRowStyled>

          <RecordsDropdown
            selected={reportListHook.hookSelectedRecords}
            options={RECORDS_PER_PAGE_OPTIONS}
            currentPage={reportListHook.hookCurrentPage}
            totalRecords={reportListHook.hookTotalRecords}
            onChange={reportListHook.hookHandleSetSelectedRecord}

          />

          <Styled.RightStyled>
            <Styled.ButtonStyled
              theme="tertiary"
              onClick={reportListHook.hookHandleDownload}
              loading={reportListHook.hookDownloadLoading}
            >
              <Styled.IconStyled icon={faArrowDown} />
              {' '}
              {PAYMENT_REPORT.DOWNLOAD_BUTTON}
            </Styled.ButtonStyled>

            <Styled.ButtonStyled
              onClick={reportListHook.hookHandleGenerateReportsButton}
              width="154px"
              loading={reportListHook.hookPaymentsLoading}
            >
              <Styled.IconStyled icon={faPlus} />
              {' '}
              {PAYMENT_REPORT.GENERATE_BUTTON}
            </Styled.ButtonStyled>
          </Styled.RightStyled>
        </Styled.OptionsRowStyled>

        {!reportListHook.hookIsMerchant && reportListHook.hookPaymentReports?.length !== 0
        && (
        <Table
          columns={reportListHook.hookIsPublisher ? publisherColumns : columns}
          data={reportListHook.hookPaymentReports}
          onRowClick={reportListHook.hookOnRowClick}
          sortColumn={reportListHook.hookSortColumn}
          onSort={reportListHook.hookOnSort}
          footerData={reportListHook.hookFooterValues}
        />
        )}
        {reportListHook.hookIsMerchant && reportListHook.hookPaymentReports?.length !== 0 && (
          <Table
            columns={merchantReportColumns(reportListHook.hookGoToPdfPage, reportListHook.hookOnRowClick, reportListHook.month, reportListHook.year)}
            data={reportListHook.hookPaymentReports}
            onRowClick={reportListHook.hookOnRowClick}
            sortColumn={reportListHook.hookSortColumn}
            onSort={reportListHook.hookOnSort}
          />
        )}
        {reportListHook.hookPaymentReports?.length !== 0
        && (
        <Pagination
          total={reportListHook.hookTotalPages}
          onPageChange={reportListHook.hookChangePage}
          currentPage={reportListHook.hookCurrentPage}
        />
        )}

        {!reportListHook.hookIsGenerated && reportListHook.hookPaymentReports?.length < 1 && (
          <ReportPlaceholder
            display="wait"
            text={PAYMENT_REPORT.PLACEHOLDER.GET_RESULT}
          />
        )}

        {reportListHook.hookPaymentReports?.length < 1 && reportListHook.hookIsGenerated && !reportListHook.hookIsLoading && (
          <PlaceHolderNoResults />
        )}
      </Styled.WrapperStyled>

    </AppWrapper>
  );
};

export default PaymentReport;
