import React from 'react';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import {
  AppWrapper, Button, InputText, LoadingDots, PageTitle, Pagination, Select, Table,
} from '../../../../components';
import { path } from '../../../../utils';
import { columns } from './contracts';
import { SEARCH_PROGRAMS } from './enum';
import { useSearchProgram } from './hooks';
import { Card } from './Components';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type SearchPublisherProgramsProps = DefaultPropsType;

const SearchPublisherPrograms = ({ permissionsCodeList = [] }: SearchPublisherProgramsProps) => {
  const hook = useSearchProgram();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <PageTitle>{path.searchProgram.name}</PageTitle>
      <Styled.DescriptionStyled>{SEARCH_PROGRAMS.DESCRIPTION}</Styled.DescriptionStyled>

      {hook.hookNewPrograms.length > 0 && (
        <Card programs={hook.hookNewPrograms} />
      )}

      <Styled.SearchWrapperStyled>
        <InputText
          type="text"
          faIcon={faMagnifyingGlass}
          placeholder={SEARCH_PROGRAMS.FILTERS.SEARCH}
          value={hook.hookSearch}
          onChange={hook.hookSetSearch}
        />
        <Styled.FlexStyled>
          <Select
            onChange={hook.hookSetProductCategory}
            placeholder={SEARCH_PROGRAMS.FILTERS.PRODUCT_CATEGORY}
            options={SEARCH_PROGRAMS.OPTIONS.PRODUCT_CATEGORY}
            selected={hook.hookProductCategory}
          />
          <Select
            onChange={hook.hookSetTargetMarket}
            placeholder={SEARCH_PROGRAMS.FILTERS.TARGET_MARKET}
            options={SEARCH_PROGRAMS.OPTIONS.TARGET_MARKET}
            selected={hook.hookTargetMarket}
          />
        </Styled.FlexStyled>
        <Styled.FlexStyled justifyContent="right">
          <Button
            theme="text-only"
            onClick={hook.hookClearForm}
          >
            {SEARCH_PROGRAMS.CLEAR_FORM}
          </Button>
        </Styled.FlexStyled>
      </Styled.SearchWrapperStyled>
      {hook.hookLoading ? (<LoadingDots />) : (
        <>
          <Table
            columns={columns}
            data={hook.hookData}
            onSort={hook.hookSortTable}
            sortColumn={hook.hookSortColumn}
            noHorizontalScroll
          />
          <Pagination
            total={hook.hookTotalPages}
            currentPage={hook.hookPage}
            onPageChange={hook.hookChangePage}
          />
        </>
      )}
    </AppWrapper>
  );
};

export default SearchPublisherPrograms;
