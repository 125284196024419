import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useToast, useUserInfo } from '../../../../../../../hooks';
import { URL_STATUSES } from '../../../../../../../utils';
import { useValidation } from '../../../../../../../utils/validation';
import { URL_UPLOAD } from '../enums';
import { UPDATE_FCHECK_SETTINGS } from '../graphql/mutations';
import { GET_FCHECK_SETTINGS, GetFCheckSettingsInput, GetFCheckSettingsOutput } from '../graphql/queries';

export const useUrlBulkUpload = () => {
  const validator = useValidation();
  const { hookWhoAmI } = useUserInfo();
  const { hookShowToast } = useToast();
  const [urlsList, setUrlsList] = useState<string[]>([]);

  const [getManualUrls, { loading: getManualUrlsLoading }] = useLazyQuery<GetFCheckSettingsOutput, GetFCheckSettingsInput>(GET_FCHECK_SETTINGS);
  const [updateManualUrls, { loading: updateManualUrlsLoading }] = useMutation(UPDATE_FCHECK_SETTINGS);

  const getUrlsListHandler = async () => {
    const { data } = await getManualUrls({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI?.companyId),
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getFintelCheckSettings?.settings?.manualUrls) setUrlsList(data.getFintelCheckSettings.settings.manualUrls);
  };

  const updateUrlsListHandler = async (newUrls: any[]) => {
    const filteredNewUrls = newUrls.filter((item) => item !== undefined && item !== '' && item !== null);
    if (filteredNewUrls.length > 0 && filteredNewUrls[0] !== undefined) {
      const { data, errors } = await updateManualUrls({
        variables: {
          input: {
            merchantId: Number(hookWhoAmI.companyId),
            manualUrls: [...urlsList, ...filteredNewUrls],
          },
        },
      });
      if (errors) {
        hookShowToast(URL_UPLOAD.TOAST.ERROR);
      } else if (data?.updateFintelSettingsFields?.settings?.manualUrls) {
        setUrlsList(data?.updateFintelSettingsFields?.settings?.manualUrls);
        hookShowToast(URL_UPLOAD.TOAST.SUCCESS);
      }
    } else {
      hookShowToast(URL_UPLOAD.TOAST.INVALID);
    }
  };

  const validateUrl = async (url: string) => {
    if (urlsList.includes(url) || url.includes(',')) return undefined;
    const urlStatus = await validator.validateUrlStatus(url);
    return urlStatus !== URL_STATUSES.INVALID_WEBSITE.STATUS ? url : undefined;
  };

  const fileUploadHandler = async (file: any) => {
    if (file && file instanceof File) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const text = event?.target?.result;
        const urlsToBeValidated = typeof text === 'string' ? text.replaceAll('"', '').split('\n') : [];
        const promiseArray: any[] = [];
        urlsToBeValidated.forEach((line: string) => promiseArray.push(validateUrl((line.endsWith('\r')) ? (line.slice(0, -1)) : line)));
        const promiseResults = await Promise.all(promiseArray);
        if (promiseResults.length > 0) updateUrlsListHandler((promiseResults.filter((e, i, a) => a.indexOf(e) === i)));
      };
      reader.readAsText(file);
    }
  };

  useEffect(() => {
    getUrlsListHandler();
  }, []);

  return {
    hookFileHandler: fileUploadHandler,
    hookLoading: getManualUrlsLoading || updateManualUrlsLoading,
  };
};
