import { fonts } from './fonts';
import { sizes } from './sizes';

export const Modules = [
  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
  [{ size: sizes }],
  [{ font: fonts }],
  ['bold'],
  ['italic'],
  ['underline'],
  ['strike'],
  ['blockquote'],
  ['code'],
  [{ script: 'sub' }],
  [{ script: 'super' }],
  [{ color: [] }],
  [{ background: [] }],
  [{ align: [] }],
  [{ list: 'ordered' }],
  [{ list: 'bullet' }],
  [{ indent: '+1' }],
  ['link'],
  ['image'],
  ['video'],
  ['document'],
  ['emoji'],
  ['clean'],
  ['undo'],
  ['redo'],
];
