import { useState } from 'react';

export const useTabs = (activeTab?: number, setActiveTabProp?: (index: number) => void, activeTabOveride?: number) => {
  const [activeTabState, setActiveTabState] = useState(activeTab || 0);

  const setActiveTab = (index: number) => {
    if (setActiveTabProp) {
      setActiveTabProp(index);
    }
    setActiveTabState(index);
  };

  return {
    hookActiveTab: activeTabOveride ?? activeTabState,
    hookSetActiveTab: setActiveTab,
  };
};
