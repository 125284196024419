import { gql } from '@apollo/client';

export const CREATE_COMMISSION = gql`
  mutation CreateCommission($input: CommissionInput) {
    createCommission(input: $input) {
      commission {
        id
      }
    }
  }
`;
