export const CREATE_PUBLISHER_GROUP = {
  BUTTON: {
    CANCEL: 'Cancel',
    ADD_GROUP: 'Add Group',
  },
  INPUT: {
    GROUP_NAME: 'Group Name',
    TEXT_AREA: {
      PLACEHOLDER: 'Maximum length of text: 500',
      LABEL: 'Description',
      MAX_LENGTH: 500,
    },
  },
  ERROR: {
    NAME: 'Please enter a valid group name.',
    DESC: 'Please enter a description.',
  },
};
