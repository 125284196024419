import { useNavigate } from 'react-router-dom';
import { useFintelCheckContext } from '../../hooks';
import { dateFormatter } from '../../../../../utils';

export const useHistoryReport = () => {
  const navigate = useNavigate();
  const { contextState } = useFintelCheckContext();

  return {
    hookNavigate: navigate,
    hookRunDate: dateFormatter(new Date(contextState.date), ','),
    hookContextState: contextState,
  };
};
