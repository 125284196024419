import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
query company($companyId: ID!) {
  company(id: $companyId) {
    id
    companyImgUrl
    companyName
    companyType
    address1
    address2
    city
    state
    country
    zip
    phone
    phone2
    companyEmail
    companyUrl
    apiAccessKey {
      status
      apiAccessKey
      apiCreatedAt
    }
    program {
      id
      transactionSettings {
        currency
      }
      campaigns {
        id
        name
      }
      products {
        id
        name
      }
      tags {
        id
        name
      }
    }
    paymentInfo {
      address1
      address2
      city
      state
      country
      zip
      email
      tax
      gst
      pst
      hst
      taxStatus
      taxVerifiedDate
      gstVerifiedDate
      pstVerifiedDate
      hstVerifiedDate
      gstStatus
      pstStatus
      hstStatus
      phone
      phone2
      currency
      payment
      payableTo
      accountHolder
      accountEmail
      accountPhoneNumber
      bankName
      bankAccountNumber
      bankCode
      transit
      wireTransferName
    }
    overview {
      businessDesc
      profilePhotoURL
      primaryPlatformLink
      hasWebsite
      audienceMarkets
      avgMonthlyAudience
      productCategories
      promotionMethods
      audienceAges
      gender
      creditScoreRating
      avgIncomes
    }
    accountStatus
    accountStatusHistory {
      status
      date
    }
    networkStatus
    productCategories
    createdAt
    activityStatus
    merchantType
  }
}
`;
