import { gql } from '@apollo/client';

export const GET_PUBLISHER = gql`
  query showInternalPublisher($input: ShowCompanyInput!) {
    showInternalPublisher(input: $input) {
      id
      companyName
      name
      registrationDate
      applicationDate
      accountStatus
    }
  }
`;
