import styled from 'styled-components';
import { colors, fonts } from '../../../../../styles/theme';

export const WrapperStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  border-left: 4px solid ${colors.color2};
  background: ${colors.color4};
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TitleStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.gotham.H20M}
  color: ${colors.color5};
`;

export const DescriptionStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H14}
  color: ${colors.color5};
  margin: 1rem 0;
`;
