import React from 'react';
import { faSearch, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useMembershipManagement } from './hooks';
import {
  AppWrapper, Button, PageTitle, Tabs, FixedFooter, Calendar, Modal, ErrorBanner,
} from '../../../../components';
import { path, RECORDS_PER_PAGE_OPTIONS } from '../../../../utils';
import {
  BUTTON_LABEL, ERROR_MESSAGE, LABEL, MARKETS, MODAL_TYPES, PAGE_DESCRIPTION, PLACEHOLDER, TABS,
} from './contracts';
import { MembershipTab } from './Tabs';
import { ApproveDelineMembershipModal } from './Modals';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type MembershipManagementProps = DefaultPropsType;

const MembershipManagement = ({ permissionsCodeList = [] }: MembershipManagementProps) => {
  const hook = useMembershipManagement(permissionsCodeList);
  const { hookIsReadOnlyList } = hook;
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      {hook.hookErrorMessage && (
        <ErrorBanner
          message={`${ERROR_MESSAGE}${hook.hookErrorMessage}`}
          isOpen={!!hook.hookErrorMessage}
        />
      )}

      <PageTitle>{path.membershipManagement.name}</PageTitle>

      <Styled.SubtitleStyled>
        {PAGE_DESCRIPTION}
      </Styled.SubtitleStyled>

      <Styled.FiltersStyled>
        <Styled.InputTextStyled
          name="search"
          value={hook.hookSearch}
          placeholder={PLACEHOLDER.SEARCH}
          faIcon={faSearch}
          type="text"
          onChange={hook.hookSetSearch}
          disabled={hook.hookIsLoading}
        />

        <Styled.ButtonStyled
          theme="text-only"
          onClick={hook.hookClearFilter}
        >
          {BUTTON_LABEL.CLEAR}
        </Styled.ButtonStyled>

        <Styled.InputCalendarStyled
          value={hook.hookInputFromCalendarDate}
          placeholder={PLACEHOLDER.FROM_DATE}
          onClick={hook.hookSetInputFromCalendar}
          isDisabled={hook.hookIsLoading}
        />
        <Styled.InputCalendarStyled
          value={hook.hookInputToCalendarDate}
          placeholder={PLACEHOLDER.TO_DATE}
          onClick={hook.hookSetInputToCalendar}
          isDisabled={hook.hookIsLoading}
        />

        <Styled.SelectStyled
          name="publisherGroup"
          options={hook.hookPublisherSelectOptions}
          selected={hook.hookSelectedPublisherGroup}
          onChange={hook.hookSetSelectedPublisherGroup}
          placeholder={PLACEHOLDER.GROUP}
          isDisabled={hook.hookIsLoading}
        />
        <Styled.SelectStyled
          name="location"
          options={MARKETS}
          selected={hook.hookSelectedLocation}
          onChange={hook.hookSetSelectedLocation}
          placeholder={PLACEHOLDER.LOCATION}
          isDisabled={hook.hookIsLoading}
        />
      </Styled.FiltersStyled>

      <Tabs
        tabNames={hook.hookTabCount}
        elements={[
          <MembershipTab
            statusType={TABS.PENDING_APPLICATIONS.value}
            hook={hook}
            isReadOnly={isReadOnly}
          />,
          <MembershipTab
            statusType={TABS.PENDING_INVITATIONS.value}
            hook={hook}
            isReadOnly={isReadOnly}
          />,
          <MembershipTab
            statusType={TABS.ACTIVE_MEMBERSHIPS.value}
            hook={hook}
            isReadOnly={isReadOnly}
          />,
          <MembershipTab
            statusType={TABS.INACTIVE_MEMBERSHIPS.value}
            hook={hook}
            isReadOnly={isReadOnly}
          />,
        ]}
      />

      <FixedFooter>
        <Styled.FooterWrapperStyled>
          <Styled.RecordsCountStyled>
            <p>{LABEL.PER_PAGE}</p>
            <Styled.CounterSelectStyled
              name="records"
              options={RECORDS_PER_PAGE_OPTIONS}
              selected={hook.hookPerPage}
              onChange={hook.hookSetPerPageHandler}
              placeholder="10"
              menuPlacement="top"
            />
          </Styled.RecordsCountStyled>

          <Styled.FooterButtonAreaStyled>
            <Styled.FooterButtonWrapperStyled>
              <Button
                theme="tertiary"
                onClick={hook.hookDownloadCSV}
                disabled={hook.hookIsLoading}
              >
                <Styled.IconWrapperStyled>
                  <FontAwesomeIcon icon={faFileDownload} />
                </Styled.IconWrapperStyled>
                {BUTTON_LABEL.DOWNLOAD}
              </Button>
            </Styled.FooterButtonWrapperStyled>

            <Styled.FooterButtonWrapperStyled>
              <Button
                theme="secondary"
                onClick={() => (hook.hookStatusType === TABS.ACTIVE_MEMBERSHIPS.value ? hook.hookSetApproveOpenModal(MODAL_TYPES.CLOSE) : hook.hookSetApproveOpenModal(MODAL_TYPES.DECLINE))}
                disabled={hook.hookDeclineButtonDisable || isReadOnly}
              >
                {BUTTON_LABEL.DECLINE}
              </Button>
            </Styled.FooterButtonWrapperStyled>

            <Button
              onClick={() => (hook.hookSetApproveOpenModal(MODAL_TYPES.APPROVE))}
              disabled={hook.hookApproveButtonDisable || isReadOnly}
            >
              {BUTTON_LABEL.APPROVE}
            </Button>
          </Styled.FooterButtonAreaStyled>
        </Styled.FooterWrapperStyled>
      </FixedFooter>

      <Calendar
        date={hook.hookFromCalendarDate}
        onApply={hook.hookSetFromCalendarDate}
        onCancel={hook.hookSetInputFromCalendar}
        isOpen={hook.hookOpenFromCalendar}
        hasRange={false}
        isSingle
        maxDate={hook.hookToCalendarDate}
      />

      <Calendar
        date={hook.hookToCalendarDate}
        onApply={hook.hookSetToCalendarDate}
        onCancel={hook.hookSetInputToCalendar}
        isOpen={hook.hookOpenToCalendar}
        hasRange={false}
        isSingle
        minDate={hook.hookFromCalendarDate}
      />

      <Modal isOpen={hook.hookApproveOpenModal}>
        <ApproveDelineMembershipModal
          modalType={hook.hookModalType}
          publishers={hook.hookSelectedPublishers}
          closeHandler={hook.hookSetApproveOpenModal}
          submitHandler={hook.hookSubmitHandler}
          loading={hook.hookUpdateMembershipLoading}
        />
      </Modal>
    </AppWrapper>
  );
};

export default MembershipManagement;
