import styled from 'styled-components';
import { colors, fonts } from '../../../../../../styles/theme';
import { toRem } from '../../../../../../utils';
import { Button, Checkbox } from '../../../../../../components';

export const ModalWrapperStyled = styled.div`
  color: ${colors.color1};
  display: flex;
  flex-direction: column;
  width: 632px;
  padding: 16px;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 24px;
  align-items: center;
`;

export const TitleText = styled.h2`
  ${fonts.gotham.H24M};
  color: ${colors.color1};
`;

export const SubTitleText = styled.h3`
  ${fonts.gotham.H20M};
  color: ${colors.color1};
  padding-bottom: 16px;
`;

export const SectionWrapper = styled.div`
  display: flex;
  padding-bottom: 32px;
`;

export const CategoryWrapper = styled.div`
  width: 50%;
  padding-bottom: 16px;
`;

export const CategoryHeader = styled.p`
  width: 100%;
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12M};
`;

export const CategoryValue = styled.p`
  width: 100%;
  color: ${colors.color1};
  ${fonts.lato.H16}
  padding-bottom: 16px;
`;

export const AndSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
`;

export const AndText = styled.span`
  color: ${colors.color23};
  ${fonts.lato.H16}
`;

export const AndLine = styled.span`
  width: 100%;
  border-bottom: 1px solid ${colors.color15};
  margin-bottom: 7px;
  margin-left: 8px;
`;

export const DetailsButton = styled(Button)`
  border: 1px solid ${colors.color2};
  height: 44px;
  width: 100%;
  :hover {
    border: 1px solid ${colors.color2};
  }
`;

export const ReviewTextStyled = styled.p`
  ${fonts.lato.H14};
  color: ${colors.color1};
  margin-bottom: 20px;
  line-height: 1.5;
`;

export const BackIconStyled = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 0 16px 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const NavButtonsWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
`;

export const CheckboxStyled = styled(Checkbox)`
  span {
    opacity: 1;
  }
`;
