import { gql } from '@apollo/client';

export const UPDATE_NEW_USER_STATE = gql`
  mutation UpdateUserState($input: UserSimplestInput) {
    updateUserState(input: $input) {
      user {
        newUser
        id
      }
    }
  }
`;
