import { gql } from '@apollo/client';

export const GET_CHECK_RULES = gql`
  query checkRules($input: CheckRuleInput!) {
    checkRules(input: $input) {
      count
      checkRules {
        id
        merchantId
        applicableProducts
        productCategory
        productId
        customizedProductId
        productName
        nominatedField
        ruleName
        productFeed {
          id
          productFeed
          productFeedData
          required
        }
        status
        startDate
        endDate
        lastRun
      }
    }
  }
`;
