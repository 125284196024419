/* eslint-disable object-shorthand */
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useMutation } from '@apollo/client';
import { useModal, useToast } from '../../../../../hooks';
import { UPDATE_TRACKING_PROFILE } from '../graphql/mutations';
import { CREATE_TRACKING_PROFILE } from '../graphql/mutations/createTrackingProfile';
import { useValidation } from '../../../../../utils/validation';
import { URL_STATUSES } from '../../../../../utils';
import { ADD_TRACKING_PROFILE } from '../Modal/enums';
import { useAccountInformation } from './useAccountInformation';

type ModalInfoProps = {
  profileId: any
  profileName: string
  profileType: SelectOption
  website: string
  profileDescription: string
}

export const useAddTrackingProfile = (setTableData: Dispatch<SetStateAction<any[]>>) => {
  const { hookUser } = useAccountInformation();
  const validator = useValidation();
  const { hookShowToast } = useToast();
  const [modal, setModal] = useModal(false);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [type, setType] = useState<SelectOption>({ label: '', value: '' });
  const [typeError, setTypeError] = useState(false);
  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState('');
  const [urlStatus, setUrlStatus] = useState('');
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState(ADD_TRACKING_PROFILE.ADD);

  const [updateTracking, { loading: loadingUpdate }] = useMutation(UPDATE_TRACKING_PROFILE);
  const [createTracking, { loading: loadingCreate }] = useMutation(CREATE_TRACKING_PROFILE);

  const updateTableList = (data: any) => {
    setTableData((oldState) => {
      const copyState = [...oldState];
      const index = copyState.findIndex((item) => item.id === data.id);

      copyState[index] = {
        ...copyState[index],
        trackingProfileType: type.value,
        name: name,
        primaryWebsite: url,
        websiteDescription: description,
        profileName: {
          name: name,
          website: url,
          description: description,
        },
        website: {
          name: name,
          website: url,
          description: description,
        },
      };

      copyState[index].name = name;
      copyState[index].primaryWebsite = url;
      copyState[index].websiteDescription = description;

      return copyState;
    });
  };

  const isNameValid = (value: string) => {
    if (value === '') {
      setNameError(true);
      return true;
    }
    setNameError(false);
    return false;
  };

  const isTypeValid = (value: SelectOption) => {
    if (value.value === '') {
      setTypeError(true);
      return true;
    }
    setTypeError(false);
    return false;
  };

  const setNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    isNameValid(e.target.value);
  };

  const setTypeHandler = (value: SelectOption) => {
    setType(value);
    isTypeValid(value);
    setUrl('');
    setDescription('');
    setUrlError('');
  };

  const setUrlHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const setDescriptionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const validateForm = async (optional?: boolean) => {
    const newStatus = await validator.validateUrlStatus(url, setUrlStatus);
    if (newStatus !== urlStatus) {
      validator.renderUrlCheck(newStatus, setUrlError);
    } else validator.renderUrlCheck(newStatus, setUrlError, !optional);
    if (urlStatus === undefined) setUrlError('');
  };

  const setModalInfo = ({
    profileName, profileType, website, profileDescription, profileId,
  }: ModalInfoProps) => {
    setTitle(ADD_TRACKING_PROFILE.EDIT);
    setId(profileId);
    setName(profileName);
    setTypeHandler(profileType);
    setUrlStatus('');
    setUrlError('');
    setUrl(website);
    setDescription(profileDescription);
  };

  const setAddModal = () => {
    setTitle(ADD_TRACKING_PROFILE.ADD);
    setId('');
    setName('');
    setTypeHandler({ label: '', value: '' });
    setUrl('');
    setDescription('');
    setNameError(false);
    setTypeError(false);
    setUrlStatus('');
    setUrlError('');
  };

  const resetModalInfo = () => {
    setNameError(false);
    setTypeError(false);
    setUrlStatus('');
    setUrlError('');
    setModal();
  };

  const addNewProfileToTable = (data: any) => {
    setTableData((oldState) => [
      {
        ...data,
        id: data.id,
        profileName: {
          name: data.profileName,
          website: data.primaryWebsite,
          description: data.websiteDescription,
        },
        createdAt: data.createdAt,
        updatedAt: data.lastActive,
      },
      ...oldState,
    ]);
  };

  const createOrUpdateTrackingHandler = async () => {
    validateForm(true);
    isNameValid(name);
    isTypeValid(type);
    if (!isNameValid(name) && !isTypeValid(type) && ((url !== '' && (urlStatus === URL_STATUSES.ACTIVE_WEBSITE.STATUS || urlStatus === URL_STATUSES.INACTIVE_WEBSITE.STATUS || urlStatus === URL_STATUSES.UNSAFE_WEBSITE.STATUS)) || url === '')) {
      if (id) {
        if ((url !== '' && url !== undefined) || type.value === 'other') {
          const { data, errors } = await updateTracking({
            variables: {
              input: {
                id: id,
                profileName: name,
                primaryWebsite: type?.value === 'website' ? url : '',
                trackingProfileType: type?.value,
                websiteDescription: type?.value === 'other' ? description : '',
              },
            },
          });

          updateTableList(data.updateTracking.tracking);
          resetModalInfo();

          if (errors) {
            hookShowToast(ADD_TRACKING_PROFILE.ERRORS.UPDATE);
          }
        }
      } else if ((url !== '' && url !== undefined) || type.value === 'other') {
        const { data, errors } = await createTracking({
          variables: {
            input: {
              companyId: hookUser?.id.toString(),
              profileName: name,
              primaryWebsite: type?.value === 'website' ? url : '',
              trackingProfileType: type?.value,
              websiteDescription: type?.value === 'other' ? description : '',
            },
          },
        });

        if (!errors) {
          hookShowToast(ADD_TRACKING_PROFILE.CREATE);
          addNewProfileToTable(data.createTracking.tracking);
          resetModalInfo();
        } else {
          hookShowToast(ADD_TRACKING_PROFILE.ERRORS.CREATE);
        }
      }
    }
  };

  useEffect(() => {
    validateForm();
  }, [url]);

  return {
    hookModalTitle: title,
    hookModal: modal,
    hookSetModal: setModal,
    hookProfileName: name,
    hookNameError: nameError,
    hookSetNameError: setNameError,
    hookSetProfileName: setNameHandler,
    hookProfileType: type,
    hookTypeError: typeError,
    hookSetTypeError: setTypeError,
    hookSetProfileType: setTypeHandler,
    hookProfileUrl: url,
    hookUrlError: urlError,
    hookSetProfileUrl: setUrlHandler,
    hookProfileDescription: description,
    hookSetProfileDescription: setDescriptionHandler,
    hookAddModal: setAddModal,
    hookResetModal: resetModalInfo,

    hookLoadingCreate: loadingCreate,
    hookLoadingUpdate: loadingUpdate,

    hookSetModalInfo: setModalInfo,
    hookCreateOrUpdateTrackingProfile: createOrUpdateTrackingHandler,

    hookValidateForm: validateForm,
  };
};
