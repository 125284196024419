import React from 'react';
import * as Styled from '../../styles';
import { CellProps } from '../../types';

export const ScheduleCell = ({ val }: CellProps) => (
  <Styled.ScheduleStyled>
    {val.frequency ? (
      <p>{val.frequency}</p>
    ) : (
      <p>Never</p>
    )}
    {val.every && (
      <p><pre>{` - ${val.every}`}</pre></p>
    )}
  </Styled.ScheduleStyled>
);
