import styled from 'styled-components';
import { AppWrapper } from '../../../components';
import { colors, fonts } from '../../../styles/theme';

type AccountStatusPRops = {
  status: string
}

export const AppWrapperStyled = styled(AppWrapper)`
  background-color: ${colors.color20};
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const MerchantWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const AccountStatusStyled = styled.div<AccountStatusPRops>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background-color: ${({ status }) => {
    switch (status) {
      case 'Paused':
        return colors.color14;
      case 'Pending':
        return colors.color19;
      case 'LivePending':
        return colors.color19;
      case 'Integrating':
        return colors.color2;
      case 'Closed':
        return colors.color19;
      default:
        return colors.color21;
    }
  }};
  color: ${colors.color4};
  height: 30px;
  border-radius: 5px;
  margin-left: 15px;
  ${fonts.gotham.H14}
`;

export const WrapperStyled = styled.div`
  width: 100%;
  background: ${colors.color4};
  padding: 2rem;
`;

export const BodyStyled = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: calc(67% - 3em) 33%;
  column-gap: 3rem;
  margin: 3rem 0;
  max-width: 100%;
`;

export const VerticalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 3rem;
`;

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
`;

export const StatisticWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
  background: ${colors.color4};
`;

export const SectionTitleStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.gotham.H20M}
  color: ${colors.color5};
  margin-bottom: 1.5rem;
`;

export const TopPublisherStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const TitleStyled = styled.span`
  ${fonts.gotham.H14M}
`;

export const DescriptionStyled = styled.span`
  ${fonts.lato.H14}
  margin: 1rem 0;
`;

export const TopProductStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const SpinnerWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
