import React from 'react';
import { faFile } from '@fortawesome/free-solid-svg-icons';

import {
  AppWrapper, PageTitle, InputText, Table, Pagination,
} from '../../../../../components';
import { path } from '../../../../../utils';
import { PRODUCT_FEED_DETAILS } from './enums';
import { useProductFeedDetails } from './hooks/useProductFeedDetails';
import { columns } from './contracts/column';
import PlaceHolderNoResults from '../../../../../components/NoResultsPlaceHolder';
import { DefaultPropsType } from '../../../../../types';

import * as Styled from './styles';

type ProductFeedDetailsProps = DefaultPropsType;

const ProductFeedDetails = ({ permissionsCodeList = [] }: ProductFeedDetailsProps) => {
  const { hookIsReadOnlyList, ...hook } = useProductFeedDetails(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle>{path.productFeedDetails.name}</PageTitle>
        <Styled.ButtonWrapperStyled>
          <Styled.ButtonStyled
            theme="tertiary"
            onClick={hook.hookHandleBack}
          >
            {PRODUCT_FEED_DETAILS.BACK}
          </Styled.ButtonStyled>
        </Styled.ButtonWrapperStyled>
      </Styled.HeaderStyled>
      <Styled.GridWrapper>
        <Styled.StyledInputArea
          label={PRODUCT_FEED_DETAILS.URL}
          disabled
          value={hook.hookProductFeed?.productFeedUrl}
        />
        <Styled.CopyWrapper>
          <Styled.ButtonStyled
            theme="tertiary"
            onClick={() => hook.hookCopyToClipboard()}
          >
            <Styled.StyledIcon icon={faFile} />
            {hook.hookUrlCopied ? PRODUCT_FEED_DETAILS.COPIED : PRODUCT_FEED_DETAILS.COPY}
          </Styled.ButtonStyled>
        </Styled.CopyWrapper>
        <InputText
          value={hook.hookProductFeed?.trackingProfileId}
          label={PRODUCT_FEED_DETAILS.TRACKING}
          type="text"
          disabled={isReadOnly}
        />
        <InputText
          value={hook.hookProductFeed?.regionCountry}
          label={PRODUCT_FEED_DETAILS.COUNTRY}
          type="text"
          disabled={isReadOnly}
        />
        <InputText
          value={hook.hookProductFeed?.regionStates}
          label={PRODUCT_FEED_DETAILS.STATES}
          type="text"
          disabled={isReadOnly}
        />
        <InputText
          value={hook.hookProductFeed?.merchants}
          label={PRODUCT_FEED_DETAILS.TRACKING}
          type="text"
          disabled={isReadOnly}
        />
        <InputText
          value={hook.hookProductFeed?.productCategories}
          label={PRODUCT_FEED_DETAILS.PRODUCT_CATEGORY}
          type="text"
          disabled={isReadOnly}
        />
        <InputText
          value={hook.hookProductFeed?.subCategories}
          label={PRODUCT_FEED_DETAILS.PRODUCT_SUBCATEGORY}
          type="text"
          disabled={isReadOnly}
        />
      </Styled.GridWrapper>
      <Styled.TabWrapperStyled>
        <Styled.TabTitleStyled>
          {PRODUCT_FEED_DETAILS.PRODUCTS}
        </Styled.TabTitleStyled>
        {(hook.hookLoading || hook.hookProducts?.length !== 0)
        && (
        <Table
          isLoading={hook.hookLoading}
          columns={columns}
          data={hook.hookProducts}
          onRowClick={hook.hookGoTo}
        />
        )}
        {(hook.hookLoading || hook.hookProducts?.length !== 0) && (
        <Pagination
          currentPage={hook.hookCurrentPage}
          total={hook.hookTotalPages}
          onPageChange={hook.hookChangePage}
        />
        )}
        {(!hook.hookLoading && hook.hookProducts?.length === 0) && (
        <PlaceHolderNoResults />
        )}
      </Styled.TabWrapperStyled>
    </AppWrapper>
  );
};

export default ProductFeedDetails;
