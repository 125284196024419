import { useEffect, useReducer, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_SAVED_REPORTS } from '../../../graphql';
import { savedReportsModals, useSavedReportsReducer } from '../../../reducers';
import { useUserInfo } from '../../../../../../hooks';
import { USER_TYPES_ID, USER_TYPES_NAMES } from '../../../../../../utils';

export const usePerformanceReport = () => {
  const { hookUserInfo, hookWhoAmI } = useUserInfo();
  const [modalsState, modalsDispatcher] = useReducer(useSavedReportsReducer, savedReportsModals);
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [tabLoaded, setTabLoaded] = useState<boolean>(false);
  const [refetchData, setRefetchData] = useState<boolean>(false);
  const [getSavedReports, { loading }] = useLazyQuery(GET_SAVED_REPORTS);

  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page);
  };

  const userTypeHandler = (id: number) => {
    switch (id) {
      case USER_TYPES_ID.MERCHANT:
        return USER_TYPES_NAMES.MERCHANT;
      case USER_TYPES_ID.PUBLISHER:
        return USER_TYPES_NAMES.PUBLISHER;
      default:
        return USER_TYPES_NAMES.ADMIN;
    }
  };

  const getReportsHandler = async () => {
    const { data } = await getSavedReports({
      variables: {
        user: {
          user: {
            id: hookUserInfo.id,
            userType: userTypeHandler(hookUserInfo.userTypesId),
          },
          report: 'Performance',
          company: Number(hookWhoAmI.companyId),
          options: {
            items: 10,
            page: currentPage,
          },
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.listReportProfiles.reports !== undefined) {
      const formatData = data?.listReportProfiles.reports.map((report: any) => ({
        ...report,
        nameDescription: {
          name: report.name,
          description: report.description,
        },
        settings: {
          filters: report.filters,
          columns: report.columns,
        },
        schedule: {
          frequency: report.frequency,
          every: report.every,
        },
      }));
      setTableData(formatData);
      setTotalPages(Math.ceil(data.listReportProfiles.count / 10));
    } else {
      setCurrentPage(1);
      setTotalPages(0);
    }
    setTabLoaded(true);
  };

  const modalsDispatcherHandler = (data: Record<string, any>) => {
    modalsDispatcher({ data });
  };

  const reportDeleted = () => {
    if (tableData.length < 2 && currentPage > 1) {
      setCurrentPage((current) => current - 1);
    }
    setRefetchData(true);
  };

  const reportCreated = () => {
    setRefetchData(true);
  };

  if (refetchData) {
    setRefetchData(false);
    getReportsHandler();
  }

  useEffect(() => {
    getReportsHandler();
  }, [hookWhoAmI.companyId, currentPage]);

  return {
    hookLoading: loading || !tabLoaded,
    hookData: tableData,
    hookSetData: setTableData,
    hookReportDeleted: reportDeleted,
    hookReportCreated: reportCreated,
    hookModals: modalsState,
    hookSetModals: modalsDispatcherHandler,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,
    hookTotalPages: totalPages,
  };
};
