import { gql } from '@apollo/client';

export const LIST_PUBLISHER_GROUP = gql`
  query List($input: ListSearchPublisherGroupInput!) {
    listPublisherGroups(input: $input) {
      count
      groups {
        id
        name
        description
        createdAt
        memberships {
          id
        }
      }
    }
  }
`;
