export const getCookie = (name: string) => {
  const cookies = document.cookie.split('; ');
  const cookie = cookies.find((item) => item.split('=')[0] === name);

  if (!cookie) {
    return undefined;
  }

  return cookie?.split('=')[1];
};
