export const ADD_TRACKING_PROFILE = {
  ADD: 'Add Tracking Profile',
  EDIT: 'Edit Tracking Profile',
  PROFILE_NAME: {
    LABEL: 'profile name',
    PLACEHOLDER: 'Tracking profile name',
  },
  PROFILE_TYPE: {
    LABEL: 'tracking profile type',
    PLACEHOLDER: 'Website',
  },
  PROFILE_URL: {
    LABEL: 'website url',
    PLACEHOLDER: 'http://www.your.site',
  },
  PROFILE_DESCRIPTION: {
    LABEL: 'description',
    PLACEHOLDER: 'A short introduction to your tracking profile',
  },
  BUTTONS: {
    CANCEL: 'Cancel',
    SAVE: 'Save',
  },
  ERRORS: {
    NAME: 'Please enter a valid profile name',
    TYPE: 'Please choose a tracking type',
    CREATE: 'Could not create tracking profile',
    UPDATE: 'Could not update tracking profile',
  },
  CREATE: 'New Tracking Profile was added successfully',
};
