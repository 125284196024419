import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { LIST_CURRENCY_RATE } from '../graphql/queries';
import { CURRENCY_TABS } from '../enums';

type Item = {
  id: string;
  createdAt: Date;
  currencyFrom: string;
  currencyTo: string;
  conversionRate: string;
};

export const useCurrencyRates = () => {
  const [currencyRateList, setCurrencyRateList] = useState<Item[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageSelected, setCurrentPage] = useState(1);
  const [records, setRecords] = useState({ label: '10', value: '10' });
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDateCalendar, setStartDate] = useState<Date>();
  const [endDateCalendar, setEndDate] = useState<Date>();
  const [dateRange, setDateRange] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState<SelectOption>(CURRENCY_TABS.CURRENCY_OPTIONS[0]);
  const [getCurrencyRate, { loading: currencyLoading }] = useLazyQuery(LIST_CURRENCY_RATE);
  const [sortTableColumn, setSort] = useState<TableSortColumn>({ column: 'createdAt', direction: 'desc' });

  const handleSort = (dataField: string, direction: any) => {
    if (sortTableColumn.direction === null) {
      setSort({ column: dataField, direction });
    } else {
      setSort({ column: dataField, direction: sortTableColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const setCurrencyRateListHandler = async () => {
    const dayFormat = (1000 * 60 * 60 * 24);
    let startDateUTC;
    let endDateUTC;
    if (startDateCalendar) {
      startDateUTC = new Date(Date.UTC(startDateCalendar.getFullYear(), startDateCalendar.getMonth(), startDateCalendar.getDate()));
    }
    if (endDateCalendar) {
      endDateUTC = new Date(Date.UTC(endDateCalendar.getFullYear(), endDateCalendar.getMonth(), endDateCalendar.getDate()) + dayFormat);
    }

    const { data } = await getCurrencyRate(
      {
        variables: {
          input: {
            limit: Number.parseInt(records.value, 10),
            currentPage: currentPageSelected,
            currencyFrom: selectedCurrency.value.slice(0, 3),
            currencyTo: selectedCurrency.value.slice(3),
            startDate: startDateUTC || startDateCalendar,
            sortOrder: sortTableColumn?.direction === 'asc' ? 1 : -1,
            sortBy: sortTableColumn.column || 'createdAt',
            endDate: endDateUTC || endDateCalendar,
          },
        },
      },
    );
    if (data?.currenciesConversionV2.currenciesConversion) {
      setCurrencyRateList(data.currenciesConversionV2.currenciesConversion);
    }
    if (data?.currenciesConversionV2.count) {
      setTotalPages(Math.ceil(data.currenciesConversionV2.count / Number.parseInt(records.value, 10)));
    }
    setTotalRecords(data.currenciesConversionV2.count);
  };

  useEffect(() => {
    const isPageValid = (((currentPageSelected - 1) * Number(records.value) + 1) <= totalRecords);

    if (totalRecords === 0) {
      setCurrentPage(1);
      setCurrencyRateListHandler();
    } else if (isPageValid) {
      setCurrencyRateListHandler();
    } else {
      setCurrentPage(1);
    }
  }, [currentPageSelected, records, selectedCurrency, startDateCalendar, endDateCalendar, sortTableColumn, totalRecords]);

  useEffect(() => {
    setCurrencyRateListHandler();
  }, []);

  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page);
  };

  const setRecordsHandler = (value: any) => {
    setRecords({
      label: value.value,
      value: value.value,
    });
  };

  const setOpenCalendarHandler = () => {
    setOpenCalendar(!openCalendar);
  };

  const setOnCancelCalendarHandler = () => {
    setOpenCalendar(false);
  };

  const setSelectedPublisherHandler = (value: SelectOption) => {
    setSelectedCurrency(value);
  };

  const setOnApplyCalendarHandler = (startDateValue: Date, endDateValue?: Date, range?: string) => {
    setCurrencyRateList([]);
    setOpenCalendar(false);
    setStartDate(startDateValue);

    if (endDateValue) {
      setEndDate(endDateValue);
    }

    if (range) {
      setDateRange(range);
    }

    setSelectedDateRange(`${startDateValue.toDateString()} / ${endDateValue?.toDateString()}`);
    setCurrencyRateListHandler();
  };

  return {
    hookCurrencyRateList: currencyRateList,
    hookSetCurrencyRateList: setCurrencyRateListHandler,

    hookRecords: records,
    hookSetRecords: setRecordsHandler,
    hookTotalRecords: totalRecords,
    hookSetTotalRecords: setTotalRecords,

    hookSetPage: setCurrentPageHandler,
    hookCurrentPage: currentPageSelected,

    hookTotalPages: totalPages,

    hookOpenCalendar: openCalendar,
    hookSetOpenCalendar: setOpenCalendarHandler,

    hookOnCancelCalendar: setOnCancelCalendarHandler,

    hookOnApplyCalendar: setOnApplyCalendarHandler,
    hookSelectDate: selectedDateRange,
    hookStartDate: startDateCalendar,
    hookEndDate: endDateCalendar,
    hookRange: dateRange,

    hookSelectedCurrency: selectedCurrency,
    hookSetSelectedCurrency: setSelectedPublisherHandler,

    hookCurrencyLoading: currencyLoading,

    hookSort: sortTableColumn,
    hookHandleSort: handleSort,
  };
};
