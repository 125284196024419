import { gql } from '@apollo/client';

export const GET_USER_BY_EMAIL = gql`
  query userByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      auth0Id
      auth0Ids
      email
    }
  }
`;
