import React from 'react';
import { USER_TYPES_ID } from '../../utils';
import { Modal } from '../Modal';
import { PageTitle } from '../PageTitle';
import { useAddAdModal } from './hooks';
import * as Styled from './styles';

type AddAdModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddAdModal = ({ onClose, isOpen }: AddAdModalProps) => {
  const hook = useAddAdModal(isOpen);

  return (
    <Modal isOpen={isOpen}>
      <Styled.WrapperStyled>
        <PageTitle isLoading={hook.hookLoading}>Add Ad</PageTitle>
        <Styled.SelectStyled
          label="Ad Type"
          onChange={hook.hookSetSelectedAdType}
          selected={hook.hookSelectedAdType}
          options={hook.hookAdTypeList}
          isDisabled={hook.hookLoading}
          isLoading={hook.hookLoading}
          required
        />
        {hook.hookUserType === USER_TYPES_ID.ADMIN && hook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT && (
        <Styled.SelectStyled
          label="Merchant"
          onChange={hook.hookSetSelectedMerchant}
          selected={hook.hookSelectedMerchant}
          options={hook.hookMerchantList}
          isDisabled={hook.hookLoading}
          isLoading={hook.hookLoading}
          error={hook.hookSelectedMerchantError}
          required
        />
        )}

        <Styled.ButtonAreaStyled>
          <Styled.ButtonStyled
            theme="secondary"
            onClick={() => hook.hookOnClose(onClose)}
          >
            Cancel
          </Styled.ButtonStyled>
          <Styled.ButtonStyled onClick={hook.hookOnConfirm}>Confirm</Styled.ButtonStyled>
        </Styled.ButtonAreaStyled>
      </Styled.WrapperStyled>
    </Modal>
  );
};
