import { gql } from '@apollo/client';

export const UPDATE_TRANSACTIONS = gql`
  mutation updateTransactions($input: TransactionsStatusInput) {
    updateTransactions(input: $input) {
      count
      transactions {
        id
        transactionStatus
      }
    }
  }
`;
