import React from 'react';
import {
  FORM_SECTION_TITLE, INPUT_LABEL, disableTaxField, getTaxPlaceholder, maskValue,
} from '../../contracts';
import { TaxDropdown } from '../Dropdown';
import * as Styled from '../../styles';
import { useAccountDetails } from '../../hooks';

type PublisherPaymentContactProps = {
  hook: ReturnType<typeof useAccountDetails>
  isAdmin: boolean
  isReadOnly: boolean
}

export const PublisherPaymentContact = ({
  hook, isAdmin, isReadOnly,
}: PublisherPaymentContactProps) => {
  const address = (isReadOnly || isAdmin) ? `${hook?.hookPaymentAddress}, ${hook?.hookPaymentCity}, ${hook.hookPaymentRegion?.value}, ${hook.hookPaymentCountry?.value}, ${hook?.hookPaymentPostal}` : hook?.hookPaymentAddress;
  return (
    <Styled.ContentStyled>
      <Styled.SectionTitleStyled>
        {FORM_SECTION_TITLE.PAYMENT_CONTACT}
      </Styled.SectionTitleStyled>

      {(isReadOnly || isAdmin) ? (
        <>
          <Styled.InputTextStyled
            grids={4}
            theme="readonlyTernary"
            type="text"
            label={INPUT_LABEL.PAYMENT_CONTACT_ADDRESS}
            value={maskValue(address)}
            readonly
          />
          <Styled.InputTextStyled
            grids={2}
            theme="readonlyTernary"
            type="text"
            value={maskValue(hook.hookPaymentAddress2)}
            label={INPUT_LABEL.ADDRESS_TWO}
            readonly
          />
          <Styled.InputTextStyled
            grids={2}
            theme="readonlyTernary"
            type="text"
            label={INPUT_LABEL.PHONE_NUMBER}
            value={maskValue(hook?.hookPaymentPrimaryPhone)}
            readonly
          />
          <Styled.InputTextStyled
            grids={2}
            theme="readonlyTernary"
            type="text"
            value={maskValue(hook?.hookPaymentSecondaryPhone)}
            label={INPUT_LABEL.PHONE_NUMBER_SECONDARY}
            readonly
          />
          <Styled.InputTextStyled
            grids={2}
            theme="readonlyTernary"
            type="text"
            value={maskValue(hook.hookPaymentEmailL)}
            label={INPUT_LABEL.PAYMENT_CONTACT_EMAIL}
            readonly
          />

          <Styled.InputWrapperStyled grids={2}>
            <Styled.InputTextStyled
              grids={2}
              theme="readonlyTernary"
              type="text"
              label={INPUT_LABEL.GST}
              readonly
              value={hook?.hookGst}
              labelStatus={hook?.hookGst !== '' ? hook.hookGstStatus : undefined}
              labelStatusDate={hook?.hookGst !== '' ? hook.hookGstVeriedDate || undefined : undefined}
            />
            {isAdmin && <TaxDropdown handleChange={hook?.hookSetGstStatus} />}
          </Styled.InputWrapperStyled>

          <Styled.InputWrapperStyled grids={2}>
            <Styled.InputTextStyled
              grids={2}
              theme="readonlyTernary"
              type="text"
              label={INPUT_LABEL.PST}
              readonly
              value={hook?.hookPst}
              labelStatus={hook?.hookPst !== '' ? hook.hookPstStatus : undefined}
              labelStatusDate={hook?.hookPst !== '' ? hook.hookPstVeriedDate || undefined : undefined}
            />
            {isAdmin && <TaxDropdown handleChange={hook?.hookSetPstStatus} />}
          </Styled.InputWrapperStyled>

          <Styled.InputWrapperStyled grids={2}>
            <Styled.InputTextStyled
              grids={2}
              theme="readonlyTernary"
              type="text"
              label={INPUT_LABEL.HST}
              readonly
              value={hook?.hookHst}
              labelStatus={hook?.hookHst !== '' ? hook.hookHstStatus : undefined}
              labelStatusDate={hook?.hookHst !== '' ? hook.hookHstVeriedDate || undefined : undefined}
            />
            {isAdmin && <TaxDropdown handleChange={hook?.hookSetHstStatus} />}
          </Styled.InputWrapperStyled>

          <Styled.InputWrapperStyled grids={2}>
            <Styled.InputTextStyled
              grids={6}
              theme="readonlyTernary"
              type="text"
              label={INPUT_LABEL.TAXATION}
              readonly
              value={hook?.hookTaxation}
              labelStatus={hook?.hookTaxation !== '' ? hook.hookTaxationStatus : undefined}
              labelStatusDate={hook?.hookTaxation !== '' ? hook.hookTaxVeriedDate || undefined : undefined}
            />
            {isAdmin && <TaxDropdown handleChange={hook?.hookSetTaxationStatus} />}
          </Styled.InputWrapperStyled>

        </>
      ) : (
        <>
          <Styled.StyledCheckBox
            label="Payment contact information same as company contact information"
            checked={hook.hookSameChecked}
            onChange={hook.hookChangeSameChecked}
            disabled={isReadOnly || hook?.hookLoading}
          />

          <Styled.InputTextStyled
            grids={4}
            type="text"
            label={INPUT_LABEL.PAYMENT_CONTACT_ADDRESS}
            value={hook?.hookPaymentAddress}
            error={hook.hookErrors.paymentAddress}
            disabled={hook.hookSameChecked || isReadOnly || hook?.hookLoading}
            onChange={hook.hookHandlePaymentAddress}
            required
          />
          <Styled.InputTextStyled
            grids={2}
            type="text"
            label={INPUT_LABEL.CITY}
            required
            error={hook.hookErrors.paymentCity}
            disabled={hook.hookSameChecked || isReadOnly || hook?.hookLoading}
            onChange={hook.hookHandlePaymentCity}
            value={hook?.hookPaymentCity}
          />
          <Styled.InputTextStyled
            grids={4}
            type="text"
            label={INPUT_LABEL.ADDRESS_TWO}
            disabled={hook.hookSameChecked || isReadOnly || hook?.hookLoading}
            value={hook.hookPaymentAddress2}
            onChange={hook?.hookHandlePaymentAddress2}
          />
          <Styled.SelectStyled
            grids={2}
            label={INPUT_LABEL.REGION}
            required
            error={hook.hookErrors.paymentRegion}
            selected={hook.hookPaymentRegion}
            isDisabled={hook.hookSameChecked || isReadOnly || hook?.hookLoading}
            options={hook.hookPaymentRegionOptions}
            onChange={hook.hookHandlePaymentRegion}
          />
          <Styled.SelectStyled
            grids={2}
            label={INPUT_LABEL.COUNTRY}
            required
            selected={hook.hookPaymentCountry}
            error={hook.hookErrors.paymentCountry}
            isDisabled={hook.hookSameChecked || isReadOnly || hook?.hookLoading}
            options={hook.hookCountryOptions}
            onChange={hook.hookHandlePaymentCountry}
          />
          <Styled.InputTextStyled
            grids={2}
            type="text"
            disabled
            label={INPUT_LABEL.COUNTRY}
          />
          <Styled.InputTextStyled
            grids={2}
            type="text"
            required
            label={INPUT_LABEL.POST_CODE}
            error={hook.hookErrors.paymentPostal}
            disabled={hook.hookSameChecked || isReadOnly || hook?.hookLoading}
            value={hook?.hookPaymentPostal}
            onChange={hook.hookHandlePaymentPostal}
          />
          <Styled.InputPhoneStyled
            grids={2}
            type="text"
            required
            label={INPUT_LABEL.PHONE_NUMBER}
            error={hook.hookErrors.paymentPrimaryPhone}
            disabled={hook.hookSameChecked || isReadOnly || hook?.hookLoading}
            value={hook?.hookPaymentPrimaryPhone}
            onChange={hook.hookHandlePaymentPrimaryPhone}
          />
          <Styled.InputPhoneStyled
            grids={2}
            type="text"
            error={hook.hookErrors.paymentSecondaryPhone}
            label={INPUT_LABEL.PHONE_NUMBER_SECONDARY}
            value={hook?.hookPaymentSecondaryPhone}
            disabled={hook.hookSameChecked || isReadOnly || hook?.hookLoading}
            onChange={hook?.hookHandleSecondaryPhone}
          />
          <Styled.InputTextStyled
            grids={2}
            type="text"
            label={INPUT_LABEL.PAYMENT_CONTACT_EMAIL}
            disabled={hook.hookSameChecked || isReadOnly || hook?.hookLoading}
            value={hook?.hookPaymentEmailL}
            onChange={hook.hookHandlePaymentEmail}
          />
          <Styled.InputWrapperStyled grids={2}>
            <Styled.InputTextStyled
              grids={2}
              type="text"
              label={INPUT_LABEL.GST}
              onChange={hook?.hookChangeGst}
              value={hook?.hookGst}
              disabled={disableTaxField(hook.hookPaymentCountry?.label, hook.hookPaymentRegion?.label, 'gst') || isReadOnly || hook?.hookLoading}
              placeholder={getTaxPlaceholder('gst')}
              labelStatus={hook?.hookGst !== '' ? hook?.hookGstStatus : undefined}
              error={hook?.hookTaxErrors.paymentGST}
            />
          </Styled.InputWrapperStyled>

          <Styled.InputWrapperStyled grids={2}>
            <Styled.InputTextStyled
              grids={2}
              type="text"
              label={INPUT_LABEL.PST}
              onChange={hook?.hookChangePst}
              value={hook?.hookPst}
              disabled={disableTaxField(hook.hookPaymentCountry?.label, hook.hookPaymentRegion?.label, 'pst') || isReadOnly || hook?.hookLoading}
              placeholder={getTaxPlaceholder('pst')}
              labelStatus={hook?.hookPst !== '' ? hook.hookPstStatus : undefined}
              error={hook?.hookTaxErrors.paymentPST}
            />
          </Styled.InputWrapperStyled>

          <Styled.InputWrapperStyled grids={2}>
            <Styled.InputTextStyled
              grids={2}
              type="text"
              label={INPUT_LABEL.HST}
              onChange={hook?.hookChangeHst}
              value={hook?.hookHst}
              disabled={disableTaxField(hook.hookPaymentCountry?.label, hook.hookPaymentRegion?.label, 'hst') || isReadOnly || hook?.hookLoading}
              placeholder={getTaxPlaceholder('hst')}
              labelStatus={hook?.hookHst !== '' ? hook.hookHstStatus : undefined}
              error={hook?.hookTaxErrors.paymentHST}
            />
          </Styled.InputWrapperStyled>

          <Styled.InputWrapperStyled grids={2}>
            <Styled.InputTextStyled
              grids={2}
              type="text"
              label={INPUT_LABEL.TAXATION}
              value={hook?.hookTaxation}
              onChange={hook.hookHandleTaxationString}
              labelStatus={hook?.hookTaxation !== '' ? hook.hookTaxationStatus : undefined}
              placeholder="123456567"
              disabled={disableTaxField(hook.hookPaymentCountry?.label, hook.hookPaymentRegion?.label, 'tax') || isReadOnly || hook?.hookLoading}
              error={hook?.hookTaxErrors.paymentTAX}
            />
          </Styled.InputWrapperStyled>
        </>
      )}

    </Styled.ContentStyled>
  );
};
