import React from 'react';
import { Button } from '../../../../../../../components';
import { MODAL } from './enums';
import * as Styled from './styles';
import type { useTransactionSettings } from '../../../hooks/useTransactionSettings';

type DeleteModalProps = {
  hook: ReturnType<typeof useTransactionSettings>
}

export const DeleteTrackingSettingsModal = ({ hook }: DeleteModalProps) => (
  <Styled.WrapperStyled>
    <Styled.TitleStyled>{MODAL.TITLE}</Styled.TitleStyled>
    <Styled.DescriptionStyled>{MODAL.DESCRIPTION}</Styled.DescriptionStyled>
    <Styled.WarningStyled>{MODAL.WARNING}</Styled.WarningStyled>
    <Styled.ButtonWrapper>
      <Button
        theme="secondary"
        onClick={hook.hookSetDeleteModalOpen}
      >
        {MODAL.BUTTONS.CANCEL}
      </Button>
      <Button
        onClick={() => {
          if (hook.hookModalInfo !== undefined) {
            const tableDataCopy = hook.hookTransactionTypeSettingsData.filter((row) => !(row.type === hook.hookModalInfo?.type && row.url === hook.hookModalInfo?.url));
            hook.hookSetTransactionsTypeSettingsData(tableDataCopy);
            hook.hookSetDeleteModalOpen();
            hook.hookSetDeleteSetting(!hook.hookDeleteSetting);
          }
        }}
      >
        {MODAL.BUTTONS.DELETE}
      </Button>
    </Styled.ButtonWrapper>
  </Styled.WrapperStyled>
);
