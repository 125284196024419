import React from 'react';
import { faCircleXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper, Calendar, ErrorBanner, FileUpload, InputCalendar, InputText, InputTextarea, PageTitle, Select,
} from '../../../../components';
import { CreateCampaignModal } from '../../../../components/CreateCampaignModal';
import { FILE_EXTENSIONS, path, TOAST_ERR_MESSAGES_NO_PAGE } from '../../../../utils';
import {
  BUTTON_LABELS, INFO, LABELS, PLACEHOLDERS, SECTION_HEADERS,
} from '../../utils';
import { useNewAd } from '../hooks';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';
import * as RootStyled from '../../Css/styles';

type AdBannerProps = DefaultPropsType;

const AdBanner = ({ permissionsCodeList = [] }: AdBannerProps) => {
  const { hookIsReadOnlyList, ...hook } = useNewAd(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        isOpen={hook.hookErrorBanner}
        message={TOAST_ERR_MESSAGES_NO_PAGE(hook.hookAdErrorMessage)}
      />
      <Styled.HeaderStyled>
        <PageTitle>{path.addNewAdBanner.name}</PageTitle>
      </Styled.HeaderStyled>

      <InputText
        required
        type="text"
        label={LABELS.AD_NAME}
        value={hook.hookName}
        onChange={hook.hookSetName}
        error={hook.hookAdErrors.adName}
        onBlur={hook.hookHandleValidation}
        disabled={isReadOnly}
      />
      <Styled.FieldsStyled>
        <Select
          width="42%"
          required
          label={LABELS.STATUS}
          options={hook.hookAdStatusTypeList}
          onChange={hook.hookSetSelectedStatus}
          selected={hook.hookSelectedStatus}
          error={hook.hookAdErrors.status}
          isDisabled={isReadOnly}
        />
        <Select
          width="42%"
          label={LABELS.CAMPAIGN}
          options={hook.hookCampaignList}
          onChange={hook.hookSetSelectedCampaign}
          selected={hook.hookSelectedCampaign}
          placeholder={PLACEHOLDERS.CAMPAIGN}
          onBlur={hook.hookHandleValidation}
          isDisabled={isReadOnly}
        />
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={() => hook.hookSetCampaignModal(true)}
          disabled={isReadOnly}
        >
          <FontAwesomeIcon icon={faPlus} />
          {BUTTON_LABELS.CREATE_CAMPAIGN}
        </Styled.ButtonStyled>
      </Styled.FieldsStyled>
      <InputTextarea
        required
        height="134px"
        label={LABELS.DESCRIPTION}
        value={hook.hookDescription}
        onChange={hook.hookSetDescription}
        error={hook.hookAdErrors.description}
        onBlur={hook.hookHandleValidation}
        disabled={isReadOnly}
      />
      <Styled.TitleStyled>{SECTION_HEADERS.BANNER}</Styled.TitleStyled>
      {hook.hookImagesList.map((item: any, index: number) => (
        <Styled.FileFieldStyled key={index}>
          <FileUpload
            required
            label={LABELS.IMAGE}
            name={`imageUpload-${index}`}
            info={INFO.DROP_ZONE}
            error={hook.hookFileErrors[index]}
            maxFileSize={200000}
            accept={`${FILE_EXTENSIONS.PNG}, ${FILE_EXTENSIONS.JPG}, ${FILE_EXTENSIONS.JPEG}, ${FILE_EXTENSIONS.GIF}`}
            type="image"
            onChange={(e) => hook.hookOnUpload(e, index)}
            disabled={isReadOnly}
          />
          <InputText
            required
            type="text"
            label={LABELS.IMAGE_ALT}
            value={item.alt}
            error={hook.hookFileAltErrors[index]}
            onChange={(e) => hook.hookSetImageAlt(e, index)}
            disabled={isReadOnly}
          />

          {index !== 0 && (
            <Styled.DeleteButtonStyled
              theme="tertiary"
              onClick={() => hook.hookRemoveImageList(index)}
              disabled={hook.hookImagesList.length === 1}
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                size="sm"
              />
            </Styled.DeleteButtonStyled>
          )}
        </Styled.FileFieldStyled>
      ))}

      <Styled.ButtonFieldStyled>
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={hook.hookNewImageList}
          disabled={isReadOnly}
        >
          <FontAwesomeIcon icon={faPlus} />
          {BUTTON_LABELS.ADD_FIELD}
        </Styled.ButtonStyled>
      </Styled.ButtonFieldStyled>
      <>
        <Styled.TitleStyled>{SECTION_HEADERS.ADVANCED}</Styled.TitleStyled>
        {(hook.hookSelectedCampaign) && (
          <RootStyled.CPStyled>
            <RootStyled.CPHeader> Campaign Product: </RootStyled.CPHeader>
            <RootStyled.CPValue>
              { (hook.hookCampaignProduct.productId === 'null' || !hook.hookCampaignProduct.productId)
                ? (<> None </>) : hook.hookCampaignProduct.productName }
            </RootStyled.CPValue>

          </RootStyled.CPStyled>
        )}

        <Styled.AdvancedStyled>
          <Select
            label={LABELS.PRODUCT}
            options={hook.hookProductList}
            onChange={hook.hookSetSelectedProduct}
            selected={hook.hookSelectedProduct}
            placeholder={PLACEHOLDERS.PRODUCT}
            isDisabled={isReadOnly}
            onBlur={hook.hookHandleValidation}
            error={hook.hookAdErrors.productId}
          />
          <InputCalendar
            label={LABELS.START_DATE}
            value={hook?.hookStartDate?.toDateString() || ''}
            placeholder={PLACEHOLDERS.DATE}
            onClick={hook.hookOnOpenStartCalendar}
            isDisabled={isReadOnly}
          />
          <InputCalendar
            label={LABELS.END_DATE}
            value={hook?.hookEndDate?.toDateString() || ''}
            placeholder={PLACEHOLDERS.DATE}
            onClick={hook.hookOnOpenEndCalendar}
            isDisabled={isReadOnly}
          />
          <Styled.InputStyled
            name="landingUrl"
            type="url"
            label={LABELS.LANDING_PAGE}
            placeholder={hook.hookUrlPlaceholder}
            value={hook.hookLandingPageForBanner}
            onChange={hook.hookSetBannerLandingPage}
            error={hook.hookSingleUrlError}
            onBlur={hook.hookValidateWebsite}
            disabled={isReadOnly}
          />
          <Select
            required
            label={LABELS.LANGUAGE}
            onChange={hook.hookSetLanguage}
            selected={hook.hookLanguage}
            isDisabled={hook.hookLoading || isReadOnly}
            isLoading={hook.hookLoading}
            options={hook.hookLanguageList}
            error={hook.hookAdErrors.language}
          />
          <Styled.SelectStyled
            label={LABELS.TAGS}
            onChange={hook.hookSetTags}
            selectedMulti={hook.hookTags}
            isDisabled={hook.hookLoading || isReadOnly}
            isLoading={hook.hookLoading}
            options={hook.hookTagList}
            isMulti
            creatable
            placeholder={PLACEHOLDERS.TAGS}
          />
        </Styled.AdvancedStyled>
      </>

      <Styled.FooterStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={hook.hookOnBack}
        >
          {BUTTON_LABELS.CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          onClick={hook.hookCreateBannerAd}
          disabled={isReadOnly}
        >
          {BUTTON_LABELS.SAVE}
        </Styled.ButtonStyled>
      </Styled.FooterStyled>

      <Calendar
        date={hook.hookStartDate}
        onApply={hook.hookOnApplyStartCalendar}
        onCancel={hook.hookOnCancelStartCalendar}
        isOpen={hook.hookStartCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
      />

      <Calendar
        date={hook.hookEndDate}
        onApply={hook.hookOnApplyEndCalendar}
        onCancel={hook.hookOnCancelEndCalendar}
        isOpen={hook.hookEndCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
      />
      <CreateCampaignModal
        isOpen={hook.hookCampaignModal}
        onClose={() => hook.hookSetCampaignModal(!hook.hookCampaignModal)}
        setCampaign={hook.hookSetCampaignAfterCreation}
      />
    </AppWrapper>
  );
};

export default AdBanner;
