import { ERROR_TYPES } from '../../../../utils';

export const validationfields = {
  feeAmount: ERROR_TYPES.IS_DOLLAR_AMOUNT,
  feeDate: ERROR_TYPES.NOT_EMPTY,
  feeType: ERROR_TYPES.NOT_EMPTY,
  productCategory: ERROR_TYPES.NOT_EMPTY,
  referenceNumber: ERROR_TYPES.NUMBERS_ONLY,
  feeTypeOther: ERROR_TYPES.NOT_EMPTY,
  realNumberFee: ERROR_TYPES.REAL_NUMBER_FEE,
};
