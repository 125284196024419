import styled from 'styled-components';
import { colors, fonts } from '../../../../../styles/theme';
import { Button } from '../../../../../components';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 2.5rem;
  width: 100%;
  position: relative;
`;

export const RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RunDateStyled = styled.div`
  ${fonts.lato.H14};
  color: ${colors.color1};
  background-color: ${colors.color3};
  padding: 8px 12px;
  margin-left: 2rem;
  border-radius: 0.25rem;
`;

export const UrlLinkStyled = styled.a`
  text-decoration: none;
  width: fit-content;
`;

export const UrlStyled = styled.p`
  margin-top: 1.5rem;
  ${fonts.lato.H16B};
  color: ${colors.color1};
  text-decoration: underline;
`;

export const SubTitleStyled = styled.p`
  margin-top: 1.5rem;
  ${fonts.lato.H14};
  color: ${colors.color1};
`;

export const BackButtonStyled = styled(Button)`
  align-self: flex-start;
  margin-right: 2rem;
`;

export const DataChangesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 2rem;
`;

export const TextDiffChangesWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 47%;
  max-height: 85vh; 
  overflow: auto;
  border: 1px solid ${colors.color3};
`;

export const TextDiffChangesContentStyled = styled.div`
`;

export const ImageWrapperStyled = styled.div`
  width: 47%;
  max-height: 85vh; 
  overflow: auto;
`;

export const DeletedSnippedStyled = styled.p`
  background: ${colors.color43};
  ${fonts.lato.H14};
  color: ${colors.color5};
  text-decoration: line-through;
  padding: 4px;
  text-wrap: wrap;
`;

export const AddedSnippedStyled = styled.p`
  background: ${colors.color44};
  ${fonts.lato.H14};
  color: ${colors.color5};
  padding: 4px;
  text-wrap: wrap;
`;

export const SeparatorStyled = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${colors.color3};
`;

export const MissingImageStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${colors.color41};
  ${fonts.lato.H16};
`;
