export const LABELS = {
  INVOICE: 'invoice',
  PHONE: 'phone',
  GST: 'gst',
  PST: 'pst',
  HST: 'hst',
  TAX: 'tax',
  TOTAL: 'TOTAL DUE',
  BANK: 'BANK INFORMATION',
};

export const PAYEE_LABELS = {
  NUMBER: 'INVOICE NO.',
  DATE: 'INVOICE DATE',
  PO: 'PO NO.',
  TERMS: 'TERMS',
  DUE: 'DUE DATE',
  TO: 'INVOICE TO',
  PUBISHER_TO: 'BILL TO',
  CUSTOMER: 'CUSTOMER ID',
};

export const FOOTER_LABELS = {
  TOTAL: 'Total',
  PAYMENT: 'Payments/Credits',
  BALANCE: 'Balance Due',
};

export const PAYEE_DEFAULTS = {
  TERMS: 'Due on receipt',
  DISCLAIMER: 'As per the authorization, the payment on this invoice has been',
  DISCLAIMER_TWO: 'taken from the existing merchant account.',
};

export const BANKING_DEFAULTS = {
  WIRE_TITLE: 'By Wire Transfer (CAD)',
  WIRE_DESCRIPTION: 'When using wire transfer, please make sure your bank charge is covered and net amount sent to us is the invoiced amount.',
  CHEQUE: 'By Cheque',
};

export const BANKING_LABEL = {
  BANK: 'Beneficiary Bank',
  BANK_ADDRESS: 'Beneficiary Bank Address',
  TRANSIT: 'Branch transit#',
  INSTITUTION: 'Institution#',
  ACCOUNT: 'Account#',
  SWIFT: 'Bank SWIFT#',
  BENEFICIARY: 'Beneficiary',
  PAYABLE: 'Payable to',
  BENEFICIARY_ADDRESS: 'Beneficiary Address',
  MAILING_ADDRESS: 'Mailing Address',
};

export const FOOTER_DEFAULTS = {
  LEFT_MESSAGE: 'THANK YOU FOR YOUR BUSINESS',
  RIGHT_MESSAGE: 'Please contact billing@fintelconnect.com if you have any questions or concerns about this invoice.',
};

export const PDF_TABLE_HEADER = [
  { name: 'DESCRIPTION', width: '25%' },
  { name: 'COUNT', width: '10%' },
  { name: 'AMOUNT', width: '15%' },
  { name: 'TAX', width: '15%' },
  { name: 'SYSTEM USAGE', width: '20%' },
  { name: 'TOTAL', width: '10%' },

];

export const PDF_TABLE_KEYS = [
  'cpa',
  'revShare',
  'referral',
  'bonus',
  'manualBonus',
  'manualCommission',
  'giftCard',
  'other',
  'managementFee',
  'manualFee',
];

export const PDF_DATE_KEYS = [
  'cpa',
  'revShare',
  'referral',
  'bonus',
];

export const PDF_CATEGORY_SUBTILE: {[key: string]: string} = {
  cpa: 'CPA Commission',
  referral: 'Referral Commission',
  manualBonus: 'Manual Bonus',
  manualCommission: 'Manual Commissions',
  giftCard: 'Gift Card',
  other: 'Other',
  managementFee: 'Management Fee',
  manualFee: 'Manual Fee',
  manualBonusOther: 'Manual Bonus (Other)',
  revShare: 'Revenue Share Commission',
  bonus: 'Bonus Commission',
};
