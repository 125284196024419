import React from 'react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../../../../components';
import {
  BUTTON_LABELS, MODAL,
} from '../../contracts';
import * as Styled from './styles';

type PayPalDepositSuccessProps = {
  closeHandler: () => void
}

const PayPalDepositSuccess = ({ closeHandler }: PayPalDepositSuccessProps) => (
  <Styled.WrapperStyled>
    <Styled.IconStyled icon={faCheckCircle} />
    <div>
      <Styled.TitleStyled>{MODAL.PAYPAL_SUCCESS.TITLE}</Styled.TitleStyled>
    </div>
    <Styled.DescriptionStyled>{MODAL.PAYPAL_SUCCESS.DESCRIPTION}</Styled.DescriptionStyled>

    <Styled.ButtonAreaStyled>
      <Button
        onClick={closeHandler}
      >
        {BUTTON_LABELS.BACK}
      </Button>
    </Styled.ButtonAreaStyled>

  </Styled.WrapperStyled>
);

export default PayPalDepositSuccess;
