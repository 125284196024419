import styled from 'styled-components';
import { colors, fonts } from '../../../../../styles/theme';

export const Wrapper = styled.div`
  width: 35rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
  padding: 1.5rem;
  ${fonts.lato.H16R}
  color: ${colors.color1};
`;

export const TitleStyled = styled.h3`
  ${fonts.gotham.H26M}
  color: ${colors.color1};
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
`;

export const ButtonWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;
