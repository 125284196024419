import React from 'react';
import { INPUT_LABEL } from '../../contracts';
import * as Styled from '../../styles';
import { ACCOUNT_STATUS_OPTIONS } from '../../../../../Merchants/Management/utils';

type PublisherAccountOverviewProps = {
  hookPublisherID: string
  hookPublisherName: string
  hookPublisherCreatedDate: string
  hookPublisherActivityStatus: string
  hookPublisherAccountStatus?: SelectOption
  hookSetPublisherName: (e: React.ChangeEvent<HTMLInputElement>) => void
  hookPublisherErrors: { [key: string]: string }
  hookValidateFields: () => void;
  hookSetAccountStatus: (valeu: SelectOption) => void
  isAdmin: boolean
  isReadOnly: boolean
  hookLoading: boolean
}

export const PublisherAccountOverview = ({
  hookPublisherActivityStatus,
  hookPublisherCreatedDate,
  hookPublisherID,
  hookPublisherName,
  hookPublisherAccountStatus,
  hookSetPublisherName,
  hookPublisherErrors,
  hookValidateFields,
  hookSetAccountStatus,
  isAdmin,
  isReadOnly,
  hookLoading,
}: PublisherAccountOverviewProps) => {
  const themeForViewOnly = isReadOnly || isAdmin ? 'readonlyTernary' : 'default';

  return (
    <Styled.ContentStyled>
      <Styled.InputTextStyled
        grids={1}
        theme="readonlyTernary"
        type="text"
        label={INPUT_LABEL.ID}
        value={hookPublisherID}
        readonly
      />

      <Styled.InputTextStyled
        grids={5}
        type="text"
        label={INPUT_LABEL.COMPANY_NAME}
        value={hookPublisherName}
        onChange={hookSetPublisherName}
        error={hookPublisherErrors.companyName}
        onBlur={hookValidateFields}
        required={!(isAdmin || isReadOnly)}
        theme={themeForViewOnly}
        readonly={isAdmin || isReadOnly}
        disabled={hookLoading && !(isAdmin || isReadOnly)}
      />

      <Styled.InputTextStyled
        grids={2}
        theme="readonlyTernary"
        type="text"
        label={INPUT_LABEL.REGISTRATION_DATE}
        value={hookPublisherCreatedDate}
        readonly
      />

      <Styled.InputTextStyled
        grids={2}
        theme="readonlyTernary"
        type="text"
        label={INPUT_LABEL.ACTIVITY_STATUS}
        value={hookPublisherActivityStatus}
        readonly
      />

      {isAdmin ? (
        <Styled.SelectStyled
          grids={2}
          name="accountStatus"
          label={INPUT_LABEL.ACCOUNT_STATUS}
          selected={hookPublisherAccountStatus}
          theme="default"
          options={ACCOUNT_STATUS_OPTIONS}
          onChange={hookSetAccountStatus}
          isDisabled={isReadOnly || hookLoading}
        />
      ) : (
        <Styled.InputTextStyled
          grids={2}
          theme="readonlyTernary"
          type="text"
          label={INPUT_LABEL.ACCOUNT_STATUS}
          value={hookPublisherAccountStatus?.label}
          readonly
        />
      )}
    </Styled.ContentStyled>
  );
};
