import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight,
} from '@fortawesome/free-solid-svg-icons';
import { usePagination } from './hooks';
import * as Styled from './styles';

type RecordOptions = {
  value: number;
  label: string;
}

type PaginationProps = {
  total: number;
  currentPage: number;
  onPageChange?: (page: number) => void;
  className?: string;
  recordOptions?: RecordOptions[];
  showRecords?: boolean;
  onRecordsChange?: (records: number) => void;
  totalRecords?: number;
}

export const Pagination = ({
  total, currentPage, onPageChange, className, recordOptions, showRecords, onRecordsChange, totalRecords,
}: PaginationProps) => {
  const {
    hookCurrentPage, hookSetCurrentPage, hookTotalPages, hookSetRecordsPerPage, hookRecordsPerPage,
  } = usePagination({
    currentPage,
    totalPages: total,
    cbPage: onPageChange,
    cbRecordsPerPage: onRecordsChange,
  });

  const previousPageRender = () => (
    <>
      <Styled.PagesStyled
        onClick={() => hookSetCurrentPage(1)}
        disabled={hookCurrentPage === 1}
      >
        <FontAwesomeIcon icon={faAnglesLeft} />
      </Styled.PagesStyled>
      <Styled.PagesStyled
        onClick={() => hookSetCurrentPage(hookCurrentPage - 1)}
        disabled={hookCurrentPage === 1}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </Styled.PagesStyled>
    </>
  );

  const nextPageRender = () => (
    <>
      <Styled.PagesStyled
        onClick={() => hookSetCurrentPage(hookCurrentPage + 1)}
        disabled={hookCurrentPage === hookTotalPages}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </Styled.PagesStyled>
      <Styled.PagesStyled
        onClick={() => hookSetCurrentPage(hookTotalPages)}
        disabled={hookCurrentPage === hookTotalPages}
      >
        <FontAwesomeIcon icon={faAnglesRight} />
      </Styled.PagesStyled>
    </>
  );

  const pagesRender = () => {
    const pages = [];

    for (let i = 2; i >= 1; i -= 1) {
      if (hookCurrentPage - i > 0) {
        pages.push(
          <Styled.PagesStyled
            key={`prev-${i}`}
            type="button"
            onClick={() => hookSetCurrentPage(hookCurrentPage - i)}
          >
            {hookCurrentPage - i}
          </Styled.PagesStyled>,
        );
      }
    }

    pages.push(
      <Styled.PagesStyled
        key="current-page"
        type="button"
        disabled
      >
        {hookCurrentPage}
      </Styled.PagesStyled>,
    );

    for (let i = 1; i <= 2; i += 1) {
      if (hookCurrentPage + i <= hookTotalPages) {
        pages.push(
          <Styled.PagesStyled
            key={`next-${i}`}
            type="button"
            onClick={() => hookSetCurrentPage(hookCurrentPage + i)}
          >
            {hookCurrentPage + i}
          </Styled.PagesStyled>,
        );
      }
    }

    return pages;
  };

  return (
    <Styled.WrapperStyled className={className}>
      <Styled.LeftSideStyled>
        {recordOptions && (
        <Styled.RecordsStyled>
          <Styled.RecordsLabelStyled>
            Records
          </Styled.RecordsLabelStyled>

          <Styled.SelectStyled
            name="records-per-page"
            selected={hookRecordsPerPage}
            onChange={hookSetRecordsPerPage}
            options={recordOptions}
          />
        </Styled.RecordsStyled>
        )}

        <Styled.DetailsStyled>
          {showRecords && (
          <>
            Showing
            {' '}
            {hookCurrentPage * Number(hookRecordsPerPage?.value) - Number(hookRecordsPerPage?.value) + 1}
            {' '}
            -
            {' '}
            {hookCurrentPage * Number(hookRecordsPerPage?.value)}
            {' '}
            of
            {' '}
            {totalRecords}
          </>
          )}

          {!showRecords && (
          <>
            Page
            {' '}
            {hookCurrentPage}
            {' '}
            of
            {' '}
            {hookTotalPages}
          </>
          )}
        </Styled.DetailsStyled>
      </Styled.LeftSideStyled>

      <Styled.PagesAreaStyled>
        {previousPageRender()}
        {pagesRender()}
        {nextPageRender()}
      </Styled.PagesAreaStyled>
    </Styled.WrapperStyled>
  );
};
